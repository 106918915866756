import types from './actions-types';

export const names = {
  startApp: 'start_app',
  navigate: 'navigate',
  reconcileNav: 'reconcile_navigation',
  setActiveRoute: 'set_active_route',
  setPrevActiveRoute: 'set_prev_active_route',
};

export function startApp() {
  return {
    type: startApp,
  };
}

export function reconcileNav(item, subItem, params) {
  return {
    type: names.reconcileNav,
    payload: {
      item,
      subItem,
      params,
    },
  };
}

export function backActive() {
  return { type: names.setPrevActiveRoute };
}

export function navigate(item, subItem, params) {
  return {
    type: names.navigate,
    payload: {
      item,
      subItem,
      params,
    },
  };
}

export const setAppSpecificLabel = (payload) => ({
  type: types.SET_SPECIFIC_LABEL,
  payload,
});
