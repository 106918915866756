import ReservationsModuleIcon from '@casanova/casanova-common/lib/Icons/ReservationsModule';
import i18n from '@i18n';
import CustomerEditor from 'containers/customers/CustomerEditor';
import { QuoteOne, QuoteTwo, QuoteThree } from '@reservations/Quote';
import NotPriceQuote from '@reservations/NotPriceQuote';
import Payment from '@reservations/Payment';

import ListOfReservations from '@reservations/ListOfReservations';

import { CustomerNoDownload } from '@customers/CustomerDetail';
import CustomerCreator from '@customers/CustomerCreator';
import { withRoleRestrictions } from 'utils/auth';
import { withReconcileNav } from 'containers/Breadcrumb';
import { validateRolePermissions } from 'utils/roles';
import {
  RESERVATIONS_MODULE,
  VIEW_RESERVATIONS,
} from 'utils/roles/permissions/reservationsPermissions';

export const reservations = {
  moduleName: RESERVATIONS_MODULE,
  route: '/reservations',
  label: 'Reservaciones',
  icon: ReservationsModuleIcon,
  main: 'main',
  show: validateRolePermissions(VIEW_RESERVATIONS),
  children: {
    main: {
      route: '',
      label: '',
      format: () => `/reservations`,
      component: withReconcileNav(
        'reservations',
        'main'
      )(withRoleRestrictions(ListOfReservations)),
    },
    quote: {
      route: '/quote',
      label: i18n('RESERVATIONS__QUOTE'),
      format: () => `/reservations/quote`,
      component: withReconcileNav(
        'reservations',
        'quote'
      )(withRoleRestrictions(QuoteOne)),
    },
    contractQuote: {
      route: '/contract-quote',
      label: 'Contratar',
      format: () => `/reservations/contract-quote`,
      component: withReconcileNav(
        'reservations',
        'contractQuote'
      )(withRoleRestrictions(NotPriceQuote)),
    },
    plan: {
      route: '/plan',
      label: i18n('RESERVATIONS__QUOTE'),
      format: () => `/reservations/plan`,
      onlyRoute: true,
      component: withReconcileNav(
        'reservations',
        'plan'
      )(withRoleRestrictions(QuoteTwo)),
    },
    summary: {
      route: '/summary',
      label: i18n('RESERVATIONS__QUOTE'),
      format: () => `/reservations/summary`,
      onlyRoute: true,
      component: withReconcileNav(
        'reservations',
        'summary'
      )(withRoleRestrictions(QuoteThree)),
    },
    addCustomer: {
      route: `/customer/add`,
      label: 'Cliente',
      format: () => `/reservations/customer/add`,
      onlyRoute: true,
      component: withReconcileNav(
        'reservations',
        'addCustomer'
      )(withRoleRestrictions(CustomerCreator)),
    },
    detailCustomer: {
      route: `/customer/:id/detail`,
      label: 'Cliente',
      format: (id) => `/reservations/customer/${id}/detail`,
      onlyRoute: true,
      component: withReconcileNav(
        'reservations',
        'detailCustomer'
      )(withRoleRestrictions(CustomerNoDownload)),
    },
    editCustomer: {
      route: `/customer/:id/edit`,
      label: 'Cliente',
      format: (id) => `/reservations/customer/${id}/edit`,
      onlyRoute: true,
      component: withReconcileNav(
        'reservations',
        'editCustomer'
      )(withRoleRestrictions(CustomerEditor)),
    },
    payment: {
      route: `/payment`,
      label: i18n('RESERVATIONS__PAYMENT__BREADCRUMB'),
      format: () => `/reservations/payment`,
      onlyRoute: true,
      component: withReconcileNav(
        'reservations',
        'payment'
      )(withRoleRestrictions(Payment)),
    },
  },
};
