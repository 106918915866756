import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { validateRolePermissions } from 'utils/roles';

const RoleVerifier = ({ identifier, allRolesValid = false, children }) => {
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    if (Array.isArray(identifier)) {
      const isValid = allRolesValid
        ? identifier.every((id) => validateRolePermissions(id))
        : identifier.some((id) => validateRolePermissions(id));

      setDisplay(isValid);
      return;
    }

    setDisplay(validateRolePermissions(identifier));
  }, [identifier, allRolesValid]);

  return <>{display && children}</>;
};

RoleVerifier.propTypes = {
  identifier: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  children: PropTypes.node.isRequired,
  allRolesValid: PropTypes.bool,
};

export default RoleVerifier;
