import _get from 'lodash/get';

export const invoiceDataTransformer = (billingDetails) => {
  const listOfBillingDetails =
    billingDetails && billingDetails.length > 0 ? billingDetails : [{}];

  const invoiceData = listOfBillingDetails.map((billing) => ({
    socialReason: _get(billing, 'name', ''),
    taxZipCode: _get(billing, 'taxZipCode', ''),
    fiscalRegime: _get(billing, 'fiscalRegime', ''),
    rfc: _get(billing, 'rfc', ''),
    useDefault: _get(billing, 'isPrimary', ''),
    cfdiUse: _get(billing, 'cfdiUse', ''),
    uuid: _get(billing, 'uuid', ''),
    taxSituationFile: _get(billing, 'taxSituationFile', ''),
  }));

  return invoiceData;
};
