export const cardInitialTouched = {
  cardType: true,
  bank: true,
  cardNumber: true,
  reference: true,
  authorization: true,
  voucher: true,
  hasHolderName: true,
  accountStatus: true,
  amountCard: true,
};

export const cardInitialValues = {
  cardType: '',
  bank: '',
  cardNumber: '',
  reference: '',
  authorization: '',
  voucher: '',
  hasHolderName: false,
  accountStatus: '',
  amountCard: '',
};
