import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import './index.scss';

const IconLink = ({ icon, url, onClick, children, ...rest }) => {
  const hasIcon = !_isEmpty(icon);
  return (
    <a
      className="link"
      {...rest}
      href={url}
      onClick={onClick}
      rel="noopener norefer"
    >
      {hasIcon && <span className="link__icon">{icon}</span>}
      {children}
    </a>
  );
};

IconLink.propTypes = {
  icon: PropTypes.object,
  url: PropTypes.string,
  text: PropTypes.string,
};

IconLink.defaultProps = {
  url: '',
  text: '',
};

export default IconLink;
