const { REACT_APP_API_URL } = process.env;

export async function sendRequest(path, options) {
  const url = `${REACT_APP_API_URL}/${path}`;
  const headers = options.headers || new Headers();
  const { authToken } = options;
  if (authToken) {
    headers.append('Authorization', `Bearer ${authToken}`);
  }

  const response = await fetch(url, {
    method: 'GET',
    headers,
  });
  if (response.ok) {
    const { data, ...rest } = await response.json();
    if (rest.errorCode) {
      throw new Error(rest.message);
    }
    return data;
  }
  throw new Error('Network in request');
}

export async function sendPostRequest(path, data, options) {
  const url = `${REACT_APP_API_URL}/${path}`;
  const headers = options.headers || new Headers();
  headers.append('content-type', 'application/json');
  const { authToken } = options;
  if (authToken) {
    headers.append('Authorization', `Bearer ${authToken}`);
  }

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });
  if (response.ok) {
    const { data, message, errorCode } = await response.json();
    if (errorCode) {
      const error = new Error(message);
      error.name = errorCode;
      throw error;
    }
    return data;
  }
  throw new Error('Network in request');
}

export async function sendPutRequest(path, data, options) {
  const url = `${REACT_APP_API_URL}/${path}`;
  const headers = options.headers || new Headers();
  headers.append('content-type', 'application/json');
  const { authToken } = options;
  if (authToken) {
    headers.append('Authorization', `Bearer ${authToken}`);
  }

  const response = await fetch(url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  });
  if (response.ok) {
    const { data, ...rest } = await response.json();
    if (rest.errorCode) {
      throw new Error(rest.message);
    }
    return data;
  }
  throw new Error('Network in request');
}

export async function sendUploadRequest(path, data, options = {}) {
  const url = `${REACT_APP_API_URL}/${path}`;
  const headers = options.headers || new Headers();
  const { authToken } = options;
  if (authToken) {
    headers.append('Authorization', `Bearer ${authToken}`);
  }

  const response = await fetch(url, {
    method: 'POST',
    body: data,
    headers,
  });

  if (response.ok) {
    const { data, ...rest } = await response.json();
    if (rest.errorCode) {
      throw new Error(rest.errorCode);
    }
    return data;
  }
  throw new Error('Network Error');
}
