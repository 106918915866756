export const CUSTOMER_LEVELS = {
  MADERA: 0,
  WOOD: 0,
  IRON: 1,
};

export const CUSTOMER_STATUS = [
  {
    value: '50995cbc-b7c2-4462-b279-41be2c40e435',
    label: 'Normal',
  },
  {
    value: '82c49bce-b66b-4cd0-8950-e0d4b476b3f7',
    label: 'Boletinado',
  },
  {
    value: '2822080c-0dd8-49fc-8bb4-9a753b36f4dd',
    label: 'Convenio',
  },
];

export const CUSTOMER_STATUS_VALUES = {
  NORMAL: '50995cbc-b7c2-4462-b279-41be2c40e435',
  BANNED: '82c49bce-b66b-4cd0-8950-e0d4b476b3f7',
  COVENANT: '2822080c-0dd8-49fc-8bb4-9a753b36f4dd',
};
