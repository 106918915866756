import ContractsModuleIcon from '@casanova/casanova-common/lib/Icons/VirtualContractsModule';
import i18n from '@i18n';
import { CONTRACTS_MODULE } from 'utils/roles/permissions/contractsPermissions';
import CustomerCreator from '@customers/CustomerCreator';
import { withReconcileNav } from 'containers/Breadcrumb';
import { withRoleRestrictions } from 'utils/auth';
import { ListOfContracts, ContractCreator, ContractDetails } from '@contracts';

const contracts = {
  moduleName: CONTRACTS_MODULE,
  route: '/contracts',
  label: i18n('CONTRACTS__MENU_LABEL'),
  icon: ContractsModuleIcon,
  main: 'all',
  children: {
    all: {
      route: '',
      label: '',
      component: withReconcileNav(
        'contracts',
        'all'
      )(withRoleRestrictions(ListOfContracts)),
    },
    add: {
      route: `/add`,
      label: i18n('COMMONS__NEW').toUpperCase(),
      onlyRoute: true,
      component: withReconcileNav(
        'contracts',
        'add'
      )(withRoleRestrictions(ContractCreator)),
    },
    detail: {
      route: `/:id/detail`,
      label: i18n('COMMONS_DETAIL').toUpperCase(),
      format: (id) => `/contracts/${id}/detail`,
      onlyRoute: true,
      component: withReconcileNav(
        'contracts',
        'detail'
      )(withRoleRestrictions(ContractDetails)),
    },
    addCustomer: {
      route: `/customer/add`,
      label: 'Cliente',
      format: () => `/contracts/customer/add`,
      onlyRoute: true,
      component: withReconcileNav(
        'contracts',
        'addCustomer'
      )(withRoleRestrictions(CustomerCreator)),
    },
  },
};

export default contracts;
