import _pick from 'lodash/pick';
import moment from 'moment';

const YYYY_MM_DD = 'YYYY-MM-DD';
const timeZone = 'America/Mexico_City';

export function plate(data) {
  const { id, vehicleId, serviceType, plateProceed } = data;
  const list = ['countryState', 'paymentType', 'plateNumber', 'price'];

  return Object.assign(_pick(data, list), {
    typeService: serviceType,
    plateProceed: new Date(plateProceed).toISOString(),
    vehicle: vehicleId,
    // Harcoded until we can get more information
    plateType: '51a0a67e-21ca-4d41-96a3-f4ed43087f8d',
    id,
  });
}

export function ownership(data) {
  const { id, vehicleId } = data;
  const list = ['folio', 'price', 'paymentType', 'fiscalYear', 'plate'];
  return Object.assign(_pick(data, list), {
    id,
    vehicle: vehicleId,
  });
}

export function circulationCard(data) {
  return data;
  // const { id, vehicleId, paymentType,
  // "cardClass": "string",
  // "cardNumber": "string",
  // "expirationDate": "2020-09-08T22:22:24.583Z",
  // "folio": "string",
  // "paymentType": "string",
  // "plate": "string",
  // "price": 0,
  // "type": "string",
  // "validity": "string",
  // "vehicle": "string"
}

export function verification(data) {
  const { id, vehicleId } = data;
  const list = [
    'putSign',
    'dateProcess',
    'nextProcess',
    'amountFine',
    'amountPaid',
    'plate',
    'paymentType',
    'hologram',
    'type',
  ];
  return Object.assign(_pick(data, list), {
    id,
    vehicle: vehicleId,
  });
}

export function policy(data) {
  const {
    id,
    vehicleId,
    emissionDate,
    validityEnd,
    validityStart,
    policyRemarks,
  } = data;
  const list = [
    'endorsement',
    'insurance',
    'paymentType',
    'policyNumber',
    'policyUse',
    'topic',
    'totalBonus',
  ];
  return Object.assign(_pick(data, list), {
    id,
    vehicle: vehicleId,
    remarks: policyRemarks,
    emissionDate: moment(emissionDate).tz(timeZone).format(YYYY_MM_DD),
    validityEnd: moment(validityEnd).tz(timeZone).format(YYYY_MM_DD),
    validityStart: moment(validityStart).tz(timeZone).format(YYYY_MM_DD),
  });
}
