import { push } from 'connected-react-router';

export const config = {
  schema: {},
  initialTouched: {},
  initialValues: {},
  validateOnBlur: true,
  showCancel: false,
  submitForm: () => push('./extra-charges/payment'),
  btnSubmitLabel: 'Pagar',
  dialogs: {
    confirmation: {},
    response: {},
  },
};
