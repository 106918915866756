import { formatDateWithTime } from '@casanova/casanova-common/lib/utils/date';
import { number2mxn } from 'utils/currency';

const transformer = (values) => ({
  series: values.serie,
  folio: values.folio,
  rfc: values.rfc,
  customerName: values.customerFullName,
  invoiceDate: formatDateWithTime(values.dateTime),
  vat: values.vat,
  amount: number2mxn(values.amount),
  totalAmount: values.totalAmount,
  invoiceStatus: values.invoiceStatus.name,
});

export default transformer;
