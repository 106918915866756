import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { saveStepThree, closeResponseDialog } from 'store/reservations/actions';
import SendQuote from './SendQuote';

export default compose(
  connect(
    (state) => {
      const mail = (_get(state, 'customers.customerDetail.data.userInfo.email', '') !== undefined) ? _get(state, 'customers.customerDetail.data.userInfo.email', '') : mail;
      return {
        discountForm: _get(state, 'reservations.quote.values.discountForm', {}),
        responseDialog: _get(state, 'reservations.responseDialog', {}),
        customerMail: mail,
        loader: _get(state, 'commons.loader', false),
      };
    },
    { saveStepThree, closeResponseDialog }
  )
)(SendQuote);
