import moment from 'moment';
import {
  timeZone,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/constants/index.js';

export const DRIVER_LICENSE__REQUIRED_UPDATE_FIELDS = {
  VALIDITY_DRIVER_LICENSE: 'validityDriverLicense',
  LICENSE_NUMBER: 'licenseNumber',
};

export const DRIVER_LICENSE_FIELDS = {
  ...DRIVER_LICENSE__REQUIRED_UPDATE_FIELDS,
};

export const PERMANENT_VALIDITY = moment()
  .add(100, 'years')
  .tz(timeZone)
  .format(YYYY_MM_DD);
