import {
  VEHICLES_FETCH_ALL, // wundertec action
  VEHICLES_FETCH_SUCCEEDED,
  VEHICLES_FETCH_FAILED,
  VEHICLES_FETCH_REQUESTED,
  VEHICLES_FETCH_FILTER,
  VEHICLES_FETCH_FILTER_SUCCEEDED,
  VEHICLES_FETCH_FILTER_FAILED,
  SET_PAGE_TABLE_VEHICLES,
  SAVE_VEHICLE_SUCCEEDED,
  SAVE_VEHICLE_FAILED,
  SAVE_VEHICLE_REQUESTED,
  EDIT_VEHICLE_SUCCEEDED,
  EDIT_VEHICLE_FAILED,
  EDIT_VEHICLE_REQUESTED,
  SHOW_CONFIRMATION_MESSAGE,
  CLOSE_CONFIRMATION_MESSAGE,
  STORE_VEHICLE_DATA,
  CLOSE_RESULT_MESSAGE,
  SET_MASSIVE_LOAD_TYPE,
  UPLOAD_VEHICLES,
  DOWNLOAD_FAILED_RECORDS,
  MODIFY_FILTERS,
  FETCH_VEHICLE_INFO_SUCCEEDED,
  FETCH_VEHICLE_INFO_REQUESTED,
  FETCH_VEHICLE_INFO_FAILED,
  SET_VEHICLES_SEARCH,
  CLEAN_VEHICLE_DATA,
  EXPORT_CATALOG_RECORDS,
  EXPORT_CATALOG_RECORDS_SUCCEEDED,
  EXPORT_CATALOG_RECORDS_FAILED,
  EXPORT_VEHICLE_DETAIL,
  DOWNLOAD_TEMPLATE_MASSIVE_LOAD,
  GET_ADDITIONAL,
  SAVE_ADDITIONAL,
  ADD_NEW_ADDITIONAL,
  CANCEL_NEW_ADDITIONAL,
  DOWNLOAD_ADDITIONAL_FILE,
  CLOSE_ADDITIONAL_RESULT,
  CLEAN_ADDITIONAL,
  RESET,
  GET_TYPES,
  GET_TYPES_SUCCEEDED,
  GET_TYPES_FAILED,
  VEHICLE_STATUS_REQUESTED,
  VEHICLE_STATUS_SUCCEEDED,
  VEHICLE_STATUS_FAILED,
  SET_PAGE_VEHICLE_STATUS,
  VEHICLE_CONTRACTS_REQUESTED,
  VEHICLE_CONTRACTS_SUCCEEDED,
  VEHICLE_CONTRACTS_FAILED,
  SET_PAGE_VEHICLE_CONTRACTS,
  VEHICLE_LOANS_REQUESTED,
  VEHICLE_LOANS_SUCCEEDED,
  VEHICLE_LOANS_FAILED,
  SET_PAGE_VEHICLE_LOANS,
  VEHICLE_TRANSFERS_REQUESTED,
  VEHICLE_TRANSFERS_SUCCEEDED,
  VEHICLE_TRANSFERS_FAILED,
  SET_PAGE_VEHICLE_TRANSFERS,
  FETCH_HISTORIC_DETAILS_REQUESTED,
  FETCH_HISTORIC_DETAILS_SUCCEEDED,
  FETCH_HISTORIC_DETAILS_FAILED,
  EDIT_VEHICLE_DETAILS_REQUESTED,
  EDIT_VEHICLE_DETAILS_SUCCEEDED,
  EDIT_VEHICLE_DETAILS_FAILED,
  CLOSE_RESPONSE_DIALOG,
  CLOSE_RESPONSE_DIALOG_VC,
  FETCH_VEHICLE_DETAILS_REQUESTED,
  FETCH_VEHICLE_DETAILS_SUCCEEDED,
  FETCH_VEHICLE_DETAILS_FAILED,
  CLEAN_VEHICLE_DETAILS,
  MARK_VEHICLE_FOR_SALE_REQUESTED,
  MARK_VEHICLE_FOR_SALE_SUCCEEDED,
  MARK_VEHICLE_FOR_SALE_FAILED,
  SEARCH_VEHICLES,
  SEARCH_VEHICLES_SUCCEEDED,
  SEARCH_VEHICLES_FAILED,
  CLEAN_SEARCH_VEHICLES,
  VEHICLES_UPDATE_LIST,
  VEHICLE_VIRTUAL_CONTRACTS_REQUESTED,
  VEHICLE_VIRTUAL_CONTRACT_SUCCEEDED,
  VEHICLE_VIRTUAL_CONTRACT_FAILED,
  SET_PAGE_VEHICLE_VIRTUAL_CONTRACT,
} from './actions-types';

export function fecthVehicles(payload) {
  return { type: VEHICLES_FETCH_ALL, payload };
}

export function fetchVehicles(payload) {
  return { type: VEHICLES_FETCH_REQUESTED, payload };
}

export function fetchVehiclesSucceeded(payload) {
  return { type: VEHICLES_FETCH_SUCCEEDED, payload };
}

export function fetchVehiclesFailed(payload) {
  return { type: VEHICLES_FETCH_FAILED, payload };
}

export function fetchVehiclesFilters() {
  return { type: VEHICLES_FETCH_FILTER };
}

export function fetchVehiclesFiltersSucceeded(payload) {
  return { type: VEHICLES_FETCH_FILTER_SUCCEEDED, payload };
}

export function fetchVehiclesFiltersFailed(payload) {
  return { type: VEHICLES_FETCH_FILTER_FAILED, payload };
}

export function searchVehicles(payload) {
  return { type: SEARCH_VEHICLES, payload };
}

export function searchVehiclesSucceeded(payload) {
  return { type: SEARCH_VEHICLES_SUCCEEDED, payload };
}

export function searchVehiclesFailed(payload) {
  return { type: SEARCH_VEHICLES_FAILED, payload };
}

export function cleanSearchVehicles(payload) {
  return { type: CLEAN_SEARCH_VEHICLES, payload };
}

export function fetchVehicleInfo(payload) {
  return { type: FETCH_VEHICLE_INFO_REQUESTED, payload };
}

export function fetchVehicleInfoSucceeded(payload) {
  return { type: FETCH_VEHICLE_INFO_SUCCEEDED, payload };
}

export function fetchVehicleInfoFailed(payload) {
  return { type: FETCH_VEHICLE_INFO_FAILED, payload };
}

export function setVehiclesPage(payload) {
  return { type: SET_PAGE_TABLE_VEHICLES, payload };
}

export function setVehiclesSearch(payload) {
  return { type: SET_VEHICLES_SEARCH, payload };
}

export function saveVehicleRequested(payload) {
  return { type: SAVE_VEHICLE_REQUESTED, payload };
}

export function saveVehicleSucceeded(payload) {
  return { type: SAVE_VEHICLE_SUCCEEDED, payload };
}

export function saveVehicleFailed(payload) {
  return { type: SAVE_VEHICLE_FAILED, payload };
}

export function editVehicleRequested(payload) {
  return { type: EDIT_VEHICLE_REQUESTED, payload };
}

export function editVehicleSucceeded(payload) {
  return { type: EDIT_VEHICLE_SUCCEEDED, payload };
}

export function editVehicleFailed(payload) {
  return { type: EDIT_VEHICLE_FAILED, payload };
}

export function openConfirmationMessage() {
  return { type: SHOW_CONFIRMATION_MESSAGE };
}

export function closeConfirmationMessage() {
  return { type: CLOSE_CONFIRMATION_MESSAGE };
}

export function closeResultMessage() {
  return { type: CLOSE_RESULT_MESSAGE };
}

export function storeVehicleData(payload) {
  return { type: STORE_VEHICLE_DATA, payload };
}

export function setMassiveLoadType(payload) {
  return { type: SET_MASSIVE_LOAD_TYPE, payload };
}

export function uploadVehicles(files, process) {
  return { type: UPLOAD_VEHICLES, payload: { files, process } };
}

export function downloadFailedRecords(data) {
  return { type: DOWNLOAD_FAILED_RECORDS, payload: data };
}

export function modifyFilters(payload) {
  return { type: MODIFY_FILTERS, payload };
}

export function getAdditional(payload) {
  return {
    type: GET_ADDITIONAL,
    payload,
  };
}

export function saveAdditional(payload) {
  return {
    type: SAVE_ADDITIONAL,
    payload,
  };
}
export function addNewAdditional(payload) {
  return {
    type: ADD_NEW_ADDITIONAL,
    payload,
  };
}

export function cancelNewAdditional(payload) {
  return {
    type: CANCEL_NEW_ADDITIONAL,
    payload,
  };
}

export function downloadAdditionalFile(payload) {
  return {
    type: DOWNLOAD_ADDITIONAL_FILE,
    payload,
  };
}
export function closeAdditionalResult(payload) {
  return {
    type: CLOSE_ADDITIONAL_RESULT,
    payload,
  };
}

export function cleanVehicleData(payload = {}) {
  return { type: CLEAN_VEHICLE_DATA, payload };
}

export function cleanAdditional(payload = {}) {
  return { type: CLEAN_ADDITIONAL, payload };
}

export function exportVehiclesCatalog(payload) {
  return { type: EXPORT_CATALOG_RECORDS, payload };
}

export function exportVehiclesCatalogSucceeded() {
  return { type: EXPORT_CATALOG_RECORDS_SUCCEEDED };
}

export function exportVehiclesCatalogFailed() {
  return { type: EXPORT_CATALOG_RECORDS_FAILED };
}

export function exportVehicleDetail(payload) {
  return { type: EXPORT_VEHICLE_DETAIL, payload };
}

export function downloadTemplate(payload) {
  return { type: DOWNLOAD_TEMPLATE_MASSIVE_LOAD, payload };
}

export function reset() {
  return { type: RESET };
}

export function getTypes(payload) {
  return { type: GET_TYPES, payload };
}

export function getTypesSucceeded(payload) {
  return { type: GET_TYPES_SUCCEEDED, payload };
}

export function getTypesFailed(payload) {
  return { type: GET_TYPES_FAILED, payload };
}

export function fetchVehicleStatus(payload) {
  return { type: VEHICLE_STATUS_REQUESTED, payload };
}

export function vehicleStatusSucceeded(payload) {
  return { type: VEHICLE_STATUS_SUCCEEDED, payload };
}

export function vehicleStatusFailed(payload) {
  return { type: VEHICLE_STATUS_FAILED, payload };
}

export function setPageVehicleStatus(payload) {
  return { type: SET_PAGE_VEHICLE_STATUS, payload };
}

export function fetchVehicleContracts(payload) {
  return { type: VEHICLE_CONTRACTS_REQUESTED, payload };
}

export function vehicleContractsSucceeded(payload) {
  return { type: VEHICLE_CONTRACTS_SUCCEEDED, payload };
}

export function vehicleContractsFailed(payload) {
  return { type: VEHICLE_CONTRACTS_FAILED, payload };
}

export function setPageVehicleContracts(payload) {
  return { type: SET_PAGE_VEHICLE_CONTRACTS, payload };
}

export function fetchVehicleLoans(payload) {
  return { type: VEHICLE_LOANS_REQUESTED, payload };
}

export function vehicleLoansSucceeded(payload) {
  return { type: VEHICLE_LOANS_SUCCEEDED, payload };
}

export function vehicleLoansFailed(payload) {
  return { type: VEHICLE_LOANS_FAILED, payload };
}

export function setPageVehicleLoans(payload) {
  return { type: SET_PAGE_VEHICLE_LOANS, payload };
}

export function fetchVehicleTransfers(payload) {
  return { type: VEHICLE_TRANSFERS_REQUESTED, payload };
}

export function vehicleTransfersSucceeded(payload) {
  return { type: VEHICLE_TRANSFERS_SUCCEEDED, payload };
}

export function vehicleTransfersFailed(payload) {
  return { type: VEHICLE_TRANSFERS_FAILED, payload };
}

export function setPageVehicleTransfers(payload) {
  return { type: SET_PAGE_VEHICLE_TRANSFERS, payload };
}

export function fetchHistoricDetails(payload) {
  return { type: FETCH_HISTORIC_DETAILS_REQUESTED, payload };
}

export function fetchHistoricDetailsSucceeded(payload) {
  return { type: FETCH_HISTORIC_DETAILS_SUCCEEDED, payload };
}

export function fetchHistoricDetailsFailed(payload) {
  return { type: FETCH_HISTORIC_DETAILS_FAILED, payload };
}

export function editVehicleDetails(payload) {
  return { type: EDIT_VEHICLE_DETAILS_REQUESTED, payload };
}

export function editVehicleDetailsSucceeded(payload) {
  return { type: EDIT_VEHICLE_DETAILS_SUCCEEDED, payload };
}

export function editVehicleDetailsFailed(payload) {
  return { type: EDIT_VEHICLE_DETAILS_FAILED, payload };
}

export function closeResponseDialog() {
  return { type: CLOSE_RESPONSE_DIALOG };
}
export function closeResponseDialogVc() {
  return { type: CLOSE_RESPONSE_DIALOG_VC };
}

export function fetchVehicleDetails(payload) {
  return { type: FETCH_VEHICLE_DETAILS_REQUESTED, payload };
}

export function fetchVehicleDetailsSucceeded(payload) {
  return { type: FETCH_VEHICLE_DETAILS_SUCCEEDED, payload };
}

export function fetchVehicleDetailsFailed(payload) {
  return { type: FETCH_VEHICLE_DETAILS_FAILED, payload };
}

export function cleanVehicleDetails(payload) {
  return { type: CLEAN_VEHICLE_DETAILS, payload };
}

export function markVehicleForSale(payload) {
  return { type: MARK_VEHICLE_FOR_SALE_REQUESTED, payload };
}

export function markVehicleForSaleSucceeded(payload) {
  return { type: MARK_VEHICLE_FOR_SALE_SUCCEEDED, payload };
}

export function markVehicleForSaleFailed(payload) {
  return { type: MARK_VEHICLE_FOR_SALE_FAILED, payload };
}

export function updateVehiclesList(payload) {
  return { type: VEHICLES_UPDATE_LIST, payload };
}

export function vehicleVirtualContracts(payload) {
  return { type: VEHICLE_VIRTUAL_CONTRACTS_REQUESTED, payload };
}

export function vehicleVirtualContractsSucceed(payload) {
  return { type: VEHICLE_VIRTUAL_CONTRACT_SUCCEEDED, payload };
}

export function vehicleVirtualContractsFailed(payload) {
  return { type: VEHICLE_VIRTUAL_CONTRACT_FAILED, payload };
}

export function setPageVehicleVirtualContracts(payload) {
  return { type: SET_PAGE_VEHICLE_VIRTUAL_CONTRACT, payload };
}
