import React, { useEffect, useCallback, useRef } from 'react';
import { Formik, yupToFormErrors, validateYupSchema } from 'formik';
import _get from 'lodash/get';
import {
  MaskedInput as Input,
  DatePickerV2,
  Select,
  TextAreaInput,
  FileInput,
  NumberInput,
} from '@casanova/casanova-common';
import { MINIMUM_DATE, MAXIMUM_DATE } from '@vehicles/common/constants';
import { fineValidation } from 'utils/validations/additionals';
import { fine as fineMeta } from 'services/vehicles/additionals';
import { CREATE_VEHICLE_FINES } from 'utils/roles/permissions';
import PreventChange from 'components/PreventChange';
import UserInfo from 'components/UserInfo';

import AdditionalWrapper from '@additionals/components/AdditionalModals';

import { TEXT_AREA_NOT_ALLOWED_CHARACTERS } from 'utils/validations';
import { alphaMask } from 'utils/masks';
import { withAdditionalCollapse } from '../AdditionalCollapse';
import { SelectWithOptions } from '../components';

function Fine(props) {
  const {
    name,
    vehicleId,
    fetchCatalog,
    getAdditional,
    editable,
    plateOptions,
    data,
    isNew,
    saveAdditional,
    vehicle,
    addNew,
    show,
  } = props;
  const formRef = useRef(null);
  useEffect(() => {
    if (!show && formRef) {
      formRef.current.resetForm(fineMeta.empty);
    }
  }, [show]);

  useEffect(() => {
    if (vehicleId) {
      getAdditional({ name, data: vehicleId, withFiles: ['receipt'] });
      fetchCatalog('fine-status');
      fetchCatalog('sanction');
    }
  }, [name, fetchCatalog, vehicleId, getAdditional]);

  const handleFormik = useCallback(
    (values) => {
      const folio = _get(data, 'fineFolio');
      saveAdditional({
        name,
        data: {
          ...values,
          receipt: values.fineReceipt,
          vehicleId,
          serialNumber: vehicle.serialNumber,
          plateOptions,
        },
        forceCreate: folio && folio !== values.folio,
        addNew,
      });
    },
    [addNew, saveAdditional, name, vehicleId, data, vehicle, plateOptions]
  );

  const handleValidation = (values) => {
    try {
      validateYupSchema(values, fineValidation, true, {
        vehicle,
        plateOptions,
      });
    } catch (error) {
      return yupToFormErrors(error);
    }
  };

  return (
    <div className="container-fluid">
      <Formik
        initialValues={addNew ? fineMeta.empty : data || fineMeta.empty}
        validate={handleValidation}
        validateOnChange={false}
        enableReinitialize
        onSubmit={handleFormik}
        innerRef={formRef}
      >
        {(formikProps) => {
          const { handleSubmit, initialValues, dirty } = formikProps;
          return (
            <PreventChange preventChange={dirty && editable}>
              <AdditionalWrapper
                roleVerifierIdentifier={CREATE_VEHICLE_FINES}
                handleSubmit={handleSubmit}
                formikProps={formikProps}
                emptyValues={fineMeta.empty}
                extraProps={props}
              >
                <div className="row">
                  <div className="col-12 col-md-4">
                    <Select
                      name="finePlate"
                      label="Numero de Placa*"
                      options={plateOptions}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Input
                      mask={alphaMask(30)}
                      maskPlaceholder=""
                      name="fineFolio"
                      label="Folio*"
                      placeholder="Folio*"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <DatePickerV2
                      name="fineDate"
                      label="Fecha de infracción *"
                      placeholder="Fecha de infracción *"
                      initialValue={initialValues.fineDate}
                      disabled={!editable && !isNew}
                      minimumDate={MINIMUM_DATE}
                      maximumDate={MAXIMUM_DATE}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <NumberInput
                      maskSuffix=" mxn"
                      decimals={2}
                      max={99_999.99}
                      name="amountPaidFine"
                      label="Importe*"
                      placeholder="Importe*"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FileInput
                      url={!isNew ? initialValues.fineReceiptUrl : undefined}
                      displayText={initialValues.initialFineReceipt}
                      type="file"
                      label="Comprobante de pago*"
                      placeholder="Comprobante de pago*"
                      name="fineReceipt"
                      accept="application/pdf"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="sanction"
                      label="Sanción en UMAS*"
                      options="catalogs.sanction"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <TextAreaInput
                      name="reason"
                      label="Motivo"
                      placeholder="Escribe el motivo"
                      disabled={!editable && !isNew}
                      regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS}
                      maxLength={200}
                    />
                  </div>
                </div>
                <UserInfo {...data} />
              </AdditionalWrapper>
            </PreventChange>
          );
        }}
      </Formik>
    </div>
  );
}
export default withAdditionalCollapse({ title: 'Infracciones' })(Fine);
