export const initialTouched = {
  hospedyType: true,
  hospedyHotelName: true,
  hospedyReservationNumber: true,
  hospedyConfirmation: true,
  hospedyContract: true,
  hospedyIdentificationLessor: true,
  hospedyRelationLabor: true,
  subtype: true,
  hospedySelected: true,
  endorsement: true,
  endorsementUuid: true,
};

const initialValues = {
  hospedyType: '',
  hospedyHotelName: '',
  hospedyReservationNumber: '',
  hospedyConfirmation: '',
  hospedyContract: '',
  hospedyIdentificationLessor: '',
  hospedyRelationLabor: '',
  subtype: '',
  endorsement: false,
  endorsementUuid: '',
};

export default {
  initialTouched,
  initialValues,
};
