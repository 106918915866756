import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { fetchCatalog } from 'store/commons/actions';
import InvoiceDataDetail from './InvoiceData';

export default compose(
  connect(
    (state) => ({
      data: _get(state, 'customers.customerDetail.data.billingDetails', []),
      cfdiUsesCatalog: _get(state, 'commons.catalogsCfdiUses.list', []),
    }),
    { fetchCatalog }
  )
)(InvoiceDataDetail);
