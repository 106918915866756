import React from 'react';
import PropTypes from 'prop-types';
import { default as Card } from 'components/CardSection';
import { SpecItem } from '@reservations/components';
import { capitalizeFirstChar } from 'utils/masks';
import PlanDetails from '../PlanDetails';
import icono_aire from './AIRE.png';

import './PlanDescription.scss';

const PlanDescription = ({
  fullVehicle,
  description,
  kind,
  size,
  image,
  cylinder,
  loadCapacity,
  passengers,
  fuelType,
  seatType,
  doors,
  cargoBed,
  transmissionType,
  branch,
  brand,
  line,
  inBranch,
  plansList,
}) => {
  const avoidCapitalization = ["HB20", "MG", "MG5", "KIA", "HR-V", "CR-V", "GMC"];
  let vehicleTitle = ''

  if(line == 'HAVAL JOLION'){
    vehicleTitle = 'GWM Haval Jolion'
  } else if(line == 'SILVERADO 3500 HD'){
    vehicleTitle = 'Chevrolet Silverado 3500 HD'
  } else {
    vehicleTitle = `${avoidCapitalization.includes(brand) ? brand : capitalizeFirstChar(brand.toLowerCase())} ${
      avoidCapitalization.includes(line) ? line : capitalizeFirstChar(line.toLowerCase())
    }`;
  }
  if(transmissionType.join(" ") == "Automático Manual" || transmissionType.join(" ") == "Automático")
    transmissionType[0] = 'Automática'
  

  return (
    <Card className="PlanDescription" noStatus noTitle padding="p-0">
      <div className="vehicle-details">
        <div className="vehicle-image">
          <img src={image} alt="Vehicle" />
        </div>
        <div className="vehicle-features">
          <p>
            <span className="kind">
              {kind} / {size}
            </span>
            <br />
            {(transmissionType.join(" ") == "Automática Manual" && size == "Mediano") ? (
              <span className="title">{vehicleTitle} automático / manual o similar</span>
            ) : (transmissionType.join(" ") == "Automática Manual" && size != "Mediano") ? (
              <span className="title">{vehicleTitle} automática / manual o similar</span>
            ) : transmissionType.join(" ") == "Manual" ? (
              <span className="title">{vehicleTitle} manual o similar</span>
            ) : (transmissionType.join(" ") == "Automática" && size == "Mediano") ? (
              <span className="title">{vehicleTitle} automático o similar</span>
            ) : (transmissionType.join(" ") == "Automática" && size != "Mediano") ? (
              <span className="title">{vehicleTitle} automática o similar</span>
            ) : (
              <span className="title">{vehicleTitle} o similar</span>
            )}
          </p>
          <div className="features">
            <div className="title">Características</div>
            <div className="feature-list">
              {/* <SpecItem feat="cilindros" specList={cylinder} /> */}
                  {loadCapacity == 0 ? (
                    <SpecItem feat="capacidad" specList={['N/A']} />
                  ) : (
                    <>
                      {loadCapacity > 100 ? (
                        <SpecItem feat="kg." specList={loadCapacity.map(element => Number(element).toFixed(0))} />
                      ) : (
                        <SpecItem feat="de capacidad" specList={loadCapacity.map(element => Number(element).toFixed(0))} />
                      )}
                    </>
                  )}
              
              <SpecItem feat="pasajeros" specList={passengers} />
              {kind == "Carga" && (
                <>
                  <SpecItem feat="" specList={fuelType} />
                  <SpecItem feat="medidas de la caja" specList={cargoBed} />
                </>
              )}
              {/* <SpecItem feat="" specList={seatType} /> */}
              {/* <SpecItem feat="puertas" specList={doors} /> */}
              <SpecItem feat="" specList={transmissionType} />
              <div className={`text ml-3`} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={icono_aire} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                <span className={`text d-block`}>
                  A/C
                </span>
              </div>
            </div>
          </div>
          {!inBranch && (
            <div className="disclaimer mt-3">
              <span className="disclaimer-title">Importante: </span>
              <span className="disclaimer-body">
                <span>El vehículo no se encuentra en sucursal {branch}.</span>
              </span>
            </div>
          )}
        </div>
      </div>
      {plansList.length > 0 && (
        <PlanDetails plans={plansList} fullVehicle={fullVehicle} />
      )}
    </Card>
  );
};

PlanDescription.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  fullVehicle: PropTypes.object.isRequired,

  kind: PropTypes.string,
  size: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,

  cylinder: PropTypes.array,
  doors: PropTypes.array,
  fuelType: PropTypes.array,
  loadCapacity: PropTypes.array,
  passengers: PropTypes.array,
  seatType: PropTypes.array,
  transmissionType: PropTypes.array,

  branch: PropTypes.string,
  brand: PropTypes.string,
  line: PropTypes.string,

  inBranch: PropTypes.bool,
  plansList: PropTypes.array,
};

PlanDescription.defaultProps = {
  kind: '',
  size: '',
  image: '',
  description: '',
  cylinder: [],
  doors: [],
  fuelType: [],
  loadCapacity: [],
  passengers: [],
  seatType: [],
  transmissionType: [],
  branch: '',
  brand: '',
  line: '',
  inBranch: true,
  plansList: [],
};

export default PlanDescription;
