import _isEmpty from 'lodash/isEmpty';

export const formatBillingDetailsForeigner = (values) => {
  const notEmpty = values.invoiceData.filter(
    (invoiceItem) => !_isEmpty(invoiceItem.rfc)
  );

  return notEmpty.map((invoiceItem) => ({
    cfdiUseUuid: invoiceItem.cfdiUse,
    name: invoiceItem.socialReason,
    taxZipCode: invoiceItem.taxZipCode,
    fiscalRegime: invoiceItem.fiscalRegime,
    rfc: invoiceItem.rfc,
    isPrimary: notEmpty.length === 1 || invoiceItem.useDefault,
    uuid: invoiceItem.uuid,
    invoiceZipCodeExist: invoiceItem.invoiceZipCodeExist,
    //taxSituationFile: invoiceItem.taxSituationFile,
  }));
};

export const formatBillingDetails = (values) => {
  const invoiceData = values.invoiceData || [];
  const notEmpty = invoiceData.filter(
    (invoiceItem) => !_isEmpty(invoiceItem.rfc)
  );

  const formattedBillingDetails = notEmpty.map((invoiceItem) => ({
    cfdiUseUuid: invoiceItem.cfdiUse,
    name: invoiceItem.socialReason,
    rfc: invoiceItem.rfc,
    taxZipCode: invoiceItem.taxZipCode,
    fiscalRegime: invoiceItem.fiscalRegime,
    isPrimary: notEmpty.length === 1 || invoiceItem.useDefault,
    uuid: invoiceItem.uuid,
    //taxSituationFile: invoiceItem.taxSituationFile,
  }));

  const formattedBillingFiles = notEmpty.map(
    ({ taxSituationFile, uuid }) => ({
      fieldName: 'taxSituationFile',
      file: taxSituationFile.presignedUrl ? null : taxSituationFile,
      suffix: 'TSF',
      fileUuid: uuid,
    })
  );

  return [formattedBillingDetails, formattedBillingFiles];
};
