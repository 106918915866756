export const defaultInvoiceData = [
  {
    socialReason: '',
    rfc: '',
    cfdiUse: '',
    taxZipCode: '',
    fiscalRegime: '',
    invoiceTaxZipCodeExist: '',
    useDefault: false,
    taxSituationFile: '',
  },
];

export const initialTouched = {
  invoiceData: [
    {
      socialReason: true,
      rfc: true,
      cfdiUse: true,
      taxZipCode: true,
      fiscalRegime: true,
      invoiceTaxZipCodeExist: true,
      useDefault: true,
      taxSituationFile: true,
    },
  ],
};

export const initialValues = {
  invoiceData: defaultInvoiceData,
};

export default {
  initialValues,
  defaultInvoiceData,
  initialTouched,
};
