import _get from 'lodash/get';
import { connect } from 'react-redux';
import ExtendDetail from './ExtendDetail';
import compose from 'recompose/compose';
import { createExtendContract } from 'store/reservations/actions';

export default compose(
  connect(
    (state) => ({
      responseDialog: _get(state, 'reservations.responseDialog', {}),
      reservationDetails: _get(state, 'reservations.reservationInfo.data', {}),
      extendContractDetails: _get(
        state,
        'reservations.contract.extendContract',
        {}
      ),
    }),
    { createExtendContract }
  )
)(ExtendDetail);
