export const CUSTOMERS_MODULE = 'CUSTOMERS_MODULE';

export const VIEW_CUSTOMER_CATALOG = 'VIEW_CUSTOMER_CATALOG';
export const VIEW_CUSTOMER_CATALOG_EXCEL = 'VIEW_CUSTOMER_CATALOG_EXCEL';
export const VIEW_CUSTOMER_FORMATS = 'VIEW_CUSTOMER_FORMATS';
export const VIEW_CUSTOMER_STATEMENT = 'VIEW_CUSTOMER_STATEMENT';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_USER = 'CREATE_CUSTOMER_USER';
export const CREATE_CUSTOMER_VALIDATED = 'CREATE_CUSTOMER_VALIDATED';
export const CREATE_CUSTOMER_SPECIAL = 'CREATE_CUSTOMER_SPECIAL';

export const VIEW_CUSTOMER_DETAILS = 'VIEW_CUSTOMER_DETAILS';
export const VIEW_CUSTOMER_ZIP_FILE = 'VIEW_CUSTOMER_ZIP_FILE';
export const VIEW_CUSTOMER_EXCEL_FILE = 'VIEW_CUSTOMER_EXCEL_FILE';

export const EDIT_CUSTOMER_INFORMATION = 'EDIT_CUSTOMER_INFORMATION';
export const EDIT_CUSTOMER_BAN = 'EDIT_CUSTOMER_BAN';
export const EDIT_CUSTOMER_UNBAN = 'EDIT_CUSTOMER_UNBAN';
export const CREATE_NEW_CUSTOMER = 'CREATE_NEW_CUSTOMER';
