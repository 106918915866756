import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, yupToFormErrors, validateYupSchema } from 'formik';
import {
  MaskedInput,
  Button,
  Icons,
  NumberInput,
  Select,
  PlainInput,
  FileInput,
  TextAreaInput,
} from '@casanova/casanova-common';
import { range } from '@casanova/casanova-common/lib/utils/numbers';
import * as validations from 'utils/validations/additionals';
import { roadTax as roadTaxMeta } from 'services/vehicles/additionals';
import RoleVerifier from 'components/RoleVerifier';
import PreventChange from 'components/PreventChange';
import UserInfo from 'components/UserInfo';
import { CREATE_VEHICLE_ROAD_TAX } from 'utils/roles/permissions';
import { alphaMask } from 'utils/masks';
import { TEXT_AREA_NOT_ALLOWED_CHARACTERS } from 'utils/validations';
import { useVehicle } from 'hooks';
import { withAdditionalCollapse } from '../AdditionalCollapse';
import ActionModal from '../components/ActionModal';

const thisYear = new Date().getFullYear();

const yearOptions = range(thisYear - 2, thisYear + 2).map((n) => ({
  label: String(n),
  value: n,
}));

function AdditionalRoadTax(props) {
  const {
    addNew,
    name,
    getAdditional,
    vehicle,
    vehicleId,
    data,
    plateOptions,
    saveAdditional,
    editable,
    isNew,
    onEdit,
    resultMessage,
    isCancelModalVisible,
    closeAdditionalResult,
    show,
  } = props;

  const { isSold } = useVehicle();

  const formRef = useRef(null);
  useEffect(() => {
    if (!show && formRef) {
      formRef.current.resetForm(roadTaxMeta.empty);
    }
  }, [show]);

  useEffect(() => {
    if (vehicleId) {
      getAdditional({
        name,
        data: vehicleId,
        withFiles: ['receipt'],
      });
    }
  }, [getAdditional, vehicleId, name]);

  const handleFormik = useCallback(
    (values) => {
      saveAdditional({
        name,
        data: {
          ...values,
          vehicleId,
          receipt: values.roadTaxReceipt,
          serialNumber: vehicle.serialNumber,
          plateOptions,
        },
        addNew,
      });
    },
    [addNew, saveAdditional, vehicleId, name, vehicle, plateOptions]
  );

  const handleCancel = (formikProps) => {
    if (editable) {
      if (formikProps.dirty) {
        props.onCancelModal(true);
      } else {
        onEdit(false);
        props.cancelNewAdditional({ name });
        if (isNew) {
          props.forceClose(name);
        }
      }
    } else {
      formikProps.resetForm(roadTaxMeta.empty);
      props.addNewAdditional({ name });
    }
  };

  const handleValidation = (values) => {
    try {
      validateYupSchema(values, validations.roadTax, true, {
        vehicle,
        plateOptions,
      });
    } catch (error) {
      return yupToFormErrors(error);
    }
    return {};
  };

  const handleConfirmCancelModal = (formikProps) => {
    onEdit(false);
    formikProps.resetForm();
    props.onCancelModal(false);
    props.onRequestClose(name);
    if (addNew) {
      props.cancelNewAdditional({ name });
    }
  };

  const handleCloseCancelModal = (formikProps) => {
    props.onCancelModal(false);
  };

  const cancelLabel = editable ? 'Cancelar' : 'Nueva';

  const handleCloseResultModal = () => {
    closeAdditionalResult({ name });
  };

  return (
    <div className="container-fluid">
      <Formik
        initialValues={addNew ? roadTaxMeta.empty : data || roadTaxMeta.empty}
        validate={handleValidation}
        validateOnChange={false}
        enableReinitialize
        onSubmit={handleFormik}
        innerRef={formRef}
      >
        {(formikProps) => {
          const { handleSubmit, initialValues, dirty } = formikProps;
          return (
            <PreventChange preventChange={dirty && editable}>
              <div className="vehicleEditor">
                {resultMessage && (
                  <ActionModal
                    open={resultMessage.open}
                    icon={
                      resultMessage.success ? (
                        <Icons.IcoCarOk />
                      ) : (
                        <Icons.IcoCarError />
                      )
                    }
                    onClose={handleCloseResultModal}
                    onAction={handleCloseResultModal}
                    title={resultMessage.title}
                    actionLabel="Ok"
                    message={resultMessage.message}
                    modalType="confirmation"
                    code={resultMessage.errorCode}
                    closeButton
                  />
                )}
                <ActionModal
                  open={isCancelModalVisible}
                  icon={<Icons.StatusWarning />}
                  onClose={handleCloseCancelModal}
                  onAction={handleConfirmCancelModal}
                  title="Cancelar proceso"
                  actionLabel="Sí"
                  closeLabel="No"
                  message={
                    !addNew
                      ? 'Al cancelar se perderán todos los datos previamente llenados. ¿Estás seguro que quieres continuar?'
                      : 'Se perderán los datos previamente llenados, te recomentados guardar tus cambios antes de salir.\n ¿Estás seguro que quieres continuar?'
                  }
                  closeButton
                  data={formikProps}
                />
                <div className="row">
                  {!isNew && !addNew && (
                    <div className="col-12 col-md-4">
                      <PlainInput
                        name="status"
                        disabled
                        label="Estatus"
                        limit={10}
                      />
                    </div>
                  )}
                  <div className="col-12 col-md-4">
                    <Select
                      name="plate"
                      label="Numero de Placa*"
                      options={plateOptions}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Select
                      name="fiscalYear"
                      label="Ejercicio Fiscal*"
                      options={yearOptions}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <MaskedInput
                      name="folio"
                      label="Folio*"
                      placeholder="Folio*"
                      mask={alphaMask(30)}
                      maskPlaceholder=""
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <NumberInput
                      decimals={2}
                      max={99_999.99}
                      name="price"
                      label="Importe de Pago*"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FileInput
                      url={!isNew ? initialValues.roadTaxReceiptUrl : undefined}
                      displayText={initialValues.roadTaxReceipt}
                      type="file"
                      label="Comprobante de pago*"
                      placeholder="Comprobante de pago*"
                      name="roadTaxReceipt"
                      accept="application/pdf"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12">
                    <TextAreaInput
                      label="Observaciones"
                      placeholder="Observaciones"
                      name="txRemarks"
                      disabled={!editable && !isNew}
                      regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS}
                      maxLength={200}
                    />
                  </div>
                </div>
                <UserInfo {...data} />
                {!isSold && (
                  <RoleVerifier identifier={CREATE_VEHICLE_ROAD_TAX}>
                    <div className="row mt-4 mb-4">
                      <div className="col-12 col-md-12 col-lg-4 d-flex footer-content">
                        {(addNew || isNew) && (
                          <p className="mandatory">
                            <span>
                              <Icons.Comment />
                            </span>{' '}
                            *Campos obligatorios.
                          </p>
                        )}
                      </div>
                      <div className="col-12 col-md-12 col-lg-8 d-flex bottom-actions">
                        <Button
                          outline={editable}
                          onClick={() => handleCancel(formikProps)}
                        >
                          {cancelLabel}
                        </Button>
                        {(addNew || isNew) && (
                          <Button onClick={handleSubmit} type="submit">
                            Guardar
                          </Button>
                        )}
                      </div>
                    </div>
                  </RoleVerifier>
                )}
              </div>
            </PreventChange>
          );
        }}
      </Formik>
    </div>
  );
}

AdditionalRoadTax.propTypes = {
  fetchCatalog: PropTypes.func,
};

export default withAdditionalCollapse({ title: 'Tenencia' })(AdditionalRoadTax);
