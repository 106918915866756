import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { FormModal } from 'components';
import { Icons, TextAreaInput } from '@casanova/casanova-common';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import ResponseDialog from 'components/ResponseDialog';
import { closeResponseDialog } from 'store/customers/actions';

import i18n from '@i18n';

export default function ChangeCustomerStatusModal({
  onCancel = _noop,
  show = false,
  onSubmit,
  onlySubmit = false,
  responseDialog = {},
  isNewsletter,
}) {
  const dispatch = useDispatch();
  const { values } = useFormikContext();

  const handleCancelAction = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSubmit = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <>
      {!isNewsletter && (
        <ActionModal
          open={show}
          closeLabel={i18n('COMMONS__CANCEL__TEXT')}
          actionLabel={i18n('COMMONS__YES_CONTINUE__TEXT')}
          title={i18n('CUSTOMERS__CHANGE_CUSTOMER_STATUS_MODAL_TITLE')}
          message={i18n('CUSTOMERS__CHANGE_CUSTOMER_STATUS_MODAL_MESSAGE')}
          icon={<Icons.StatusWarning />}
          messageBold
          // textBold={[`${folio}`]}
          // subDescription="Modificar los datos de envío"
          // onClickSubDescription={handleClickSubDescription}
          onClose={handleCancelAction}
          onAction={handleSubmit}
          onlySubmit={onlySubmit}
        />
      )}

      {isNewsletter && (
        <FormModal
          open={show}
          onCancel={handleCancelAction}
          onSubmit={handleSubmit}
          closeLabel={i18n('COMMONS__CANCEL__TEXT')}
          actionLabel={i18n('COMMONS__YES_CONTINUE__TEXT')}
          title={i18n('CUSTOMERS__CHANGE_CUSTOMER_STATUS_MODAL_TITLE')}
          description={i18n(
            'CUSTOMERS__CHANGE_CUSTOMER_STATUS_MODAL_MESSAGE_WITH_REASON'
          )}
          onlySubmit={onlySubmit}
        >
          <TextAreaInput
            name="reason"
            placeholder={i18n('COMMONS__PLACEHOLDER__WRITE')}
            regex={/[()[\]|&;$%@#"{}¿!¡?^/\\|<>+~=`':*¨]/g}
            maxLength={200}
            value={values.reason}
          />
        </FormModal>
      )}

      <ResponseDialog
        open={responseDialog.open}
        success={responseDialog.success}
        successTitle={i18n('CUSTOMERS__CHANGE_CUSTOMER_STATUS_DIALOG_TITLE')}
        successMessage={i18n(
          'CUSTOMERS__CHANGE_CUSTOMER_STATUS_DIALOG_MESSAGE'
        )}
        successLabel={i18n('COMMONS__CLOSE__TEXT')}
        onSuccess={() => dispatch(closeResponseDialog())}
        errorMessage={responseDialog.message}
        // onError={() => handleOnSendDiscount()}
        onClose={() => dispatch(closeResponseDialog())}
      />
    </>
  );
}

ChangeCustomerStatusModal.propTypes = {
  onCancel: PropTypes.func,
  responseDialog: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onlySubmit: PropTypes.bool,
  isNewsletter: PropTypes.bool.isRequired,
};
