import * as Yup from 'yup';
import { REQUIRED_MESSAGE } from 'utils/constants';

export const discountSchema = Yup.object().shape({
  discount: Yup.number()
    .test('required-in-case', REQUIRED_MESSAGE, function (value) {
      const { discountType } = this.parent;
      if (discountType.length === 0) return true;
      if (!value) return false;
      return true;
    })
    .test(
      'no-more-than-30%',
      'El descuento no puede superar el 30% del monto total',
      function (value) {
        const { discountType, discountTypeSymbol, total } = this.parent;
        if (discountType.length === 0) return true;
        if (!value) return false;

        const limit = total * 0.3;
        let amount = value;

        if (discountTypeSymbol === '%') {
          amount = total * (value / 100);
        }

        return amount <= limit;
      }
    ),
});

export const promocodeSchema = Yup.object().shape({
  promocode: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(3, 'El codigo debe tener más 3 caracteres.'),
});

export default {
  schema: discountSchema,
  enableReinitialize: true,
  initialValues: {
    withPromocode: [],
    discountType: [],
    withoutPayment: false,
  },
  initialTouched: {
    discount: true,
  },
  withButtons: false,
  submitForm: () => {},
  btnSubmitLabel: 'Actualizar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE REGISTRO',
      message:
        'Una vez cargada la información del cliente tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a Clientes',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};
