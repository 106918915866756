import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import _noop from 'lodash/noop';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import i18n from '@i18n';
import { SpecItem } from '@reservations/components';
import {
  REMARK_TYPES,
  ADDITIONAL_FIELDS,
  FIELD_TYPE,
} from '@reservations/commons/constants';
import { INPUT_COMPONENTS } from '@reservations/commons/constantsComponents';
import {
  specItemProps,
  additionalFieldName,
  isCheckPointSet,
} from '@reservations/sections/DeliveryRefluxSteps/StepTwo/config';
import { mapPropsToValues, validationSchema } from './config';
import { validations } from './schema';

const { BOOL } = FIELD_TYPE;

function VehicleStatement({
  selectedVerifyPoint = {},
  additionalFields = {},
  handleClickStatement = _noop,
  setNewValueAditionalField = _noop,
  textAreaRender = () => null,
  handleChangeAditionalField = _noop,
  setHasError,
  remarkName,
  setFieldValue,
  errors,
}) {
  const {
    customRemark = {},
    infoSet = false,
    remarks = '',
  } = selectedVerifyPoint;

  useEffect(() => {
    const withErrors = !_isEmpty(errors);
    setHasError(withErrors);
  }, [errors]);

  useEffect(() => {
    const remarkValue = customRemark.remark || remarks || '';
    setTimeout(() => {
      setFieldValue(
        remarkName,
        infoSet && !remarkValue ? i18n('COMMONS__NOT_REMARKS') : remarkValue
      );
    }, 100);
  }, [selectedVerifyPoint, remarkName, customRemark, remarks, infoSet]);

  const onChangeWithMaskValue = (maskValues, field, fieldKey) => {
    setFieldValue(maskValues.name, maskValues.value);
    handleChangeAditionalField({
      ...field,
      ...maskValues,
      fieldKey,
    });
  };

  const listOfAdditionalFields = useMemo(
    () => Object.entries(additionalFields),
    [additionalFields]
  );

  return (
    <div className="row">
      <div className="col-12 my-3">
        <div className="row">
          {[REMARK_TYPES.GOOD_STATE, REMARK_TYPES.DETAIL].map((remarkState) => (
            <div
              key={remarkState}
              className="col-6 d-flex justify-content-center align-items-center"
            >
              <SpecItem
                {...specItemProps}
                className={classNames(
                  !isCheckPointSet(selectedVerifyPoint) && 'pointer'
                )}
                specList={
                  remarkState === REMARK_TYPES.GOOD_STATE
                    ? ['BUEN ESTADO']
                    : ['DETALLE']
                }
                check={selectedVerifyPoint.customRemark.type === remarkState}
                onClick={() => handleClickStatement(remarkState)}
              />
            </div>
          ))}
        </div>
      </div>
      {listOfAdditionalFields.length > 0 && (
        <div className="col-12 mt-4">
          {listOfAdditionalFields.map(([fieldKey, field]) => {
            const { label, value } = field;
            const { max = 0 } = validations[fieldKey] || {};
            const inGas = fieldKey === ADDITIONAL_FIELDS.GAS;
            const InputComponent = INPUT_COMPONENTS[field.type];
            const isBool = field.type === BOOL;
            const name = additionalFieldName({
              fieldUuid: selectedVerifyPoint.uuid,
              fieldKey,
            });

            if (!InputComponent) return null;

            return (
              <div className="row" key={fieldKey}>
                {isBool ? (
                  <div className="col-12 my-2">
                    <SpecItem
                      {...specItemProps}
                      className={classNames(
                        'VehicleParts__General--item',
                        isCheckPointSet(selectedVerifyPoint) && 'pointer'
                      )}
                      textClassName="text-dark"
                      reverse
                      specList={[label]}
                      check={value}
                      onClick={() =>
                        setNewValueAditionalField({
                          fieldKey,
                          fieldValue: {
                            ...field,
                            value: !value,
                          },
                        })
                      }
                    />
                  </div>
                ) : (
                  <>
                    <div className="col-6 d-flex justify-content-start align-items-center">
                      <h6>{label}</h6>
                    </div>
                    <div className={`col-${inGas ? '4' : '6'}`}>
                      <InputComponent
                        max={max}
                        maskPrefix=""
                        name={name}
                        disabled={selectedVerifyPoint.infoSet}
                        onChangeWithMaskValue={(maskValues) =>
                          onChangeWithMaskValue(maskValues, field, fieldKey)
                        }
                        decimals={null}
                      />
                    </div>
                    {inGas && (
                      <div className="col-2 p-0 d-flex justify-content-center align-items-center">
                        <h6 className="VehicleParts__HelperAditionalField--sub-text">
                          / 8
                        </h6>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}
      <div className="col-12 mt-3">{textAreaRender()}</div>
    </div>
  );
}

VehicleStatement.propTypes = {
  selectedVerifyPoint: PropTypes.object,
  additionalFields: PropTypes.object,
  inRefund: PropTypes.bool,
  reservationInfo: PropTypes.object,
  checkPointIsSet: PropTypes.bool,
  handleClickStatement: PropTypes.func,
  setNewValueAditionalField: PropTypes.func,
  textAreaRender: PropTypes.func,
  handleChangeAditionalField: PropTypes.func,
  setHasError: PropTypes.func,
  remarkName: PropTypes.string,
};

export default withFormik({
  validationSchema,
  mapPropsToValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
})(VehicleStatement);
