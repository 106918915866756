import React, { useMemo, useEffect, useState, useCallback } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _isEqual from 'lodash/isEqual';
import { useFormikContext } from 'formik';
import i18n from '@i18n';
import {
  MaskedInput as Input,
  RadioGroupInput,
  ConnectedSelect,
  Section,
  withLayout,
  Select,
} from '@casanova/casanova-common';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { numericMask } from 'utils/masks';
import {
  EDIT_VEHICLE_MASTER_DATA,
  EDIT_VEHICLE_PLATE_NUMBER,
  EDIT_VEHICLE_GENERAL_INFORMATION,
} from 'utils/roles/permissions';
import { validateRolePermissions } from 'utils/roles';

const { ADD, VIEW, EDIT } = FORM_ACTIONS;

function VehicleDetail({
  action = ADD,
  match = {},
  vehicleUuid = '',
  vehicleDetails,
  onUpdateForm,
  fetchCatalog,
  fetchVehicleCatalog,
  transmissionTypeCatalog,
  cleanVehicleDetails,
  setShouldDisabledSubmit = _noop,
  brandSelected,
  selectBrand,
  selectLine,
  getSpecs,
  listBrands,
  listModels,
  listColors,
  listLines,
  linesBySelectedBrand,
  lineSelected,
}) {
  const vehicleId = useMemo(() => vehicleUuid || match.params.id, [
    vehicleUuid,
    match,
  ]);
  const [hasHydratedForm, setHasHydratedForm] = useState(false);
  const { setFieldValue, initialValues, values } = useFormikContext();
  const [customModels, setCustomModels] = useState([]);

  useEffect(() => {
    if (action !== 'add') {
      setCustomModels(_get(values, 'modelsDetail', []));
    }
    if (
      values?.brand &&
      values?.line &&
      listBrands?.length > 0 &&
      values.brand !== '' &&
      values.line !== ''
    ) {
      selectBrand({ target: { value: values?.brand } });
      selectLine({ target: { value: values?.line } });
    }
  }, [action, values.brand, values.line, listBrands]);

  const linesOptions = useMemo(
    () =>
      listLines && listLines.length > 0 ? listLines : linesBySelectedBrand,
    [listLines, linesBySelectedBrand]
  );

  useEffect(() => {
    if (vehicleId) {
      setFieldValue('vehicleUuid', vehicleId);
    }
  }, [vehicleId, setFieldValue]);

  useEffect(() => {
    if (!_isEmpty(vehicleDetails)) {
      onUpdateForm(vehicleDetails);
      setHasHydratedForm(true);
    }
  }, [vehicleDetails, onUpdateForm, vehicleId]);

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/warehouses',
      catalogPath: 'vehicle',
    });

    fetchCatalog({
      catalogPath: 'pricequotes',
      catalogId: 'catalogs/business-areas',
    });

    [
      'fuel-type',
      'transmission-type',
      'vehicle-status',
      'vehicle-sub-status',
      'brand',
      'model',
      'color',
      'version',
      'vehicle-type',
      'categories',
    ].forEach(fetchVehicleCatalog);
  }, [fetchCatalog, fetchVehicleCatalog]);

  useEffect(() => {
    if (hasHydratedForm) {
      setShouldDisabledSubmit(_isEqual(initialValues, values));
    }
  }, [hasHydratedForm, setShouldDisabledSubmit, initialValues, values]);

  useEffect(
    () => () => {
      cleanVehicleDetails();
    },
    [cleanVehicleDetails]
  );

  const isView = action === VIEW;
  const disabledInput =
    isView || !validateRolePermissions(EDIT_VEHICLE_MASTER_DATA);
  const disablePartialInputs =
    isView ||
    validateRolePermissions(EDIT_VEHICLE_PLATE_NUMBER) ||
    !validateRolePermissions(EDIT_VEHICLE_GENERAL_INFORMATION);

  const disablePlateNumberInput =
    isView ||
    !(
      validateRolePermissions(EDIT_VEHICLE_MASTER_DATA) ||
      validateRolePermissions(EDIT_VEHICLE_PLATE_NUMBER)
    );

  const title = `${
    action === EDIT ? `${i18n('COMMONS__EDIT').toUpperCase()} ` : ''
  }${i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__TITLE')}`;

  const handleSelectBrand = useCallback(
    (e) => {
      setFieldValue('line', undefined);
      setFieldValue('version', undefined);
      selectBrand(e);
    },
    [selectBrand, setFieldValue]
  );

  const handleSelectLine = useCallback(
    (e) => {
      setFieldValue('version', undefined);
      selectLine(e);
    },
    [selectLine, setFieldValue]
  );

  const handleModelChange = useCallback(
    ({
      nativeEvent: {
        target: { value },
      },
    }) => {
      const modelSelected = listModels.filter((model) => model.uuid === value);
      setFieldValue('modelKey', modelSelected[0].key);
      setFieldValue('modelYear', modelSelected[0].name);

      if (values.version && action === 'add') {
        getSpecs({ version: values.version, model: value });
      }
    },
    [values.version, getSpecs, listModels, setFieldValue, action]
  );

  return (
    <Section title={title}>
      <div className="row">
        <div className="col-md-4">
          <Input
            maskPlaceholder=""
            label={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__NO_CONTRACT'
            )}
            placeholder={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__NO_CONTRACT'
            )}
            name="contractNumber"
            disabled={true}
            showLabel={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <Input
            maskPlaceholder=""
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__CUSTOMER')}
            placeholder={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__CUSTOMER'
            )}
            name="customer"
            disabled={true}
            showLabel={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <Select
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__BRAND')}
            name="brand"
            disabled={disabledInput}
            onChange={handleSelectBrand}
            options={listBrands}
          />
        </div>
        <div className="col-md-4">
          <Select
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__LINE')}
            name="line"
            disabled={disabledInput}
            onChange={handleSelectLine}
            options={action !== 'add' ? linesOptions : brandSelected.lines}
          />
        </div>
        <div className="col-md-4">
          <Select
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__VERSION')}
            name="version"
            disabled={disabledInput}
            options={lineSelected.versions}
          />
        </div>
        <div className="col-md-4">
          <Select
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__MODEL')}
            name="model"
            disabled={disabledInput}
            onChange={handleModelChange}
            options={customModels > 0 ? customModels : listModels}
          />
        </div>
        <div className="col-md-4">
          <Input
            maskPlaceholder=""
            label={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__SERIAL_NUMBER'
            )}
            placeholder={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__SERIAL_NUMBER'
            )}
            name="serialNumber"
            disabled={true}
            showLabel={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <Input
            maskPlaceholder=""
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__PLATE')}
            placeholder={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__PLATE'
            )}
            name="plateNumber"
            disabled={disablePlateNumberInput}
            showLabel={disablePlateNumberInput}
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__FUEL_TYPE', [
              '*',
            ])}
            placeholder={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__FUEL_TYPE',
              ['*']
            )}
            name="fuelType"
            disabled={disabledInput}
            showLabel={disabledInput}
            options="catalogs.fuelType"
          />
        </div>
        <div className="col-md-12 ">
          <RadioGroupInput
            labelClass="d-flex align-items-center col-form-label col-md-12 py-0 my-1"
            groupClass="col-12 d-flex justify-content-between seat-type"
            label={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__TRANSMITION_TYPE',
              ['*']
            )}
            name="transmissionType"
            options={transmissionTypeCatalog}
            disabled={disabledInput}
            standardError
          />
        </div>
        <div className="col-md-4">
          <Select
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__COLOR')}
            name="color"
            disabled={disabledInput}
            options={listColors}
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__CATEGORY')}
            placeholder={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__CATEGORY'
            )}
            name="category"
            disabled={disabledInput}
            options="catalogs.categories"
          />
        </div>
        <div className="col-md-4">
          <Input
            mask={numericMask(10)}
            maskPlaceholder=""
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__KM')}
            placeholder={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__KM')}
            name="km"
            disabled={disabledInput}
            showLabel={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__ESTATUS')}
            placeholder={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__ESTATUS'
            )}
            name="status"
            disabled={disablePartialInputs}
            options="catalogs.vehicleStatus"
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__SUBESTATUS')}
            placeholder={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__SUBESTATUS'
            )}
            name="subStatus"
            disabled={disablePartialInputs}
            options="catalogs.vehicleSubStatus"
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            label={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__BUSINESS_UNIT'
            )}
            placeholder={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__BUSINESS_UNIT'
            )}
            name="businessArea"
            disabled={disablePartialInputs}
            options="commons.catalogsBusinessAreas"
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            label={i18n('VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__WAREHOUSE')}
            placeholder={i18n(
              'VEHICLES__SECTIONS__VEHICLE_DETAIL__FORM__WAREHOUSE'
            )}
            name="warehouse"
            disabled={disablePartialInputs}
            options="commons.catalogsWarehouses"
          />
        </div>
      </div>
    </Section>
  );
}

export default (props) => {
  const { layout = true } = props;
  return layout ? (
    withLayout(VehicleDetail)(props)
  ) : (
    <VehicleDetail {...props} />
  );
};
