import compose from 'recompose/compose';
import { connect } from 'react-redux';
import PurchaseForm from './PurchaseForm';

export default compose(
  connect(
    (state) => ({
      listPaymentTypes: state.catalogs.paymentType.list,
      listOwner: state.catalogs.owner.list,
      listProviders: state.catalogs.provider.list,
    }),
    {}
  )
)(PurchaseForm);
