import * as Yup from 'yup';
import i18n from '@i18n';

const REQUIRED_MESSAGE = 'Este campo es obligatorio.';

const INTERVAL_MESSAGE_MOTOR =
  'El número de motor debe tener entre 2 y 30 carácteres.';
const INTERVAL_MESSAGE_KEYS = 'El número de llaves debe estar entre 1 y 5.';

export const vehicleValidationProps = {
  brand: Yup.string().required(REQUIRED_MESSAGE),
  line: Yup.string().required(REQUIRED_MESSAGE),
  version: Yup.string().required(REQUIRED_MESSAGE),
  model: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test(
      'greaterThanTwoYears',
      'El modelo no puede ser de más de dos años.',
      function () {
        const { modelYear, action } = this.parent;
        if (action && action === 'add') {
          return new Date().getFullYear() - window.parseInt(modelYear) <= 2;
        }
        return true;
      }
    ),
  serialNumber: Yup.string()
    .required(REQUIRED_MESSAGE)
    .length(17, 'El número de serie debe tener 17 carácteres.')
    .test(
      'modelMatchWithSerial',
      'El número de serie no coincide con el modelo seleccionado',
      function () {
        const { modelKey, serialNumber, action } = this.parent;
        if (
          (action && action === 'edit') ||
          !serialNumber ||
          !modelKey ||
          serialNumber.length <= 9
        ) {
          return true;
        }
        return serialNumber[9].toUpperCase() === modelKey;
      }
    ),
  engineNumber: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(2, INTERVAL_MESSAGE_MOTOR)
    .max(30, INTERVAL_MESSAGE_MOTOR),
  vehicularKey: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test(
      'min-characters',
      i18n('ERROR__COMMONS__MIN_LENGTH', [7]),
      (vehicularKey = '') => vehicularKey.length > 6
    ),
  color: Yup.string().required(REQUIRED_MESSAGE),
  origin: Yup.string().required(REQUIRED_MESSAGE),
  cylinder: Yup.string().required(REQUIRED_MESSAGE),
  fuelType: Yup.string().required(REQUIRED_MESSAGE),
  passengers: Yup.string().required(REQUIRED_MESSAGE),
  loadCapacity: Yup.number()
    .required(REQUIRED_MESSAGE)
    .test(
      'loadCapacity',
      'La capacidad de carga debe estar entre 0 y 999.99 toneladas',
      (loadCapacity) =>
        parseFloat(loadCapacity) >= 0 && parseFloat(loadCapacity) <= 999.99
    ),
  mileage: Yup.string().nullable(),
  keys: Yup.number()
    .required(REQUIRED_MESSAGE)
    .min(1, INTERVAL_MESSAGE_KEYS)
    .max(5, INTERVAL_MESSAGE_KEYS),
  transmissionType: Yup.string().required(REQUIRED_MESSAGE),
  seatType: Yup.string().required(REQUIRED_MESSAGE),
  doors: Yup.string().required(REQUIRED_MESSAGE),
};

export const VehicleOnlySchema = Yup.object().shape({
  ...vehicleValidationProps,
});
