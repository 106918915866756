export const initialTouched = {
  visaNumber: true,
  visaYearIssue: true,
  visaValidity: true,
  visaCountry: true,
  visaCoverFront: true,
  visaCoverBack: true,
};

export const initialValues = {
  visaNumber: '',
  visaYearIssue: '',
  visaValidity: '',
  visaCountry: '',
  visaCoverFront: '',
  visaCoverBack: '',
};

export default {
  initialTouched,
  initialValues,
};

export const FIELDS = Object.keys(initialValues);
