import types from './actions-types';

export const names = {
  login: ['login', 'login_succeed', 'login_failed', 'login_reset'],
  logout: 'logout',
};

export function login(data) {
  return {
    type: names.login[0],
    payload: data,
  };
}

export function resetLogin() {
  return {
    type: names.login[3],
  };
}

export function logout() {
  return {
    type: names.logout,
  };
}

export function hydrateUserData() {
  return { type: types.HYDRATE_USER_DATA };
}

export function hydrateUserDataSuccess() {
  return { type: types.HYDRATE_USER_DATA_SUCCESS };
}

export function hydrateUserDataFailed() {
  return { type: types.HYDRATE_USER_DATA_FAILED };
}
