import React from 'react';
import { Icons } from '@casanova/casanova-common';
import { MASSIVE_LOAD_TYPES } from '@vehicles/common/constants';
import DropFile from '../DropFile';
import styles from './styles.module.css';

export default function StepOne({
  massiveLoadType,
  excelFile,
  xmlFiles,
  pdfFiles,
  handleXmlFiles,
  handlePdfFiles,
  handleExcelFile,
}) {
  return (
    <>
      <div className="row mt-2">
        <div className="col-md-12">
          <h5>Archivo de excel</h5>
          <DropFile
            accept=".xls,.xlsx"
            height="96px"
            onDropFile={handleExcelFile}
            files={excelFile}
            icon={(hasFile) => (
              <div className={styles.iconMd}>
                <Icons.FileCombined fill={hasFile ? '#8e8e93' : '#f2a000'} />
              </div>
            )}
          />
        </div>
      </div>
      {!!excelFile.length && massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES && (
        <div className="row pt-3">
          <div className="col-md-6">
            <h5>Archivos XML</h5>
            <DropFile
              onDropFile={handleXmlFiles}
              accept=".xml"
              isMultiple
              height="361px"
              files={xmlFiles}
              buttonLabel="Agregar archivo XML"
              icon={(hasFile) => (
                <div className={hasFile ? styles.iconSm : styles.iconLg}>
                  <Icons.FileXml fill={hasFile ? '#8e8e93' : '#f2a000'} />
                </div>
              )}
            />
          </div>
          <div className="col-md-6">
            <h5>Archivos PDF</h5>
            <DropFile
              onDropFile={handlePdfFiles}
              accept=".pdf"
              isMultiple
              height="361px"
              files={pdfFiles}
              buttonLabel="Agregar archivo PDF"
              icon={(hasFile) => (
                <div className={hasFile ? styles.iconSm : styles.iconLg}>
                  <Icons.FilePdf fill={hasFile ? '#8e8e93' : '#f2a000'} />
                </div>
              )}
            />
          </div>
        </div>
      )}
    </>
  );
}
