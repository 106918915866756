import React from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.css';

const MODES_TYPES = {
  RETIRO: 'Retiro',
  INSTALACION: 'Instalación',
  REINSTALACION: 'Reinstall',
};

function AdditionalTitle(props) {
  const modeType = useSelector((state) => state.catalogs.modeType);
  const { title, isNew, show, editable, name } = props;
  if (isNew && !show) {
    return title;
  }

  let suffix = '';
  if (name === 'gps') {
    switch (modeType) {
      case MODES_TYPES.INSTALACION:
        suffix = isNew ? 'Instalar' : 'Detalle';
        break;
      case MODES_TYPES.RETIRO:
        suffix = 'Retirar';
        break;
      case MODES_TYPES.REINSTALACION:
        suffix = 'Reinstalar';
        break;
      default:
        suffix = isNew ? 'Instalar' : editable ? 'Instalar' : 'Detalle';
        break;
    }
  } else {
    suffix = isNew ? 'Nueva' : editable ? 'Nueva' : 'Detalle';
  }
  return (
    <>
      {title}{' '}
      {show ? (
        <>
          {' '}
          | <span className={styles.suffix}>{suffix}</span>
        </>
      ) : null}
    </>
  );
}

export default AdditionalTitle;
