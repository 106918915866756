import { savePayment } from 'store/reservations/actions';
import { initialTouched, initialValues } from '@reservations/Payment/config';
import { schema } from './schema';
import formatter from './formatter';

const config = {
  schema,
  initialTouched: {
    ...initialTouched,
    reservationId: true,
    totallyPaidWithRefund: true,
  },
  initialValues: {
    ...initialValues,
    reservationId: '',
    totallyPaidWithRefund: false,
  },
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: true,
  showCancel: false,
  formatter: (values) => formatter(values, 'EXTRA_CHARGES'),
  submitForm: (request) => {
    const reservation = request.commons
      ? request.commons.reservation
      : request.reservation;
    if (reservation) {
      return savePayment({
        request,
        redirect: `/returns/${reservation}/refund-vehicle-parts?rejected=true`,
        inExtraCharges: true,
      });
    }
  },
  btnSubmitLabel: 'Continuar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE REGISTRO',
      message:
        'Una vez cargada la información del cliente tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a reservaciones',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};

export default config;
