import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  getCatalogueOfVehicles,
  setVehicleReservation,
  setParamsOfVehiclesCatalogue,
  createReservationContract,
  closeResponseDialog,
  setChangeVehicle,
  setInProgressExtend,
  changeVehicleReservation,
  resetReservations,
  closeResponseDialogS,
} from 'store/reservations/actions';
import { fetchCatalog } from 'store/commons/actions';
import { getTypes } from 'store/vehicles/actions';
import { fetchRentalStatuses, fetchRentalFilter } from 'store/catalogs/actions';
import { setAppSpecificLabel } from 'store/app/actions';
import { getQuotePath } from 'utils/navigation';
import { withLayout } from 'hooks';
import { Blank } from 'components';
import {
  isChangeOfVehicleFlow,
  isExtendOfContractFlow,
} from '@reservations/commons/utils';
import Contract from './Container';
import i18n from '@i18n';

const mapStateToProps = (state) => {
  const quoteType = _get(
    state,
    'reservations.quote.values.fullVehicle.vehicleTypeName',
    ''
  );

  // This work for change vehicle flow
  const selectedVehicleTypeLabel = _get(
    state,
    'reservations.reservationInfo.data.priceQuote.selectedVehicleLinePrice.vehicle.vehicleTypeName',
    ''
  );

  // This work for change vehicle flow
  const selectedVehicleCategory = _get(
    state,
    'reservations.reservationInfo.data.priceQuote.selectedVehicleLinePrice.vehicle.vehicleCategoryUuid',
    ''
  );

  const selectedVehicleLabel = _get(
    state,
    'reservations.reservationInfo.data.priceQuote.selectedVehicleLinePrice.vehicle.vehicleCategoryName',
    ''
  );

  const selectedCategory = _get(
    state,
    'reservations.filters.selectedCategory',
    {}
  );

  const { changeVehicle, extendContract } = state.reservations.contract;

  const changeVehicleResponseDialog = {
    open: Boolean(changeVehicle.error),
    success: false,
    errorMessage: changeVehicle.errorMessage,
    errorCode: changeVehicle.errorCode,
    errorLabel: i18n('COMMONS__CLOSE__TEXT'),
  };

  const extendResponseDialog = {
    open: Boolean(extendContract.error),
    success: false,
  };

  return {
    confirmationMessage: false,
    onCancelPath: `${getQuotePath('list')}?isCanceling=true`,
    responseDialog: state.reservations.responseDialog,
    responseDialogS: state.reservations.contract.setVehicle.responseDialog,
    rentalStatuses: state.catalogs.rentalStatuses.list.results,
    contract: state.reservations.contract,
    lineSelected: _get(
      state,
      'reservations.quote.values.fullVehicle.vehicleLineUuid',
      ''
    ),
    category: !_isEmpty(selectedCategory.value)
      ? selectedCategory
      : {
          value: selectedVehicleCategory,
          label: selectedVehicleLabel,
        },
    type: quoteType || selectedVehicleTypeLabel,
    left: <Blank />,
    vehicleTypes: _get(state, 'vehicles.types.list', []),
    reservationInfo: state.reservations.reservationInfo.data,
    changeOfVehicle: changeVehicle,
    extendOfContract: extendContract,

    // withResponseDialog props
    resDialog: changeVehicleResponseDialog,
    resECDialog: extendResponseDialog,
  };
};

const mapDispatchToProps = (dispatch, { history }) => {
  const getDispatch = (action) => (...args) => dispatch(action(...args));

  const onClose = () => {
    history.goBack();
  };

  const onTryAgain = ({ values }) => {
    // if (isExtendOfContractFlow()) dispatch(changeVehicleReservation(values));
    if (isChangeOfVehicleFlow()) history.push(`/daily-rent-contracts/`);
  };

  return {
    onCancel:
      isChangeOfVehicleFlow() || isExtendOfContractFlow()
        ? () => {
            dispatch(setChangeVehicle(false));
            dispatch(setInProgressExtend(false));
            history.push(`/daily-rent-contracts`);
          }
        : undefined,
    closeResponseDialog: getDispatch(closeResponseDialog),
    closeResponseDialogS: getDispatch(closeResponseDialogS),

    // withResponseDialog props
    onClose,
    onTryAgain,

    getCatalogueOfVehicles: getDispatch(getCatalogueOfVehicles),
    setVehicleReservation: getDispatch(setVehicleReservation),
    setParamsOfVehiclesCatalogue: getDispatch(setParamsOfVehiclesCatalogue),
    createReservationContract: getDispatch(createReservationContract),
    fetchRentalStatuses: getDispatch(fetchRentalStatuses),
    fetchRentalFilter: getDispatch(fetchRentalFilter),
    getVehicleTypes: getDispatch(getTypes),
    setChangeVehicle: getDispatch(setChangeVehicle),
    setInProgressExtend: getDispatch(setInProgressExtend),
    fetchCatalog: getDispatch(fetchCatalog),
    changeVehicleReservation: getDispatch(changeVehicleReservation),
    setAppSpecificLabel: getDispatch(setAppSpecificLabel),
    resetReservations: getDispatch(resetReservations),
  };
};

export default withLayout(
  compose(connect(mapStateToProps, mapDispatchToProps))(Contract)
);
