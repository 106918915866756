import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { MASSIVE_LOAD_TYPES } from '@vehicles/common/constants';
import { Table, Icons } from '@casanova/casanova-common';
import styles from './styles.module.css';
import { VehicleArray, VehicleForSale } from './columns';

export default function StepTwo({
  initialTab,
  records,
  onDownloadRecords,
  massiveLoadType,
}) {
  const vehicleCase = massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES;

  const [tabActive, setTabActive] = useState(initialTab);
  useEffect(() => {
    setTabActive(initialTab);
  }, [initialTab]);

  const buttonFirst = classnames(styles.btn, styles.first, {
    [styles.active]: tabActive === 1,
  });
  const buttonClass = classnames(styles.btn, styles.second, {
    [styles.active]: tabActive === 2,
  });

  const handleDownloadFailed = useCallback(() => {
    if (records && records.failed_records && records.failed_records.length) {
      onDownloadRecords();
    }
  }, [records, onDownloadRecords]);

  return (
    <>
      <form>
        <div className={buttonFirst} onClick={() => setTabActive(1)}>
          <span>
            Registros correctos (
            {records && records.success_records
              ? records.success_records.length
              : 0}
            )
          </span>
        </div>
        <div className={buttonClass} onClick={() => setTabActive(2)}>
          <span>
            Registros con error (
            {records && records.failed_records
              ? records.failed_records.length
              : 0}
            )
          </span>
        </div>
        {tabActive === 2 && (
          <button
            type="button"
            onClick={handleDownloadFailed}
            className={`btn text-secondary pb-0 pr-0 float-right font-weight-bold ${styles.btnDownload}`}
          >
            <span className={styles.iconXs}>
              <Icons.Download />
            </span>{' '}
            Descargar archivo
          </button>
        )}
      </form>
      <p className={styles.comment}>
        <span>
          <Icons.Comment />
        </span>
        Antes de continuar, descarga el archivo de errores para corregirlo e
        intenta subirlo después.
      </p>
      <div className={styles.tableWrapper}>
        <Table
          dataList={
            tabActive === 1 ? records.success_records : records.failed_records
          }
          columns={vehicleCase ? VehicleArray : VehicleForSale}
        />
      </div>
    </>
  );
}

StepTwo.defaultProps = {
  initialTab: 1,
};
