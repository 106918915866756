import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import {
  getTimeLimits,
  closeResponseDialog,
  setContractQuote,
  setQuoteValues,
  getVehicleTypes,
  setTitles,
} from 'store/reservations/actions';
import { fetchCatalog } from 'store/commons/actions';
import {
  search,
  getDetail,
  resetDetail,
  searchJuridicalCustomer,
  searchTenderCustomer,
} from 'store/customers/actions';
import { setRentalPlanOptions } from 'store/dailyRentContracts/actions';
import NotPriceQuote from './Parent';

export default withLayout(
  compose(
    connect(
      (state) => ({
        location: state.router.location,
        quoteValues: state.reservations.quote.values,
        notPriceQuote: state.reservations.notPriceQuote,
        confirmationMessage: false,
        responseDialog: state.reservations.responseDialog,
        customerDetail: state.customers.customerDetail?.data || {},
        quote: state.reservations.quote,
        loader: state.commons.loader,
      }),
      {
        fetchCatalog,
        closeResponseDialog,
        setContractQuote,
        setQuoteValues,
        search,
        searchJuridicalCustomer,
        searchTenderCustomer,
        getDetail,
        resetDetail,
        getTimeLimits,
        getVehicleTypes,
        setTitles,
        setRentalPlanOptions,
      }
    )
  )(NotPriceQuote)
);
