export const depositInitialTouched = {
  bankAuthorization: true,
  bankVoucher: true,
  amountDeposit: true,
};

export const depositInitialValues = {
  bankAuthorization: '',
  bankVoucher: '',
  amountDeposit: '',
};
