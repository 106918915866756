import qs from 'qs';
import { sendCustomRequest } from '@casanova/casanova-common/lib/utils/service';
import { APIError } from 'utils/exceptions';
import { userRoot } from 'services/roots';

export async function getVerifyValidToken(params = {}) {
  const endpoint = `${userRoot}/tokens?${qs.stringify(params, {
    encode: false,
  })}`;
  const response = await sendCustomRequest(endpoint, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function postUserActivation(body) {
  const endpoint = `${userRoot}/users/activation`;

  const response = await sendCustomRequest(endpoint, {}, body, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}
