import { call, put, select } from 'redux-saga/effects';
import { getVerifyValidToken, postUserActivation } from 'services/activation';
import {
  verifyValidTokenSuccessed,
  verifyValidTokenFailed,
  userActivationSuccessed,
  userActivationFailed,
} from './actions';

export function* verifyValidTokenSaga(action) {
  const key = 'verifyToken';
  try {
    const { token } = action.payload;
    const { status } = yield call(getVerifyValidToken, action.payload);
    const data = {
      status,
      token,
    };
    yield put(verifyValidTokenSuccessed({ data, key }));
  } catch (error) {
    const errorRes = {
      data: error,
      key,
    };
    yield put(verifyValidTokenFailed(errorRes));
    console.error(error);
  }
}

export function* userActivationSaga(action) {
  const key = 'userActivation';
  try {
    const token = yield select(
      ({ activation }) => activation.verifyToken.data.token
    );
    if (!token) throw new Error('Token not exist');
    const body = {
      ...action.payload,
      token,
    };
    const data = yield call(postUserActivation, body);
    yield put(userActivationSuccessed({ data, key }));
  } catch (error) {
    const errorRes = {
      data: error,
      key,
    };
    yield put(userActivationFailed(errorRes));
    console.error(error);
  }
}
