import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from 'hooks';
import { closeResponseDialog } from 'store/loans/actions';
import { searchEmployees } from 'store/customers/actions';
import { searchVehicles } from 'store/vehicles/actions';
import { successPath as onCancelPath } from '@loans/common/config';
import LoanCreator from './LoanCreator';

export default withLayout(
  compose(
    connect(
      (state) => ({
        responseDialog: state.loans.responseDialog,
        user: state.auth.user,
        employees: state.customers.employees,
        onCancelPath,
      }),
      {
        searchEmployees,
        searchVehicles,
        closeResponseDialog,
      }
    )
  )(LoanCreator)
);
