import qs from 'qs';
import { sendCustomRequest } from '@casanova/casanova-common/lib/utils/service';
import { APIError } from 'utils/exceptions';
import { cleanParams } from 'utils/requests';
import { priceQuoteRootV1 } from '../roots';
import { listOfInvoicesTransformer, CRPsTransformer } from './transformers';

export async function getInvoicesList(params) {
  const queryParams = `?${qs.stringify(cleanParams(params), {
    encode: false,
  })}`;
  const url = `${priceQuoteRootV1}/invoice/catalog${queryParams}`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return listOfInvoicesTransformer(response.data.data);
}

export async function getInvoiceDetails(invoiceNumber) {
  const url = `${priceQuoteRootV1}/invoice/${invoiceNumber}/details`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getListOfCRPs(invoiceUuid, params) {
  const queryParams = `?${qs.stringify(cleanParams(params), {
    encode: false,
  })}`;
  const url = `${priceQuoteRootV1}/crp/invoice/${invoiceUuid}${queryParams}`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return CRPsTransformer(response.data.data);
}

export async function saveCRP(invoiceUuid, request) {
  const url = `${priceQuoteRootV1}/crp/invoice/${invoiceUuid}`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}
