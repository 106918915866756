import { transformIdOfList } from 'utils/transformHelpers';
import types from './actions-types';
import _get from 'lodash/get';
import { getBrach, getRoleName } from '../../services/auth';

const initialState = {
  responseDialog: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
  },
  list: {
    params: {
      departureBranch:
        getRoleName() != 'CONTACT_CENTER' && getBrach() ? getBrach() : '',
      page: 0,
      size: 10,
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: [],
  },
  closeContract: {
    data: {},
    success: false,
    error: false,
    errorMessage: '',
    responseDialog: {
      open: false,
      success: false,
      errorCode: '',
      errorMessage: '',
    },
  },
  detailsOfCloseContract: {
    data: {},
    success: false,
    error: false,
    errorMessage: '',
  },
  refund: {
    data: {},
    success: false,
    error: false,
    errorMessage: '',
  },
  pay: {
    data: {},
    success: false,
    error: false,
    errorMessage: '',
  },
  totalPay: {
    data: {},
    success: false,
    error: false,
    errorMessage: '',
  },
  rentalPlans: {
    list: [],
    toUse: {
      list: [],
    },
  },
};

export function dailyRentContractsReducer(
  state = initialState,
  { payload, type }
) {
  switch (type) {
    case types.CLOSE_RESPONSE_DIALOG:
      return {
        ...state,
        responseDialog: initialState.responseDialog,
      };
    case types.CLOSE_RESPONSE_DIALOG_VALID_CODE:
      return {
        ...state,
        closeContract: {
          ...state.closeContract,
          responseDialog: initialState.responseDialog,
        },
      };
    case types.SET_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            ...payload,
            page: 0,
          },
        },
      };
    case types.RESET_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          params: initialState.list.params,
        },
      };
    case types.SET_LIST_PAGE:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            page: payload,
          },
        },
      };
    case types.GET_LIST_FAILED:
    case types.GET_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          results: [],
        },
      };
    case types.GET_LIST_SUCCEED:
      return {
        ...state,
        list: {
          ...state.list,
          results: transformIdOfList(payload.content, 'contractNumber'),
          totalPages: payload.totalPages,
          totalElements: payload.totalElements,
        },
      };
    case types.CLOSE_CONTRACT:
      return {
        ...state,
        closeContract: initialState.closeContract,
      };
    case types.CLOSE_CONTRACT_SUCCEED:
      return {
        ...state,
        closeContract: {
          ...state.closeContract,
          data: payload,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    case types.CLOSE_CONTRACT_FAILED:
      return {
        ...state,
        closeContract: {
          ...state.closeContract,
          data: initialState.closeContract.data,
          success: false,
          error: true,
          errorMessage: payload,
          responseDialog: {
            open: true,
            success: false,
            errorCode: payload.code,
            errorMessage: payload.message,
            closeInConfirmation: true,
          },
        },
      };
    case types.GET_CLOSE_CONTRACT_DETAILS:
      return {
        ...state,
        detailsOfCloseContract: initialState.detailsOfCloseContract,
      };
    case types.GET_CLOSE_CONTRACT_DETAILS_SUCCEED:
      return {
        ...state,
        detailsOfCloseContract: {
          ...state.detailsOfCloseContract,
          data: payload,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    case types.GET_CLOSE_CONTRACT_DETAILS_FAILED:
      return {
        ...state,
        detailsOfCloseContract: {
          ...state.detailsOfCloseContract,
          data: initialState.detailsOfCloseContract.data,
          success: false,
          error: false,
          errorMessage: payload,
        },
      };
    case types.SAVE_CONTRACT_REFUND:
      return {
        ...state,
        refund: initialState.refund,
        responseDialog: initialState.responseDialog,
      };
    case types.SAVE_CONTRACT_REFUND_SUCCEED:
      return {
        ...state,
        refund: {
          ...state.refund,
          data: payload,
          success: true,
          error: false,
          errorMessage: '',
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
          message: '',
        },
      };
    case types.SAVE_CONTRACT_REFUND_FAILED:
      return {
        ...state,
        refund: {
          ...state.refund,
          data: initialState.refund.data,
          success: false,
          error: false,
          errorMessage: payload,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.SAVE_CONTRACT_PAY:
      return {
        ...state,
        pay: initialState.pay,
      };
    case types.SAVE_CONTRACT_PAY_SUCCEED:
      return {
        ...state,
        pay: {
          ...state.pay,
          data: payload,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    case types.SAVE_CONTRACT_PAY_FAILED:
      return {
        ...state,
        pay: {
          ...state.pay,
          data: initialState.pay.data,
          success: false,
          error: true,
          errorMessage: payload,
        },
      };
    case types.GET_TOTAL_PAY:
      return {
        ...state,
        totalPay: initialState.totalPay,
      };
    case types.GET_TOTAL_PAY_SUCCEED:
      return {
        ...state,
        totalPay: {
          ...state.totalPay,
          data: payload.data.data,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    case types.GET_TOTAL_PAY_FAILED:
      return {
        ...state,
        totalPay: {
          ...state.totalPay,
          data: initialState.totalPay.data,
          success: false,
          error: true,
          errorMessage: payload,
        },
      };
    case types.SET_RENTAL_PLAN_OPTIONS:
      return {
        ...state,
        rentalPlans: {
          ...state.rentalPlans,
          list: payload,
        },
      };
    case types.SET_RENTAL_PLAN_TO_USE:
      return {
        ...state,
        rentalPlans: {
          ...state.rentalPlans,
          toUse: {
            list: payload,
          },
        },
      };
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}
