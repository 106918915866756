import React, { useEffect, useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { CustomerForms } from '@customers/sections';
import withResponseDialog from '@hocs/withResponseDialog';
import { getQuotePath, isContractQuote } from 'utils/navigation';
import { getFormikPropsForInvalidDocsVerification } from '@customers/common/utils';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { commonCustomerCatalogs } from 'utils/customers';

const CustomerFormsWithResponseDialog = withResponseDialog(CustomerForms);

export default function CustomerEditor(props) {
  const {
    history,
    match,
    customer,
    onUpdateForm,
    quoteId,
    submitted,
    setSchema,
    schema,
    listOfInvalidsDocuments = [],
    values,
    hydrateCustomerEdition,
    hydrateEdition,
    resetDetail,
    getAllCatalogs = true,
    fetchCatalog,
  } = props;

  const isReservation = history.location.pathname.includes('reservations');
  const customerId = useMemo(() => match.params.id, [match.params.id]);
  const hasContract = isContractQuote();

  const [hasValidatedDocuments, setHasValidatedDocuments] = useState(false);
  const [hasTouchedAllFields, setHasTouchedAllFields] = useState(false);
  const [newFieldsToTouch, setNewFieldsToTouch] = useState({});
  const [hasHydrated, setHasHydrated] = useState(false);

  const { setFieldTouched, setValues, setErrors, errors } = useFormikContext();

  useEffect(() => {
    hydrateCustomerEdition({
      id: customerId,
      hydrateForm: onUpdateForm,
      onHydrate: () => setHasHydrated(true),
    });
  }, [hydrateCustomerEdition, customerId, onUpdateForm]);

  useEffect(() => {
    if (
      listOfInvalidsDocuments.length > 0 &&
      hasHydrated &&
      !hasValidatedDocuments
    ) {
      const newFormikProps = getFormikPropsForInvalidDocsVerification({
        values,
        listOfInvalidsDocuments,
        errors,
        schema,
      });
      // setSchema(newFormikProps.schema);
      setValues(newFormikProps.values);
      setErrors(newFormikProps.errors);
      setNewFieldsToTouch(newFormikProps.fieldsToTouch);

      setHasValidatedDocuments(true);
    }
  }, [
    listOfInvalidsDocuments,
    setErrors,
    setValues,
    hasHydrated,
    values,
    errors,
    schema,
    hasValidatedDocuments,
  ]);

  useEffect(() => {
    if (hasValidatedDocuments && !hasTouchedAllFields) {
      Object.entries(newFieldsToTouch).forEach(([key]) => {
        setFieldTouched(key, true);
      });

      setHasTouchedAllFields(true);
    }
  }, [
    hasValidatedDocuments,
    setFieldTouched,
    hasTouchedAllFields,
    newFieldsToTouch,
  ]);

  useEffect(() => {
    if (customer.success && isReservation && !quoteId && !hasContract) {
      window.location.href = getQuotePath('one');
    }

    if (getAllCatalogs) {
      commonCustomerCatalogs(fetchCatalog);
    }
  }, [
    customer,
    isReservation,
    quoteId,
    hasContract,
    fetchCatalog,
    getAllCatalogs,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hydrateResponseDialog = {
    open: Boolean(hydrateEdition.error),
    success: false,
  };

  const onClose = () => {
    history.push('/customers/');
    return resetDetail();
  };

  const onError = () => {
    hydrateCustomerEdition({
      id: customerId,
      hydrateForm: onUpdateForm,
      onHydrate: () => setHasHydrated(true),
    });
  };

  const formsProps = {
    ...props,
    preventChange: (isReservation || hasContract) && !submitted,
    isReservation,
    action: FORM_ACTIONS.EDIT,

    // withResponseDialog props
    resDialog: hydrateResponseDialog,
    onClose,
    onError,
  };

  return <CustomerFormsWithResponseDialog {...formsProps} />;
}
