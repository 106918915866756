import { connect } from 'react-redux';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { searchEmployees } from 'store/customers/actions';
import ContractCreator from './ContractCreator';

export default connect(
  (state) => ({
    onCancelPath: '/contracts',
    action: FORM_ACTIONS.ADD,
    responseDialog: state.contracts.responseDialog,
    contractDetails: state.contracts.contractDetails,
  }),
  { searchEmployees }
)(ContractCreator);
