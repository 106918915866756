/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useEffect, lazy, Suspense } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import {
  MaskedInput as Input,
  Switch,
  FileInput,
  Select,
  ConnectedSelect,
  Section,
  Loader,
} from '@casanova/casanova-common';
import { CheckInput } from 'components';
import {
  onlyLettersWithSpaces,
  alphaMaskWithSpaces,
} from '@casanova/casanova-common/lib/utils/masks';
import { transformForSelect } from '@casanova/casanova-common/lib/utils/transformHelpers';
import { acceptFiles } from 'utils/files';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { HOSPEDAY_TYPES } from './constants';
import config from './config';
import './AccommodationInformation.scss';

const AvalCreator = lazy(() => import('@customers/sections/AvalCreator'));

const { HOTEL, FAMILYFRIEND, RENT } = HOSPEDAY_TYPES;
const { ADD, VIEW } = FORM_ACTIONS;

const AccommodationInformation = ({
  history,
  action,
  accommodationTypeEntities,
  avalCustomer,
  avalCustomerByDetail,
  setShowResponseDialog,
  setPreventChange,
}) => {
  const [hospedy, setHospedy] = useState(null);
  const [hideAval, setHideAval] = useState(true);
  const [showAddAval, setShowAddAval] = useState(false);
  const [updateAvalInfo, setUpdateAvalInfo] = useState(false);

  const {
    touched,
    values,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const { hospedySelected, endorsement, endorsementUuid, hospedyType } = values;

  const handleChangeAccommodationType = useCallback(
    (event) => {
      const newHospedyType = event.target.value;
      const newHospedy = accommodationTypeEntities.find(
        ({ uuid }) => uuid === newHospedyType
      );
      setValues({
        ...values,
        ...config.initialValues,
        hospedyType: newHospedyType,
        hospedyIdentifier: newHospedy.identifier,
        hospedySelected: newHospedy,
      });
    },
    [values, accommodationTypeEntities]
  );

  const handleAvalChange = useCallback(({ value }) => {
    setShowAddAval(value);
    setHideAval(false);
  }, []);

  const handleChangeShow = useCallback(
    (show) => {
      if (!show) {
        setFieldValue('endorsement', false);
      }
      setShowAddAval(show);
    },
    [setFieldValue, setFieldValue]
  );

  const handleClickViewDetails = useCallback(() => {
    if (endorsementUuid) {
      setUpdateAvalInfo(true);
      setShowAddAval(true);
      setHideAval(false);
      setFieldValue('endorsement', true);
    }
  }, [endorsementUuid, setFieldValue]);

  useEffect(() => {
    setHospedy(hospedySelected || null);
  }, [hospedySelected]);

  useEffect(() => {
    if (showAddAval) {
      setPreventChange(false);
    }
  }, [showAddAval, setPreventChange]);

  useEffect(() => {
    if (endorsement && touched.endorsement) {
      setFieldTouched('endorsementUuid', true);
    }
  }, [endorsement, touched.endorsement, setFieldTouched]);

  useEffect(() => {
    const customerUuid =
      _get(avalCustomer, 'data.uuid', '') ||
      _get(avalCustomerByDetail, 'uuid', '');

    const hasCustomerData =
      !_isEmpty(avalCustomer.data) || !_isEmpty(avalCustomerByDetail);

    if (hasCustomerData && customerUuid) {
      setFieldValue('endorsementUuid', customerUuid);
    }
  }, [avalCustomer, avalCustomerByDetail, setFieldValue]);

  useEffect(() => {
    if (!_isEmpty(avalCustomerByDetail)) {
      setUpdateAvalInfo(true);
    }
  }, [avalCustomerByDetail]);

  useEffect(() => {
    if (action !== ADD && accommodationTypeEntities.length > 0) {
      const accommodation = accommodationTypeEntities.find(
        ({ uuid }) => uuid === hospedyType
      );

      if ((!hospedy || !hospedy.accommodationSubtypes) && accommodation) {
        setHospedy(accommodation);
        setFieldValue('hospedySelected', accommodation);
      }
    }
  }, [hospedyType, hospedy, action, accommodationTypeEntities, setFieldValue]);

  const disabledInput = action !== ADD;
  const showInputUrl = action !== ADD;

  const RENDER_INPUTS = {
    [HOTEL]: (
      <>
        <div className="col-md-4">
          <Input
            label="HOTEL*"
            placeholder="HOTEL*"
            name="hospedyHotelName"
            mask={onlyLettersWithSpaces(50)}
            maskPlaceholder=""
            disabled={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="NÚMERO DE RESERVACIÓN*"
            placeholder="NÚMERO DE RESERVACIÓN*"
            name="hospedyReservationNumber"
            mask={alphaMaskWithSpaces(50)}
            maskPlaceholder=""
            disabled={disabledInput}
          />
        </div>
        <div className="col-12">
          <div className="BanSwitch switch-inverted">
            <label htmlFor="hospedyConfirmation" className="mr-1">
              ¿Se confirmó la reservación?
            </label>
            <Switch id="hospedyConfirmation" name="hospedyConfirmation" />
          </div>
        </div>
      </>
    ),
    [RENT]: (
      <>
        <div className="col-md-4">
          <FileInput
            url={
              showInputUrl ? values.hospedyIdentificationLessorUrl : undefined
            }
            displayText={values.hospedyIdentificationLessor}
            type="file"
            label="IDENTIFICACIÓN ARRENDADOR*"
            placeholder="IDENTIFICACIÓN ARRENDADOR*"
            name="hospedyIdentificationLessor"
            accept={acceptFiles}
            disabled={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <FileInput
            url={showInputUrl ? values.hospedyContractUrl : undefined}
            displayText={values.hospedyContract}
            type="file"
            label="CONTRATO ARRENDAMIENTO*"
            placeholder="CONTRATO ARRENDAMIENTO*"
            name="hospedyContract"
            accept={acceptFiles}
            disabled={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <FileInput
            url={showInputUrl ? values.hospedyRelationLaborUrl : undefined}
            displayText={values.hospedyRelationLabor}
            type="file"
            label="RELACIÓN LABORAL*"
            placeholder="RELACIÓN LABORAL*"
            name="hospedyRelationLabor"
            accept={acceptFiles}
            disabled={disabledInput}
          />
        </div>
      </>
    ),
    [FAMILYFRIEND]: null,
  };

  const avalCheckView = (
    <CheckInput
      label={endorsementUuid ? 'Aval dado de alta' : 'Dar de alta aval*'}
      name="endorsement"
      placeholder={endorsementUuid ? 'Aval dado de alta' : 'Dar de alta aval*'}
      onChange={handleAvalChange}
      active={Boolean(endorsementUuid)}
      right={
        endorsementUuid && (
          <button
            type="button"
            className="btn p-0 m-0 HospedayInformation__CheckInput"
            onClick={handleClickViewDetails}
          >
            <h6 className="text-secondary right">Ver detalle</h6>
          </button>
        )
      }
    />
  );

  return (
    <Suspense fallback={<Loader show />}>
      {!hideAval && showAddAval && (
        <AvalCreator
          history={history}
          setShowResponseDialog={setShowResponseDialog}
          show={showAddAval}
          setShow={handleChangeShow}
          updateAvalInfo={updateAvalInfo}
          action={endorsementUuid ? VIEW : ADD}
          endorsementUuid={endorsementUuid}
        />
      )}

      <Section title="HOSPEDAJE">
        <div className="row">
          <div className="col-md-4">
            <ConnectedSelect
              label="TIPO DE HOSPEDAJE*"
              name="hospedyType"
              options="commons.accommodationTypeEntities"
              onChange={handleChangeAccommodationType}
              disabled={disabledInput}
            />
          </div>
          {(hospedy && RENDER_INPUTS[hospedy.identifier]) || null}
          {hospedy &&
            hospedy.accommodationSubtypes &&
            hospedy.accommodationSubtypes.length > 0 && (
              <>
                <div className="col-md-4">
                  <Select
                    label="TIPO DE RELACIÓN*"
                    name="subtype"
                    options={transformForSelect(
                      hospedy.accommodationSubtypes || [],
                      'uuid',
                      'name'
                    )}
                    disabled={disabledInput}
                  />
                </div>
                {values.subtype && (
                  <div className="col-md-4">{avalCheckView}</div>
                )}
              </>
            )}
        </div>
      </Section>
    </Suspense>
  );
};

export default AccommodationInformation;
