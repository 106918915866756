import { connect } from 'react-redux';
import _get from 'lodash/get';
import { getCatalogueOfVehicles } from 'store/reservations/actions';
import VehicleAssignment from './VehicleAssignment';

export default connect(
  (state) => ({
    vehiclesSearchList: state.reservations.contract.catalogueOfVehicles.results,
    categories: _get(state, 'reservations.filters.categories', []),
  }),
  { getCatalogueOfVehicles }
)(VehicleAssignment);
