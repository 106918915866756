import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FieldArray, useFormikContext } from 'formik';
import { OPTIONS_BAR_TYPES } from 'utils/constants';
import ListItem from './ListItem';
import './OptionsBar.scss';

const { DEFAULT, CHECK } = OPTIONS_BAR_TYPES;

const OptionsBar = ({
  options,
  name,
  multiple,
  onlyIcons,
  small,
  onChangeSelection,
  disabled,
  valueToCompare = null,
  fullWidth = false,
  type = DEFAULT,
  listItemClassName = '',
  disabledSelections = false,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleOptionChange = useCallback(
    (e, option, arrayHelpers) => {
      if (multiple) {
        if (e.target.checked) {
          arrayHelpers.push(option.value);
        } else {
          const idx = values[name].indexOf(option.value);
          arrayHelpers.remove(idx);
        }
      } else {
        setFieldValue(name, [option.value]);
      }

      if (onChangeSelection) {
        onChangeSelection(option);
      }
    },
    [name, values, setFieldValue, multiple, onChangeSelection]
  );

  const optionsBarClasses = classNames(
    'OptionsBar',
    onlyIcons && 'OptionsBar__only-icons',
    small && 'OptionsBar__small',
    disabled && 'OptionsBar__disabled',
    fullWidth && 'OptionsBar__full-with'
  );

  return (
    <div className={optionsBarClasses}>
      <ul>
        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <>
              {options.map((option) => {
                const isCheck = type === CHECK && option.check;
                const isValueToCompare =
                  valueToCompare && valueToCompare === option.value;

                const listItemClass = classNames(
                  (values[name] && values[name].indexOf(option.value) !== -1) ||
                    isValueToCompare
                    ? 'selected'
                    : '',
                  disabledSelections && 'disable-pointer',
                  listItemClassName
                );
                const labelClassName = classNames(
                  type === DEFAULT ||
                    (!isValueToCompare && !isCheck && 'default'),
                  disabledSelections && 'disable-pointer text-muted'
                );

                return (
                  <ListItem
                    key={`element_${option.value}_${option.uuid}_${option.label}`}
                    name={name}
                    onChange={handleOptionChange}
                    disabled={disabledSelections}
                    option={option}
                    options={options}
                    className={listItemClass}
                    labelClassName={labelClassName}
                    arrayHelpers={arrayHelpers}
                    onlyIcons={onlyIcons}
                    isCheck={isCheck}
                    fullWidth={fullWidth}
                  />
                );
              })}
            </>
          )}
        />
      </ul>
    </div>
  );
};

OptionsBar.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  multiple: PropTypes.bool,
  onlyIcons: PropTypes.bool,
  small: PropTypes.bool,
};

OptionsBar.defaultProps = {
  multiple: false,
  onlyIcons: false,
  small: false,
  options: [],
};

export default OptionsBar;
