import * as yup from 'yup';
import 'moment/locale/es';
import { REQUIRED_MESSAGE } from 'utils/constants';

const cashFormSchema = {
  amountCash: yup.mixed().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'cash') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup.mixed().when('diffMethods', {
      is: (diffMethods) => diffMethods === true,
      then: yup
        .mixed()
        .required(REQUIRED_MESSAGE)
        .test(
          'invalidPrice',
          'La suma de los montos no es igual al total del abono',
          function (value) {
            if (!value) {
              return true;
            }
            const validPrice = this.parent.priceIsInvalid;
            return validPrice === true;
          }
        ),
      otherwise: yup.mixed(),
    }),
    otherwise: yup.mixed(),
  }),
};

export default cashFormSchema;
