import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { fetchCatalog } from 'store/commons/actions';
import { fetchCatalog as fetchVehicleCatalog } from 'store/catalogs/actions';
import {
  fetchVehicles,
  closeResponseDialog,
  fetchVehicleDetails,
} from 'store/vehicles/actions';
import ViewVehicleDetail from './ViewVehicleDetail';

export default compose(
  connect(
    (state) => ({
      responseDialog: state.vehicles.responseDialog,
      loader: state.commons.loader,
      vehicleDetails: state.vehicles.vehicleDetails,
      vehicles: state.vehicles.vehiclesList,
    }),
    {
      closeResponseDialog,
      fetchVehicleDetails,
      fetchCatalog,
      fetchVehicleCatalog,
      fetchVehicles,
    }
  )
)(ViewVehicleDetail);
