import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import _zipObject from 'lodash/zipObject';
import { CellText } from 'components/ColumnsCells';

import { Table, TableNavigation } from '@casanova/casanova-common';
import TableBottom from 'components/TableBottom';

import i18n from '@i18n';
import formatter from '@reports/SearchReports/formatter';
import { DOWNLOAD_REPORTS } from 'utils/roles/permissions/reportsPermissions';
import { REPORTS_ROWS_PER_PAGE } from '../../common/constants';

export default function ListOfReports({
  reportsList,
  setListPage,
  cleanReportsList,
  downloadReports,
  setHasResetList,
}) {
  const { values } = useFormikContext();
  const { reportType } = values;
  const [dataReports, setDataReports] = useState([]);
  const [dataPaginator, setDataPaginator] = useState([]);
  const [initPage, setInitPage] = useState(1);
  const [lastPage, setLastPage] = useState(REPORTS_ROWS_PER_PAGE);
  const [columns, setColumns] = useState([]);

  const { data = [] } = reportsList;

  const handleDownload = () => {
    const params = formatter(values);
    downloadReports({ params });
  };

  useEffect(() => {
    setInitPage(1);
    setLastPage(REPORTS_ROWS_PER_PAGE);
    cleanReportsList();
    setHasResetList(true);
    mapDataReports();
  }, [reportType, reportsList]);

  useEffect(() => {
    setDataReports([]);
  }, [reportType]);

  const mapDataReports = () => {
    const res = data.data ? data.data : [];

    if (res.length > 0) {
      const dataMap = data.data;

      const indexList = data.data[0].map((item) => {
        const newItem = {
          label: item.toUpperCase().replaceAll('_', ' '),
          field: item,
          align: 'center',
          renderer: CellText,
        };

        return newItem;
      });

      const keys = indexList.map((item) => item.field);
      const reportDataToShow = dataMap.map((item) => {
        const obj = _zipObject(keys, item);

        return obj;
      });

      const totalPages = Math.ceil(
        (reportDataToShow.length - 1) / REPORTS_ROWS_PER_PAGE
      );

      const totalElements = reportDataToShow.length - 1;

      const paginationConfig = {
        params: {
          page: 0,
          size: REPORTS_ROWS_PER_PAGE,
        },
        searchPattern: '',
        filters: [],
        totalPages,
        totalElements,
        results: {
          page: 0,
          list: reportDataToShow.slice(1),
          loading: false,
          success: false,
          error: false,
        },
      };

      setDataPaginator(paginationConfig);
      setDataReports(reportDataToShow.slice(1));
      setColumns(indexList);
    } else {
      setDataReports([]);
    }
  };

  const onChangePaginator = (page) => {
    const newData = {
      ...dataPaginator,
      params: {
        page,
        size: REPORTS_ROWS_PER_PAGE,
      },
      results: {
        ...dataPaginator.results,
        page,
      },
    };

    setDataPaginator(newData);
    setInitPage(1 + page * REPORTS_ROWS_PER_PAGE);
    setLastPage((page + 1) * REPORTS_ROWS_PER_PAGE);
  };

  return (
    <div className="row mt-5">
      <div className="col-12">
        <Table
          rowClassName="row-clickable"
          emptyMessage={i18n('ERROR__COMMONS__NOT_FOUND')}
          dataList={dataReports.slice(initPage - 1, lastPage)}
          columns={columns}
          withEllipsis
        />

        <div className="row">
          <div className="col-12 col-xl-7 table-paginator text-right d-flex justify-content-between align-items-center">
            {dataReports.length > 0 && (
              <TableNavigation
                search={dataPaginator}
                onChange={onChangePaginator}
              />
            )}
          </div>
        </div>

        <TableBottom
          search={reportsList}
          onChange={setListPage}
          onClick={handleDownload}
          textButton={i18n('REPORTS__SEARCH_REPORT__LIST__DOWNLOAD')}
          showButton={dataReports.length > 0}
          actionPermission={DOWNLOAD_REPORTS}
        />
      </div>
    </div>
  );
}
