import {
  CLOSE_RESPONSE_DIALOG,
  POST_AVAILABLE_USER,
  POST_AVAILABLE_USER_SUCCESSED,
  POST_AVAILABLE_USER_FAILED,
} from './action-types';

export function closeResponseDialog() {
  return { type: CLOSE_RESPONSE_DIALOG };
}

export function postAvailableUser(payload) {
  return { type: POST_AVAILABLE_USER, payload };
}

export function postAvailableUserSuccessed(payload) {
  return { type: POST_AVAILABLE_USER_SUCCESSED, payload };
}

export function postAvailableUserFailed(payload) {
  return { type: POST_AVAILABLE_USER_FAILED, payload };
}
