import PropTypes from 'prop-types';
import { usePDF } from '@react-pdf/renderer';
import { Loader, ActionModal, Icons } from '@casanova/casanova-common';
import PDFModal from 'components/PDFModal';
import i18n from '@i18n';
import AccountStatementPDF from './AccountStatementPDF';

import './AccountStatement.scss';

export default function CustomerAccountStatement({
  show,
  onClose,
  customerName,
  customerNumber,
  statementList,
  movementTypes,
  defineUrl
}) {
  const [instance] = usePDF({
    document: (
      <AccountStatementPDF
        customerName={customerName}
        customerNumber={customerNumber}
        statementList={statementList}
        movementTypes={movementTypes}
      />
    ),
  });

  const { loading, error, url = '' } =
    // eslint-disable-next-line eqeqeq
    defineUrl != null || defineUrl != undefined
      ? {
          loading: false,
          error: false,
          url: defineUrl,
        }
      : instance;

  if (!show) return null;

  if (loading && !error && !url) return <Loader show />;

  if (error)
    return (
      <ActionModal
        open
        icon={<Icons.IcoUserAlert />}
        title={i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE')}
        message={i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE')}
        actionLabel={i18n('COMMONS__CLOSE__TEXT')}
        modalType="confirmation"
        closeButton
        onClose={onClose}
        onAction={onClose}
      />
    );

  return <PDFModal show={show} onClose={onClose} url={url || ''} />;
}

CustomerAccountStatement.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customerName: PropTypes.string.isRequired,
  customerNumber: PropTypes.string.isRequired,
};
