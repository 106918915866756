import React, { useEffect, useCallback, useRef } from 'react';
import { Formik, yupToFormErrors, validateYupSchema } from 'formik';
import _get from 'lodash/get';
import {
  MaskedInput as Input,
  DatePickerV2,
  NumberInput,
  Select,
  FileInput,
  PlainInput,
  Switch,
  TextAreaInput,
} from '@casanova/casanova-common';
import * as additionalsValidation from 'utils/validations/additionals';
import { verification as verificationMeta } from 'services/vehicles/additionals';
import { CREATE_VEHICLE_VERIFICATION } from 'utils/roles/permissions';
import PreventChange from 'components/PreventChange';
import UserInfo from 'components/UserInfo';

import AdditionalWrapper from '@additionals/components/AdditionalModals';
import { MINIMUM_DATE, MAXIMUM_DATE } from '@vehicles/common/constants';
import { TEXT_AREA_NOT_ALLOWED_CHARACTERS } from 'utils/validations';
import { alphaMask, alphaMaskWithSpaces } from 'utils/masks';
import { withAdditionalCollapse } from '../AdditionalCollapse';
import { SelectWithOptions } from '../components';

function Verification(props) {
  const {
    name,
    vehicleId,
    fetchCatalog,
    getAdditional,
    editable,
    plateOptions,
    data,
    isNew,
    saveAdditional,
    vehicle,
    addNew,
    show,
  } = props;
  const formRef = useRef(null);
  useEffect(() => {
    if (!show && formRef) {
      formRef.current.resetForm(verificationMeta.empty);
    }
  }, [show]);

  useEffect(() => {
    if (vehicleId) {
      getAdditional({
        name,
        data: vehicleId,
        withFiles: ['receipt', 'certificate'],
      });
      fetchCatalog('hologram-type');
      fetchCatalog('verification-status');
      fetchCatalog('verification-type');
    }
  }, [name, fetchCatalog, vehicleId, getAdditional]);

  const handleFormik = useCallback(
    (values) => {
      const folio = _get(data, 'folio');
      saveAdditional({
        name,
        data: {
          ...values,
          receipt: values.verificationReceipt,
          vehicleId,
          serialNumber: vehicle.serialNumber,
          plateOptions,
        },
        forceCreate: folio && folio !== values.folio,
        addNew,
      });
    },
    [addNew, saveAdditional, name, vehicleId, data, plateOptions, vehicle]
  );

  const handleValidation = (values) => {
    try {
      validateYupSchema(values, additionalsValidation.verification, true, {
        vehicle,
        plateOptions,
      });
    } catch (error) {
      return yupToFormErrors(error);
    }
    return {};
  };

  return (
    <div className="container-fluid">
      <Formik
        initialValues={
          addNew ? verificationMeta.empty : data || verificationMeta.empty
        }
        validate={handleValidation}
        validateOnChange={false}
        enableReinitialize
        onSubmit={handleFormik}
        innerRef={formRef}
      >
        {(formikProps) => {
          const { handleSubmit, initialValues, values, dirty } = formikProps;
          return (
            <PreventChange preventChange={dirty && editable}>
              <AdditionalWrapper
                roleVerifierIdentifier={CREATE_VEHICLE_VERIFICATION}
                handleSubmit={handleSubmit}
                formikProps={formikProps}
                emptyValues={verificationMeta.empty}
                extraProps={props}
              >
                <div className="row">
                  {!isNew && !addNew && (
                    <div className="col-12 col-md-4">
                      <PlainInput
                        name="verificationStatus"
                        disabled
                        label="Estatus"
                        limit={10}
                      />
                    </div>
                  )}
                  <div className="col-12 col-md-4">
                    <Select
                      name="plate"
                      label="Numero de Placa*"
                      options={plateOptions}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <DatePickerV2
                      name="dateProcess"
                      label="Fecha de trámite*"
                      placeholder="Fecha de trámite*"
                      initialValue={initialValues.dateProcess}
                      disabled={!editable && !isNew}
                      minimumDate={MINIMUM_DATE}
                      maximumDate={MAXIMUM_DATE}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Input
                      mask={alphaMaskWithSpaces(200)}
                      maskPlaceholder=""
                      name="amountFine"
                      label="Multa y Monto*"
                      placeholder="Multa y Monto*"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <DatePickerV2
                      name="nextProcess"
                      label="Próxima Verificación*"
                      placeholder="Próxima Verificación*"
                      initialValue={initialValues.nextProcess}
                      disabled={!editable && !isNew}
                      minimumDate={MINIMUM_DATE}
                      // maximumDate={MAXIMUM_DATE}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="verificationType"
                      label="Tipo de Verificación*"
                      options="catalogs.verificationType"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="hologram"
                      label="Tipo de Holograma*"
                      options="catalogs.hologramType"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <NumberInput
                      name="amountPaid"
                      label="Importe Pagado*"
                      decimals={2}
                      max={99_999.99}
                      maskSuffix=" mxn"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Input
                      mask={alphaMask(30)}
                      maskPlaceholder=""
                      name="folio"
                      label="Folio*"
                      placeholder="Folio*"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FileInput
                      url={
                        !isNew
                          ? initialValues.verificationReceiptUrl
                          : undefined
                      }
                      displayText={initialValues.initialVerificationReceipt}
                      type="file"
                      label="Comprobante de pago*"
                      placeholder="Comprobante de pago*"
                      name="verificationReceipt"
                      accept="application/pdf"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FileInput
                      url={!isNew ? values.certificateUrl : undefined}
                      displayText={values.certificate}
                      type="file"
                      label="Papeleta*"
                      placeholder="Papeleta*"
                      name="certificate"
                      accept="application/pdf"
                      disabled={!editable && !isNew}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 switch-inverted">
                    <label htmlFor="putSign">¿Se colocó la verificación?</label>
                    <Switch name="putSign" disabled={!editable && !isNew} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <TextAreaInput
                      label="Observaciones"
                      placeholder="Observaciones"
                      name="verificationRemarks"
                      disabled={!editable && !isNew}
                      regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS}
                      maxLength={200}
                    />
                  </div>
                </div>
                <UserInfo {...data} />
              </AdditionalWrapper>
            </PreventChange>
          );
        }}
      </Formik>
    </div>
  );
}
export default withAdditionalCollapse({ title: 'Verificación' })(Verification);
