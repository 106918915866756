import _get from 'lodash/get';

export const passportTransformer = (passportData = {}) => ({
  passportKey: _get(passportData, 'identityKey', ''),
  passportYearIssue: _get(passportData, 'issuedYear', ''),
  passportValidity: _get(passportData, 'validityYear', ''),
  passportCountry: _get(passportData, 'country.uuid', ''),
  passportCoverFront: _get(passportData, 'frontScanFile.originalName'),
  passportCoverFrontUrl: _get(passportData, 'frontScanFile.presignedUrl'),
  passportCoverBack: _get(passportData, 'backScanFile.originalName'),
  passportCoverBackUrl: _get(passportData, 'backScanFile.presignedUrl'),
});
