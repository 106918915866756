import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { Select } from '@casanova/casanova-common';

const withListOptions = (Component) => ({
  options,
  fullKey,
  transformer,
  selectClassName,
  placeholder,
  arrayList,
  isArrayList,
  ...props
}) => {
  const data = useSelector((state) => _get(state, options, {}));
  const listOfOptions = useMemo(() => {
    const optionsList = fullKey ? data : data.list || [];
    return transformer ? transformer(optionsList) : optionsList;
  }, [data, fullKey, transformer]);

  return (
    <Component
      {...props}
      placeholder={placeholder}
      selectClassName={selectClassName}
      options={isArrayList ? arrayList : listOfOptions}
    />
  );
};
export default withListOptions(Select);
