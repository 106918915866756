import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Button, withForm, Loader } from '@casanova/casanova-common';
import Modal from '@casanova/casanova-common/lib/Modal';
import i18n from '@i18n';
import { VehicleDetail } from '@vehicles/sections';
import { Blank } from 'components';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { config as viewConfig, editConfig } from './config';
import './ViewVehicleDetail.scss';
import { EDIT_VEHICLE_GENERAL_INFORMATION } from 'utils/roles/permissions';
import RoleVerifier from 'components/RoleVerifier';
import { useFormikContext } from 'formik';

const { VIEW, EDIT } = FORM_ACTIONS;

export default function ViewVehicleDetail(props) {
  const {
    show = true,
    setShow,
    loader = false,
    vehicleUuid = '',
    fetchVehicleDetails,
    closeResponseDialog,
    responseDialog,
    vehicleDetails,
    fetchVehicles,
    vehicles,
  } = props;

  const [action, setAction] = useStateWithCallbackLazy(VIEW);
  const [hide, setHide] = useState(true);
  const [shouldDisabledSubmit, setShouldDisabledSubmit] = useState(false);

  const { success: successVehicleDetails = false } = vehicleDetails;

  const { values } = useFormikContext();

  const { params, searchPattern, filters } = vehicles;

  const sold = values.status === 'e73bd123-a362-4ead-a4fe-b1c1345e04b3';

  const handleClickEdit = useCallback(() => {
    setAction(EDIT);
  }, []);

  const handleCancel = useCallback(
    ({ setFieldValue, setFieldError, initialValues } = {}) => {
      setAction(VIEW, () => {
        setFieldValue('warehouse', initialValues.warehouse);
        setFieldError('warehouse', null);
      });
    },
    []
  );

  const handleBeforeSubmit = useCallback(() => {
    setHide(true);
  }, []);

  const handleSuccess = useCallback(() => {
    setHide(false);
    setAction(VIEW);
    setShow(false);
    fetchVehicles({ params, filters, searchPattern });
  }, []);

  const handleClose = useCallback(() => {
    setHide(false);
    fetchVehicles({ params, filters, searchPattern });
  }, []);

  const handleCloseModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  useEffect(() => {
    if (vehicleUuid && show) {
      fetchVehicleDetails({ uuid: vehicleUuid });
    }
  }, [fetchVehicleDetails, vehicleUuid, show]);

  useEffect(() => {
    if (successVehicleDetails && action === VIEW) {
      setHide(false);
    }
  }, [successVehicleDetails]);

  const config = useMemo(() => {
    const finalConfig = action === EDIT ? editConfig : viewConfig;
    return {
      ...finalConfig,
      onBeforeSubmit: handleBeforeSubmit,
      onSuccess: handleSuccess,
      onClose: handleClose,
    };
  }, [action, handleBeforeSubmit, handleSuccess, handleClose]);

  const VehicleDetailWithForm = withForm({ config })(VehicleDetail)({
    ...props,
    vehicleUuid,
    layout: false,
    left: <Blank />,
    action,
    onCancel: handleCancel,
    closeResponseDialog: () => {
      if (responseDialog.open) {
        // This reset the modal state
        handleSuccess();
      }
      closeResponseDialog();
    },
    disabledSubmit: shouldDisabledSubmit,
    shouldDisabledSubmit,
    setShouldDisabledSubmit,
  });

  return (
    <>
      <Loader show={hide && !loader && show} />
      <Modal
        open={!loader && show}
        header={null}
        footer={null}
        className="ViewVehicleDetail"
        topContainerClassName={`${hide ? 'd-none' : ''}`}
        contentClassName="px-4 py-4 container"
        closeButton={action === VIEW}
        onClose={handleCloseModal}
        bigModal
      >
        {VehicleDetailWithForm}
        <RoleVerifier identifier={EDIT_VEHICLE_GENERAL_INFORMATION}>
          {action !== EDIT && !sold && (
            <div className="row ViewVehicleDetail__BottomBarContainer">
              <div className="col" />
              <div className="col-4">
                <Button block onClick={handleClickEdit}>
                  {i18n('COMMONS__EDIT')}
                </Button>
              </div>
            </div>
          )}
        </RoleVerifier>
      </Modal>
    </>
  );
}

ViewVehicleDetail.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  loader: PropTypes.bool,
  vehicleUuid: PropTypes.string,
  fetchVehicleDetails: PropTypes.func,
  closeResponseDialog: PropTypes.func,
  responseDialog: PropTypes.shape({
    open: PropTypes.bool,
  }),
};
