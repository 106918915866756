import {
  CONTRACTS_RD_MODULE,
  VIEW_CONTRACTS_RD,
} from 'utils/roles/permissions/contractsRDPermissions';
import { CREATE_RESERVATION } from 'utils/roles/permissions/reservationsPermissions';
import {
  CONTRACT_RD_PATH,
  CONTRACT_RD_CONTRACT_PATH,
  CONTRACT_RD_LEASING_PATH,
  CONTRACT_RD_PROMISSORY_NOTE_PATH,
  CONTRACT_RD_CONFIRM_PATH,
  CONTRACT_RD_LEASING_SIGNATURES_PATH,
  CONTRACT_RD_PROMISSORY_NOTE_SIGNATURES_PATH,
  CONTRACT_RD_DELIVERY_PATH,
  CONTRACT_RD_DELIVERY_VEHICLE_PARTS_PATH,
  CONTRACT_RD_DELIVERY_INFORMATION_AND_FIRM_PATH,
  CONTRACT_RD_NOT_QUOTE_RESERVATION_PATH,
  CONTRACT_RD_EXTEND_CONTRACT_PATH,
  CONTRACT_RD_EXTEND_CONTRACT_SIGN_PATH,
  CONTRACT_RD_EXTEND_CONTRACT_PAYMENT_PATH,
  CONTRACT_RD_CLOSED_CONTRACT_PATH,
  CONTRACT_RD_UNIT_INVENTORY,
} from 'utils/roles/paths';

export function dailyRentContractsAuth(permissions) {
  const allowedModules = [];

  if (permissions.includes(VIEW_CONTRACTS_RD)) {
    allowedModules.push(CONTRACTS_RD_MODULE);
  }

  return allowedModules;
}

export function dailyRentContractsPathsAuth(permissions) {
  const allowedPaths = [];

  if (permissions.includes(VIEW_CONTRACTS_RD)) {
    allowedPaths.push(CONTRACT_RD_UNIT_INVENTORY);
    allowedPaths.push(CONTRACT_RD_EXTEND_CONTRACT_PATH);
    allowedPaths.push(CONTRACT_RD_EXTEND_CONTRACT_SIGN_PATH);
    allowedPaths.push(CONTRACT_RD_EXTEND_CONTRACT_PAYMENT_PATH);
    allowedPaths.push(CONTRACT_RD_CLOSED_CONTRACT_PATH);
    allowedPaths.push(CONTRACT_RD_PATH);
    allowedPaths.push(CONTRACT_RD_NOT_QUOTE_RESERVATION_PATH);
  }

  if (permissions.includes(CREATE_RESERVATION)) {
    allowedPaths.push(CONTRACT_RD_CONFIRM_PATH);
    allowedPaths.push(CONTRACT_RD_CONTRACT_PATH);
    allowedPaths.push(CONTRACT_RD_LEASING_PATH);
    allowedPaths.push(CONTRACT_RD_PROMISSORY_NOTE_PATH);
    allowedPaths.push(CONTRACT_RD_LEASING_SIGNATURES_PATH);
    allowedPaths.push(CONTRACT_RD_PROMISSORY_NOTE_SIGNATURES_PATH);
    allowedPaths.push(CONTRACT_RD_DELIVERY_PATH);
    allowedPaths.push(CONTRACT_RD_DELIVERY_VEHICLE_PARTS_PATH);
    allowedPaths.push(CONTRACT_RD_DELIVERY_INFORMATION_AND_FIRM_PATH);
  }
  return allowedPaths;
}
