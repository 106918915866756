import { takeLatest } from 'redux-saga/effects';
import types from './actions-types';
import {
  getReportsListSaga,
  getTypesOfReportsSaga,
  downloadReportsSaga,
} from './sagas';

export default function* watchers() {
  yield takeLatest(types.GET_LIST, getReportsListSaga);
  yield takeLatest(types.GET_TYPES_OF_REPORTS, getTypesOfReportsSaga);
  yield takeLatest(types.DOWNLOAD_REPORTS, downloadReportsSaga);
}
