import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { fetchVehicleLoans, setPageVehicleLoans } from 'store/vehicles/actions';
import VehicleLoans from './VehicleLoans';

export default compose(
  connect(
    (state) => ({
      responseDialog: state.vehicles.responseDialog,
      showTitle: false,
      vehicleLoans: state.vehicles.vehicleLoans,
      vehicleDetails: state.vehicles.vehicleDetails.data,
    }),
    { fetchVehicleLoans, setPageVehicleLoans }
  )
)(VehicleLoans);
