import qs from 'qs';
import { getOptionsAuth } from 'services/auth';
import { sendRawRequest } from '@casanova/casanova-common/lib/utils/service';
import transformers from './transformers';

const DEFAULT_CATALOG_PATH = 'catalog';
const NO_FORMAT_CATALOGS = ['pricequotes', 'vehicle', 'customer'];

const { REACT_APP_API_URL } = process.env;

export async function fetchCatalog(
  catalogId,
  catalogPath = null,
  params = {},
  options = {},
  catalogName = null
) {
  const queryParams = `?sort=name&${qs.stringify(params)}&isEnabled=true`;
  const url = `${
    catalogPath !== null ? catalogPath : DEFAULT_CATALOG_PATH
  }/v1/${catalogId}${queryParams}`;

  const response = await sendRawRequest(url, options, getOptionsAuth());

  if (
    NO_FORMAT_CATALOGS.indexOf(catalogPath) !== -1 &&
    response &&
    response.data &&
    response.data.data
  ) {
    return response.data.data.content;
  }

  return transformers.pageToList(response.data, catalogName || catalogId);
}

export async function getDataByZipCode(code, options = {}) {
  const url = `${DEFAULT_CATALOG_PATH}/v1/suburbs/search/zipCode?code=${code}`;
  const response = await sendRawRequest(url, options);
  return transformers.pageToZipCode(response.data);
}

export const downloadFileFromBase64 = (contentType, base64Data, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadFileFromUrl = (fileUrl, fileName) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = fileUrl;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const getFileName = (url, fileExtension) => {
  const fileName = url
    .split('/')
    .find((ele) => ele.includes(`.${fileExtension}`));

  return fileName;
};

export const fetchAndDownloadFileFromUrl = (url, fileExtension, fileName) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => URL.createObjectURL(blob))
    .then((blobUrl) => {
      const fileNameFromUrl = getFileName(url, fileExtension);
      downloadFileFromUrl(blobUrl, fileName || fileNameFromUrl);
    });
};

export async function fileRequest(
  path,
  options = {},
  data,
  method = 'get',
  contentType = 'application/octet-stream'
) {
  const headers = options.headers || new Headers();
  headers.append('content-type', contentType);

  if (options.authToken) {
    headers.append('authorization', `Bearer ${options.authToken}`);
  }

  const url = `${REACT_APP_API_URL}/${path}`;
  const response = await fetch(url, {
    method,
    headers,
    body: data ? JSON.stringify(data) : undefined,
  });
  const fileBlob = await response.blob();

  const fileName = response.headers.get('Content-Disposition').split('=')[1];
  const fileUrl = URL.createObjectURL(fileBlob);

  return { file: fileBlob, fileUrl, fileName };
}
