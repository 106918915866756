import PropTypes from 'prop-types';
import { Card } from 'components';
import { number2mxn } from 'utils/currency';

const ChargeCard = ({
  thumbnail = '',
  groupName = '',
  name = '',
  remarks = '',
  price = 0,
}) => (
  <Card noStatus className="p-3">
    <div className="row">
      <div className="col-2">
        {thumbnail && (
          <img
            src={`${thumbnail}`}
            alt={`${groupName} ${name}`}
            className="ExtraChargesDetails__Card--image"
          />
        )}
      </div>
      <div className="col-6 d-flex flex-column justify-content-center">
        <h6>{groupName}</h6>
        <p>{remarks || 'Sin comentarios'}</p>
      </div>
      <div className="col-4 d-flex flex-column justify-content-center">
        <h6>MXN {number2mxn(price)}</h6>
        <p className="text-muted text-uppercase">{name}</p>
      </div>
    </div>
  </Card>
);

ChargeCard.propTypes = {
  thumbnail: PropTypes.string,
  groupName: PropTypes.string,
  name: PropTypes.string,
  remarks: PropTypes.string,
  price: PropTypes.number,
};

export default ChargeCard;
