import RD2 from '@images/rd2.png';
import RD2P2 from '@images/rd2_p2.png';
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
  },
  section: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingTop: 40,
  },
  image_width: {
    width: '80%',
    zIndex: 9,
  },
  image_width_p2: {
    width: '100%',
  },
  client_name: {
    position: 'absolute',
    top: 157,
    left: 170,
    fontSize: 9,
  },
  number_phone: {
    position: 'absolute',
    top: 169,
    left: 170,
    fontSize: 9,
  },
  number_phone_fixed: {
    position: 'absolute',
    top: 169,
    left: 390,
    fontSize: 9,
  },
  email: {
    position: 'absolute',
    top: 180,
    left: 180,
    fontSize: 9,
  },
});

const DocumentRd2 = ({ name, number, email }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image style={styles.image_width} src={RD2} />
      </View>
      <Text style={styles.client_name}>{name}</Text>
      <Text style={styles.number_phone}>{number}</Text>
      {/* <Text style={styles.number_phone_fixed}>{}</Text> */}
      <Text style={styles.email}>{email}</Text>
    </Page>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image style={styles.image_width_p2} src={RD2P2} />
      </View>
    </Page>
  </Document>
);

export default DocumentRd2;
