import qs from 'qs';
import { cleanParams } from '@casanova/casanova-common/lib/utils/requests';
import { sendCustomRequest } from '@casanova/casanova-common/lib/utils/service';
import { APIError } from '@casanova/casanova-common/lib/utils/exceptions';
import { customerRoot, priceQuoteRootV1 } from '../roots';
import transformer from './transformer';

const ROOT = 'pricequotes/v1';

export async function getDailyRentContracts(params) {
  const queryParams = `?${qs.stringify(cleanParams(params))}`;
  const endpoint = `${ROOT}/contracts/catalog${queryParams}`;
  const response = await sendCustomRequest(endpoint, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { data = { content: [] } } = response.data;

  data.content = transformer(data.content);

  const result = data.content;
  result.forEach((el, i) => {
    result[i].contractNumber = `Z${el.contractNumber.padStart(8, '0')}`;
  });

  return data;
}

export async function closeRDContract(contractUuid, validCodeRequest) {
  const url = `${priceQuoteRootV1}/contracts/${contractUuid}/close`;
  const response = await sendCustomRequest(url, {}, validCodeRequest, 'PUT');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function cancelRDContract(contractUuid, validCodeRequest) {
  const url = `${priceQuoteRootV1}/contracts/${contractUuid}/cancel`;
  const response = await sendCustomRequest(url, {}, validCodeRequest, 'PUT');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getCloseDetailsOfCloseRDContract(contractUuid) {
  const url = `${priceQuoteRootV1}/contracts/${contractUuid}/close-details`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function saveContractRefund(request) {
  const url = `${priceQuoteRootV1}/contracts/refund`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  return response;
}

export async function saveContractPay(request) {
  const url = `${priceQuoteRootV1}/contracts/pay`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  return response;
}

export async function getTotalPay(request) {
  const url = `${customerRoot}/billing-details/movement/pay/contract/${request.uuid}`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  return response;
}

export async function postReservationExtendContract(body) {
  const url = `${priceQuoteRootV1}/contracts/extend`;
  const response = await sendCustomRequest(url, {}, body, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getExtendedContracts(uuid) {
  const url = `${priceQuoteRootV1}/contracts/${uuid}/extend`;
  const response = await sendCustomRequest(url, {}, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function finishExtendContract(rcUuid, eUui) {
  const url = `${priceQuoteRootV1}/contracts/extend/${rcUuid}/${eUui}`;
  const response = await sendCustomRequest(url, {}, undefined, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function reviewExpiredContracts() {
  const url = `${priceQuoteRootV1}/contracts/review-expired-contracts`;
  const response = await sendCustomRequest(url, {}, undefined, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  return response.data.data;
}
