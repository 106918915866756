import i18n from '@i18n';
import { REQUIRED_MESSAGE } from 'utils/constants';

const yup = require('yup');
require('yup-password')(yup);

export const activationSchema = yup.object().shape({
  password: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(8, 'Debe contener mínimo 8 dígitos')
    .minUppercase(1, 'Debe contener una letra mayúscula')
    .minLowercase(1, 'Debe contener una letra minúscula')
    .minNumbers(1, 'Debe contener un número'),
  passwordConfirmation: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .oneOf(
      [yup.ref('password'), null],
      i18n('ACTIVATION__PASSWORD__NOT__MATCH')
    ),
});

export const activationInitialValues = {
  password: '',
  passwordConfirmation: '',
};
