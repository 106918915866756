import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';

const CardSection = ({
  children,
  title,
  noStatus,
  status,
  padding,
  noTitle,
  className,
  borderDirection = 'top',
  onClick,
  titleClassName = '',
  upperCase = false,
}) => {
  const cls = classNames(
    `CardSection`,
    noStatus && 'CardSection--without-status',
    !noStatus && status && `CardSection__${borderDirection}--${status}`,
    padding,
    className
  );
  return (
    <div className={cls} onClick={onClick}>
      {!noTitle && (
        <h6 className={`CardSection__title ${titleClassName}`}>
          {upperCase ? title.toUpperCase() : title}
        </h6>
      )}
      {children}
    </div>
  );
};

CardSection.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string,
};

CardSection.defaultProps = {
  status: 'success',
  title: '',
};

export default CardSection;
