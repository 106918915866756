import React from 'react';
import Checkbox from '@casanova/casanova-common/lib/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { selectGPS, deleteSelectGPS } from 'store/catalogs/actions';
import './index.scss';

const Section = ({ title, description, className }) => (
  <div className={className}>
    <h6>{title}</h6>
    <p>{description}</p>
  </div>
);

const CardGps = ({
  data,
  removeType,
  gps,
  modeRemove,
  reinstallType,
  dateOperation,
}) => {
  const dispatch = useDispatch();
  const selectGps = useSelector((state) => state.catalogs.selectGPS);

  const toggleCheckbox = (label) => {
    if (selectGps.filter((e) => e.gps.tag === label.gps.tag).length > 0) {
      dispatch(deleteSelectGPS(label));
    } else {
      dispatch(selectGPS(label));
    }
  };

  const createCheckbox = (label) => (
    <Checkbox
      label={label}
      onChange={toggleCheckbox}
      data={{ ...gps, checked: true, dateOperation }}
      classDiv="text-center"
      disabled={modeRemove}
      checked={modeRemove}
    />
  );

  return (
    <div className="card-gps mb-3">
      <div className="row">
        <div className="col-md-2 text-center my-auto">
          <p className="gps-title">GPS</p>
        </div>
        <div
          className={`${
            removeType || reinstallType ? 'col-md-9' : 'col-md-10'
          } bg-danger`}
        >
          <div className="row">
            <Section
              className="tag col-md-4"
              extraData={data}
              title="TAG GPS"
              description={data.gps.tag}
            />
            <Section
              className="tag col-md-4"
              extraData={data}
              title="IMEI GPS"
              description={data.gps.imei}
            />
            <Section
              className="tag col-md-4"
              extraData={data}
              title="LUGAR  DE INSTALACIÓN"
              description={data.installationPlace}
            />
          </div>
          <div className="dos">
            {data.remarks && (
              <Section
                title="OBSERVACIONES"
                extraData={data}
                description={data.remarks}
              />
            )}
          </div>
        </div>
        {(removeType || reinstallType) && (
          <div className="col-md-1 my-auto">{createCheckbox()}</div>
        )}
      </div>
    </div>
  );
};

export default CardGps;
