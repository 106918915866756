import React, { useEffect, useCallback } from 'react';
import cln from 'classnames';

import _isEmpty from 'lodash/isEmpty';
import {
  TableNavigation,
  Button,
  Table,
  withForm,
} from '@casanova/casanova-common';
import { RoleVerifier } from 'components';
import useCleanModule from 'hooks/useCleanModule';

import { validateRolePermissions } from 'utils/roles';
import {
  VIEW_CUSTOMER_DETAILS,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_USER,
  CREATE_CUSTOMER_VALIDATED,
  CREATE_CUSTOMER_SPECIAL,
  CREATE_NEW_CUSTOMER,
} from 'utils/roles/permissions/customerPermissions';

import { config } from './config';
import columns from './columns';

export const CustomerList = ({
  getList,
  setPage,
  // getCustomersListFilters,
  reset,
  customersList,
  history,
  onUpdateForm,
}) => {
  const { params, searchPattern, filters, results } = customersList;

  useCleanModule({ module: 'customers' });

  const handleAddClick = useCallback(() => {
    history.push('/customers/add');
  }, [history]);

  const handleClick = ({ naturalPersonUuid }) => {
    if (validateRolePermissions(VIEW_CUSTOMER_DETAILS)) {
      history.push(`/customers/${naturalPersonUuid}/detail`);
    }
  };

  useEffect(() => {
    getList({ params, filters, searchPattern });
  }, [getList, params, filters, searchPattern]);

  useEffect(() => {
    reset({ exceptions: ['customersList'] });

    // getCustomersListFilters();
  }, []);

  useEffect(() => {
    if (!_isEmpty(params)) {
      onUpdateForm(params);
    }
  }, [onUpdateForm, params]);

  return (
    <div>
      <Table
        additionalHeader
        rowClassName={cln(
          validateRolePermissions(VIEW_CUSTOMER_DETAILS) && 'row-clickable'
        )}
        rowClick={handleClick}
        emptyMessage="No se encontraron resultados"
        dataList={results.list}
        columns={columns}
      />
      <div className="row">
        <div className="col-12 col-xl-7 table-paginator text-right d-flex justify-content-between align-items-center">
          <TableNavigation search={customersList} onChange={setPage} />
        </div>
        <div className="col-12 col-xl-5">
          <div className="row">
            <div className="offset-6 col-6">
              <RoleVerifier
                identifier={[
                  // CREATE_CUSTOMER,
                  // CREATE_CUSTOMER_USER,
                  // CREATE_CUSTOMER_VALIDATED,
                  // CREATE_CUSTOMER_SPECIAL,
                  CREATE_NEW_CUSTOMER,
                ]}
              >
                <Button block onClick={handleAddClick}>
                  Nuevo
                </Button>
              </RoleVerifier>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withForm({ config })(CustomerList);
