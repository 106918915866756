import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { VEHICLES_STATUS } from '@vehicles/common/constants';

export default function useVehicle() {
  const vehicleStatus = useSelector((state) =>
    _get(state, 'vehicles.vehicleData.status', '')
  );

  const isSold = useMemo(() => vehicleStatus === VEHICLES_STATUS.SOLD, [
    vehicleStatus,
  ]);

  return { isSold };
}
