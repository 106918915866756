import * as yup from 'yup';
import i18n from '@i18n';
import { setParamsListOfReservations } from 'store/reservations/actions';
import { getBrach, getRoleName } from '../../../services/auth';

const userBranchUuid =
  getRoleName() != 'CONTACT_CENTER' && getBrach() ? getBrach() : '';

const schema = yup.object().shape({
  folioD: yup.string(),
  nameD: yup.string(),
  emailD: yup.string(),
  cellphoneD: yup.string(),
  createdDate: yup.string(),
  categoryD: yup.string(),
  deliveryDate: yup.string(),
  returnDate: yup.string(),
  daysDiffD: yup.string(),
  deliveryBranchD: yup.string(),
  observationsD: yup.string(),
  stepD: yup.string(),
  plateNumberD: yup.string(),
});

const initialValues = {
  folioD: '',
  nameD: '',
  emailD: '',
  cellphoneD: '',
  createdDate: '',
  categoryD: '',
  deliveryDate: '',
  returnDate: '',
  daysDiffD: '',
  deliveryBranchD: userBranchUuid,
  observationsD: '',
  stepD: '',
  plateNumberD: '',
};

export const initialTouched = {
  folioD: true,
  nameD: true,
  emailD: true,
  cellphoneD: true,
  createdDate: true,
  categoryD: true,
  deliveryDate: true,
  returnDate: true,
  daysDiffD: true,
  deliveryBranchD: true,
  observationsD: true,
  stepD: true,
  plateNumberD: true,
};

const formatter = (values) => {
  const { folioD } = values;
  return {
    folio: folioD == '' ? folioD : folioD.padStart(3, '0'),
    name: values.nameD,
    email: values.emailD,
    cellphone: values.cellphoneD,
    category: values.categoryD,
    daysDiff: values.daysDiffD,
    deliveryBranch: values.deliveryBranchD,
    observations: values.observationsD,
    step: values.stepD,
    plateNumber: values.plateNumberD,
  };
};

export const config = {
  schema,
  modalType: 'empty',
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter,
  submitForm: setParamsListOfReservations,
  withButtons: false,
  dialogs: {
    confirmation: {},
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      successMessage: '',
      successLabel: '',
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      errorLabel: i18n('COMMONS__CLOSE__TEXT'),
    },
  },
};
