import React from 'react';
import classNames from 'classnames';
import './VehicleSectionChargeValue.scss';

export default function VehicleSectionChargeValue({
  title,
  className,
  image,
  charge = '',
  price = '',
  icon = null,
}) {
  const cls = classNames(
    'container-fluid VehicleSectionChargeValue p-0 m-0',
    className
  );
  return (
    <div className={cls}>
      <div className="row m-0 p-0">
        <div className="col-4 p-0">
          <>
            {icon || (
              <>
                {image && (
                  <div className="VehicleSectionChargeValue__Icon-container align-items-center d-flex justify-content-center">
                    <img
                      src={image}
                      alt={title}
                      className="VehicleSectionChargeValue__Icon-container--icon"
                    />
                  </div>
                )}
              </>
            )}
          </>
        </div>
        <div className="col-8 p-0 pl-1">
          <div className="h6 text-bold m-0 text-overflow-ellipsis">{title}</div>
          <p className="text-uppercase m-0 text-overflow-ellipsis">{charge}</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p className="text-right">{price}</p>
        </div>
      </div>
    </div>
  );
}
