import React, { useEffect } from 'react';
import { useField } from 'formik';

const Checkbox = ({
  id,
  name,
  disabled,
  label,
  value,
  handleCheckboxChange,
  gps,
  checked = null,
  defaultChecked,
  ...rest
}) => {
  const [field] = useField({ name, type: 'checkbox' });
  const [isChecked, setIsChecked] = React.useState(false);

  const toggleCheckboxChange = (e) => {
    setIsChecked(!isChecked);
    if (handleCheckboxChange) {
      handleCheckboxChange(gps, e);
    }
  };

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <div className="Checkbox">
      <input
        {...rest}
        disabled={disabled}
        name={field.name}
        type="checkbox"
        className="Checkbox__input styled-checkbox"
        id={id || name}
        value={value}
        checked={checked === null ? isChecked : checked}
        onChange={toggleCheckboxChange}
        defaultChecked={defaultChecked}
      />
      <label className="Checkbox__label" htmlFor={id || name}>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {};

export default Checkbox;
