import * as Yup from 'yup';
import i18n from '@i18n';
import {
  fileValidation,
  differentValue,
  dateValidationGreat,
} from 'utils/validations/common';
import { IDENTITY_DOCUMENT_FIELDS, REQUIRED_MESSAGE } from 'utils/constants';

const identityFileValidation = fileValidation({
  schema: Yup.mixed().required(REQUIRED_MESSAGE),
  fields: IDENTITY_DOCUMENT_FIELDS,
});

export const officialIdentificationProps = {
  identificationType: Yup.string().required(REQUIRED_MESSAGE),
  identificationCode: Yup.string().required(REQUIRED_MESSAGE),
  emissionYear: Yup.string().required(REQUIRED_MESSAGE),
  validity: differentValue(
    dateValidationGreat.required(REQUIRED_MESSAGE),
    'emissionYear',
    i18n('ERROR__CUSTOMERS__PASSPORT__INVALID_VALIDTY_INFO')
  ),
  identificationFrontSide: identityFileValidation,
  identificationBackSide: identityFileValidation,
};

export const OfficialIdentificationSchema = Yup.object().shape({
  ...officialIdentificationProps,
});

export default OfficialIdentificationSchema;
