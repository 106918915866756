import RD9 from '@images/rd9.png';
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
  },
  section: {
    position: 'absolute',
    zIndex: '-1',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  image_width: {
    width: '100%',
  },
  name: {
    position: 'absolute',
    top: 168,
    left: 170,
    fontSize: 9,
  },
  rfc: {
    position: 'absolute',
    top: 180,
    left: 97,
    fontSize: 9,
  },
  number: {
    position: 'absolute',
    top: 192,
    left: 165,
    fontSize: 9,
  },
  landline: {
    position: 'absolute',
    top: 192,
    left: 350,
    fontSize: 9,
  },
  email: {
    position: 'absolute',
    top: 204,
    left: 181,
    fontSize: 9,
  },
});

const DocumentRd9 = ({ name, number, landline, email, billingDetails }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image style={styles.image_width} src={RD9} />
      </View>
      <Text style={styles.name}>{name}</Text>
      <Text style={styles.number}>{number}</Text>
      <Text style={styles.landline}>{landline}</Text>
      <Text style={styles.email}>{email}</Text>
      <Text style={styles.rfc}>
        {
          billingDetails?.find((bill) => {
            return bill?.isPrimary;
          })?.rfc
        }
      </Text>
    </Page>
  </Document>
);

export default DocumentRd9;
