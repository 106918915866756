import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  Suspense,
  lazy,
} from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import {
  Section,
  Button,
  MaskedInput as Input,
  Loader,
  CardRow,
  Table,
} from '@casanova/casanova-common';
import {
  transformDropdownElements,
  transformIdOfList,
} from '@casanova/casanova-common/lib/utils/transformHelpers';
import { alphaMask } from '@casanova/casanova-common/lib/utils/masks';

import i18n from '@i18n';

import { VEHICLES_STATUS } from '@vehicles/common/constants';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { KEYBOARD_CODES } from 'utils/constants/keyboardCodes';
import columns, { VEHICLES_ACTIONS, VEHICLE_CARD_ROW_FIELDS } from './columns';
import {
  ADD_REMOVE_VEHICLE_VIRTUAL_CONTRACT,
  CHANCE_VEHICLE_VIRTUAL_CONTRACT,
} from 'utils/roles/permissions/contractsPermissions';
import { RoleVerifier } from 'components';
import { validateRolePermissions } from 'utils/roles';

import './Vehicles.scss';

import { postAddCar, postRemoveCar } from 'services/contracts';
import ResponseDialog from 'components/ResponseDialog';

const ChangeOfVehicle = lazy(() =>
  import('@contracts/sections/ChangeOfVehicle')
);
const Loan = lazy(() => import('@contracts/sections/Loan'));

export default function Vehicles({
  history,
  vehiclesSearchList = [],
  action,
  setResponseDialog,
  searchVehicles,
  cleanSearchVehicles,
  onlyAdd = false,
  onVehiclesChange,
  onlyOne = false,
  contractVehicles = [],
  responseDialogVc,
  closeResponseDialogVc,
}) {
  const [listOfVehicles, setListOfVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [lastSearch, setLastSearch] = useState('');
  const [showChangeVehicle, setShowChangeVehicle] = useState(false);
  const [showSearchVehicle, setShowSearchVehicle] = useState(false);
  const [showSaveVehicle, setShowSaveVehicle] = useState(false);
  const [showLoan, setShowLoan] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const { values, dirty, errors, touched, setFieldValue } = useFormikContext();
  const { vehicleSearch } = values;
  const generalUuid = history ? history.location.pathname.split('/')[2] : null;

  useEffect(() => {
    if (values.businessArea != undefined) {
      sessionStorage.setItem('bsArea', values.businessArea);
      /* values.vehicles = [];
      setSelectedVehicles([]); */
    }
  }, [values.businessArea]);

  // console.log(bs)

  const handleVehicleSearch = useCallback(() => {
    const bs =
      sessionStorage.getItem('bsArea') ||
      '1cabdbe5-24e9-40b6-9e56-11cadc007b62';
    if (vehicleSearch.length > 2) {
      setLastSearch(vehicleSearch);
      searchVehicles({
        pattern: vehicleSearch,
        isVirtualContract: true,
        showLoader: true,
        businessArea: bs,
      });
    }
  }, [searchVehicles, vehicleSearch]);

  const handleKeyDown = useCallback(
    (evt) => {
      if (evt.nativeEvent.keyCode === KEYBOARD_CODES.ENTER) {
        handleVehicleSearch();
      }
    },
    [handleVehicleSearch]
  );

  const handleAddVehicle = useCallback(
    (vehicle) => () => {
      if (onlyOne) {
        setSelectedVehicles([vehicle]);
      } else {
        setSelectedVehicles((prevSelectedVehicles) => {
          // eslint-disable-next-line no-shadow
          const selectedVehicle = prevSelectedVehicles.some(
            ({ uuid }) => uuid === vehicle.uuid
          );
          if (onlyAdd && selectedVehicle) {
            return prevSelectedVehicles.filter(
              ({ uuid }) => uuid !== vehicle.uuid
            );
          }
          return selectedVehicle
            ? prevSelectedVehicles
            : prevSelectedVehicles.concat(vehicle);
        });
      }

      if (!onlyAdd) {
        setListOfVehicles((prevSelectedVehicles) =>
          prevSelectedVehicles.filter(({ uuid = '' }) => uuid !== vehicle.uuid)
        );
      }
    },
    [onlyAdd, onlyOne]
  );

  const handleEditVehicles = (vehicles) => {
   const  newVehicles = vehicles.filter((el) => !el.vehicleUuid);
    setShowLoader(true);
    Promise.all([
      postAddCar({
        newVehicleUuids: newVehicles.map((el) => el.uuid),
        contractUuid: generalUuid,
      }),
    ]).then(() => {
      setShowLoader(false);
      setShowSearchVehicle(false);
      setShowSaveVehicle(false);
      setSelectedVehicles((prevSelectedVehicles) =>
      prevSelectedVehicles.map((v) => v?.vehicleUuid ? {...v} : {...v, vehicleUuid: v.uuid})
    );
    });
  };

  const handleRemoveVehicle = useCallback((vehicle) => {
    if (generalUuid !== 'add') {
      setShowLoader(true);
      Promise.all([
        postRemoveCar({
          removeVehicleUuids: [vehicle.vehicleUuid],
          contractUuid: generalUuid,
        }),
      ]).then(() => {
        setShowLoader(false);
      });
    }
    setSelectedVehicles((prevSelectedVehicles) =>
      prevSelectedVehicles.filter(({ uuid = '' }) => uuid !== vehicle.uuid)
    );
    setListOfVehicles((prevListOfVehicles) => {
      const vehicleInList = prevListOfVehicles.some(
        ({ uuid }) => uuid === vehicle.uuid
      );
      return vehicleInList
        ? prevListOfVehicles
        : prevListOfVehicles.concat(vehicle);
    });
  }, []);

  const handleDeleteValue = useCallback(() => {
    cleanSearchVehicles();
    setListOfVehicles([]);
    setLastSearch('');
  }, []);

  const handleDropdownSelect = useCallback(({ name, ...vehicle }) => {
    setSelectedVehicle(vehicle);

    switch (name) {
      case VEHICLES_ACTIONS.CHANGE_OF_VEHICLE.name:
        setShowChangeVehicle(true);
        break;
      case VEHICLES_ACTIONS.LOAN.name:
        setShowLoan(true);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    setListOfVehicles(vehiclesSearchList);
  }, [vehiclesSearchList]);

  useEffect(
    () => () => {
      cleanSearchVehicles();
    },
    []
  );

   useEffect(() => {
     if (action !== FORM_ACTIONS.ADD && !dirty && values.temporalChanges) {
       setSelectedVehicles(values.temporalChanges);
     }
   }, [action, values.temporalChanges, dirty, selectedVehicles]);


  useEffect(
    () => () => {
      setSelectedVehicles([]);
      setFieldValue('vehicles', []);
    },
    []
  );

  useEffect(() => {
    if (onlyAdd) {
      setResponseDialog(null);
    }
  }, [onlyAdd, setResponseDialog]);

  useEffect(() => {
    setTimeout(() => {
      setFieldValue('vehicles', selectedVehicles);
    }, 1);
  }, [setFieldValue, selectedVehicles]);

  useEffect(() => {
    if (onVehiclesChange) {
      onVehiclesChange(selectedVehicles);
    }
  }, [onVehiclesChange, selectedVehicles]);

  const showVehicleResults = useMemo(
    () => listOfVehicles.length > 0 || selectedVehicles.length > 0,
    [listOfVehicles, selectedVehicles]
  );

  const actionModalHelpers = useMemo(
    () => ({
      setResponseDialog,
      history,
      vehicle: selectedVehicle,
    }),
    [setResponseDialog, history, selectedVehicle]
  );

  const handelOnClose = () => {
    closeResponseDialogVc();
  };

  return (
    <Suspense fallback={<Loader show />}>
      {showLoader && <Loader show />}
      <ResponseDialog
        open={responseDialogVc.open}
        success={responseDialogVc.success}
        errorTitle={i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE')}
        errorLabel={i18n('COMMONS__CLOSE__TEXT')}
        errorMessage={responseDialogVc.message}
        errorCode={responseDialogVc.errorCode}
        onError={handelOnClose}
        onClose={handelOnClose}
      />
      {action !== FORM_ACTIONS.ADD && (
        <>
          <ChangeOfVehicle
            show={showChangeVehicle}
            setShow={setShowChangeVehicle}
            {...actionModalHelpers}
          />
          <Loan show={showLoan} setShow={setShowLoan} {...actionModalHelpers} />
        </>
      )}

      <Section
        title={i18n(
          action === FORM_ACTIONS.ADD
            ? 'CONTRACTS__ADD_VEHICLE__TITLE'
            : 'CONTRACTS__CONTRACT_DETAILS__VEHICLES__TITLE'
        )}
        className="Vehicles ContractVehicles"
        upperCase
      >
        <div className="row">
          {action === FORM_ACTIONS.VIEW && (
            <RoleVerifier identifier={[ADD_REMOVE_VEHICLE_VIRTUAL_CONTRACT]}>
              <div className="col-6">
                <Button
                  onClick={() => {
                    setShowSearchVehicle(true);
                    setShowSaveVehicle(true);
                  }}
                >
                  AGREGAR
                </Button>
              </div>
            </RoleVerifier>
          )}
          {showSaveVehicle && (
            <div className="col-6">
              <Button onClick={() => handleEditVehicles(selectedVehicles)}>
                GUARDAR
              </Button>
            </div>
          )}
        </div>
        {(action !== FORM_ACTIONS.VIEW || showSearchVehicle) && (
          <div className="row">
            <div className="col-4">
              <Input
                name="vehicleSearch"
                maskPlaceholder=""
                mask={alphaMask(17)}
                label={i18n('CONTRACTS__ADD_VEHICLE__SEARCH_VEHICLE')}
                placeholder={i18n('CONTRACTS__ADD_VEHICLE__SEARCH_VEHICLE')}
                infoText={i18n(
                  'CONTRACTS__ADD_VEHICLE__SEARCH_VEHICLE__INFO_TEXT'
                )}
                upperCase
                deleteValue
                onDeleteValue={handleDeleteValue}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="col-2 pt-3">
              <br />
              <Button block onClick={handleVehicleSearch}>
                {i18n('CUSTOMERS__CURP__SEARCH_BUTTON')}
              </Button>
            </div>
          </div>
        )}

        <div className="row p-0 m-0">
          <div className="col-12 d-flex justify-content-end">
            {errors.vehicles && touched.vehicles && (
              <span className="text-danger col-form-error">
                {errors.vehicles}
              </span>
            )}
          </div>
        </div>

        {lastSearch && (
          <div className="row">
            <div className="col-12">
              <h5>
                {i18n('COMMONS__RESULTS')} &quot;{lastSearch}
                &quot;
              </h5>
            </div>
          </div>
        )}

        {showVehicleResults && (
          <>
            {(action !== FORM_ACTIONS.VIEW || showSearchVehicle) && (
              <div className="row">
                <div className="col-12 SearchList">
                  {transformIdOfList(listOfVehicles)
                    .filter((vehicle) => {
                      const someSelected = ({ uuid }) => uuid === vehicle.uuid;

                      const hasSelected = !onlyAdd
                        ? selectedVehicles.some(someSelected)
                        : contractVehicles.some(someSelected);

                      return (
                        vehicle.status === 'Disponible' &&
                        vehicle.subStatus === VEHICLES_STATUS.ON_FLOOR &&
                        !hasSelected
                      );
                    })
                    .map((vehicle) => {
                      const items = Object.entries(vehicle)
                        .filter(([key]) => VEHICLE_CARD_ROW_FIELDS[key])
                        .map(([key, value]) => ({
                          value,
                          bold: VEHICLE_CARD_ROW_FIELDS[key].bold,
                        }));

                      const itemsWithId = transformIdOfList(items);

                      const hasChecked = selectedVehicles.some(
                        ({ uuid }) => uuid === vehicle.uuid
                      );

                      return (
                        <CardRow
                          key={vehicle.id}
                          items={itemsWithId}
                          withCheck={onlyAdd}
                          withAdd={!onlyAdd}
                          onAdd={handleAddVehicle(vehicle)}
                          check={hasChecked}
                          withExternalCheck
                          onChangeCheck={handleAddVehicle(vehicle)}
                        />
                      );
                    })}
                </div>
              </div>
            )}
            {!onlyAdd && (
              <div className="row mt-3">
                <Table
                  dataList={selectedVehicles}
                  columns={columns}
                  removeItems={
                    selectedVehicles.length > 0 &&
                    validateRolePermissions(ADD_REMOVE_VEHICLE_VIRTUAL_CONTRACT)
                  }
                  onRemoveItem={handleRemoveVehicle}
                  dropdownCell={action !== FORM_ACTIONS.ADD}
                  dropdownOptions={
                    action !== FORM_ACTIONS.ADD &&
                    validateRolePermissions(CHANCE_VEHICLE_VIRTUAL_CONTRACT) &&
                    transformDropdownElements({
                      elements: Object.values(VEHICLES_ACTIONS),
                    })
                  }
                  onDropdownSelect={handleDropdownSelect}
                  emptyMessage={i18n('CONTRACTS__VEHICLES__EMPTY_MESSAGE')}
                />
              </div>
            )}
          </>
        )}
      </Section>
    </Suspense>
  );
}

Vehicles.propTypes = {
  action: PropTypes.string,
  onlyAdd: PropTypes.bool,
  onVehiclesChange: PropTypes.func,
  setResponseDialog: PropTypes.func,
  vehiclesSearchList: PropTypes.array,
  searchVehicles: PropTypes.func,
  cleanSearchVehicles: PropTypes.func,
  onlyOne: PropTypes.bool,
  contractVehicles: PropTypes.array,
};
