import React, { useCallback, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import i18n from '@i18n';
import { Icons } from '@casanova/casanova-common';
import { Section } from 'components';
import {
  SpecItem,
  CardInfoOfVehiclePart,
  CardFirm,
  VerificationPointModal,
} from '@reservations/components';
import {
  isGeneralGroup,
  isPassedGeneralGroup,
} from '@reservations/commons/utils';
import './VehicleInfo.scss';

export default function VehicleInfo({
  title = i18n('RESERVATIONS__DETAIL__INFO_SECTION__TITLE__VEHICLE'),
  customerFullName = '',
  folio = '',
  vehicleImage = '',
  selectedCategoryName = '',
  vehicleTypeName = '',
  vehicleTitle = '',
  featureList = [],
  planFeatureList = [],
  showCharacteristics = false,
  showPlanFeature = false,
  showDetailCustomer = false,
  showFolio = true,
  showDetailInfo = true,
  detailInfo = [],
  verificationGroups = [],
  showVerificationGroups = true,
  firmInfo = {},
  showFirm = true,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [verificationPoint, setVerificationPoint] = useState({});

  const handleClickPoint = useCallback((point) => {
    if (!point.isSet || (point.firmPoint && !point.signature)) return;

    const checkPassed = isGeneralGroup(point)
      ? isPassedGeneralGroup(point)
      : point.passed;

    setVerificationPoint({ ...point, passed: checkPassed });
    setShowDetails(true);
  }, []);

  const handleClose = useCallback(() => {
    setShowDetails(false);
    setVerificationPoint({});
  }, []);

  return (
    <>
      <VerificationPointModal
        open={showDetails}
        verificationPoint={verificationPoint}
        onClose={handleClose}
      />
      <Section title={title}>
        {showDetailCustomer && (
          <div className="row">
            <div className="col-7 offset-5">
              <div className="Detail__customer">
                <div className="Detail__customer__name">
                  {`${i18n(
                    'RESERVATIONS__DETAIL__CUSTOMER'
                  )} ${customerFullName}`}
                </div>
                {showFolio && (
                  <div className="Detail__customer__folio">
                    {`${i18n('RESERVATIONS__DETAIL__FOLIO')} ${folio}`}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="row mb-1">
          <div className="col-5">
            <div className="vehicle-image">
              <img src={vehicleImage} alt="" className="VehicleInfo__image" />
            </div>
          </div>
          <div className="col-7 vehicle-features text-overflow-ellipsis">
            <p>
              <span className="kind">
                {selectedCategoryName} / {vehicleTypeName}
              </span>
              <br />
              <span className="title">{vehicleTitle}</span>
            </p>

            {showCharacteristics && (
              <div className="features">
                <div className="title">Características</div>
                <div className="feature-list">
                  {featureList.map(({ feat, specList }) => (
                    <SpecItem feat={feat} specList={specList} />
                  ))}
                </div>
              </div>
            )}

            {showDetailInfo && detailInfo && (
              <div className="row mb-3 my-1">
                {detailInfo.map(({ label = '', value }) => (
                  <div
                    key={`${label}_${value}`}
                    className="col-6 my-2 text-overflow-ellipsis"
                  >
                    <h6 className="text-bold d-inline mr-2">{label}</h6>
                    <h6 className="normal-text d-inline">{value}</h6>
                  </div>
                ))}
              </div>
            )}

            {showPlanFeature && (
              <div className="row">
                <div className="col-12">
                  <div className="plan-features">
                    <span className="coverage">
                      <Icons.GoldenShield width="1.25rem" height="1.5rem" />
                      Cuenta con un paquete de cobertura integrado BÁSICO
                    </span>
                    <ul className="plan-features-list">
                      {planFeatureList
                        .sort((a, b) => a.length - b.length)
                        .map((feature) => (
                          <li key={feature}>{feature}</li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {showVerificationGroups && (
          <div className="row mt-3">
            {verificationGroups.map((group) => {
              const {
                verificationPoints = [],
                image,
                position = 0,
                uuid = '',
              } = group;
              return (
                <Fragment key={uuid}>
                  {verificationPoints.map((point) => (
                    <div
                      key={point.uuid}
                      className="d-flex justify-content-center col-12 col-md-6 col-xl-4"
                    >
                      <CardInfoOfVehiclePart
                        image={image}
                        {...point}
                        className={point.isSet ? 'VehicleInfo__CardPart' : ''}
                        onClick={() =>
                          handleClickPoint({
                            ...point,
                            position,
                            vehicleImage,
                          })
                        }
                        vehicleImage={vehicleImage}
                        position={position}
                        group={group}
                      />
                    </div>
                  ))}
                </Fragment>
              );
            })}

            {showFirm && (
              <div className="d-flex justify-content-center col-12 col-md-6 col-xl-4">
                <CardFirm
                  {...firmInfo}
                  className={firmInfo.signature ? 'VehicleInfo__CardPart' : ''}
                  onClick={() =>
                    handleClickPoint({
                      ...firmInfo,
                      isSet: true,
                      position: 0,
                      vehicleImage: firmInfo.signature,
                      showDetails: false,
                      firmPoint: true,
                    })
                  }
                />
              </div>
            )}
          </div>
        )}
      </Section>
    </>
  );
}

VehicleInfo.propTypes = {
  title: PropTypes.string,
  customerFullName: PropTypes.string,
  folio: PropTypes.string,
  vehicleImage: PropTypes.string,
  selectedCategoryName: PropTypes.string,
  vehicleTypeName: PropTypes.string,
  vehicleTitle: PropTypes.string,
  featureList: PropTypes.arrayOf(
    PropTypes.shape({
      feat: PropTypes.string,
      specList: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  planFeatureList: PropTypes.arrayOf(PropTypes.string),
  showCharacteristics: PropTypes.bool,
  showPlanFeature: PropTypes.bool,
  showDetailCustomer: PropTypes.bool,
  showFolio: PropTypes.bool,
  showDetailInfo: PropTypes.bool,
  detailInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  verificationGroups: PropTypes.arrayOf(
    PropTypes.shape({
      verificationPoints: PropTypes.arrayOf(
        PropTypes.shape({
          uuid: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          passed: PropTypes.bool,
          isSet: PropTypes.bool,
          signature: PropTypes.string,
        })
      ),
      image: PropTypes.string,
      position: PropTypes.number,
      uuid: PropTypes.string,
    })
  ),
  showVerificationGroups: PropTypes.bool,
  firmInfo: PropTypes.shape({
    signature: PropTypes.string,
    fullName: PropTypes.string,
    folio: PropTypes.string,
    position: PropTypes.number,
  }),
  showFirm: PropTypes.bool,
};
