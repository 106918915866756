import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { DeliveryOne, DeliveryTwo, DeliveryThree } from './steps';

export default function Delivery(props) {
  const { match, step, getReservationInfo } = props;

  const reservationUuid = useMemo(() => match.params.id, [match]);

  useEffect(() => {
    if (reservationUuid) {
      getReservationInfo({ uuid: reservationUuid, redirect: false });
    }
  }, [reservationUuid, getReservationInfo]);

  const renderComponents = [
    <DeliveryOne {...props} />,
    <DeliveryTwo {...props} />,
    <DeliveryThree {...props} />,
  ];

  if (step > 3) {
    return <Redirect to="/reservations" />;
  }

  return renderComponents[step - 1];
}

Delivery.propTypes = {
  step: PropTypes.number.isRequired,
  getReservationInfo: PropTypes.func.isRequired,
};
