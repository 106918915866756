import { acceptSignatures } from 'store/reservations/actions';

const schema = {};

export const config = (folio = '') => ({
  schema,
  initialTouched: {},
  initialValues: {},
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: false,
  submitForm: (values) => acceptSignatures(values.formUuid),
  btnSubmitLabel: 'Finalizar',
  showCancel: false,
  dialogs: {
    confirmation: {
      title: 'VERIFICAR ENTREGA',
      message: `Debes verificar la entrega del vehículo con el ${folio}`,
      actionLabel: 'Continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡VEHÍCULO ENTREGADO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage: `La apertura del contrato y la entrega del vehículo 
      fueron realizados con éxito.
      ¡Buen viaje!`,
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a reservaciones',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: '/daily-rent-contracts/',
    },
  },
});
