"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUAT = exports.isPROD = exports.isIST = void 0;
var isIST = exports.isIST = function isIST() {
  return process.env.REACT_APP_ENV_NAME === 'ist';
};
var isUAT = exports.isUAT = function isUAT() {
  return process.env.REACT_APP_ENV_NAME === 'uat';
};
var isPROD = exports.isPROD = function isPROD() {
  return process.env.REACT_APP_ENV_NAME === 'prod';
};