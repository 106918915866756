import i18n from '@i18n';
import moment from 'moment-timezone';
import 'moment/locale/es';
import { editTransfer } from 'store/transfers/actions';
import { transformer } from '@transfers/sections/TransfersInformation/transformer';
import { transfersInformationSchema } from '@transfers/sections/TransfersInformation/schema';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { YYYY_MM_DD, timeZone } from 'utils/constants';
import _get from 'lodash/get';

const initialValues = {
  requester: '',
  requester_search: '',
  requester__lastSearch: '',
  plate: '',
  plate_search: '',
  plate_lastSearch: '',
  source: '',
  destination: '',
  departureDate: '',
  departureTime: '',
  departureRealDate: '',
  departureRealTime: '',
  realDepartureDate: '',
  realDepartureTime: '',
  arrivalDate: '',
  arrivalTime: '',
  realArrivalDate: '',
  realArrivalTime: '',
  driver: '',
  observations: '',
  licenseNumber: '',
  validityDriverLicense: '',
  emissionState: '',
  action: FORM_ACTIONS.EDIT,
};

export const initialTouched = {
  destination: true,
  arrivalTime: true,
  observations: true,
  realDepartureDate: true,
  realDepartureTime: true,
  realArrivalDate: true,
  realArrivalTime: true,
  licenseNumber: true,
  validityDriverLicense: true,
  emissionState: true,
};

export const formatter = (values) => {
  const {
    observations,
    destination,
    arrivalTime,
    returnDate,
    realDepartureDate,
    realDepartureTime,
    realArrivalDate,
    realArrivalTime,
    licenseNumber,
    validityDriverLicense,
  } = values;

  return {
    arrivalTime: arrivalTime || null,
    arrivalDate: returnDate,
    destinyWarehouse: destination,
    realDepartureDate: realDepartureDate
      ? moment(realDepartureDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
      : '',
    realDepartureTime: realDepartureTime ? realDepartureTime : '',
    realArrivalDate: realArrivalDate
      ? moment(realArrivalDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
      : '',
    realArrivalTime: realArrivalTime ? realArrivalTime : '',
    observations: observations || null,
    licenseNumber,
    validityDate: moment(validityDriverLicense, YYYY_MM_DD)
      .tz(timeZone)
      .format(YYYY_MM_DD),
    issuingStateUuid: _get(values, 'emissionState', ''),
  };
};

export const config = {
  schema: transfersInformationSchema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  disableOnDirty: true,
  formatter,
  transformer,
  submitForm: editTransfer,
  btnSubmitLabel: 'Guardar',
  dialogs: {
    confirmation: {
      title: 'EDITAR TRASLADO',
      message:
        'Se editará el traslado del vehículo ¿Estas seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage: 'Se realizó la edición del traslado de manera exitosa',
      errorMessage:
        'Hubo un error mientras procesábamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a traslados',
      errorLabel: 'Aceptar',
      cancelLabel: 'Cancelar',
      successPath: '/transfers',
    },
  },
};

export default config;
