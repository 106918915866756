export const initialValues = {
  vehicleLineUuid: '',
  rentalPlanName: '',
  contractUuid: '',
  totalRefund: '',
  daysRented: '',
  daysAccrued: '',
  days: '',
  userUuid: '',
  branchUuid: '',
};

export const initialTouched = {
  vehicleLineUuid: true,
  rentalPlanName: true,
  contractUuid: true,
  totalRefund: true,
  daysRented: true,
  daysAccrued: true,
  days: true,
  userUuid: true,
  branchUuid: true,
};
