import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import _noop from 'lodash/noop';

import {
  Icons,
  RadioGroupInput,
  MaskedInput as Input,
} from '@casanova/casanova-common';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';

import i18n from '@i18n';
import { FormModal } from 'components';
import { numericMask, alphaEmailMask } from 'utils/masks';
import { AREA_OPTIONS } from '@reservations/commons/constants';

const OPTIONS = [
  {
    label: 'Celular',
    value: AREA_OPTIONS.CELLPHONE,
  },
  {
    label: 'Correo electrónico',
    value: AREA_OPTIONS.EMAIL,
  },
];

export default function SendFirmLinkModal({
  onActionSuccess = _noop,
  onCancelFirm = _noop,
  onAreaChange = _noop,
  onCancelModifyData = _noop,
  onClickSubDescription = _noop,
  showFirm = false,
  showSuccess = false,
  showModifyData = false,
  onSubmit,
  area = AREA_OPTIONS.USER,
  strictValidation = true,
  onlySubmit = false,
}) {
  const { values } = useFormikContext();

  const handleCancelFirm = useCallback(() => {
    onCancelFirm();
  }, [onCancelFirm]);

  const handleActionFirm = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const handleClickSubDescription = useCallback(() => {
    onClickSubDescription();
  }, [onClickSubDescription]);

  const handleCancelModifyData = useCallback(() => {
    onCancelModifyData();
  }, [onCancelModifyData]);

  const handleSubmitModifyData = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const handleActionSuccess = useCallback(() => {
    onActionSuccess();
  }, [onActionSuccess]);

  const handleAreaChange = useCallback(
    (areaValues) => {
      onAreaChange(areaValues);
    },
    [onAreaChange]
  );

  const cellPhoneStrict = useMemo(() => {
    const numberCellphone = strictValidation
      ? Number(values.cellphone)
      : values.cellphone;
    return numberCellphone || 'No hay número';
  }, [strictValidation, values]);

  return (
    <>
      <ActionModal
        open={showFirm}
        closeLabel={i18n('COMMONS__CANCEL__TEXT')}
        actionLabel={i18n('COMMONS__YES_CONTINUE__TEXT')}
        title={i18n('RESERVATIONS__CONTRACT__PROMISSORY_NOTE__MODAL__TITLE')}
        message={i18n(
          'RESERVATIONS__CONTRACT__PROMISSORY_NOTE__MODAL__LINK_TEXT',
          [cellPhoneStrict]
        )}
        icon={<Icons.StatusWarning />}
        messageBold
        textBold={[`${cellPhoneStrict}`]}
        subDescription="Modificar los datos de envío"
        onClickSubDescription={handleClickSubDescription}
        onClose={handleCancelFirm}
        onAction={handleActionFirm}
        onlySubmit={onlySubmit}
      />
      <ActionModal
        open={showSuccess}
        closeLabel={i18n('COMMONS__NO_RESEND__TEXT')}
        actionLabel={i18n('COMMONS__YES_CONTINUE__TEXT')}
        title={i18n(
          'RESERVATIONS__CONTRACT__PROMISSORY_NOTE__MODAL__SUCCESS__TITLE'
        )}
        message={i18n(
          'RESERVATIONS__CONTRACT__PROMISSORY_NOTE__MODAL__SUCCESS__DESCRIPTION'
        )}
        icon={<Icons.IcoCarOk />}
        subDescription="Modificar los datos de envío"
        onClickSubDescription={handleClickSubDescription}
        onClose={handleActionFirm}
        onAction={handleActionSuccess}
      />
      <FormModal
        open={showModifyData}
        title={i18n(
          'RESERVATIONS__CONTRACT__PROMISSORY_NOTE__MODAL__MODIFY_DATA__TITLE'
        )}
        description={i18n(
          'RESERVATIONS__CONTRACT__PROMISSORY_NOTE__MODAL__MODIFY_DATA__DESCRIPTION'
        )}
        submitLabel={i18n('COMMONS__SEND__TEXT')}
        onCancel={handleCancelModifyData}
        onSubmit={handleSubmitModifyData}
      >
        <div className="row">
          <div className="col-12">
            <RadioGroupInput
              labelClass="d-flex align-items-center"
              groupClass="col-12 d-flex justify-content-around"
              parentClass="vehicle-radio-input"
              label=""
              name="area"
              value={area}
              options={OPTIONS}
              standardError
              onChange={handleAreaChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Input
              name={area}
              placeholder={i18n('COMMONS__PLACEHOLDER__WRITE')}
              maskPlaceholder=""
              mask={
                area === AREA_OPTIONS.EMAIL
                  ? alphaEmailMask(50)
                  : numericMask(10)
              }
            />
          </div>
        </div>
      </FormModal>
    </>
  );
}

SendFirmLinkModal.propTypes = {
  onActionSuccess: PropTypes.func,
  onCancelFirm: PropTypes.func,
  onAreaChange: PropTypes.func,
  onCancelModifyData: PropTypes.func,
  onClickSubDescription: PropTypes.func,
  showFirm: PropTypes.bool.isRequired,
  showSuccess: PropTypes.bool,
  showModifyData: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  area: PropTypes.string,
  strictValidation: PropTypes.bool,
  onlySubmit: PropTypes.bool,
};
