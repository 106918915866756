import i18n from '@i18n';
import { CellText, CellDashed } from 'components/ColumnsCells';
import { VEHICLE_TRANSFERS_FIELDS as FIELDS } from '@vehicles/common/constants';

export const columns = [
  {
    label: i18n('COMMONS__ID'),
    field: FIELDS.ID,
    align: 'center',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_LOANS__TABLE__REGISTER_DATE'),
    field: FIELDS.REGISTER_DATE,
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_TRANSFERS__TABLE__DELIVERY_DATE'),
    field: FIELDS.DEPARTURE_DATE_AND_TIME,
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_TRANSFERS__TABLE__RETURN_DATE'),
    field: FIELDS.ARRIVAL_DATE,
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n(
      'VEHICLES__SECTIONS__VEHICLE_TRANSFERS__TABLE__REAL_RETURN_DATE'
    ),
    field: FIELDS.REAL_ARRIVAL_DATE,
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_CONTRACTS__TABLE__APERTURE_USER'),
    field: FIELDS.APERTURE_USER,
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_TRANSFERS__TABLE__REQUESTER'),
    field: FIELDS.REQUESTER,
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_ESTATUS__TABLE__ESTATUS'),
    field: FIELDS.STATUS,
    align: 'center',
    renderer: (props) => {
      const { data = {} } = props;
      return (
        <CellDashed
          {...props}
          fixedColor={data.transferStatus.color}
          value={data.transferStatus.name}
          align="left"
          textClass="text-left"
        />
      );
    },
  },
];
