import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { closeLoan, closeResponseDialog } from 'store/loans/actions';
import AuthorizeLoan from './AuthorizeLoan';

export default compose(
  connect(
    (state) => ({
      loans: state.loans.list,
      confirmationMessage: false,
      responseDialog: state.loans.responseDialog,
      loader: state.commons.loader,
    }),
    { closeLoan, closeResponseDialog }
  )
)(AuthorizeLoan);
