import * as yup from 'yup';
import { CUSTOM_EXTRA_CHARGE_TYPES } from '@reservations/commons/constants';
import {
  extraChargeFieldName,
  extraChargeName,
} from '@reservations/sections/DeliveryRefluxSteps/StepTwo/config';
import { makeSchema } from './schema';

const getFormFields = ({
  selectedVerifyPoint = {},
  completeElement = {},
  disabledForm = false,
}) => {
  const extraChargeRemarkName = extraChargeFieldName({
    ...selectedVerifyPoint,
    customExtraCharge: true,
    customExtraChargeType: CUSTOM_EXTRA_CHARGE_TYPES.CUSTOM_EXTRA_CHARGE_REMARK,
  });

  const extraChargeAmountName = extraChargeFieldName({
    ...selectedVerifyPoint,
    customExtraCharge: true,
  });

  const disabledFormName = extraChargeName({
    ...completeElement,
    onlyName: true,
  });

  const disabledFormPrice = extraChargeName({
    ...completeElement,
    onlyPrice: true,
  });

  return {
    disabledFormName,
    disabledFormPrice,
    extraChargeRemarkName,
    extraChargeAmountName,
    remarkName: disabledForm ? disabledFormName : extraChargeRemarkName,
    priceName: disabledForm ? disabledFormPrice : extraChargeAmountName,
  };
};

export const handleSubmit = (values, { props }) => {
  const { remarkName, priceName } = getFormFields(props);
  return props.handleClickAdd({
    [remarkName]: values[remarkName],
    [priceName]: values[priceName],
  });
};

export const mapPropsToValues = (props) => {
  const {
    disabledFormName,
    disabledFormPrice,
    extraChargeRemarkName,
    extraChargeAmountName,
    remarkName,
    priceName,
  } = getFormFields(props);

  const { disabledForm = false } = props;

  const defaultValues = {
    remarkName,
    priceName,
    disabledFormName,
    disabledFormPrice,
    extraChargeRemarkName,
    extraChargeAmountName,
    [remarkName]: '',
    [priceName]: disabledForm ? '' : 1,
    [extraChargeRemarkName]: '',
    [extraChargeAmountName]: '',
  };

  const { selectedVerifyPoint } = props;

  if (selectedVerifyPoint) {
    const { uuid = '' } = selectedVerifyPoint;

    const { prevExtraCharges = [] } =
      selectedVerifyPoint.prevCustomExtraCharges.find(
        ({ verifyPointUuid }) => verifyPointUuid === uuid
      ) || {};

    if (prevExtraCharges.length > 0) {
      const prevExtraCharge = prevExtraCharges.find(
        (extraCharge) =>
          extraCharge.extraChargeRemarkName === extraChargeRemarkName
      );

      if (prevExtraCharge && extraChargeRemarkName && extraChargeAmountName) {
        const remark = prevExtraCharge.values[extraChargeRemarkName];
        const amount = prevExtraCharge.values[extraChargeAmountName];

        if (remark && amount) {
          return {
            ...prevExtraCharge.values,
            [extraChargeRemarkName]: remark,
            [extraChargeAmountName]: amount,
          };
        }
      }
    }
  }

  return defaultValues;
};

export const mapPropsToTouched = (props) => {
  const {
    disabledFormName,
    disabledFormPrice,
    extraChargeRemarkName,
    extraChargeAmountName,
    remarkName,
    priceName,
  } = getFormFields(props);

  const defaultTouched = {
    [remarkName]: false,
    [priceName]: false,
    [disabledFormName]: false,
    [disabledFormPrice]: false,
    [extraChargeRemarkName]: false,
    [extraChargeAmountName]: false,
  };

  return defaultTouched;
};

export const validationSchema = (props) => {
  const { extraChargeRemarkName, extraChargeAmountName } = getFormFields(props);

  const { selectedVerifyPoint = {} } = props;

  const newSchema = makeSchema({
    extraChargeRemarkName,
    extraChargeAmountName,
    customExtraCharges: selectedVerifyPoint.customExtraCharges,
  });

  return yup.object().shape(newSchema);
};
