import _get from 'lodash/get';

export const authorizedDriversTransformer = (authorizedDrivers = []) =>
  authorizedDrivers.map((driver) => ({
    driverUuid: driver.uuid,
    driverCurp: driver.curp,
    driverName: driver.names,
    driverFirstName: driver.lastName,
    driverLastName: driver.secondLastName,
    driverBirthDate: driver.birthDate,
    driverPlaceBirth: driver.birthPlace,
    driverBirthState: driver.birthState,
    driverBirthCountry: driver.birthCountry,
    driverGender: driver.sex,
    driverFrontLicense: _get(driver, 'driverLicenseBack.originalName'),
    driverFrontLicenseUrl: _get(driver, 'driverLicenseBack.presignedUrl'),
    driverBackLicense: _get(driver, 'driverLicenseBack.originalName'),
    driverBackLicenseUrl: _get(driver, 'driverLicenseBack.presignedUrl'),
  }));
