import React, { useCallback, useEffect } from 'react';
import { useFormikContext, validateYupSchema } from 'formik';
import {
  Button,
  MaskedInput as Input,
  Loader,
} from '@casanova/casanova-common';
import { userMask } from 'utils/masks';
import { number2mxn } from 'utils/currency';
import Modal from '@casanova/casanova-common/lib/Modal';
import { discountFormSchema, formatter } from './config';

function ApplyDiscount(props) {
  const {
    open,
    id,
    onClose,
    modalType,
    closeButton,
    data,
    discountForm,
    saveDiscount,
  } = props;

  const { discount, total, discountAmount } = discountForm;
  const {
    values,
    setErrors,
    setTouched,
    errors,
    setFieldValue,
  } = useFormikContext();

  const handleOnSendDiscount = useCallback(() => {
    validateYupSchema(values, discountFormSchema)
      .then((result) => {
        setErrors({});
        saveDiscount(
          formatter({
            ...result,
            units: discountForm.units,
            discount,
          })
        );
      })
      .catch((errorsdd) => {
        const err = errorsdd.inner.reduce(
          (prev, current) => ({
            ...prev,
            [current.path]: current.message,
          }),
          {}
        );
        setTouched({ user: true, password: true });
        setErrors(err);
      });
  }, [values, setErrors, setTouched, discountForm, saveDiscount, discount]);

  useEffect(() => {
    if (!discountForm.isPromocode) {
      if (discountForm.finished && !discountForm.success) {
        setErrors({ apiResponse: discountForm.message });
      } else {
        setErrors({});
      }
    }
  }, [discountForm, setErrors, values]);

  useEffect(() => {
    if (!discountForm.isPromocode) {
      if (discountForm.finished && discountForm.success) {
        setFieldValue('user', '');
        setFieldValue('password', '');
        setFieldValue('withPromocode', []);
        setFieldValue('promocode', '');
      }
    }
  }, [discountForm, setErrors, setFieldValue]);

  const handleFieldsChange = useCallback(() => {
    setErrors({});
  }, [setErrors]);

  const header = (
    <div className="modal-header justify-content-center align-items-center pt-3">
      <h5 className="modal-title text-center" id={`${id}-label`}>
        Aplicar descuento
      </h5>
      <p className="text-center">
        <span className="common-info-text font-weight-bold">
          TOTAL {number2mxn(total)}
        </span>
        <br />
        <span className="common-amount-text">
          MXN {number2mxn(discountAmount)}
        </span>
        <br />
        <span className="common-info-text font-weight-bold">DESCUENTO</span>
      </p>
    </div>
  );
  const footer = (
    <div className="modal-footer container">
      {(modalType === 'action' || !modalType) && (
        <div className="row">
          <div className="col-md-6">
            <Button block outline onClick={() => onClose(data)}>
              Cancelar
            </Button>
          </div>
          <div className="col-md-6">
            <Button block onClick={handleOnSendDiscount}>
              Sí, aplicar
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      open={open}
      closeButton={closeButton}
      onClose={() => onClose(data)}
      header={header}
      footer={footer}
      className="action-modal"
      data={data}
    >
      <Loader show={discountForm.submiting} />
      <div className="modal-body">
        <Input
          name="user"
          label="USUARIO GERENTE DE SUCURSAL"
          placeholder="USUARIO GERENTE DE SUCURSAL"
          mask={userMask(30)}
          maskPlaceholder=""
          onChange={handleFieldsChange}
        />
        <Input
          type="password"
          name="password"
          label="CONTRASEÑA"
          placeholder="CONTRASEÑA"
          mask={userMask(30)}
          maskPlaceholder=""
          onChange={handleFieldsChange}
        />
        {errors && errors.apiResponse && (
          <div className="col-form-error text-danger">{errors.apiResponse}</div>
        )}
      </div>
    </Modal>
  );
}

ApplyDiscount.defaultProps = {
  closeLabel: 'Cancelar',
};

export default ApplyDiscount;
