import React, { useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { Icons } from '@casanova/casanova-common';
import { Card } from 'components';
import { SpecItem } from '@reservations/components';
import {
  isGeneralGroup,
  isPassedGeneralGroup,
} from '@reservations/commons/utils';
import { getSpectList } from '@reservations/commons/transformers';
import './CardInfoOfVehiclePart.scss';

export default function CardInfoOfVehicleSection(props) {
  const {
    title = '',
    className = '',
    imageClassName = '',
    image = null,
    images = [],
    passed = false,
    isSet = false,
    infoSet = false,
    name = '',
    onClick,
    vehicleImage = '',
    position = 1,
    extraCharges = [],
  } = props;

  const generalGroup = useMemo(() => isGeneralGroup(props), [position]);

  const imageSrc = useMemo(() => {
    if (generalGroup) return vehicleImage;
    return images[0] ? images[0].presignedUrl : image;
  }, [generalGroup, vehicleImage, image, images]);

  const checkPassed = generalGroup
    ? isPassedGeneralGroup(props)
    : passed && _isEmpty(extraCharges);

  return (
    <Card
      noStatus
      title={title && title.length > 4 ? title : name}
      padding="p-3"
      className={`CardInfo__container my-2 p-3 ${className}`}
      titleClassName=""
      upperCase
      onClick={onClick}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-5">
            {imageSrc ? (
              <div className="justify-content-center d-flex align-items-center">
                <img
                  src={imageSrc}
                  alt={title}
                  className={`${imageClassName || 'text-center'}`}
                  width={100}
                  height={70}
                  style={{ objectFit: 'contain' }}
                />
              </div>
            ) : (
              <Icons.Frontal width={84} />
            )}
          </div>
          <div className="col-7 mt-2 p-0 flex justify-content-center">
            <SpecItem
              specList={[getSpectList({ isSet, passed: checkPassed })]}
              check={checkPassed}
              className="CardInfo__info-container--Spec-item"
              textContainerClassName="text-overflow-ellipsis"
              textClassName="m-0 text-overflow-ellipsis"
              iconStyle={{ width: '2.25rem', height: '2.25rem' }}
              disableIconType={isSet ? 3 : 2}
            />
          </div>
        </div>
        {(isSet || infoSet) && (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <h6 className="text-right text-secondary">VER DETALLES</h6>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
