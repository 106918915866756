import React from 'react';
import { withForm } from 'hooks';
import { LoanInformation } from '@loans/sections';
import { config } from './config';

function LoanCreator(props) {
  return <LoanInformation {...props} />;
}

export default withForm({ config })(LoanCreator);
