import React from 'react';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { setListParams } from 'store/loans/actions';
import { components } from '@casanova/casanova-common';
import { AdditionalHeaderCamp } from 'components';
import withNotEmptyValue from '@casanova/casanova-common/lib/hocs/withNotEmptyValue';
import { onlyLettersWithSpaces, numericMask, hourMask } from 'utils/masks';
import VERSIONS from 'utils/constants/versions';
import { validateRolePermissions } from 'utils/roles';
import {
  EDIT_STATUS_CLOSED_ANY_LOAN_REQUEST,
  EDIT_ANY_LOAN_REQUEST,
  EDIT_STATUS_AUTHORIZE_LOAN_REQUEST,
  EDIT_STATUS_CANCELLED_ANY_LOAN_REQUEST,
} from 'utils/roles/permissions/loansPermissions';
import { minOfDepartureDateSearch } from './schemas';

const haveToShowDropdownCell = () => {
  const PERMISSIONS = [
    EDIT_STATUS_CLOSED_ANY_LOAN_REQUEST,
    EDIT_ANY_LOAN_REQUEST,
    EDIT_STATUS_AUTHORIZE_LOAN_REQUEST,
    EDIT_STATUS_CANCELLED_ANY_LOAN_REQUEST,
  ];

  const hasValidPermission = PERMISSIONS.some((permission) =>
    validateRolePermissions(permission)
  );

  return hasValidPermission;
};

export const showDropdownCell = haveToShowDropdownCell();

const { CellText: Cell, CellDashed } = components.ColumnsCells;

const CellText = withNotEmptyValue(Cell);

export default [
  {
    label: 'Id',
    field: 'folio',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(10)}
        name="folio"
        searchKey="folio"
      />
    ),
  },
  {
    label: 'Observaciones',
    field: 'observations',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(40)}
        name="observations"
        searchKey="observations"
      />
    ),
  },
  {
    label: 'Fecha de salida',
    field: 'departureDate',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();

      return (
        <AdditionalHeaderCamp
          name="departureDate"
          mask={numericMask(10)}
          searchKey="departureDate"
          type="DATE"
          version={VERSIONS.V2}
          action={setListParams}
          datePickerProps={{
            resetDate: Boolean(values.departureDate),
            onResetDate: () => {
              setFieldValue('departureDate', '');
              dispatch(setListParams({ departureDate: '' }));
            },
            minimumDate: minOfDepartureDateSearch,
          }}
        />
      );
    },
  },
  {
    label: 'Hora de salida',
    field: 'departureTime',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="departureTime"
        mask={hourMask}
        searchKey="departureTime"
        version={VERSIONS.V2}
        action={setListParams}
      />
    ),
  },
  {
    label: 'Fecha de regreso',
    field: 'returnDate',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();

      return (
        <AdditionalHeaderCamp
          name="returnDate"
          mask={numericMask(10)}
          searchKey="returnDate"
          type="DATE"
          version={VERSIONS.V2}
          action={setListParams}
          datePickerProps={{
            resetDate: Boolean(values.returnDate),
            onResetDate: () => {
              setFieldValue('returnDate', '');
              dispatch(setListParams({ returnDate: '' }));
            },
            minimumDate: minOfDepartureDateSearch,
          }}
        />
      );
    },
  },
  {
    label: 'Hora de regreso',
    field: 'returnTime',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="returnTime"
        mask={hourMask}
        searchKey="returnTime"
        version={VERSIONS.V2}
        action={setListParams}
      />
    ),
  },
  {
    label: 'Usuario llegada',
    field: 'requesterName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(30)}
        name="requesterName"
        searchKey="requesterName"
      />
    ),
  },
  {
    label: 'Estatus',
    field: 'status',
    align: 'center',
    renderer: (props) => {
      const { data = {} } = props;
      return (
        <CellDashed
          {...props}
          fixedColor={data.loanStatus.color}
          value={data.loanStatus.name}
          align="left"
          textClass="text-left"
        />
      );
    },
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="status"
        searchKey="status"
        options="commons.catalogsLoanStatuses"
        selectProps={{ availableFirstValue: true }}
        type="SELECT"
      />
    ),
  },
];
