const BodyNotification = ({ message: { summary, detail, action } }) => (
  <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
    <div className="text-lg flex align-items-center gap-2">
      <span className="font-bold">{summary}</span>
    </div>
    <span className="btn btn-link mr-auto" onClick={() => action()}>
      {detail}
    </span>
  </div>
);

export default BodyNotification;
