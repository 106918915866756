import React, { useCallback, useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { TableNavigation, Table, withForm } from '@casanova/casanova-common';
import useCleanModule from 'hooks/useCleanModule';
import { config } from './config';
import columns from './columns';
import { NOT_VIEW_RESERVATION_DETAILS } from 'utils/roles/permissions/reservationsPermissions';
import { validateRolePermissions } from 'utils/roles';

function ListOfRervationContract({
  history,
  listOfReservations,
  setListOfReservationsPage,
  setInitialParamsListOfReservations,
  getListOfReservations,
  getReservationInfo,
  getReservationListFilters,
  hydrateChangeOfVehicleFlow,
  resetReservations,
  onUpdateForm,
}) {
  const { params, searchPattern, filters, results } = listOfReservations;

  useCleanModule({ module: 'reservations' });

  const handleRowClick = useCallback(
    ({ uuid, changeRequest, ...reservation }) => {
      if (validateRolePermissions(NOT_VIEW_RESERVATION_DETAILS)) return;
      if (changeRequest) {
        if (validateRolePermissions(NOT_VIEW_RESERVATION_DETAILS)) return;
        hydrateChangeOfVehicleFlow({
          reservationUuid: uuid,
          changeRequestUuid: changeRequest,
        });
      } else {
        if (validateRolePermissions(NOT_VIEW_RESERVATION_DETAILS)) return;
        getReservationInfo({ uuid, ...reservation });
      }
    },
    [history, getReservationInfo, hydrateChangeOfVehicleFlow]
  );

  useEffect(() => {
    resetReservations({ exceptions: ['listOfReservations'] });
    setInitialParamsListOfReservations('DAILY_RENT');
    getReservationListFilters({ tableSelected: 'DAILY_RENT' });
  }, []);

  useEffect(() => {
    if (params.tableSelected == 'DAILY_RENT')
      getListOfReservations({ params, filters, searchPattern });
  }, [getListOfReservations, params, filters, searchPattern]);

  useEffect(() => {
    if (!_isEmpty(params)) {
      onUpdateForm(params);
    }
  }, [onUpdateForm]);

  return (
    <div>
      <Table
        additionalHeader
        rowClassName="row-clickable"
        rowClick={handleRowClick}
        emptyMessage="No se encontraron resultados"
        dataList={results.list}
        columns={columns}
      />
      <div className="row">
        <div className="col-12 col-xl-7 table-paginator text-right d-flex justify-content-between align-items-center">
          <TableNavigation
            search={listOfReservations}
            onChange={setListOfReservationsPage}
          />
        </div>
        <div className="col-12 col-md-8 col-xl-5" />
      </div>
    </div>
  );
}

export default withForm({ config })(ListOfRervationContract);
