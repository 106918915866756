export const initialTouched = {
  identificationType: true,
  identificationCode: true,
  lastName: true,
  emissionYear: true,
  validity: true,
  identificationFrontSide: true,
  identificationBackSide: true,
};

const initialValues = {
  identificationType: '',
  identificationCode: '',
  emissionYear: '',
  validity: '',
  identificationFrontSide: '',
  identificationBackSide: '',
};

export default {
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
  initialTouched,
};
