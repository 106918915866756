import {
  VIEW_VEHICLE_FINES,
  CREATE_VEHICLE_FINES,
} from 'utils/roles/permissions';
import Fine from './Fine';
import { withConnectedAdditional } from '../AdditionalCollapse';

export default withConnectedAdditional({
  dependsOnElement: 'plateOptions',
  identifierForView: VIEW_VEHICLE_FINES,
  identifierForAdd: CREATE_VEHICLE_FINES,
})(Fine);
