import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import {
  downloadFiles,
  getStatement,
  updateCustomerStatus,
} from 'store/customers/actions';
import CustomerInformationDetail from './CustomerInformation';

export default compose(
  connect(
    (state) => ({
      data: _get(state, 'customers.customerDetail.data', {}),
      responseDialog: _get(state, 'customers.responseDialog', {}),
    }),
    { downloadFiles, getStatement, updateCustomerStatus }
  )
)(CustomerInformationDetail);
