import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _unique from 'lodash/uniqBy';
import useIntersectionObserver from '@react-hook/intersection-observer';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import {
  ProgressIndicator,
  Icons,
  Button,
  Popover,
  TextAreaInput,
  MaskedInput as Input,
  ConnectedSelect,
} from '@casanova/casanova-common';
import { alphaMask } from '@casanova/casanova-common/lib/utils/masks';
import { withReservationAuth, withResponseDialog } from '@hocs';
import { Card, Checkbox, FormModal, PreventChange } from 'components';
import { UpgradeModal } from '@reservations/sections';
import i18n from '@i18n';
import {
  isChangeOfVehicleFlow,
  isExtendOfContractFlow,
} from '@reservations/commons/utils';
import { VEHICLE_RENTAL_STATUSES } from '@reservations/commons/constants';
import { RESERVATION_STEP } from 'utils/constants/reservations';
import './Contract.scss';
import ResponseDialog from 'components/ResponseDialog';

const Contract = ({
  maxSteps,
  step,
  title,
  subtitle,
  history,
  getCatalogueOfVehicles,
  setParamsOfVehiclesCatalogue,
  showObservation,
  setShowObservation,
  rentalStatuses = [],
  fetchRentalStatuses,
  contract,
  fetchRentalFilter,
  category,
  type,
  isSending,
  setVehicleSelected,
  vehicleSelected,
  getVehicleTypes,
  vehicleTypes,
  reservationInfo = {},
  setChangeVehicle,
  fetchCatalog,
  setShowChangeOfVehicleForm,
  showChangeOfVehicleForm,
  changeVehicleReservation,
  setAppSpecificLabel,
  changeOfVehicle,
  extendOfContract,
  responseDialogS,
  closeResponseDialogS,
}) => {
  const [wantUpgrade, setWantUpgrade] = useState(false);
  const [subType, setSubType] = useState(type);
  const [elements, setElements] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [ref, setRef] = useState();
  const { isIntersecting } = useIntersectionObserver(ref);

  const { catalogueOfVehicles = {}, setVehicle = {} } = contract;
  const { vehicle = {} } = setVehicle;
  const { params = {}, results = [], totalPages } = catalogueOfVehicles;
  const { step: reservationStep } = reservationInfo;

  const {
    values,
    handleSubmit,
    setErrors,
    errors,
    setFieldValue,
  } = useFormikContext();

  const contractStep = useMemo(
    () => reservationStep === RESERVATION_STEP.CREATE_CONTRACT,
    [reservationStep]
  );

  const isChangeOfVehicle = useMemo(() => isChangeOfVehicleFlow(), [
    isChangeOfVehicleFlow(),
  ]);

  const isExtendContract = useMemo(() => isExtendOfContractFlow(), [
    isExtendOfContractFlow(),
  ]);

  const {
    changeOfVehicleHasSuccess,
    changeOfVehicleIsLoading,
    changeOfVehicleHasError,
  } = useMemo(
    () => ({
      changeOfVehicleHasSuccess: changeOfVehicle.success,
      changeOfVehicleIsLoading: changeOfVehicle.loading,
      changeOfVehicleHasError: changeOfVehicle.error,
    }),
    [changeOfVehicle]
  );

  const handleChangeStatus = useCallback(
    (_, evt) => {
      const { statuses = [] } = params;
      let newListOfStatus = [...statuses];

      if (evt) {
        const statusUuid = evt.target.value;
        const statusInParams = Boolean(
          statuses.find((status) => status === statusUuid)
        );

        if (statusInParams) {
          newListOfStatus = newListOfStatus.filter(
            (status) => status !== statusUuid
          );
        } else {
          newListOfStatus = [...newListOfStatus, statusUuid];
        }
      }

      setElements([]);

      setParamsOfVehiclesCatalogue({
        ...params,
        page: 0,
        statuses: newListOfStatus,
        text: values.searchVehicle,
      });
    },
    [setParamsOfVehiclesCatalogue, values]
  );

  const handleOnUpgrade = useCallback(() => {
    setWantUpgrade(true);
  }, []);

  const handleOnCancel = useCallback(() => {
    setWantUpgrade(false);
  }, []);

  const handleUpgrade = useCallback(() => {
    setWantUpgrade(false);
    setElements([]);
    const typeSelected = vehicleTypes.find(
      (vehicleType) => vehicleType.value === values.vehicleUpgradeType
    );
    setParamsOfVehiclesCatalogue({
      ...params,
      page: 0,
      line: '',
      type: values.vehicleUpgradeType,
    });

    setSubType(typeSelected.label);
  }, [values, vehicleTypes, setParamsOfVehiclesCatalogue]);

  const handleObservationCancel = useCallback(() => {
    if (isExtendContract) window.location.pathname = '/daily-rent-contracts/';
    if (!contractStep) {
      setShowObservation(false);
    }
  }, [contractStep]);

  const handleObservationSubmit = useCallback(() => {
    handleSubmit();
    setShowObservation(false);
  }, [handleSubmit]);

  const handleSubmitChangeVehicle = () => changeVehicleReservation(values);

  const handleVehicleClick = useCallback(
    (newVehicle = {}) => {
      if (newVehicle.statusName === VEHICLE_RENTAL_STATUSES.AVAILABLE) {
        setVehicleSelected(newVehicle);
      }
    },
    [setVehicleSelected]
  );

  const handlePressSearchVehicle = useCallback(
    ({ nativeEvent }) => {
      if (nativeEvent.charCode === 13) {
        handleChangeStatus();
      }
    },
    [handleChangeStatus]
  );

  const handleChangeOfVehicleCancel = useCallback(() => {
    history.goBack();
    setShowChangeOfVehicleForm(false);
  }, [history, setChangeVehicle]);

  const handlePreventChangeConfirmation = useCallback(() => {
    setChangeVehicle(false);
  }, [setChangeVehicle]);

  const handleCancelPreventChange = useCallback(() => {
    if (isChangeOfVehicle) {
      setShowChangeOfVehicleForm(true);
    }
  }, [isChangeOfVehicle]);

  useEffect(() => {
    fetchRentalStatuses();
    fetchRentalFilter();
    getVehicleTypes(category.value);

    if (isChangeOfVehicleFlow()) {
      fetchCatalog({
        catalogId: 'catalogs/vehicle-change-reasons',
        catalogPath: 'pricequotes',
      });
    }
  }, []);

  useEffect(() => {
    const statuses = rentalStatuses;

    const preselected = statuses.find((status) => status.preselected);

    if (statuses.length > 0 && preselected && results.length === 0) {
      setElements([]);
      setParamsOfVehiclesCatalogue({
        ...params,
        statuses: [preselected?.status?.uuid],
      });
    }
  }, [rentalStatuses, setParamsOfVehiclesCatalogue]);

  useEffect(() => {
    if (vehicleSelected.uuid) {
      setErrors({
        ...errors,
        notValidSubmit: '',
      });
    }
  }, [setErrors, vehicleSelected, errors]);

  useEffect(() => {
    if (
      isIntersecting &&
      params.page < totalPages &&
      elements.length > 0 &&
      !blocked
    ) {
      setParamsOfVehiclesCatalogue({ ...params, page: params.page + 1 });
      setBlocked(true);
    }
  }, [isIntersecting, setParamsOfVehiclesCatalogue, setBlocked]);

  useEffect(() => {
    if (totalPages > params.page || totalPages == 0)
      getCatalogueOfVehicles({ params, type: values.vehicleUpgradeType });
  }, [getCatalogueOfVehicles, params]);

  useEffect(() => {
    const vehicles = results;
    if (vehicles && vehicles.length > 0) {
      setElements((prev) => {
        let vehicleItems = [];
        vehicleItems = [...new Set([...prev, ...vehicles])];
        setBlocked(false);
        return _unique(vehicleItems, 'uuid');
      });
    }
  }, [results]);

  useEffect(() => {
    setVehicleSelected(vehicle);
  }, [vehicle, setVehicleSelected]);

  useEffect(() => {
    setShowObservation(contractStep);
  }, [contractStep]);

  useEffect(() => {
    if (isChangeOfVehicle) {
      setAppSpecificLabel(
        i18n('RESERVATIONS__CONTRACT__CHANGE_VEHICLE__BREADCRUMB')
      );
    }
  }, [isChangeOfVehicle, rentalStatuses]);

  useEffect(() => {
    if (changeOfVehicleHasSuccess) {
      setShowChangeOfVehicleForm(false);
    }
  }, [changeOfVehicleHasSuccess]);

  useEffect(() => {
    setFieldValue('contractStep', contractStep || changeOfVehicleHasSuccess);
  }, [contractStep, changeOfVehicleHasSuccess]);

  const handelOnClose = () => {
    closeResponseDialogS();
  };

  const shouldBlock = !isSending && !changeOfVehicleHasError;

  return (
    <PreventChange
      preventChange={shouldBlock}
      onConfirmation={handlePreventChangeConfirmation}
      onCancel={handleCancelPreventChange}
    >
      <ResponseDialog
        open={responseDialogS.open}
        success={responseDialogS.success}
        errorTitle={i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE')}
        errorLabel={i18n('COMMONS__CLOSE__TEXT')}
        errorMessage={responseDialogS.message}
        errorCode={responseDialogS.errorCode}
        onError={handelOnClose}
        onClose={handelOnClose}
      />
      <UpgradeModal
        onCancel={handleOnCancel}
        open={wantUpgrade}
        onUpgrade={handleUpgrade}
      />

      <FormModal
        open={
          showChangeOfVehicleForm &&
          !contractStep &&
          !changeOfVehicleIsLoading &&
          !changeOfVehicleHasError
        }
        onCancel={handleChangeOfVehicleCancel}
        onSubmit={handleSubmitChangeVehicle}
        title={i18n('RESERVATIONS__CONTRACT__CHANGE_VEHICLE_MODAL__TITLE')}
        description={i18n(
          'RESERVATIONS__CONTRACT__CHANGE_VEHICLE_MODAL__DESCRIPTION'
        )}
      >
        <ConnectedSelect
          name="changeVehicleReason"
          label={i18n('RESERVATIONS__CONTRACT__CHANGE_VEHICLE_MODAL__REASON')}
          options="commons.catalogsVehicleChangeReasons"
        />
      </FormModal>

      <FormModal
        open={showObservation}
        onCancel={handleObservationCancel}
        onSubmit={handleObservationSubmit}
        title={i18n('RESERVATIONS__CONTRACT__OBSERVATIONS_MODAL__TITLE')}
        description={i18n(
          'RESERVATIONS__CONTRACT__OBSERVATIONS_MODAL__DESCRIPTION'
        )}
        onlySubmit={contractStep}
      >
        <TextAreaInput
          name="remarks"
          placeholder={i18n('COMMONS__PLACEHOLDER__WRITE')}
          regex={/[()[\]|&;$%@#"{}¿!¡?^/\\|<>+~=`':*¨]/g}
          maxLength={200}
        />
      </FormModal>

      <div className="contract">
        <div className="row">
          <div className="col-12 p-0 d-flex align-items-center">
            <ProgressIndicator
              size={120}
              stroke={['#e2e2e2', '#007aff']}
              strokeWidth={6}
              progress={(step / maxSteps) * 100}
              label={`${step} de ${maxSteps}`}
            />
            <div className="ml-4">
              <h3 className="text-primary">{title}</h3>
              <p>{subtitle}</p>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col" />
          <div className="col-">
            <Input
              onKeyPress={handlePressSearchVehicle}
              left={<Icons.Search width="85%" />}
              name="searchVehicle"
              placeholder="Buscar un vehículo"
              style={{ minWidth: '20rem' }}
              mask={alphaMask(18)}
              maskPlaceholder=""
              upperCase
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12">
            <div className="bg-orange contract__actions-bar">
              {category.label} - {subType}
              <div className="font-weight-bold d-flex">
                <span className="mr-3">Upgrade</span>
                <Icons.Edit
                  onClick={handleOnUpgrade}
                  fill="white"
                  width="1.5rem"
                  height="1.5rem"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'contract__car-list mb-3',
            elements.length === 0 &&
              'd-flex align-items-center justify-content-center'
          )}
        >
          {elements.length > 0 ? (
            elements.map((singleVehicle) => {
              const {
                uuid,
                serialNumber = '',
                brand = '',
                image = '',
                version = '',
                model = '',
                statusName = '',
                statusColor = '',
                plateNumber = '',
                line = '',
                warehouse = '',
              } = singleVehicle;

              return (
                <Card
                  key={uuid}
                  noStatus
                  noTitle
                  padding="px-4 py-2 mb-3 d-flex"
                  onClick={() => handleVehicleClick(singleVehicle)}
                >
                  <div
                    className={`contract__car-list__element ${
                      statusName !== VEHICLE_RENTAL_STATUSES.AVAILABLE
                        ? 'contract__car-list__element--no-selectable'
                        : ''
                    }`}
                  >
                    <div className="contract__car-list__element__image">
                      <img
                        src={image}
                        alt={`${brand} - ${version} - ${model}`}
                      />
                    </div>
                    <div className="contract__car-list__element__line">
                      {line}
                    </div>
                    <div className="contract__car-list__element__brand">
                      {brand}
                    </div>
                    <div className="contract__car-list__element__model">
                      {model}
                    </div>
                    <div className="contract__car-list__element__serial">
                      <span className="pr-3">NO. DE SERIE</span>
                      <span className="font-weight-bold">{serialNumber}</span>
                    </div>
                    <div className="contract__car-list__element__serial">
                      <span className="pr-3">PLACA</span>
                      <span className="font-weight-bold">{plateNumber}</span>
                    </div>
                    <div className="contract__car-list__element__warehouse">
                      <span className="pr-3">ALMACÉN</span>
                      <span className="font-weight-bold">{warehouse}</span>
                    </div>
                    <div className="contract__car-list__element__status">
                      <div
                        className="dash"
                        style={{ backgroundColor: statusColor }}
                      />
                      {statusName}
                    </div>
                    <div className="contract__car-list__element__check">
                      {vehicleSelected.uuid === uuid ? (
                        <Icons.CheckAvailable width="1.5rem" height="1.5rem" />
                      ) : (
                        <Icons.CheckUnavailable
                          width="1.5rem"
                          height="1.5rem"
                        />
                      )}
                    </div>
                  </div>
                </Card>
              );
            })
          ) : (
            <h3 className="text-center font-weight-normal mb-5">
              {i18n('ERROR__COMMONS__NOT_FOUND')}
            </h3>
          )}
          <div className="reference" ref={setRef} />
          <br />
        </div>
        <div className="row">
          <div className="col-12">
            {errors.notValidSubmit && (
              <p className="text-right text-danger">{errors.notValidSubmit}</p>
            )}
          </div>
        </div>
      </div>
    </PreventChange>
  );
};

Contract.propTypes = {
  maxSteps: PropTypes.number,
  step: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Contract.defaultProps = {
  maxSteps: 5,
  step: 1,
  title: i18n('RESERVATIONS__CONTRACT__STEPPER__TITLE'),
  subtitle: i18n('RESERVATIONS__CONTRACT__STEPPER__SUBTITLE'),
};

export default withResponseDialog(withReservationAuth(Contract));
