import { getFieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import _get from 'lodash/get';

export const transformer = (values) => {
  const {
    departureDate,
    departureTime,
    arrivalDate,
    arrivalTime,
    destinyWarehouse = {},
    departureWarehouse = {},
    driverName,
    observations,
    requesterName,
    plateNumber,
    responsibleName,
    realDepartureDate,
    realDepartureTime,
    realArrivalDate,
    realArrivalTime,
    licenseNumber,
    validityDate,
  } = values;

  return {
    observations,
    departureDate,
    departureTime,
    returnDate: arrivalDate,
    arrivalTime,
    initialDepartureDate: departureDate,
    requester: requesterName,
    [getFieldSearch('requester')]: requesterName,
    responsible: responsibleName,
    [getFieldSearch('responsible')]: responsibleName,
    plate: plateNumber,
    [getFieldSearch('plate')]: plateNumber,
    plate_lastSearch: plateNumber,
    source: departureWarehouse.uuid,
    destination: destinyWarehouse.uuid,
    driver: driverName,
    realDepartureDate,
    realDepartureTime,
    realArrivalDate,
    realArrivalTime,
    licenseNumber,
    validityDriverLicense: validityDate,
    emissionState: _get(values, 'issuingState.uuid', ''),
  };
};
