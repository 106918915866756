import React from 'react';
import { CellDashed, CellStatus, CellText } from 'components/ColumnsCells';
import { AdditionalHeaderCamp } from 'components';
import { onlyLettersWithSpaces, numericMask } from 'utils/masks';

const customerTypes = [
  { label: 'Normal', value: 'isNatural' },
  { label: 'Empresa', value: 'isJuridical' },
  { label: 'Extranjero', value: 'isForeign' },
  { label: 'Licitación', value: 'isTender' },
];
const customerStatuses = [
  { label: 'Normal', value: '50995cbc-b7c2-4462-b279-41be2c40e435' },
  { label: 'Boletinado', value: '82c49bce-b66b-4cd0-8950-e0d4b476b3f7' },
  { label: 'Convenio', value: '2822080c-0dd8-49fc-8bb4-9a753b36f4dd' },
];
const documentStatuses = [
  { label: 'Sin verificar', value: 'f4fd4469-0118-490d-87e5-894c997530a6' },
  { label: 'Verificada', value: '49eebad0-549a-4756-9211-93eb1b4ed1e4' },
  { label: 'Rechazada', value: 'b139e52f-fd72-459f-a2ae-7cd0b7261702' },
  { label: 'Vencida', value: '152a788f-8ec5-4b90-83d6-2bf7fa05a2e2' },
  { label: 'Verificación opcional', value: 'e9deb14e-9ea6-439c-8011-84405b53ecbf' },
  { label: 'Necesita verificación', value: '414138bd-c1b5-4a81-b808-0ebccc334af2' },
];

export default [
  {
    label: 'No. Cliente',
    field: 'customerNumber',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(10)}
        name="customerNumber"
        searchKey="customerNumber"
      />
    ),
  },
  {
    label: 'Nombre / Empresa',
    field: 'name',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(40)}
        name="name"
        searchKey="name"
      />
    ),
  },
  {
    label: 'RFC',
    field: 'rfc',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp name="rfc" searchKey="rfc" />
    ),
  },
  {
    label: 'Tipo',
    field: 'customerType',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="type"
        searchKey="type"
        options={customerTypes}
        selectProps={{ fullKey: true, availableFirstValue: true }}
        type="SELECT"
      />
    ),
  },
  {
    label: 'Clasificación',
    field: 'customerClassification',
    align: 'center',
    renderer: CellDashed,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="customerStatus"
        searchKey="customerStatus"
        options={customerStatuses}
        selectProps={{ fullKey: true, availableFirstValue: true }}
        type="SELECT"
      />
    ),
  },
  {
    label: 'Documentación',
    field: 'documentationStatus',
    align: 'center',
    renderer: CellStatus,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="documentStatus"
        searchKey="documentStatus"
        options={documentStatuses}
        selectProps={{ fullKey: true, availableFirstValue: true }}
        type="SELECT"
      />
    ),
  },
];
