export const CATALOG_FETCH_REQUESTED = 'catalogs/CATALOG_FETCH_REQUESTED';
export const CATALOG_FETCH_SUCCEEDED = 'catalogs/CATALOG_FETCH_SUCCEEDED';
export const CATALOG_FETCH_FAILED = 'catalogs/CATALOG_FETCH_FAILED';

export const INSTALLER_FETCH_REQUESTED = 'INSTALLER_FETCH_REQUESTED';
export const INSTALLER_FETCH_SUCCEEDED = 'INSTALLER_FETCH_SUCCEEDED';
export const INSTALLER_FETCH_FAILED = 'INSTALLER_FETCH_FAILED';

export const FILTERS_FETCH_REQUESTED = 'catalogs/FILTERS_FETCH_REQUESTED';
export const FILTERS_FETCH_SUCCEEDED = 'catalogs/FILTERS_FETCH_SUCCEEDED';
export const FILTERS_FETCH_FAILED = 'catalogs/FILTERS_FETCH_FAILED';

export const CATALOG_BY_ID_FETCH_REQUESTED =
  'catalogs/CATALOG_BY_ID_FETCH_REQUESTED';
export const CATALOG_BY_ID_FETCH_SUCCEEDED =
  'catalogs/CATALOG_BY_ID_FETCH_SUCCEEDED';
export const CATALOG_BY_ID_FETCH_FAILED = 'catalogs/CATALOG_BY_ID_FETCH_FAILED';

export const SELECT_INSTALLER = 'SELECT_INSTALLER';
export const SELECT_GPS = 'SELECT_GPS';
export const DELETE_SELECT_GPS = 'DELETE_SELECT_GPS';
export const CLEAR_SELECT_GPS = 'CLEAR_SELECT_GPS';
export const CLEAR_ONE_SELECT_GPS = 'CLEAR_ONE_SELECT_GPS';
export const CLEAR_IMEI_GPS_SELECTED = 'CLEAR_IMEI_GPS_SELECTED';
export const CHANGE_MODE_GPS = 'CHANGE_MODE_GPS';
export const SELECT_IMEI_GPS = 'SELECT_IMEI_GPS';

export const SELECT_BRAND = 'catalogs/SELECT_BRAND';
export const SELECT_LINE = 'catalogs/SELECT_LINE';
export const CLEAR_SELECT_TAGS = 'CLEAR_SELECT_TAGS';

export const SELECT_CIRCULATION_CARD_CLASS =
  'catalogs/SELECT_CIRCULATION_CARD_CLASS';

export const SPECS_REQUESTED = 'catalogs/SPECS_REQUESTED';
export const SPECS_SUCCEEDED = 'catalogs/SPECS_SUCCEEDED';
export const SPECS_FAILED = 'catalogs/SPECS_FAILED';

export const AREAS_FETCH_REQUESTED = 'catalogs/AREAS_FETCH_REQUESTED';
export const AREAS_FETCH_SUCCEEDED = 'catalogs/AREAS_FETCH_SUCCEEDED';
export const AREAS_FETCH_FAILED = 'catalogs/AREAS_FETCH_FAILED';

export const ROLES_FETCH_REQUESTED = 'catalogs/ROLES_FETCH_REQUESTED';
export const ROLES_FETCH_SUCCEEDED = 'catalogs/ROLES_FETCH_SUCCEEDED';
export const ROLES_FETCH_FAILED = 'catalogs/ROLES_FETCH_FAILED';

export const MODULES_FETCH_REQUESTED = 'catalogs/MODULES_FETCH_REQUESTED';
export const MODULES_FETCH_SUCCEEDED = 'catalogs/MODULES_FETCH_SUCCEEDED';
export const MODULES_FETCH_FAILED = 'catalogs/MODULES_FETCH_FAILED';

export const RESTART_SPECS = 'catalogs/RESTART_SPECS';

export const RENTAL_STATUSES_FETCH_REQUESTED =
  'catalogs/RENTAL_STATUSES_FETCH_REQUESTED';
export const RENTAL_STATUSES_FETCH_SUCCEEDED =
  'catalogs/RENTAL_STATUSES_FETCH_SUCCEEDED';
export const RENTAL_STATUSES_FETCH_FAILED =
  'catalogs/RENTAL_STATUSES_FETCH_FAILED';

export const RENTAL_FILTER_FETCH_REQUESTED =
  'catalogs/RENTAL_FILTER_FETCH_REQUESTED';
export const RENTAL_FILTER_FETCH_SUCCEEDED =
  'catalogs/RENTAL_FILTER_FETCH_SUCCEEDED';
export const RENTAL_FILTER_FETCH_FAILED = 'catalogs/RENTAL_FILTER_FETCH_FAILED';
