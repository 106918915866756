import { savePayment } from 'store/reservations/actions';
import { schema } from './schema';
import formatter from './formatter';

export const initialTouched = {
  methodSelected: true,
  cardType_0: true,
  cardType_1: true,
  cardType_2: true,
  bank_0: true,
  bank_1: true,
  bank_2: true,
  cardNumber_0: true,
  cardNumber_1: true,
  cardNumber_2: true,
  reference_0: true,
  reference_1: true,
  reference_2: true,
  movementDate_0: true,
  movementDate_1: true,
  movementDate_2: true,
  authorization_0: true,
  authorization_1: true,
  authorization_2: true,
  movementHour_0: true,
  movementHour_1: true,
  movementHour_2: true,
  voucher_0: true,
  voucher_1: true,
  voucher_2: true,
  amountCard_0: true,
  amountCard_1: true,
  amountCard_2: true,
  amountDeposit: true,
  amountTransfer: true,
  amountCash: true,
  accountStatus: true,
  hasHolderName_0: true,
  hasHolderName_1: true,
  hasHolderName_2: true,
  bankAuthorization: true,
  bankDate: true,
  bankHour: true,
  bankVoucher: true,
  bankTo: true,
  bankClave: true,
  bankReference: true,
  transferDate: true,
  transferHour: true,
  transferVoucher: true,

  diffMetods: true,
  holdbackOtherRetention: true,
  holdbackRetention: true,
  holdbackCardHolder: true,
  holdbackBank: true,
  holdbackCard: true,
  holdbackValidity: true,
  holdbackCode: true,

  wantInvoice: true,
  invoiceSocialReason: true,
  invoiceRfc: true,
  invoiceCfdiUse: true,
  invoicePayForm: true,
  invoicePayMethod: true,
  emailInvoice: true,
  observationsInvoice: true,
  invoiceTaxRegime: true,
  invoiceZipCode: true,
  invoiceZipCodeExist: true,
  zipCode: true,
  state: true,

  totalPriceRent: true,
  priceIsInvalid: true,
};

export const initialValues = {
  methodSelected: '',
  cardType_0: undefined,
  cardType_1: undefined,
  cardType_2: undefined,
  bank_0: '',
  bank_1: '',
  bank_2: '',
  cardNumber_0: '',
  cardNumber_1: '',
  cardNumber_2: '',
  reference_0: '',
  reference_1: '',
  reference_2: '',
  movementDate_0: '',
  movementDate_1: '',
  movementDate_2: '',
  authorization_0: '',
  authorization_1: '',
  authorization_2: '',
  movementHour_0: '',
  movementHour_1: '',
  movementHour_2: '',
  voucher_0: '',
  voucher_1: '',
  voucher_2: '',
  amountCard_0: 0,
  amountCard_1: 0,
  amountCard_2: 0,
  accountStatus_0: '',
  accountStatus_1: '',
  accountStatus_2: '',
  hasHolderName_0: true,
  hasHolderName_1: true,
  hasHolderName_2: true,

  bankAuthorization: '',
  bankDate: '',
  bankHour: '',
  bankVoucher: '',
  amountDeposit: 0,

  bankTo: '',
  bankClave: '',
  bankReference: '',
  transferDate: '',
  transferHour: '',
  transferVoucher: '',
  amountTransfer: 0,

  amountCash: 0,

  diffMetods: false,
  holdbackRetention: '',
  holdbackOtherRetention: '',
  holdbackCardHolder: '',
  holdbackBank: '',
  holdbackCard: '',
  holdbackValidity: '',
  holdbackCode: '',

  wantInvoice: false,
  invoiceSocialReason: '',
  invoiceRfc: '',
  invoiceCfdiUse: '',
  invoicePayForm: '',
  invoicePayMethod: '',
  invoiceTaxRegime: '',
  invoiceZipCode: '',
  invoiceZipCodeExist: false,
  state: '',

  emailInvoice: '',
  isAdding: false,
  zipCode: '',

  totalPriceRent: 0,
  priceIsInvalid: '',
};

const config = {
  schema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  alwaysSetTemporalValues: true,
  formatter,
  submitForm: (request) => {
    console.log('PROOF');
    console.log(request);
    return savePayment({ request });
  },
  withButtons: true,
  btnSubmitLabel: 'Continuar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE REGISTRO',
      message:
        'Una vez cargada la información del cliente tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a Clientes',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};

export default config;
