const RESET = 'dailyRentContracts/RESET';

const GET_LIST = 'dailyRentContracts/GET_LIST';
const GET_LIST_SUCCEED = 'dailyRentContracts/GET_LIST_SUCCEED';
const GET_LIST_FAILED = 'dailyRentContracts/GET_LIST_FAILED';
const SET_PARAMS = 'dailyRentContracts/SET_PARAMS';
const RESET_PARAMS = 'dailyRentContracts/RESET_PARAMS';
const SET_LIST_PAGE = 'dailyRentContracts/SET_LIST_PAGE';

const CLOSE_RESPONSE_DIALOG = 'dailyRentContracts/CLOSE_RESPONSE_DIALOG';
const CLOSE_RESPONSE_DIALOG_VALID_CODE =
  'dailyRentContracts/CLOSE_RESPONSE_DIALOG_VALID_CODE';

const CLOSE_CONTRACT = 'dailyRentContracts/CLOSE_CONTRACT';
const CLOSE_CONTRACT_SUCCEED = 'dailyRentContracts/CLOSE_CONTRACT_SUCCEED';
const CLOSE_CONTRACT_FAILED = 'dailyRentContracts/CLOSE_CONTRACT_FAILED';

const GET_CLOSE_CONTRACT_DETAILS =
  'dailyRentContracts/GET_CLOSE_CONTRACT_DETAILS';
const GET_CLOSE_CONTRACT_DETAILS_SUCCEED =
  'dailyRentContracts/GET_CLOSE_CONTRACT_DETAILS_SUCCEED';
const GET_CLOSE_CONTRACT_DETAILS_FAILED =
  'dailyRentContracts/GET_CLOSE_CONTRACT_DETAILS_FAILED';

const SAVE_CONTRACT_REFUND = 'dailyRentContracts/SAVE_CONTRACT_REFUND';
const SAVE_CONTRACT_REFUND_SUCCEED =
  'dailyRentContracts/SAVE_CONTRACT_REFUND_SUCCEED';
const SAVE_CONTRACT_REFUND_FAILED =
  'dailyRentContracts/SAVE_CONTRACT_REFUND_FAILED';

const SAVE_CONTRACT_PAY = 'dailyRentContracts/SAVE_CONTRACT_PAY';
const SAVE_CONTRACT_PAY_SUCCEED =
  'dailyRentContracts/SAVE_CONTRACT_PAY_SUCCEED';
const SAVE_CONTRACT_PAY_FAILED = 'dailyRentContracts/SAVE_CONTRACT_PAY_FAILED';

const GET_TOTAL_PAY = 'dailyRentContracts/GET_TOTAL_PAY';
const GET_TOTAL_PAY_SUCCEED = 'dailyRentContracts/GET_TOTAL_PAY_SUCCEED';
const GET_TOTAL_PAY_FAILED = 'dailyRentContracts/GET_TOTAL_PAY_FAILED';

const SET_RENTAL_PLAN_OPTIONS = 'dailyRentContracts/SET_RENTAL_PLAN_OPTIONS';
const SET_RENTAL_PLAN_TO_USE = 'dailyRentContracts/SET_RENTAL_PLAN_TO_USE';

export default {
  RESET,
  GET_LIST,
  GET_LIST_SUCCEED,
  GET_LIST_FAILED,
  SET_PARAMS,
  RESET_PARAMS,
  SET_LIST_PAGE,
  CLOSE_RESPONSE_DIALOG,
  CLOSE_RESPONSE_DIALOG_VALID_CODE,
  CLOSE_CONTRACT,
  CLOSE_CONTRACT_SUCCEED,
  CLOSE_CONTRACT_FAILED,
  GET_CLOSE_CONTRACT_DETAILS,
  GET_CLOSE_CONTRACT_DETAILS_SUCCEED,
  GET_CLOSE_CONTRACT_DETAILS_FAILED,
  SAVE_CONTRACT_REFUND,
  SAVE_CONTRACT_REFUND_SUCCEED,
  SAVE_CONTRACT_REFUND_FAILED,
  SAVE_CONTRACT_PAY,
  SAVE_CONTRACT_PAY_SUCCEED,
  SAVE_CONTRACT_PAY_FAILED,
  GET_TOTAL_PAY,
  GET_TOTAL_PAY_SUCCEED,
  GET_TOTAL_PAY_FAILED,
  SET_RENTAL_PLAN_OPTIONS,
  SET_RENTAL_PLAN_TO_USE,
};
