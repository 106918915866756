import React, { useEffect } from 'react';
import { withForm } from '@casanova/casanova-common';
import { TransfersInformation } from '@transfers/sections';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { config } from './config';

const EditTransfer = ({
  match,
  getTransferDetail,
  transferDetail,
  onUpdateForm,
  fetchCatalog,
}) => {
  useEffect(() => {
    getTransferDetail(match.params.id);
  }, [getTransferDetail, match]);

  useEffect(() => {
    fetchCatalog('states');
  }, [fetchCatalog]);

  useEffect(() => {
    if (transferDetail) {
      onUpdateForm(transferDetail);
    }
  }, [onUpdateForm, transferDetail]);

  return <TransfersInformation action={FORM_ACTIONS.EDIT} />;
};

EditTransfer.propTypes = {};

export default withForm({ config })(EditTransfer);
