import React from 'react';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import Section from '@vehicles/VehicleEditor/additionals/Gps/Aditional/Section';
import { YYYY_MM_DD, timeZone } from 'utils/constants';
import { policy as policyFormatter } from './formatters';
import 'moment/locale/es';

const dateValid = (date) => {
  const arrDate = date.split('-');

  if (arrDate[2].length === 1) {
    arrDate[2] = arrDate[2].padStart(2, 0);
  }

  return arrDate.join('-');
};

const emptyPlate = {
  countryState: '',
  paymentType: '',
  plateNumber: '',
  plateProceed: '',
  plateStatus: '',
  plateType: '',
  price: '',
  receipt: null,
  serviceType: '',
  externalPlate: undefined,
  initialReceipt: null,
};

function plateFormatter(data) {
  const {
    vehicleId,
    serviceType,
    plateProceed,
    uuid,
    paymentType,
    externalPlate,
    price,
  } = data;
  const list = [
    'countryState',
    'plateNumber',
    'remarks',
    'inserted',
    'updated',
  ];

  return Object.assign(_pick(data, list), {
    typeService: serviceType,
    plateProceed: moment(plateProceed).tz(timeZone).format(YYYY_MM_DD),
    vehicle: vehicleId,
    // Harcoded until we can get more information
    plateType: '51a0a67e-21ca-4d41-96a3-f4ed43087f8d',
    id: uuid,
    uuid,
    externalPlate,
    price: !externalPlate ? price : undefined,
    paymentType: !externalPlate ? paymentType : '',
  });
}

export const plate = {
  empty: emptyPlate,
  name: 'plate',
  fileFields: ['receipt'],
  targetField: 'plate',
  transformer: function plateTranformer(data) {
    const { uuid, price, files, externalPlate, remarks, plateProceed } = data;

    let receiptFile;
    if (files) {
      const { receipt } = files;
      receiptFile = receipt;
    }

    return {
      ...emptyPlate,
      ..._pick(data, ['plateNumber', 'inserted', 'updated']),
      id: uuid,
      uuid,
      externalPlate,
      price: externalPlate ? '' : price.toString(),
      paymentType: _get(data, 'paymentType.uuid'),
      countryState: _get(data, 'countryState.uuid'),
      typePlate: _get(data, 'typePlate.uuid'),
      serviceType: _get(data, 'typeServices.uuid'),
      plateStatus: _get(data, 'plateStatus.name', '').toUpperCase(),
      plateProceed: plateProceed
        ? moment(plateProceed).tz(timeZone).format(YYYY_MM_DD)
        : '',
      receipt: _get(receiptFile, 'pdf.originalName', null),
      initialReceipt: _get(receiptFile, 'pdf.originalName', null),
      receiptUrl: _get(receiptFile, 'pdf.presignedUrl', ''),
      remarks,
    };
  },
  formatter: plateFormatter,
};

const emptyCirculationCard = {
  card: null,
  cardClass: '',
  cardStatus: '',
  type: '',
  folio: '',
  issueDate: '',
  paymentType: '',
  plate: '',
  price: '',
  circulationCardReceipt: null,
  validity: '',
  certifiedCopy: false,
  certifiedCopy1Folio: '',
  certifiedCopy2Folio: '',
  certifiedCopyDate: '',
  lost: false,
  dateLost: '',
  ccRemarks: '',
};

function circulationCardFormatter(data) {
  const {
    id,
    vehicleId,
    issueDate,
    folio,
    price,
    certifiedCopy,
    lost,
    ccRemarks,
  } = data;
  const formatted = {
    id,
    validity: _get(data, 'validity.uuid', data.validity),
    type: _get(data, 'type.uuid', data.type),
    cardClass: _get(data, 'cardClass.uuid', data.cardClass),
    paymentType: _get(data, 'paymentType.uuid', data.paymentType),
    plate: _get(data, 'plate.uuid', data.plate),
    vehicle: vehicleId,
    issueDate: moment(issueDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD),
    folio: folio.toString(),
    price: price.toString(),
    files: _get(data, 'files', {}),
    certifiedCopy,
    certifiedCopy1Folio: certifiedCopy ? data.certifiedCopy1Folio : '',
    certifiedCopy2Folio: certifiedCopy ? data.certifiedCopy2Folio : '',
    certifiedCopyDate: certifiedCopy
      ? moment(data.certifiedCopyDate, YYYY_MM_DD)
          .tz(timeZone)
          .format(YYYY_MM_DD)
      : '',
    lost,
    dateLost: lost
      ? moment(data.dateLost, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
      : '',
    remarks: ccRemarks,
  };

  return formatted;
}

export const circulationCard = {
  empty: emptyCirculationCard,
  fileFields: ['receipt', 'card'],
  targetField: 'uuid',
  transformer: function circulationCardTransformer(data) {
    const {
      uuid,
      folio,
      price,
      files,
      inserted,
      updated,
      certifiedCopy,
      certifiedCopy1Folio,
      certifiedCopy2Folio,
      certifiedCopyDate,
      lost,
      issueDate,
      dateLost,
      remarks,
    } = data;
    let receiptFile;
    let cardFile;
    if (files) {
      const { receipt, card } = files;
      receiptFile = receipt;
      cardFile = card;
    }

    const response = {
      ...data,
      id: uuid,
      ccRemarks: remarks,
      uuid,
      folio: folio.toString(),
      price: price.toString(),

      circulationCardReceipt: _get(receiptFile, 'pdf.originalName', null),
      initialCirculationCardReceipt: _get(
        receiptFile,
        'pdf.originalName',
        null
      ),
      circulationCardReceiptUrl: _get(receiptFile, 'pdf.presignedUrl', ''),
      cardClass: _get(data, 'cardClass.uuid', ''),
      type: _get(data, 'type.uuid', ''),
      card: cardFile ? cardFile.originalName : null,
      initialCard: cardFile ? cardFile.originalName : null,
      cardUrl: cardFile ? cardFile.presignedUrl : '',
      cardStatus: _get(data, 'status.name', 'Vigente'),
      validity: _get(data, 'validity.uuid', ''),
      issueDate: issueDate
        ? moment(issueDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
      inserted,
      updated,
      certifiedCopy,
      certifiedCopy1Folio,
      certifiedCopy2Folio,
      certifiedCopyDate: certifiedCopy
        ? moment(certifiedCopyDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
      lost,
      dateLost: lost
        ? moment(dateLost, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
    };
    return response;
  },
  formatter: circulationCardFormatter,
};

export const emptyRoadTax = {
  folio: '',
  price: '',
  paymentType: '',
  fiscalYear: '',
  plate: '',
  roadTaxReceipt: null,
  initialroadTaxReceipt: null,
  roadTaxReceiptUrl: '',
  txRemarks: '',
};

function roadTaxFormatter(data) {
  const { id, vehicleId, price, txRemarks } = data;
  const list = ['folio', 'paymentType', 'fiscalYear', 'plate'];
  return Object.assign(_pick(data, list), {
    id,
    price: `${price}`,
    vehicle: vehicleId,
    remarks: txRemarks,
  });
}

export const roadTax = {
  empty: emptyRoadTax,
  fileFields: ['receipt'],
  targetField: 'uuid',
  transformer: function roadTaxTranformer(data = {}, empty) {
    const {
      uuid,
      fiscalYear,
      folio,
      price,
      files,
      inserted,
      updated,
      remarks,
    } = data;

    let receiptFile;
    if (files) {
      const { receipt } = files;
      receiptFile = receipt;
    }

    return {
      id: uuid,
      txRemarks: remarks,
      paymentType: _get(data, 'paymentType.uuid'),
      status: _get(data, 'status.name'),
      plate: _get(data, 'plate.uuid'),
      folio,
      price: `${price}`,
      fiscalYear,
      roadTaxReceipt: receiptFile ? receiptFile.pdf.originalName : null,
      initialroadTaxReceipt: receiptFile ? receiptFile.pdf.originalName : null,
      roadTaxReceiptUrl: receiptFile ? receiptFile.pdf.presignedUrl : '',
      inserted,
      updated,
    };
  },
  formatter: roadTaxFormatter,
};

const emptyVerification = {
  plate: '',
  dateProcess: '',
  amountFine: '',
  nextProcess: '',
  verificationType: '',
  hologram: '',
  paymentType: '',
  amountPaid: '',
  folio: '',
  verificationReceipt: null,
  certificate: null,
  putSign: false,
  verificationRemarks: '',
};

export const verification = {
  empty: emptyVerification,
  targetField: 'uuid',
  fileFields: ['receipt', 'certificate'],
  transformer: function verificationTransformer(data) {
    const {
      uuid,
      folio,
      dateProcess,
      amountFine,
      amountPaid,
      nextProcess,
      files,
      putSign,
      inserted,
      updated,
      remarks,
    } = data;

    let receiptFile;
    let certificateFile;
    if (files) {
      const { receipt, certificate } = files;
      receiptFile = receipt;
      certificateFile = certificate;
    }

    return {
      ...data,
      id: uuid,
      verificationRemarks: remarks,
      dateProcess: moment(dateProcess).tz(timeZone).format(YYYY_MM_DD),
      nextProcess: moment(nextProcess).tz(timeZone).format(YYYY_MM_DD),
      putSign,
      amountFine,
      amountPaid: `${amountPaid}`,
      folio,
      initialFolio: folio,
      plate: _get(data, 'plate.uuid', data.plate),
      paymentType: _get(data, 'paymentType.uuid', data.paymentType),
      hologram: _get(data, 'hologram.uuid', data.hologram),
      verificationStatus: _get(data, 'status.name', 'Vigente'),
      verificationType: _get(data, 'type.uuid', data.type),
      initialVerificationReceipt: _get(receiptFile, 'pdf.originalName', null),
      verificationReceipt: _get(receiptFile, 'pdf.originalName', null),
      verificationReceiptUrl: _get(receiptFile, 'pdf.presignedUrl', ''),
      certificate: certificateFile ? certificateFile.originalName : null,
      initialCertificate: certificateFile ? certificateFile.originalName : null,
      certificateUrl: certificateFile ? certificateFile.presignedUrl : '',
      inserted,
      updated,
    };
  },
  formatter(data) {
    const {
      uuid,
      folio,
      dateProcess,
      amountFine,
      amountPaid,
      nextProcess,
      verificationReceipt: receipt,
      certificate,
      vehicleId,
      putSign,
      verificationRemarks,
    } = data;

    return {
      id: uuid,
      vehicle: vehicleId,
      dateProcess: moment(dateProcess).tz(timeZone).format(YYYY_MM_DD),
      nextProcess: moment(nextProcess).tz(timeZone).format(YYYY_MM_DD),
      putSign,
      amountFine,
      amountPaid,
      folio,
      plate: _get(data, 'plate.uuid', data.plate),
      paymentType: _get(data, 'paymentType.uuid', data.paymentType),
      hologram: _get(data, 'hologram.uuid', data.hologram),
      status: _get(data, 'status.uuid', 'Vigente'),
      type: _get(data, 'type.uuid', data.verificationType),
      receipt,
      certificate,
      remarks: verificationRemarks,
    };
  },
};

export const emptyFine = {
  finePlate: '',
  fineFolio: '',
  fineDate: '',
  situation: '',
  finePaymentType: '',
  amountPaidFine: '',
  fineReceipt: null,
  sanction: '',
  reason: '',
  statusFine: '',
  paid: true,
};

export const fine = {
  empty: emptyFine,
  targetField: 'uuid',
  fileFields: ['receipt'],
  transformer: function fineTranformer(values) {
    let data = _get(values, 'content[0]', values);
    if (values.content.length > 1) {
      data = values.content[values.content.length - 1];
    }

    const { files } = values;

    const { uuid, amountPaid, inserted, updated } = data;

    let receiptFile;

    if (files) {
      const { receipt } = files;
      receiptFile = receipt;
    }

    return {
      ...emptyFine,
      id: uuid,
      uuid,

      finePlate: _get(data, 'plate', ''),
      fineFolio: _get(data, 'folio', ''),
      fineDate: _get(data, 'fineDate', ''),
      amountPaidFine: amountPaid,
      initialFineReceipt: _get(receiptFile, 'pdf.originalName', null),
      fineReceipt: _get(receiptFile, 'pdf.originalName', null),
      fineReceiptUrl: _get(receiptFile, 'pdf.presignedUrl', ''),
      sanction: _get(data, 'sanction.uuid', ''),
      reason: _get(data, 'reason', ''),
      vehicle: values.vehicleId,
      inserted,
      updated,
    };
  },
  formatter(values) {
    const { uuid, amountPaidFine, fineDate, reason, sanction } = values;
    return {
      id: uuid,
      uuid,
      amountPaid: amountPaidFine,
      fineDate: moment(fineDate).tz(timeZone).format(YYYY_MM_DD),
      reason,
      sanction,
      folio: values.fineFolio,
      paymentType: values.finePaymentType,
      plate: values.finePlate,
      status: values.situation,
      vehicle: values.vehicleId,
    };
  },
};

const emptyPolicy = {
  plate: '',
  insurance: '',
  policyNumber: '',
  endorsement: '',
  topic: '',
  validityStart: '',
  validityEnd: '',
  emissionDate: '',
  policyUse: '',
  paymentType: '',
  totalBonus: '',
  policyReceipt: null,
  policy: null,
  status: '',
  policyRemarks: '',
};

export const policy = {
  empty: emptyPolicy,
  targetField: 'policy',
  fileFields: ['receipt', 'policy'],
  transformer: function policyTranformer(data) {
    const { files, validityStart, validityEnd, emissionDate, remarks } = data;

    const fields = ['endorsement', 'topic', 'receipt', 'inserted', 'updated'];

    let receiptFile;
    let policyFile;
    if (files) {
      const { receipt, policy: policyData } = files;
      receiptFile = receipt;
      policyFile = policyData;
    }

    return {
      ...emptyPolicy,
      ..._pick(data, fields),
      id: _get(data, 'uuid'),
      policyRemarks: remarks,
      uuid: _get(data, 'uuid'),
      plate: _get(data, 'plate.uuid'),
      insurance: _get(data, 'insurance.uuid'),
      policyUse: _get(data, 'policyUse.uuid'),
      paymentType: _get(data, 'paymentType.uuid'),
      initialPolicyNumber: _get(data, 'policyNumber'),
      policyNumber: _get(data, 'policyNumber'),
      totalBonus: `${data.totalBonus}`,

      initialPolicyReceipt: _get(receiptFile, 'pdf.originalName', null),
      policyReceipt: _get(receiptFile, 'pdf.originalName', null),
      policyReceiptUrl: _get(receiptFile, 'pdf.presignedUrl', ''),
      status: _get(data, 'status.name', ''),
      initialPolicy: _get(policyFile, 'originalName', null),
      policy: _get(policyFile, 'originalName', null),
      policyUrl: _get(policyFile, 'presignedUrl', ''),
      validityStart: validityStart
        ? moment(validityStart, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
      validityEnd: validityEnd
        ? moment(validityEnd, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
      emissionDate: emissionDate
        ? moment(emissionDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
    };
  },
  formatter: policyFormatter,
};

const emptySinister = {
  sinisterNumber: '',
  policyNumber: '',
  sinisterDate: '',
  sinisterType: '',
  sinisterStatus: '',
  description: '',
};

export const sinister = {
  empty: emptySinister,
  target: 'uuid',
  transformer: function policyTranformer(values) {
    let data = _get(values, 'content[0]', values);
    if (values.content.length > 1) {
      data = values.content[values.content.length - 1];
    }

    const sinisterDate = _get(data, 'date', '');

    const response = {
      ...emptySinister,
      ..._pick(data, ['inserted', 'updated']),
      id: _get(data, 'uuid', ''),
      uuid: _get(data, 'uuid', ''),
      description: _get(data, 'description', ''),
      sinisterNumber: _get(data, 'sinisterNumber', ''),
      sinisterDate:
        sinisterDate !== ''
          ? moment(sinisterDate).tz(timeZone).format(YYYY_MM_DD)
          : '',
      sinisterStatus: _get(data, 'status.uuid', ''),
      sinisterType: _get(data, 'type.uuid', ''),
      policyNumber: _get(data, 'policy.uuid', ''),
    };

    return response;
  },
  formatter(data) {
    const {
      description,
      policyNumber,
      sinisterNumber,
      sinisterDate,
      sinisterType,
      vehicleId,
      sinisterStatus,
    } = data;

    return {
      date: moment(sinisterDate).tz(timeZone).format(YYYY_MM_DD),
      description,
      number: sinisterNumber,
      policy: policyNumber,
      type: sinisterType,
      vehicle: vehicleId,
      status: sinisterStatus,
    };
  },
};

const emptyGps = {
  instalationDate: '',
  installer: '',
  icon: '',
  gps: [
    {
      gpsTag:'',
      tagGPS: '',
      imeiGPS: '',
      remarks: '',
      installationPlace: '',
      label: 'GPS 1',
      content: <Section />,
      insertOrder: 1,
    },
  ],
  orders: [],
  available: 2,
};
export const gps = {
  empty: emptyGps,
  targetField: 'pdf',
  transformer: function policyTranformer(values) {
    // console.log("🚀 ~ policyTranformer ~ values:", values)
    const orders = _get(values, 'gpsOrders', []);
    const gps_orders = [];
    const objectSection = {
      //gpsTag: '',
      //isCustom: false,
      tagGPS: '',
      imeiGPS: '',
      remarks: '',
      installationPlace: '',
      label: 'GPS 1',
      insertOrder: 1,
      content: <Section />,
    };

    let cont_gps = 0;
    let available = 0;
    try {
      orders.forEach((order) => {
        order.containedGps.forEach((gps) => {
          cont_gps += 1;
          gps.label = `GPS ${cont_gps}`;
        });
        // order.operationDate = moment(order.perationDate).tz(timeZone).format(YYYY_MM_DD)
        // order.containedGps[index]['label'] = `GPS ${cont_gps}`
      });
    } catch (error) {
      console.log(error);
    }
    const ordersSorted = [];
    orders.forEach((order) => {
      ordersSorted.push({
        ...order,
        containedGps: order.containedGps.sort((a, b) => {
          if (a.insertOrder < b.insertOrder) {
            return -1;
          }
          if (a.insertOrder > b.insertOrder) {
            return 1;
          }
          return 0;
        }),
      });
    });

    switch (cont_gps) {
      case 1:
        gps_orders.push({ ...objectSection, label: `GPS 2` });
        available = 2;
        break;
      case 2:
        gps_orders.push({ ...objectSection, label: `GPS 3` });
        available = 0;
        break;
      case 3:
        gps_orders.push({ ...objectSection, label: `GPS 1` });
        available = 2;
        break;
      default:
        gps_orders.push({ ...objectSection, label: `GPS 1` });
        available = 3;
        break;
    }
    const response = {
      ...emptyGps,
      id: _get(values, 'gpsOrders.0.uuid', ''),
      uuid: _get(values, 'gpsOrders.0.uuid', ''),
      orders: ordersSorted,
      totalInstalledGps: _get(values, 'totalInstalledGps', 0),
      gps: gps_orders,
      available,
    };

    return response;
  },
  formatter(values) {
    const {
      extraInstaller,
      installer,
      instalationDate,
      vehicleId,
      gps,
      icon,
      type,
    } = values;
    const gps_orders = [];
    gps.forEach((el, index) => {
      console.log(el);
      gps_orders.push({
        // isCustom: el.isCustom,
        gpsTag: el.gpsTag,
        imeiGps: el.imeiGPS,
        gpsUuid: el.tagGPS,
        installationPlace: el.installationPlace,
        remarks: el.remarks,
        insertOrder: el.insertOrder,
        icon: el.icon,
      });
    });
    // console.log("first: ", gps_orders)
    let bodyReq = {
      vehicleUuid: vehicleId,
      gpsInstallerUuid: installer,
      operationDate: dateValid(instalationDate),
      gpsToOperateList: gps_orders,
      icon,
      type,
    };
    // console.log("🚀 ~ formatter ~ bodyReq:", bodyReq)
    if (extraInstaller && extraInstaller !== '')
      bodyReq = { ...bodyReq, gpsInstallerExtraUuid: extraInstaller };

    return bodyReq;
  },
};

const emptyKeys = {
  tabSelected: 0,
  keys: [
    {
      elementIndex: 0,
      propertySelected: 'lend',
      lend: {
        lendDate: '',
        lendResponsible: '',
        lendBranch: '',
        branch: '',
        lendReason: '',
      },
      lost: {
        lostDate: '',
        lostDescription: '',
      },
      sinister: {
        sinister: '',
        sinisterResponsible: '',
      },
    },
  ],
};
export const key = {
  empty: emptyKeys,
  targetField: 'uuid',
  transformer: function policyTranformer(values) {
    const keys = [];
    let response;
    if (Array.isArray(values)) {
      values.forEach((key, index) => {
        delete key.insert;
        keys.push({
          ...key,
          label: `LLAVE ${index + 1}`,
          resguardo: key.keyStatus.name,
          lost: {
            elementIndex: index,
            propertySelected: key.keyStatus.name,
            lostDate: key.lostDate
              ? moment(key.lostDate).tz(timeZone).format(YYYY_MM_DD)
              : '',
            lostDescription: key.lostDescription ? key.lostDescription : '',
          },
          lend: {
            elementIndex: index,
            propertySelected: key.keyStatus.name,
            lendDate: key.lendDate
              ? moment(key.lendDate).tz(timeZone).format(YYYY_MM_DD)
              : '',
            lendResponsible: key.lendResponsible,
            lendBranch: key.lendBranch ? key.lendBranch.uuid : undefined,
            lendBranchDetails: key.lendBranch ? key.lendBranch.name : undefined,
            branch: _get(key, 'lendOtherBranch', ''),
            lendReason: key.lendReason,
          },
          sinister: {
            elementIndex: index,
            propertySelected: key.keyStatus.name,
            sinister: '',
            sinisterResponsible: '',
          },
        });
      });

      response = {
        ...emptyKeys,
        id: values[0].uuid,
        uuid: values[0].uuid,
        key: keys,
      };
    } else {
      response = {
        ...emptyKeys,
        id: _get(values, 'uuid', ''),
        uuid: _get(values, 'uuid', ''),
      };
    }
    return response;
  },
  formatter(values) {
    if (_get(values, 'serialNumber', false)) {
      return values;
    }

    const keys = _get(values, 'key', []);
    const requestData = [];
    keys.forEach((key, k) => {
      requestData.push({
        lendBranch: _get(key, `lend.lendBranch`, ''),
        lendDate:
          _get(key, `lend.lendDate`, '') === ''
            ? ''
            : dateValid(_get(key, `lend.lendDate`, '')),
        lendOtherBranch: _get(key, `lend.branch`, ''),
        lendReason: _get(key, `lend.lendReason`, ''),
        lendResponsible: _get(key, `lend.lendResponsible`, ''),
        lostDate:
          _get(key, `lost.lostDate`, '') === ''
            ? ''
            : dateValid(_get(key, `lost.lostDate`, '')),
        lostDescription: _get(key, `lost.lostDescription`, ''),
        sinister: _get(key, 'sinister.sinister', ''),
        sinisterResponsible: _get(key, 'sinister.sinisterResponsible', ''),
        status: _get(key, `keyStatus.uuid`, ''),
        id: key.uuid,
      });
    });
    return requestData;
  },
};
