import * as Yup from 'yup';
import _isEmpty from 'lodash/isEmpty';
import i18n from '@i18n';
import { dynamicRequired } from 'utils/validations/common';
import { MESSAGE_ZIP_CODE } from '../../../reservations/sections/InvoicePayment/constants';
import { RFC } from '../../../../utils/constants/regularExpressions';

const notEquals = (elements, property) => {
  if (elements.length <= 1) {
    return true;
  }
  const all = elements
    .filter((iData) => !_isEmpty(iData[property]))
    .map((iData) => iData[property]);

  if (all.length === 0) {
    return true;
  }

  const withoutDuplicates = [...new Set(all)];

  return all.length === withoutDuplicates.length;
};

const params = { parentFields: true };

export const invoiceDataSchema = Yup.object().shape({
  socialReason: dynamicRequired(params)
    .max(251, i18n('ERROR__COMMONS__MAX_LENGTH', [251]))
    .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3])),
  rfc: dynamicRequired(params).test(
    'invalid-rfc-format',
    i18n('ERROR__RESERVATIONS__PAYMENT__RFC__INVALID'),
    (value) => {
      if (!value) return true;
      return RFC.test(value);
    }
  ),
  /* fiscalRegime: dynamicRequired(params), */
  taxZipCode: dynamicRequired(params).when('invoiceTaxZipCodeExist', {
    is: false,
    then: dynamicRequired(params)
      .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5]))
      .test({
        name: 'invoiceTaxZipCodeExist',
        exclusive: true,
        message: MESSAGE_ZIP_CODE,
        test: (value) => value === true,
      }),
  }),
  /* cfdiUse: dynamicRequired(params), */
});

export const invoiceDataProps = {
  globalValidation: Yup.boolean()
    .test(
      'default-selected',
      'Es necesario seleccionar una opcion predeterminada',
      function () {
        const { invoiceData = [] } = this.parent;
        if (invoiceData.length <= 1) {
          return true;
        }
        const predefined = invoiceData.filter((id) => id.useDefault);
        return predefined.length > 0;
      }
    )
    .test(
      'different-social-reason',
      'Las razones sociales no pueden repetirse',
      function () {
        const { invoiceData = [] } = this.parent;
        return notEquals(invoiceData, 'socialReason');
      }
    )
    .test('different-rfc', 'Los RFCs no pueden repetirse', function () {
      const { invoiceData = [] } = this.parent;
      return notEquals(invoiceData, 'rfc');
    }),
  invoiceData: Yup.array().of(invoiceDataSchema),
};

export const InvoiceDataSchema = Yup.object().shape({
  ...invoiceDataProps,
});

export default InvoiceDataSchema;
