import React from 'react';
import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';

import { Icons } from '@casanova/casanova-common';
import { getQuotePath } from 'utils/navigation';
import { withLayout } from 'hooks';
import { Blank } from 'components';
import { DeliveryReturnStepOne } from '@reservations/sections/DeliveryRefluxSteps';

import { config } from './config';

export default withLayout(
  compose(
    connect((state) => {
      const { folio = '' } = state.reservations.reservationInfo.data;
      return {
        onCancelPath: `${getQuotePath('one')}?isCanceling=true`,
        left: <Blank />,
        status: _get(state, 'reservations.delivery', false),
        leftCol: 4,
        middleCol: 4,
        confirmationMessage: true,
        IconsResponseDialog: {
          Success: () => <Icons.Logo />,
        },
        config: config({ folio }),
        responseDialog: {},
      };
    })
  )(DeliveryReturnStepOne)
);
