import { VEHICLE_DETAIL_FIELDS } from '@vehicles/common/constants';
import {
  EDIT_VEHICLE_MASTER_DATA,
  EDIT_VEHICLE_PLATE_NUMBER,
} from 'utils/roles/permissions';
import { validateRolePermissions } from 'utils/roles';

export const editVehicleDetailFormatter = (values) => {
  let valuesToUpdate = {
    wareHouse: values[VEHICLE_DETAIL_FIELDS.DEPOT],
    status: values[VEHICLE_DETAIL_FIELDS.STATUS],
    businessAreaUuid: values[VEHICLE_DETAIL_FIELDS.BUSINESS_AREA],
    substatus: values[VEHICLE_DETAIL_FIELDS.SUBSTATUS],
    vehicleUuid: values.vehicleUuid,
  };
  if (validateRolePermissions(EDIT_VEHICLE_PLATE_NUMBER))
    valuesToUpdate = {
      vehicleUuid: values.vehicleUuid,
      plateNumber: values[VEHICLE_DETAIL_FIELDS.PLATE],
    };
  if (validateRolePermissions(EDIT_VEHICLE_MASTER_DATA))
    valuesToUpdate = {
      ...valuesToUpdate,
      plateNumber: values[VEHICLE_DETAIL_FIELDS.PLATE],
      version: values[VEHICLE_DETAIL_FIELDS.VERSION],
      transmissionType: values[VEHICLE_DETAIL_FIELDS.TRANSMISSION_TYPE],
      color: values[VEHICLE_DETAIL_FIELDS.COLOR],
      model: values[VEHICLE_DETAIL_FIELDS.MODEL],
      mileage: values[VEHICLE_DETAIL_FIELDS.KM],
      fuelType: values[VEHICLE_DETAIL_FIELDS.FUEL],
      category: values[VEHICLE_DETAIL_FIELDS.CATEGORY],
    };
  if (values[VEHICLE_DETAIL_FIELDS.PLATE] === values.lastPlateNumber)
    delete valuesToUpdate[VEHICLE_DETAIL_FIELDS.PLATE];

  return valuesToUpdate;
};
