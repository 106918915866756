import { cardInitialTouched, cardInitialValues } from '../CardMethod/config';
import { cashInitialTouched, cashInitialValues } from '../CashMethod/config';
import {
  depositInitialTouched,
  depositInitialValues,
} from '../DepositMethod/config';
import {
  invoiceInitialTouched,
  invoiceInitialValues,
} from '../InvoicePayment/config';
import {
  transferInitialTouched,
  transferInitialValues,
} from '../TransferMethod/config';
import { obtenerFechaActual } from '../index';

export const multiPaymentsInitialTouched = {
  diffMethods: true,
  methodSelected: true,
  selectedMethods: true,
  priceIsInvalid: true,
  paymentDate: true,
  paymentTime: true,
  ...cardInitialTouched,
  ...cashInitialTouched,
  ...depositInitialTouched,
  ...invoiceInitialTouched,
  ...transferInitialTouched,
};

export const multiPaymentsInitialValues = {
  diffMethods: false,
  selectedMethods: [],
  priceIsInvalid: '',
  methodSelected: '',
  paymentDate: obtenerFechaActual(),
  paymentTime: '',
  ...cardInitialValues,
  ...cashInitialValues,
  ...depositInitialValues,
  ...invoiceInitialValues,
  ...transferInitialValues,
};
