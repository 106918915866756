import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cln from 'classnames';
import _get from 'lodash/get';

import { Section, TextField } from '@casanova/casanova-common';
import { Card } from 'components';

import { useReservation } from 'hooks';

import i18n from '@i18n';
import { number2mxn } from 'utils/currency';
import { getPaymentMethod } from '@reservations/commons/utils';
import { isJuridicalCustomer } from '@customers/common/utils';

export default function Invoice({
  title = i18n('RESERVATIONS__DETAIL__INVOICE_SECTION__TITLE'),

  showInvoiceSummaryData = true,
  showDaysDifference = true,
  showRental = true,
  showExtraChargesInfo = false,
  onlyShowTotalPrice = false,
  notInfoPayment = false,

  withDiscount = true,
  withSection = true,

  showSubtotal = true,
  subtotal = 0,

  electronicInvoice = 'PÚBLICO EN GENERAL',

  containerInvoiceClassName,
  detailInvoiceClassName,
  priceTitleClassName,
  subtotalClassName,

  catalogsBankCardTypes,
  paymentInfo: externalPaymentInfo,

  getDetail,
  customerDetail,
}) {
  const [paymentMethod, setPaymentMethod] = useState('');

  const {
    invoiceInfo,
    paymentInfo,
    reservationInfo,
    extraCharges,
  } = useReservation();
  const {
    total: totalAmount,
    rental,
    // IVAOfAmount,
    daysDifference,
    discountAmount,
    discountWord,
  } = invoiceInfo;

  const IVAOfAmount = subtotal ? subtotal * 0.16 : invoiceInfo.IVAOfAmount;

  const { naturalPerson = {} } = reservationInfo;
  const { uuid: customerUuid } = naturalPerson;

  const isJuridical = isJuridicalCustomer(customerDetail);
  const email = _get(customerDetail, 'userInfo.email', '');
  const customerName = _get(customerDetail, 'fullName', '');
  const businessName = _get(customerDetail, 'juridicalPerson.businessName', '');
  const rfc = _get(customerDetail, 'juridicalPerson.rfc', '');
  const cfdiUse = _get(customerDetail, 'juridicalPerson.cfdiUse', '');

  const { totalPrice = 0 } = extraCharges;
  const total = showExtraChargesInfo ? totalPrice : totalAmount;

  const invoiceSummaryClass = cln(
    'Detail__invoice-summary__data',
    notInfoPayment && 'd-flex justify-content-start align-items-center'
  );

  useEffect(() => {
    const method = getPaymentMethod({
      paymentInfo: externalPaymentInfo || paymentInfo,
      catalogsBankCardTypes,
    });
    setPaymentMethod((currentMethod) => method || currentMethod);
  }, [catalogsBankCardTypes, paymentInfo, externalPaymentInfo]);

  useEffect(() => {
    if (customerUuid) {
      getDetail(customerUuid);
    }
  }, [customerUuid, getDetail]);

  const content = (
    <div className={cln('Detail__invoice-summary', containerInvoiceClassName)}>
      <div className={invoiceSummaryClass}>
        {showInvoiceSummaryData && !onlyShowTotalPrice && (
          <>
            {notInfoPayment ? (
              <div className="row" style={{ width: '100%' }}>
                <div className="col-6">
                  <TextField title="PAGO" value="Sin pago" />
                </div>
                <div className="col-6">
                  <TextField
                    title="FACTURA ELECTRÓNICA"
                    value={electronicInvoice}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-4">
                    <TextField title="PAGO" value="Sin pago" />
                  </div>
                  <div className="col-4">
                    <TextField title="RFC" value={rfc} />
                  </div>
                  <div className="col-4">
                    <TextField
                      title="Razón social"
                      value={isJuridical ? businessName : customerName}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <TextField title="Uso de la factura" value={cfdiUse} />
                  </div>
                  <div className="col-4">
                    <TextField title="Correo electrónico" value={email} />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <div
        className={cln(
          'Detail__invoice-summary__amount',
          detailInvoiceClassName
        )}
      >
        {showRental && !onlyShowTotalPrice && (
          <div className="common-info-text text-right text-uppercase">
            Renta: {number2mxn(rental)} <br />
            {withDiscount && discountAmount > 0 && (
              <span>
                {discountWord}: {number2mxn(discountAmount)}
              </span>
            )}
            <br />
            IVA: {number2mxn(IVAOfAmount)}
          </div>
        )}

        <br />

        <div
          className={cln(
            'common-info-text font-weight-bold text-uppercase d-flex justify-content-between flex-column',
            subtotalClassName
          )}
        >
          {showSubtotal && subtotal > 0 && (
            <>
              <div className="common-info-text">
                SUBTOTAL: {number2mxn(subtotal)}
                <br />
                IVA: {number2mxn(IVAOfAmount)}
              </div>
              <br />
            </>
          )}

          <div>PRECIO TOTAL</div>
          {withDiscount && discountAmount > 0 && (
            <div className="text-right text-decoration-line-through ">
              {number2mxn(total)}
            </div>
          )}
        </div>

        <br />
        <div
          className={cln(
            'gray-amount-text text-uppercase text-right',
            priceTitleClassName
          )}
        >
          MXN {number2mxn(withDiscount ? total - discountAmount : total)}
        </div>

        {showDaysDifference && daysDifference > 0 && (
          <div className="common-info-text font-weight-bold text-uppercase text-right">
            POR {daysDifference} DÍAS
          </div>
        )}
      </div>
    </div>
  );

  return withSection ? (
    <Section title={title}>
      <Card noStatus noTitle padding="p-0">
        {content}
      </Card>
    </Section>
  ) : (
    content
  );
}

Invoice.propTypes = {
  title: PropTypes.string,

  showInvoiceSummaryData: PropTypes.bool,
  showRental: PropTypes.bool,
  showExtraChargesInfo: PropTypes.bool,
  showDaysDifference: PropTypes.bool,
  notInfoPayment: PropTypes.bool,

  withDiscount: PropTypes.bool,
  withSection: PropTypes.bool,

  showSubtotal: PropTypes.bool,
  subtotal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  electronicInvoice: PropTypes.string,

  containerInvoiceClassName: PropTypes.string,
  detailInvoiceClassName: PropTypes.string,
  priceTitleClassName: PropTypes.string,
  subtotalClassName: PropTypes.string,

  paymentInfo: PropTypes.shape({
    method: PropTypes.string,
    bankCardType: PropTypes.string,
    type: PropTypes.string,
  }),
};
