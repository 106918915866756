/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import { Icons } from '@casanova/casanova-common';

const transformRefToEvent = (ref) => {
  const event = {
    target: {
      ...ref.current,
    },
  };

  return event;
};

const ListItem = ({
  name,
  option,
  options = [],
  fullWidth,
  onChange = _noop,
  arrayHelpers,
  onlyIcons,
  isCheck,
  disabled,
  className,
  labelClassName,
}) => {
  const { values } = arrayHelpers.form;
  const inputRef = useRef({});

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li
      className={className}
      style={fullWidth ? { width: `${100 / options.length}%` } : {}}
      onClick={() =>
        onChange(transformRefToEvent(inputRef), option, arrayHelpers)
      }
    >
      <label className={labelClassName}>
        <input
          ref={inputRef}
          name={name}
          type="checkbox"
          value={option.value}
          checked={values[name] && values[name].includes(option.value)}
          onChange={(e) => onChange(e, option, arrayHelpers)}
          disabled={disabled}
        />
        {option.icon && (
          <span className="icon">
            <img src={option.icon} alt="" />
          </span>
        )}
        {!onlyIcons && (
          <>
            {isCheck ? (
              <div className="d-flex p-0 m-0 justify-content-center">
                <div className="d-inline mx-1">
                  <Icons.GroupCheck width="1rem" />
                </div>
                <p className="d-inline m-0 p-0 mx-1 check-title">
                  {option.label}
                </p>
              </div>
            ) : (
              option.label
            )}
          </>
        )}

        {!onlyIcons && option.sub && (
          <p className="text-center mb-0 mt-2">{option.sub}</p>
        )}
      </label>
    </li>
  );
};

ListItem.propTypes = {
  name: PropTypes.string.isRequired,
  option: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  arrayHelpers: PropTypes.object.isRequired,
  onlyIcons: PropTypes.bool,
  isCheck: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
};

export default ListItem;
