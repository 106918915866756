import _get from 'lodash/get';
import moment from 'moment';
import {
  timeZone,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/constants/index.js';

import { PERMANENT_VALIDITY } from './constants';

export const formatDriverLicense = (values, isForeign = false) => {
  const { isPermanentLicense = false, validityDriverLicense } = values;

  const validityYear = isPermanentLicense
    ? PERMANENT_VALIDITY
    : validityDriverLicense;

  const formattedDriverLicenseValues = {
    issuedYear: _get(values, 'expedition', ''),
    issuingStateUuid: _get(values, 'emissionState', ''),
    licenseTypeUuid: values.licenseType,
    nationalityUuid: values.nationality,
    number: values.licenseNumber,
    sinceDate: moment(values.antiquity).tz(timeZone).format(YYYY_MM_DD),
    validityYear,
  };

  if (isForeign) {
    // delete formattedDriverLicenseValues.issuedYear;
    delete formattedDriverLicenseValues.issuingStateUuid;
  }

  return formattedDriverLicenseValues;
};
