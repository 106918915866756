import { connect } from 'react-redux';
import _get from 'lodash/get';
import { Layout } from '@casanova/casanova-common';
import { navigate } from 'store/app/actions';
import { logout } from 'store/auth/actions';

export default connect(
  (state) => {
    const userFullName = _get(state, 'auth.user.fullName');
    const userBranchUuid = _get(state, 'auth.user.branch', '');
    const branchCatalog = _get(state, 'commons.branches.list', []);
    const userBranch = branchCatalog.find(
      (branch) => branch.uuid === userBranchUuid
    );
    const userBranchName = _get(userBranch, 'shortName', '');    
    localStorage.setItem("br", userBranchUuid);
    
    return {
      activeRoute: state.app.activeRoute,
      userFullName,
      userBranch: userBranchName,
    };
  },
  (dispatch) => ({
    onChangeRoute: (item, subItem) => dispatch(navigate(item, subItem)),
    logout: () => dispatch(logout()),
  })
)(Layout);
