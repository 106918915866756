"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _esMXCommons = _interopRequireDefault(require("./es-MX-commons.json"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var CODES = {
  MX: 'es-MX'
};
var languages = _defineProperty({}, CODES.MX, _esMXCommons["default"]);
var getLocaleCode = function getLocaleCode() {
  return CODES.MX;
};
var replaceAllVars = function replaceAllVars(message, elements) {
  elements.forEach(function (element, index) {
    // eslint-disable-next-line no-param-reassign
    message = message.replace("%".concat(index + 1), element);
  });
  return message;
};
var I18n = function I18n(key) {
  var replaces = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var locale = languages[getLocaleCode()];
  if (replaces.length === 0) return locale[key];
  return replaceAllVars(locale[key], replaces);
};
var _default = exports["default"] = I18n;