import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { closeResponseDialog } from 'store/transfers/actions';
import { searchEmployees } from 'store/customers/actions';
import { searchVehicles } from 'store/vehicles/actions';
import { fetchCatalog } from 'store/commons/actions';
import _get from 'lodash/get';

import { withLayout } from 'hooks';

import CreateTransfer from './CreateTransfer';

export default withLayout(
  compose(
    connect(
      (state) => ({
        loader: _get(state, 'commons.loader', false),
        quote: _get(state, 'reservations.quote', {}),
        responseDialog: _get(state, 'transfers.responseDialog', {}),
        transfersSearch: _get(state, 'customers.employees.list', []),
        onCancelPath: `/transfers?isCanceling=true`,
      }),
      {
        fetchCatalog,
        closeResponseDialog,
        searchEmployees,
        searchVehicles,
      }
    )
  )(CreateTransfer)
);
