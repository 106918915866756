import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, yupToFormErrors, validateYupSchema, FieldArray } from 'formik';
import { Button, Icons } from '@casanova/casanova-common';
import * as validations from 'utils/validations/additionals';
import { key as keysMeta } from 'services/vehicles/additionals';
import { EDIT_VEHICLE_KEYS } from 'utils/roles/permissions';
import { useSelector } from 'react-redux';
import Tabs from 'components/Tabs/Tabs';
import PreventChange from 'components/PreventChange';
import RoleVerifier from 'components/RoleVerifier';
import { useVehicle } from 'hooks';
import ActionModal from '../components/ActionModal';
import { withAdditionalCollapse } from '../AdditionalCollapse';
import SectionKeys from './Additional/SectionKeys';
import { createObjectBranch, createObjectStatus } from './helpers';

const activeKey = 'LLAVE 1';
function AdditionalKeys(props) {
  const {
    addNew,
    closeAdditionalResult,
    editable,
    fetchCatalog,
    getAdditional,
    data,
    isCancelModalVisible,
    isNew,
    name,
    onEdit,
    saveAdditional,
    vehicle,
    vehicleId,
    resultMessage,
    show,
  } = props;

  const { isSold } = useVehicle();

  const formRef = useRef(null);
  const [tabs, setTabs] = useState([
    {
      label: 'LLAVE 1',
    },
    {
      label: 'LLAVE 2',
    },
    {
      label: 'LLAVE 3',
    },
  ]);
  const keyStatus = useSelector((state) => state.catalogs.keyStatus);
  const status = createObjectStatus(keyStatus);
  const branch = useSelector((state) => state.catalogs.branch);
  const [activeTab, setActiveTab] = useState('LLAVE 1');
  useEffect(() => {
    if (!show && formRef) {
      formRef.current.resetForm(keysMeta.empty);
    }
  }, [show]);

  useEffect(() => {
    if (vehicleId) {
      getAdditional({ name, data: vehicleId });
      fetchCatalog('branch');
      fetchCatalog('key-status');
    }
  }, [name, fetchCatalog, vehicleId, getAdditional]);

  const validateEmptyKey = (el) =>
    !el.lendBranch &&
    !el.lendDate &&
    !el.lendOtherBranch &&
    !el.lendReason &&
    !el.lendResponsible &&
    !el.lostDate &&
    !el.lostDescription &&
    !el.sinister &&
    !el.sinisterResponsible;
  const clearDataKey = useCallback(
    (key) => {
      if (key.status === status.Existente) {
        key.lendBranch = '';
        key.lendDate = '';
        key.lendOtherBranch = '';
        key.lendReason = '';
        key.lendResponsible = '';
        key.lostDate = '';
        key.lostDescription = '';
        key.sinister = '';
        key.sinisterResponsible = '';
        return key;
      }
      if (key.status === status.Préstamo) {
        key.lostDate = '';
        key.lostDescription = '';
        key.sinister = '';
        key.sinisterResponsible = '';
        return key;
      }
      if (key.status === status.Extraviada) {
        key.lendBranch = '';
        key.lendDate = '';
        key.lendOtherBranch = '';
        key.lendReason = '';
        key.lendResponsible = '';
        key.sinister = '';
        key.sinisterResponsible = '';
        return key;
      }
      if (key.status === status.Siniestro) {
        key.lendBranch = '';
        key.lendDate = '';
        key.lendOtherBranch = '';
        key.lendReason = '';
        key.lendResponsible = '';
        key.lostDate = '';
        key.lostDescription = '';
        return key;
      }
    },
    [status]
  );
  const handleFormik = useCallback(
    (values) => {
      const data = keysMeta.formatter(values);
      data.forEach((key, k) => {
        if (!validateEmptyKey(key)) {
          const keyValid = clearDataKey(key);
          saveAdditional({
            name,
            data: {
              ...keyValid,
              vehicleId,
              serialNumber: vehicle.serialNumber,
            },
            forceCreate: false,
            addNew,
          });
        }
      });
      setActiveTab(activeKey);
    },
    [addNew, saveAdditional, name, vehicleId, vehicle, clearDataKey]
  );

  useEffect(
    () => () => {
      onEdit(false);
      props.cancelNewAdditional({ name });
    },
    []
  );

  const handleCancel = (formikProps) => {
    if (editable) {
      formikProps.resetForm(keysMeta.empty);
      onEdit(false);
      props.cancelNewAdditional({ name });
      setActiveTab(activeKey);
      if (isNew) {
        props.forceClose(name);
      }
    } else {
      props.addNewAdditional({ ...data, name });
    }
  };

  const handleValidation = (values) => {
    const lendBranchs = createObjectBranch(branch);
    const { buyDate } = vehicle;
    try {
      validateYupSchema(values, validations.keys, true, {
        ...values,
        buyDate,
        status,
        lendBranchs,
      });
    } catch (error) {
      return yupToFormErrors(error);
    }
    return {};
  };

  const handleConfirmCancelModal = (formikProps) => {
    onEdit(false);
    formikProps.resetForm();
    props.onCancelModal(false);
    props.onRequestClose(name);
    if (addNew) {
      props.cancelNewAdditional({ name });
    }
  };

  const handleCloseCancelModal = () => {
    props.onCancelModal(false);
  };

  const cancelLabel = editable ? 'Cancelar' : 'Editar';

  const cancelEditTitle = !addNew
    ? 'Cancelar Edición'
    : 'Estás a punto de salir';

  const handleCloseResultModal = (formikProps) => {
    formikProps.resetForm(keysMeta.empty);
    closeAdditionalResult({ name });
  };

  return (
    <div className="container-fluid">
      <Formik
        initialValues={data || keysMeta.empty || keysMeta.empty}
        validate={handleValidation}
        validateOnChange
        enableReinitialize
        onSubmit={handleFormik}
        innerRef={formRef}
      >
        {(formikProps) => {
          const { handleSubmit, dirty, values, setValues } = formikProps;

          return (
            <PreventChange preventChange={dirty && editable}>
              <div className="vehicleEditor">
                {resultMessage && (
                  <ActionModal
                    open={resultMessage.open}
                    icon={
                      resultMessage.success ? (
                        <Icons.IcoCarOk />
                      ) : (
                        <Icons.IcoCarError />
                      )
                    }
                    onClose={() => handleCloseResultModal(formikProps)}
                    onAction={() => handleCloseResultModal(formikProps)}
                    title={resultMessage.title}
                    actionLabel="Ok"
                    message={resultMessage.message}
                    modalType="confirmation"
                    code={resultMessage.errorCode}
                    closeButton
                  />
                )}
                <ActionModal
                  open={isCancelModalVisible}
                  icon={<Icons.StatusWarning />}
                  onClose={handleCloseCancelModal}
                  onAction={() => handleConfirmCancelModal(formikProps)}
                  title={cancelEditTitle}
                  actionLabel={!addNew ? 'Sí' : 'Sí, continuar'}
                  closeLabel={!addNew ? 'No' : 'Cancelar'}
                  message={
                    !addNew
                      ? 'Al cancelar se perderán todos los datos previamente llenados. ¿Estás seguro que quieres continuar?'
                      : 'Se perderán los datos previamente llenados, te recomentados guardar tus cambios antes de salir.\n ¿Estás seguro que quieres continuar?'
                  }
                  closeButton
                  data={formikProps}
                />
                <div className="row">
                  <div className="col-12 switch-inverted">
                    <div className="w-100 mb-3">
                      <div>
                        <p className="title-key">
                          Control de llaves del vehículo
                        </p>
                      </div>
                      <Tabs
                        editable={editable}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        name="key"
                        isNew={isNew}
                        setValues={setValues}
                        addNew={addNew}
                        tabs={tabs}
                        nameTab="key"
                        deleteTabs
                      >
                        {values.key && values.key.length > 0 ? (
                          <FieldArray
                            name="key"
                            render={() => (
                              <>
                                {values.key.map((gp, index) => {
                                  if (gp.label !== activeTab) return undefined;
                                  return (
                                    <SectionKeys
                                      key={index}
                                      index={index}
                                      addNew={props.addNew}
                                      isNew={props.isNew}
                                      data={gp}
                                      name={index}
                                    />
                                  );
                                })}
                              </>
                            )}
                          />
                        ) : (
                          <div className="row">
                            <div className="col-12">
                              <h5>Sin información</h5>
                            </div>
                          </div>
                        )}
                      </Tabs>
                    </div>
                  </div>
                </div>
                {!isSold && (
                  <RoleVerifier identifier={EDIT_VEHICLE_KEYS}>
                    <div className="row mt-4 mb-4">
                      <div className="col-12 col-md-12 col-lg-4 d-flex footer-content">
                        {(addNew || isNew) && (
                          <p className="mandatory">
                            <span>
                              <Icons.Comment />
                            </span>{' '}
                            *Campos obligatorios.
                          </p>
                        )}
                      </div>
                      <div className="col-12 col-md-12 col-lg-8 d-flex bottom-actions">
                        <Button
                          outline={editable}
                          onClick={() => handleCancel(formikProps)}
                        >
                          {cancelLabel}
                        </Button>

                        {(addNew || isNew) && (
                          <Button onClick={handleSubmit} type="submit">
                            Guardar
                          </Button>
                        )}
                      </div>
                    </div>
                  </RoleVerifier>
                )}
              </div>
            </PreventChange>
          );
        }}
      </Formik>
    </div>
  );
}

AdditionalKeys.propTypes = {
  fetchCatalog: PropTypes.func,
  getAdditional: PropTypes.func,
  saveAdditional: PropTypes.func,
};

AdditionalKeys.defaultProps = {};

export default withAdditionalCollapse({ title: 'Llaves' })(AdditionalKeys);
