import {
  sendFormLinkFirm,
  verifySignaturesCompleted,
} from 'store/reservations/actions';
import {
  schemaStepTwo,
  initialTouchedStepTwo,
  initialValuesStepTwo,
} from '@reservations/commons/deliveryAndRefundConfig';

export const config = ({ cellphone = '' }) => ({
  schema: schemaStepTwo,
  textBold: cellphone,
  messageBold: true,
  modalType: 'action',
  initialTouched: initialTouchedStepTwo,
  initialValues: initialValuesStepTwo,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: false,
  redirectInSuccess: false,
  showResponseDialog: false,
  submitForm: sendFormLinkFirm,
  btnSubmitLabel: 'Entregar',
  onSuccessAction: (values) => verifySignaturesCompleted(values.formUuid),
  dialogs: {
    confirmation: {
      title: 'FIRMAR',
      message: `Se enviará un link al número ${cellphone} para la firma de aceptación del cliente. ¿Estás seguro que quieres enviarlo?`,
      actionLabel: 'Si, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: 'MENSAJE ENVIADO',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage: `¿El cliente ha recibido el mensaje?`,
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Si, continuar',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: './',
    },
  },
});
