import * as Yup from 'yup';
import { REQUIRED_MESSAGE } from 'utils/constants';
import { formatter } from './formatter';
import { transformer } from './transformer';
import { updateReservation } from 'store/reservations/actions';
import {
  reservationDatesProps,
  config as rdConfig,
} from '../../sections/ReservationDates/config';
import moment from 'moment';
import { ISO_DATE_FORMAT } from 'utils/constants';

const schema = Yup.object().shape({
  customerName: Yup.string().nullable().required(REQUIRED_MESSAGE),
  email: Yup.string().nullable().required(REQUIRED_MESSAGE),
  selectedCategory: Yup.string().required(REQUIRED_MESSAGE),
  types: Yup.string().required(REQUIRED_MESSAGE),
  vehicleLineUuid: Yup.string().required(REQUIRED_MESSAGE),
  vehicleLinePrice: Yup.string().required(REQUIRED_MESSAGE),
  // vehicleQuantity: Yup.number()
  //   .required(REQUIRED_MESSAGE)
  //   .min(1, 'La cantidad debe ser mayor que 0'),
  ...reservationDatesProps,
  // startHour: Yup.string().nullable().required(REQUIRED_MESSAGE),
});

export const getReturnDateTime = ({ dateStart, startHour, daysNumber }) => {
  const formatedHour = `${dateStart} ${startHour}`;
  return moment(dateStart)
    .set({
      hour: moment(formatedHour).get('hour'),
      minutes: moment(formatedHour).get('minutes'),
      seconds: moment(formatedHour).get('seconds'),
    })
    .add(Number(daysNumber), 'days')
    .format(ISO_DATE_FORMAT);
};

const config = {
  schema,
  // initialTouched: {
  //   customer: true,
  //   email: true,
  //   customerName: true,
  //   customer_search: true,
  //   vehicleCategory: true,
  //   vehicleQuantity: true,
  //   vehicleLinePrice: true,
  //   reservation: true,
  //   types: true,
  //   ...rdConfig.initialTouched,
  // },
  initialValues: {
    reservation: '',
    vehicleLinePrice: '',
    customer: '',
    customer_search: '',
    vehicleQuantity: '1',
    customerName: '',
    email: '',
    types: '',
    selectedCategory: '',
    vehicleCategory: undefined,
    ...rdConfig.initialValues,
  },
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  alwaysSetTemporalValues: true,
  withInitialValues: true,
  formatter,
  transformer,
  submitForm: updateReservation,
  btnSubmitLabel: 'Modificar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE MODIFICACIÓN',
      message: '¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {},
  },
};

export default config;
