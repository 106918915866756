import {
  formatTransfer,
  formatCard,
  formatDeposit,
  formatInvoice,
} from '@reservations/Payment/formatter';

const formatCommons = (values, paymentType = 'CONTRACT') => {
  const {
    holdbackCardHolder,
    holdbackBank,
    holdbackCard,
    holdbackCode,
    holdbackValidity,
    reservationId,
  } = values;

  const elements = holdbackValidity.split('/');
  const month = parseInt(elements[0], 10);
  const year = parseInt(elements[1], 10) + 2000;

  const realMonth = month >= 10 ? month : `0${month}`;

  const creditCardHold = {
    bank: holdbackBank,
    cardHolderName: holdbackCardHolder,
    cardNumber: holdbackCard,
    cvc: holdbackCode,
    expDate: year && realMonth ? `${year}-${realMonth}-01` : '',
  };

  return {
    reservation: reservationId,
    creditCardHold,
    paymentType,
  };
};

const formatter = (values, paymentType = 'CONTRACT') => {
  let request = {};
  const multiRequest = [];

  if (values.totallyPaidWithRefund) {
    return {
      paymentForm: 'CASH',
      amount: 0,
      paymentType: 'EXTRA_CHARGES',
      reservation: values.reservationId,
      withoutPayment: true,
      isTheLastPayment: true,
    };
  }

  if (values.diffMetods) {
    const amountCardKeys = Object.keys(values).filter((key) =>
      key.startsWith('amountCard_')
    );

    amountCardKeys.forEach((key) => {
      const match = key.match(/amountCard_(\d+)/);
      if (match && values[key]) {
        const index = match[1];
        request = formatCard(values, index);
        multiRequest.push(request);
      }
    });
    // if (values.amountCard) {
    //   request = formatCard(values);
    //   multiRequest.push(request);
    // }
    if (values.amountDeposit) {
      request = formatDeposit(values);
      multiRequest.push(request);
    }
    if (values.amountTransfer) {
      request = formatTransfer(values);
      multiRequest.push(request);
    }
    if (values.amountCash) {
      request = {
        paymentForm: 'CASH',
        isTheLastPayment: values.diffMetods,
        amount: values.amountCash,
      };
      multiRequest.push(request);
    }
  } else {
    switch (values.methodSelected) {
      case 'card_0':
      case 'card_1':
      case 'card_2':
      case 'card':
        request = formatCard(values, values.methodSelected.split('_')[1]);
        break;
      case 'deposit':
        request = formatDeposit(values);
        break;
      case 'transfer':
        request = formatTransfer(values);
        break;
      default:
        request = {
          paymentForm: 'CASH',
        };
        break;
    }
  }

  const commons = formatCommons(values, paymentType);
  if (values.wantInvoice) {
    if (multiRequest.length > 0) {
      multiRequest.forEach((element) => {
        if (element.paymentForm !== request.paymentForm) {
          element.isTheLastPayment = false;
        }
        element.paymentForm = element.paymentForm.replace(/_\d+$/, '');
      });
      return {
        multiRequest,
        ...formatInvoice(values),
        commons,
      };
    }

    request.paymentForm = request.paymentForm.replace(/_\d+$/, '');
    request.isTheLastPayment = true;
    return {
      ...request,
      ...formatInvoice(values),
      ...commons,
    };
  }

  if (multiRequest.length > 0) {
    multiRequest.forEach((element) => {
      if (element.paymentForm !== request.paymentForm) {
        element.isTheLastPayment = false;
      }
      element.paymentForm = element.paymentForm.replace(/_\d+$/, '');
    });
    return { multiRequest, commons };
  }

  request.paymentForm = request.paymentForm.replace(/_\d+$/, '');
  request.isTheLastPayment = true;
  return { ...request, ...commons };
};

export default formatter;
