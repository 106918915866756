import * as yup from 'yup';
import i18n from '@i18n';

export const AMOUNT_MAX = 999999;

export const schema = yup.object().shape({
  vehicleSearch: yup.string().min(1, i18n('ERROR__COMMONS__MIN_LENGTH', [1])),
  vehicles: yup
    .array()
    .required(i18n('ERROR__CONTRACTS__REQUIRED_VEHICLES'))
    .min(1, i18n('ERROR__CONTRACTS__REQUIRED_VEHICLES')),
});
