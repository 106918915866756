import * as yup from 'yup';
import i18n from '@i18n';
import { createReservationContract } from 'store/reservations/actions';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index.js';
import { isChangeOfVehicleFlow } from '@reservations/commons/utils';

const formatter = (values) => ({
  remarks: values.remarks,
});

const initialTouched = {
  remarks: false,
  searchVehicle: true,
  changeVehicleReason: true,
  contractStep: true,
};

const initialValues = {
  remarks: '',
  searchVehicle: '',
  changeVehicleReason: '',
  contractStep: false,
};

const schema = yup.object().shape({
  remarks: yup.string().notRequired(),
  searchVehicle: yup.string().notRequired(),
  changeVehicleReason: yup.mixed().when('contractStep', {
    is: (contractStep) => !contractStep,
    then: yup
      .string()
      .test(
        'change-vehicle-reason-required',
        REQUIRED_MESSAGE,
        (changeVehicleReason) =>
          isChangeOfVehicleFlow() ? Boolean(changeVehicleReason) : true
      ),
  }),
});

const config = {
  schema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter,
  submitForm: createReservationContract,
  withButtons: true,
  disableSubmit: true,
  alwaysSetTemporalValues: true,
  btnSubmitLabel: 'Continuar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE REGISTRO',
      message:
        'Una vez cargada la información del cliente tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: 'Ir a Clientes',
      errorLabel: i18n('COMMONS__CLOSE__TEXT'),
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};

export default config;
