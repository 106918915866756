import {
  RETURNS_LIST_PATH,
  RETURNS_EXTRA_CHARGES_PATH,
  RETURNS_EXTRA_CHARGES_PAYMENT_PATH,
  RETURNS_REFUND_PATH,
  RETURNS_REFUND_VEHICLE_PARTS_PATH,
  RETURNS_REFUND_INFORMATION_AND_FIRM_PATH,
} from 'utils/roles/paths';
import { CREATE_RESERVATION } from 'utils/roles/permissions/reservationsPermissions';
import {
  RETURNS_MODULE,
  VIEW_RETURNS,
} from 'utils/roles/permissions/returnsPermissions';

export function returnsModulesAuth(permissions) {
  const allowedModules = [];

  const hasReturnsPermissions =
    permissions.filter((permission) => permission.includes(VIEW_RETURNS))
      .length > 0;

  if (hasReturnsPermissions) {
    allowedModules.push(RETURNS_MODULE);
  }

  return allowedModules;
}

export function returnsPathsAuth(permissions) {
  const allowedPaths = [];

  // if (permissions.includes(CREATE_RESERVATION)) {
  if (permissions.includes(VIEW_RETURNS)) {
    allowedPaths.push(RETURNS_REFUND_PATH);
    allowedPaths.push(RETURNS_REFUND_VEHICLE_PARTS_PATH);
    allowedPaths.push(RETURNS_REFUND_INFORMATION_AND_FIRM_PATH);

    allowedPaths.push(RETURNS_EXTRA_CHARGES_PATH);
    allowedPaths.push(RETURNS_EXTRA_CHARGES_PAYMENT_PATH);
    allowedPaths.push(RETURNS_LIST_PATH);
  }

  return allowedPaths;
}
