import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { RadioGroupInput } from '@casanova/casanova-common';
import UserInfo from 'components/UserInfo';
import { useSelector } from 'react-redux';
import LoanForm from '../components/LoanForm';
import LostForm from '../components/LostForm';
import DetailsLost from '../components/DetailsLost';
import SinisterForm from '../components/SinisterForm';
import { createObjectStatus } from '../helpers';
import DetailsLoan from '../components/DetailsLoan';
import DetailsSinister from '../components/DetailsSinister';

const SectionKeys = ({ name, data, isNew, addNew, index }) => {
  const { values, setFieldValue } = useFormikContext();
  const listValues = [
    { value: 'Existente', label: 'Existente' },
    { value: 'Prestamo', label: 'Prestamo' },
    { value: 'Extraviada', label: 'Extraviada' },
    { value: 'Siniestro', label: 'Siniestro' },
  ];
  const keyStatus = useSelector((state) => state.catalogs.keyStatus);
  const options = keyStatus
    ? keyStatus.list.filter((key) => key.name !== 'No existente')
    : listValues;
  const status = createObjectStatus(keyStatus);

  useEffect(() => {
    setFieldValue('index', index);
  }, [index, setFieldValue]);
  const statusKey = values.key && values.key[name].keyStatus.uuid;
  const dataUser = values.key && values.key[name];
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className={`col-12 ${
            values.key && values.key[name].resguardo === 'No existente'
              ? 'd-none'
              : ''
          }`}
        >
          <RadioGroupInput
            labelClass="d-flex align-items-center"
            groupClass="col-12 d-flex justify-content-between"
            parentClass="vehicle-radio-input"
            label=""
            name={`key.${name}.keyStatus.uuid`}
            options={options}
            typeCheck
            disabled={!isNew && !addNew}
            statusKey={status}
          />
        </div>
        <div className="col-12">
          {values.key && values.key[name].resguardo === 'No existente' && (
            <p className="font-weight-bold mt-3">
              {values.key[name].resguardo}
            </p>
          )}

          {values.key && statusKey === status.Préstamo && (
            <>
              {addNew ? (
                <LoanForm
                  name={name}
                  isNew={isNew}
                  addNew={addNew}
                  values={values}
                />
              ) : (
                <DetailsLoan data={values.key[name]} />
              )}
            </>
          )}
          {values.key && statusKey === status.Extraviada && (
            <>
              {addNew ? (
                <LostForm
                  name={name}
                  isNew={isNew}
                  addNew={addNew}
                  values={values}
                />
              ) : (
                <DetailsLost
                  lostDescription={values.key[name].lostDescription}
                  lostDate={values.key[name].lostDate}
                />
              )}
            </>
          )}
          {values.key && statusKey === status.Siniestro && (
            <>
              {addNew ? (
                <SinisterForm
                  name={name}
                  isNew={isNew}
                  addNew={addNew}
                  values={values}
                />
              ) : (
                <DetailsSinister data={values.key[name]} />
              )}
            </>
          )}
        </div>
        <div className="col-md-12 mt-2">
          <UserInfo {...dataUser} />
        </div>
      </div>
    </div>
  );
};

export default SectionKeys;
