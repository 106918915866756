import React from 'react';
import {
  DatePickerV2 as DatePicker,
  MaskedInput,
  TextAreaInput,
} from '@casanova/casanova-common';
import { useSelector } from 'react-redux';

import { MINIMUM_DATE, MAXIMUM_DATE } from '@vehicles/common/constants';
import { alphaMaskWithSpaces } from 'utils/masks';

import { SelectWithOptions } from '../../components';
import { createObjectBranch } from '../helpers';

const LoanForm = ({ isNew, addNew, values, name }) => {
  const branch = useSelector((state) => state.catalogs.branch);
  const lendBranchs = createObjectBranch(branch);

  return (
    <div className="row">
      <div className="col-12 col-md-4">
        <DatePicker
          name={`key.${name}.lend.lendDate`}
          initialValue={values.key && values.key[name].lend.lendDate}
          label="Fecha de prestamo*"
          placeholder="Fecha de prestamo*"
          disabled={!isNew && !addNew}
          minimumDate={MINIMUM_DATE}
          maximumDate={MAXIMUM_DATE}
        />
      </div>
      <div className="col-12 col-md-4">
        <MaskedInput
          name={`key.${name}.lend.lendResponsible`}
          label="responsable*"
          placeholder="responsable*"
          mask={alphaMaskWithSpaces(30)}
          maskPlaceholder=""
          disabled={!addNew && !isNew}
        />
      </div>
      <div className="col-12 col-md-4">
        <SelectWithOptions
          name={`key.${name}.lend.lendBranch`}
          label="Sucursal*"
          options="catalogs.branch"
          disabled={!addNew && !isNew}
        />
      </div>
      {values.key[name].lend.lendBranch === lendBranchs.Otro && (
        <div className="col-12 col-md-4">
          <MaskedInput
            name={`key.${name}.lend.branch`}
            label="Introduce la sucursal*"
            placeholder="Introduce la sucursal*"
            mask={alphaMaskWithSpaces(30)}
            maskPlaceholder=""
            disabled={!addNew && !isNew}
          />
        </div>
      )}
      <div className="col-12">
        <TextAreaInput
          name={`key.${name}.lend.lendReason`}
          label="Escribe aqui las Observaciones"
          placeholder="Escribe aqui las Observaciones"
          disabled={!addNew && !isNew}
          regex={/[()[\]|&;$%@#"{}¿!¡?^/\\|<>+~=`':*¨]/g}
          maxLength={200}
        />
      </div>
    </div>
  );
};

export default LoanForm;
