const config = {
  schema: {},
  initialTouched: {},
  initialValues: {},
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter: (values) => values,
  transformer: (values) => values,
  submitForm: () => true,
  withButtons: false,
  disableSubmit: true,
  btnSubmitLabel: 'Continuar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE REGISTRO',
      message:
        'Una vez cargada la información del cliente tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a Clientes',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};

export default config;

export const MAX_STEPS = 5;
export const CHANGE_OF_VEHICLE_MAX_STEPS = 4;
