const transformer = (data) => {
  const { vehicle = {} } = data;
  const { serialNumber, brand, line, model } = vehicle;

  return {
    contractNumber: data.number,
    contractUuid: data.uuid,
    customer: data.naturalPersonName,
    customerUuid: data.naturalPerson,
    serialNumber,
    vehicle: `${brand} ${line} ${model}`,
    userOpened: data.userOpened,
    userClosed: data.userClosed,
    status: data.status,
  };
};

export default transformer;
