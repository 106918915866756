import { Section } from '@casanova/casanova-common';
import { default as Card } from 'components/CardSection';
import { SchedDate } from '@reservations/components';
import { number2mxn } from 'utils/currency';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import { RENTAL_PLANS } from '../../../reservations/commons/constants';

const RefundDetail = (props) => {
  const {
    reservationDetails = [],
    vehicleLinePricesDetails = [],
    rentalPlanName,
    customerRefund,
  } = props;
  const { priceQuote } = reservationDetails;
  const [selectedPlan, setSelectedPlan] = useState('');
  const [days, setDays] = useState(0);
  const [totalRefund, setTotalRefund] = useState(0);
  const [chargePrice, setChargePrice] = useState(0);
  const pickupDate = priceQuote?.pickupDateTime;
  const returnDate = priceQuote?.returnDateTime;
  const totalToCharge = days * chargePrice;
  const totalPaid = priceQuote?.total;

  const actualPlan = rentalPlanName;

  const setDaysUsed = () => {
    const today = moment().startOf('day');
    const start = moment(pickupDate).startOf('day');
    const end = moment(returnDate).startOf('day');
    const daysRented = end.diff(start, 'days');
    let daysUsed = today.diff(start, 'days');
    daysUsed = daysUsed > 0 ? daysUsed : 0;
    daysUsed = daysRented == daysUsed ? daysRented : daysUsed;
    setDays(daysUsed);
  };

  useEffect(() => {
    if (!_isEmpty(reservationDetails)) {
      setDaysUsed();
    }
  }, [reservationDetails]);

  useEffect(() => {
    const totalRefund = totalToCharge > 0 ? totalPaid - totalToCharge : 0;
    setTotalRefund(totalRefund);
  }, [totalToCharge]);

  useEffect(() => {
    if (!_isEmpty(vehicleLinePricesDetails) && actualPlan) {
      const temporal = actualPlan.includes('Temporal') ? ' Temporal' : '';
      let plantToUse = {},
        dividendo = 1;
      switch (actualPlan) {
        case RENTAL_PLANS.TWO_HUNDRED_FIFTY_KM:
        case RENTAL_PLANS.FREE_KM:
        case RENTAL_PLANS.TWO_HUNDRED_FIFTY_KM_TEMPORAL:
        case RENTAL_PLANS.FREE_KM_TEMPORAL:
          plantToUse = vehicleLinePricesDetails.find((vlp) => {
            return vlp.rentalPlanName.includes(actualPlan);
          });
          break;
        case RENTAL_PLANS.WEEKLY:
        case RENTAL_PLANS.WEEKLY_TEMPORAL:
          if (days == 1)
            plantToUse = vehicleLinePricesDetails.find((vlp) => {
              return vlp.rentalPlanName.includes(
                RENTAL_PLANS.TWO_HUNDRED_FIFTY_KM + temporal
              );
            });
          else if (days >= 2 && days <= 6)
            plantToUse = vehicleLinePricesDetails.find((vlp) => {
              return vlp.rentalPlanName.includes(
                RENTAL_PLANS.FREE_KM + temporal
              );
            });
          else {
            dividendo = 7;
            plantToUse = vehicleLinePricesDetails.find((vlp) => {
              return vlp.rentalPlanName.includes(actualPlan);
            });
          }
          break;
        case RENTAL_PLANS.MONTHLY:
        case RENTAL_PLANS.MONTHLY_TEMPORAL:
          if (days == 1)
            plantToUse = vehicleLinePricesDetails.find((vlp) => {
              return vlp.rentalPlanName.includes(
                RENTAL_PLANS.TWO_HUNDRED_FIFTY_KM + temporal
              );
            });
          else if (days >= 2 && days <= 6)
            plantToUse = vehicleLinePricesDetails.find((vlp) => {
              return vlp.rentalPlanName.includes(
                RENTAL_PLANS.FREE_KM + temporal
              );
            });
          else if (days >= 7 && days <= 29) {
            dividendo = 7;
            plantToUse = vehicleLinePricesDetails.find((vlp) => {
              return vlp.rentalPlanName.includes(
                RENTAL_PLANS.WEEKLY + temporal
              );
            });
          } else {
            dividendo = 30;
            plantToUse = vehicleLinePricesDetails.find((vlp) => {
              return vlp.rentalPlanName.includes(actualPlan);
            });
          }
          break;
      }
      setChargePrice(plantToUse.price / dividendo);
      setSelectedPlan(plantToUse.rentalPlanName);
    }
  }, [vehicleLinePricesDetails, actualPlan, days]);

  return (
    <Section title="Detalles de reembolso">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row flex align-content-center justify-content-center">
              <Card noStatus noTitle className="w-100">
                <div className="row">
                  <div className="col-4 d-flex common-info-text flex-column align-content-center justify-content-center">
                    <span className=" font-weight-bold">PLAN {actualPlan}</span>
                  </div>
                  <div className="col-4">
                    <SchedDate
                      className="mb-0"
                      date={priceQuote?.pickupDateTime}
                      branch={priceQuote?.pickupBranch.name}
                      title="Fecha de salida"
                    />
                  </div>
                  <div className="col-4">
                    <SchedDate
                      className="mb-0"
                      date={priceQuote?.returnDateTime}
                      branch={priceQuote?.returnBranch.name}
                      title="Fecha de devolución"
                      end
                    />
                  </div>
                </div>
              </Card>
            </div>
            <div className="row">
              <div className="col-2" />
              <div className="col-8 flex justify-content-center align-items-end gap-2 text-center">
                <span>
                  DÍAS UTILIZADOS
                  <br />
                  {days}
                </span>
                <span>x</span>
                <Tooltip target=".selected-plan-tooltip" />
                <span
                  className="selected-plan-tooltip pi p-overlay-badge"
                  data-pr-tooltip={selectedPlan}
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                  style={{ cursor: 'pointer' }}
                >
                  <Badge
                    severity="warning"
                    style={{ minWidth: 'revert', height: '.5rem' }}
                  ></Badge>
                  TARIFA APLICADA
                  <br />
                  {number2mxn(chargePrice)}
                </span>
              </div>
              <div className="col-2" />
            </div>
            <row className="row my-3">
              <div className="col-2" />
              <div className="col-8 flex justify-content-center align-items-end gap-2 text-center text-lg">
                <span>
                  TOTAL PAGADO
                  <br />
                  {number2mxn(totalPaid)}
                </span>
                <span>-</span>
                <span>
                  TOTAL A COBRAR
                  <br />
                  {number2mxn(totalPaid - customerRefund.amount)}
                </span>
                <span>=</span>
                <span className="text-primary-500 text-lg font-semibold">
                  TOTAL DEL REEMBOLSO
                  <br />
                  {number2mxn(customerRefund.amount)}
                </span>
              </div>
              <div className="col-2" />
            </row>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default (props) => <RefundDetail {...props} />;
