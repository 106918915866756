import compose from 'recompose/compose';
import { connect } from 'react-redux';
import PermissionForm from './PermissionForm';

export default compose(
  connect(
    (state) => ({
      permissionTypes: state.catalogs.permissionType.list,
    }),
    {}
  )
)(PermissionForm);
