import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import { Button, RadioGroupInput, withForm } from '@casanova/casanova-common';
import { GrayDisclaimer, Carrousel } from '@reservations/components';
import SearchableSelect from 'components/SercheableSelect';
import PreventChange from 'components/PreventChange';
import { fieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import { preventChangeConfig } from '@reservations/commons/config';
import ReservationDates from '../../../sections/ReservationDates';
import config from './config';
import './One.scss';
import { lettersAndNumbersWithSpaces } from '../../../../../utils/masks';
import { VIEW_CREATE_CUSTOMER_PRICE_QUOTE } from 'utils/roles/permissions/reservationsPermissions';
import { RoleVerifier } from 'components';

const One = ({
  search,
  fetchCatalog,
  getVehicleTypes,
  categories,
  getTimeLimits,
  quoteValues,
  onUpdateForm,
  selectedCategory,
  getMostQuoted,
  mostQuoted,
  setTitles,
  availableTime,
  setEndAvailableHours,
  resetCustomers,
  leaveStepOne,
  setVehicleCategory,
  vehicleCategory,
  inProgress,
  resetReservations,
}) => {
  const {
    setFieldValue,
    setFieldTouched,
    dirty,
    values,
    isSubmitting,
  } = useFormikContext();

  const [initialSelected, setInitial] = useState();
  const history = useHistory();

  // console.log(dirty);

  const { customer = '', lineSelected } = values;

  const handleRemove = useCallback(() => {
    setFieldValue(fieldSearch('customer'), '');
    setFieldValue('customer', '');
  }, [setFieldValue]);

  useEffect(() => {
    fetchCatalog('branches');
    getVehicleTypes();
    getTimeLimits();
    // getMostQuoted();
    setTitles({
      title: 'Seleccionar tipo de cotización',
      subtitle: 'Elige los parámetros de búsqueda que te indique el cliente.',
    });
    resetCustomers();
  }, [fetchCatalog, getVehicleTypes, getTimeLimits, setTitles]);

  // useEffect(() => {
  //   getMostQuoted(values.vehicleCategory);
  // }, [getMostQuoted, values.vehicleCategory]);

  useEffect(() => {
    if (!_isEmpty(quoteValues) && inProgress) {
      onUpdateForm(quoteValues);
      setInitial(lineSelected);
    }
  }, [onUpdateForm, quoteValues, lineSelected, inProgress]);

  useEffect(() => {
    if (
      categories &&
      categories.length > 0 &&
      !dirty &&
      ((_isEmpty(selectedCategory) && _isEmpty(quoteValues)) || !inProgress)
    ) {
      const defaultSelected = categories.filter((cat) => cat.isDefault);
      onUpdateForm(
        {
          ...config.initialValues,
          vehicleCategory: defaultSelected[0].value,
          availableTime,
        },
        false
      );
    }
  }, [
    categories,
    dirty,
    onUpdateForm,
    selectedCategory,
    quoteValues,
    availableTime,
    inProgress,
  ]);

  useEffect(() => {
    setFieldTouched('dateStart', true);
    setFieldTouched('dateEnd', true);
  }, [vehicleCategory]);

  useEffect(() => {
    if (vehicleCategory && availableTime) {
      setFieldValue('availableTime', availableTime);
    }
  }, [vehicleCategory, setFieldValue, availableTime]);

  useEffect(() => {
    if (!inProgress) {
      resetReservations();
    }
  }, []);

  const handleSelectCustomer = useCallback(
    (element) => {
      setFieldValue('customer_field', element);
    },
    [setFieldValue]
  );

  const [date, setDate] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setDate(new Date());
    }, 1000);
  }, []);

  // setRegisterUserContact()
  useEffect(() => {
    const registerUserContact =
      sessionStorage.getItem('tmp_set_fullname_user') || 0;
    const registerUuidContact =
      sessionStorage.getItem('tmp_set_uuid_user') || 0;
    if (registerUserContact !== 0) {
      search({ text: registerUserContact });
      setFieldValue('customer', registerUuidContact);
      setFieldValue('customer_lastSearch', registerUserContact);
      setFieldValue('customer_search', registerUserContact);
      setFieldValue('customer_field', registerUuidContact);
    }
  }, [date]);

  const handleSearchCustomer = useCallback(
    (query) => {
      if (query && query.length > 3) {
        setFieldValue('customer', '');
        search({ text: query });
      }
    },
    [setFieldValue, search]
  );

  const handleOnSelectViewed = useCallback(
    (line) => {
      setFieldValue('lineSelected', line);
    },
    [setFieldValue]
  );

  const handleOnBlur = useCallback(() => {
    if (!values.customer) {
      setFieldValue('customer_search', '');
    }
  }, [values.customer, setFieldValue]);

  const handleOnChangeCategory = useCallback(
    (category) => {
      if (category) setVehicleCategory(category);
      if (values.dateEnd) {
        setEndAvailableHours(values.dateEnd);
      }
    },
    [values.dateEnd, setEndAvailableHours, setVehicleCategory]
  );

  const preventChange = useMemo(
    () => (dirty && !isSubmitting) || leaveStepOne,
    [dirty, !isSubmitting, leaveStepOne]
  );

  const handleAddCustomer = useCallback(() => {
    setTimeout(() => {
      history.push(`/quoter/customer/add`);
    }, 1);
  }, [history]);

  useEffect(() => {
    history.push(
      preventChange ? `?withValues=true` : history.location.pathname
    );
  }, [preventChange, history.push]);

  return (
    <PreventChange
      {...preventChangeConfig}
      preventChange={preventChange}
      fullReload
      stockInCurrentPath
    >
      <div className="row">
        <div className="col-5">
          <SearchableSelect
            onChange={handleSelectCustomer}
            onBlur={handleOnBlur}
            name="customer"
            label="Buscar cliente"
            placeholder="Buscar cliente"
            options="customers.basicInfo"
            mask={lettersAndNumbersWithSpaces(100)}
            onSearching={handleSearchCustomer}
            infoText="La búsqueda de cliente puede ser por nombre, CURP o RFC. "
            icon="svg-icon-search"
            remove
            onRemove={handleRemove}
            showRightIcon={!customer}
          />
        </div>
        <div className="col-7 text-right">
          <RoleVerifier identifier={VIEW_CREATE_CUSTOMER_PRICE_QUOTE}>
            <Button
              className="px-5"
              style={{ marginTop: '2rem' }}
              onClick={handleAddCustomer}
            >
              Nuevo
            </Button>
          </RoleVerifier>
        </div>
      </div>
      <div className="row">
        <div className="col-12 switch-inverted">
          <RadioGroupInput
            labelClass="d-flex align-items-center"
            groupClass="col-12 d-flex justify-content-between"
            parentClass="mb-0"
            label=""
            name="vehicleCategory"
            options={categories}
            onChange={handleOnChangeCategory}
          />
        </div>
      </div>
      <div className="col-md-12">
        <hr className="mb-0" />
      </div>
      <br />
      <GrayDisclaimer />
      {mostQuoted.length > 0 && (
        <Carrousel
          className="row"
          elements={mostQuoted}
          onSelect={handleOnSelectViewed}
          title="Más vistos"
          initial={initialSelected}
        />
      )}
      <ReservationDates onUpdateForm={onUpdateForm} className="mb-8" />
    </PreventChange>
  );
};

export default withForm({ config })(One);
