import {
  VIEW_VEHICLE_CATALOG,
  VIEW_VEHICLE,
  EDIT_VEHICLE,
  VEHICLES_MODULE,
  CREATE_VEHICLE_MANUAL,
  CREATE_VEHICLE_BATCH,
} from 'utils/roles/permissions';
import {
  VEHICLE_VIEW_CATALOG_PATH,
  VEHICLE_HISTORIC_DETAIL_PATH,
  VEHICLE_MANUAL_ADD_PATH,
  VEHICLE_MANUAL_DETAIL_PATH,
  VEHICLE_MANUAL_EDIT_PATH,
  VEHICLE_MASSIVE_LOAD_PATH,
  VEHICLE_MASSIVE_LOAD_FOR_SALES_PATH,
} from 'utils/roles/paths';

export function vehiclesAuth(permissions) {
  const allowedModules = [];

  const vehicleModule = permissions.filter((permission) =>
    permission.includes(VIEW_VEHICLE_CATALOG)
  );

  if (vehicleModule.length > 0) {
    allowedModules.push(VEHICLES_MODULE);
  }

  return allowedModules;
}

export function vehiclesPathsAuth(permissions) {
  const allowedPaths = [];

  if (permissions.includes(CREATE_VEHICLE_MANUAL)) {
    allowedPaths.push(VEHICLE_MANUAL_ADD_PATH);
  }

  if (permissions.includes(CREATE_VEHICLE_BATCH)) {
    allowedPaths.push(VEHICLE_MASSIVE_LOAD_PATH);
    allowedPaths.push(VEHICLE_MASSIVE_LOAD_FOR_SALES_PATH);
  }

  const viewVehiclePermissions = permissions.filter((permission) =>
    permission.startsWith(VIEW_VEHICLE_CATALOG)
  );
  const editVehiclePermissions = permissions.filter((permission) =>
    permission.startsWith(EDIT_VEHICLE)
  );

  if (viewVehiclePermissions.length > 0) {
    allowedPaths.push(VEHICLE_MANUAL_DETAIL_PATH);
    allowedPaths.push(VEHICLE_VIEW_CATALOG_PATH);
    allowedPaths.push(VEHICLE_HISTORIC_DETAIL_PATH);
  }
  if (editVehiclePermissions.length > 0)
    allowedPaths.push(VEHICLE_MANUAL_EDIT_PATH);

  return allowedPaths;
}
