import { CellText, CellDashed } from 'components/ColumnsCells';
import { AdditionalHeaderCamp } from 'components';
import {
  onlyLettersWithSpaces,
  numericMask,
  alphaEmailMask,
  alphaMaskWithSpacesAndAccent,
  lettersAndNumbersWithSpaces,
} from 'utils/masks';
import VERSIONS from 'utils/constants/versions';
import { getBrach, getRoleName } from '../../../services/auth';

export default [
  {
    label: 'Folio',
    field: 'folio',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(10)}
        name="folioRD"
        searchKey="folioRD"
      />
    ),
  },
  {
    label: 'Cliente',
    field: 'naturalPersonName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(40)}
        name="nameRD"
        searchKey="nameRD"
      />
    ),
  },
  // {
  //   label: 'Correo electrónico',
  //   field: 'email',
  //   align: 'center',
  //   renderer: CellText,
  //   AdditionalHeaderCamp: () => (
  //     <AdditionalHeaderCamp
  //       mask={alphaEmailMask(50)}
  //       name="emailRD"
  //       searchKey="emailRD"
  //     />
  //   ),
  // },
  // {
  //   label: 'Teléfono',
  //   field: 'cellphone',
  //   align: 'center',
  //   renderer: CellText,
  //   AdditionalHeaderCamp: () => (
  //     <AdditionalHeaderCamp
  //       mask={numericMask(10)}
  //       name="cellphoneRD"
  //       searchKey="cellphoneRD"
  //     />
  //   ),
  // },
  {
    label: 'Reservación',
    field: 'createdDateFormatted',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="createdDate"
        mask={numericMask(10)}
        searchKey="createdDate"
        type="DATERANGE"
        dateProps={{
          minimumDate: 'reservations.filters.reservationList.minCreatedDate',
          maximumDate: 'reservations.filters.reservationList.maxCreatedDate',
        }}
      />
    ),
  },
  {
    label: 'Categoría del vehículo',
    field: 'category',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={alphaMaskWithSpacesAndAccent(16)}
        name="categoryRD"
        searchKey="categoryRD"
      />
    ),
  },
  {
    label: 'Placa',
    field: 'plateNumber',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={lettersAndNumbersWithSpaces(11)}
        name="plateNumberRD"
        searchKey="plateNumberRD"
      />
    ),
  },
  {
    label: 'Salida',
    field: 'deliveryDateFormatted',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="deliveryDate"
        mask={numericMask(10)}
        searchKey="deliveryDate"
        type="DATERANGE"
        dateProps={{
          minimumDate: 'reservations.filters.reservationList.minDeliveryDate',
          maximumDate: 'reservations.filters.reservationList.maxDeliveryDate',
        }}
      />
    ),
  },
  {
    label: 'Devolución',
    field: 'returnDateFormatted',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="returnDate"
        mask={numericMask(10)}
        searchKey="returnDate"
        type="DATERANGE"
        version={VERSIONS.V2}
        dateProps={{
          minimumDate: 'reservations.filters.reservationList.minReturnDate',
          maximumDate: 'reservations.filters.reservationList.maxReturnDate',
        }}
      />
    ),
  },
  {
    label: 'Días de renta',
    field: 'daysDiff',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(2)}
        name="daysDiffRD"
        searchKey="daysDiffRD"
      />
    ),
  },
  {
    label: 'Sucursal',
    field: 'deliveryBranchName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="deliveryBranchRD"
        searchKey="deliveryBranchRD"
        options="reservations.filters.reservationList.deliveryBranches"
        selectProps={{ fullKey: true, availableFirstValue: true }}
        type="SELECT"
        disabled={getRoleName() != 'CONTACT_CENTER' && getBrach()}
      />
    ),
  },
  {
    label: 'Observaciones',
    field: 'observations',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp name="observationsRD" searchKey="observationsRD" />
    ),
  },
  {
    label: 'Etapa',
    field: 'stepName',
    align: 'center',
    renderer: (props) => {
      const { data = {} } = props;
      return (
        <CellDashed
          {...props}
          fixedColor={data.statusColor}
          value={data.stepName}
          align="left"
          textClass="text-left"
        />
      );
    },
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="stepRD"
        searchKey="stepRD"
        options="reservations.filters.reservationList.steps"
        type="SELECT"
        selectProps={{ fullKey: true, availableFirstValue: true }}
      />
    ),
  },
];
