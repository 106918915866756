import * as Yup from 'yup';
import { adaptationValidationProps } from '@vehicles/sections/AdaptationForm/schema';
import { conversionValidationProps } from '@vehicles/sections/ConversionForm/schema';

export const VehicleDataSchema = Yup.object().shape({
  ...adaptationValidationProps,
});

export const ConversionSchema = Yup.object().shape({
  ...conversionValidationProps,
});

export const initialTouchedVehicle = {
  brand: true,
  line: true,
  version: true,
  model: true,
  serialNumber: true,
  engineNumber: true,
  vehicularKey: true,
  color: true,
  origin: true,
  cylinder: true,
  fuelType: true,
  category: true,
  passengers: true,
  loadCapacity: true,
  vehicleType: true,
  mileage: true,
  keys: true,
  gpsNumber: true,
  hasAdaptation: true,
  originAdaptation: true,
  adaptationType: true,
  adaptationCost: true,
  adaptationInvoiceNumber: true,
  adaptationInvoicePDF: true,
  adaptationInvoiceXML: true,
};

export const initialTouchedPurchase = {
  owner: true,
  provider: true,
  buyDate: true,
  paymentType: true,
  invoicePrice: true,
  invoiceNumber: true,
  invoicePDF: true,
  invoice: true,
  purchaseOrder: true,
};
