import * as yup from 'yup';
import i18n from '@i18n';
import { saveContractRefund } from 'store/dailyRentContracts/actions';
import { initialTouched, initialValues } from '../sections/RefundDetail/config';

const schema = yup.object().shape({
  vehicleLineUuid: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  contractUuid: yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  userUuid: yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  branchUuid: yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  rentalPlanName: yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  totalRefund: yup.string(),
  daysRented: yup.string(),
  daysAccrued: yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  days: yup
    .number()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .min(1, 'Sin días para reembolsar')
    .test(
      'validate-days',
      'Deben transcurrir al menos 3 días en el plan de Kilometraje Libre para solicitar el reembolso',
      function (value) {
        const { rentalPlanName } = this.parent;
        if (rentalPlanName.includes('Kilometraje libre') && value < 3)
          return false;
        return true;
      }
    )
    .test(
      'no-days-to-refund',
      'El contrato termina hoy, no se puede reembolsar',
      function (value) {
        const { daysRented } = this.parent;
        if (daysRented == value) return false;
        return true;
      }
    ),
});

export const config = {
  schema,
  initialValues,
  initialTouched,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  redirectInSuccess: false,
  submitForm: saveContractRefund,
  btnSubmitLabel: 'Guardar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE DEVOLUCIÓN',
      message:
        'Una vez guardada la devolución el contrato pasará al estatus cerrado\n¿Estás seguro que quieres continuar?',
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: `Se guardó la devolución con éxito.`,
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: 'Ir a contratos de renta diaria',
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: './',
    },
  },
};
