import { useEffect, useState, useCallback } from 'react';
import _isEmpty from 'lodash/isEmpty';

import { TableNavigation, Table, withForm } from '@casanova/casanova-common';
import SearchInput from 'components/SearchInput';
import useCleanModule from 'hooks/useCleanModule';

import columns from './columns';
import { config } from './config';

function ListOfInvoices({
  history,
  invoicesList,
  getInvoicesList,
  setListPage,
  resetListParams,
  getInvoiceDetails,
  invoiceDetails,
}) {
  const [hasSearched, setHasSearched] = useState(false);

  const { params } = invoicesList;

  useCleanModule({ module: 'invoices' });

  const handleRowClick = useCallback(({ searchText, serie, folio }) => {
    const realFolio = searchText || `${serie}${folio}`;
    getInvoiceDetails(realFolio);
    setHasSearched(true);
  }, []);

  useEffect(() => {
    if (!_isEmpty(invoiceDetails) && hasSearched) {
      const { serie, folio } = invoiceDetails;
      const realFolio = `${serie}${folio}`;
      history.push(`/invoices/${realFolio}/detail`);
    }
  }, [invoiceDetails, hasSearched]);

  useEffect(() => {
    getInvoicesList({ params });
  }, [getInvoicesList, params]);

  useEffect(() => {
    resetListParams();
  }, []);

  // console.log(invoicesList.results);

  return (
    <>
      {/* <div className="col-md-12">
        <SearchInput
          name="searchText"
          placeholder="Buscar factura"
          subtitle="Folio de factura o No. de contrato."
          onSubmit={handleRowClick}
        />
      </div> */}

      <Table
        additionalHeader
        rowClassName="row-clickable"
        emptyMessage="No se encontraron resultados"
        rowClick={handleRowClick}
        dataList={invoicesList.results}
        columns={columns}
      />

      <div className="row">
        <div className="col-12 col-xl-7 table-paginator text-right d-flex justify-content-between align-items-center">
          <TableNavigation search={invoicesList} onChange={setListPage} />
        </div>
      </div>
    </>
  );
}

export default withForm({ config })(ListOfInvoices);
