import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
  CardSection,
  TextField,
  FileViewer,
  UserInfo,
} from '@casanova/casanova-common';
import i18n from '@i18n';

import { getTextColor } from 'utils/customers';
import { CUSTOMER_DETAIL_KEYS } from '@customers/common/constants';
import { isForeignCustomer, isPermanentLicense } from '@customers/common/utils';

const DriverLicenseDetail = ({
  data,
  listOfInvalidsDocuments = [],
  customerDetail,
}) => {
  const [invalidDocument, setInvalidDocument] = useState({});

  const { number, sinceDate, validityYear, issuedYear } = data;

  const documentStatus = _get(data, 'documentStatus.name', '');
  const licenseType = _get(data, 'driverLicenseType.name');
  const nationality = _get(data, 'nationality.name');
  const state = _get(data, 'issuingState.name');
  const isForeign = isForeignCustomer(customerDetail);

  const statusColor = getTextColor(
    invalidDocument ? 'rechazada' : documentStatus
  );

  const files = !_isEmpty(data)
    ? [
        _get(data, 'frontScanFile.presignedUrl', undefined),
        _get(data, 'backScanFile.presignedUrl', undefined),
      ]
    : undefined;

  useEffect(() => {
    setInvalidDocument(
      listOfInvalidsDocuments.find(
        ({ documentKey = '' }) =>
          documentKey === CUSTOMER_DETAIL_KEYS.DRIVER_LICENSE
      )
    );
  }, [listOfInvalidsDocuments]);

  const validityYearText = useMemo(
    () =>
      isPermanentLicense(validityYear)
        ? i18n('COMMONS__PERMANENT')
        : validityYear,
    [validityYear]
  );

  return (
    <CardSection status={statusColor} title="Licencia de Conducir" upperCase>
      <div className="row">
        <div className="col-7">
          <FileViewer files={files} />
        </div>
        <div className="col-5">
          <TextField
            title={i18n('COMMONS__STATUS')}
            status={statusColor}
            value={documentStatus.toUpperCase()}
          />
          <TextField title="Tipo de licencia" value={licenseType} />
          <div className="row">
            <div className="col-6">
              <TextField title="Número licencia" value={number} />
            </div>
            <div className="col-6">
              <TextField title="ANTIGÜEDAD" value={sinceDate} />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <TextField title="EXPEDICIÓN" value={issuedYear} />
            </div>
            <div className="col-4">
              <TextField title="Vigencia" value={validityYearText} />
            </div>
            <div className="col-4">
              <TextField title="Nacionalidad" value={nationality} />
            </div>
          </div>
          {!isForeign && <TextField title="Estado de emisión" value={state} />}
          <UserInfo {...data} />
        </div>
      </div>
    </CardSection>
  );
};

DriverLicenseDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

DriverLicenseDetail.defaultProps = {
  data: {},
};

export default DriverLicenseDetail;
