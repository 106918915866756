import { Contract } from '@contracts/sections';
import { config } from '@contracts/common/config';
import { BackButton } from 'components';
import withResponseDialog from '@hocs/withResponseDialog';

function ContractDetails({ closeResponseDialog, responseDialog, ...props }) {
  return (
    <section>
      <Contract
        {...props}
        config={{
          ...config,
          showContinue: false,
          showCancel: false,
          redirectInSuccess: false,
        }}
        left={<BackButton path="/contracts" />}
      />
    </section>
  );
}

export default withResponseDialog(ContractDetails);
