import moment from 'moment';
import {
  timeZone,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/constants/index.js';

const transformer = (contracts) => {
  const transformed = contracts.map((contract) => ({
    uuid: contract.uuid,
    reservationFolio: contract.reservationFolio,
    contractNumber: contract.contractNumber,
    amount: contract.amount,
    departureBranchUuid: contract.departureBranchUuid,
    departureBranch: contract.departureBranch,
    returnBranch: contract.returnBranch,
    startDate: moment(contract.startDate)
      .tz(timeZone)
      .format(`${YYYY_MM_DD} HH:mm`),
    finalDate: moment(contract.finalDate)
      .tz(timeZone)
      .format(`${YYYY_MM_DD} HH:mm`),
    leasingContract: contract.leasingContract,
    promissoryNoteContract: contract.promissoryNoteContract,
    leasingContractOriginal: contract.leasingContractOriginal,
    promissoryNoteContractOriginal: contract.promissoryNoteContractOriginal,
    changeVehicleRequest: contract.changeVehicleRequest,
    status: contract.status,
    reservationUuid: contract.reservationUuid,
    changeRequest: contract.changeRequest,
    naturalPersonName: contract.naturalPersonName,
    plateNumber: contract.plateNumber,
    closeDate: contract.closeDate,
    reservationStep: contract.reservationStep,
  }));

  return transformed;
};

export default transformer;
