import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './index.scss';

const TextField = ({ value, title, status, noMb }) => {
  const cls = classNames(status && `text-${status}`);

  return (
    <div className={`form-group TextField ${noMb ? 'TextField--no-mb' : ''}`}>
      <label>{title.toUpperCase()}</label>
      <br />
      <span className={cls}>{value}</span>
    </div>
  );
};

TextField.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
};

TextField.defaultProps = {
  value: '',
  title: '',
  status: undefined,
};

export default TextField;
