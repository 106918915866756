import React from 'react';
import { Contract } from '@contracts/sections';
import { config } from '@contracts/common/config';

export default function ContractCreator(props) {
  return (
    <section>
      <Contract {...props} config={config} />
    </section>
  );
}
