import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import classNames from 'classnames';
import i18n from '@i18n';
import { Button, Icons, ActionModal } from '@casanova/casanova-common';
import { PreventChange, BackButton, RoleVerifier } from 'components';
import { CustomerStepper } from '@customers/components';
import {
  CustomerInformationDetail,
  OfficialIdentificationDetail,
  AddressDetail,
  CompanyAddressDetail,
  CompanyDataDetail,
  AccommodationRent,
  AccommodationRentalAndFamily,
  CountryEntranceTypeDetail,
  DriverLicenseDetail,
  InvoiceDataDetail,
  AuthorizedDriverDetail,
} from '@customers/detail-sections';
import withResponseDialog from '@hocs/withResponseDialog';
import {
  getQuotePath,
  isContractQuote,
  QUOTE_CONTRACT_QUERY,
} from 'utils/navigation';
import {
  CUSTOMER_DETAIL_KEYS,
  ACCOMMODATION_TYPES,
  CUSTOMER_TYPES,
} from '@customers/common/constants';
import { DOCUMENTS_STATUS } from 'utils/constants/documentsStatus';
import { CUSTOMER_LEVELS } from 'utils/constants/customerLevels';
import { EDIT_CUSTOMER_INFORMATION } from 'utils/roles/permissions/customerPermissions';
import { useSelector } from 'react-redux';

const { NEEDS_VERIFICATION } = DOCUMENTS_STATUS;
const {
  INE,
  PASSPORT,
  PROFESSIONAL_LICENSE,
  INAPAM,
  VISA,
  DRIVER_LICENSE,
  ADDRESS,
} = CUSTOMER_DETAIL_KEYS;

const CustomerDetail = ({
  match,
  getDetail,
  customer,
  history,
  quoteId,
  listOfInvalidsDocuments = [],
  resetDetail,
  selectionOfAvalCustomerByDetail,
  setCustomerType,
  setCustomerPersonType,
  setAvalCustomerByDetail,
  setContractQuote,
  notPriceQuote,
}) => {
  const isReservation = history.location.pathname.includes('reservations');
  const customerId = match.params.id;
  const hasContract = isContractQuote();

  const [shouldBlock, setShouldBlock] = useStateWithCallbackLazy(
    isReservation || selectionOfAvalCustomerByDetail || hasContract
  );
  const [showLoadDocuments, setShowLoadDocuments] = useState(false);
  const [showInvalidDocuments, setShowInvalidDocuments] = useState(false);
  const [
    showLoadOfficialIdentification,
    setShowLoadOfficialIdentification,
  ] = useState(false);
  const [showLoadDriverLicense, setShowLoadDriverLicense] = useState(false);

  const {
    isMoral,
    isForeign,
    driverLicense,
    address,
    identityDocuments,
    isAccommodationRent,
    authorizedDrivers,
    category,
  } = useMemo(() => {
    const unorderedIdentityDocuments = _get(
      customer,
      'data.identityDocuments',
      []
    );
    const orderIdentityDocuments = _orderBy(
      unorderedIdentityDocuments,
      'identityDocumentType.identifier',
      'asc'
    );

    return {
      isMoral:
        !_isEmpty(_get(customer, 'data.juridicalPerson', {})) ||
        _get(customer, 'data.tender.tenderType') === CUSTOMER_TYPES.JURIDICAL,
      isForeign: _get(customer, 'data.isForeign', false),
      driverLicense: _get(customer, 'data.driverLicense', null),
      address: _get(customer, 'data.address', null),
      identityDocuments: orderIdentityDocuments,
      isAccommodationRent:
        _get(customer, 'data.accommodation.type.identifier', '') ===
        ACCOMMODATION_TYPES.RENT,
      authorizedDrivers: _get(
        customer,
        'data.juridicalPerson.authorizedDrivers',
        _get(customer, 'data.authorizedDrivers', [])
      ),
      category: _get(customer, 'data.category', {}),
    };
  }, [customer]);

  const MODAL_BY_SECTION = useMemo(
    () => ({
      [INE]: setShowLoadOfficialIdentification,
      [PASSPORT]: setShowLoadOfficialIdentification,
      [PROFESSIONAL_LICENSE]: setShowLoadOfficialIdentification,
      [INAPAM]: setShowLoadOfficialIdentification,
      [VISA]: setShowLoadOfficialIdentification,
      [DRIVER_LICENSE]: setShowLoadDriverLicense,
      [ADDRESS]: setShowInvalidDocuments,
      [NEEDS_VERIFICATION]: setShowLoadDocuments,
    }),
    []
  );

  // const validateInfo = () => {
  //   const validateInfoToForeings = ['curp'];

  //   const esValido = validateInfoToForeings.every(
  //     (propiedad) =>
  //       customer.data[propiedad] !== null && customer.data[propiedad] !== ''
  //   );

  //   console.log(esValido);
  //   if (customer.data.isForeign) {
  //     console.log('rod la chupa');
  //     return;
  //   }
  //   console.log('rod no extranjero');
  // };
  const getModal = useCallback(({ documentType, documentKey }) => {
    if (documentType === NEEDS_VERIFICATION) {
      return MODAL_BY_SECTION[documentType];
    }
    return MODAL_BY_SECTION[documentKey];
  }, []);

  const getEditPath = () => {
    let path = `/customers/${customerId}/edit`;

    if (isReservation) {
      path = `/reservations/customer/${customerId}/edit${
        hasContract ? `?${QUOTE_CONTRACT_QUERY}` : ''
      }`;
    }

    return path;
  };

  const handleOnEdit = useCallback(() => {
    setShouldBlock(false);
    const path = getEditPath();

    setTimeout(() => {
      history.push(path);
    }, 1);
  }, [history, customerId, isReservation]);

  const openInvalidDocument = () => {
    if (listOfInvalidsDocuments.length > 0) {
      const setModal = getModal(listOfInvalidsDocuments[0]);
      if (setModal) setModal(true);
    }
  };
  const hasBirthDate = Boolean(
    useSelector((state) =>
      _get(state, 'customers.customerDetail.data.userInfo.birthDate', '')
    )
  );

  const handleOnContinue = () => {
    setShouldBlock(false, () => {
      const isWoodLevel = category.level === CUSTOMER_LEVELS.WOOD;
      const hasInvalidDocs = listOfInvalidsDocuments.length > 0 && isWoodLevel;

      if (hasInvalidDocs) openInvalidDocument();
      else history.push('/reservations/payment');
    });
  };

  const handleLoad = useCallback(() => {
    setShowLoadDocuments(false);
    if (isMoral || isForeign) return;

    const path = getEditPath();

    history.push(path);
  }, [customerId, isReservation, history, isMoral, isForeign]);

  const handleLoadClose = useCallback(() => {
    setShowLoadDocuments(false);
  }, []);

  const handleBack = useCallback(() => {
    setShouldBlock(false);

    if (hasContract) {
      setTimeout(() => {
        history.push('/reservations/contract-quote');
      }, 1);
    }
  }, [history, hasContract]);

  const handleCloseShowOfficialIdentification = () => {
    setShowLoadOfficialIdentification(false);
  };

  const handleCloseShowDriverLicense = () => {
    setShowLoadDriverLicense(false);
  };

  const handleCloseShowInvalidDocuments = () => {
    setShowInvalidDocuments(false);
  };

  const handleAcceptAvalCustomer = () => {
    setAvalCustomerByDetail(customer.data || {});
    setCustomerType(CUSTOMER_TYPES.FOREGINER);
    setCustomerPersonType('');

    setShouldBlock(false, () => {
      if (isReservation) history.goBack();
      else history.push(`/customers/add`);
    });
  };

  const handleConfirmation = useCallback(() => {
    // Reset contract quote
    if (hasContract) setContractQuote(false);
  }, [hasContract, setContractQuote]);

  useEffect(() => {
    if (isReservation && !quoteId && !hasContract) {
      window.location.href = getQuotePath('one');
    }
    getDetail({ id: customerId, withLoader: true });
  }, [customerId]);

  useEffect(
    () => () => {
      if (!isReservation) resetDetail();
    },
    [resetDetail, isReservation]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PreventChange
        preventChange={shouldBlock}
        icon={<Icons.IcoClientesWarning />}
        onConfirmation={handleConfirmation}
      />
      {listOfInvalidsDocuments.length > 0 && (
        <>
          <ActionModal
            open={showLoadOfficialIdentification}
            icon={<Icons.UserAlert />}
            title={i18n('CUSTOMERS__LOAD_OFICIAL_IDENTIFICATION__MODAL__TITLE')}
            message={i18n(
              'CUSTOMERS__LOAD_OFICIAL_IDENTIFICATION__MODAL__MESSAGE'
            )}
            actionLabel={i18n('COMMONS__LOAD__TEXT')}
            closeLabel={i18n('COMMONS__CLOSE__TEXT')}
            onClose={handleCloseShowOfficialIdentification}
            onAction={handleOnEdit}
          />
          <ActionModal
            open={showLoadDriverLicense}
            icon={<Icons.UserAlert />}
            title={i18n('CUSTOMERS__LOAD_DRIVER__LICENSE__MODAL__TITLE')}
            message={i18n('CUSTOMERS__LOAD_DRIVER__LICENSE__MODAL__MESSAGE')}
            actionLabel={i18n('COMMONS__LOAD__TEXT')}
            closeLabel={i18n('COMMONS__CLOSE__TEXT')}
            onClose={handleCloseShowDriverLicense}
            onAction={handleOnEdit}
          />

          <ActionModal
            open={showInvalidDocuments}
            icon={<Icons.UserAlert />}
            title={i18n('CUSTOMERS__INVALID_DOCUMENTS__MODAL__TITLE')}
            message={i18n('CUSTOMERS__INVALID_DOCUMENTS__MODAL__MESSAGE')}
            actionLabel={i18n('COMMONS__LOAD__TEXT')}
            closeLabel={i18n('COMMONS__CLOSE__TEXT')}
            onClose={handleCloseShowInvalidDocuments}
            onAction={handleOnEdit}
          />

          <ActionModal
            open={showLoadDocuments}
            icon={<Icons.UserAlert />}
            title={i18n('CUSTOMERS__LOAD_DOCUMENTS__MODAL__TITLE')}
            message={i18n('CUSTOMERS__LOAD_DOCUMENTS__MODAL__MESSAGE', [
              listOfInvalidsDocuments[0].formatSpanishLastVerifiedDate || '',
            ])}
            actionLabel={i18n('COMMONS__LOAD__TEXT')}
            closeLabel={i18n('COMMONS__CLOSE__TEXT')}
            onClose={handleLoadClose}
            onAction={handleLoad}
            textBold={['Última actualización:']}
          />
        </>
      )}
      {isReservation && (
        <CustomerStepper
          title={i18n('CUSTOMERS__STEPPER__VERIFY__TITLE')}
          subtitle={i18n('CUSTOMERS__STEPPER__VERIFY__SUBTITLE')}
        />
      )}
      <CustomerInformationDetail
        customerId={customerId}
        isReservation={isReservation}
      />
      {isMoral && <CompanyAddressDetail />}
      <InvoiceDataDetail />
      {isForeign && !isAccommodationRent && <AccommodationRentalAndFamily />}
      {identityDocuments.map((identityDocument) => (
        <OfficialIdentificationDetail
          key={identityDocument.uuid}
          identityDocument={identityDocument}
        />
      ))}
      {isMoral && <CompanyDataDetail />}
      {isForeign && <CountryEntranceTypeDetail />}
      {isForeign && isAccommodationRent && <AccommodationRent />}
      {address && !isMoral && <AddressDetail />}
      {driverLicense && <DriverLicenseDetail />}
      {authorizedDrivers.length > 0 &&
        authorizedDrivers.map((driver, i) => (
          <AuthorizedDriverDetail
            key={driver.uuid}
            driverData={driver}
            current={authorizedDrivers.length > 1 ? i + 1 : ''}
          />
        ))}
      {!isReservation ||
      (hasContract && !notPriceQuote.success) ||
      selectionOfAvalCustomerByDetail ? (
        <div className="row">
          {selectionOfAvalCustomerByDetail ? (
            <div className="offset-3 offset-lg-8 offset-xl-10 col-6 col-lg-4 col-xl-2">
              <Button block onClick={handleAcceptAvalCustomer}>
                {i18n('COMMONS__ACCEPT__TEXT')}
              </Button>
            </div>
          ) : (
            <>
              <div className={classNames('col-6 col-lg-4 col-xl-2')}>
                <BackButton
                  path="/customers/"
                  onClick={hasContract && handleBack}
                />
              </div>
              <div
                className={classNames(
                  'col-6 col-lg-4 col-xl-2 offset-lg-4 offset-xl-8',
                  hasContract && 'col-4'
                )}
              >
                <RoleVerifier identifier={EDIT_CUSTOMER_INFORMATION}>
                  <Button block onClick={handleOnEdit}>
                    {i18n('COMMONS__EDIT')}
                  </Button>
                </RoleVerifier>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-6 col-lg-4 col-xl-2">
            <RoleVerifier identifier={EDIT_CUSTOMER_INFORMATION}>
              <Button block outline onClick={handleOnEdit}>
                {i18n('COMMONS__EDIT')}
              </Button>
            </RoleVerifier>
          </div>
          <div className="offset-3 offset-lg-4 offset-xl-8 col-6 col-lg-4 col-xl-2">
            <Button block disabled={!hasBirthDate} onClick={handleOnContinue}>
              {i18n('COMMONS__CONTINUE__TEXT')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default withResponseDialog(CustomerDetail);
