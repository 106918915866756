import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withLayout } from '@casanova/casanova-common';
import {
  closeResponseDialog,
  getTransferDetail,
} from 'store/transfers/actions';
import { searchEmployees } from 'store/customers/actions';
import { searchVehicles } from 'store/vehicles/actions';
import { fetchCatalog } from 'store/commons/actions';
import EditTransfer from './EditTransfer';

export default withLayout(
  compose(
    connect(
      (state) => ({
        loader: _get(state, 'commons.loader', false),
        responseDialog: _get(state, 'transfers.responseDialog', {}),
        transferDetail: _get(state, 'transfers.detail', {}),
        onCancelPath: '/transfers',
      }),
      {
        fetchCatalog,
        closeResponseDialog,
        searchEmployees,
        searchVehicles,
        getTransferDetail,
      }
    )
  )(EditTransfer)
);
