import ReportsModuleIcon from '@casanova/casanova-common/lib/Icons/ReportsModule';
import i18n from '@i18n';
import { REPORTS_MODULE } from 'utils/roles/permissions/reportsPermissions';
import { withReconcileNav } from 'containers/Breadcrumb';
import { withRoleRestrictions } from 'utils/auth';
import { SearchReports } from '@reports';

const MODULE_KEY = 'reports';

export const reports = {
  moduleName: REPORTS_MODULE,
  route: '/reports',
  label: i18n('REPORTS__MENU_LABEL'),
  icon: ReportsModuleIcon,
  main: 'main',
  children: {
    main: {
      route: '',
      label: '',
      format: () => '/reports',
      component: withReconcileNav(
        MODULE_KEY,
        'main'
      )(withRoleRestrictions(SearchReports)),
    },
  },
};
