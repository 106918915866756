import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Section,
  ConnectedSelect,
  DatePickerV2,
  TextAreaInput as TextArea,
} from '@casanova/casanova-common';
import { textAreaRegex } from '@casanova/casanova-common/lib/utils/masks';
import { ContractBigModal } from '@contracts/sections';
import i18n from '@i18n';
import { isSafari } from 'utils/browser';
import { config } from './config';

const LoanForm = ({
  disabledInput,
  initialValues,
  contractDetails,
  vehicle,
}) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (contractDetails.success) {
      if (vehicle.vehicleUuid) {
        setFieldValue('currentVehicle', vehicle.vehicleUuid);
      }

      setFieldValue('uuid', contractDetails.data.uuid);
    }
  }, [contractDetails, vehicle]);

  return (
    <Section title={i18n('CONTRACTS__LOAN__TITLE')} upperCase>
      <div className="row">
        <div className="col-md-4">
          <ConnectedSelect
            placeholder={i18n('CONTRACTS__LOAN__REASON', ['*'])}
            name="reason"
            label={i18n('CONTRACTS__LOAN__REASON', ['*'])}
            options="commons.catalogsTemporaryChangeReasons"
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={initialValues.startDate}
            placeholder={i18n('CONTRACTS__LOAN__START_DATE', ['*'])}
            name="startDate"
            label={i18n('CONTRACTS__LOAN__START_DATE', ['*'])}
            disabled={disabledInput}
            placement={isSafari() ? 'bottom' : undefined}
            formatDateValue
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={initialValues.endDate}
            placeholder={i18n('CONTRACTS__LOAN__END_DATE', ['*'])}
            name="endDate"
            label={i18n('CONTRACTS__LOAN__END_DATE', ['*'])}
            disabled={disabledInput}
            placement={isSafari() ? 'bottom' : undefined}
            formatDateValue
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TextArea
            label={i18n('CONTRACTS__LOAN__REMARKS', ['*'])}
            placeholder={i18n('CONTRACTS__LOAN__REMARKS', ['*'])}
            name="remarks"
            disabled={disabledInput}
            regex={textAreaRegex}
            maxLength={200}
          />
        </div>
      </div>
    </Section>
  );
};

export default function Loan({ fetchCatalog, ...props }) {
  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/temporary-change-reasons',
      catalogPath: 'pricequotes',
    });
  }, []);

  return (
    <ContractBigModal
      {...props}
      config={config}
      responseDialog={props.responseDialogLoan}
    >
      {(helpers) => <LoanForm {...helpers} />}
    </ContractBigModal>
  );
}
