import { getLastSearchField } from '@casanova/casanova-common/lib/utils/transformHelpers';
import { LOAN_CREATOR_FIELDS as FIELDS } from '@loans/common/constants';

export const transformer = (values = { action: null }) => {
  const transformedValues = {
    ...values,
    [`${FIELDS.PLATE}_search`]: values.plateNumber,
    [getLastSearchField(FIELDS.PLATE)]: values.plateNumber,
    [`${FIELDS.REQUESTER}_search`]: values.requesterName,
    [FIELDS.AUTHORIZER]: values.inserted.by.fullname,
  };

  if (values.action) {
    transformedValues.action = values.action;
  }

  return transformedValues;
};
