import { call, put, select } from 'redux-saga/effects';
import { showLoader, hideLoader } from 'store/commons/actions';
import {
  getLoans,
  getLoansFailed,
  getLoansSucceed,
  saveLoanFailed,
  saveLoanSucceed,
  getLoanDetailsSucceed,
  getLoanDetailsFailed,
  editLoanSucceed,
  editLoanFailed,
  closeLoanSucceed,
  closeLoanFailed,
  cancelLoanSucceed,
  cancelLoanFailed,
  authorizeLoanSucceed,
  authorizeLoanFailed,
} from 'store/loans/actions';
import {
  getLoansList,
  postLoan,
  getLoanDetails,
  patchLoan,
  postCloseLoan,
  postCancelLoan,
  postAuthorizeLoan,
} from 'services/loans';

export function* getLoansListSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getLoansList, action.payload);
    yield put(getLoansSucceed(data));
  } catch (error) {
    yield put(getLoansFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveLoanSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(postLoan, payload);
    yield put(saveLoanSucceed(data));
  } catch (error) {
    yield put(saveLoanFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getLoanDetailsSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getLoanDetails, action.payload);
    yield put(getLoanDetailsSucceed(data));
  } catch (error) {
    yield put(getLoanDetailsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* editLoanSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(patchLoan, payload);
    yield put(editLoanSucceed(data));
  } catch (error) {
    yield put(editLoanFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getListOfLoans() {
  try {
    const loans = yield select((state) => state.loans.list);
    const { params, searchPattern, filters } = loans;
    yield put(getLoans({ params, searchPattern, filters }));
  } catch (error) {
    console.error(error);
  }
}

export function* closeLoanSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(postCloseLoan, payload);
    yield put(closeLoanSucceed(data));
    yield getListOfLoans();
  } catch (error) {
    yield put(closeLoanFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* cancelLoanSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(postCancelLoan, payload);
    yield put(cancelLoanSucceed(data));
    yield getListOfLoans();
  } catch (error) {
    yield put(cancelLoanFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* authorizeLoanSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(postAuthorizeLoan, payload);
    yield put(authorizeLoanSucceed(data));
    yield getListOfLoans();
  } catch (error) {
    yield put(authorizeLoanFailed(error));
  } finally {
    yield put(hideLoader());
  }
}
