import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withLayout } from '@casanova/casanova-common';
import { fetchCatalog } from 'store/commons/actions';
import {
  downloadXlsx,
  getDetail,
  resetDetail,
  setSelectionOfAvalCustomerByDetail,
  setCustomerType,
  setCustomerPersonType,
  setAvalCustomerByDetail,
} from 'store/customers/actions';
import { setContractQuote } from 'store/reservations/actions';
import i18n from '@i18n';
import CustomerDetail from './CustomerDetail';

const mapDispatchToProps = (dispatch, { match, history }) => {
  const getDispatch = (action) => (...args) => dispatch(action(...args));
  return {
    onClose: () => {
      history.goBack();
      return dispatch(resetDetail());
    },
    onError: () => dispatch(getDetail(match.params.id)),
    fetchCatalog: getDispatch(fetchCatalog),
    getDetail: getDispatch(getDetail),
    downloadAction: getDispatch(downloadXlsx),
    resetDetail: getDispatch(resetDetail),
    setSelectionOfAvalCustomerByDetail: getDispatch(
      setSelectionOfAvalCustomerByDetail
    ),
    setCustomerType: getDispatch(setCustomerType),
    setCustomerPersonType: getDispatch(setCustomerPersonType),
    setAvalCustomerByDetail: getDispatch(setAvalCustomerByDetail),
    setContractQuote: getDispatch(setContractQuote),
  };
};

const mapStateToProps = (state) => ({
  customer: _get(state, 'customers.customerDetail', {}),
  quoteId: _get(state, 'reservations.quote.values.quoteId', undefined),
  listOfInvalidsDocuments: _get(
    state,
    'customers.customerDetail.data.listOfInvalidsDocuments',
    []
  ),
  selectionOfAvalCustomerByDetail: _get(
    state,
    'customers.selectionOfAvalCustomerByDetail',
    false
  ),
  location: state.router.location,
  notPriceQuote: state.reservations.notPriceQuote,
  responseDialog: {
    open: Boolean(state.customers.customerDetail.error),
    success: false,
    errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
    errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
    errorLabel: i18n('COMMONS__TRY_AGAIN'),
  },
});

export const CustomerNoDownload = withLayout(
  compose(connect(mapStateToProps, mapDispatchToProps))(CustomerDetail)
);

export default withLayout(
  compose(connect(mapStateToProps, mapDispatchToProps))(CustomerDetail),
  downloadXlsx
);
