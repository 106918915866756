import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import {
  applyBranchDiscount,
  cancelBranchDiscount,
  closeResponseDialog,
  validatePromocode,
  openSendQuoteDialog,
  editPlan,
  editDiscount,
  cancelEditDiscount,
  savePayment,
} from 'store/reservations/actions';
import Summary from './Summary';

export default compose(
  connect(
    (state) => {
      return {
        ..._get(state, 'reservations.quote.values', {}),
        extendContract: _get(state, 'reservations.contract.extendContract', {}),
        branches: _get(state, 'commons.branches.list', []),
        discountOptions: _get(state, 'reservations.filters.discounts', []),
        customerName: _get(state, 'customers.customerDetail.data.fullName', ''),
        selectedCategoryName: _get(
          state,
          'reservations.filters.selectedCategory.label',
          ''
        ),
        reservationId: _get(state, 'reservations.reservation.id', ''),
      };
    },
    {
      applyBranchDiscount,
      cancelBranchDiscount,
      closeResponseDialog,
      validatePromocode,
      openSendQuoteDialog,
      editPlan,
      editDiscount,
      cancelEditDiscount,
      savePayment,
    }
  )
)(Summary);
