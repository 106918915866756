import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { CardSection, TextField } from '@casanova/casanova-common';
import { ACCOMMODATION_TYPES } from '@customers/common/constants';

const RentalAndFamily = () => {
  const data = useSelector((state) =>
    _get(state, 'customers.customerDetail.data.accommodation', {})
  );
  const accommodationType = _get(data, 'type', {});
  const hotel = _get(data, 'hotel', '');
  const reservationNumber = _get(data, 'reservationNumber', '');
  const reservationConfirmed = _get(data, 'reservationConfirmed', '');

  const relationType = _get(data, 'subtype.name', '');

  // TODO: personal guarantee on text field
  // const personalGuarantee = _get(data, 'naturalPersonGuarantee', '');

  return (
    <CardSection noStatus upperCase title="Hospedaje">
      <div className="row mt-3">
        <div className="col-3 mb-2">
          <TextField title="Tipo de hospedaje" value={accommodationType.name} />
        </div>

        {accommodationType.identifier === ACCOMMODATION_TYPES.HOTEL && (
          <>
            <div className="col-3 mb-2">
              <TextField noMb title="Hotel" value={hotel} />
            </div>
            <div className="col-3 mb-2">
              <TextField
                noMb
                title="Número de reservación"
                value={reservationNumber}
              />
            </div>
            <div className="col-3 mb-2">
              <TextField
                noMb
                title="¿Se confirmó la reservación?"
                value={reservationConfirmed ? 'Si' : 'No'}
              />
            </div>
          </>
        )}

        {accommodationType.identifier === ACCOMMODATION_TYPES.FAMILY_FRIEND && (
          <>
            <div className="col-3 mb-2">
              <TextField noMb title="Tipo de relación" value={relationType} />
            </div>
            {/*
            // TODO: personal guarantee on text field
            <div className="col-3 mb-2">
              <TextField
                noMb
                title="Aval"
                value={personalGuarantee}
              />
            </div> */}
          </>
        )}
      </div>
    </CardSection>
  );
};

export default RentalAndFamily;
