import moment from 'moment';
import 'moment/locale/es';
import {
  getPrevYear,
  getNextYear,
  SPANISH_FORMAT,
} from '@casanova/casanova-common/lib/utils/date';
import { notValidDocument } from 'utils/customers';

moment.locale('es');

export const EMISSION_AND_VALIDITY_VALIDATIONS = {
  EMISSION: {
    MIN_DATE: getPrevYear({ years: 90 }),
    MAX_DATE: getPrevYear({ years: 0 }),
  },
  VALIDITY: {
    MIN_DATE: undefined,
    MAX_DATE: getNextYear({ years: 90 }),
  },
  ANTIQUITY: {
    MIN_DATE: getPrevYear({ years: 90 }),
    MAX_DATE: getNextYear({ years: 0 }),
  },
};

export const getCustomerDocumentsValidations = ({
  documentStatus = {},
  lastVerifiedSetDate = '',
  key = '',
}) => {
  const documentType = documentStatus.id;
  const formattedLastVerifiedSetDate = lastVerifiedSetDate
    ? moment(lastVerifiedSetDate)
    : null;
  const formatSpanishLastVerifiedDate = formattedLastVerifiedSetDate
    ? formattedLastVerifiedSetDate.format(SPANISH_FORMAT)
    : null;
  const invalidDocument = notValidDocument({ documentType });

  const documentValidations = {
    formatSpanishLastVerifiedDate,
    invalidDocument,
    documentType,
    key,
  };

  return documentValidations;
};
