import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import Layout from 'components/Layout';
import { hydrateUserData } from 'store/auth/actions';
import { getDefinitions } from 'utils/menu';

const { routes, menuItems } = getDefinitions();

export function renderRoutes() {
  return routes.map((route) => {
    const { path, component: Component } = route;
    return <Route key={path} exact path={path} component={Component} />;
  });
}

const NotPathRedirect = () => {
  const history = useHistory();
  useEffect(() => {
    history.push('/vehicle');
  }, []);
  return null;
};

function Root() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hydrateUserData());
  }, [dispatch]);

  return (
    <Layout logoSara sidebarItems={menuItems}>
      <Switch>
        {renderRoutes()}
        <Route path="*" exact component={NotPathRedirect} />
      </Switch>
    </Layout>
  );
}

export default Root;
