import _noop from 'lodash/noop';
import transformer from './transformer';

const initialValues = {
  contractNumber: '',
  contractUuid: '',
  customer: '',
  customerUuid: '',
  vehicle: '',
  serialNumber: '',
  userOpened: '',
  userClosed: '',
  status: '',
};

const initialTouched = {
  contractNumber: true,
  contractUuid: true,
  customer: true,
  customerUuid: true,
  vehicle: true,
  serialNumber: true,
  userOpened: true,
  userClosed: true,
  status: true,
};

export const config = {
  schema: {},
  initialTouched,
  initialValues,
  enableReinitialize: true,
  withButtons: false,
  transformer,
  submitForm: _noop,
  btnSubmitLabel: '',
  dialogs: {
    confirmation: {},
    response: {},
  },
};
