import { transformIdOfList } from 'utils/transformHelpers';
import { paginateList } from 'utils/pagination';
import types from './actions-types';

const initialState = {
  responseDialog: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
  },
  list: {
    params: {
      page: 0,
      size: 10,
    },
    totalPages: 1,
    totalElements: 10,
    results: [],
    fullContent: [],
  },
  reportsColumns: [],
  typesOfReports: {
    success: false,
    error: false,
    list: [],
  },
  downloadReports: {
    data: {},
    success: false,
    error: false,
  },

  response: {
    data: [],
    errorCode: '',
    message: '',
  },
};

export function reportsReducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.CLOSE_RESPONSE_DIALOG:
      return {
        ...state,
        responseDialog: initialState.responseDialog,
      };
    case types.SET_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            ...payload,
          },
        },
      };
    case types.SET_LIST_PAGE: {
      const { size } = state.list.params;
      // TODO: Waiting for Carlos pagination to be implemented
      const newResults = paginateList(state.list.fullContent, payload, size);
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            page: payload,
          },
          results: newResults,
        },
      };
    }
    case types.GET_LIST_FAILED:
      return {
        ...state,
        response: {
          data: [],
          errorCode: true,
          message: payload.status,
        },
      };
    case types.GET_LIST:
      return {
        ...state,
        response: {
          data: payload.data,
          errorCode: payload.success,
          message: payload.status,
        },
      };
    case types.CLEAN_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          results: [],
        },
      };
    case types.GET_LIST_SUCCEED:
      return {
        ...state,
        response: {
          data: payload.data,
          errorCode: payload.success,
          message: payload.status,
        },
      };
    case types.SET_REPORT_COLUMNS:
      return {
        ...state,
        reportsColumns: payload,
      };
    case types.GET_TYPES_OF_REPORTS_SUCCEED:
      return {
        ...state,
        typesOfReports: {
          success: true,
          error: false,
          list: payload,
        },
      };
    case types.GET_TYPES_OF_REPORTS_FAILED:
      return {
        ...state,
        typesOfReports: {
          success: false,
          error: true,
          list: [],
        },
      };
    case types.DOWNLOAD_REPORTS:
      return {
        ...state,
        downloadReports: initialState.downloadReports,
      };
    case types.DOWNLOAD_REPORTS_SUCCEED:
      return {
        ...state,
        downloadReports: {
          data: payload,
          success: true,
          error: false,
        },
      };
    case types.DOWNLOAD_REPORTS_FAILED:
      return {
        ...state,
        downloadReports: {
          data: {},
          success: false,
          error: true,
        },
      };
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}
