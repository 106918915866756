import _get from 'lodash/get';

const pageToList = (response, catalogName) =>
  _get(response, `_embedded.${catalogName}`);

function compare(a, b) {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}

const pageToZipCode = (response) => {
  const base = {
    state: [],
    municipality: [],
    suburbs: [],
  };
  if (response.page.totalElements === 0) return base;

  const suburbs = _get(response, '_embedded.suburbs', {});
  const firstSuburb = suburbs[0];

  return {
    ...base,
    state: {
      list: [
        {
          uuid: _get(firstSuburb, 'city.municipality.state.uuid'),
          value: _get(firstSuburb, 'city.municipality.state.uuid'),
          name: _get(firstSuburb, 'city.municipality.state.name'),
          label: _get(firstSuburb, 'city.municipality.state.name'),
        },
      ],
    },
    municipality: {
      list: [
        {
          uuid: _get(firstSuburb, 'city.municipality.uuid'),
          value: _get(firstSuburb, 'city.municipality.uuid'),
          name: _get(firstSuburb, 'city.municipality.name'),
          label: _get(firstSuburb, 'city.municipality.name'),
        },
      ],
    },
    suburbs: {
      list: suburbs
        .map((suburb) => ({
          uuid: suburb.uuid,
          value: suburb.uuid,
          name: suburb.name,
          label: suburb.name,
        }))
        .sort(compare),
    },
  };
};

export default {
  pageToList,
  pageToZipCode,
};
