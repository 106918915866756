export const VALID_DOCUMENTS_STATUS = {
  OPTIONAL_VERIFICATION: 'OPTIONAL_VERIFICATION',
  VERIFIED: 'VERIFIED',
};

export const NOT_VALID_DOCUMENTS_STATUS = {
  NOT_VERIFIED: 'NOT_VERIFIED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  NEEDS_VERIFICATION: 'NEEDS_VERIFICATION',
};

export const DOCUMENTS_STATUS = {
  ...VALID_DOCUMENTS_STATUS,
  ...NOT_VALID_DOCUMENTS_STATUS,
};
