import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { withLayout } from 'hooks';
import CloseContract from './CloseContract';

export default withLayout(
  compose(
    connect(
      (state) => ({
        loader: state.commons.loader,
        detailsOfCloseContract:
          state.dailyRentContracts.detailsOfCloseContract.data,
      }),
      {}
    )
  )(CloseContract)
);
