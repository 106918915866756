import { banCustomer } from 'store/customers/actions';
import BanSchema from './schema';
import BanFormatter from './formatter';

export const initialTouched = {
  contractNumber: true,
  reason: true,
};

export const initialValues = {
  contractNumber: '',
  reason: '',
};

export default {
  schema: BanSchema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: true,
  formatter: BanFormatter,
  submitForm: banCustomer,
  dialogs: {
    confirmation: {
      title: 'Boletinar cliente',
      message: '¿Estás seguro que quieres boletinar al cliente?',
      actionLabel: 'Sí, boletinar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage: 'El cliente ha sido boletinado',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Aceptar',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: 'refresh',
    },
  },
};
