import * as Yup from 'yup';
import { REQUIRED_MESSAGE } from 'utils/constants';
import { formatter } from './formatter';
import { transformer } from './transformer';
import { updateCard } from 'store/reservations/actions';
import i18n from '@i18n';
import { validityCardValidation } from 'utils/validations/cards';

const schema = Yup.object().shape({
  holdbackCardHolderModify: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3])),
  holdbackBankModify: Yup.string().required(REQUIRED_MESSAGE),
  holdbackCardModify: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(13, i18n('ERROR__COMMONS__MIN_LENGTH', [13])),
  holdbackValidityModify: validityCardValidation.required(REQUIRED_MESSAGE),
});

const config = {
  schema,
  initialTouched: {
    holdbackCardHolderModify: true,
    holdbackBankModify: true,
    holdbackCardModify: true,
    holdbackValidityModify: true,
    uuid: true,
  },
  initialValues: {
    uuid: '',
    holdbackCardHolderModify: '',
    holdbackBankModify: '',
    holdbackCardModify: '',
    holdbackValidityModify: '',
  },
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  alwaysSetTemporalValues: true,
  withInitialValues: true,
  formatter,
  transformer,
  submitForm: updateCard,
  btnSubmitLabel: 'Modificar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE MODIFICACIÓN',
      message: '¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {},
  },
};

export default config;
