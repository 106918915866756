import React from 'react';
import { useSelector } from 'react-redux';
import Breadcrumb from 'containers/Breadcrumb';
import { Layout, Button } from '@casanova/casanova-common';

const withLayout = (
  Component,
  downloadAction,
  aside,
  centerClass = '',
  withLoader = true
) => {
  function WrappedChildren(props) {
    const { dispatch, showTitle = true } = props;
    const loader = useSelector((state) => state.commons.loader);
    return (
      <>
        {showTitle && (
          <Layout.Title
            label={<Breadcrumb />}
            right={
              downloadAction && (
                <Button onClick={() => dispatch(downloadAction())} outline>
                  Descargar información
                </Button>
              )
            }
          />
        )}
        <Layout.Body
          showLoader={withLoader && loader}
          aside={aside}
          centerClass={centerClass}
        >
          <Component {...props} />
        </Layout.Body>
      </>
    );
  }

  return WrappedChildren;
};

export default withLayout;
