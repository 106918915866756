import { useCallback, useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { Modal, Icons } from '@casanova/casanova-common';
import { PreventChange } from 'components';
import { CustomerCreator } from '@customers/CustomerCreator/CustomerCreator';
import { FORM_ACTIONS } from 'utils/constants/formActions';

const { VIEW, ADD } = FORM_ACTIONS;

export default function AvalCreator(props) {
  const {
    show = false,
    setShow,
    loader,
    bottom,
    responseDialog,
    openConfirmationMessage,
    onUpdateForm,
    avalCustomer,
    updateAvalInfo,
    hide,
    setHide,
    preventChange,
    avalPreventChange,
    avalCustomerByDetail,
  } = props;

  const {
    isSubmitting,
    initialValues,
    setValues,
    setTouched,
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    const { active, cancel, success } = preventChange;
    if (!active && !cancel && !success) {
      setHide(false);
    } else if (cancel) {
      setHide(false);
    } else if (success) {
      setShow(false);
    } else if (active) {
      setHide(true);
    }
  }, [preventChange]);

  const handleCloseModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const handleModalOpen = useCallback(() => {
    setTimeout(() => setHide(true), 1);
  }, [setHide]);

  const handleModalClose = useCallback(() => {
    setHide(false);
  }, [setHide]);

  useEffect(() => {
    const newHide = !(
      !loader &&
      show &&
      !responseDialog.open &&
      !openConfirmationMessage
    );

    setHide(newHide);
  }, [loader, show, responseDialog.open, openConfirmationMessage]);

  useEffect(() => {
    if (
      avalCustomer.success &&
      updateAvalInfo &&
      !_isEmpty(avalCustomer.data)
    ) {
      onUpdateForm(avalCustomer);
    }
  }, [onUpdateForm, avalCustomer, updateAvalInfo]);

  useEffect(() => {
    if (!_isEmpty(avalCustomerByDetail)) {
      onUpdateForm({ data: avalCustomerByDetail });
    }
  }, [avalCustomerByDetail, onUpdateForm]);

  useEffect(() => {
    if (!show) {
      setValues(initialValues);
      setTouched({});
    }
    setFieldValue('state', '');
    setFieldValue('municipality', '');
  }, [show, initialValues, setValues, setTouched, setFieldValue]);

  return (
    <PreventChange
      preventChange={avalPreventChange}
      stockInCurrentPath
      onConfirmation={() => setShow(false)}
      icon={<Icons.UserAlert />}
    >
      <Modal
        open={show}
        header={null}
        footer={null}
        bigModal
        onClose={handleCloseModal}
        topContainerClassName={classNames(hide && 'd-none')}
      >
        <CustomerCreator
          {...props}
          onlyFisic
          getAllCatalogs={false}
          onModalOpen={handleModalOpen}
          onModalClose={handleModalClose}
          stockInCurrentPath
          action={updateAvalInfo ? VIEW : ADD}
          avalCustomer
          submitted={isSubmitting}
        />
        {bottom}
      </Modal>
    </PreventChange>
  );
}
