import { useCallback, useEffect, useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import {
  withForm,
  Button,
  ActionModal,
  Icons,
  RadioGroupInput,
  Section,
} from '@casanova/casanova-common';
import { SercheableSelect, PreventChange, ResponseDialog } from 'components';
import { VehicleAssignment, ReservationDates } from '@reservations/sections';
import i18n from '@i18n';
import { lettersAndNumbersWithSpaces } from 'utils/masks';
import { fieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import { scrollTop } from 'utils/screen';
import { QUOTE_CONTRACT_QUERY } from 'utils/navigation';
import { config } from './config';
import { quoteValueFormatter } from './formatter';
import { VALUES_LIST_NOT_LEASING } from '../commons/constants';
import { number2mxn } from 'utils/currency';

function NotPriceQuote({
  search,
  searchJuridicalCustomer,
  searchTenderCustomer,
  fetchCatalog,
  getTimeLimits,
  onUpdateForm,
  setContractQuote,
  setQuoteValues,
  quoteValues,
  notPriceQuote,
  getDetail,
  resetDetail,
  customerDetail,
  getVehicleTypes,
  setRentalPlanOptions,
}) {
  const [showExpirationBalance, setShowExpirationBalance] = useState(false);
  const [hasPreventChange, setHasPreventChange] = useState(true);
  const [
    isAlertCustomerWithDebtOpen,
    setIsAlertCustomerWithDebtOpen,
  ] = useState(false);
  const [showSections, setShowSections] = useState(true);

  const history = useHistory();
  const {
    setFieldTouched,
    setFieldValue,
    values,
    initialValues,
    isSubmitting,
    dirty,
    touched,
  } = useFormikContext();
  const { customer, personType } = values;
  const {
    listOfInvalidsDocuments = [],
    debts = [],
    identityDocuments = [],
  } = customerDetail;

  const shouldBlock = useMemo(() => {
    const currentValues = { ...values };
    const currentInitialValues = { ...initialValues };
    delete currentValues.returnDateError;
    delete currentValues.availableTime;
    delete currentInitialValues.returnDateError;
    delete currentInitialValues.availableTime;

    return (
      dirty &&
      !isSubmitting &&
      (!_isEqual(currentValues, currentInitialValues) || !_isEmpty(touched))
    );
  }, [values, initialValues, isSubmitting, dirty, touched]);

  const onOpenCloseAlertCustomerWithDebtOpen = () => {
    setIsAlertCustomerWithDebtOpen((prev) => !prev);
  };

  const withInvalidDocuments = listOfInvalidsDocuments.length > 0;
  const withoutDocuments =
    !(identityDocuments.length > 0) && personType !== 'JURIDICAL';
  const typePerson = useMemo(
    () => personType === VALUES_LIST_NOT_LEASING[1].value,
    [personType]
  );

  const handleSelectCustomer = useCallback(
    (value) => {
      setFieldTouched('customer', true);
      setFieldValue('customer', value);
    },
    [setFieldValue, setFieldTouched]
  );

  const handleSearchCustomer = useCallback(
    (query) => {
      if (query && query.length > 2) {
        setFieldValue('customer', '');
        if (personType === 'JURIDICAL') {
          searchJuridicalCustomer(query, 'uuid');
        } else if (personType === 'FOREGINER') {
          search({ text: query, isForeigner: true });
        } else {
          search({ text: query, isForeigner: false });
        }
      }
    },
    [
      setFieldValue,
      search,
      searchJuridicalCustomer,
      searchTenderCustomer,
      personType,
    ]
  );

  const handleBlurCustomer = useCallback(() => {
    setFieldTouched(fieldSearch('customer'), true);
    setFieldTouched('customer', true);
  }, [setFieldTouched]);

  const goToCustomer = (edit = false) => {
    if (!customer) return;

    const formattedValues = quoteValueFormatter(values);
    setQuoteValues(formattedValues);
    // delete currentQuoteValues.withInvalidDocuments;

    setHasPreventChange(false);

    const path = `/reservations/customer/${customer}/${
      edit ? 'edit' : 'detail'
    }?${QUOTE_CONTRACT_QUERY}`;

    setTimeout(() => {
      history.push(path);
    }, 1);
  };

  const handleViewDetails = () => goToCustomer();

  const handleAddCustomer = useCallback(() => {
    setHasPreventChange(false);

    setTimeout(() => {
      history.push(`/reservations/customer/add?${QUOTE_CONTRACT_QUERY}`);
    }, 1);
  }, [history]);

  const handlePersonsType = useCallback(
    (category) => {
      handleRemove();
      setFieldValue('personType', category.value);
    },
    [setFieldValue]
  );

  const handleUpdate = useCallback(() => {
    goToCustomer(true);
  }, [goToCustomer]);

  const handleRemove = useCallback(() => {
    setFieldValue(fieldSearch('customer'), '');
    setFieldValue('customer', '');
    resetDetail();
  }, [setFieldValue, resetDetail]);

  const handleLoadClose = useCallback(() => {
    setShowExpirationBalance(false);
  }, []);

  const handleConfirmation = useCallback(() => {
    // reset contract quote state
    setContractQuote(false);
  }, [setContractQuote]);

  useEffect(() => {
    if (customer) getDetail(customer);
  }, [customer, getDetail]);

  useEffect(() => {
    setFieldValue('withInvalidDocuments', withInvalidDocuments);
  }, [withInvalidDocuments, customer]);

  useEffect(() => {
    fetchCatalog({
      catalogPath: 'pricequotes',
      catalogId: 'catalogs/rental-plans',
    });
    getTimeLimits();
  }, [fetchCatalog, getTimeLimits]);

  useEffect(() => {
    setContractQuote(true);
  }, [setContractQuote]);

  useEffect(() => {
    scrollTop();
    getVehicleTypes();
  }, []);

  useEffect(
    () => () => {
      if (!shouldBlock && !notPriceQuote.success) {
        setContractQuote(true);
      }
    },
    [shouldBlock, setContractQuote, notPriceQuote]
  );

  useEffect(() => {
    if (!personType) {
      setFieldValue('personType', VALUES_LIST_NOT_LEASING[0].value);
    }
  }, [personType, VALUES_LIST_NOT_LEASING]);

  useEffect(() => {
    if (!_isEmpty(quoteValues)) onUpdateForm(quoteValues);
  }, []);

  useEffect(() => {
    if (debts.length) {
      onOpenCloseAlertCustomerWithDebtOpen();
    }
  }, [customerDetail]);

  useEffect(() => {
    setFieldValue('withoutDocuments', withoutDocuments);
  }, [withoutDocuments]);

  const radioRender = (
    <div className="col-12 switch-inverted">
      <RadioGroupInput
        labelClass="d-flex align-items-center"
        groupClass="col-12 d-flex justify-content-between"
        parentClass="vehicle-radio-input"
        label=""
        name="personType"
        options={VALUES_LIST_NOT_LEASING}
        standardError
        onChange={handlePersonsType}
      />
    </div>
  );

  return (
    <>
      <PreventChange
        preventChange={hasPreventChange && shouldBlock}
        onConfirmation={handleConfirmation}
      />

      <ResponseDialog
        open={isAlertCustomerWithDebtOpen}
        errorMessage={
          debts[0] && (
            <>
              <p>
                El cliente{' '}
                <span className="font-bold">{debts[0].fullName}</span> tiene un
                adeudo por{' '}
                <span className="font-bold">
                  {number2mxn((debts[0].debt || 0) * -1)}
                </span>
              </p>
              <p className="m-0">
                Contrato:{' '}
                <span className="font-bold">{debts[0].contractNumber}</span>
              </p>
              <p>
                Sucursal: <span className="font-bold">{debts[0].branch}</span>
              </p>
            </>
          )
        }
        errorTitle={i18n('ERROR__CUSTOMERS__CUSTOMER_WITH_DEBT__TITLE')}
        errorLabel={i18n('COMMONS__CLOSE__TEXT')}
        onError={onOpenCloseAlertCustomerWithDebtOpen}
        onClose={onOpenCloseAlertCustomerWithDebtOpen}
      />
      <ActionModal
        open={showExpirationBalance}
        icon={<Icons.UserAlert />}
        title="SALDO VENCIDO"
        message="El cliente tiene saldo vencido, no es candidato a renta ¿Estás seguro que quieres continuar?"
        actionLabel={i18n('COMMONS__LOAD__TEXT')}
        closeLabel={i18n('COMMONS__CLOSE__TEXT')}
        onClose={handleLoadClose}
      />

      <Section
        title={i18n('RESERVATIONS__NOT_PRICE_QUOTE__CLIENT_TITLE')}
        uppercase
        className="mt-5"
      >
        <div className="row">
          {radioRender}
          <div className="col-5">
            <SercheableSelect
              onChange={handleSelectCustomer}
              onBlur={handleBlurCustomer}
              onSearching={handleSearchCustomer}
              name="customer"
              label={i18n('RESERVATIONS__SEARCH_CLIENT')}
              placeholder={i18n('RESERVATIONS__SEARCH_CLIENT')}
              options={
                personType === 'JURIDICAL'
                  ? 'customers.juridicalCustomers'
                  : 'customers.basicInfo'
              }
              mask={lettersAndNumbersWithSpaces(100)}
              infoText={
                personType === 'FISIC'
                  ? i18n('RESERVATIONS__SEARCH_LEYENT__CLIENT_PHYSICAL')
                  : i18n('RESERVATIONS__SEARCH_LEYENT__CLIENT_LEGAL')
              }
              icon="svg-icon-search"
              remove
              onRemove={handleRemove}
              showRightIcon={!customer}
            />
          </div>
          <div className="col-7 d-flex justify-content-end align-items-start pt-1">
            {customer && (withInvalidDocuments || withoutDocuments) && (
              <Button
                variant="yellow"
                className="px-5 mt-4"
                onClick={handleUpdate}
              >
                Actualizar
              </Button>
            )}

            {customer && !withInvalidDocuments && !withoutDocuments && (
              <Button className="px-5 mt-4" onClick={handleViewDetails}>
                Ver detalle
              </Button>
            )}

            {!customer && (
              <Button className="px-5 mt-4" onClick={handleAddCustomer}>
                Nuevo
              </Button>
            )}
          </div>
        </div>

        <VehicleAssignment setRentalPlanOptions={setRentalPlanOptions} />

        <ReservationDates
          notPriceQuote
          onUpdateForm={onUpdateForm}
          className="mb-8"
        />
      </Section>
    </>
  );
}

export default withForm({ config })(NotPriceQuote);
