import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from 'hooks';
import { getLoanDetails } from 'store/loans/actions';
import LoanDetails from './LoanDetails';

export default withLayout(
  compose(
    connect(
      (state) => ({
        details: state.loans.loanDetails,
        responseDialog: state.loans.responseDialog,
      }),
      { getLoanDetails }
    )
  )(LoanDetails)
);
