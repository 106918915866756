import React from 'react';
import { Button, Icons } from '@casanova/casanova-common';
import Modal from '@casanova/casanova-common/lib/Modal';
import { getQuotePath } from 'utils/navigation';

function ConfirmQuote(props) {
  const { open, id, onClose, modalType, closeButton, data, email } = props;

  const header = (
    <div className="modal-header justify-content-center align-items-center">
      <div className="icon">
        <Icons.Email />
      </div>
      <h5 className="modal-title text-center" id={`${id}-label`}>
        COTIZACIÓN ENVIADA
      </h5>
    </div>
  );
  const footer = (
    <div className="modal-footer container">
      {(modalType === 'action' || !modalType) && (
        <div className="row">
          <div className="offset-3 col-md-6">
            <Button
              block
              onClick={() => (window.location.href = getQuotePath('one'))}
            >
              Aceptar
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      open={open}
      closeButton={closeButton}
      onClose={() => onClose(data)}
      header={header}
      footer={footer}
      className="action-modal"
      data={data}
    >
      <div className="modal-body text-center mb-4">
        La cotización se envío al correo
        <br />
        <span className="font-weight-bold">{email}</span>
      </div>
    </Modal>
  );
}

ConfirmQuote.defaultProps = {
  closeLabel: 'Cancelar',
};

export default ConfirmQuote;
