import RDContractsModuleIcon from '@casanova/casanova-common/lib/Icons/RdContractsModule';

import DailyRentContracts, { CloseContract } from '@dailyRentContracts';
import {
  ExtendDetails,
  ExtendSign,
  ExtendPayment,
} from '@dailyRentContracts/ExtendContract';
import NotPriceQuote from '@reservations/NotPriceQuote';

import {
  CONTRACTS_RD_MODULE,
  VIEW_CONTRACTS_RD,
} from 'utils/roles/permissions/contractsRDPermissions';
import { validateRolePermissions } from 'utils/roles';
import { withReconcileNav } from 'containers/Breadcrumb';
import { withRoleRestrictions } from 'utils/auth';
import Detail from '@reservations/Detail';
import Contract from '@reservations/Contract';
import Leasing, { LeasingWithSignatures } from '@reservations/Leasing';
import PromissoryNote, {
  PromissoryNoteWithSignatures,
} from '@reservations/PromissoryNote';
import {
  DeliveryStepOne,
  DeliveryStepTwo,
  DeliveryStepThree,
} from '@reservations/Delivery';
import { ReturnStepThree } from '@reservations/Refund';
import i18n from '@i18n';

const SIDEBAR_KEY = 'contractRD';

export const contractRD = {
  moduleName: CONTRACTS_RD_MODULE,
  route: '/daily-rent-contracts',
  label: 'Contratos renta diaria',
  icon: RDContractsModuleIcon,
  main: 'main',
  show: validateRolePermissions(VIEW_CONTRACTS_RD),
  children: {
    main: {
      route: '',
      label: '',
      component: withReconcileNav(
        SIDEBAR_KEY,
        'main'
      )(withRoleRestrictions(DailyRentContracts)),
    },
    notPriceQuoteReservation: {
      route: '/contract-quote',
      label: 'Contratar',
      format: () => `/daily-rent-contracts/contract-quote/`,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'notPriceQuoteReservation'
      )(withRoleRestrictions(NotPriceQuote)),
    },
    extendContract: {
      route: `/:uuid/extend-contract`,
      label: 'Extensión de contrato',
      format: (uuid) => `/daily-rent-contracts/${uuid}/extend-contract`,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'extendContract'
      )(withRoleRestrictions(ExtendDetails)),
    },
    extendContractSign: {
      route: `/:uuid/extend-sign`,
      label: 'Extensión de contrato',
      format: (uuid) => `/daily-rent-contracts/${uuid}/extend-sign`,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'extendContractSign'
      )(withRoleRestrictions(ExtendSign)),
    },
    extendContractPayment: {
      route: `/:uuid/extend-payment`,
      label: 'Extensión de contrato',
      format: (uuid) => `/daily-rent-contracts/${uuid}/extend-payment`,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'extendContractPayment'
      )(withRoleRestrictions(ExtendPayment)),
    },
    closedContract: {
      route: `/:id/closed-contract`,
      label: 'Detalle cierre de contrato',
      format: (id) => `/daily-rent-contracts/${id}/closed-contract`,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'closedContract'
      )(withRoleRestrictions(CloseContract)),
    },
    confirm: {
      route: `/confirm`,
      label: i18n('RESERVATIONS__DETAIL__BREADCRUMB'),
      format: () => `/daily-rent-contracts/confirm`,
      onlyRoute: true,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'confirm'
      )(withRoleRestrictions(Detail)),
    },
    contract: {
      route: `/contract`,
      label: i18n('RESERVATIONS__CONTRACT__BREADCRUMB'),
      format: () => '/daily-rent-contracts/contract',
      onlyRoute: true,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'contract'
      )(withRoleRestrictions(Contract)),
    },
    leasing: {
      route: `/leasing`,
      label: i18n('RESERVATIONS__CONTRACT__BREADCRUMB'),
      format: () => `/daily-rent-contracts/leasing`,
      onlyRoute: true,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'leasing'
      )(withRoleRestrictions(Leasing)),
    },
    promissoryNote: {
      route: `/promissory-note`,
      label: i18n('RESERVATIONS__CONTRACT__BREADCRUMB'),
      format: () => `/daily-rent-contracts/promissory-note`,
      onlyRoute: true,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'promissoryNote'
      )(withRoleRestrictions(PromissoryNote)),
    },
    leasingSignatures: {
      route: `/leasing/signatures`,
      label: i18n('RESERVATIONS__CONTRACT__BREADCRUMB'),
      format: () => `/daily-rent-contracts/leasing/signatures`,
      onlyRoute: true,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'leasingSignatures'
      )(withRoleRestrictions(LeasingWithSignatures)),
    },
    promissoryNoteSignatures: {
      route: `/promissory-note/signatures`,
      label: i18n('RESERVATIONS__CONTRACT__BREADCRUMB'),
      format: () => `/daily-rent-contracts/promissory-note/signatures`,
      onlyRoute: true,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'promissoryNoteSignatures'
      )(withRoleRestrictions(PromissoryNoteWithSignatures)),
    },
    deliveryDetails: {
      route: `/:id/delivery`,
      label: i18n('RESERVATIONS__DELIVERY__BREADCRUMB'),
      format: (id) => `/daily-rent-contracts/${id}/delivery`,
      onlyRoute: true,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'deliveryDetails'
      )(withRoleRestrictions(DeliveryStepOne)),
    },
    deliveryVehicleParts: {
      route: `/:id/delivery-vehicle-parts`,
      label: i18n('RESERVATIONS__DELIVERY__BREADCRUMB'),
      format: (id) => `/daily-rent-contracts/${id}/delivery-vehicle-parts`,
      onlyRoute: true,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'deliveryVehicleParts'
      )(withRoleRestrictions(DeliveryStepTwo)),
    },
    deliveryInformationAndFirm: {
      route: `/:id/delivery-information-and-firm`,
      label: i18n('RESERVATIONS__DELIVERY__BREADCRUMB'),
      format: (id) =>
        `/daily-rent-contracts/${id}/delivery-information-and-firm`,
      onlyRoute: true,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'deliveryInformationAndFirm'
      )(withRoleRestrictions(DeliveryStepThree)),
    },
    unitInventory: {
      route: `/:id/unit_inventory`,
      label: i18n('DAILY_RENT_CONTRACTS_TITLE_INVENTORY'),
      format: (id) => `/daily-rent-contracts/${id}/unit_inventory`,
      onlyRoute: true,
      component: withReconcileNav(
        SIDEBAR_KEY,
        'unitInventory'
      )(withRoleRestrictions(ReturnStepThree)),
    },
  },
};
