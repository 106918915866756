import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  MaskedInput as Input,
  FileInput,
  // ConnectedSelect,
  Section,
  DatePickerV2,
} from '@casanova/casanova-common';
import ConnectedSelect from 'components/ConnectedSelect';
import i18n from '@i18n';
import { alphaMask } from '@casanova/casanova-common/lib/utils/masks';
import { acceptFiles } from 'utils/files';
import { EMISSION_AND_VALIDITY_VALIDATIONS } from '@customers/common/validations';
import { getFieldsWithNationalitiesCatalog } from '@customers/common/utils';
import { isSafari } from 'utils/browser';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { initialValues as visaInitialValues } from './config';

const { EMISSION, VALIDITY } = EMISSION_AND_VALIDITY_VALIDATIONS;

const VisaInformation = ({ action }) => {
  const { values, initialValues, setFieldValue } = useFormikContext();

  const nationalCatalogFields = useMemo(
    () => getFieldsWithNationalitiesCatalog(values),
    [values]
  );

  const hasFieldWithValue = useMemo(() => {
    const filterValues = ([key]) => visaInitialValues[key] !== undefined;
    const someFieldWithValue = ([, value]) => Boolean(value);

    const currentVisaValues = Object.entries(values).filter(filterValues);
    return currentVisaValues.some(someFieldWithValue);
  }, [values]);

  useEffect(() => {
    if (
      !values.visaCountry &&
      nationalCatalogFields.length > 0 &&
      hasFieldWithValue
    ) {
      const [, value] = nationalCatalogFields[0];
      setFieldValue('visaCountry', value);
    }
  }, [nationalCatalogFields, values, setFieldValue, hasFieldWithValue]);

  const disabledInput = action === FORM_ACTIONS.VIEW;
  const showInputUrl = action !== FORM_ACTIONS.ADD;

  return (
    <Section title={i18n('CUSTOMERS__VISA__TITLE')}>
      <div className="row">
        <div className="col-md-4">
          <Input
            label={i18n('CUSTOMERS__VISA__NUMBER', [
              hasFieldWithValue ? '*' : '',
            ])}
            placeholder={i18n('CUSTOMERS__VISA__NUMBER', [
              hasFieldWithValue ? '*' : '',
            ])}
            name="visaNumber"
            disabled={disabledInput}
            mask={alphaMask(20)}
            maskPlaceholder=""
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={initialValues.visaYearIssue || values.visaYearIssue}
            label={i18n('CUSTOMERS__VISA__YEAR_ISSUE', [
              hasFieldWithValue ? '*' : '',
            ])}
            placeholder={i18n('CUSTOMERS__VISA__YEAR_ISSUE', [
              hasFieldWithValue ? '*' : '',
            ])}
            name="visaYearIssue"
            disabled={disabledInput}
            minimumDate={EMISSION.MIN_DATE}
            maximumDate={EMISSION.MAX_DATE}
            formatDateValue
            placement={isSafari() ? 'bottom' : undefined}
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={initialValues.visaValidity || values.visaValidity}
            label={i18n('CUSTOMERS__VISA__VALIDITY', [
              hasFieldWithValue ? '*' : '',
            ])}
            placeholder={i18n('CUSTOMERS__VISA__VALIDITY', [
              hasFieldWithValue ? '*' : '',
            ])}
            name="visaValidity"
            disabled={disabledInput}
            minimumDate={VALIDITY.MIN_DATE}
            maximumDate={VALIDITY.MAX_DATE}
            formatDateValue
            placement={isSafari() ? 'bottom' : undefined}
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            label={i18n('CUSTOMERS__VISA__COUNTRY', [
              hasFieldWithValue ? '*' : '',
            ])}
            name="visaCountry"
            options="commons.countries"
            disabled={disabledInput}
            availableFirstValue
          />
        </div>
        <div className="col-md-4">
          <FileInput
            url={showInputUrl ? values.visaCoverFrontUrl : undefined}
            displayText={values.visaCoverFront}
            type="file"
            label={i18n('CUSTOMERS__VISA__COVER_FRONT', [
              hasFieldWithValue ? '*' : '',
            ])}
            placeholder={i18n('CUSTOMERS__VISA__COVER_FRONT', [
              hasFieldWithValue ? '*' : '',
            ])}
            name="visaCoverFront"
            accept={acceptFiles}
            disabled={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <FileInput
            url={showInputUrl ? values.visaCoverBackUrl : undefined}
            displayText={values.visaCoverBack}
            type="file"
            label={i18n('CUSTOMERS__VISA__COVER_BACK', [
              hasFieldWithValue ? '*' : '',
            ])}
            placeholder={i18n('CUSTOMERS__VISA__COVER_BACK', [
              hasFieldWithValue ? '*' : '',
            ])}
            name="visaCoverBack"
            accept={acceptFiles}
            disabled={disabledInput}
          />
        </div>
      </div>
    </Section>
  );
};

VisaInformation.propTypes = {
  action: PropTypes.string,
};

VisaInformation.defaultProps = {
  action: FORM_ACTIONS.ADD,
};

export default VisaInformation;
