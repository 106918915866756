import { ISO_DATE_FORMAT } from 'utils/constants';
import { realDate } from 'utils/date';

export const formatter = (values, contractQuote = false) => {
  const {
    customer,
    branchStart,
    branchEnd,
    startHour,
    endHour,
    vehicleCategory,
    // eslint-disable-next-line camelcase
    customer_search,
    dateEnd,
    dateStart,
    lineSelected,
    planType,
    vehicleQuantity,
    reservation,
    vehicleLinePrice,
  } = values;

  const startDate = {
    pickupBranch: branchStart,
    pickupDateTime: realDate(dateStart, startHour).format(ISO_DATE_FORMAT),
  };

  // This validation is for typeof bool
  const hasContractQuote = contractQuote === true;

  if (hasContractQuote) {
    const formattedContractQuote = {
      ...startDate,
      returnDateTime: values.returnDateTime,
      rentalPlanUuid: planType,
    };

    delete formattedContractQuote.pickupBranch;

    return formattedContractQuote;
  }

  return {
    customer,
    customer_search,
    vehicleCategory,
    returnBranch: branchEnd,
    returnDateTime: realDate(dateEnd, endHour).format(ISO_DATE_FORMAT),
    lineSelected: lineSelected || '',
    vehicleQuantity,
    reservation,
    vehicleLinePrice,
    ...startDate,
  };
};
