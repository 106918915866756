import { gotToContract } from 'store/reservations/actions';
import i18n from '@i18n';
import { isExtendOfContractFlow } from '@reservations/commons/utils';

const config = {
  initialTouched: {},
  initialValues: {},
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  submitForm: gotToContract,
  withButtons: true,
  btnSubmitLabel: isExtendOfContractFlow()
    ? 'Extender'
    : i18n('RESERVATIONS__CONFIRM__CONTINUE_BTN'),
  dialogs: {
    confirmation: {},
    response: {},
  },
};

export default config;
