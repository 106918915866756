import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withForm } from '@casanova/casanova-common';

import { ExtraChargesDetails } from '@reservations/sections';
import { DeliveryRefundDetails } from '@reservations/sections/DeliveryRefluxSteps';
import { getExtraChargesTransformer } from '@reservations/commons/transformers';
import { getTotalPriceExtraCharges } from '@reservations/commons/utils';

import { config } from './config';

const ExtraCharges = (props) => {
  const { verificationCheckList = {}, setExtraCharges, extraCharges } = props;
  const { listOfExtraCharges = [] } = extraCharges;

  useEffect(() => {
    if (listOfExtraCharges.length <= 0) {
      const { verificationGroups = [] } = verificationCheckList;
      const newListOfExtraCharges = getExtraChargesTransformer(
        verificationGroups
      );
      const total = getTotalPriceExtraCharges({
        listOfExtraCharges: newListOfExtraCharges,
      });

      setExtraCharges({
        listOfExtraCharges: newListOfExtraCharges,
        totalPrice: total,
      });
    }
  }, [verificationCheckList.verificationGroups]);

  return (
    <>
      <DeliveryRefundDetails
        {...props}
        showClientInfo={false}
        showPlanAndDates={false}
        showInvoice={false}
        vehicleInfoProps={{
          showVerificationGroups: false,
          showFolio: false,
          showDetailCustomer: true,
        }}
      />
      <ExtraChargesDetails
        listOfExtraCharges={listOfExtraCharges}
        showInformation={false}
        showSubtotal={false}
        fetchReservationInfo
      />
    </>
  );
};

ExtraCharges.propTypes = {
  verificationCheckList: PropTypes.shape({
    verificationGroups: PropTypes.array,
  }),
  setExtraCharges: PropTypes.func,
  extraCharges: PropTypes.shape({
    listOfExtraCharges: PropTypes.array,
    totalPrice: PropTypes.number,
  }),
  reservationInfo: PropTypes.shape({
    priceQuote: PropTypes.shape({
      selectedVehicleLinePrice: PropTypes.shape({
        amount: PropTypes.number,
      }),
    }),
    naturalPerson: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }),
};

export default withForm({ config })(ExtraCharges);
