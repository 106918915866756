import RD5 from '@images/rd5.png';
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Table,
  TableHeader,
  TableCell,
  TableRow,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
  },
  section: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  image_width: {
    width: '100%',
  },
  year: {
    position: 'absolute',
    top: 117,
    right: 37,
    fontSize: 9,
  },
  month: {
    position: 'absolute',
    top: 117,
    right: 120,
    fontSize: 9,
  },
  day: {
    position: 'absolute',
    top: 117,
    right: 180,
    fontSize: 9,
  },
  text: {
    position: 'absolute',
    top: 100, // Ajusta la posición vertical del texto
    left: 100, // Ajusta la posición horizontal del texto
  },
});

const DocumentRd5 = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image style={styles.image_width} src={RD5} />
      </View>
      <Text style={styles.year}>23</Text>
      <Text style={styles.month}>Enero</Text>
      <Text style={styles.day}>12</Text>
      {/* <Text style={styles.text}>Tu texto aquí</Text> */}
    </Page>
  </Document>
);

export default DocumentRd5;
