import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import {
  getTransfers,
  closeTransfer,
  cancelTransfer,
  closeResponseDialog,
  setTablePage,
  authorizeTransfer,
  rejectTransfer,
} from 'store/transfers/actions';
import { fetchCatalog } from 'store/commons/actions';
import TransferList from './TransferList';

export default compose(
  connect(
    (state) => ({
      responseDialog: _get(state, 'transfers.responseDialog', {}),
      transfers: _get(state, 'transfers.list', {}),
      confirmationMessage: false,
    }),
    {
      getTransfers,
      fetchCatalog,
      closeTransfer,
      cancelTransfer,
      closeResponseDialog,
      setTablePage,
      authorizeTransfer,
      rejectTransfer,
    }
  )
)(TransferList);
