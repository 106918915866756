import _isArray from 'lodash/isArray';
import { FILES_EXTENSIONS } from './constants/filesExtensions';

function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function Base64ToFile(base64URL = '') {
  const block = base64URL.split(';');
  const contentType = block[0].split(':')[1];
  const realData = block[1].split(',')[1];

  const blob = b64toBlob(realData, contentType);
  return blob;
}

const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export function URLToBase64(url) {
  return fetch(url)
    .then((res) => res.blob())
    .then(convertBlobToBase64);
}

export const generateBase64FromData = (fileType, data) =>
  `data:${fileType};base64,${data}`;

export const generateUrlFromBase64Data = (fileType, base64Data) => {
  const base64 = generateBase64FromData(fileType, base64Data);
  const file = Base64ToFile(base64);
  const url = URL.createObjectURL(file);
  return url;
};

export const inputFiles = (params = {}) => {
  const { fileInput = true } = params;
  const files = Object.values(FILES_EXTENSIONS)
    .map(({ name, type }) => ` ${type}/${name}`)
    .toString();
  return fileInput ? files : '';
};

export const acceptFiles = inputFiles();

export const isFile = (file) => file instanceof File;

export const getOnlyFilesOfFormatterFiles = (formatterFiles, strict = true) => {
  const reduceOnlyFiles = (acc, [key, current]) => {
    if (current && _isArray(current)) {
      const filterFiles = current.filter(({ file }) => isFile(file));

      if (filterFiles.length > 0 && strict) {
        acc[key] = filterFiles;
      }
    }

    return acc;
  };

  const formatterFilesWithOnlyFiles = Object.entries(formatterFiles).reduce(
    reduceOnlyFiles,
    {}
  );

  return formatterFilesWithOnlyFiles;
};
