import { number2mxn } from 'utils/currency';
import { MOVEMENTS_TYPES } from '@customers/common/constants';

export const contractMovementsTransformer = (data) =>
  data.map((contract) => {
    const { movementType, amount } = contract;
    const charges =
      movementType.identifier === MOVEMENTS_TYPES.CHARGE
        ? number2mxn(amount)
        : number2mxn(0);
    const payments =
      movementType.identifier === MOVEMENTS_TYPES.PAYMENT ||
      movementType.identifier === MOVEMENTS_TYPES.BONUS
        ? number2mxn(amount)
        : number2mxn(0);

    return {
      ...contract,
      charges,
      payments,
    };
  });
