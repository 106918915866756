import React from 'react';
import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { getQuotePath } from 'utils/navigation';
import { withLayout } from 'hooks';
import {
  DeliveryReturnStepThree,
  LeftDeliveryReturnStepThree as Left,
} from '@reservations/sections/DeliveryRefluxSteps';
import { config } from './config';

export default withLayout(
  compose(
    connect(
      (state) => ({
        sendQuoteDialog: _get(state, 'reservations.sendQuoteDialog', false),
        loader: _get(state, 'commons.loader', false),
        onCancelPath: `${getQuotePath('one')}?isCanceling=true`,
        left: <Left />,
        responseDialog: _get(state, 'reservations.responseDialog', {}),
        delivery: _get(state, 'reservations.delivery', false),
        leftCol: 4,
        middleCol: 4,
        confirmationMessage: false,
        config: config(),
      }),
      {}
    )
  )(DeliveryReturnStepThree)
);
