import { select, call, put, take } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';
import jwtDecode from 'jwt-decode';
import { authService } from 'services';
import { getAllowedPaths } from 'services/auth';
import { reset } from 'store/vehicles/actions';
import { showLoader, hideLoader } from 'store/commons/actions';
import { fetchCommonCatalogSaga } from 'store/commons/sagas';
import {
  names,
  logout,
  hydrateUserDataSuccess,
  hydrateUserDataFailed,
} from './actions';

export function* startAuthSaga() {
  yield take((action) => action.type === REHYDRATE && action.key === 'auth');
  const { authToken } = yield select((state) => state.auth);
  if (authToken) {
    const { exp } = jwtDecode(authToken);
    if (exp * 1000 < Date.now()) {
      yield put(logout());
    }
  }
}

export function* loginSaga({ payload }) {
  try {
    const result = yield call(authService.login, payload);
    yield put({
      type: names.login[1],
      payload: result,
    });
    yield put(reset());
    window.location.href = './vehicle';
  } catch (error) {
    console.error('Error when loging', error);
    yield put({
      type: names.login[2],
      payload: 'Tu usuario y/o contraseña son inválidos.',
    });
  }
}

export function* hydrateUserDataSaga() {
  try {
    yield put(showLoader());

    const hasError = yield fetchCommonCatalogSaga({ payload: 'branches' });
    if (hasError) throw new Error('Error when fetching catalogs');

    yield put(hydrateUserDataSuccess());
  } catch (error) {
    yield put(hydrateUserDataFailed(error));
  } finally {
    yield put(hideLoader());
  }
}
