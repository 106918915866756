import { acceptSignatures } from 'store/reservations/actions';

const schema = {};

export const config = ({ folio = '' }) => ({
  schema,
  textBold: folio,
  messageBold: true,
  modalType: 'empty',
  initialTouched: {},
  initialValues: {},
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  showCancel: false,
  confirmationMessage: false,
  withButtons: false,
  submitForm: (values) => acceptSignatures(values.formUuid),
  btnSubmitLabel: 'Devolución',
  dialogs: {
    confirmation: {
      title: 'VERIFICAR DEVOLUCIÓN',
      message: `Debes verificar la devolución del vehículo`,
      actionLabel: 'Continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡VEHÍCULO DEVUELTO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage: `Finalizó la renta del vehículo.
      ¡Gracias por confiar en nosotros!
      `,
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a reservaciones',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: '/daily-rent-contracts/',
    },
  },
});
