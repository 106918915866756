import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { closeResponseDialog } from 'store/payments/actions';
import { searchEmployees } from 'store/customers/actions';
import { fetchCatalog } from 'store/commons/actions';
import {
  setNewInvoicePayment,
  setHasAddedBillingDetailsOfPayment,
} from 'store/reservations/actions';
import PaymentDetail from './PaymentDetail';

function mapDispatchToProps(dispatch) {
  const getDispatch = (action) => (...args) => dispatch(action(...args));

  return {
    setNewInvoicePayment: getDispatch(setNewInvoicePayment),
    closeResponseDialog: getDispatch(closeResponseDialog),
    onCancel: () => {
      console.log("Close");
      dispatch(setNewInvoicePayment(false))
    },
    setHasAddedBillingDetailsOfPayment: getDispatch(
      setHasAddedBillingDetailsOfPayment
    ),
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const newDispatchProps = { ...dispatchProps };

  if (!stateProps.newInvoicePayment) {
    delete newDispatchProps.onCancel;
  }

  return {
    ...stateProps,
    ...newDispatchProps,
    ...ownProps,
  };
}

export default compose(
  connect(
    (state) => ({
      loader: state.commons.loader,
      responseDialog: state.payments.responseDialog,
      movementTypes: state.payments.movementTypes.list,
      contractDetails: state.payments.contractDetails.data,
      customer: _get(state, 'customers.customerDetail', {}),
      responseDialogValidCode: _get(
        state,
        'payments.responseDialogValidCode',
        {}
      ),
    }),
    {
      closeResponseDialog,
      searchEmployees,
      fetchCatalog,
      mapDispatchToProps,
      mergeProps,
    }
  )
)(PaymentDetail);
