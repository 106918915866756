export const initialTouched = {
  companyName: true,
  companyRfc: true,
  companySocialReason: true,
  companyPhone: true,
  companyPhoneExt: true,
  companyPhoneTwo: true,
  companyPhoneTwoExt: true,
  companyConstituentAct: true,
  companyCompyRec: true,
  companyCouponFiscal: true,
  companyOpinionCompliance: true,
  companyRequestVehicles: true,
  companyPowerAttornery: true,
  companyLetterAuthorization: true,
};

const initialValues = {
  companyName: '',
  companyRfc: '',
  companySocialReason: '',
  companyPhone: '',
  companyPhoneExt: '',
  companyPhoneTwo: '',
  companyPhoneTwoExt: '',
  companyConstituentAct: '',
  companyCompyRec: '',
  companyCouponFiscal: '',
  companyOpinionCompliance: '',
  companyRequestVehicles: '',
  companyPowerAttornery: '',
  companyLetterAuthorization: '',
};

export default {
  initialValues,
  initialTouched,
};

export const FIELDS = Object.keys(initialValues);
