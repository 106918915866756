import {
  VIEW_VEHICLE_CIRCULATION_CARD,
  CREATE_VEHICLE_CIRCULATION_CARD,
} from 'utils/roles/permissions';
import CirculationCard from './CirculationCard';
import { withConnectedAdditional } from '../AdditionalCollapse';

export default withConnectedAdditional({
  dependsOnElement: 'plateOptions',
  identifierForView: VIEW_VEHICLE_CIRCULATION_CARD,
  identifierForAdd: CREATE_VEHICLE_CIRCULATION_CARD,
})(CirculationCard);
