import { takeLatest } from 'redux-saga/effects';
import types from './action-types';

import {
  getTransfersListSaga,
  saveTransferSaga,
  getTransferDetailSaga,
  authorizeTransferSaga,
  cancelTransferSaga,
  closeTransferSaga,
  rejectTransferSaga,
  editTransferSaga,
} from './sagas';

export default function* watchers() {
  yield takeLatest(types.GET_LIST, getTransfersListSaga);
  yield takeLatest(types.SAVE_TRANSFER, saveTransferSaga);
  yield takeLatest(types.GET_TRANSFER_DETAIL, getTransferDetailSaga);
  yield takeLatest(types.CANCEL_TRANSFER, cancelTransferSaga);
  yield takeLatest(types.CLOSE_TRANSFER, closeTransferSaga);
  yield takeLatest(types.AUTHORIZE_TRANSFER, authorizeTransferSaga);
  yield takeLatest(types.REJECT_TRANSFER, rejectTransferSaga);
  yield takeLatest(types.EDIT_TRANSFER, editTransferSaga);
}
