import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  fetchVehicleStatus,
  setPageVehicleStatus,
} from 'store/vehicles/actions';
import VehicleStatus from './VehicleStatus';

export default compose(
  connect(
    (state) => ({
      responseDialog: state.vehicles.responseDialog,
      showTitle: false,
      vehicleStatus: state.vehicles.vehicleStatus,
    }),
    { fetchVehicleStatus, setPageVehicleStatus }
  )
)(VehicleStatus);
