import { takeLatest, takeEvery } from 'redux-saga/effects';
import types from './action-types';
import {
  getVehicleTypesSaga,
  getAvailableTimeSaga,
  getVehiclesCatalogSaga,
  saveStepOneSaga,
  editStepOneSaga,
  saveNotPriceQuoteSaga,
  saveStepTwoSaga,
  getDiscountsSaga,
  saveDiscountSaga,
  validatePromocodeSaga,
  saveStepThreeSaga,
  editPlanSaga,
  getMostQuotedSaga,
  createReservationSaga,
  getListOfReservationsSaga,
  getReservationInfoSaga,
  getVehicleLinePricesSaga,
  verifyCheckPointSaga,
  sendFormLinkFirmSaga,
  savePaymentSaga,
  setVehicleReservationSaga,
  changeVehicleReservationSaga,
  getCatalogueOfVehiclesSaga,
  createReservationContractSaga,
  getContractsPdfSaga,
  verifyCompletedCheckListSaga,
  sendFormLinkSaga,
  goToContractSaga,
  verifySignaturesCompletedSagas,
  acceptSignaturesSagas,
  rejectSignaturesSagas,
  getReservationFormSagas,
  getReservationListFiltersSagas,
  assignCustomerSagas,
  hydrateChangeOfVehicleFlowSaga,
  sendReservation,
  hydrateExtendContractFlowSaga,
  createExtendContractSaga,
  getExtendedContractsSaga,
  finishExtendContractSaga,
  cancelReservaionSaga,
  getCustomerCardsSaga,
  updateReservationSaga,
  getDetailOfCustomerInUpdateModalSaga,
  updateCardSaga,
} from './sagas';

export default function* watchers() {
  yield takeLatest(types.GET_VEHICLE_TYPES, getVehicleTypesSaga);
  yield takeLatest(types.GET_TIME_LIMITS, getAvailableTimeSaga);
  yield takeEvery(types.GET_VEHICLES, getVehiclesCatalogSaga);
  yield takeLatest(types.SAVE_QUOTE_STEP_ONE, saveStepOneSaga);
  yield takeLatest(types.SAVE_QUOTE_STEP_TWO, saveStepTwoSaga);
  yield takeLatest(types.EDIT_QUOTE_STEP_ONE, editStepOneSaga);
  yield takeLatest(types.SAVE_NOT_PRICE_QUOTE, saveNotPriceQuoteSaga);
  yield takeLatest(types.GET_DISCOUNTS, getDiscountsSaga);
  yield takeLatest(types.SAVE_DISCOUNT, saveDiscountSaga);
  yield takeLatest(types.VALIDATE_PROMOCODE, validatePromocodeSaga);
  yield takeLatest(types.SAVE_QUOTE_STEP_THREE, saveStepThreeSaga);
  yield takeLatest(types.EDIT_PLAN, editPlanSaga);
  yield takeLatest(types.GET_MOST_QUOTED, getMostQuotedSaga);
  yield takeLatest(types.CREATE_RESERVATION, createReservationSaga);
  yield takeLatest(
    types.GET_LIST_OF_RESERVATIONS_REQUESTED,
    getListOfReservationsSaga
  );
  yield takeLatest(types.GET_VEHICLE_LINE_PRICES, getVehicleLinePricesSaga);
  yield takeLatest(types.GET_RESERVATION_INFO, getReservationInfoSaga);
  yield takeLatest(types.VERIFY_CHECK_POINT, verifyCheckPointSaga);
  yield takeLatest(types.SAVE_PAYMENT, savePaymentSaga);
  yield takeLatest(types.SET_VEHICLE_OF_RESERVATION, setVehicleReservationSaga);
  yield takeLatest(
    types.CHANGE_VEHICLE_OF_RESERVATION,
    changeVehicleReservationSaga
  );
  yield takeLatest(types.GET_CATALOGUE_OF_VEHICLES, getCatalogueOfVehiclesSaga);
  yield takeLatest(
    types.CREATE_RESERVATION_CONTRACT,
    createReservationContractSaga
  );
  yield takeLatest(types.GET_CONTRACTS_PDF, getContractsPdfSaga);
  yield takeLatest(types.SEND_FORM_REQ, sendFormLinkSaga);
  yield takeLatest(types.SEND_RESERVATION_REQ, sendReservation);
  yield takeLatest(types.GO_TO_CONTRACT, goToContractSaga);
  yield takeLatest(
    types.VERIFY_COMPLETED_CHECK_LIST,
    verifyCompletedCheckListSaga
  );
  yield takeLatest(types.SEND_FORM_LINK_FIRM, sendFormLinkFirmSaga);
  yield takeLatest(types.VERIFY_FORM_COMPLETED, verifySignaturesCompletedSagas);
  yield takeLatest(types.ACCEPT_SIGNATURES, acceptSignaturesSagas);
  yield takeLatest(types.REJECT_SIGNATURES, rejectSignaturesSagas);
  yield takeLatest(types.GET_RESERVATION_FORM, getReservationFormSagas);
  yield takeLatest(
    types.GET_RESERVATION_LIST_FILTERS,
    getReservationListFiltersSagas
  );
  yield takeLatest(types.ASSIGN_CUSTOMER, assignCustomerSagas);
  yield takeLatest(
    types.HYDRATE_CHANGE_OF_VEHICLE_FLOW,
    hydrateChangeOfVehicleFlowSaga
  );
  yield takeLatest(
    types.HYDRATE_EXTEND_CONTRACT_FLOW,
    hydrateExtendContractFlowSaga
  );
  yield takeLatest(types.CREATE_EXTEND_CONTRACT, createExtendContractSaga);
  yield takeLatest(types.GET_EXTENDED_CONTRACTS, getExtendedContractsSaga);
  yield takeLatest(types.FINISH_EXTENDED_CONTRACT, finishExtendContractSaga);
  yield takeLatest(types.CANCEL_RESERVATION, cancelReservaionSaga);
  yield takeLatest(types.GET_CUSTOMER_CARDS, getCustomerCardsSaga);
  yield takeLatest(types.UPDATE_RESERVATION, updateReservationSaga);
  yield takeLatest(
    types.GET_DETAIL_OF_CUSTOMER_IN_UPDATE_MODAL,
    getDetailOfCustomerInUpdateModalSaga
  );
  yield takeLatest(types.UPDATE_CARD, updateCardSaga);
}
