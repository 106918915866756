import { formatter as reservationDatesFormatter } from '@reservations/sections/ReservationDates/config';
import { CUSTOMER_TYPES } from '@customers/common/constants';
import { ISO_DATE_FORMAT } from 'utils/constants';
import { realDate } from 'utils/date';
import moment from 'moment';

export const formatter = ({ vehicles, customer, ...values }) => {
  const isJuridicalPerson = values.personType !== CUSTOMER_TYPES.FISIC;
  const [vehicle] = vehicles;
  const { startHour, dateStart, dateEnd, planType } = values;

  const formattedContractQuote = {
    pickupDateTime: moment(`${dateStart} ${startHour}`).format(ISO_DATE_FORMAT),
    returnDateTime: moment(`${dateEnd} ${startHour}`).format(ISO_DATE_FORMAT),
    rentalPlanUuid: planType,
  };

  const reservationDates = formattedContractQuote;
  const formatted = {
    customerUuid: customer,
    vehicleUuid: vehicle.uuid,
    isJuridical: isJuridicalPerson,
    ...reservationDates,
  };

  return formatted;
};

export const quoteValueFormatter = (values) => ({
  notPriceQuoteValues: values,
});
