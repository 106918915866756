export const transferInitialTouched = {
  bankTo: true,
  bankClave: true,
  bankReference: true,
  transferVoucher: true,
  amountTransfer: true,
};

export const transferInitialValues = {
  bankTo: '',
  bankClave: '',
  bankReference: '',
  transferVoucher: '',
  amountTransfer: '',
};
