import { saveTransfer } from 'store/transfers/actions';
import { transferInformationFormatter } from '@transfers/sections/TransfersInformation/formatter';
import { transfersInformationSchema } from '@transfers/sections/TransfersInformation/schema';

const initialValues = {
  requester: '',
  plate: '',
  requester_search: '',
  plate_search: '',
  plate_lastSearch: '',
  source: '',
  destination: '',
  departureRealDate: '',
  departureRealTime: '',
  departureDate: '',
  departureTime: '',
  returnRealDate: '',
  arrivalRealTime: '',
  returnDate: '',
  arrivalTime: '',
  driver: '',
  observations: '',
  licenseNumber: '',
  validityDriverLicense: '',
  emissionState: '',
};

export const initialTouched = {
  requester_search: true,
  plate_search: true,
  source: true,
  destination: true,
  departureRealDate: true,
  departureRealTime: true,
  departureDate: true,
  departureTime: false,
  returnRealDate: true,
  arrivalRealTime: true,
  returnDate: true,
  arrivalTime: true,
  driver: true,
  observations: true,
  licenseNumber: true,
  validityDriverLicense: true,
  emissionState: true,
};

export const config = {
  schema: transfersInformationSchema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter: transferInformationFormatter,
  submitForm: saveTransfer,
  btnSubmitLabel: 'Guardar',
  dialogs: {
    confirmation: {
      title: 'SOLICITAR TRASLADO',
      message:
        'Se solicitará un traslado del vehículo ¿Estas seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage: 'Se realizó la solicitud de traslado de manera exitosa',
      errorMessage:
        'Hubo un error mientras procesábamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a traslados',
      errorLabel: 'Aceptar',
      cancelLabel: 'Cancelar',
      successPath: '/transfers',
    },
  },
};

export default config;
