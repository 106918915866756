import QuoterModuleIcon from '@casanova/casanova-common/lib/Icons/QuoterModule';
import { QUOTER_MODULE } from 'utils/roles/permissions/reservationsPermissions';
import { withRoleRestrictions } from 'utils/auth';
import { withReconcileNav } from 'containers/Breadcrumb';
import { QuoteOne, QuoteTwo, QuoteThree } from '@reservations/Quote';
import CustomerCreatorBase from '@customers/CustomerCreatorBase';
import i18n from '@i18n';

const MODULE_KEY = 'quoter';

export const quoter = {
  moduleName: QUOTER_MODULE,
  route: '/quoter',
  label: i18n('RESERVATIONS__QUOTE'),
  icon: QuoterModuleIcon,
  main: 'main',
  children: {
    main: {
      route: '',
      label: '',
      component: withReconcileNav(
        MODULE_KEY,
        'main'
      )(withRoleRestrictions(QuoteOne)),
    },
    plan: {
      route: '/plan',
      label: i18n('RESERVATIONS__QUOTE'),
      format: () => `/quoter/plan`,
      onlyRoute: true,
      component: withReconcileNav(
        MODULE_KEY,
        'plan'
      )(withRoleRestrictions(QuoteTwo)),
    },
    summary: {
      route: '/summary',
      label: i18n('RESERVATIONS__QUOTE'),
      format: () => `/quoter/summary`,
      onlyRoute: true,
      component: withReconcileNav(
        MODULE_KEY,
        'summary'
      )(withRoleRestrictions(QuoteThree)),
    },
    addCustomer: {
      route: `/customer/add`,
      label: 'Cliente',
      format: () => `/quoter/customer/add`,
      onlyRoute: true,
      component: withReconcileNav(
        MODULE_KEY,
        'addCustomer'
      )(withRoleRestrictions(CustomerCreatorBase)),
    },
  },
};
