import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { fetchCatalog } from 'store/commons/actions';
import { withLayout } from 'hooks';
import Summary from '../sections/Summary';
import Quote from './Quote';

export const QuoteOne = withLayout(
  compose(
    connect(
      (state) => ({
        step: 1,
        quote: _get(state, 'reservations.quote', {}),
        loader: _get(state, 'commons.loader', false),
      }),
      {
        fetchCatalog,
      }
    )
  )(Quote)
);

export const QuoteTwo = withLayout(
  compose(
    connect(
      (state) => ({
        quote: _get(state, 'reservations.quote', {}),
        step: 2,
        loader: _get(state, 'commons.loader', false),
      }),
      {
        fetchCatalog,
      }
    )
  )(Quote)
);

export const QuoteThree = withLayout(
  compose(
    connect(
      (state) => ({
        step: 3,
        quote: _get(state, 'reservations.quote', {}),
        loader: _get(state, 'commons.loader', false),
      }),
      {
        fetchCatalog,
      }
    )
  )(Quote),
  undefined,
  <Summary />
);
