import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';

import { getDetail } from 'store/customers/actions';

import Invoice from './Invoice';

export default compose(
  connect(
    (state) => ({
      catalogsBankCardTypes: _get(
        state,
        'commons.catalogsBankCardTypes.list',
        []
      ),
      customerDetail: _get(state, 'customers.customerDetail.data', {}),
    }),
    { getDetail }
  )
)(Invoice);
