import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styles from './styles.module.css';
import { createObjectStatus } from '../helpers';

const DetailsLoan = ({ data }) => {
  const keyStatus = useSelector((state) => state.catalogs.keyStatus);
  const status = createObjectStatus(keyStatus);
  return (
    <>
      <div className="row">
        <div className="col-3">
          <h6 className={`${styles.title} text-uppercase`}>
            Fecha de préstamo
          </h6>
          <p className={styles.body}>
            {moment(data.lendDate).format('DD MMM YYYY').replace('.', '')}
          </p>
        </div>
        <div className="col-3">
          <h6 className={`${styles.title} text-uppercase`}>Responsable</h6>
          <p className={styles.body}>{data.lendResponsible}</p>
        </div>
        <div className="col-3">
          <h6 className={`${styles.title} text-uppercase`}>Sucursal</h6>
          <p className={styles.body}>
            {data && data.lendOtherBranch
              ? data.lendOtherBranch
              : data && data.lendBranch && data.lendBranch.name}
          </p>
        </div>
        <div className="col-12">
          {data.lendReason && (
            <>
              <h6 className={`${styles.title} text-uppercase`}>
                OBSERVACIONES
              </h6>
              <p className={styles.body}>{data.lendReason}</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DetailsLoan;
