import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  fetchVehicleContracts,
  setPageVehicleContracts,
} from 'store/vehicles/actions';
import VehicleContracts from './VehicleContracts';

export default compose(
  connect(
    (state) => ({
      responseDialog: state.vehicles.responseDialog,
      showTitle: false,
      vehicleContracts: state.vehicles.vehicleContracts,
    }),
    { fetchVehicleContracts, setPageVehicleContracts }
  )
)(VehicleContracts);
