"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStreamTrack = exports.ZOOM = exports.VIDEO_CONSTRAINTS_DEFAULT = exports.SCREENSHOT_FORMAT_DEFAULT = exports.ICON_WIDTH = exports.ERRORS = void 0;
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var VIDEO_CONSTRAINTS_DEFAULT = exports.VIDEO_CONSTRAINTS_DEFAULT = {
  width: 1280,
  height: 720,
  facingMode: 'environment',
  zoom: true
};
var SCREENSHOT_FORMAT_DEFAULT = exports.SCREENSHOT_FORMAT_DEFAULT = 'image/jpeg';
var ZOOM = exports.ZOOM = 'zoom';
var getStreamTrack = exports.getStreamTrack = function getStreamTrack(stream) {
  var _stream$getVideoTrack, _stream$getVideoTrack2;
  // eslint-disable-next-line no-multi-assign
  var _ref = (_stream$getVideoTrack = stream.getVideoTracks(), _stream$getVideoTrack2 = _slicedToArray(_stream$getVideoTrack, 1), window.track = _stream$getVideoTrack2[0], _stream$getVideoTrack),
    _ref2 = _slicedToArray(_ref, 1),
    track = _ref2[0];
  return track;
};
var ICON_WIDTH = exports.ICON_WIDTH = 80;
var ERRORS = exports.ERRORS = {
  PERMISSIONS_DENIED: 'Permission denied'
};