import {
  REPORTS_MODULE,
  VIEW_REPORTS,
  DOWNLOAD_REPORTS,
} from 'utils/roles/permissions/reportsPermissions';
import { REPORTS_LIST_PATH } from 'utils/roles/paths';

export function reportsAuth(permissions) {
  const allowedModules = [];

  if (permissions.includes(VIEW_REPORTS)) {
    allowedModules.push(REPORTS_MODULE);
  }

  return allowedModules;
}

export function reportsPathsAuth(permissions) {
  const allowedPaths = [];

  if (permissions.includes(DOWNLOAD_REPORTS)) {
    allowedPaths.push(REPORTS_LIST_PATH);
  }

  return allowedPaths;
}
