import { useEffect } from 'react';
import { withFormik } from 'formik';
import _noop from 'lodash/noop';

import { MaskedInput as Input, NumberInput } from '@casanova/casanova-common';
import { lettersAndNumbersAndAccentsWithSpace } from '@casanova/casanova-common/lib/utils/masks';
import i18n from '@i18n';

import {
  handleSubmit as handleFormikSubmit,
  mapPropsToValues,
  mapPropsToTouched,
  validationSchema,
} from './config';
import { validations } from './schema';

export const { maxPrice, minPrice, maxRemark } = validations;

const remarkLabel = i18n('COMMONS__LABEL__RESERVATIONS__EXTRA_CHARGE__REMARK');
const priceLabel = i18n('COMMONS__LABEL__RESERVATIONS__EXTRA_CHARGE__AMOUNT');

function CustomExtraChargeForm({
  selectedVerifyPoint = {},
  onChangeFormik = _noop,
  setCheckPoint = _noop,
  setStockInCurrentPoint = _noop,
  disabledForm = false,
  handleSubmit,
  values,
}) {
  const {
    extraChargeRemarkName,
    extraChargeAmountName,
    disabledFormName,
    disabledFormPrice,
    remarkName,
    priceName,
  } = values;
  const { prevCustomExtraCharges = [], uuid = '' } = selectedVerifyPoint;

  useEffect(() => {
    if (handleSubmit) {
      onChangeFormik({ handleSubmit });
    }
  }, [handleSubmit]);

  useEffect(() => {
    if (!disabledForm) {
      setStockInCurrentPoint(true);
    }
    return () => {
      setStockInCurrentPoint(false);
    };
  }, [disabledForm]);

  useEffect(() => {
    const newPrevValues = {
      extraChargeRemarkName,
      extraChargeAmountName,
      values,
    };
    const prevValues = prevCustomExtraCharges.find(
      (prevExtraCharge) => prevExtraCharge.verifyPointUuid === uuid
    ) || { verifyPointUuid: uuid, prevExtraCharges: [] };

    prevValues.prevExtraCharges = [...prevValues.prevExtraCharges]
      .filter(
        (prevValue) => prevValue.extraChargeRemarkName !== extraChargeRemarkName
      )
      .concat(newPrevValues);

    const newPrevCustomExtraCharges = prevCustomExtraCharges
      .filter((prevExtraCharge) => prevExtraCharge.verifyPointUuid !== uuid)
      .concat(prevValues);

    setCheckPoint({
      ...selectedVerifyPoint,
      prevCustomExtraCharges: newPrevCustomExtraCharges,
    });
  }, [values]);

  return (
    <div>
      <Input
        mask={lettersAndNumbersAndAccentsWithSpace(30)}
        maskPlaceholder=""
        label={remarkLabel}
        placeholder={disabledForm ? disabledFormName : remarkLabel}
        name={remarkName}
        showLabel
        disabled={disabledForm}
      />
      <NumberInput
        max={maxPrice}
        min={minPrice}
        label={priceLabel}
        placeholder={disabledForm ? disabledFormPrice : priceLabel}
        name={priceName}
        showLabel
        showPlaceholder
        disabled={disabledForm}
        decimals={2}
      />
    </div>
  );
}

export default withFormik({
  handleSubmit: handleFormikSubmit,
  mapPropsToValues,
  mapPropsToTouched,
  validationSchema,
})(CustomExtraChargeForm);
