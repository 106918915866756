import { LOANS_STATUSES } from 'utils/constants/loansStatuses';
import { getContextualMenuItem } from 'utils/table';
import {
  EDIT_STATUS_CLOSED_ANY_LOAN_REQUEST,
  EDIT_ANY_LOAN_REQUEST,
  EDIT_STATUS_AUTHORIZE_LOAN_REQUEST,
  EDIT_STATUS_CANCELLED_ANY_LOAN_REQUEST,
} from 'utils/roles/permissions/loansPermissions';
import { validateRolePermissions } from 'utils/roles';

export const successPath = '/loans';

const getContextualMenu = () => {
  const closeMenuItem = {
    CLOSE: {
      label: 'Cerrar',
      name: 'closeLoan',
      disabled: false,
      options: [],
      showExpanded: false,
    },
  };

  const closeItem = getContextualMenuItem(
    closeMenuItem,
    EDIT_STATUS_CLOSED_ANY_LOAN_REQUEST
  );

  const editMenuItem = {
    EDIT: {
      label: 'Editar',
      name: 'editLoan',
      disabled: false,
      options: [],
      showExpanded: false,
    },
  };

  const editItem = getContextualMenuItem(editMenuItem, EDIT_ANY_LOAN_REQUEST);

  const authorizeMenuItem = {
    AUTHORIZE: {
      label: 'Autorizar',
      name: 'authorizeLoan',
      disabled: false,
      options: [],
      showExpanded: false,
    },
  };

  const authorizeItem = getContextualMenuItem(
    authorizeMenuItem,
    EDIT_STATUS_AUTHORIZE_LOAN_REQUEST
  );

  const cancelMenuItem = {
    CANCEL: {
      label: 'Cancelar',
      name: 'cancelLoan',
      disabled: false,
      options: [],
      showExpanded: false,
    },
  };

  const cancelItem = getContextualMenuItem(
    cancelMenuItem,
    EDIT_STATUS_CANCELLED_ANY_LOAN_REQUEST
  );

  const allItems = {
    ...closeItem,
    ...editItem,
    ...authorizeItem,
    ...cancelItem,
  };

  return { allItems, closeItem };
};

export const contextualMenu = getContextualMenu().allItems;

export const getLoanContextualMenuItems = (loanStatus) => {
  const { allItems, closeItem } = getContextualMenu();
  let menuItems = {};

  switch (loanStatus) {
    case LOANS_STATUSES.PROCESS:
      menuItems = {
        ...closeItem,
      };
      break;
    case LOANS_STATUSES.CONCLUDED:
    case LOANS_STATUSES.CANCELED:
      menuItems = {};
      break;
    case LOANS_STATUSES.REQUESTED:
      menuItems = allItems;
      break;
    default:
      menuItems = allItems;
      break;
  }
  if (!validateRolePermissions(EDIT_ANY_LOAN_REQUEST)) delete menuItems.EDIT;
  if (!validateRolePermissions(EDIT_STATUS_AUTHORIZE_LOAN_REQUEST))
    delete menuItems.AUTHORIZE;
  if (!validateRolePermissions(EDIT_STATUS_CLOSED_ANY_LOAN_REQUEST))
    delete menuItems.CLOSE;
  if (!validateRolePermissions(EDIT_STATUS_CANCELLED_ANY_LOAN_REQUEST))
    delete menuItems.CANCEL;

  return Object.values(menuItems);
};
