import { takeLatest } from 'redux-saga/effects';
import types from './actions-types';
import {
  getDailyRentContractsSaga,
  closeContractSaga,
  getCloseContractDetailsSaga,
  saveContractRefundSaga,
  saveContractPaySaga,
  getTotalPaySaga,
} from './sagas';

export default function* watchers() {
  yield takeLatest(types.GET_LIST, getDailyRentContractsSaga);
  yield takeLatest(types.CLOSE_CONTRACT, closeContractSaga);
  yield takeLatest(
    types.GET_CLOSE_CONTRACT_DETAILS,
    getCloseContractDetailsSaga
  );
  yield takeLatest(types.SAVE_CONTRACT_REFUND, saveContractRefundSaga);
  yield takeLatest(types.SAVE_CONTRACT_PAY, saveContractPaySaga);
  yield takeLatest(types.GET_TOTAL_PAY, getTotalPaySaga);
}
