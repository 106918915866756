import { sendCustomRequest } from '@casanova/casanova-common/lib/utils/service';
import { APIError } from '@casanova/casanova-common/lib/utils/exceptions';
import { downloadFileFromUrl, fileRequest } from 'services/commons';

const ROOT = 'reporting/v1';

export async function getReportsList(params, request, branch) {
  const endpoint = `${ROOT}/general/${params.reportType}`;
  // request.branch = branch;
  const response = await sendCustomRequest(endpoint, {}, request, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function getTypesOfReports() {
  const endpoint = `${ROOT}/general/types`;
  const response = await sendCustomRequest(endpoint, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getDownloadReports(params, request, branch) {
  const endpoint = `${ROOT}/general/${params.reportType}/download`;
  // request.branch = branch;
  const { fileUrl, fileName } = await fileRequest(
    endpoint,
    {},
    request,
    'POST',
    'application/json'
  );

  downloadFileFromUrl(fileUrl, fileName);

  return { data: true };
}
