import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getTypesOfReports } from 'store/reports/actions';
import { fetchCatalog } from 'store/commons/actions';
import SearchReportForm from './SearchReportForm';

export default compose(
  connect(
    (state) => ({
      typesOfReports: state.reports.typesOfReports.list,
    }),
    { getTypesOfReports, fetchCatalog }
  )
)(SearchReportForm);
