import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import {
  getContracts,
  getContractsFilters,
  setListOfContractsPage,
  resetListOfContractsParams,
} from 'store/contracts/actions';
import { fetchCatalog } from 'store/commons/actions';
import ListOfContracts from './ListOfContracts';

export default withLayout(
  compose(
    connect(
      (state) => ({
        contracts: state.contracts.list,
        confirmationMessage: false,
      }),
      {
        getContracts,
        getContractsFilters,
        setListOfContractsPage,
        resetListOfContractsParams,
        fetchCatalog,
      }
    )
  )(ListOfContracts)
);
