import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  CardSection,
  TextField,
  FileViewer,
  UserInfo,
} from '@casanova/casanova-common';
import { TITLE_OF_DOCUMENTS } from './constants';

const CompanyDataDetail = ({ data }) => {
  const {
    companyName,
    companyRFC,
    businessName,
    statusColor,
    files,
  } = useMemo(() => {
    const getFiles = () => {
      if (!_isEmpty(data)) {
        const DOCUMENTS = [
          'attorneyPowerCopy',
          'authorizationLetter',
          'complianceOpinion',
          'incorporationArticlesCopy',
          'rfcCopy',
          'taxId',
          'vehicleRequest',
        ];

        const objDocs = [];

        DOCUMENTS.forEach((doc) => {
          const body = {
            file: _get(data, `${doc}.presignedUrl`, undefined),
            title: TITLE_OF_DOCUMENTS[doc],
          };
          if (body.file !== undefined) objDocs.push(body);
        });

        return objDocs;
      }
    };

    return {
      companyName: _get(data, 'name', ''),
      companyRFC: _get(data, 'rfc', ''),
      businessName: _get(data, 'businessName', ''),
      files: getFiles(),
    };
  }, [data]);

  return (
    <CardSection
      status={statusColor}
      title="INFORMACIÓN DE LA EMPRESA"
      upperCase
    >
      <div className="row">
        <div className="col-7">
          <FileViewer files={files} />
        </div>
        <div className="col-5">
          <TextField title="Nombre de la empresa" value={companyName} />
          <div className="row">
            <div className="col-6">
              <TextField title="RFC" value={companyRFC} />
            </div>
            <div className="col-6">
              <TextField title="Razón social" value={businessName} />
            </div>
          </div>
          {data.landline1 && (
            <div className="row">
              <div className="col-6">
                <TextField title="TELÉFONO 1" value={data.landline1} />
              </div>
              <div className="col-6">
                <TextField title="EXT" value={data.ext1} />
              </div>
            </div>
          )}
          {data.landline2 && (
            <div className="row">
              <div className="col-6">
                <TextField title="TELÉFONO 2" value={data.landline2} />
              </div>
              <div className="col-6">
                <TextField title="EXT" value={data.ext2} />
              </div>
            </div>
          )}
          <UserInfo {...data} />
        </div>
      </div>
    </CardSection>
  );
};

CompanyDataDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

CompanyDataDetail.defaultProps = {
  data: {},
};

export default CompanyDataDetail;
