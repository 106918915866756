export const RESERVATIONS_MODULE = 'RESERVATIONS_MODULE';
export const QUOTER_MODULE = 'QUOTER_MODULE';

export const CREATE_RESERVATION = 'CREATE_RESERVATION';

export const VIEW_PRICE_QUOTE = 'VIEW_PRICE_QUOTE';
export const CREATE_INTERNAL_PRICE_QUOTE = 'CREATE_INTERNAL_PRICE_QUOTE';
export const VIEW_RESERVATIONS = 'VIEW_RESERVATIONS';
export const VIEW_CREATE_CUSTOMER_PRICE_QUOTE =
  'VIEW_CREATE_CUSTOMER_PRICE_QUOTE';
export const VIEW_SEND_PRICE_QUOTE = 'VIEW_SEND_PRICE_QUOTE';
export const VIEW_CREATE_RESERVATION = 'VIEW_CREATE_RESERVATION';
export const NOT_VIEW_RESERVATION_DETAILS = 'NOT_VIEW_RESERVATION_DETAILS';
export const EDIT_RESERVATION = 'EDIT_RESERVATION';
export const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
