import _get from 'lodash/get';
import _noop from 'lodash/noop';
import { Icons } from '@casanova/casanova-common';

import { CellText, Cell } from 'components/ColumnsCells';
import i18n from '@i18n';

import { number2mxn } from 'utils/currency';
import { fetchAndDownloadFileFromUrl } from 'services/commons';
import { generateUrlFromBase64Data } from 'utils/files';
import { APPLICATION_FILES } from 'utils/constants/filesExtensions';

export default ({ onSelectPdf = _noop, invoiceDetails = {} }) => [
  {
    label: i18n('COMMONS__FOLIO'),
    field: 'folio',
    align: 'center',
    renderer: CellText,
  },
  {
    label: i18n('INVOICES__SERIE'),
    field: 'serie',
    align: 'center',
    renderer: CellText,
  },
  {
    label: 'Importe pagado',
    field: 'amountPaid',
    align: 'center',
    renderer: (props) => (
      <Cell className="text-center">{() => number2mxn(props.value)}</Cell>
    ),
  },
  {
    label: 'Importe de saldo anterior',
    field: 'previousBalanceAmount',
    align: 'center',
    renderer: (props) => (
      <Cell className="text-center">{() => number2mxn(props.value)}</Cell>
    ),
  },
  {
    label: 'Importe pendiente',
    field: 'unpaidBalanceAmount',
    align: 'center',
    renderer: (props) => (
      <Cell className="text-center">{() => number2mxn(props.value)}</Cell>
    ),
  },
  {
    label: i18n('COMMONS__DATE'),
    field: 'paymentDate',
    align: 'center',
    renderer: CellText,
  },
  {
    label: 'Método de pago',
    field: 'paymentMethod.name',
    align: 'center',
    renderer: CellText,
  },
  {
    label: '',
    field: 'pdf',
    align: 'center',
    renderer: (props) => {
      const handleShowPdf = () => {
        const pdfBase64Data = _get(props, 'data.pdf', '');
        const isUrl = pdfBase64Data.startsWith('https://');
        if (isUrl) return;

        const url = generateUrlFromBase64Data(
          APPLICATION_FILES.PDF,
          pdfBase64Data
        );
        onSelectPdf(url);
      };

      return (
        <Cell>
          {() => (
            <Icons.Clip
              onClick={handleShowPdf}
              width="0.975rem"
              height="1.163rem"
            />
          )}
        </Cell>
      );
    },
  },
  {
    label: '',
    field: 'xml',
    align: 'center',
    renderer: (props) => {
      const handleDownload = () => {
        const xmlBase64Data = _get(props, 'data.xml', '');
        const isUrl = xmlBase64Data.startsWith('https://');
        if (isUrl) return;

        const url = generateUrlFromBase64Data(
          APPLICATION_FILES.XML,
          xmlBase64Data
        );
        const fileName = `crp_${invoiceDetails.uuid}`;

        fetchAndDownloadFileFromUrl(url, 'xml', fileName);
      };

      return (
        <Cell>
          {() => (
            <Icons.DownloadV2
              onClick={handleDownload}
              width="0.975rem"
              height="1.163rem"
            />
          )}
        </Cell>
      );
    },
  },
];

export const contextualMenu = {
  SAVE_CRP: {
    label: i18n('INVOICES__EMIT_CRP'),
    name: 'saveCRP',
    disabled: false,
    options: [],
    showExpanded: false,
  },
};
