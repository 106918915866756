import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import { getReportsList } from 'store/reports/actions';
import SearchReports from './SearchReports';

export default withLayout(
  compose(
    connect(
      (state) => ({
        // withForm props
        confirmationMessage: false,

        reportsList: state.reports.list,
      }),
      { getReportsList }
    )
  )(SearchReports)
);
