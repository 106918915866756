import React from 'react';
import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { Icons } from '@casanova/casanova-common';
import {
  setRefundStep,
  getReservationInfo,
  closeResponseDialog,
  hydrateExtendContractFlow,
} from 'store/reservations/actions';
import { getQuotePath } from 'utils/navigation';
import { withLayout } from 'hooks';
import { BackButton } from 'components';
import { DeliveryReturnStepOne } from '@reservations/sections/DeliveryRefluxSteps';
import { config } from './config';

const Left = () => <BackButton path="/reservations" />;

export default withLayout(
  compose(
    connect(
      (state) => ({
        loader: _get(state, 'commons.loader', false),
        onCancelPath: `${getQuotePath('one')}?isCanceling=true`,
        left: <Left />,
        responseDialog: _get(state, 'reservations.responseDialog', {}),
        status: _get(state, 'reservations.refund', false),
        leftCol: 4,
        middleCol: 4,
        confirmationMessage: true,
        IconsResponseDialog: { Success: () => <Icons.Logo /> },
        config: config({
          folio: _get(state, 'reservations.reservationInfo.data.folio', ''),
        }),
      }),
      {
        setStep: setRefundStep,
        getReservationInfo,
        closeResponseDialog,
        hydrateExtendContractFlow,
      }
    )
  )(DeliveryReturnStepOne)
);
