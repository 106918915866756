import * as yup from 'yup';
import { fileValidation } from 'utils/validations/common';
import { REQUIRED_MESSAGE } from 'utils/constants';
import { ENTRANCE_TYPES } from '@customers/common/constants';

const isAerial = ({ identifier }) => identifier === ENTRANCE_TYPES.AERIAL;
const isMaritime = ({ identifier }) => identifier === ENTRANCE_TYPES.MARITIME;

const documentSchema = fileValidation({
  schema: yup.string().required(REQUIRED_MESSAGE),
  fields: [],
});

const aerialDocumentSchema = yup.mixed().when('selectedEntrance', {
  is: isAerial,
  then: documentSchema,
  otherwise: yup.string(),
});

const maritimeDocumentSchema = yup.mixed().when('selectedEntrance', {
  is: isMaritime,
  then: documentSchema,
  otherwise: yup.string(),
});

export const countryEntranceTypeProps = {
  entranceType: yup.string().required(REQUIRED_MESSAGE),
  selectedEntrance: yup.object().required(REQUIRED_MESSAGE),
  entranceItinerary: aerialDocumentSchema,
  entranceFFME: aerialDocumentSchema,
  entranceFMM: maritimeDocumentSchema,
};
