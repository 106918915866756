export const FILES_EXTENSIONS = {
  JPEG: {
    type: 'image',
    name: 'jpeg',
  },
  PDF: {
    type: 'application',
    name: 'pdf',
  },
  PNG: {
    type: 'image',
    name: 'png',
  },
};

export const APPLICATION_FILES = {
  XML: 'application/xml',
  XML_TEXT: 'text/xml',
  PDF: 'application/pdf',
};
