export const formatAuthorizedDrivers = (values) => {
  const drivers = values.driverAuthorizedData || [];
  const formattedDrivers = drivers
    .filter(({ driverCurp = '', driverUuid = '' }) => driverCurp || driverUuid)
    .map(({ driverCurp = '', driverUuid = '' }) => ({
      curp: driverCurp,
      authorizedDriverUuid: driverUuid,
    }));

  const formattedDriversFiles = drivers.map(
    ({ driverFrontLicense, driverBackLicense, driverCurp }) => [
      {
        fieldName: 'front',
        file: driverFrontLicense,
        suffix: 'ADDF',
        fileUuid: driverCurp,
      },
      {
        fieldName: 'back',
        file: driverBackLicense,
        suffix: 'ADDB',
        fileUuid: driverCurp,
      },
    ]
  );

  return [formattedDrivers, formattedDriversFiles];
};
