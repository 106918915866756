import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  MaskedInput as Input,
  FileInput,
  NumberInput,
} from '@casanova/casanova-common';
import { DatePickerV2 } from 'components';
import { PaymentType } from '@reservations/components';
import { alphaMask, hourMask } from 'utils/masks';
import { generateCalendarMinimumDate, today2CalendarDate } from 'utils/date';

const minimumDate = generateCalendarMinimumDate(0, 0, 9);
const maximumDate = today2CalendarDate();

const DepositMethod = ({ name, onSelect, hasUpdateDifMetods }) => {
  const { values, setFieldValue } = useFormikContext();

  const obtenerFechaActual = () => {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
    const day = String(fechaActual.getDate()).padStart(2, '0');
    const fechaFormateada = `${year}-${month}-${day}`;
    return fechaFormateada;
  };

  const obtenerHoraActual = () => {
    const horaActual = new Date();
    const horas = String(horaActual.getHours()).padStart(2, '0');
    const minutos = String(horaActual.getMinutes()).padStart(2, '0');
    const segundos = String(horaActual.getSeconds()).padStart(2, '0');
    const horaFormateada = `${horas}:${minutos}:${segundos}`;
    return horaFormateada;
  };

  useEffect(() => {
    setFieldValue('bankDate', obtenerFechaActual());
    setInterval(() => {
      setFieldValue('bankHour', obtenerHoraActual());
    }, 1000);
  }, []);

  return (
    <PaymentType
      name={name}
      onSelect={onSelect}
      title="DEPÓSITO BANCARIO"
      hasUpdateDifMetods={hasUpdateDifMetods}
    >
      <div className="row">
        <div className="col-6">
          <Input
            mask={alphaMask(20)}
            maskPlaceholder=""
            label="Referencia*"
            placeholder="Referencia*"
            name="bankAuthorization"
          />
        </div>
        <div className="col-6">
          <DatePickerV2
            initialValue={values.bankDate}
            label="Fecha*"
            name="bankDate"
            placeholder="Fecha*"
            minimumDate={minimumDate}
            maximumDate={maximumDate}
            disabled
          />
        </div>
        <div className="col-6">
          <Input
            mask={hourMask}
            maskPlaceholder=""
            label="Hora*"
            placeholder="Hora*"
            name="bankHour"
            disabled
          />
        </div>
        <div className="col-md-6">
          <FileInput
            url={values.bankVoucher}
            displayText={values.bankVoucher}
            type="file"
            label="Comprobante*"
            placeholder="Comprobante*"
            name="bankVoucher"
            showIcon={false}
          />
        </div>
        <div className="col-6">
          {hasUpdateDifMetods && (
            <NumberInput
              decimals={2}
              max={9_999_999}
              label="Monto en Deposito"
              placeholder="Monto en Deposito"
              name="amountDeposit"
              disabled={!hasUpdateDifMetods}
            />
          )}
        </div>
      </div>
    </PaymentType>
  );
};

DepositMethod.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
  hasUpdateDifMetods: PropTypes.bool,
};

DepositMethod.defaultProps = {
  name: 'deposit',
};

export default DepositMethod;
