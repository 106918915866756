import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import { selectBrand, selectLine } from 'store/catalogs/actions';
import { fetchCatalog } from 'store/commons/actions';

import {
  fetchVehicles,
  fetchVehiclesFilters,
  setVehiclesPage,
  modifyFilters,
  setVehiclesSearch,
  exportVehiclesCatalog,
  setMassiveLoadType,
} from 'store/vehicles/actions';
import VehicleList from './VehicleList';

export default compose(
  connect(
    (state) => ({
      vehicles: state.vehicles.vehiclesList,
      listBrands: state.catalogs.brand.list,
      listOwner: state.catalogs.owner.list,
      listProviders: state.catalogs.provider.list,
      listModels: state.catalogs.model.list,
      listStatus: state.catalogs.status.list,
      brandSelected: state.catalogs.brandSelected,
      lineSelected: state.catalogs.lineSelected,
      confirmationMessage: false,
      exportCatalogRecords: state.vehicles.exportCatalogRecords,
    }),
    {
      fetchVehicles,
      fetchVehiclesFilters,
      setVehiclesPage,
      modifyFilters,
      selectBrand,
      selectLine,
      setVehiclesSearch,
      exportVehiclesCatalog,
      setMassiveLoadType,
      fetchCatalog,
    }
  ),
  withLayout
)(VehicleList);
