import React, { useEffect, useState } from 'react';
import _get from 'lodash/get';
import { Section } from '@casanova/casanova-common';
import _isEmpty from 'lodash/isEmpty';
import { formatContractNumber } from './config';
import { ScrollPanel } from 'primereact/scrollpanel';
import { SplitButton } from 'primereact/splitbutton';
import { PDFModal } from 'components';
import './styles.css';
import { NOTE_CONTRACT_RD } from 'utils/roles/permissions/contractsRDPermissions';
import { validateRolePermissions } from 'utils/roles';

const ExtendList = (props) => {
  const { extendContractDetails } = props;
  const { data, currentContract } = extendContractDetails;
  const [items, setItems] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [contractUrl, setContractUrl] = useState('');

  const handleOnClick = (url) => {
    setShowDetail(true);
    setContractUrl(url);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setItems(transformItems(extendContractDetails));
    }
  }, [extendContractDetails]);

  const getFistContract = (contract) => {
    const template = validateRolePermissions(NOTE_CONTRACT_RD)
      ? [{}, {}]
      : [{}];
    template.label = contract.contractNumber;
    template[0].label = 'Contrato';
    template[0].command = () =>
      handleOnClick(contract.leasingContractOriginal.presignedUrl);
    if (validateRolePermissions(NOTE_CONTRACT_RD)) {
      template[1].label = 'Pagaré';
      template[1].command = () =>
        handleOnClick(contract.promissoryNoteContractOriginal.presignedUrl);
    }
    return template;
  };

  const transformItems = (extendContractDetails) => {
    const contracts = [];
    extendContractDetails.data.forEach((ec) => {
      const template = [{}];
      template[0].label = 'Contrato';
      template[0].command = () =>
        handleOnClick(ec.leasingContract.presignedUrl);
      // if (validateRolePermissions(NOTE_CONTRACT_RD)) {
      //   template[1].label = 'Pagaré';
      //   template[1].command = () =>
      //     handleOnClick(ec.promissoryNoteContract.presignedUrl);
      // }
      contracts.push(template);
    });
    contracts.push(getFistContract(currentContract));
    return contracts;
  };

  return (
    <>
      <PDFModal
        show={showDetail}
        onClose={() => setShowDetail(false)}
        url={contractUrl}
      />
      <Section title="Contratos">
        <ScrollPanel className="w-full h-14rem custombar1">
          {items.length > 0 && (
            <SplitButton
              label={items[items.length - 1].label}
              className="w-full mb-2"
              model={items[items.length - 1]}
              severity="items"
              size="small"
              outlined
            />
          )}
          {items.length > 0 ? (
            data.map((ec, index) => {
              return (
                <SplitButton
                  key={index}
                  label={formatContractNumber(ec.contractNumber)}
                  className="w-full mb-2"
                  model={items[index]}
                  severity="items"
                  size="small"
                  outlined
                />
              );
            })
          ) : (
            <p>Sin extensiones</p>
          )}
        </ScrollPanel>
      </Section>
    </>
  );
};

export default ExtendList;
