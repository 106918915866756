export const initialTouched = {
  entranceType: true,
  entranceItinerary: true,
  entranceFFME: true,
  entranceFMM: true,
  selectedEntrance: true,
};

export const initialValues = {
  entranceType: '',
  entranceItinerary: '',
  entranceFFME: '',
  entranceFMM: '',
  selectedEntrance: {},
};

export default {
  initialTouched,
  initialValues,
};

export const FIELDS = Object.keys(initialValues);
