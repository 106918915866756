import { CUSTOMER_TYPES } from '@customers/common/constants';
export const DELIVERY_REFUND_TYPES = {
  DELIVERY: 'DELIVERY',
  RETURN: 'RETURN',
  REFUND: 'RETURN',
};

export const DELIVERY_RETURN_TYPES = DELIVERY_REFUND_TYPES;

export const VEHICLE_VIEW_TYPE = {
  VEHICLE_DESCRIPTION: 'VEHICLE_DESCRIPTION',
  VEHICLE_SECTION_OBSERVATION: 'VEHICLE_SECTION_OBSERVATION',
};

export const REMARK_TYPES = {
  GOOD_STATE: 'GOOD_STATE',
  DETAIL: 'DETAIL',
};

export const VEHICLE_DETAILS_LABEL = {
  serialNumber: 'No. de serie',
  plate: 'Placa',
  km: 'Kilometraje',
  policy: 'Póliza de seguro',
  circulationCardHasCopy: 'Tarjeta de circulación',
  circulationCardOriginal: 'Original',
  circulationCardCopy: 'Copia certificada',
  gas: 'Tanque de gasolina',
  // tools: 'Herramientas',
  extinguisher: 'Extintor',
  spareTire: 'Llanta de refacción',
  // safetyTriangle: 'Triángulo de seguridad',
};

export const AREA_OPTIONS = {
  EMAIL: 'email',
  CELLPHONE: 'cellphone',
  USER: 'user',
};

export const VEHICLE_RENTAL_STATUSES = {
  ON_FLOOR: 'Piso',
  AVAILABLE: 'Disponible',
};

export const SPECT_ITEM_TYPES = {
  DIVISION: 'DIVISION',
  WITH_VALUE: 'WITH_VALUE',
};

export const FIELD_TYPE = {
  BOOL: 'boolean',
  NUMBER: 'number',
};

export const GROUPS = {
  GENERAL: {
    position: 1,
    name: 'general',
  },
  TABLERO: {
    position: 12,
    name: 'Tablero',
    verificationPoints: {
      TABLERO: {
        name: 'Tablero',
        position: 1,
      },
    },
  },
};

export const ADDITIONAL_FIELDS = {
  GAS: 'gas',
  KM: 'km',
  VEHICLE: 'vehicle',
};

export const CUSTOM_EXTRA_CHARGE = {
  label: 'OTRO',
  name: 'OTRO',
  value: 'CUSTOM_EXTRA_CHARGE',
  uuid: 'CUSTOM_EXTRA_CHARGE',
};

export const CUSTOM_EXTRA_CHARGE_TYPES = {
  CUSTOM_EXTRA_CHARGE_AMOUNT: 'CUSTOM_EXTRA_CHARGE_AMOUNT',
  CUSTOM_EXTRA_CHARGE_REMARK: 'CUSTOM_EXTRA_CHARGE_REMARK',
  BASE: 'custom_extra_charge_',
};

export const RENTAL_PLANS = {
  TWO_HUNDRED_FIFTY_KM: '250 Kilómetros',
  FREE_KM: 'Kilometraje libre',
  WEEKLY: 'Semanal',
  MONTHLY: 'Mensual',
  TWO_HUNDRED_FIFTY_KM_TEMPORAL: '250 Kilómetros Temporal',
  FREE_KM_TEMPORAL: 'Kilometraje libre Temporal',
  WEEKLY_TEMPORAL: 'Semanal Temporal',
  MONTHLY_TEMPORAL: 'Mensual Temporal',
};

export const VALUES_LIST = [
  { value: CUSTOMER_TYPES.FISIC, label: 'Física' },
  { value: CUSTOMER_TYPES.JURIDICAL, label: 'Moral' },
  { value: CUSTOMER_TYPES.TENDER, label: 'Licitación' },
];

export const VALUES_LIST_NOT_LEASING = [
  { value: CUSTOMER_TYPES.FISIC, label: 'Física' },
  { value: CUSTOMER_TYPES.JURIDICAL, label: 'Moral' },
  { value: CUSTOMER_TYPES.FOREIGNER, label: 'Extranjero' },
];
