"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeSpaces = exports.removeAccents = exports.getWithHoursFormat = void 0;
var removeAccents = exports.removeAccents = function removeAccents(string) {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
var removeSpaces = exports.removeSpaces = function removeSpaces(string) {
  return string.replace(/\s/g, '');
};
var getWithHoursFormat = exports.getWithHoursFormat = function getWithHoursFormat(string) {
  return "".concat(string, " HRS");
};