import React from 'react';
import PropTypes from 'prop-types';
import { ProgressIndicator, Button } from '@casanova/casanova-common';
import { LABELS_OF_MASSIVE_LOAD_TYPES } from './constants';

export default function StepHeader({
  currentStep,
  onDownload,
  maxSteps,
  massiveLoadType,
}) {
  if (!massiveLoadType) return null;

  const labels = LABELS_OF_MASSIVE_LOAD_TYPES[massiveLoadType](currentStep);

  return (
    <div className="row">
      <div className="col-md-2 p-0">
        <ProgressIndicator
          size={120}
          stroke={['#e2e2e2', '#007aff']}
          strokeWidth={6}
          progress={(currentStep / maxSteps) * 100}
          label={`${currentStep} de ${maxSteps}`}
        />
      </div>
      <div className="col-md-6 d-flex align-items-center">
        <div>
          <h3 className="text-primary">{labels.title}</h3>
          <p>{labels.subtitle}</p>
        </div>
      </div>
      {currentStep === 1 && (
        <div className="col-md-4 text-right">
          <p>¿No tienes el formato?</p>
          <Button outline onClick={onDownload}>
            Descargar plantilla
          </Button>
        </div>
      )}
      <div className="col-md-12">
        <hr className="mb-0" />
      </div>
    </div>
  );
}

StepHeader.propTypes = {
  currentStep: PropTypes.number,
  onDownload: PropTypes.func,
  maxSteps: PropTypes.number,
  massiveLoadType: PropTypes.string,
};
