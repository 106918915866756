import * as yup from 'yup';
import moment from 'moment';
import { timeZone } from '@casanova/casanova-common/lib/utils/date';
import i18n from '@i18n';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index.js';

export const schema = yup.object().shape({
  reason: yup.string().required(REQUIRED_MESSAGE),
  startDate: yup.string().required(REQUIRED_MESSAGE),
  endDate: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .test(
      'greater-than-start-date',
      'La fecha de regreso debe ser mayor a la de inicio',
      function greaterThanStartDate(endDate) {
        const { startDate } = this.parent;

        if (!startDate) return true;

        return moment(endDate).tz(timeZone) >= moment(startDate).tz(timeZone);
      }
    ),
  remarks: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(1, i18n('ERROR__COMMONS__MIN_LENGTH', [1])),
});
