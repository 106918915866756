import { removeSpaces } from '@casanova/casanova-common/lib/utils/masks';

export const formatter = (values) => {
  const {
    holdbackCardHolderModify,
    holdbackBankModify,
    holdbackCardModify,
    holdbackValidityModify,
    uuid,
  } = values;

  const elements = holdbackValidityModify.split('/');
  const month = parseInt(elements[0], 10);
  const year = parseInt(elements[1], 10) + 2000;

  const realMonth = month >= 10 ? month : `0${month}`;

  const creditCardHold = {
    bank: holdbackBankModify,
    cardHolderName: holdbackCardHolderModify,
    cardNumber: removeSpaces(holdbackCardModify),
    expDate: `${year}-${realMonth}-01`,
    uuid,
  };

  return creditCardHold;
};
