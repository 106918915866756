import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from '@i18n';
import {
  Table,
  withLayout,
  TableBottom,
  Section,
  useFirstRender,
} from '@casanova/casanova-common';
import { columns } from './columns';

function VehicleLoans({
  match,
  vehicleLoans,
  fetchVehicleLoans,
  setPageVehicleLoans,
  vehicleDetails,
  onlyFetchParams = true,
}) {
  const { params = {}, results = {} } = vehicleLoans;

  const isFirstRender = useFirstRender();

  const vehicleUuid = useMemo(() => match.params.id, [match.params.id]);
  console.log(vehicleUuid, params);

  useEffect(() => {
    params.vehicleUuid = vehicleUuid;
    if (onlyFetchParams ? !isFirstRender : true) {
      fetchVehicleLoans({
        params,
        plateNumber: vehicleDetails.plateNumber,
        serialNumber: vehicleDetails.serialNumber,
        uuid: vehicleUuid,
      });
    }
  }, [vehicleUuid, params, onlyFetchParams]);

  return (
    <Section title={i18n('VEHICLES__SECTIONS__VEHICLE_LOANS__TITLE')}>
      <Table
        primary
        emptyMessage={i18n('ERROR__COMMONS__NOT_FOUND')}
        dataList={results.list}
        columns={columns}
      />
      <TableBottom
        search={vehicleLoans}
        onChange={setPageVehicleLoans}
        showButton={false}
      />
    </Section>
  );
}

VehicleLoans.propTypes = {
  onlyFetchParams: PropTypes.bool,
};

export default withLayout(VehicleLoans, { withLoader: false });
