import { getReportsList } from 'store/reports/actions';
import schema from './schema';
import formatter from './formatter';

const config = {
  schema,
  initialTouched: {},
  initialValues: {},
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  validateOnMount: true,
  formatter,
  // TODO: Waiting for Carlos pagination to be implemented
  submitForm: (values) => getReportsList({ params: values }),
  withButtons: false,
  dialogs: {
    confirmation: {},
    response: {},
  },
};

export default config;
