import * as yup from 'yup';
import 'moment/locale/es';
import i18n from '@i18n';
import cardFormSchema from '../CardMethod/schema';
import cashFormSchema from '../CashMethod/schema';
import depositFormSchema from '../DepositMethod/schema';
import invoiceFormSchema from '../InvoicePayment/schema';
import transferFormSchema from '../TransferMethod/schema';

const multiPaymentSchema = {
  diffMethods: yup.bool(),
  methodSelected: yup.string().when(['movementType'], {
    is: (movementType) =>
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup.string().required(i18n('ERROR__RESERVATIONS__PAYMENT__METHOD')),
    otherwise: yup.string(),
  }),
  ...cardFormSchema,
  ...cashFormSchema,
  ...depositFormSchema,
  ...invoiceFormSchema,
  ...transferFormSchema,
};

export default multiPaymentSchema;
