import React, { useEffect, useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import useIntersectionObserver from '@react-hook/intersection-observer';
import _get from 'lodash/get';
import _unique from 'lodash/uniqBy';
import i18n from '@i18n';
import { RadioGroupInput, Button, Icons } from '@casanova/casanova-common';
import withForm from 'hooks/withForm';
import OptionsBar from 'components/OptionsBar';
import { PreventChange } from 'components';
import { preventChangeConfig } from '@reservations/commons/config';
import PlanDescription from '@reservations/components/PlanDescription';
import Schedule from '@reservations/sections/Schedule';
import { getQuotePath } from 'utils/navigation';
import { notSunday } from 'utils/date';
import config from './config';
import './Two.scss';
import moment from 'moment';
import { TabMenu } from 'primereact/tabmenu';

export const BackButton = ({ setTitles, setStep }) => {
  const handleBackButton = useCallback(() => {
    setStep(1);
    setTitles({
      title: 'Seleccionar tipo de cotización',
      subtitle: 'Elige los parametrós de búsqueda que te indique el cliente.',
    });
  }, [setStep, setTitles]);

  return (
    <Button
      onClick={handleBackButton}
      lg
      outline
      id="spnBackButton"
      className="mr-3 button-outline-icon"
    >
      <span style={{ marginLeft: '1rem', marginRight: '1rem' }}>
        <Icons.Atras height="1.25rem" /> Atrás
      </span>
    </Button>
  );
};

const Two = ({
  loader,
  setTitles,
  getVehicleTypes,
  categories,
  brands,
  vehicleTypes,
  selectedCategory,
  quoteValues,
  getTimeLimits,
  getVehicles,
  vehicles,
  fetchCatalog,
  branches,
  totalPages,
  params,
  setParams,
  setCategorySelected,
  setPreviousCategorySelected,
  editScheduleAction,
  openEditScheduleSection,
  closeEditScheduleSection,
  setBrands,
  last,
  setLeaveStepOne,
  setQuoteInternalError,
}) => {
  if (!quoteValues.quoteId) {
    window.location.href = getQuotePath('one');
  }

  const {
    values,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    setErrors,
  } = useFormikContext();

  const [selectedStartBranch, setSelectedStartBranch] = useState({});
  const [selectedEndBranch, setSelectedEndBranch] = useState({});
  const [typeSelected, setTypeSelected] = useState({});
  const [line, setLine] = useState(quoteValues.lineSelected);
  const [schedStart, setSchedStart] = useState({
    date: '',
    branch: '',
  });

  const [schedEnd, setSchedEnd] = useState({
    date: '',
    branch: '',
  });

  const [ref, setRef] = React.useState();
  const { isIntersecting } = useIntersectionObserver(ref);
  const [items, setItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [stockInCurrentPoint, setStockInCurrentPoint] = useState(false);
  vehicleTypes = vehicleTypes.filter((item) => item.label !== 'Sedan');
  vehicleTypes = vehicleTypes.filter((item) => item.label !== 'Pasajeros');
  vehicleTypes = vehicleTypes.filter((item) => item.label !== 'Carga Chica');
  
  console.log(vehicleTypes);

  useEffect(() => {
    if (branches && branches.length > 0) {
      setSelectedStartBranch(
        branches
          .filter((item) => item.uuid === quoteValues.pickupBranch)
          .reduce((_, current) => current)
      );

      setSelectedEndBranch(
        branches
          .filter((item) => item.uuid === quoteValues.returnBranch)
          .reduce((_, current) => current)
      );
    }
  }, [branches, quoteValues.pickupBranch, quoteValues.returnBranch]);

  useEffect(() => {
    setTitles({
      title: 'Elegir vehículo y plan de renta',
      subtitle: 'Elige el vehículo por categoría y/o marca.',
    });

    if (categories.length > 0) {
      const category = categories
        ?.filter((item) => item.value === selectedCategory)
        .reduce((_, current) => current);
      setTypeSelected(category);
      setFieldValue('vehicleTypes', [category?.types[0].value]);
      setFieldValue('selectedCategory', selectedCategory);
    }

    setSchedStart({
      date: quoteValues.pickupDateTime,
      branch: selectedStartBranch.name,
    });

    setSchedEnd({
      date: quoteValues.returnDateTime,
      branch: selectedEndBranch.name,
    });
  }, [
    setFieldValue,
    setTitles,
    selectedCategory,
    quoteValues,
    branches,
    selectedStartBranch.name,
    selectedEndBranch.name,
    categories,
  ]);

  useEffect(() => {
    getVehicleTypes();
    getTimeLimits();
    fetchCatalog('branches');
    setLeaveStepOne(true);
  }, [getVehicleTypes, getTimeLimits, fetchCatalog, setLeaveStepOne]);

  useEffect(() => {
    if (
      isIntersecting &&
      params.page < totalPages &&
      !last &&
      params.page != 0
    ) {
      getVehicles({
        vehicleTypes: values.vehicleTypes,
        brands: values.brands,
        branch: quoteValues.pickupBranch,
        lineSelected: line,
        page: params.page,
        startRentalDay: moment(quoteValues.pickupDateTime).format('YYYY-MM-DD'),
      });
    }
  }, [getVehicles, isIntersecting]);

  const handleOnchangeFilters = useCallback(
    (option) => {
      setItems([]);
      setParams({ page: 0 });
      getVehicles({
        vehicleTypes: values.vehicleTypes,
        brands: [option.value],
        branch: quoteValues.pickupBranch,
        lineSelected: '',
        page: 0,
      });
    },
    [
      setParams,
      setItems,
      values.vehicleTypes,
      quoteValues.pickupBranch,
      getVehicles,
    ]
  );

  const handleOnchangeVehicleTypes = useCallback(
    (option) => {
      if (loader) return;
      // console.trace('handleOnchangeVehicleTypes 202');
      setActiveIndex(option.index);
      setItems([]);
      setParams({ page: 0 });
      setFieldValue('brands', []);
      // setFieldValue('vehicleTypes', [option.value]);
      setBrands(option.value);
      getVehicles({
        vehicleTypes: [option.value],
        brands: [],
        branch: quoteValues.pickupBranch,
        lineSelected: '',
        page: 0,
        startRentalDay: moment(quoteValues.pickupDateTime).format('YYYY-MM-DD'),
      });
    },
    [
      loader,
      setParams,
      setItems,
      setFieldValue,
      setBrands,
      getVehicles,
      quoteValues.pickupBranch,
      quoteValues.pickupDateTime,
    ]
  );

  useEffect(() => {
    console.log("Vehicles >> ", vehicles);
    if (vehicles && vehicles.length > 0) {
      setItems((prev) => {
        let vehicleItems = [];

        if (params.page === 0) {
          vehicleItems = [...new Set([...vehicles])];
        } else {
          vehicleItems = [...new Set([...prev, ...vehicles])];
        }

        return _unique(vehicleItems, 'vehicleLineUuid');
      });
    }
  }, [vehicles]);

  useEffect(() => {
    setItems([]);
    setParams({ page: 0 });
    getVehicles({
      vehicleTypes: [
        selectedCategory != '297b6a1f-d612-4734-aa14-86410e70c330'
          ? 'c8c86c30-7d5d-4efd-b92e-5529e398de70'
          : '47881e2e-6f8e-4d9e-a3b0-c3503e55de15',
      ],
      brands: values.brands,
      branch: quoteValues.pickupBranch,
      lineSelected: line,
      page: params.page,
      startRentalDay: moment(quoteValues.pickupDateTime).format('YYYY-MM-DD'),
    });
  }, [quoteValues.pickupDateTime]);

  const handleChangeFilter = useCallback(
    (option) => {
      setActiveIndex(option.index);
      setItems([]);
      setParams({ page: 0 });
      setCategorySelected(option.value);
      setPreviousCategorySelected(selectedCategory);
      setFieldValue('brands', []);
      setFieldValue('vehicleTypes', ['']);
      setLine('');
      getVehicles({
        vehicleTypes: [
          [
            option.value != '297b6a1f-d612-4734-aa14-86410e70c330'
              ? 'c8c86c30-7d5d-4efd-b92e-5529e398de70'
              : '47881e2e-6f8e-4d9e-a3b0-c3503e55de15',
          ],
        ],
        brands: [],
        branch: quoteValues.pickupBranch,
        lineSelected: '',
        page: 0,
        startRentalDay: moment(quoteValues.pickupDateTime).format('YYYY-MM-DD'),
      });
    },
    [
      setCategorySelected,
      setFieldValue,
      getVehicles,
      quoteValues.pickupBranch,
      setItems,
      setParams,
      setPreviousCategorySelected,
      selectedCategory,
      quoteValues.pickupDateTime,
    ]
  );

  useEffect(() => {
    setFieldValue('selectedCategory', selectedCategory);
  }, [selectedCategory, setFieldValue]);

  useEffect(() => {
    setFieldTouched('selectedCategory', true);
  }, [setFieldTouched]);

  useEffect(() => {
    if (!values.selectedCategory) return;

    const category = categories.find(
      (singleCategory) => singleCategory.value === values.selectedCategory
    );

    if (quoteValues.returnDateTime && category && category.isDefault) {
      const inSunday = !notSunday(quoteValues.returnDateTime);
      if (inSunday) {
        const message = i18n(
          'RESERVATIONS__QUOTE__OP__ERROR_STEP_2_INVALID_FORM'
        );

        setFieldError('selectedCategory', message);
        setQuoteInternalError({
          message,
          status: true,
        });

        return;
      }
    }

    setErrors({});
    setQuoteInternalError({
      message: '',
      status: false,
    });
  }, [
    values.selectedCategory,
    quoteValues,
    categories,
    setQuoteInternalError,
    setErrors,
    setFieldError,
  ]);

  return (
    <PreventChange {...preventChangeConfig}>
      <div className="row">
        <div className="col-12 switch-inverted">
          <RadioGroupInput
            labelClass="d-flex align-items-center"
            groupClass="col-12 d-flex"
            parentClass="vehicle-radio-input"
            label=""
            name="selectedCategory"
            options={categories}
            onChange={handleChangeFilter}
          />
        </div>
      </div>
      <div className="StepTwo">
        <Schedule
          schedStart={schedStart}
          schedEnd={schedEnd}
          quoteValues={quoteValues}
          editScheduleAction={editScheduleAction}
          openEditScheduleSection={openEditScheduleSection}
          closeEditScheduleSection={closeEditScheduleSection}
          selectedCategory={selectedCategory}
          selectedCategoryInForm={values.selectedCategory}
          alwaysDirty={false}
        />
        <div className="filters mt-3">
          {/* <TabMenu
            model={vehicleTypes}
            onTabChange={handleOnchangeVehicleTypes}
            activeIndex={activeIndex}
          /> */}
          <OptionsBar
            onChangeSelection={handleOnchangeVehicleTypes}
            name="vehicleTypes"
            options={vehicleTypes}
          />
          {/* <br />
          <OptionsBar
            onChangeSelection={handleOnchangeFilters}
            onlyIcons
            name="brands"
            options={brands}
          /> */}
        </div>
        <div className="row ">
          <div className="col-12 text-center">
            <div className="gray-disclaimer">
              Todas las tarifas incluyen impuestos. Se aplican restricciones de
              edad.
            </div>
          </div>
        </div>
        <div className="row plans-container">
          {items?.map((vehicle) => (
            <div
              key={`${vehicle.vehicleLineUuid}_${vehicle.vehicleTypeName}${vehicle.vehicleLineDescription}`}
              className="col-12"
            >
              <PlanDescription
                fullVehicle={vehicle}
                image={_get(vehicle, 'vehicleLineImagesOriginalUrls.0', '')}
                kind={typeSelected.label}
                size={vehicle.vehicleTypeName}
                description={vehicle.vehicleLineDescription}
                cylinder={_get(vehicle, 'specs.cylinder', [])}
                loadCapacity={_get(vehicle, 'specs.loadCapacity', [])}
                passengers={_get(vehicle, 'specs.passengers', [])}
                fuelType={_get(vehicle, 'specs.fuelType', [])}
                seatType={_get(vehicle, 'specs.seatType', [])}
                doors={_get(vehicle, 'specs.doors', [])}
                cargoBed={_get(vehicle, 'specs.cargoBed', [])}
                transmissionType={_get(vehicle, 'specs.transmissionType', [])}
                brand={_get(vehicle, 'vehicleBrandName')}
                line={_get(vehicle, 'vehicleLineName')}
                branch={selectedStartBranch.name}
                inBranch={_get(vehicle, 'existsInRequestedBranch')}
                plansList={_get(vehicle, 'vehicleLinePrices', [])}
              />
            </div>
          ))}
          <div ref={setRef} />
        </div>
      </div>
    </PreventChange>
  );
};

Two.propTypes = {};

export default withForm({ config })(Two);
