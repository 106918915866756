import { connect } from 'react-redux';
import store from 'store';
import { navigate } from 'store/app/actions';

export const PATH_RESERVATION_LIST = '/reservations';
export const PATH_QUOTE_STEP_ONE = '/reservations/quote';
export const PATH_QUOTE_STEP_TWO = '/reservations/plan';
export const PATH_QUOTE_STEP_THREE = '/reservations/summary';

export const QUOTER_PATH_QUOTE_STEP_ONE = '/quoter/';
export const QUOTER_PATH_QUOTE_STEP_TWO = '/quoter/plan';
export const QUOTER_PATH_QUOTE_STEP_THREE = '/quoter/summary';

export const PATH_CUSTOMERS_BASE = '/reservations/customer/';

export const PATH_QUOTE_OP_STEP_ONE = '/op/quote';
export const PATH_QUOTE_OP_STEP_TWO = '/op/quote/plan';
export const PATH_QUOTE_OP_STEP_THREE = '/op/quote/summary';
export const PATH_QUOTE_OP_STEP_FOURTH = '/op/quote/customer';

export const PATH_EXTEND_LIST = '/daily-rent-contracts/';
export const PATH_EXTEND_STEP_ONE = '/extend-contract';
export const PATH_EXTEND_STEP_TWO = '/extend-sign';
export const PATH_EXTEND_STEP_THREE = '/extend-payment';

export const QUOTE_CONTRACT_QUERY = 'contract=true';

export const paths = {
  list: {
    normal: PATH_RESERVATION_LIST,
    quoter: QUOTER_PATH_QUOTE_STEP_ONE,
    op: PATH_RESERVATION_LIST,
  },
  one: {
    normal: PATH_QUOTE_STEP_ONE,
    quoter: QUOTER_PATH_QUOTE_STEP_ONE,
    op: PATH_QUOTE_OP_STEP_ONE,
  },
  two: {
    normal: PATH_QUOTE_STEP_TWO,
    quoter: QUOTER_PATH_QUOTE_STEP_TWO,
    op: PATH_QUOTE_OP_STEP_TWO,
  },
  three: {
    normal: PATH_QUOTE_STEP_THREE,
    quoter: QUOTER_PATH_QUOTE_STEP_THREE,
    op: PATH_QUOTE_OP_STEP_THREE,
  },
  fourth: {
    normal: PATH_QUOTE_OP_STEP_FOURTH,
    quoter: PATH_QUOTE_OP_STEP_FOURTH,
    op: PATH_QUOTE_OP_STEP_FOURTH,
  },
};

export const pathsExtend = {
  one: PATH_EXTEND_STEP_ONE,
  two: PATH_EXTEND_STEP_TWO,
  three: PATH_EXTEND_STEP_THREE,
};

export const withNavigation = connect(
  (state) => ({ activeRoute: state.app.activeRoute }),
  { navigate }
);

export const getQuotePath = (step = 'one') => {
  const { normal, quoter } = paths[step];

  if (isQuoter()) return quoter;
  return normal;
};

export const getExtendPath = (step = 'one', uuid) => {
  const path = PATH_EXTEND_LIST + uuid + pathsExtend[step];
  return path;
};

export const isReservation = () =>
  window.location.href.includes('reservations');

export function isQuoter() {
  return window.location.href.includes('quoter');
}

export const isContractQuote = (strict = true) => {
  const isContractQuery = window.location.search.includes(QUOTE_CONTRACT_QUERY);

  const state = store.getState();
  const { isContract } = state.reservations.quote;

  if (strict) return isContract;

  return isContractQuery || isContract;
};

export const inUserActivation = () =>
  window.location.href.includes('activacion?token');

export const isLogin = () => window.location.href.includes('login');

export const notAuthPath = inUserActivation() || isLogin();
