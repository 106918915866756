import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { ProgressIndicator, Button, Icons } from '@casanova/casanova-common';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import { PreventChange } from 'components';
import { withReservationAuth } from '@hocs';
import i18n from '@i18n';
import { withForm, usePreventChange } from 'hooks';
import { WaitingModal, SendFirmLinkModal } from '@reservations/components';
import {
  isChangeOfVehicleFlow,
  isExtendOfContractFlow,
} from '@reservations/commons/utils';
import config, { AREA_OPTIONS } from './config';

/*
  TODO: Transfer logic of firm link to a component
*/

const PromissoryNote = ({
  maxSteps,
  step,
  title,
  subtitle,
  reservationContract,
  isCompleted,
  isSending,
  accepted,
  verifySignaturesCompleted,
  formUuid,
  summaryInfo,
  contractSendFormLink = {},
  history,
  rejectSignatures,
  acceptSignatures,
  reservationId,
  reservationInfo = {},
  getReservationForm,
  extendContract,
  finishExtendContract,
  loader,
}) => {
  const isChangeOfVehicle = useMemo(() => isChangeOfVehicleFlow(), []); // In normal flow is false

  const [showFirm, setShowFirm] = useState(isChangeOfVehicle); // In normal flow, show firm link only when user has accepted the contract
  const [showAccepted, setShowAccepted] = useState(false);
  const [hasAccepted, setHasAccepted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showModifyData, setShowModifyData] = useState(false);
  const [area, setArea] = useState(AREA_OPTIONS.USER);
  const [shouldPrevent, setShouldPrevent] = useStateWithCallbackLazy(true);

  const { data = {} } = reservationContract;
  const { success = false } = contractSendFormLink;
  const { naturalPerson = {} } = reservationInfo;
  const { promissoryNote = '' } = data;

  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    values,
  } = useFormikContext();
  const { email, cellphone } = values;

  const [preventChange] = usePreventChange();

  const isExtendContract = useMemo(() => isExtendOfContractFlow(), [
    isExtendOfContractFlow(),
  ]);

  const params = history.location.search;
  const isRejected = params.includes('rejected');

  const handleContinue = useCallback(() => {
    setShowFirm(true);
  }, []);

  const submit = useCallback(() => {
    setShowModifyData(false);
    setShowFirm(false);

    if (!values.cellphone && !values.email) {
      setFieldTouched('cellphone', true);
      setArea(AREA_OPTIONS.CELLPHONE);
      setShowModifyData(true);
      return;
    }

    handleSubmit(values);
  }, [values, setFieldTouched, handleSubmit, showFirm]);

  useEffect(() => {
    setShowSuccess(success);
  }, [success]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isRejected) {
      setShowFirm(true);
      setShowSuccess(false);

      // On change of vehicle flow, we need to show the firm link
    } else if (!isChangeOfVehicle) {
      setShowFirm(false);
    }
  }, [isChangeOfVehicle]);

  useEffect(() => {
    setFieldValue('email', summaryInfo.email || naturalPerson.email);
    setFieldValue(
      'cellphone',
      summaryInfo.cellphone || naturalPerson.cellphone
    );
  }, []);

  const handleSignaturesContinue = useCallback(() => {
    setShouldPrevent(false, () => {
      getReservationForm({
        reservation: reservationId,
        redirectPath: `/daily-rent-contracts/leasing/signatures${
          isExtendContract ? '?extend=true' : ''
        }`,
      });
    });
  }, [getReservationForm, reservationId]);

  const handleAcceptedSignatures = () => {
    if (isExtendContract) {
      setShouldPrevent(false, () => {
        finishExtendContract();
      });
    } else
      setShouldPrevent(false, () => {
        history.push(`/daily-rent-contracts/${reservationId}/delivery`);
      });
  };

  const handleOnReject = useCallback(() => {
    rejectSignatures({ form: formUuid });
  }, []);

  const handleOnAccept = useCallback(() => {
    acceptSignatures(formUuid);
    setHasAccepted(true);
  }, []);

  const handleCancelFirm = useCallback(() => {
    if (isChangeOfVehicle) {
      history.goBack();
    } else {
      setShowFirm(false);
    }
  }, [isChangeOfVehicle, history]);

  const handleCancelModifyData = useCallback(() => {
    setShowModifyData(false);
    if (!showSuccess) {
      setShowFirm(true);
    } else {
      setShowFirm(false);
    }
  }, [showSuccess]);

  const handleClickSubDescription = useCallback(() => {
    setShowFirm(false);
    setShowModifyData(true);
    setFieldValue('area', AREA_OPTIONS.EMAIL);
    setArea(AREA_OPTIONS.EMAIL);
  }, []);

  const handleAreaChange = useCallback(({ value }) => setArea(value), []);

  const handleActionSuccess = useCallback(() => {
    setShowSuccess(false);
    setShowModifyData(false);
    verifySignaturesCompleted(formUuid);
  }, [formUuid]);

  useEffect(() => {
    if (hasAccepted && accepted) {
      setShowAccepted(true);
    }
  }, [hasAccepted, accepted]);

  return (
    <>
      <PreventChange preventChange={shouldPrevent && !isCompleted} />
      <WaitingModal open={accepted === null && !isCompleted && isSending} />
      <ActionModal
        open={accepted === null && isCompleted && !isSending && !loader}
        actionLabel={i18n('COMMONS__CONTINUE__TEXT')}
        title={i18n('RESERVATIONS__CONTRACT__SIGNATURES_COMPLETE_MODAL__TITLE')}
        message={i18n(
          'RESERVATIONS__CONTRACT__SIGNATURES_COMPLETE_MODAL__BODY'
        )}
        icon={<Icons.IcoCarOk />}
        modalType="confirmation"
        onAction={handleSignaturesContinue}
      />
      <ActionModal
        open={showAccepted}
        actionLabel={i18n('COMMONS__CONTINUE__TEXT')}
        title={i18n('RESERVATIONS__CONTRACT__SIGNATURES_ACCEPTED_MODAL__TITLE')}
        message={i18n(
          'RESERVATIONS__CONTRACT__SIGNATURES_ACCEPTED_MODAL__BODY'
        )}
        icon={<Icons.IcoCarOk />}
        modalType="confirmation"
        onAction={handleAcceptedSignatures}
      />
      <SendFirmLinkModal
        showFirm={showFirm && !preventChange.active}
        showSuccess={showSuccess}
        showModifyData={showModifyData}
        showFirmInInitialRender={isRejected}
        email={email}
        cellphone={cellphone}
        area={area}
        onSubmit={submit}
        onActionSuccess={handleActionSuccess}
        onCancelFirm={handleCancelFirm}
        onCancelModifyData={handleCancelModifyData}
        onClickSubDescription={handleClickSubDescription}
        onAreaChange={handleAreaChange}
      />

      <div className="promissory-note">
        <div className="row">
          <div className="col-12 p-0 d-flex align-items-center">
            <ProgressIndicator
              size={120}
              stroke={['#e2e2e2', '#007aff']}
              strokeWidth={6}
              progress={(step / maxSteps) * 100}
              label={`${step} de ${maxSteps}`}
            />
            <div className="ml-4">
              <h3 className="text-primary">{title}</h3>
              <p>{subtitle}</p>
            </div>
          </div>
        </div>

        {promissoryNote && (
          <div className="pdf-viewer">
            <iframe
              title={i18n('RESERVATIONS__LEASING__STEPPER__TITLE')}
              src={promissoryNote}
              frameBorder="0"
            />
          </div>
        )}

        {step === 5 ? (
          <div className="row mt-4">
            <div className="col-6 col-lg-4 col-xl-2">
              <Button block outline onClick={handleOnReject}>
                {i18n('COMMONS__REJECT__TEXT')}
              </Button>
            </div>
            <div className="offset-3 offset-lg-6 offset-xl-8 col-6 col-lg-4 col-xl-2">
              <Button block onClick={handleOnAccept}>
                {i18n('COMMONS__ACCEPT__TEXT')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="row mt-4">
            <div className="col-2 offset-10">
              <Button block onClick={handleContinue}>
                {i18n('COMMONS__CONTINUE__TEXT')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

PromissoryNote.propTypes = {
  maxSteps: PropTypes.number,
  step: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PromissoryNote.defaultProps = {
  maxSteps: 5,
  step: 3,
  title: i18n('RESERVATIONS__PROMISSORY_NOTE__STEPPER__TITLE'),
  subtitle: i18n('RESERVATIONS__PROMISSORY_NOTE__STEPPER__SUBTITLE'),
};

export default withReservationAuth(withForm({ config })(PromissoryNote));
