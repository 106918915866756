import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { fetchCatalog } from 'store/commons/actions';
import {
  fetchCatalog as fetchVehicleCatalog,
  selectBrand,
  selectLine,
  getSpecs,
} from 'store/catalogs/actions';
import { cleanVehicleDetails } from 'store/vehicles/actions';
import VehicleDetail from './VehicleDetail';

export default compose(
  connect(
    (state) => {
      const linesBySelectedBrand = state.catalogs?.brandSelected?.lines;
      return {
        vehicleDetails: _get(state, 'vehicles.vehicleDetails.data', {}),
        transmissionTypeCatalog: _get(
          state,
          'catalogs.transmissionType.list',
          []
        ),
        brandSelected: state.catalogs.brandSelected,
        listBrands: state.catalogs.brand.list,
        listModels: state.catalogs.model.list,
        listColors: state.catalogs.color.list,
        listLines: state.catalogs.line.list,
        listVersions: state.catalogs.version.list,
        lineSelected: state.catalogs.lineSelected,
        linesBySelectedBrand,
      };
    },
    {
      fetchCatalog,
      selectBrand,
      selectLine,
      getSpecs,
      fetchVehicleCatalog,
      cleanVehicleDetails,
    }
  )
)(VehicleDetail);
