import { Icons, NumberInput } from '@casanova/casanova-common';
import { SpecItem } from '@reservations/components';
import { FIELD_TYPE } from './constants';

export const DisableIcons = [
  Icons.IcoDeleteGray,
  Icons.CheckUnavailable,
  Icons.ErrorBlack,
];

export const INPUT_COMPONENTS = {
  [FIELD_TYPE.NUMBER]: NumberInput,
  [FIELD_TYPE.BOOL]: SpecItem,
};
