import React, { useEffect, useState, useCallback, useMemo } from 'react';
import i18n from '@i18n';
import { useFormikContext } from 'formik';
import { Button, Icons } from '@casanova/casanova-common';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import { withForm } from 'hooks';
import { getExtraChargesTransformer } from '@reservations/commons/transformers';
import { DELIVERY_RETURN_TYPES } from '@reservations/commons/constants';
import { ExtraChargesInfo } from '@reservations/sections';
import { RESERVATION_STEP } from 'utils/constants/reservations';
import Details from '../Details';

const { RETURN, DELIVERY } = DELIVERY_RETURN_TYPES;
const { END } = RESERVATION_STEP;

export const Left = () => (
  <Button outline className="Left-button">
    Reenviar
  </Button>
);

function DeliveryReturnStepThree(props) {
  const {
    history,
    verificationCheckList = {},
    accepted,
    type = '',
    extraCharges,
    reservationInfo = {},
    resetSignatures,
    rejectSignatures,
    loader,
    isReadOnly,
  } = props;
  const [modalInfoCompleted, setModalInfoCompleted] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [withExtraCharges, setWithExtraCharges] = useState(null);

  const { form = {}, verificationGroups = [] } = verificationCheckList;
  const { success = false, listOfExtraCharges = [] } = extraCharges;
  const isReturn = useMemo(() => type === RETURN, [type]);
  const isDelivery = type === DELIVERY;
  const {
    uuid: reservationUuid,
    step: reservationStep,
    previousAssignedVehicle,
  } = reservationInfo;
  const isChangeVehicleFlow = Boolean(previousAssignedVehicle);

  const successModalConfig = {
    actionLabel: isChangeVehicleFlow ? 'Ir a la entrega' : 'Ir a reservaciones',
    title: isReturn ? '¡VEHÍCULO DEVUELTO!' : '¡VEHÍCULO ENTREGADO!',
    message: isReturn
      ? isChangeVehicleFlow
        ? `Se ha devuelto el vehículo anterior
    Ahora se procederá a la entrega del nuevo vehículo`
        : `Finalizó la renta del vehículo.
    ¡Gracias por confiar en nosotros!`
      : `La apertura del contrato y la entrega del vehículo
    fueron realizados con éxito.
    ¡Buen viaje!`,
  };

  const { setFieldValue, handleSubmit } = useFormikContext();

  const handleChangeModalInfoCompleted = useCallback(() => {
    setModalInfoCompleted(!modalInfoCompleted);
  }, [modalInfoCompleted]);

  const handleClickSuccess = useCallback(() => {
    if (isChangeVehicleFlow) {
      history.push(
        `/daily-rent-contracts/${reservationUuid}/delivery-vehicle-parts`
      );
    } else {
      history.push('/daily-rent-contracts/');
    }
  }, [history]);

  const handleSend = useCallback(() => {
    if (reservationStep === END && success) {
      setShowSuccess(true);
      return;
    }
    handleSubmit();
  }, [isDelivery, handleSubmit, reservationInfo, success]);

  const handleResend = useCallback(() => {
    const redirect = `${
      isReturn ? 'refund' : 'delivery'
    }-vehicle-parts?rejected=true`;
    rejectSignatures({ form: form.uuid, redirect });
  }, [rejectSignatures, form, isReturn]);

  useEffect(() => {
    if (form.uuid) {
      setFieldValue('formUuid', form.uuid);
    }
  }, [form]);

  useEffect(() => {
    setShowSuccess(accepted);
  }, [accepted]);

  useEffect(() => {
    if (isReturn) {
      // eslint-disable-next-line no-shadow
      const listExtraCharges = getExtraChargesTransformer(verificationGroups);
      setWithExtraCharges(listExtraCharges.length > 0 && !success);
    }
  }, [isReturn, success, verificationGroups]);

  useEffect(
    () => () => {
      resetSignatures();
    },
    [resetSignatures]
  );

  return (
    <div>
      <ActionModal
        open={!isReadOnly && modalInfoCompleted && !success}
        actionLabel={i18n('COMMONS__CONTINUE__TEXT')}
        title={i18n(
          'RESERVATIONS__DELIVERY__STEP_3_INFO_COMPLETE_MODAL__TITLE'
        )}
        message={i18n(
          isDelivery
            ? 'RESERVATIONS__DELIVERY__STEP_3_INFO_COMPLETE_MODAL__BODY'
            : 'RESERVATIONS__REFUND__STEP_3_INFO_COMPLETE_MODAL__BODY'
        )}
        icon={<Icons.IcoCarOk />}
        modalType="confirmation"
        onAction={handleChangeModalInfoCompleted}
      />

      <ActionModal
        open={showSuccess}
        actionLabel={successModalConfig.actionLabel}
        title={successModalConfig.title}
        message={successModalConfig.message}
        icon={<Icons.IcoCarOk />}
        modalType="confirmation"
        onAction={handleClickSuccess}
      />

      <Details {...props} />

      {!isReadOnly &&
        (withExtraCharges || listOfExtraCharges.length > 0 || success) && (
          <ExtraChargesInfo {...props} />
        )}

      {!isReadOnly && (
        <div className="row mt-4">
          {reservationStep !== END && (
            <div className="col-2 p-0 pr-1">
              <Button block outline onClick={handleResend}>
                Reenviar
              </Button>
            </div>
          )}

          <div className="col" />

          <div className="col-2 p-0 pl-1">
            <Button block onClick={handleSend}>
              {isDelivery ? 'Confirmar' : 'Devolución'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default (props) => {
  const Component = withForm({ config: props.config })(DeliveryReturnStepThree);
  const isReadOnly =
    props.history?.location?.pathname &&
    props.history.location.pathname.split('/').pop() === 'unit_inventory';
  return Component({ ...props, isReadOnly });
};
