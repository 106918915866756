export const LOANS_MODULE = 'LOANS_MODULE';

export const VIEW_LOANS = 'VIEW_LOANS';

export const CREATE_LOAN_REQUEST = 'CREATE_LOAN_REQUEST';

export const EDIT_ANY_LOAN_REQUEST = 'EDIT_ANY_LOAN_REQUEST';
export const EDIT_OWN_LOAN_REQUEST = 'EDIT_OWN_LOAN_REQUEST';
export const EDIT_STATUS_AUTHORIZE_LOAN_REQUEST =
  'EDIT_STATUS_AUTHORIZE_LOAN_REQUEST';
export const EDIT_STATUS_CANCELLED_ANY_LOAN_REQUEST =
  'EDIT_STATUS_CANCELLED_ANY_LOAN_REQUEST';
export const EDIT_STATUS_CANCELLED_OWN_LOAN_REQUEST =
  'EDIT_STATUS_CANCELLED_OWN_LOAN_REQUEST';
export const EDIT_STATUS_CLOSED_ANY_LOAN_REQUEST =
  'EDIT_STATUS_CLOSED_ANY_LOAN_REQUEST';
export const EDIT_STATUS_CLOSED_OWN_LOAN_REQUEST =
  'EDIT_STATUS_CLOSED_OWN_LOAN_REQUEST';
