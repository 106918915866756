import {
  sendRequest,
  sendCustomRequest,
} from '@casanova/casanova-common/lib/utils/service';
import fetchIntercept from 'fetch-intercept';
import { APIError } from 'utils/exceptions';
import { userRoot, vehicleRoot } from 'services/roots';
import { getToken, refresh, getDecodedToken, logout } from '../auth';
import * as mock from './mocks.json';
import {
  transformForSelect,
  transformSpecs,
} from '../../utils/transformHelpers';
import { NETWORK_STATUS } from '../../utils/constants/networkStatus';

// TODO: Fix utils navigation dependency cycle
const isLogin = window.location.href.includes('/login');
const isUserActivation = window.location.href.includes('/activacion');
const isOpenMarket = window.location.href.includes('/op');
var REACT_APP_API_URL = process.env.REACT_APP_API_URL;
var REACT_APP_API_INVOICE = process.env.REACT_APP_API_INVOICE;

const itIsNotAuthPath = () => isLogin || isUserActivation || isOpenMarket;

fetchIntercept.register({
  async request(url, config) {
    try {
      if (
        url &&
        (url.includes('login') || url.includes('refresh') || itIsNotAuthPath())
      ) {        
        return [url, config];
      }
      if(url && url.includes('setInvoice')) { 
        url = url.replace(new RegExp(REACT_APP_API_URL), REACT_APP_API_INVOICE);
        delete config.credentials;
        return [url, config];
      }
      if (getToken()) {
        const { exp } = getDecodedToken(getToken());
        if (
          exp * 1000 < Date.now() &&
          !url.includes('refresh') &&
          !url.includes('login')
        ) {
          const refreshed = await refresh(getToken());
          if (refreshed) {
            if (config) {
              config.headers.set('Authorization', `Bearer ${getToken()}`);
            }            
            return [url, config];
          }
          window.location.href = '/login';
        } else {
          if (config) {
            config.headers.set('Authorization', `Bearer ${getToken()}`);
          }
          return [url, config];
        }
      }
    } catch (error) {
      console.log('interceptor', error);
    }
  },
  response: async (response = {}) => {
    const notAuthorized = response.status === NETWORK_STATUS.NOT_AUTHORIZED;
    if (notAuthorized && !itIsNotAuthPath()) logout();
    return response;
  },
});

const useMocks = false;

export async function getDocsInvoice(folio, serie) {
  const url = `api/v1/invoice/setInvoice/get/document/${folio}/${serie}`;
  const response = await sendCustomRequest(url, {});
  return response;
}

export async function fetchCatalog(catalogId, options = {}) {
  const url = `vehicle/v1/catalog/${catalogId}`;
  const response = useMocks.getVehicles
    ? mock
    : await sendRequest(url, options);
  if (catalogId !== 'filter') {
    response.content = transformForSelect(response.content, 'uuid', 'name');
  }
  return response;
}

export async function fetchInstaller(query, options = {}) {
  const url = `vehicle/v1/catalog/gps?${query}`;
  const response = useMocks.getVehicles
    ? mock
    : await sendRequest(url, options);
  const transformed = transformForSelect(response.content, 'uuid', 'name');
  return { content: transformed };
}

export async function getVersionSpecs(version_uuid, model_uuid, options = {}) {
  const url = `vehicle/v1/catalog/versions/${version_uuid}/models/${model_uuid}/specs`;
  const response = await sendCustomRequest(url, options, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return transformSpecs(response.data.data);
}

export const getAreas = async (options) => {
  const endpoint = `${userRoot}/catalogs/areas`;
  const response = await sendRequest(endpoint, options);
  return response;
};

export const getRoles = async (options) => {
  const endpoint = `${userRoot}/catalogs/roles`;
  const res = await sendRequest(endpoint, options);
  return res;
};

export const getModules = async (options) => {
  const endpoint = `${userRoot}/catalogs/modules`;
  const res = await sendRequest(endpoint, options);
  return res;
};

export async function getRentalStatues(options = {}) {
  const url = `${vehicleRoot}/catalog/vehicle-statuses`;

  const response = await sendCustomRequest(url, options, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { data = {} } = response.data;
  return data;
}

export async function getRentalFilterV2Service(options = {}) {
  const url = `${vehicleRoot}/catalog/rental-filter-v2`;

  const response = await sendCustomRequest(url, options, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  const { data = [] } = response.data;
  return data;
}
