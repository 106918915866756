import _get from 'lodash/get';

export const companyDataTransformer = (companyData = {}) => ({
  companyName: companyData.name,
  companyRfc: companyData.rfc,
  companySocialReason: companyData.businessName,
  companyPhone: companyData.landline1,
  companyPhoneExt: companyData.ext1,
  companyPhoneTwo: companyData.landline2,
  companyPhoneTwoExt: companyData.ext2,
  companyConstituentAct: _get(
    companyData,
    'incorporationArticlesCopy.originalName'
  ),
  companyConstituentActUrl: _get(
    companyData,
    'incorporationArticlesCopy.presignedUrl'
  ),
  companyCompyRec: _get(companyData, 'rfcCopy.originalName'),
  companyCompyRecUrl: _get(companyData, 'rfcCopy.presignedUrl'),
  companyCouponFiscal: _get(companyData, 'taxId.originalName'),
  companyCouponFiscalUrl: _get(companyData, 'taxId.presignedUrl'),
  companyOpinionCompliance: _get(companyData, 'complianceOpinion.originalName'),
  companyOpinionComplianceUrl: _get(
    companyData,
    'complianceOpinion.presignedUrl'
  ),
  companyRequestVehicles: _get(companyData, 'vehicleRequest.originalName'),
  companyRequestVehiclesUrl: _get(companyData, 'vehicleRequest.presignedUrl'),
  companyPowerAttornery: _get(companyData, 'attorneyPowerCopy.originalName'),
  companyPowerAttorneryUrl: _get(companyData, 'attorneyPowerCopy.presignedUrl'),
  companyLetterAuthorization: _get(
    companyData,
    'authorizationLetter.originalName'
  ),
  companyLetterAuthorizationUrl: _get(
    companyData,
    'authorizationLetter.presignedUrl'
  ),
});

export const companyDataTenderTransformer = (companyData = {}) => ({
  companyName: companyData.name,
  companyRfc: companyData.rfc,
  companySocialReason: companyData.businessName,
  companyPhone: companyData.landline1,
  companyPhoneExt: companyData.ext1,
  companyPhoneTwo: companyData.landline2,
  companyPhoneTwoExt: companyData.ext2,
  companyCompyRec: _get(companyData, 'rfcCopy.originalName'),
  companyCompyRecUrl: _get(companyData, 'rfcCopy.presignedUrl'),
  companyCouponFiscal: _get(companyData, 'taxId.originalName'),
  companyCouponFiscalUrl: _get(companyData, 'taxId.presignedUrl'),
});
