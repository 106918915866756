import { useState } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import * as yup from 'yup';

import { withForm } from '@casanova/casanova-common';

import { verifyCheckPoint as verifyCheckPointSubmit } from 'store/reservations/actions';
import DeliveryReturnStepTwo from './DeliveryReturnStepTwo';

export default ({ config, ...props }) => {
  const [submitData, setSubmitData] = useState({});
  const [isCheckPointSubmit, setIsCheckPointSubmit] = useState(true);
  const [initialValues, setInitialValues] = useStateWithCallbackLazy(
    config.initialValues
  );
  const [initialTouched, setInitialTouched] = useStateWithCallbackLazy(
    config.initialTouched
  );
  const [schema, setSchema] = useStateWithCallbackLazy({});

  const submitForm = (values) =>
    isCheckPointSubmit
      ? verifyCheckPointSubmit(submitData)
      : config.submitForm(values);

  const Component = withForm({
    config: {
      ...config,
      submitForm,
      initialValues,
      initialTouched,
      schema: config.schema.concat(yup.object().shape(schema)),
      enableReinitialize: true,
    },
  })(DeliveryReturnStepTwo);

  return Component({
    ...props,
    setSubmitData,
    isCheckPointSubmit,
    setIsCheckPointSubmit,
    schema,
    setSchema,
    setInitialValues,
    setInitialTouched,
  });
};
