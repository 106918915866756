import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { reset as resetVehicles } from 'store/vehicles/actions';
import { reset as resetCustomers } from 'store/customers/actions';
import { resetReservations } from 'store/reservations/actions';
import { reset as resetLoans } from 'store/loans/actions';
import { reset as resetTransfers } from 'store/transfers/actions';
import { resetContracts } from 'store/contracts/actions';
import { reset as resetPaymentAndCharges } from 'store/payments/actions';

const getModulePath = (module) => {
  const PATHS_BY_MODULE = {
    vehicles: '/vehicle',
    customers: '/customers',
    reservations: '/reservations',
    loans: '/loans',
    transfers: '/transfers',
    contracts: '/contracts',
    paymentsAndCharges: '/payments-and-charges',
  };

  return PATHS_BY_MODULE[module];
};

const getResetActionByModule = (module, allWithoutModule = false) => {
  const RESET_ACTIONS_BY_MODULE = {
    vehicles: resetVehicles,
    customers: resetCustomers,
    reservations: resetReservations,
    loans: resetLoans,
    transfers: resetTransfers,
    contracts: resetContracts,
    paymentAndCharges: resetPaymentAndCharges,
  };

  if (allWithoutModule) {
    delete RESET_ACTIONS_BY_MODULE[module];
    return RESET_ACTIONS_BY_MODULE;
  }

  return RESET_ACTIONS_BY_MODULE[module];
};

export default function useCleanModule({ module = '', exceptions = [] } = {}) {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { pathname } = location;
    if (!pathname) return;

    return () => {
      const modulePath = getModulePath(module);
      const isModulePath = pathname.startsWith(modulePath);
      const resetCurrentModule = getResetActionByModule(module);

      const goesToAnotherModule = !window.location.pathname.startsWith(
        modulePath
      );

      if (isModulePath && resetCurrentModule && goesToAnotherModule) {
        dispatch(resetCurrentModule({ full: true }));
      }

      const allResetActions = getResetActionByModule(module, true);

      Object.entries(allResetActions)
        .filter(([moduleOfAction]) => !exceptions.includes(moduleOfAction))
        .forEach(([, resetModule]) => dispatch(resetModule({ full: true })));
    };
  }, [location, module]);
}
