import { call, put, select, all } from 'redux-saga/effects';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import _noop from 'lodash/noop';
import { push } from 'connected-react-router';
import { showLoader, hideLoader } from 'store/commons/actions';
import {
  getCustomerInfo,
  getSummaryInfo,
  saveCustomer,
  getCustomers,
  banCustomer,
  getCustomerFiles,
  getCustomerDetailXlsx,
  editCustomer,
  searchCustomers,
  searchEmployees,
  searchJuridicalCustomers,
  searchTenderCustomers,
  addBillingInfo,
  deleteBillingInfo,
  saveForeignerCustomer,
  saveLegalCustomer,
  saveLegalCustomerBase,
  saveTenderCustomer,
  saveTenderCustomerBase,
  fetchMovementTypes,
  getCustomerStatement,
  getCustomersListFilter,
  saveCustomerBase,
  saveForeignerCustomerBase,
  updateCustomerStatus,
  checkDebtsByNaturalPerson,
} from 'services/customers';
import { assignCustomer } from 'services/reservations';
import { validateCurp } from 'services/auth';
import {
  setCustomer,
  setCustomerNotPriceQuote,
} from 'store/reservations/actions';
import { CUSTOMER_TYPES } from '@customers/common/constants';
import { isContractQuote } from 'utils/navigation';
import { fetchCommonCatalogSaga } from 'store/commons/sagas';
import {
  getDetailFailed,
  getDetailSucceeded,
  getSummaryInfoSucceeded,
  getSummaryInfoFailed,
  saveCustomerSucceeded,
  saveCustomerFailed,
  getListSucceeded,
  getListFailed,
  editCustomerSucceeded,
  editCustomerFailed,
  banCustomerSucceded,
  banCustomerFailed,
  downloadFilesFailed,
  downloadXlsxFailed,
  searchSucceeded,
  searchFailed,
  validateCurpSucceeded,
  validateCurpFailed,
  validateEndorsementCurpSucceeded,
  validateEndorsementCurpFailed,
  validateCurpDriverSucceeded,
  validateCurpDriverFailed,
  addBillingInfoFailed,
  addBillingInfoSucceeded,
  deleteBillingInfoFailed,
  deleteBillingInfoSucceeded,
  saveCustomerForeignerSucceeded,
  saveCustomerForeignerFailed,
  searchEmployeesFailed,
  searchEmployeesSucceeded,
  searchJuridicalCustomerFailed,
  searchJuridicalCustomerSucceeded,
  searchTenderCustomerFailed,
  searchTenderCustomerSucceeded,
  saveAvalCustomerSucceeded,
  saveAvalCustomerFailed,
  hydrateCustomerEditionSuccess,
  hydrateCustomerEditionFailed,
  getStatementSuccess,
  getStatementFailed,
  searchJuridicalTenderCustomerSucceeded,
  searchJuridicalTenderCustomerFailed,
  getCustomersListFiltersSucceed,
  getCustomersListFiltersFailed,
  saveCustomerBaseInfoSucceeded,
  saveCustomerBaseInfoFailed,
  saveCustomerForeignerBaseSucceeded,
  saveCustomerForeignerBaseFailed,
  updateCustomerStatusSucceeded,
  updateCustomerStatusFailed,
} from './actions';

const isReservation = () => window.location.pathname.includes('reservations');

function* getCurrentCustomerUuid() {
  return yield select((state) =>
    _get(state, 'customers.customerDetail.data.uuid', '')
  );
}

export function* downloadXlsxSaga() {
  try {
    yield put(showLoader());
    const uuid = yield getCurrentCustomerUuid();
    yield call(getCustomerDetailXlsx, uuid);
  } catch (error) {
    yield put(downloadXlsxFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* downloadFilesSaga() {
  try {
    yield put(showLoader());
    const uuid = yield getCurrentCustomerUuid();
    yield call(getCustomerFiles, uuid);
  } catch (error) {
    yield put(downloadFilesFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* banCustomerSaga(action) {
  try {
    yield put(showLoader());
    const uuid = yield yield getCurrentCustomerUuid();
    const data = yield call(banCustomer, uuid, action.payload);
    yield put(banCustomerSucceded({ data }));
  } catch (error) {
    yield put(banCustomerFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getDetailSaga(action) {
  const { withLoader = true, controlled = true } = _isObject(action.payload)
    ? action.payload
    : {};

  function* fetchDetail() {
    let id = action.payload;
    let avalCustomer = false;
    let responseDialog = false;

    if (_isObject(action.payload)) {
      id = action.payload.id;
      avalCustomer = action.payload.avalCustomer;
      responseDialog = action.payload.responseDialog;
    }

    const data = yield call(getCustomerInfo, id);
    data.data.data.debts = yield call(checkDebtsByNaturalPerson, id);
    if (avalCustomer) {
      yield put(saveAvalCustomerSucceeded({ data, responseDialog }));
    } else {
      yield put(getDetailSucceeded({ data }));
    }
    return data;
  }

  if (controlled) {
    try {
      if (withLoader) yield put(showLoader());
      return yield fetchDetail();
    } catch (error) {
      yield put(getDetailFailed(error));
    } finally {
      if (withLoader) yield put(hideLoader());
    }
  } else {
    return yield fetchDetail();
  }
}

export function* getSummaryInfoSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getSummaryInfo, action.payload);
    yield put(getSummaryInfoSucceeded(data));
  } catch (error) {
    yield put(getSummaryInfoFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* editCustomerSaga(action) {
  try {
    yield put(showLoader());
    const uuid = yield getCurrentCustomerUuid();
    const data = yield call(editCustomer, action.payload, uuid);
    if (isReservation()) {
      const path = isContractQuote()
        ? '/reservations/contract-quote'
        : '/reservations/payment';

      yield put(push(path));
    } else {
      yield put(editCustomerSucceeded({ data }));
    }
  } catch (error) {
    yield put(editCustomerFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveCustomerSaga(action) {
  try {
    yield put(showLoader());
    const customerPersonType = yield select(
      (state) => state.customers.customerPersonType
    );
    const customerUuid = yield select((state) =>
      _get(state, 'customers.customerDetail.data.uuid', '')
    );
    const customerTenderType = yield select(
      (state) => state.customers.tenderType
    );

    if (customerUuid) action.payload.customerInfo.customerUuid = customerUuid;
    const isLegalCustomer = customerPersonType === CUSTOMER_TYPES.JURIDICAL;
    const isTenderCustomer = customerPersonType === CUSTOMER_TYPES.TENDER;
    if (isTenderCustomer && customerTenderType)
      action.payload.createTenderReq.tenderType = customerTenderType;

    const data = yield call(
      isLegalCustomer
        ? saveLegalCustomer
        : isTenderCustomer
        ? saveTenderCustomer
        : saveCustomer,
      action.payload
    );
    const customer = data.data.data.uuid;
    if (isReservation()) {
      if (isContractQuote()) {
        yield put(setCustomerNotPriceQuote(data.data.data));
        yield put(push('/reservations/contract-quote'));
      } else {
        const reservation = yield select((state) =>
          _get(state, 'reservations.reservation.id', '')
        );

        yield call(assignCustomer, { customer }, reservation);
        yield put(setCustomer(customer));
        yield put(push('/reservations/payment'));
      }
    } else {
      yield put(saveCustomerSucceeded({ data }));
    }
  } catch (error) {
    console.error(error);
    yield put(saveCustomerFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveCustomerBaseSaga(action) {
  try {
    yield put(showLoader());
    const customerPersonType = yield select(
      (state) => state.customers.customerPersonType
    );

    const isLegalCustomer = customerPersonType === CUSTOMER_TYPES.JURIDICAL;
    const isTenderCustomer = customerPersonType === CUSTOMER_TYPES.TENDER;
    // console.log(
    //   '🚀 ~ file: sagas.js:245 ~ function*saveCustomerBaseSaga ~ payload:',
    //   JSON.stringify(action.payload)
    // );

    const data = yield call(
      isLegalCustomer
        ? saveLegalCustomerBase
        : isTenderCustomer
        ? saveTenderCustomerBase
        : saveCustomerBase,
      action.payload
    );

    const customer = data?.data?.data?.uuid;

    if (isReservation()) {
      if (isContractQuote()) {
        yield put(setCustomerNotPriceQuote(data.data.data));
        yield put(push('/reservations/contract-quote'));
      } else {
        const reservation = yield select((state) =>
          _get(state, 'reservations.reservation.id', '')
        );

        yield call(assignCustomer, { customer }, reservation);
        yield put(setCustomer(customer));
        yield put(push('/reservations/payment'));
      }
    } else {
      yield put(saveCustomerBaseInfoSucceeded({}));
    }
  } catch (error) {
    console.error(error);
    yield put(saveCustomerBaseInfoFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getCustomersListSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getCustomers, action.payload);
    yield put(getListSucceeded({ data }));
  } catch (error) {
    yield put(getListFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* searchCustomersSaga(action) {
  try {
    const data = yield call(searchCustomers, action.payload);
    yield put(searchSucceeded({ data }));
  } catch (error) {
    yield put(searchFailed(error));
  }
}

export function* searchEmployeesSaga(action) {
  try {
    const data = yield call(searchEmployees, action.payload);
    yield put(searchEmployeesSucceeded(data));
  } catch (error) {
    yield put(searchEmployeesFailed(error));
  }
}

export function* searchJuridicalAndTenderCustomersSaga(action) {
  try {
    const dataJuridical = yield call(searchJuridicalCustomers, action.payload);
    const dataTender = yield call(searchTenderCustomers, action.payload);

    const dataCustomers = dataJuridical.concat(dataTender);

    const typeValue = action?.typeValue ? action.typeValue : 'juridicalUuid';

    yield put(
      searchJuridicalTenderCustomerSucceeded({ dataCustomers, typeValue })
    );
  } catch (error) {
    yield put(searchJuridicalTenderCustomerFailed(error));
  }
}

export function* searchJuridicalCustomersSaga(action) {
  try {
    const data = yield call(searchJuridicalCustomers, action.payload);
    const typeValue = action?.typeValue ? action.typeValue : 'juridicalUuid';
    yield put(searchJuridicalCustomerSucceeded({ data, typeValue }));
  } catch (error) {
    yield put(searchJuridicalCustomerFailed(error));
  }
}

export function* searchTenderCustomersSaga(action) {
  try {
    const data = yield call(searchTenderCustomers, action.payload);
    const typeValue = action?.typeValue ? action.typeValue : 'juridicalUuid';
    yield put(searchTenderCustomerSucceeded({ data, typeValue }));
  } catch (error) {
    yield put(searchTenderCustomerFailed(error));
  }
}

export function* validateCurpSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(validateCurp, action.payload);
    yield put(validateCurpSucceeded({ data }));
  } catch (error) {
    yield put(validateCurpFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* validateCurpEndorsementSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(validateCurp, action.payload);
    yield put(validateEndorsementCurpSucceeded({ data }));
  } catch (error) {
    yield put(validateEndorsementCurpFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* validateCurpDriversSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(validateCurp, action.payload);
    yield put(validateCurpDriverSucceeded({ data }));
  } catch (error) {
    yield put(validateCurpDriverFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* addBillingInfoSagas({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(addBillingInfo, payload);
    yield put(addBillingInfoSucceeded(data));
  } catch (error) {
    yield put(addBillingInfoFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* deleteBillingInfoSagas({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(deleteBillingInfo, payload);
    yield put(deleteBillingInfoSucceeded(data));
  } catch (error) {
    yield put(deleteBillingInfoFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveForeignerCustomerSaga(action) {
  try {
    yield put(showLoader());
    const customerUuid = yield select((state) =>
      _get(state, 'customers.customerDetail.data.uuid', '')
    );

    if (customerUuid)
      action.payload.foreignCustomerInfo.customerUuid = customerUuid;
    const data = yield call(saveForeignerCustomer, action.payload);
    const customer = data.data.data.uuid;
    if (isReservation()) {
      if (isContractQuote()) {
        yield put(setCustomerNotPriceQuote(data.data.data));
        yield put(push('/reservations/contract-quote'));
      } else {
        const reservation = yield select((state) =>
          _get(state, 'reservations.reservation.id', '')
        );

        yield call(assignCustomer, { customer }, reservation);
        yield put(setCustomer(customer));
        yield put(push('/reservations/payment'));
      }
    } else {
      yield put(saveCustomerForeignerSucceeded({ data }));
    }
  } catch (error) {
    console.error(error);
    yield put(saveCustomerForeignerFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveForeignerCustomerBaseSaga(action) {
  try {
    yield put(showLoader());
    action.payload.foreignCustomerInfo.isCreatedFromContactCenter = true;
    const data = yield call(saveForeignerCustomerBase, action.payload);
    const customer = data?.data?.data?.uuid;
    if (isReservation()) {
      if (isContractQuote()) {
        yield put(setCustomerNotPriceQuote(data.data.data));
        yield put(push('/reservations/contract-quote'));
      } else {
        const reservation = yield select((state) =>
          _get(state, 'reservations.reservation.id', '')
        );

        yield call(assignCustomer, { customer }, reservation);
        yield put(setCustomer(customer));
        yield put(push('/reservations/payment'));
      }
    } else {
      yield put(saveCustomerForeignerBaseSucceeded({ data }));
    }
  } catch (error) {
    console.error(error);
    yield put(saveCustomerForeignerBaseFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveAvalCustomerSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(saveCustomer, action.payload);
    const customerUuid = data.data.data.uuid;

    // This is responsible of save the customer successfully
    yield getDetailSaga({
      payload: { id: customerUuid, avalCustomer: true, responseDialog: true },
    });
  } catch (error) {
    yield put(saveAvalCustomerFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* fetchAllCustomerCatalogs({ payload = {} }) {
  const { controlled = true } = payload;

  function getCatalogsToFetch() {
    const CATALOGS_TO_FETCH = [
      'states',
      {
        catalogId: 'sexes/search/byActive',
        catalogPath: 'catalog',
        catalogName: 'sexes',
      },
      'municipalities',
      'nationalities',
      'countries',
      {
        catalogId: 'identityDocumentTypeEntities',
        catalogPath: 'customer',
      },
      {
        catalogId: 'driverLicenseTypeEntities',
        catalogPath: 'customer',
      },
      {
        catalogId: 'documentStatusEntities',
        catalogPath: 'customer',
      },
      {
        catalogId: 'proofAddressTypeEntities',
        catalogPath: 'customer',
      },
      { catalogId: 'catalogs/cfdi-uses', catalogPath: 'pricequotes' },
      {
        catalogId: 'accommodationTypeEntities',
        catalogPath: 'customer',
      },
      {
        catalogId: 'countryEntranceTypeEntities',
        catalogPath: 'customer',
      },
    ];

    // eslint-disable-next-line no-shadow
    return CATALOGS_TO_FETCH.map((payload) =>
      fetchCommonCatalogSaga({ payload })
    );
  }

  if (controlled) {
    try {
      yield all(getCatalogsToFetch());
    } catch (error) {
      console.error(error);
    }
  } else {
    yield all(getCatalogsToFetch());
  }
}

export function* hydrateCustomerEditionSaga({ payload }) {
  try {
    yield put(showLoader());
    const { id, hydrateForm, onHydrate = _noop } = payload;

    const commonPayload = { withLoader: false, controlled: false };

    const customerData = yield getDetailSaga({
      payload: { id, ...commonPayload },
    });

    yield fetchAllCustomerCatalogs({
      payload: commonPayload,
    });

    hydrateForm(customerData.data);

    yield put(hydrateCustomerEditionSuccess({ hydrated: true }));

    onHydrate(customerData.data);
  } catch (error) {
    yield put(hydrateCustomerEditionFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getStatementSaga({ payload }) {
  try {
    yield put(showLoader());
    const { uuid, onSuccess = _noop } = payload;
    let movementTypes = yield select(
      (state) => state.customers.statement.movementTypes
    );

    if (movementTypes.length === 0) {
      movementTypes = yield call(fetchMovementTypes);
    }

    const statementData = yield call(getCustomerStatement, uuid);
    yield put(getStatementSuccess({ statementData, movementTypes }));
    onSuccess();
  } catch (error) {
    yield put(getStatementFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getCustomersListFiltersSagas() {
  try {
    yield put(showLoader());
    const data = yield call(getCustomersListFilter);
    yield put(getCustomersListFiltersSucceed(data));
  } catch (error) {
    yield put(getCustomersListFiltersFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* updateCustomerStatusSaga(action) {
  try {
    yield put(showLoader());
    const customerUuid = yield select(
      (state) => state.customers.customerDetail.data.uuid
    );
    const body = {
      naturalPersonUuid: customerUuid,
      customerStatusUuid: action.payload.customerStatusState,
      banReason: action.payload.reasonNewsletter,
    };
    yield call(updateCustomerStatus, body);
    yield put(updateCustomerStatusSucceeded(action.payload));
  } catch (error) {
    console.error(error);
    yield put(updateCustomerStatusFailed(error));
  } finally {
    yield put(hideLoader());
  }
}
