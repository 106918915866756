import moment from 'moment-timezone';
import 'moment/locale/es';
import { YYYY_MM_DD, timeZone } from 'utils/constants';
import _get from 'lodash/get';

export const transferInformationFormatter = (values) => {
  const {
    plate,
    observations,
    destination,
    source,
    requester,
    driver,
    departureDate,
    departureTime,
    departureRealDate,
    departureRealTime,
    returnRealDate,
    arrivalRealTime,
    arrivalTime,
    licenseNumber,
    validityDriverLicense,
    returnDate: arrivalDate,
  } = values;

  return {
    departureDate: moment(departureDate, YYYY_MM_DD)
      .tz(timeZone)
      .format(YYYY_MM_DD),
    departureTime,
    arrivalTime: arrivalTime || null,
    arrivalDate,
    departureRealDate:
      departureRealDate.length != 0
        ? moment(departureRealDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
    departureRealTime,
    returnRealDate:
      returnRealDate.length != 0
        ? moment(returnRealDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
    arrivalRealTime,
    departureWarehouse: source,
    destinyWarehouse: destination,
    driverName: driver,
    observations: observations || null,
    requester,
    vehicle: plate,
    licenseNumber,
    validityDate: moment(validityDriverLicense, YYYY_MM_DD)
      .tz(timeZone)
      .format(YYYY_MM_DD),
    issuingStateUuid: _get(values, 'emissionState', ''),
  };
};
