import i18n from '@i18n';
import { CellText, CellStatus } from 'components/ColumnsCells';
import { VEHICLE_STATUS_FIELDS as FIELDS } from '@vehicles/common/constants';

export const columns = [
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_ESTATUS__TABLE__USER'),
    field: FIELDS.USER,
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_ESTATUS__TABLE__STATUS_DATE'),
    field: FIELDS.STATUS_DATE,
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_ESTATUS__TABLE__ESTATUS'),
    field: FIELDS.STATUS,
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_ESTATUS__TABLE__ACTIVE'),
    field: FIELDS.ACTIVE,
    align: 'center',
    renderer: CellStatus,
  },
];
