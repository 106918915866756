import moment from 'moment';
import { YYYY_MM_DD, timeZone } from '@casanova/casanova-common/lib/utils/date';

export const formatter = (values) => {
  const formatterValues = {
    reason: values.reason,
    startDate: moment(values.startDate, YYYY_MM_DD)
      .tz(timeZone)
      .format(YYYY_MM_DD),
    endDate: moment(values.endDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD),
    vehicleA: values.vehicleA.vehicleUuid || values.currentVehicle,
    vehicleB: values.vehicleB.uuid,
    remarks: values.remarks,
    uuid: values.uuid,
  };
  return formatterValues;
};
