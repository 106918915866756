import { saveNotPriceQuote } from 'store/reservations/actions';
import i18n from '@i18n';
import {
  transformer as reservationDateTransformer,
  reservationDatesContractQuoteInitialValues,
  reservationDatesContractQuoteInitialTouched,
} from '@reservations/sections/ReservationDates/config';
import {
  initialValues as vehicleAssignmentInitialValues,
  initialTouched as vehicleAssignmentInitialTouched,
} from '@reservations/sections/VehicleAssignment/config';
import { formatter } from './formatter';
import { schema } from './schema';

const initialTouched = {
  customer: true,
  customer_search: true,
  withInvalidDocuments: true,
  withoutDocuments: true,
  personType: true,
  ...reservationDatesContractQuoteInitialTouched,
  ...vehicleAssignmentInitialTouched,
};

const initialValues = {
  customer: '',
  customer_search: '',
  withInvalidDocuments: false,
  withoutDocuments: false,
  personType: undefined,
  ...reservationDatesContractQuoteInitialValues,
  ...vehicleAssignmentInitialValues,
};

const transformer = ({ notPriceQuoteValues }) => ({
  ...initialValues,
  ...reservationDateTransformer(notPriceQuoteValues),
});

export const config = {
  schema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  alwaysSetTemporalValues: true,
  withInitialValues: true,
  formatter,
  transformer,
  submitForm: saveNotPriceQuote,
  btnSubmitLabel: i18n('COMMONS__CONTINUE__TEXT'),
  dialogs: {
    confirmation: {},
    response: {
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
    },
  },
};
