import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _get from 'lodash/get';
import { routesDefinitions } from 'utils/menu';
import { names } from './actions';

export function* reconcileNavSaga({ payload }) {
  const { item, subItem } = payload;
  const generalSpec = routesDefinitions[item];
  const spec = _get(generalSpec, `children.${subItem}`);

  if (spec) {
    yield put({
      type: names.setActiveRoute,
      payload: {
        general: generalSpec,
        specific: spec,
      },
    });
  }
}

export function* navigateSaga({ payload }) {
  const { subItem, item, params } = payload;
  const generalSpec = routesDefinitions[item];
  const spec = _get(generalSpec, `children.${subItem}`);
  if (spec) {
    const path =
      typeof spec.format === 'function'
        ? spec.format(params)
        : `${generalSpec.route}/${spec.route}` || generalSpec.route;
    yield put(push(path));
    yield put({
      type: names.setActiveRoute,
      payload: {
        general: generalSpec,
        specific: spec,
      },
    });
  }
}
