import React, { useCallback, useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import {
  TableNavigation,
  Table,
  withForm,
  ActionModal,
  Icons,
  MaskedInput as Input,
} from '@casanova/casanova-common';
import { FormModal } from 'components';
import useCleanModule from 'hooks/useCleanModule';
import { config } from './config';
import columns, { contextualMenu, getContextualMenu } from './columns';
import { transformDropdownElements } from 'utils/transformHelpers';
import { SHOW_DROPDOWN_MODE } from '@casanova/casanova-common/lib/utils/constants/index';
import i18n from '@casanova/casanova-common/lib/utils/i18n';
import { NOT_VIEW_RESERVATION_DETAILS } from 'utils/roles/permissions/reservationsPermissions';
import { validateRolePermissions } from 'utils/roles';
import SearchableSelect from 'components/SercheableSelect';
import { lettersAndNumbersWithSpaces } from '../../../utils/masks.js';
import { ModifyReservationModal } from '../components';

function ListOfReservations({
  resetCustomers,
  getDetail,
  search,
  history,
  listOfReservations,
  setListOfReservationsPage,
  setInitialParamsListOfReservations,
  getListOfReservations,
  getReservationInfo,
  getReservationListFilters,
  hydrateChangeOfVehicleFlow,
  resetReservations,
  onUpdateForm,
  cancelReservation,
  getVehicleTypes,
}) {
  const { params, searchPattern, filters, results } = listOfReservations;

  const [
    showConfirmCancelReservation,
    setShowConfirmCancelReservation,
  ] = useState(false);
  const [reservationUuid, setReservationUuid] = useState('');

  useCleanModule({ module: 'reservations' });

  const handleRowClick = useCallback(
    ({ uuid, changeRequest, ...reservation }) => {
      if (changeRequest) {
        if (validateRolePermissions(NOT_VIEW_RESERVATION_DETAILS)) return;
        hydrateChangeOfVehicleFlow({
          reservationUuid: uuid,
          changeRequestUuid: changeRequest,
        });
      } else {
        if (validateRolePermissions(NOT_VIEW_RESERVATION_DETAILS)) return;
        getReservationInfo({ uuid, ...reservation });
      }
    },
    [history, getReservationInfo, hydrateChangeOfVehicleFlow]
  );

  useEffect(() => {
    if (params.tableSelected == 'RESERVATION')
      getListOfReservations({ params, filters, searchPattern });
  }, [getListOfReservations, params, filters, searchPattern]);

  useEffect(() => {
    resetReservations({ exceptions: ['listOfReservations'] });
    setInitialParamsListOfReservations('RESERVATION');
    getReservationListFilters({ tableSelected: 'RESERVATION' });
    getVehicleTypes();
  }, []);

  useEffect(() => {
    if (!_isEmpty(params)) {
      onUpdateForm(params);
    }
  }, [onUpdateForm]);

  const handleDropdownSelect = (currentReservation) => {
    const { name, uuid } = currentReservation;

    switch (name) {
      case contextualMenu.CANCEL_RESERVATION.name:
        setReservationUuid(uuid);
        setShowConfirmCancelReservation(true);
        break;

      case contextualMenu.MODIFY_RESERVATION.name:
        getReservationInfo({
          uuid,
          redirect: false,
          isUpdateReservationModalOpen: true,
        });
        break;
    }
  };

  const handleConfirmCancelReservation = () => {
    setShowConfirmCancelReservation(false);
    cancelReservation(reservationUuid);
  };

  const handleOnClose = () => {
    setShowConfirmCancelReservation(false);
    setReservationUuid('');
  };

  return (
    <div>
      <ModifyReservationModal />
      <ActionModal
        open={showConfirmCancelReservation}
        icon={<Icons.StatusWarning />}
        onClose={handleOnClose}
        onAction={handleConfirmCancelReservation}
        title="Cancelar Reservación"
        message="¿Desea cancelar la reservación?"
        actionLabel={i18n('COMMONS__YES_CONTINUE__TEXT')}
        closeButton
      />
      <Table
        additionalHeader
        rowClassName="row-clickable"
        rowClick={handleRowClick}
        emptyMessage="No se encontraron resultados"
        dataList={results.list}
        columns={columns.map((col) => ({
          ...col,
          renderer: (props = { data: {} }) => {
            const Component = col.renderer;
            return (
              <Component
                {...props}
                dropdownOptions={transformDropdownElements({
                  elements: getContextualMenu(),
                })}
              />
            );
          },
        }))}
        dropdownCell
        showDropdownMode={SHOW_DROPDOWN_MODE.POINTS_CLICK}
        onDropdownSelect={handleDropdownSelect}
      />
      <div className="row">
        <div className="col-12 col-xl-7 table-paginator text-right d-flex justify-content-between align-items-center">
          <TableNavigation
            search={listOfReservations}
            onChange={setListOfReservationsPage}
          />
        </div>
        <div className="col-12 col-md-8 col-xl-5" />
      </div>
    </div>
  );
}

export default withForm({ config })(ListOfReservations);
