import React, { useEffect, useState, useCallback } from 'react';
import { Formik } from 'formik';
import {
  AuthContainer,
  PasswordInput,
  Button,
} from '@casanova/casanova-common';
import useQuery from 'hooks/useQuery';
import { activationSchema, activationInitialValues } from './config';
import { ACTIVATION_STATUS } from './constants';
import './styles.scss';

const { WELCOME, TOKEN_ERROR, PASSWORD_CREATED } = ACTIVATION_STATUS;

export default function Activation({
  history,
  verifyValidToken,
  verifyToken,
  userActivation,
  postUserActivation,
}) {
  const [status, setStatus] = useState({
    loading: true,
    error: false,
    title: '',
    subtitle: '',
    success: false,
  });

  const query = useQuery();

  const handleFormik = useCallback(
    (values) => {
      const { password } = values;
      setStatus({ ...status, loading: true, error: false });
      postUserActivation({ password });
    },
    [postUserActivation]
  );

  const redirectToLogin = useCallback(() => {
    history.replace('/login');
  }, [history]);

  useEffect(() => {
    const token = query.get('token');
    if (token) {
      verifyValidToken({ token });
    } else redirectToLogin();
  }, [verifyValidToken]);

  useEffect(() => {
    const { data, error } = verifyToken;
    if (data && data.token) {
      setStatus({
        ...WELCOME,
        loading: false,
        success: false,
        error: false,
      });
    } else if (error) {
      setStatus({
        ...TOKEN_ERROR,
        loading: false,
        success: false,
        error: true,
      });
    }
  }, [verifyToken]);

  useEffect(() => {
    const { data } = userActivation;
    if (data && data.success) {
      setStatus({
        ...PASSWORD_CREATED,
        loading: false,
        success: true,
        error: false,
      });
    }
  }, [userActivation]);

  const renderSection = () => {
    if (status.error) {
      return (
        <div className="row single-action-container">
          <div className="col-12">
            <Button block onClick={redirectToLogin}>
              Aceptar
            </Button>
          </div>
        </div>
      );
    }
    if (!status.error && !status.success) {
      return (
        <Formik
          initialValues={activationInitialValues}
          validationSchema={activationSchema}
          validateOnBlur
          onSubmit={handleFormik}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <PasswordInput
                  name="password"
                  label="Nueva contraseña"
                  onlyError
                  displayError
                />
                <p className="mt-1 text-left text-muted input-roules">
                  Debe contener mínimo 8 dígitos, una letra mayúscula, una
                  minúscula, un número.
                </p>
              </div>

              <PasswordInput
                name="passwordConfirmation"
                label="Confirmar contraseña"
                onlyError
                displayError
              />
              <div className="row">
                <div className="col-12">
                  <Button block className="mt-3">
                    Crear contraseña
                  </Button>
                </div>
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-block text-secondary"
                    onClick={redirectToLogin}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      );
    }
    if (status.success && !status.error) {
      return (
        <div className="row single-action-container">
          <div className="col-12">
            <Button block className="mt-3" onClick={redirectToLogin}>
              Continuar
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  return <AuthContainer {...status}>{renderSection()}</AuthContainer>;
}
