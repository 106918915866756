import {
  VIEW_VEHICLE_GPS_ORDERS,
  CREATE_VEHICLE_GPS_ORDERS,
} from 'utils/roles/permissions';
import Gps from './Gps';
import { withConnectedAdditional } from '../AdditionalCollapse';

export default withConnectedAdditional({
  identifierForView: VIEW_VEHICLE_GPS_ORDERS,
  identifierForAdd: CREATE_VEHICLE_GPS_ORDERS,
})(Gps);
