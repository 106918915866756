export const CONTRACTS_RD_MODULE = 'CONTRACTS_RD_MODULE';

export const CONTRACTS_RD = 'CONTRACTS_RD';
export const VIEW_CONTRACTS_RD = 'VIEW_CONTRACTS_RD';
export const CREATE_CONTRACT_RD = 'CREATE_CONTRACT_RD';
export const EXTEND_CONTRACT_RD = 'EXTEND_CONTRACT_RD';
export const CLOSE_CONTRACT_RD = 'CLOSE_CONTRACT_RD';
export const REFUND_CONTRACT_RD = 'REFUND_CONTRACT_RD';
export const VEHICLE_CONTRACT_RD = 'VEHICLE_CONTRACT_RD';
export const NOTE_CONTRACT_RD = 'NOTE_CONTRACT_RD';
export const VIEW_ONLY_CONTRACTS_RD = 'VIEW_ONLY_CONTRACTS_RD';
export const VIEW_ONLY_RESERVATIONS_RD = 'VIEW_ONLY_RESERVATIONS_RD';
export const VIEW_REPORTS_CONTACT_CENTER = 'VIEW_REPORTS_CONTACT_CENTER';
