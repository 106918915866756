import { takeEvery } from 'redux-saga/effects';
import {
  CATALOG_FETCH_REQUESTED,
  GET_SUBURBS_BY_ZIPCODE,
  GET_TAX_ZIPCODE,
} from './action-types';
import {
  fetchCommonCatalogSaga,
  getSuburbsByZipCodeSaga,
  getTaxZipCodeSaga,
} from './sagas';

export default function* watchers() {
  yield takeEvery(CATALOG_FETCH_REQUESTED, fetchCommonCatalogSaga);
  yield takeEvery(GET_SUBURBS_BY_ZIPCODE, getSuburbsByZipCodeSaga);
  yield takeEvery(GET_TAX_ZIPCODE, getTaxZipCodeSaga);
}
