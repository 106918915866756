import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  ProgressIndicator,
  Icons,
  MaskedInput as Input,
  withForm,
  NumberInput,
  Checkbox,
  Button,
  Loader,
  Modal,
} from '@casanova/casanova-common';
import { Section, Card, ConnectedSelect, PreventChange } from 'components';
import {
  CardMethod,
  DepositMethod,
  CashMethod,
  TransferMethod,
  InvoicePayment,
} from '@reservations/sections';

import {
  alphaMaskWithSpacesAndAccent,
  numericMask,
  cardValidityMask,
  cardNumberValidations,
} from 'utils/masks';
import { number2mxn } from 'utils/currency';
import i18n from '@i18n';
import { getQuotePath } from 'utils/navigation';
import { isExtendOfContractFlow } from '@reservations/commons/utils';

import { scrollTop } from 'utils/screen';
import config from './config';
import './Payment.scss';
import { options } from '@casanova/casanova-common/lib/Loader/code';
import ResponseDialog from 'components/ResponseDialog';

let fetchCatalogGlobal;

const Payment = ({
  maxSteps,
  step,
  title,
  subtitle,
  fetchCatalog,
  reservationId,
  getCustomer,
  quoteValues,
  submitted,
  onUpdateForm,
  setNewInvoicePayment,
  setHasAddedBillingDetailsOfPayment,
  customerJuridical,
  priceVehicleRent,
  withoutPayment,
  responseDialogReservationVehicle,
  history,
}) => {
  if (!quoteValues.quoteId) {
    window.location.href = getQuotePath('list');
  }

  const buttonGuardarBancoStyle = {
    backgroundColor: '#450E99',
    color: 'white',
    padding: '15px 32px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    marginTop: '10px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '4px',
  };

  const [hasUpdatedHoldBack, setHasUpdatedHoldBack] = useState(false);
  const [hasTotalPay, setHasTotalPay] = useState(0);
  const [otroMonto, setOtroMonto] = useState(false);
  const [otroBanco, setOtroBanco] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [show, setShow] = useState(false);
  // const [hide, setHide] = useState(false);
  const [titleModalBancoNuevo, setTitleModalBancoNuevo] = useState('');
  const [subtitleModalBancoNuevo, setSubtitleModalBancoNuevo] = useState('');

  const { errors, touched, setFieldValue, values } = useFormikContext();

  const isExtendContract = useMemo(() => isExtendOfContractFlow(), [
    isExtendOfContractFlow(),
  ]);

  // const { REACT_APP_API_URL } = process.env;

  const isJuridical = Boolean(customerJuridical);

  const [hasUpdateDifMetods, setHasUpdateDifMetods] = useState(false);

  // const typeOfPerson = isJuridical ? IS_JURIDICAL : IS_NATURAL;

  const handleMethodChange = useCallback(() => {
    if (hasUpdatedHoldBack) {
      setFieldValue('holdbackCard', '');
      setFieldValue('holdbackBank', '');
    }
  }, [hasUpdatedHoldBack]);

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/banks',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-fiscal-regime/natural-person',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-fiscal-regime/juridical-person',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/bank-card-types',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/payment-forms',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/payment-methods',
      catalogPath: 'pricequotes',
    });

    fetchCatalog('branches');
    getCustomer(quoteValues.customer);
    setFieldValue('reservationId', reservationId);
    fetchCatalogGlobal = fetchCatalog;
  }, []);

  useEffect(() => {
    actualizarSelectBancos();
  }, []);

  useEffect(() => {
    if (values.invoiceTaxRegime !== '') {
      fetchCatalog({
        catalogId: 'catalogs/cfdi-uses/fiscal-regime',
        params: {
          uuid: values.invoiceTaxRegime,
          isJuridical,
        },
        catalogPath: 'pricequotes',
      });
    }
  }, [values.invoiceTaxRegime]);

  useEffect(() => {
    scrollTop();
    // Reset added billing details flag
    setHasAddedBillingDetailsOfPayment(false);

    return () => {
      setNewInvoicePayment(false);
      setHasAddedBillingDetailsOfPayment(false);
    };
  }, []);

  useEffect(() => {
    const sumAmounts = sumaTotal();
    setFieldValue('totalPriceRent', priceVehicleRent);
    setHasTotalPay(sumAmounts);
    if (sumAmounts === priceVehicleRent) {
      // values.priceIsInvalid = true;
      setFieldValue('priceIsInvalid', true);
    } else {
      // values.priceIsInvalid = false;
      setFieldValue('priceIsInvalid', false);
    }
  }, [
    values.amountCard_0,
    values.amountCard_1,
    values.amountCard_2,
    values.amountCash,
    values.amountTransfer,
    values.amountDeposit,
  ]);

  function sumaTotal() {
    const total =
      Number(values.amountCard_0) +
      Number(values.amountCard_1) +
      Number(values.amountCard_2) +
      Number(values.amountCash) +
      Number(values.amountDeposit) +
      Number(values.amountTransfer);

    return total;
  }

  useEffect(() => {
    setFieldValue('diffMetods', hasUpdateDifMetods);
  }, [hasUpdateDifMetods]);

  const handleChange = (e) => {
    if (e.target.value === 'otro') {
      setOtroMonto(true);
      return;
    }
    setOtroMonto(false);
  };

  const handleChangeBanco = (e) => {
    setSelectedBank(e.target.value);
    if (e.target.value === 'c1f56866-0904-499b-a3fe-1b70a3e207b3') {
      setOtroBanco(true);
      return;
    }
    setOtroBanco(false);
  };

  const handleAddBank = async (newBank, options = {}) => {
    let uuidNuevoBanco = '';
    try {
      const headers = options.headers || new Headers();

      const { authToken } = options;
      if (authToken) {
        headers.append('Authorization', `Bearer ${authToken}`);
        headers.append('Content-Type', 'application/json');
      }
      headers.append('Content-Type', 'application/json');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/pricequotes/v1/catalogs/banks`,
        {
          method: 'POST',
          body: JSON.stringify(newBank),
          headers: headers,
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      fetchCatalog({
        catalogId: 'catalogs/banks',
        catalogPath: 'pricequotes',
      });
      uuidNuevoBanco = data.uuid;
      setSelectedBank(data.uuid);
      setShowLoader(false);
      setTitleModalBancoNuevo('Banco agregado');
      setSubtitleModalBancoNuevo('Selecciona el banco que has agregado.');
      setShow(true);
    } catch (error) {
      setShowLoader(false);
      setTitleModalBancoNuevo('Error');
      setSubtitleModalBancoNuevo(
        'El banco que intentas agregar ya existe o vuelve a intentarlo más tarde.'
      );
      setShow(true);
    }
    setFieldValue('holdbackBank', uuidNuevoBanco);
  };

  const handleSaveNewBank = () => {
    setShowLoader(true);
    setOtroBanco(false);
    const newBank = {
      name: values.holdbackOtherBankName,
      legalName: values.holdbackOtherBankName,
    };
    handleAddBank(newBank, options);
  };

  const handleCloseModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const handleDifMetodsChange = () => {
    if (hasUpdateDifMetods) {
      setHasUpdateDifMetods(false);
      setHasTotalPay(0);
      values.amountCard_0 = '';
      values.amountCard_1 = '';
      values.amountCard_2 = '';
      values.amountCash = '';
      values.amountTransfer = '';
      values.amountDeposit = '';
      values.methodSelected = '';
      return;
    }
    setHasUpdateDifMetods(true);
  };

  const handleOnCloseReservationVehicle = () => {
    history.push('/daily-rent-contracts/contract');
  };

  return (
    <PreventChange preventChange={!submitted && !withoutPayment}>
      {showLoader && <Loader show />}
      <Modal
        open={show}
        header={null}
        footer={null}
        className="ViewRefundDetail"
        contentClassName="px-4 py-4 container"
        closeButton
        onClose={handleCloseModal}
        bigModal
      >
        <div className="ml-4">
          <h3 className="text-primary">{titleModalBancoNuevo}</h3>
          <p>{subtitleModalBancoNuevo}</p>
        </div>
      </Modal>
      <ResponseDialog
        open={responseDialogReservationVehicle.open}
        success={responseDialogReservationVehicle.success}
        errorTitle={i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE')}
        errorLabel={i18n('COMMONS__CLOSE__TEXT')}
        errorMessage={responseDialogReservationVehicle.message}
        errorCode={responseDialogReservationVehicle.errorCode}
        onError={handleOnCloseReservationVehicle}
        onClose={handleOnCloseReservationVehicle}
      />
      <div className="payment-page">
        {!isExtendContract && (
          <>
            <div className="row">
              <div className="col-12 p-0 d-flex align-items-center">
                <ProgressIndicator
                  size={120}
                  stroke={['#e2e2e2', '#007aff']}
                  strokeWidth={6}
                  progress={(step / maxSteps) * 100}
                  label={`${step} de ${maxSteps}`}
                />
                <div className="ml-4">
                  <h3 className="text-primary">{title}</h3>
                  <p>{subtitle}</p>
                </div>
              </div>
            </div>
            <hr className="mt-4" width="100%" />
          </>
        )}
        <div className="payment-page__main">
          <div className="payment-page__main__title my-4 d-flex justify-content-between align-items-center">
            <p>Método de pago</p>
            {hasUpdateDifMetods && <p>{number2mxn(hasTotalPay)}</p>}
            <div>
              <Checkbox
                name="diffMetods"
                label="Pagar con diferentes métodos"
                onChange={handleDifMetodsChange}
              />
            </div>
          </div>
          <CardMethod
            setHasUpdatedHoldBack={setHasUpdatedHoldBack}
            hasUpdatedHoldBack={hasUpdatedHoldBack}
            hasUpdateDifMetods={hasUpdateDifMetods}
            uuidCustomer={quoteValues.customer}
          />
          {hasUpdateDifMetods &&
            [1, 2].map((index) => (
              <CardMethod
                setHasUpdatedHoldBack={setHasUpdatedHoldBack}
                hasUpdatedHoldBack={hasUpdatedHoldBack}
                hasUpdateDifMetods={hasUpdateDifMetods}
                uuidCustomer={quoteValues.customer}
                index={index}
              />
            ))}
          <DepositMethod
            onSelect={handleMethodChange}
            hasUpdateDifMetods={hasUpdateDifMetods}
          />
          <TransferMethod
            onSelect={handleMethodChange}
            hasUpdateDifMetods={hasUpdateDifMetods}
          />
          <CashMethod
            onSelect={handleMethodChange}
            hasUpdateDifMetods={hasUpdateDifMetods}
          />
          <Section title="RETENCIÓN">
            <div className="common-muted-text font-weight-normal">
              {i18n('RESERVATIONS__HOLDBACK__DESCRIPTION')}
            </div>
            <div className="payment-page__main__icons mt-4">
              <Card noStatus padding="py-0 px-3 mr-3 mb-1" noTitle>
                <Icons.American width="3.125rem" height="3.125rem" />
              </Card>
              <Card noStatus padding="py-0 px-3 mr-3 mb-1" noTitle>
                <Icons.Mastercard width="3.125rem" height="3.125rem" />
              </Card>
              <Card noStatus padding="py-0 px-3 mb-1" noTitle>
                <Icons.Visa width="3.125rem" height="3.125rem" />
              </Card>
            </div>
            <div className="row">
              <div className="col-6">
                <ConnectedSelect
                  label="Retencion*"
                  name="holdbackRetention"
                  isArrayList
                  arrayList={[
                    { value: '0.00', label: '0' },
                    { value: '3000', label: '3,000' },
                    { value: '5000', label: '5,000' },
                    { value: '10000', label: '10,000' },
                    { value: 'otro', label: 'Otro' },
                  ]}
                  onChange={handleChange}
                />
              </div>
              <div className="col-6">
                {otroMonto && (
                  <NumberInput
                    decimals={2}
                    max={9_999_999}
                    label="Otro monto*"
                    placeholder="Otro monto*"
                    name="holdbackOtherRetention"
                    disabled={!otroMonto}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Input
                  mask={alphaMaskWithSpacesAndAccent(40)}
                  maskPlaceholder=""
                  label="Titular de la tarjeta*"
                  placeholder="Titular de la tarjeta*"
                  name="holdbackCardHolder"
                />
              </div>
              <div className="col-6">
                <ConnectedSelect
                  label="Banco*"
                  name="holdbackBank"
                  value={selectedBank}
                  options="commons.catalogsBanks"
                  onChange={handleChangeBanco}
                />
              </div>
              {otroBanco && (
                <Fragment>
                  <div className="col-md-6 col-sm-12">
                    <Input
                      mask={alphaMaskWithSpacesAndAccent(40)}
                      maskPlaceholder=""
                      label="Otro Banco*"
                      placeholder="Ingresa nuevo banco*"
                      name="holdbackOtherBankName"
                      disabled={!otroBanco}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex align-items-center">
                    <Button
                      style={buttonGuardarBancoStyle}
                      onClick={() => handleSaveNewBank()}
                    >
                      Guardar Banco
                    </Button>
                  </div>
                </Fragment>
              )}
            </div>
            <div className="row">
              <div className="col-6">
                <Input
                  label="No. de tarjeta*"
                  placeholder="No. de tarjeta*"
                  name="holdbackCard"
                  mask={cardNumberValidations}
                  maskPlaceholder="#### #### #### ####"
                />
              </div>
              <div className="col-4">
                <Input
                  mask={cardValidityMask}
                  maskPlaceholder=""
                  label="Vigencia*"
                  placeholder="Vigencia*"
                  name="holdbackValidity"
                />
              </div>
              <div className="col-2">
                <Input
                  mask={numericMask(4)}
                  maskPlaceholder=""
                  label="CVC"
                  placeholder="CVC"
                  name="holdbackCode"
                />
              </div>
            </div>
            <p className="common-muted-text font-weight-normal">
              {/* {i18n('RESERVATIONS__HOLDBACK__CARD_CHARGE')} */}
              <br />
              <br />
              {/* {i18n('RESERVATIONS__HOLDBACK__CARD_DISCLAIMER')} */}
            </p>
            <div className="col-12 my-5">
              <span className="mr-4">
                <Icons.Coment height="2rem" />
              </span>
              {i18n('COMMONS__REQUIRED_FIELDS')}
            </div>
            <div className="disclaimer mt-3">
              <span className="disclaimer-title">
                {i18n('RESERVATIONS__HOLDBACK__INVOICE_DISCLAIMER__TITLE')}
              </span>
              <span className="disclaimer-body">
                <span>
                  {i18n(
                    'RESERVATIONS__HOLDBACK__INVOICE_DISCLAIMER__DESCRIPTION'
                  )}
                </span>
              </span>
            </div>
            <InvoicePayment onUpdateForm={onUpdateForm} />
            {errors.methodSelected && touched.methodSelected && (
              <div className="col-form-error text-danger">
                {errors.methodSelected}
              </div>
            )}
          </Section>
        </div>
      </div>
    </PreventChange>
  );
};

Payment.propTypes = {
  maxSteps: PropTypes.number,
  step: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  setNewInvoicePayment: PropTypes.func,
};

Payment.defaultProps = {
  maxSteps: 2,
  step: 2,
  title: i18n('RESERVATIONS__STEPPER__TITLE'),
  subtitle: i18n('RESERVATIONS__STEPPER__SUBTITLE'),
};

export function actualizarSelectBancos() {
  console.log(
    'fetchCatalog dentro de actualizarSelectBancos:',
    fetchCatalogGlobal
  );
  fetchCatalogGlobal({
    catalogId: 'catalogs/banks',
    catalogPath: 'pricequotes',
  });
  console.log(
    'Se hizo correctamente fetchCatalog dentro de actualizarSelectBancos'
  );
}
export default withForm({ config })(Payment);
