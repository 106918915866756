const RESET = 'reports/RESET';

const GET_LIST = 'reports/GET_LIST';
const GET_LIST_SUCCEED = 'reports/GET_LIST_SUCCEED';
const GET_LIST_FAILED = 'reports/GET_LIST_FAILED';
const SET_PARAMS = 'reports/SET_PARAMS';
const SET_LIST_PAGE = 'reports/SET_LIST_PAGE';
const CLEAN_LIST = 'reports/CLEAN_LIST';

const CLOSE_RESPONSE_DIALOG = 'reports/CLOSE_RESPONSE_DIALOG';

const GET_TYPES_OF_REPORTS = 'reports/GET_TYPES_OF_REPORTS';
const GET_TYPES_OF_REPORTS_SUCCEED = 'reports/GET_TYPES_OF_REPORTS_SUCCEED';
const GET_TYPES_OF_REPORTS_FAILED = 'reports/GET_TYPES_OF_REPORTS_FAILED';

const SET_REPORT_COLUMNS = 'reports/SET_REPORT_COLUMNS';

const DOWNLOAD_REPORTS = 'reports/DOWNLOAD_REPORTS';
const DOWNLOAD_REPORTS_SUCCEED = 'reports/DOWNLOAD_REPORTS_SUCCEED';
const DOWNLOAD_REPORTS_FAILED = 'reports/DOWNLOAD_REPORTS_FAILED';

export default {
  RESET,
  GET_LIST,
  GET_LIST_SUCCEED,
  GET_LIST_FAILED,
  SET_PARAMS,
  SET_LIST_PAGE,
  CLEAN_LIST,
  CLOSE_RESPONSE_DIALOG,
  GET_TYPES_OF_REPORTS,
  GET_TYPES_OF_REPORTS_SUCCEED,
  GET_TYPES_OF_REPORTS_FAILED,
  SET_REPORT_COLUMNS,
  DOWNLOAD_REPORTS,
  DOWNLOAD_REPORTS_SUCCEED,
  DOWNLOAD_REPORTS_FAILED,
};
