import {
  TRANSFERS_MODULE,
  VIEW_TRANSFERS,
  CREATE_TRANSFER_REQUEST,
  EDIT_ANY_TRANSFER_REQUEST,
  EDIT_OWN_TRANSFER_REQUEST,
} from 'utils/roles/permissions/transfersPermissions';
import {
  TRANSFERS_LIST_PATH,
  TRANSFERS_DETAIL_PATH,
  TRANSFERS_ADD_PATH,
  TRANSFERS_EDIT_PATH,
} from 'utils/roles/paths';

export function transfersAuth(permissions) {
  const allowedModules = [];

  if (permissions.includes(VIEW_TRANSFERS)) {
    allowedModules.push(TRANSFERS_MODULE);
  }

  return allowedModules;
}

export function transfersPathsAuth(permissions) {
  const allowedPaths = [];

  if (permissions.includes(VIEW_TRANSFERS)) {
    allowedPaths.push(TRANSFERS_LIST_PATH);
    allowedPaths.push(TRANSFERS_DETAIL_PATH);
  }

  if (permissions.includes(CREATE_TRANSFER_REQUEST)) {
    allowedPaths.push(TRANSFERS_ADD_PATH);
  }

  if (
    permissions.includes(EDIT_ANY_TRANSFER_REQUEST) ||
    permissions.includes(EDIT_OWN_TRANSFER_REQUEST)
  ) {
    allowedPaths.push(TRANSFERS_EDIT_PATH);
  }

  return allowedPaths;
}
