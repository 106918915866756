import { useSelector } from 'react-redux';
import _get from 'lodash/get';

export default function withReservationAuth(
  Component,
  { path = '/reservations' } = {}
) {
  const Wrapper = (props) => {
    const reservations = useSelector((state) => state.reservations);
    const reservationId = _get(reservations, 'reservation.id', '');
    const reservationInfoId = _get(
      reservations,
      'reservationInfo.data.uuid',
      ''
    );

    const withReservation = reservationId || reservationInfoId;

    if (!withReservation) {
      window.location.href = path;
    }

    return <Component {...props} />;
  };

  return Wrapper;
}
