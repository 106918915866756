import React, { useState, useCallback } from 'react';
import { Layout, Button, Icons } from '@casanova/casanova-common';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import { MASSIVE_LOAD_TYPES } from '@vehicles/common/constants';
import { PreventChange } from 'components';
import StepOne from 'components/VehicleMassLoad/StepOne';
import StepTwo from 'components/VehicleMassLoad/StepTwo';
import StepHeader from './StepHeader';
import styles from './styles.module.css';
import Breadcrumb from '../../Breadcrumb';

const MAX_STEPS_VEHICLES = 3;
const MAX_STEPS_VEHICLES_FOR_SALES = 2;

export default function VehicleMassLoad({
  history,
  uploadVehicles,
  massiveLoadType,
  massiveRecords,
  downloadFailedRecords,
  loader,
  resultMessage,
  closeResultMessage,
  confirmationMessage,
  openConfirmationMessage,
  closeConfirmationMessage,
  downloadTemplate,
}) {
  const [currentStep, setCurrentStep] = useState(1);
  const [excelFile, setExcelFile] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [xmlFiles, setXmlFiles] = useState([]);
  const [initialTab] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState('upload');

  const CONFIRMATION_MESSAGE_VEHICLE =
    'Una vez cargados lo vehiculos tendrás que editarlos manualmente ¿Estás seguro que quieres continuar?';
  const CONFIRMARION_MESSAGE_VEHICLE_FOR_SALE =
    'Una vez cargados los vehículos vendidos, ya no podrá ponerlos en venta';
  const SUCESS_MESSAGE_VEHICLE = `Se agregaron ${massiveRecords.success_records.length} vehículos de forma exitosa al catálogo, si deseas realizar algún cambio tendrás 
que buscarlo y editarlo manualmente.`;
  const SUCESS_MESSAGE_VEHICLE_FOR_SALE = `Se agregaron ${massiveRecords.success_records.length} vehículos vendidos de forma exitosa al catálogo.`;

  const messageConfirmation = () => {
    if (massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES)
      return CONFIRMATION_MESSAGE_VEHICLE;
    if (massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES_FOR_SALES)
      return CONFIRMARION_MESSAGE_VEHICLE_FOR_SALE;
  };

  const messageSucess = () => {
    if (massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES)
      return SUCESS_MESSAGE_VEHICLE;
    if (massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES_FOR_SALES)
      return SUCESS_MESSAGE_VEHICLE_FOR_SALE;
  };

  const handleExcelFile = (files) => {
    setExcelFile(files);
  };

  const handlePdfFiles = (files) => {
    setPdfFiles(files);
  };

  const handleXmlFiles = (files) => {
    setXmlFiles(files);
  };

  const handleStep = (step) => {
    if (step === 2) {
      handleUploadVehicles(false);
      setCurrentStep(step);
      if (
        step === 2 &&
        massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES_FOR_SALES &&
        massiveRecords.process
      ) {
        setConfirmationModal('upload');
        openConfirmationMessage();
      }
    } else if (step === 3) {
      setConfirmationModal('upload');
      openConfirmationMessage();
    }
  };
  const handleUploadVehicles = useCallback(
    (process) => {
      uploadVehicles([...excelFile, ...pdfFiles, ...xmlFiles], process);
    },
    [excelFile, pdfFiles, xmlFiles, uploadVehicles]
  );
  const handleOnCancel = () => {
    if (currentStep === 2) {
      setConfirmationModal('exit');
      openConfirmationMessage();
    } else {
      history.push('/vehicle');
    }
  };

  const handleOnConfirmCancel = () => {
    setExcelFile([]);
    setPdfFiles([]);
    setXmlFiles([]);
    setCurrentStep(1);
    closeConfirmationMessage();
  };

  const showButtons = () => {
    const hasExcelFile = !!excelFile.length;
    if (currentStep === 1 && !hasExcelFile) {
      return false;
    }
    return true;
  };

  const handleDownloadRecords = () => {
    downloadFailedRecords();
  };

  const handleConfirmModal = (process) => {
    if (process) {
      setConfirmationModal('upload');
      closeResultMessage();
      openConfirmationMessage();
    }
  };

  const handleClickDownload = useCallback(() => {
    downloadTemplate();
  }, [downloadTemplate]);

  const { open, success, errorCode } = resultMessage;

  const preventChangeStep1 = currentStep === 1 && !!excelFile.length;
  const preventChangeStep2 = currentStep === 2 && !massiveRecords.process;
  const shouldPrevent = preventChangeStep1 || preventChangeStep2;

  return (
    <>
      <PreventChange preventChange={shouldPrevent} />
      <ActionModal
        open={
          success &&
          open &&
          !massiveRecords.process &&
          massiveRecords.failed_records.length > 0 &&
          massiveRecords.success_records.length === 0
        }
        icon={<Icons.StatusWarning />}
        onClose={closeResultMessage}
        onAction={() => {
          if (massiveRecords.success_records.length > 0) {
            handleConfirmModal(true);
          } else {
            closeResultMessage();
          }
        }}
        title="HAY REGISTROS CON ERROR"
        actionLabel="Ver registros"
        modalType="confirmation"
        message="Identificamos registros que contienen errores por favor corrigelos para cargarlos nuevamente."
        closeButton
      />

      <ActionModal
        open={
          success &&
          open &&
          !massiveRecords.process &&
          massiveRecords.failed_records.length > 0 &&
          massiveRecords.success_records.length > 0
        }
        icon={<Icons.StatusWarning />}
        onClose={closeResultMessage}
        onAction={() => {
          if (massiveRecords.success_records.length > 0) {
            handleConfirmModal(true);
          } else {
            closeResultMessage();
          }
        }}
        title="HAY REGISTROS CON ERROR"
        actionLabel="Continuar"
        closeLabel="Ver registros"
        message="Identificamos registros que contienen errores por favor corrigelos para cargarlos nuevamente."
        closeButton
      />

      <ActionModal
        open={success && open && massiveRecords.process}
        icon={<Icons.IcoCarOk />}
        onClose={closeResultMessage}
        onAction={() => history.push('/vehicle')}
        title="¡BIEN HECHO!"
        actionLabel="Ir a Vehículos"
        modalType="confirmation"
        message={messageSucess()}
      />

      <ActionModal
        open={!success && open}
        icon={<Icons.IcoCarError />}
        code={errorCode}
        onClose={closeResultMessage}
        onAction={() => handleUploadVehicles(false)}
        title="¡UPS! ALGO PASÓ!"
        actionLabel="Reintentar"
        closeButton
        message="Hubo un error mientras procesabamos la información, por favor intenta de nuevo."
      />

      <ActionModal
        open={confirmationMessage && confirmationModal === 'back'}
        icon={<Icons.StatusWarning />}
        closeButton
        onClose={closeConfirmationMessage}
        onAction={handleOnConfirmCancel}
        title="ESTÁS A PUNTO DE SALIR"
        actionLabel="Si, continuar"
        closeLabel="Cancelar"
        message="Se perderán los archivos previamente cargados, te recomendamos guardar tus cambios antes de salir.
                ¿Estás seguro que quieres continuar?"
      />

      <ActionModal
        open={confirmationMessage && confirmationModal === 'exit'}
        icon={<Icons.StatusWarning />}
        closeButton
        onClose={closeConfirmationMessage}
        onAction={handleOnConfirmCancel}
        title="CANCELAR PROCESO"
        actionLabel="Sí"
        closeLabel="No"
        message="Al cancelar se perderán todos los archivos previamente cargados.
                ¿Estás seguro que quieres continuar?"
      />

      <ActionModal
        open={confirmationMessage && confirmationModal === 'upload'}
        icon={<Icons.StatusWarning />}
        closeButton
        onClose={closeConfirmationMessage}
        onAction={() => {
          closeConfirmationMessage();
          handleUploadVehicles(true);
        }}
        title="CONFIRMACIÓN DE CARGA"
        actionLabel="Sí, continuar"
        closeLabel="Cancelar"
        message={messageConfirmation()}
      />
      <Layout.Title label={<Breadcrumb />} />
      <Layout.Body showLoader={loader}>
        <div className={`${styles.vehicleMassLoad} col`}>
          <StepHeader
            massiveLoadType={massiveLoadType}
            currentStep={currentStep}
            maxSteps={
              (massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES &&
                MAX_STEPS_VEHICLES) ||
              (massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES_FOR_SALES &&
                MAX_STEPS_VEHICLES_FOR_SALES)
            }
            onDownload={handleClickDownload}
          />
          {currentStep === 1 && (
            <StepOne
              massiveLoadType={massiveLoadType}
              excelFile={excelFile}
              pdfFiles={pdfFiles}
              xmlFiles={xmlFiles}
              handleExcelFile={handleExcelFile}
              handleXmlFiles={handleXmlFiles}
              handlePdfFiles={handlePdfFiles}
            />
          )}
          {currentStep === 2 && (
            <StepTwo
              massiveLoadType={massiveLoadType}
              records={massiveRecords}
              initialTab={initialTab}
              onDownloadRecords={handleDownloadRecords}
            />
          )}

          {showButtons() && (
            <div className="row mt-4">
              <div className="col-md-6 text-left">
                {currentStep >= 2 && (
                  <Button
                    onClick={() => {
                      setConfirmationModal('back');
                      openConfirmationMessage();
                    }}
                    lg
                    outline
                    id="spnBackButton"
                    className={`mr-3 button-outline-icon ${
                      massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES_FOR_SALES
                        ? styles.vehiclesForSales
                        : ''
                    }`}
                  >
                    <span className={styles.vehicleMassLoadBackButtom}>
                      <Icons.Atras height="1.25rem" /> Atrás
                    </span>
                  </Button>
                )}
              </div>
              <div
                className={`col-md-6 text-right ${
                  massiveLoadType === MASSIVE_LOAD_TYPES.VEHICLES_FOR_SALES
                    ? styles.vehiclesForSales
                    : ''
                }`}
              >
                <Button onClick={handleOnCancel} lg outline className="mr-3">
                  Cancelar
                </Button>
                <Button
                  disabled={
                    currentStep === 2 &&
                    massiveRecords.success_records.length === 0
                  }
                  onClick={() => handleStep(currentStep + 1)}
                >
                  Continuar
                </Button>
              </div>
            </div>
          )}
        </div>
      </Layout.Body>
    </>
  );
}
