import React from 'react';
import styles from './styles.module.css';

const DetailsSinister = ({ data }) => (
  <>
    <div className="row">
      <div className="col-4">
        <h6 className={`${styles.title} text-uppercase`}>
          Número de siniestro
        </h6>
        <p className={styles.body}>{data.lendDate}</p>
      </div>
      <div className="col-4">
        <h6 className={`${styles.title} text-uppercase`}>Responsable</h6>
        <p className={styles.body}>{data.lendResponsible}</p>
      </div>
    </div>
  </>
);

export default DetailsSinister;
