import React from 'react';
import PropTypes from 'prop-types';
import { PaymentType } from '@reservations/components';
import { NumberInput } from '@casanova/casanova-common';

const CardMethod = ({ name, onSelect, hasUpdateDifMetods }) => (
  <PaymentType
    name={name}
    onSelect={onSelect}
    hasUpdateDifMetods={hasUpdateDifMetods}
    title="PAGO EN EFECTIVO"
    withoutContent={!hasUpdateDifMetods && true}
  >
    {hasUpdateDifMetods && (
      <div className="row">
        <div className="col-12">
          <NumberInput
            decimals={2}
            max={9_999_999}
            label="Monto en Efectivo"
            placeholder="Monto en Efectivo"
            name="amountCash"
            disabled={!hasUpdateDifMetods}
          />
        </div>
      </div>
    )}
  </PaymentType>
);

CardMethod.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
  hasUpdateDifMetods: PropTypes.bool,
};

CardMethod.defaultProps = {
  name: 'cash',
};

export default CardMethod;
