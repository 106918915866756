import compose from 'recompose/compose';
import { connect } from 'react-redux';
import AdaptationForm from './AdaptationForm';

export default compose(
  connect(
    (state) => ({
      listAdaptationOrigin: state.catalogs.adaptationOrigin.list,
      listAdaptationType: state.catalogs.adaptationType.list,
    }),
    {}
  )
)(AdaptationForm);
