import React, { useEffect, useState, useCallback, useMemo } from 'react';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { Section, DatePickerV2, withForm } from '@casanova/casanova-common';
import { default as Card } from 'components/CardSection';
import { SchedDate } from '@reservations/components';
import { number2mxn } from 'utils/currency';
import PreventChange from 'components/PreventChange';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { preventChangeConfig } from '@dailyRentContracts/common/config';
import _isEmpty from 'lodash/isEmpty';
import config from './config';
import { ExtendList } from '../../../sections';
import ResponseDialog from '../../../../../components/ResponseDialog';
import { closeResponseDialog } from 'store/reservations/actions';

const ExtendDetail = (props) => {
  const {
    reservationDetails,
    extendContractDetails,
    responseDialog,
    createExtendContract,
  } = props;
  const { data, currentContract } = extendContractDetails;
  const [reservationDays, setReservationDays] = useState(0);
  const [newReservationDays, setNewReservationDays] = useState(0);
  const [extendedDays, setExtendedDays] = useState(0);
  const totalReservationDays =
    reservationDays + newReservationDays + extendedDays;
  const [minDate, setMinDate] = useState({});
  const [maxDate, setMaxDate] = useState({});
  const dispatch = useDispatch();

  const { priceQuote } = reservationDetails;

  const amount =
    priceQuote?.selectedVehicleLinePrice.vehicleLinePrice.price /
    priceQuote?.selectedVehicleLinePrice.vehicleLinePrice.period;

  const {
    values,
    dirty,
    isSubmitting,
    initialValues,
    setFieldValue,
  } = useFormikContext();

  const preventChange = useMemo(() => dirty && !isSubmitting, [
    dirty,
    !isSubmitting,
  ]);

  const handleResevationNumber = useCallback(
    (value) => {
      const end = moment(priceQuote?.returnDateTime, 'YYYY-MM-DD HH:mm:ss.SSS');
      const newEnd = moment(value, 'YYYY-MM-DD HH:mm:ss.SSS');
      newEnd.set('hour', end.get('hour')).set('minute', end.get('minute'));
      const daysDiff = isNaN(newEnd.diff(end, 'days'))
        ? 0
        : newEnd.diff(end, 'days');
      setFieldValue('newDateEnd', newEnd.format('YYYY-MM-DD[T]HH:mm:ss.SSS'));
      setFieldValue('daysAdded', daysDiff);
      setNewReservationDays(daysDiff);
    },
    [priceQuote?.pickupDateTime, priceQuote?.returnDateTime]
  );

  const handleReservationDays = (pickupDate, returnDate, extendedDays = 0) => {
    const start = moment(pickupDate).startOf('day');
    const end = moment(returnDate).startOf('day');
    const daysDiff = isNaN(end.diff(start, 'days'))
      ? 0
      : end.diff(start, 'days');
    setReservationDays(daysDiff);
    extendedDays > 0 ? end.add(extendedDays + 1, 'days') : end.add(1, 'days');
    setMinDate({ year: end.year(), month: end.month() + 1, day: end.date() });
    extendedDays > 0 ? end.add(29 - extendedDays, 'days') : end.add(29, 'days');
    setMaxDate({ year: end.year(), month: end.month() + 1, day: end.date() });
  };

  const handleReservationExtendedDays = useCallback(() => {
    const originalReturnDate = data.find((ec) => {
      return ec.contractNumber.includes('-E1');
    }).returnDateTime;
    const daysAdded = data.map((ec) => {
      return ec.daysAdded;
    });
    const totalExtendedDays = daysAdded.reduce((total, da) => total + da);
    setExtendedDays(totalExtendedDays);
    handleReservationDays(
      priceQuote?.pickupDateTime,
      originalReturnDate,
      totalExtendedDays
    );
  }, [priceQuote?.pickupDateTime, priceQuote?.returnDateTime]);

  const setExtendNumber = (contractNumber, count) => {
    const reduceToNumber = contractNumber?.replace(/Z0*/g, '');
    setFieldValue('extendNumber', reduceToNumber + '-E' + count);
  };

  useEffect(() => {
    setFieldValue('reservationUuid', reservationDetails.uuid);
  }, [reservationDetails]);

  useEffect(() => {
    setExtendNumber(currentContract?.contractNumber, data.length + 1);
  }, []);

  const handleOnClose = useCallback(() => {
    dispatch(closeResponseDialog());
  });

  useEffect(() => {
    const actual = data?.find((ec) => !ec.isEnabled);
    if (actual?.uuid && !actual?.isEnabled)
      setFieldValue('lastReservationExtenContractUuid', actual.uuid);
    if (data.length > 0) handleReservationExtendedDays();
    else
      handleReservationDays(
        priceQuote?.pickupDateTime,
        priceQuote?.returnDateTime
      );
  }, [extendContractDetails, priceQuote?.pickupDateTime]);

  return (
    <PreventChange
      {...preventChangeConfig}
      preventChange={preventChange}
      fullReload
      stockInCurrentPath
    >
      <ResponseDialog
        open={responseDialog.openEc}
        errorMessage={responseDialog.messageEc}
        onError={() => createExtendContract({ ...values })}
        onClose={handleOnClose}
      />
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="row">
            <div className="col-9">
              <Section title="Fechas y Plan">
                <Card noStatus noTitle>
                  <div className="row">
                    <div className="col-4 d-flex common-info-text flex-column align-content-center justify-content-center">
                      <span className=" font-weight-bold">
                        PLAN{' '}
                        {
                          priceQuote?.selectedVehicleLinePrice.vehicleLinePrice
                            .rentalPlanName
                        }{' '}
                        MXN
                      </span>
                      <span className="common-muted-text text-lg">
                        {number2mxn(isNaN(amount) ? 0 : amount)} x día
                      </span>
                    </div>
                    <div className="col-4">
                      <SchedDate
                        className="mb-0"
                        date={priceQuote?.pickupDateTime}
                        branch={priceQuote?.pickupBranch.name}
                        title="Fecha de salida"
                      />
                    </div>
                    <div className="col-4">
                      <SchedDate
                        className="mb-0"
                        date={priceQuote?.returnDateTime}
                        branch={priceQuote?.returnBranch.name}
                        title="Fecha de devolución"
                        end
                      />
                    </div>
                  </div>
                </Card>
              </Section>
            </div>
            <div className="col-3">
              <ExtendList />
            </div>
          </div>
          <div className="row justify-content-evenly">
            <div className="col-6">
              <DatePickerV2
                initialValue={initialValues.newDateEnd}
                placeholder="Nueva fecha de devolución*"
                onChange={handleResevationNumber}
                name="newDateEnd"
                label="Nueva fecha de devolución*"
                minimumDate={minDate}
                // maximumDate={maxDate}
                autoComplete="off"
              />
            </div>
            <div className="col-6 flex flex-column justify-content-center align-items-center">
              <p className="text-lg font-semibold">D&iacute;as de renta</p>
              <div className="flex justify-content-center align-items-end gap-2 text-center text-lg mb-3">
                {data.length > 0 ? (
                  <>
                    <span>
                      Original
                      <br />
                      {reservationDays}
                    </span>
                    <span>+</span>
                    <span>
                      Extendidos
                      <br />
                      {extendedDays}
                    </span>
                    <span>+</span>
                    <span>
                      Adicionales
                      <br />
                      {newReservationDays}
                    </span>
                    <span>=</span>
                    <span className="text-primary-500 text-lg font-semibold">
                      Total
                      <br />
                      {totalReservationDays}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      Actual
                      <br />
                      {reservationDays}
                    </span>
                    <span>+</span>
                    <span>
                      Adicionales
                      <br />
                      {newReservationDays}
                    </span>
                    <span>=</span>
                    <span className="text-primary-500 text-lg font-semibold">
                      Total
                      <br />
                      {totalReservationDays}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PreventChange>
  );
};

export default withForm({ config })(ExtendDetail);
