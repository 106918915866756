export default function formatter(values) {
  const params = Object.keys(values)
    .filter((item) => item.indexOf('key') !== -1)
    .map((item) => {
      const newItem = {
        order: parseInt(item.replace('key', ''), 10),
        value: values[item],
      };

      return newItem;
    });

  return {
    params,
    reportType: values.reportType,
  };
}
