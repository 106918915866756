import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  closeResponseDialog,
  fetchVehicleDetails,
  fetchHistoricDetails,
} from 'store/vehicles/actions';
import VehicleHistoricDetail from './VehicleHistoricDetail';

export default compose(
  connect(
    (state) => ({
      responseDialog: state.vehicles.responseDialog,
    }),
    { closeResponseDialog, fetchVehicleDetails, fetchHistoricDetails }
  )
)(VehicleHistoricDetail);
