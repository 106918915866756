import React, { useEffect, useMemo, useCallback } from 'react';
import { useFormikContext } from 'formik';
import i18n from '@i18n';
import { Button, withForm } from '@casanova/casanova-common';
import { Section, Card, TextField, UserInfo } from 'components';
import { successPath } from '@loans/common/config';
import { config } from './config';

function LoanDetails({
  details,
  getLoanDetails,
  match,
  history,
  onUpdateForm,
}) {
  const loanUuid = useMemo(() => match.params.id, [match]);

  const { values } = useFormikContext();

  const { data: loanDetails } = details;
  const { loanStatus = {} } = loanDetails;
  const { color = '', name = '' } = loanStatus;

  const handleClose = useCallback(() => {
    history.push(successPath);
  }, [history]);

  useEffect(() => {
    if (loanDetails) {
      onUpdateForm(loanDetails);
    }
  }, [onUpdateForm, loanDetails]);

  useEffect(() => {
    getLoanDetails(loanUuid);
  }, [getLoanDetails, loanUuid]);

  return (
    <Section title={i18n('LOANS__LOAN_DETAIL__LABEL')}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Card noStatus>
              <div className="row mb-3">
                <div className="col-12">
                  <h4 className="text-left text-uppercase" style={{ color }}>
                    {name}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-3">
                      <TextField
                        title={i18n('COMMONS__FOLIO')}
                        value={values.folio}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_CREATOR__REQUESTER', [''])}
                        value={values.requesterName}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_CREATOR__AUTHORIZED', [''])}
                        value={values.responsibleName}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField
                        title={i18n('COMMONS__PLATE')}
                        value={values.plateNumber}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_CREATOR__DEPERTURE_DATE', [
                          '',
                        ])}
                        value={values.formattedDepartureDate}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_DETAIL__DEPARTURE_TIME')}
                        value={values.departureTime}
                      />
                    </div>
                    {/* <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_DETAIL__REAL_DEPARTURE_DATE', [
                          '',
                        ])}
                        value={values.formattedRealDepartureDate}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_DETAIL__REAL_DEPARTURE_TIME')}
                        value={values.realDepartureTime}
                      />
                    </div> */}
                    <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_DETAIL__ARRIVAL_DATE', [''])}
                        value={values.formattedReturnDate}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_DETAIL__ARRIVAL_TIME')}
                        value={values.returnTime}
                      />
                    </div>
                    {/* <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_DETAIL__REAL_ARRIVAL_DATE', [
                          '',
                        ])}
                        value={values.formattedRealReturnDate}
                      />
                    </div>
                    <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_DETAIL__REAL_ARRIVAL_TIME')}
                        value={values.realReturnTime}
                      />
                    </div> */}
                    <div className="col-md-3">
                      <TextField
                        title={i18n('LOANS__LOAN_CREATOR__DRIVER', [''])}
                        value={values.driverName}
                      />
                    </div>
                  </div>
                  {values.observations && (
                    <div className="row">
                      <div className="col-md-8">
                        <TextField
                          title={i18n('COMMONS__REMARKS')}
                          value={values.observations}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-end m-0">
                  <UserInfo {...loanDetails} label={i18n('COMMONS__USER')} />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col" />
        <div className="col-3 col-xl-3">
          <Button block onClick={handleClose}>
            {i18n('COMMONS__BACK')}
          </Button>
        </div>
      </div>
    </Section>
  );
}

export default withForm({ config })(LoanDetails);
