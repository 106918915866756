import { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Table, withForm, Icons, ActionModal } from '@casanova/casanova-common';
import ResponseDialog from '../../../components/ResponseDialog';

import { TableBottom, PDFModal, BodyNotification } from 'components';
import useCleanModule from 'hooks/useCleanModule';
import i18n from '@i18n';
import { transformDropdownElements } from 'utils/transformHelpers';
import { CREATE_CONTRACT_RD } from 'utils/roles/permissions/contractsRDPermissions';
import {
  IT_ADMIN,
  BRANCH_A_MANAGER,
  BRANCH_B_MANAGER,
} from 'utils/roles/roles';
import { useDispatch } from 'react-redux';
import { closeResponseDialog } from 'store/reservations/actions';
import { closeResponseDialogValidCode } from 'store/dailyRentContracts/actions';

import config from './config';
import columns, {
  contextualMenu,
  getContextualMenu,
  CLOSE_CONTRACT_DETAIL,
} from './columns';

import './ListOfDRContracts.scss';
import ViewRefundDetail from '../ViewRefundDetail';
import ViewPayDetail from '../ViewPayDetail';
import ViewExpiringContracts from '../ViewExpiringContracts';
import { CodeModal } from '../../../components/CodeModal';
import { validateRole } from '../../../utils/roles';
import { getBrach } from '../../../services/auth';
import { Toast } from 'primereact/toast';
import { useMountEffect } from 'primereact/hooks';
import { reviewExpiredContracts } from 'services/dailyRentContracts';
import _get from 'lodash/get';

function ListOfDRContracts({
  history,
  contractsList,
  getDailyRentContractsList,
  setListPage,
  hydrateChangeOfVehicleFlow,
  hydrateExtendContractFlow,
  resetDailyRent,
  closeContract,
  hasClosedContract,
  hasFailedCloseContract,
  responseDialog,
  responseDialogValidCode,
  loader,
}) {
  const VEHICLE_CHANGE_LABEL = 'Contrato Cambio de Vehículo';
  const LEASING_CONTRACT_LABEL = 'Contrato de Arrendamiento';

  const [showDetail, setShowDetail] = useState(false);
  const [contractUrl, setContractUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [changeVehicleUrl, setChangeVehicleUrl] = useState('');
  const [leftButtonLabel, setLeftButtonLabel] = useState(VEHICLE_CHANGE_LABEL);
  const [title, setTitle] = useState('');
  const [showConfirmCloseContract, setShowConfirmCloseContract] = useState(
    false
  );
  const [isCancelContractTextActive, setIsCancelContractTextActive] = useState(
    false
  );
  const [showModalRefund, setShowModalRefund] = useState(false);
  const [showModalPay, setShowModalPay] = useState(false);
  const [contract, setContract] = useState({});
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [itWasSentCloseContract, setItWasSentCloseContract] = useState(false);
  const [showExpiringContractsModal, setShowExpiringContractsModal] = useState(
    false
  );
  const [expiringContractsToShow, setExpiringContractsToShow] = useState({});
  const dispatch = useDispatch();
  const toast = useRef(null);

  const onOpenExpiringContractsModal = (data) => {
    setShowExpiringContractsModal(true);
    setExpiringContractsToShow(data);
  };

  const reviewExpiredContractsService = async () => {
    const contractsE = await reviewExpiredContracts();
    if (!contractsE?.length) return;

    // Separar los contratos expirados y no expirados
    const { expiringContracts, expiredContracts } = contractsE.reduce(
      (result, contractE) => {
        if (contractE.isExpired) result.expiredContracts.push(contractE);
        else result.expiringContracts.push(contractE);
        return result;
      },
      { expiredContracts: [], expiringContracts: [] }
    );

    if (expiringContracts?.length) {
      toast.current.show({
        severity: 'warn',
        summary: 'Contratos próximos a vencer',
        detail: `${expiringContracts?.length} ${
          expiringContracts?.length > 1 ? 'contratos' : 'contrato'
        }`,
        sticky: true,
        action: () =>
          onOpenExpiringContractsModal({
            title: 'Contratos próximos a vencer',
            contracts: expiringContracts,
          }),
        content: (props) => <BodyNotification {...props} />,
      });
    }

    if (expiredContracts?.length) {
      toast.current.show({
        severity: 'error',
        summary: 'Contratos vencidos',
        detail: `${expiredContracts?.length} ${
          expiredContracts?.length > 1 ? 'contratos' : 'contrato'
        }`,
        sticky: true,
        action: () =>
          onOpenExpiringContractsModal({
            title: 'Contratos vencidos',
            contracts: expiredContracts,
          }),
        content: (props) => <BodyNotification {...props} />,
      });
    }
  };

  useMountEffect(() => {
    if (toast.current) {
      reviewExpiredContractsService();
    }
  });

  useCleanModule({ module: 'dailyRentContract', exceptions: ['reservations'] });

  const { params, searchPattern, filters, results } = contractsList;

  const handleClickNew = () => {
    history.push('/daily-rent-contracts/contract-quote/');
  };

  const handleDropdownSelect = (currentContract) => {
    const {
      name,
      leasingContract,
      promissoryNoteContract,
      reservationUuid,
      changeRequest,
      changeVehicleRequest,
    } = currentContract;

    switch (name) {
      case contextualMenu.LEASING_CONTRACT.name:
        setShowDetail(true);
        setContractUrl(leasingContract?.presignedUrl);
        setPdfUrl(leasingContract?.presignedUrl);
        setChangeVehicleUrl(_get(changeVehicleRequest, 'presignedUrl', ''));
        break;
      case contextualMenu.EXTEND_CONTRACT.name:
        setContract(currentContract);
        hydrateExtendContractFlow({ currentContract });
        break;
      case contextualMenu.PROMISSORY_NOTE_CONTRACT.name:
        setShowDetail(true);
        setPdfUrl(promissoryNoteContract?.presignedUrl);
        setChangeVehicleUrl('');
        break;
      case contextualMenu.CHANGE_VEHICLE.name:
        hydrateChangeOfVehicleFlow({
          reservationUuid,
          changeRequestUuid: changeRequest,
        });
        break;
      case contextualMenu.CANCEL_CONTRACT.name:
      case contextualMenu.CLOSE_CONTRACT.name:
        setIsCancelContractTextActive(
          name === contextualMenu.CANCEL_CONTRACT.name
        );
        if (
          validateRole([IT_ADMIN, BRANCH_A_MANAGER, BRANCH_B_MANAGER]) ||
          getBrach() === currentContract.departureBranchUuid
        )
          setShowConfirmCloseContract(true);
        else setShowCodeModal(true);

        setTitle('cierre / cancelación');
        setContract(currentContract);
        break;
      case contextualMenu.UNIT_INVENTORY.name:
        history.push(
          `/daily-rent-contracts/${currentContract.reservationUuid}/unit_inventory`
        );
        break;
      case CLOSE_CONTRACT_DETAIL?.name:
        history.push(
          `/daily-rent-contracts/${currentContract.uuid}/closed-contract`
        );
        break;
      case contextualMenu.REFUND.name:
        setShowModalRefund(true);
        setContract(currentContract);
        break;
      case contextualMenu.PAY.name:
        setShowModalPay(true);
        setContract(currentContract);
        break;
      default:
        break;
    }
  };

  const handleRejectCancelContract = () => {
    setShowConfirmCloseContract(false);
    setContract({});
  };

  const handleConfirmCancelContract = (codeForm = {}) => {
    setShowConfirmCloseContract(false);
    closeContract({
      uuid: contract.uuid,
      name: contract.name,
      authReq: {
        validCodeRequest: codeForm,
        contractBranchUuid: contract.departureBranchUuid,
      },
    });
    setItWasSentCloseContract(true);
  };

  const refreshHydrateExtendContractFlow = () => {
    hydrateExtendContractFlow({ currentContract: contract });
  };

  const handleOnClose = useCallback(() => {
    setContract({});
    dispatch(closeResponseDialog());
  });

  const handleOnCloseValidCode = useCallback(() => {
    dispatch(closeResponseDialogValidCode());
  });

  const handleOnCloseCodeModal = useCallback(() => {
    setContract({});
    setShowCodeModal(false);
  });

  useEffect(() => {
    if (!itWasSentCloseContract) return;

    if (hasClosedContract && contract.uuid) {
      history.push(`/daily-rent-contracts/${contract.uuid}/closed-contract`);
    }
  }, [
    itWasSentCloseContract,
    hasClosedContract,
    hasFailedCloseContract,
    contract.uuid,
  ]);

  useEffect(() => {
    getDailyRentContractsList({ params, filters, searchPattern });
  }, [getDailyRentContractsList, params, filters, searchPattern]);

  useEffect(() => {
    resetDailyRent({ exceptions: [] });
  }, []);

  const pdfChangeView = () => {
    setPdfUrl((prev) =>
      prev == changeVehicleUrl ? contractUrl : changeVehicleUrl
    );
    setLeftButtonLabel((prev) =>
      prev == VEHICLE_CHANGE_LABEL
        ? LEASING_CONTRACT_LABEL
        : VEHICLE_CHANGE_LABEL
    );
  };

  return (
    <section className="ListOfDRContracts my-1">
      <Toast ref={toast} position="bottom-center" />
      <ViewRefundDetail
        show={showModalRefund}
        setShow={setShowModalRefund}
        contract={contract}
      />
      <ViewPayDetail
        show={showModalPay}
        setShow={setShowModalPay}
        contract={contract}
      />
      <PDFModal
        show={showDetail}
        onClose={() => setShowDetail(false)}
        url={pdfUrl}
        showLeftButton={changeVehicleUrl != ''}
        leftButtonLabel={leftButtonLabel}
        onClickLeft={pdfChangeView}
      />
      <ViewExpiringContracts
        show={showExpiringContractsModal}
        setShow={setShowExpiringContractsModal}
        data={expiringContractsToShow}
      />

      <ResponseDialog
        open={responseDialog.openEc}
        successTitle="Reembolso realizado exitosamente"
        successMessage="Podrás encontrar el detalle del reembolso en el estado de cuenta del cliente"
        successLabel={i18n('COMMONS__CLOSE__TEXT')}
        errorMessage={responseDialog.messageEc}
        onError={refreshHydrateExtendContractFlow}
        onClose={handleOnClose}
      />
      <ResponseDialog
        open={responseDialogValidCode.open}
        errorTitle={i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE')}
        errorLabel={i18n('COMMONS__CLOSE__TEXT')}
        errorMessage={responseDialogValidCode.errorMessage}
        errorCode={responseDialogValidCode.errorCode}
        onError={handleOnCloseValidCode}
        onClose={handleOnCloseValidCode}
      />
      <CodeModal
        open={showCodeModal && !responseDialogValidCode.open && !loader}
        message={'gerente de sucursal'}
        title={title}
        onAction={handleConfirmCancelContract}
        onClose={handleOnCloseCodeModal}
      />

      <ActionModal
        open={showConfirmCloseContract}
        icon={<Icons.StatusWarning />}
        onClose={handleRejectCancelContract}
        onAction={() => handleConfirmCancelContract({ username: '', code: '' })}
        title={
          !isCancelContractTextActive
            ? i18n(
                'DAILY_RENT_CONTRACTS__CLOSE_CONTRACT__CONFIRMATION_MESSAGE__TITLE'
              )
            : i18n(
                'DAILY_RENT_CONTRACTS__CANCEL_CONTRACT__CONFIRMATION_MESSAGE__TITLE'
              )
        }
        message={
          !isCancelContractTextActive
            ? i18n(
                'DAILY_RENT_CONTRACTS__CLOSE_CONTRACT__CONFIRMATION_MESSAGE__MESSAGE'
              )
            : i18n(
                'DAILY_RENT_CONTRACTS__CANCEL_CONTRACT__CONFIRMATION_MESSAGE__MESSAGE'
              )
        }
        actionLabel={i18n('COMMONS__YES_CONTINUE__TEXT')}
        closeButton
      />

      <Table
        additionalHeader
        rowClassName="row-clickable"
        emptyMessage={i18n('ERROR__COMMONS__NOT_FOUND')}
        dataList={results}
        columns={columns.map((col) => ({
          ...col,
          renderer: (propsCol = { data: {} }) => {
            const Component = col.renderer;
            return (
              <Component
                {...propsCol}
                dropdownOptions={transformDropdownElements({
                  elements: getContextualMenu(propsCol.data),
                })}
              />
            );
          },
        }))}
        dropdownCell
        onDropdownSelect={handleDropdownSelect}
      />

      <TableBottom
        search={contractsList}
        onChange={setListPage}
        onClick={handleClickNew}
        textButton={i18n('COMMONS__NEW')}
        actionPermission={CREATE_CONTRACT_RD}
      />
    </section>
  );
}

ListOfDRContracts.propTypes = {
  history: PropTypes.object.isRequired,
  contractsList: PropTypes.object.isRequired,
  getDailyRentContractsList: PropTypes.func.isRequired,
  setListPage: PropTypes.func.isRequired,
  hydrateChangeOfVehicleFlow: PropTypes.func.isRequired,
  resetDailyRent: PropTypes.func.isRequired,

  closeContract: PropTypes.func.isRequired,
  hasClosedContract: PropTypes.bool.isRequired,
  hasFailedCloseContract: PropTypes.bool.isRequired,
};

export default withForm({ config })(ListOfDRContracts);
