import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import {
  getInvoicesList,
  setListPage,
  resetListParams,
  getInvoiceDetails,
} from 'store/invoices/actions';
import ListOfInvoices from './ListOfInvoices';

export default withLayout(
  compose(
    connect(
      (state) => ({
        invoicesList: state.invoices.list,
        invoiceDetails: state.invoices.invoiceDetails.data,
        hasNotFound: state.invoices.invoiceDetails.hasNotFound,
        confirmationMessage: false,
      }),
      { getInvoicesList, setListPage, resetListParams, getInvoiceDetails }
    )
  )(ListOfInvoices)
);
