import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { setContractQuote } from 'store/reservations/actions';
import CustomerFormBase from './CustomerFormBase';

export default compose(
  connect(
    (state) => ({
      loans: state.loans.list,
      confirmationMessage: false,
    }),
    { setContractQuote }
  )
)(CustomerFormBase);
