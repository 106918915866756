export const CATALOG_FETCH_REQUESTED = 'commons/CATALOG_FETCH_REQUESTED';
export const CATALOG_FETCH_SUCCEEDED = 'commons/CATALOG_FETCH_SUCCEEDED';
export const CATALOG_FETCH_FAILED = 'commons/CATALOG_FETCH_FAILED';

export const GET_SUBURBS_BY_ZIPCODE = 'commons/GET_SUBURBS_BY_ZIPCODE';
export const GET_SUBURBS_BY_ZIPCODE_SUCCEEDED =
  'commons/GET_SUBURBS_BY_ZIPCODE_SUCCEEDED';
export const GET_SUBURBS_BY_ZIPCODE_FAILED =
  'commons/GET_SUBURBS_BY_ZIPCODE_FAILED';

export const GET_TAX_ZIPCODE = 'commons/GET_TAX_ZIPCODE';
export const GET_TAX_ZIPCODE_SUCCEEDED = 'commons/GET_TAX_ZIPCODE_SUCCEEDED';
export const GET_TAX_ZIPCODE_FAILED = 'commons/GET_TAX_ZIPCODE_FAILED';

export const SHOW_LOADER = 'commons/SHOW_LOADER';
export const HIDE_LOADER = 'commons/HIDE_LOADER';

export const SET_PREVENT_CHANGE = 'commons/PREVENT_CHANGE';

export const RESET = 'commons/RESET';
