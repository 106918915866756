import { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { Button, PasswordInput, PlainInput } from '@casanova/casanova-common';
import i18n from '@i18n';
import { initialValues, schema } from './config';

const CodeForm = ({ onAction }) => {
  const formikRef = useRef(null);
  const [submitted, setSubmitted] = useState(true);

  const onFormikSubmit = (values) => {
    onAction({ username: values.user, code: values.code });
  };

  const resetForm = useCallback(() => {
    setSubmitted(false);
  }, []);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(initialValues);
    }
  }, [formikRef]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={submitted}
      validateOnBlur
      onSubmit={onFormikSubmit}
      innerRef={formikRef}
    >
      {({ handleSubmit }) => (
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            setSubmitted(true);
            handleSubmit();
          }}
          autoComplete="off"
        >
          <div className="my-4 flex justify-content-evenly gap-2">
            <div>
              <PlainInput
                name="user"
                label="Usuario"
                type="text"
                onlyError
                displayError={false}
                autoComplete="off"
              />
            </div>
            <div>
              <PasswordInput
                onBlur={resetForm}
                name="code"
                label="Código"
                onlyError
                displayError={false}
                notVisible={true}
                autoComplete="new-password"
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <Button block type="submit">
                {i18n('COMMONS__CONTINUE__TEXT')}
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CodeForm;
