import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from 'hooks';
import { WorkshopMain } from './WorkshopMain';

export default withLayout(
    compose(
        connect(
            (state) => ({
            }),
            {
            }
        )
    )(WorkshopMain)
);
