import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  CardSection,
  TextField,
  UserInfo,
  FileViewer,
} from '@casanova/casanova-common';
import i18n from '@i18n';
import { getTextColor } from 'utils/customers';
import { HAS_BIRTH_IN_FOREIGN } from '@customers/common/constants';
import { TITLE_OF_DOCUMENTS } from './constants';

export default function AuthorizedDriver({ driverData, current = '' }) {
  const {
    curp,
    names,
    lastName = '',
    secondLastName = '',
    birthDate,
    birthState,
    birthCountry,
    sex,
  } = driverData;

  const documentStatus = _get(driverData, 'documentStatus.name', '');
  const statusColor = getTextColor(documentStatus);
  const hasBirthInForeign = birthState === HAS_BIRTH_IN_FOREIGN;

  const files = useMemo(() => {
    if (!_isEmpty(driverData)) {
      const DOCUMENTS = ['driverLicenseFront', 'driverLicenseBack'];

      return DOCUMENTS.map((doc) => ({
        file: _get(driverData, `${doc}.presignedUrl`, undefined),
        title: TITLE_OF_DOCUMENTS[doc],
      }));
    }
  }, [driverData]);

  return (
    <CardSection
      status={statusColor}
      title={`${i18n(
        'CUSTOMERS__AUTHORIZED_DRIVERS__DETAILS__DRIVER__TITLE'
      )} ${current}`}
      upperCase
    >
      <div className="row">
        <div className="col-7">
          <FileViewer files={files} />
        </div>
        <div className="col-5">
          <TextField
            title={i18n('CUSTOMERS__INFORMATION__STATUS')}
            value={documentStatus.toUpperCase()}
            status={statusColor}
          />
          <TextField
            title={i18n('CUSTOMERS__INFORMATION__CURP', [''])}
            value={curp}
          />
          <div className="row">
            <div className="col-6">
              <TextField
                title={i18n('CUSTOMERS__INFORMATION__NAME', [''])}
                value={names}
              />
            </div>
            <div className="col-6">
              <TextField
                title={i18n('CUSTOMERS__INFORMATION__LAST_NAME', [''])}
                value={lastName}
              />
            </div>
            {secondLastName && (
              <div className="col-6">
                <TextField
                  title={i18n('CUSTOMERS__INFORMATION__SECOND_LAST_NAME', [''])}
                  value={lastName}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-6">
              <TextField
                title={i18n('CUSTOMERS__INFORMATION__DATE_OF_BIRTH', [''])}
                value={birthDate}
              />
            </div>
            <div className="col-6">
              {hasBirthInForeign ? (
                <TextField
                  title={i18n('CUSTOMERS__INFORMATION__BIRTH_COUNTRY', [''])}
                  value={birthCountry}
                />
              ) : (
                <TextField
                  title={i18n('CUSTOMERS__INFORMATION__BIRTH_PLACE', [''])}
                  value={birthState}
                />
              )}
            </div>
          </div>
          <TextField
            title={i18n('CUSTOMERS__INFORMATION__GENDER', [''])}
            value={sex}
          />
          <UserInfo {...driverData} />
        </div>
      </div>
    </CardSection>
  );
}

AuthorizedDriver.propTypes = {
  driverData: PropTypes.object.isRequired,
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
