import { Icons } from '@casanova/casanova-common';
import { DisableIcons } from '@reservations/commons/constantsComponents';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import _noop from 'lodash/noop';
import { useCallback, useEffect, useState } from 'react';
const DisableIcon = DisableIcons[1];

const CheckBox = ({ name, label }) => {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setValue('false');
  }, []);

  const handleChange = useCallback(() => {
    const booleanStr = !isChecked ? 'true' : 'false';
    setValue(booleanStr);
    setIsChecked((prev) => !prev);
  }, [value, setValue]);
  return (
    <div
      className="SpecItem feature-item d-flex align-items-baseline extra-row"
      onClick={handleChange}
    >
      <span className="mr-3">
        {isChecked ? (
          <Icons.CheckAvailable width="1.5rem" height="1.5rem" />
        ) : (
          <DisableIcon width="1.5rem" height="1.5rem" />
        )}
      </span>
      <div className="text text-uppercase">
        <p>{label}</p>
      </div>
    </div>
  );
};

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckBox;
