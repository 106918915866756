import { CellText, CellStatus } from 'components/ColumnsCells';
import i18n from '@i18n';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { setListParams } from 'store/dailyRentContracts/actions';
import { number2mxn } from 'utils/currency';
import { AdditionalHeaderCamp } from 'components';
import VERSIONS from 'utils/constants/versions';
import {
  numericMask,
  onlyLettersWithSpaces,
} from '@casanova/casanova-common/lib/utils/masks';
import {
  EXTEND_CONTRACT_RD,
  CLOSE_CONTRACT_RD,
  REFUND_CONTRACT_RD,
  VEHICLE_CONTRACT_RD,
  NOTE_CONTRACT_RD,
  VIEW_REPORTS_CONTACT_CENTER,
} from 'utils/roles/permissions/contractsRDPermissions';
import { VIEW_PAYMENTS } from 'utils/roles/permissions';
import { validateRolePermissions } from 'utils/roles';
import { RESERVATION_STEP } from 'utils/constants/reservations';
import { getBrach, getRoleName } from '../../../services/auth';

const MIN_SEARCH_DATE = '01-01-2021';

const statuses = [
  { label: 'Abierto', value: '623bc53a-56be-4a0d-aa71-f0acbe4752d7' },
  { label: 'Cerrado', value: 'e53c13ff-d616-4055-8f0a-514b12207732' },
  // { label: 'Vencido', value: '3c3981d6-69b9-4e1c-ae88-aef0368f2fd8' },
  { label: 'Cancelado', value: 'c69a3342-464b-4d1e-93a5-e98d89e80897' },
  { label: 'Extendido', value: 'afc372aa-88a6-4058-99ff-33bba1e405a8' },
];

const branches = [
  { label: 'CHAPULTEPEC', value: 'ff515133-ef70-45e0-8f7e-7659dbcb9c32' },
  { label: 'PATRIOTISMO', value: '84461aa3-28f4-4496-8472-0a483a14277a' },
  { label: 'TLALPAN', value: 'c589db6b-73f7-4840-8375-89e8bfedb01b' },
  { label: 'TLALNEPANTLA', value: '8349bc4b-7a41-4e10-bf04-7501d937a815' },
  { label: 'VALLEJO', value: '29e168fc-86a6-43ea-b4c6-9e50a5330bb4' },
];

export default [
  {
    label: i18n('DAILY_RENT_CONTRACTS__CONTRACT_NUMBER'),
    field: 'contractNumber',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(10)}
        name="contractNumber"
        searchKey="contractNumber"
      />
    ),
  },
  {
    label: i18n('DAILY_RENT_CONTRACTS__FOLIO'),
    field: 'reservationFolio',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(10)}
        name="folio"
        searchKey="folio"
      />
    ),
  },

  {
    label: i18n('COMMONS__CUSTOMER'),
    field: 'naturalPersonName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(40)}
        name="customerName"
        searchKey="customerName"
      />
    ),
  },
  {
    label: i18n('DAILY_RENT_CONTRACTS__START_DATE'),
    field: 'startDate',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();

      return (
        <AdditionalHeaderCamp
          name="startDate"
          searchKey="startDate"
          mask={numericMask(10)}
          type="DATE"
          version={VERSIONS.V2}
          datePickerProps={{
            resetDate: Boolean(values.startDate),
            onResetDate: () => {
              setFieldValue('startDate', '');
              dispatch(setListParams({ startDate: '' }));
            },
            minimumDate: MIN_SEARCH_DATE,
          }}
        />
      );
    },
  },
  {
    label: i18n('DAILY_RENT_CONTRACTS__FINAL_DATE'),
    field: 'finalDate',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();

      return (
        <AdditionalHeaderCamp
          name="finalDate"
          searchKey="finalDate"
          mask={numericMask(10)}
          type="DATE"
          version={VERSIONS.V2}
          action={setListParams}
          datePickerProps={{
            resetDate: Boolean(values.finalDate),
            onResetDate: () => {
              setFieldValue('finalDate', '');
              dispatch(setListParams({ finalDate: '' }));
            },
            minimumDate: MIN_SEARCH_DATE,
          }}
        />
      );
    },
  },
  {
    label: i18n('DAILY_RENT_CONTRACTS__AMOUNT'),
    field: 'amount',
    align: 'center',
    renderer: ({ value, ...props }) => (
      <CellText value={number2mxn(value || 0)} {...props} />
    ),
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp name="amount" searchKey="amount" type="number" />
    ),
  },
  {
    label: i18n('DAILY_RENT_CONTRACTS__DEPARTURE_BRANCH'),
    field: 'departureBranch',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="departureBranch"
        searchKey="departureBranch"
        options={branches}
        selectProps={{ fullKey: true, availableFirstValue: true }}
        type="SELECT"
        // disabled={getRoleName() != 'CONTACT_CENTER' && getBrach()}
      />
    ),
  },
  {
    label: i18n('COMMONS__PLATE'),
    field: 'plateNumber',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp name="plateNumber" searchKey="plateNumber" />
    ),
  },
  // TODO: Temporary disabled
  // {
  //   label: i18n('DAILY_RENT_CONTRACTS__RETURN_BRANCH'),
  //   field: 'returnBranch',
  //   align: 'center',
  //   renderer: CellText,
  // },
  {
    label: i18n('DAILY_RENT_CONTRACTS__CLOSE_DATE'),
    field: 'closeDate',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();

      return (
        <AdditionalHeaderCamp
          name="closeDate"
          searchKey="closeDate"
          mask={numericMask(10)}
          type="DATE"
          version={VERSIONS.V2}
          action={setListParams}
          datePickerProps={{
            resetDate: Boolean(values.closeDate),
            onResetDate: () => {
              setFieldValue('closeDate', '');
              dispatch(setListParams({ closeDate: '' }));
            },
            minimumDate: MIN_SEARCH_DATE,
          }}
        />
      );
    },
  },
  {
    label: i18n('DAILY_RENT_CONTRACTS__STATUS'),
    field: 'status',
    align: 'center',
    renderer: CellStatus,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="contractStatus"
        searchKey="contractStatus"
        options={statuses}
        selectProps={{ fullKey: true, availableFirstValue: true }}
        type="SELECT"
      />
    ),
  },
];

const CLOSE_STATUS = 'Cerrado';
const CANCEL_STATUS = 'Cancelado';

const LEASING_CONTRACT = {
  label: 'Contrato',
  name: 'leasingContract',
  showExpanded: false,
};

const EXTEND_CONTRACT = {
  label: 'Extender',
  name: 'extendContract',
  showExpanded: false,
};

const PROMISSORY_NOTE_CONTRACT = {
  label: 'Pagaré',
  name: 'promissoryNoteContract',
  showExpanded: false,
};

const CHANGE_VEHICLE = {
  label: i18n('DAILY_RENT_CONTRACTS__LIST__MENU__CHANGE_VEHICLE'),
  name: 'changeVehicle',
  showExpanded: false,
};

const CLOSE_CONTRACT = {
  label: i18n('DAILY_RENT_CONTRACTS__LIST__MENU__CLOSE'),
  name: 'closeContract',
  showExpanded: false,
};

const CANCEL_CONTRACT = {
  label: i18n('DAILY_RENT_CONTRACTS__LIST__MENU__CANCEL'),
  name: 'cancelContract',
  showExpanded: false,
};

export const CLOSE_CONTRACT_DETAIL = {
  label: i18n('DAILY_RENT_CONTRACTS__LIST__MENU__CLOSE_DETAIL'),
  name: 'closeContractDetail',
  showExpanded: false,
};

// export const REFUND = {
//   label: i18n('DAILY_RENT_CONTRACTS__LIST__MENU__REFUND'),
//   name: 'refund',
//   showExpanded: false,
// };

// export const PAY = {
//   label: i18n('DAILY_RENT_CONTRACTS__LIST__MENU__PAY'),
//   name: 'pay',
//   showExpanded: false,
// };

export const UNIT_INVENTORY = {
  label: 'Inventario de la unidad',
  name: 'unit_inventory',
  showExpanded: false,
};

export const contextualMenu = {
  LEASING_CONTRACT,
  PROMISSORY_NOTE_CONTRACT,
  EXTEND_CONTRACT,
  CHANGE_VEHICLE,
  UNIT_INVENTORY,
  // PAY,
  // REFUND,
  CLOSE_CONTRACT,
  CANCEL_CONTRACT,
};

export const getContextualMenu = ({
  leasingContract,
  promissoryNoteContract,
  status,
  reservationStep,
}) => {
  const contextualMenuToSend = { ...contextualMenu };
  const clavesReservationsStep = Object.keys(RESERVATION_STEP);

  if (
    clavesReservationsStep.indexOf(reservationStep) !== -1 &&
    clavesReservationsStep.indexOf('SIGN_DELIVERY') !== -1 &&
    clavesReservationsStep.indexOf(reservationStep) <=
      clavesReservationsStep.indexOf('SIGN_DELIVERY')
  )
    delete contextualMenuToSend.UNIT_INVENTORY;

  if (leasingContract && !promissoryNoteContract) {
    delete contextualMenuToSend.PROMISSORY_NOTE_CONTRACT;
  }

  if (promissoryNoteContract && !leasingContract) {
    delete contextualMenuToSend.LEASING_CONTRACT;
  }

  if (!validateRolePermissions(EXTEND_CONTRACT_RD))
    delete contextualMenuToSend.EXTEND_CONTRACT;
  if (!validateRolePermissions(CLOSE_CONTRACT_RD)) {
    delete contextualMenuToSend.CLOSE_CONTRACT;
    delete contextualMenuToSend.CANCEL_CONTRACT;
  }
  // if (!validateRolePermissions(VIEW_PAYMENTS)) delete contextualMenuToSend.PAY;
  // if (!validateRolePermissions(REFUND_CONTRACT_RD))
  //   delete contextualMenuToSend.REFUND;
  if (!validateRolePermissions(VEHICLE_CONTRACT_RD))
    delete contextualMenuToSend.CHANGE_VEHICLE;
  if (!validateRolePermissions(NOTE_CONTRACT_RD))
    delete contextualMenuToSend.PROMISSORY_NOTE_CONTRACT;

  if (status === CLOSE_STATUS || status === CANCEL_STATUS) {
    delete contextualMenuToSend.CHANGE_VEHICLE;
    delete contextualMenuToSend.EXTEND_CONTRACT;
    delete contextualMenuToSend.CLOSE_CONTRACT;
    delete contextualMenuToSend.CANCEL_CONTRACT;
    // delete contextualMenuToSend.REFUND;
    // delete contextualMenuToSend.PAY;
    contextualMenuToSend.CLOSE_CONTRACT_DETAIL = CLOSE_CONTRACT_DETAIL;
  }

  if (validateRolePermissions(VIEW_REPORTS_CONTACT_CENTER)) {
    delete contextualMenuToSend.UNIT_INVENTORY;
    delete contextualMenuToSend.CLOSE_CONTRACT_DETAIL;
  }

  return contextualMenuToSend;
};
