/* eslint-disable no-param-reassign */
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { customerInformationTransformer } from '@customers/sections/CustomerInformation/transformer';
import { officialIdentificationTransformer } from '@customers/sections/OfficialIdentification/transformer';
import { addressTransformer } from '@customers/sections/Address/transformer';
import { driverLicenseTransformer } from '@customers/sections/DriverLicense/transformer';
import {
  companyDataTransformer,
  companyDataTenderTransformer,
} from '@customers/sections/CompanyData/transformer';
import { authorizedDriversTransformer } from '@customers/sections/DriversInformation/transformer';
import { passportTransformer } from '@customers/sections/PassportInformation/transformer';
import { visaTransformer } from '@customers/sections/VisaInformation/transformer';
import { countryEntranceTransformer } from '@customers/sections/CountryEntranceType/transformer';
import { accommodationInformationTransformer } from '@customers/sections/AccommodationInformation/transformer';
import {
  isJuridicalCustomer,
  isForeignCustomer,
  isTenderCustomer,
} from './utils';
import { CUSTOMER_TYPES, TYPES_OF_DOCUMENTS } from './constants';

const customerTransformer = (customer) => {
  const customerData = _get(customer, 'data', {});

  const {
    tender,
    juridicalPerson,
    identityDocuments = [],
    address = {},
    driverLicense = {},
    countryEntrance = {},
    accommodation = {},
  } = customerData;

  const isJuridical = isJuridicalCustomer(customer.data);
  const isForeign = isForeignCustomer(customer.data);
  const isTender = isTenderCustomer(customer.data);
  const isNatural = !isJuridical && !isForeign && !isTender;
  const customerStatus = _get(customer.data, 'customerStatus', '');
  const personType = !_isEmpty(juridicalPerson);

  const transformedIdentityDocuments = identityDocuments.reduce(
    (accIdentityDocs, identityDocument) => {
      const { identityDocumentType } = identityDocument;

      if (!isForeign) {
        accIdentityDocs.identityDocument = officialIdentificationTransformer(
          identityDocument
        );
      }

      if (isForeign) {
        if (identityDocumentType.identifier === TYPES_OF_DOCUMENTS.PASSPORT) {
          accIdentityDocs.transformedPassport = passportTransformer(
            identityDocument
          );
        }

        if (identityDocumentType.identifier === TYPES_OF_DOCUMENTS.VISA) {
          accIdentityDocs.transformedVisa = visaTransformer(identityDocument);
        }
      }

      return accIdentityDocs;
    },
    {}
  );

  const { identityDocument = {} } = transformedIdentityDocuments;

  const customerInformation = customerInformationTransformer({
    ...customerData,
    customerStatus,
    personType,
    isForeign,
  });

  const addressData = addressTransformer(address);

  const transformedDriverLicense = driverLicenseTransformer(
    driverLicense,
    address,
    isForeign
  );

  const transformedCustomerData = {
    ...customerInformation,
    ...identityDocument,
    ...addressData,
  };

  if (customerData.authorizedDrivers.length > 0) {
    const { authorizedDrivers = [] } = customerData;
    return {
      ...transformedCustomerData,
      ...transformedDriverLicense,
      driverAuthorizedData: authorizedDriversTransformer(authorizedDrivers),
      personType: CUSTOMER_TYPES.FISIC,
      companySocialReason: personType ? juridicalPerson.name : '',
      companyRfc: personType ? juridicalPerson.rfc : '',
      companyFiscalReason: personType ? juridicalPerson.businessName : '',
    };
  }

  if (isNatural) {
    return {
      ...transformedCustomerData,
      companySocialReason: personType ? juridicalPerson.name : '',
      companyRfc: personType ? juridicalPerson.rfc : '',
      companyFiscalReason: personType ? juridicalPerson.businessName : '',
      personType: CUSTOMER_TYPES.FISIC,
      ...transformedDriverLicense,
    };
  }

  if (isJuridical && juridicalPerson) {
    const { authorizedDrivers = [] } = juridicalPerson;
    const companyData = companyDataTransformer(juridicalPerson);
    return authorizedDrivers.length > 0
      ? {
          ...transformedCustomerData,
          ...companyData,
          driverAuthorizedData: authorizedDriversTransformer(authorizedDrivers),
          personType: CUSTOMER_TYPES.JURIDICAL,
        }
      : {
          ...transformedCustomerData,
          ...companyData,
          personType: CUSTOMER_TYPES.JURIDICAL,
        };
  }

  if (isTender && tender) {
    const companyData = companyDataTenderTransformer(tender);

    return {
      ...transformedCustomerData,
      ...companyData,
      personType: CUSTOMER_TYPES.TENDER,
    };
  }

  if (isForeign) {
    const transformedCountryEntrance = countryEntranceTransformer(
      countryEntrance
    );
    const transformedAccommodationInformation = accommodationInformationTransformer(
      accommodation
    );

    return {
      ...transformedCustomerData,
      ...transformedIdentityDocuments.transformedPassport,
      ...transformedIdentityDocuments.transformedVisa,
      ...transformedCountryEntrance,
      ...transformedAccommodationInformation,
      ...transformedDriverLicense,
      personType: CUSTOMER_TYPES.FOREGINER,
    };
  }

  return transformedCustomerData;
};

export default customerTransformer;
