import _orderBy from 'lodash/orderBy';
import _head from 'lodash/head';
import {
  vehicle,
  customers,
  reservations,
  loans,
  transfers,
  contracts,
  invoices,
  payments,
  contractRD,
  quoter,
  reports,
  // workshop,
  returns,
  catalogs,
} from 'utils/menu-sections';
import { validateModuleAccess } from 'utils/roles';

export const routesDefinitions = {
  vehicle,
  customers,
  reservations,
  contractRD,
  loans,
  transfers,
  contracts,
  invoices,
  payments,
  quoter,
  reports,
  // workshop,
  returns,
  catalogs,
};

const reduceRouteDefinitionsSorted = (routesAcc, currentRoute) => {
  const findRoute = ([, route]) => route.moduleName === currentRoute.moduleName;
  const routeEntries = Object.entries(routesDefinitions);
  const routeKey = _head(routeEntries.find(findRoute));

  if (routeKey) {
    // eslint-disable-next-line no-param-reassign
    routesAcc[routeKey] = currentRoute;
  }

  return routesAcc;
};

const routesDefinitionsSorted = _orderBy(
  Object.values(routesDefinitions),
  ['label'],
  ['asc']
).reduce(reduceRouteDefinitionsSorted, {});

export function getDefinitions() {
  return Object.keys(routesDefinitionsSorted)
    .filter((key) =>
      validateModuleAccess(routesDefinitionsSorted[key].moduleName)
    )
    .reduce(
      (acc, name) => {
        const { routes, menuItems } = acc;

        const entry = routesDefinitionsSorted[name];
        const {
          route: baseRoute,
          label,
          icon,
          children,
          disabled,
          show = true,
        } = entry;

        if (disabled) {
          return acc;
        }

        if (children) {
          const inner = Object.keys(children).reduce(
            (innerAcc, innerName) => {
              const { innerRoutes, innerMenuItems } = innerAcc;
              const {
                route,
                label: innerLabel,
                onlyRoute,
                component,
              } = children[innerName];

              const path = `${baseRoute}${route}`;
              const innerRoute = {
                name: innerName,
                path,
                component,
              };
              if (onlyRoute) {
                return {
                  innerRoutes: innerRoutes.concat([innerRoute]),
                  innerMenuItems,
                };
              }
              const innerMenuItem = {
                name: innerName,
                route: path,
                label: innerLabel,
              };

              return {
                innerRoutes: innerRoutes.concat([innerRoute]),
                innerMenuItems: innerMenuItems.concat([innerMenuItem]),
              };
            },
            { innerRoutes: [], innerMenuItems: [] }
          );

          const menuItem = {
            name,
            route: baseRoute,
            label,
            icon,
            children: inner.innerMenuItems,
            show,
          };
          return {
            routes: routes.concat(inner.innerRoutes),
            menuItems: menuItems.concat([menuItem]),
          };
        }
        return acc;
      },
      { routes: [], menuItems: [] }
    );
}
