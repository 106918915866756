import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import {
  getContractDetails,
  cleanContractDetails,
  setCancelMovement,
} from 'store/payments/actions';
import ContractDetail from './ContractDetail';
import { getDetail } from 'store/customers/actions';

export default withLayout(
  compose(
    connect(
      (state) => ({
        contractDetails: state.payments.contractDetails.data,
        contractMovementsList: state.payments.contractMovements,
      }),
      { getContractDetails, cleanContractDetails, setCancelMovement, getDetail }
    )
  )(ContractDetail)
);
