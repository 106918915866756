import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  setTitles,
  setStep,
  getVehicleTypes,
  getTimeLimits,
  getVehicles,
  setParams,
  setCategorySelected,
  openEditScheduleSection,
  closeEditScheduleSection,
  setBrands,
  closeResponseDialog,
  setLeaveStepOne,
  setPreviousCategorySelected,
  setQuoteInternalError,
} from 'store/reservations/actions';

import { fetchCatalog } from 'store/commons/actions';
import { BackButton } from '@reservations/components';
import _get from 'lodash/get';
import { getQuotePath } from 'utils/navigation';
import Two from './Two';

const stepConfig = {
  page: getQuotePath('one'),
};

export default compose(
  connect(
    (state) => ({
      responseDialog: _get(state, 'reservations.responseDialog', {}),
      loader: _get(state, 'commons.loader', false),
      categories: _get(state, 'reservations.filters.categories', []),
      brands: _get(state, 'reservations.filters.brands', []),
      quoteValues: _get(state, 'reservations.quote.values', {}),
      vehicles: _get(state, 'reservations.catalog.results', []),
      totalPages: _get(state, 'reservations.catalog.totalPages', []),
      last: _get(state, 'reservations.catalog.last', []),
      params: _get(state, 'reservations.catalog.params', []),
      branches: _get(state, 'commons.branches.list', []),
      selectedCategory: _get(
        state,
        'reservations.filters.selectedCategory.value',
        ''
      ),
      vehicleTypes: _get(
        state,
        'reservations.filters.selectedCategory.types',
        []
      ),
      left: <BackButton {...stepConfig} />,
      editScheduleAction: _get(
        state,
        'reservations.quote.editScheduleAction',
        {}
      ),
      onCancelPath: `${getQuotePath('one')}?isCanceling=true`,
      previousSelectedCategory: _get(
        state,
        'reservations.filters.previousSelectedCategory',
        null
      ),
    }),
    {
      setTitles,
      setStep,
      getVehicleTypes,
      getTimeLimits,
      getVehicles,
      fetchCatalog,
      setParams,
      setCategorySelected,
      setPreviousCategorySelected,
      openEditScheduleSection,
      closeEditScheduleSection,
      setBrands,
      closeResponseDialog,
      setLeaveStepOne,
      setQuoteInternalError,
    }
  )
)(Two);
