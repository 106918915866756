"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var VERSIONS = {
  V1: 'V1',
  V2: 'V2'
};
var _default = exports["default"] = VERSIONS;