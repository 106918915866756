import { useEffect, useCallback, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';

import SearchInput from 'components/SearchInput';
import useCleanModule from 'hooks/useCleanModule';

import i18n from '@i18n';

export default function SearchInvoice({
  history,
  invoiceDetails,
  getInvoiceDetails,
  hasNotFound,
}) {
  const [hasSearched, setHasSearched] = useState(false);

  useCleanModule({ module: 'invoices' });

  const handleSearch = useCallback(({ searchText }) => {
    getInvoiceDetails(searchText);
    setHasSearched(true);
  }, []);

  useEffect(() => {
    if (!_isEmpty(invoiceDetails) && hasSearched) {
      const { serie, folio } = invoiceDetails;
      const realFolio = `${serie}${folio}`;
      history.push(`/invoices/${realFolio}/detail`);
    }
  }, [invoiceDetails, hasSearched]);

  return (
    <section>
      <div className="col-md-12">
        <SearchInput
          name="searchText"
          placeholder="Buscar factura"
          subtitle="Folio de factura o No. de contrato."
          onSubmit={handleSearch}
          schema={yup.object().shape({})}
        />
      </div>
      {hasNotFound && (
        <div className="row">
          <div className="col-12">
            <h3 className="text-center">{i18n('ERROR__COMMONS__NOT_FOUND')}</h3>
          </div>
        </div>
      )}
    </section>
  );
}
