import React, { useCallback, useEffect, useState } from 'react';
import {
  Table,
  Icons,
  ActionModal,
  withForm,
  withLayout,
  MaskedInput as Input,
  DatePickerV2,
  Button,
} from '@casanova/casanova-common';
import { isSafari } from 'utils/browser';
import { hourMask } from 'utils/masks';
import { TableBottom, FormModal } from 'components';
import { SHOW_DROPDOWN_MODE } from '@casanova/casanova-common/lib/utils/constants/index.js';
import { transformDropdownElements } from 'utils/transformHelpers';
import i18n from '@i18n';
import { CREATE_TRANSFER_REQUEST } from 'utils/roles/permissions/transfersPermissions';
import useCleanModule from 'hooks/useCleanModule';
import {
  getNextYear,
  getCurrentTime,
} from '@casanova/casanova-common/lib/utils/date';
import { config } from './config';
import columns, {
  contextualMenu,
  getTransferContextualMenuItems,
  showDropdownCell,
} from './columns';

import { saveDatesTransfers } from 'services/transfers';

function ListOfUsers({
  history,
  transfers,
  getTransfers,
  fetchCatalog,
  closeTransfer,
  cancelTransfer,
  setTablePage,
  authorizeTransfer,
  rejectTransfer,
}) {
  const { params, searchPattern, filters, results } = transfers;

  const [openConfirmationMessage, setOpenConfirmationMessage] = useState(false);
  const [currentUuid, setCurrentUuid] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const [showModifyModal, setShowModifyModal] = useState(false);
  useCleanModule({ module: 'transfers' });

  const closeModal = () => {
    setShowModifyModal(false);
  };

  const handleAddClick = useCallback(() => {
    history.push('/transfers/add');
  }, [history]);

  useEffect(() => {
    getTransfers({ params, filters, searchPattern });
  }, [getTransfers, params, filters, searchPattern]);

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/warehouses',
      catalogPath: 'vehicle',
    });
    fetchCatalog({
      catalogId: 'catalogs/transfer-statuses',
      catalogPath: 'vehicle',
    });
  }, []);

  const handleRowClick = useCallback(
    ({ uuid }) => {
      history.push(`/transfers/${uuid}/detail`);
    },
    [history.push]
  );

  const handleDropdownSelect = useCallback(
    ({ uuid, name }) => {
      setCurrentUuid(uuid);

      switch (name) {
        // case contextualMenu.AUTHORIZE.name:
        //   setSelectedAction('AUTHORIZE');
        //   setOpenConfirmationMessage(true);
        //   break;
        case contextualMenu.REJECT.name:
          setSelectedAction('REJECT');
          setOpenConfirmationMessage(true);
          break;
        case contextualMenu.EDIT.name:
          history.push(`/transfers/${uuid}/edit`);
          break;
        case contextualMenu.CANCEL.name:
          setSelectedAction('CANCEL');
          setOpenConfirmationMessage(true);
          break;
        case contextualMenu.CLOSE.name:
          setShowModifyModal(true);
          setSelectedAction('CLOSE');
          // setOpenConfirmationMessage(true);
          break;
        default:
          break;
      }
    },
    [history]
  );

  const handleCancelAction = useCallback(() => {
    setOpenConfirmationMessage(false);
  }, []);

  // const authorizeConfirmation = () => authorizeTransfer(currentUuid);
  const rejectConfirmation = () => rejectTransfer(currentUuid);
  const cancelConfirmation = () => cancelTransfer(currentUuid);
  const closeConfirmation = () => closeTransfer(currentUuid);

  const CONFIRMATIONS_BY_ACTIONS = {
    // AUTHORIZE: authorizeConfirmation,
    REJECT: rejectConfirmation,
    CANCEL: cancelConfirmation,
    CLOSE: closeConfirmation,
  };

  const [getRealDeperture, setRealDeperture] = useState('');
  const realDeperture = (value) => {
    setRealDeperture(value);
  };

  const [getReturnRealDate, setReturnRealDate] = useState('');
  const returnRealDate = (value) => {
    setReturnRealDate(value);
  };

  const [getDepartureRealTime, setDepartureRealTime] = useState('');
  const departureRealTime = (value) => {
    setDepartureRealTime(value);
  };

  const [getArrivalRealTime, setArrivalRealTime] = useState('');
  const arrivalRealTime = (value) => {
    setArrivalRealTime(value);
  };

  const handleConfirmActionA = () => {
    // Modify dates
    setShowModifyModal(false);

    const confirmation = CONFIRMATIONS_BY_ACTIONS[selectedAction];

    saveDatesTransfers(currentUuid, {
      departureRealDate: getRealDeperture,
      departureRealTime: getDepartureRealTime,
      returnRealDate: getReturnRealDate,
      arrivalRealTime: getArrivalRealTime,
    });

    if (confirmation) confirmation();
  };

  const handleConfirmAction = () => {
    setOpenConfirmationMessage(false);

    const confirmation = CONFIRMATIONS_BY_ACTIONS[selectedAction];

    if (confirmation) confirmation();
  };

  results.map((e) => {
    if (e.realDepartureDateTime === 'Fecha inválida')
      e.realDepartureDateTime = 'Sin información';
    if (e.realArrivalDateTime === 'Fecha inválida')
      e.realArrivalDateTime = 'Sin información';
    return e;
  });

  return (
    <>
      <ActionModal
        open={openConfirmationMessage}
        icon={<Icons.StatusWarning />}
        onClose={handleCancelAction}
        onAction={handleConfirmAction}
        title={i18n(`TRANSFERS__DETAIL__MODAL__${selectedAction}_TITLE`)}
        message={i18n(`TRANSFERS__DETAIL__MODAL__${selectedAction}_BODY`)}
        actionLabel={i18n('COMMONS__YES_CONTINUE__TEXT')}
        closeButton
      />

      <FormModal
        onSubmit={handleConfirmActionA}
        open={showModifyModal}
        title="CERRAR TRASLADO"
        onCancel={closeModal}
      >
        <div className="row">
          <div className="col-6">
            <DatePickerV2
              placeholder="FECHA DE SALIDA REAL"
              name="departureRealDate"
              label="FECHA DE SALIDA REAL"
              autoComplete="off"
              placement={isSafari() ? 'bottom' : undefined}
              onChange={(e) => realDeperture(e)}
              maximumDate={getNextYear({
                years: 0,
                months: new Date().getMonth() + 1,
              })}
              formatDateValue
            />
          </div>
          <div className="col-6">
            <Input
              mask={hourMask}
              maskPlaceholder=""
              label="Hora de salida real"
              placeholder="Hora de salida real"
              name="departureRealTime"
              onChange={(e) => departureRealTime(e.target.value)}
              right={<Icons.Time width="85%" height="85%" />}
            />
          </div>
          <div className="col-6">
            <DatePickerV2
              placeholder="FECHA DE LLEGADA REAL"
              name="returnRealDate"
              label="FECHA DE LLEGADA REAL"
              autoComplete="off"
              placement={isSafari() ? 'bottom' : undefined}
              onChange={(e) => returnRealDate(e)}
              maximumDate={getNextYear({
                years: 0,
                months: new Date().getMonth() + 1,
              })}
              formatDateValue
            />
          </div>
          <div className="col-6">
            <Input
              mask={hourMask}
              maskPlaceholder=""
              label="HORA DE LLEGADA real"
              placeholder="Hora de llegada real"
              name="arrivalRealTime"
              onChange={(e) => arrivalRealTime(e.target.value)}
              right={<Icons.Time width="85%" height="85%" />}
            />
          </div>
        </div>
      </FormModal>

      <Table
        additionalHeader
        rowClassName="row-clickable"
        emptyMessage="No se encontraron resultados"
        dataList={results}
        columns={columns.map((col) => ({
          ...col,
          renderer: (props = { data: {} }) => {
            const { transferStatus = {} } = props.data;
            const Component = col.renderer;
            return (
              <Component
                {...props}
                dropdownOptions={transformDropdownElements({
                  elements: getTransferContextualMenuItems(
                    transferStatus.identifier,
                    props.data
                  ),
                })}
              />
            );
          },
        }))}
        rowClick={handleRowClick}
        dropdownCell={showDropdownCell}
        showDropdownMode={SHOW_DROPDOWN_MODE.POINTS_CLICK}
        dropdownOptions={transformDropdownElements({
          elements: Object.values(contextualMenu),
        })}
        onDropdownSelect={handleDropdownSelect}
        withEllipsis
      />
      <TableBottom
        search={transfers}
        onChange={setTablePage}
        onClick={handleAddClick}
        textButton={i18n('TRANSFERS__LIST__NEW_BUTTON')}
        actionPermission={CREATE_TRANSFER_REQUEST}
      />
    </>
  );
}

export default withForm({ config })(withLayout(ListOfUsers));
