import { CellText, CellStatus } from 'components/ColumnsCells';
import i18n from '@i18n';

export const columns = [
  {
    label: 'Contrato',
    field: 'contractNumber',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('COMMONS__CUSTOMER'),
    field: 'customer',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_CONTRACTS__TABLE__INITIAL_DATE'),
    field: 'initialDate',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_CONTRACTS__TABLE__END_DATE'),
    field: 'returnDate',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_CONTRACTS__TABLE__REAL_END_DATE'),
    field: 'realReturnDate',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('COMMONS__BRANCH'),
    field: 'branch',
    align: 'center',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_CONTRACTS__TABLE__APERTURE_USER'),
    field: 'apertureUser',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_CONTRACTS__TABLE__CLOSE_USER'),
    field: 'closeUser',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_ESTATUS__TABLE__ESTATUS'),
    field: 'status',
    align: 'center',
    renderer: CellStatus,
  },
];
