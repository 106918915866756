import { useEffect, useCallback, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import SearchInput from 'components/SearchInput';
import useCleanModule from 'hooks/useCleanModule';
import i18n from '@i18n';

export default function ContractSearch({
  contractDetails,
  hasNotFound,
  getContractDetails,
  history,
}) {
  const [hasSearched, setHasSearched] = useState(false);
  useCleanModule({ module: 'paymentAndCharges' });

  const handleSearch = useCallback(({ searchText }) => {
    getContractDetails(searchText);
    setHasSearched(true);
  }, []);

  useEffect(() => {
    if (!_isEmpty(contractDetails) && hasSearched) {
      const { number } = contractDetails;
      history.push(`/payments-and-charges/${number}/detail`);
    }
  }, [contractDetails, hasSearched]);

  return (
    <section>
      <div className="col-md-12">
        <SearchInput
          name="searchText"
          placeholder="Buscar contrato"
          subtitle="Buscar por ID de contrato."
          onSubmit={handleSearch}
        />
      </div>
      {hasNotFound && (
        <div className="row">
          <div className="col-12">
            <h3 className="text-center">{i18n('ERROR__COMMONS__NOT_FOUND')}</h3>
          </div>
        </div>
      )}
    </section>
  );
}
