import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

function Breadcrumb(props) {
  const { activeRoute } = props;
  const { general, specific } = activeRoute;
  return (
    <>
      {general && <span className={styles.title}> {general.label} </span>}
      {specific && specific.label && (
        <>
          |<span className={styles.subTitle}> {specific.label} </span>{' '}
        </>
      )}
    </>
  );
}

Breadcrumb.propTypes = {
  activeRoute: PropTypes.shape({
    general: PropTypes.shape({
      label: PropTypes.string,
    }),
    specific: PropTypes.shape({
      label: PropTypes.string,
    }),
  }),
};

Breadcrumb.defaultProps = {
  activeRoute: {
    general: null,
    specific: null,
  },
};

export default Breadcrumb;
