import { takeLatest, fork } from 'redux-saga/effects';
import { names } from './actions';
import types from './actions-types';
import * as sagas from './sagas';

export default function* watchers() {
  yield fork(sagas.startAuthSaga);
  yield takeLatest(names.login[0], sagas.loginSaga);
  yield takeLatest(types.HYDRATE_USER_DATA, sagas.hydrateUserDataSaga);
}
