import _get from 'lodash/get';
import * as yup from 'yup';
import {
  resetReservations,
  clearReservationInfo,
  getReservationInfo,
  setDeliveryStep,
  closeResponseDialog,
  verifyCheckPoint,
  setActualPosition,
  sendFormLinkFirm,
  verifySignaturesCompleted,
  verifyCompletedCheckList,
  setExtraCharges,
  cleanSendFormLinkFirm,
  resetSignatures,
  rejectSignatures,
} from 'store/reservations/actions';
import { showLoader, hideLoader, fetchCatalog } from 'store/commons/actions';
import { getVerificationCheckListDeliveryReturn } from '@reservations/commons/utils';

export const mapStateToPropsOfDeliveryAndReturn = (state, type = '') => ({
  type,
  loader: _get(state, 'commons.loader', false),
  quote: _get(state, 'reservations.quote', {}),
  reservationInfo: _get(state, 'reservations.reservationInfo.data', {}),
  verificationCheckList: getVerificationCheckListDeliveryReturn({
    reservationInfo: _get(state, 'reservations.reservationInfo', {}),
    checkListType: type,
  }),
  responseDialog: _get(state, 'reservations.responseDialog', {}),
  verifyCheckPointState: _get(state, 'reservations.verifyCheckPoint', {}),
  isCompleted: _get(state, 'reservations.signatures.isCompleted', false),
  accepted: _get(state, 'reservations.signatures.accepted', null),
  isSending: _get(state, 'reservations.signatures.isSending', false),
  verifyCompletedCheckListState: _get(
    state,
    'reservations.verifyCompletedCheckList',
    {}
  ),
  extraCharges: _get(state, 'reservations.extraCharges', {}),
  sendFirmForm: _get(state, 'reservations.sendFirmForm', {}),
  payment: _get(state, 'reservations.reservation.payment', {}),
  branches: _get(state, 'commons.branches.list', []),
  catalogsBankCardTypes: _get(state, 'commons.catalogsBankCardTypes.list', []),
});

export const dispatchToPropsOfDeliveryAndReturn = {
  resetReservations,
  clearReservationInfo,
  getReservationInfo,
  setStep: setDeliveryStep,
  closeResponseDialog,
  verifyCheckPoint,
  sendFormLinkFirm,
  closeResDialog: closeResponseDialog,
  showLoader,
  hideLoader,
  setActualPosition,
  verifySignaturesCompleted,
  verifyCompletedCheckList,
  setExtraCharges,
  cleanSendFormLinkFirm,
  fetchCatalog,
  resetSignatures,
  rejectSignatures,
};

export const schemaStepTwo = yup.object().shape({
  formUuid: yup.string(),
  verificationPointsOptionsBar: yup.array(),
  email: yup.string(),
  cellphone: yup.string(),
  user: yup.string(),
  area: yup.string(),
  match: yup.object(),
  deliveryCirculationType: yup.bool(),
  returnCirculationType: yup.bool(),
});

export const initialTouchedStepTwo = {
  formUuid: true,
  verificationPointsOptionsBar: true,
  deliveryCirculationType: true,
  returnCirculationType: true,
};

export const initialValuesStepTwo = {
  formUuid: '',
  verificationPointsOptionsBar: [],
  email: '',
  cellphone: '',
  user: '',
  area: '',
  match: {},
  deliveryCirculationType: '',
  returnCirculationType: '',
};
