import { VEHICLE_DETAILS_LABEL } from './constants';

export const vehicleDetailTransformer = (details = {}) => {
  const formatedDetails = Object.keys(details)
    .filter((detailKey) => VEHICLE_DETAILS_LABEL[detailKey])
    .map((detailKey) => {
      const value = details[detailKey];
      const label = VEHICLE_DETAILS_LABEL[detailKey];
      const isCirculation = detailKey === 'circulationCardHasCopy';
      return {
        label,
        value: isCirculation ? 'Original' : value,
      };
    });
  return formatedDetails;
};

export const getExtraChargesTransformer = (verificationGroups) => {
  let listOfExtraCharges = [];

  const mapExtraCharges = ({ uuid, name, thumbnail, remarks }) => (
    extraCharge
  ) => {
    const formattedExtraCharge = {
      ...extraCharge,
      groupName: name,
      thumbnail,
      remarks,
      id: `${uuid}-${extraCharge.uuid}`,
    };
    listOfExtraCharges = [...listOfExtraCharges, formattedExtraCharge];
  };

  const mapVerifyPoint = ({ name = '', thumbnail = '' }) => ({
    extraCharges = [],
    remarks = '',
    uuid,
  }) => {
    extraCharges.forEach(mapExtraCharges({ uuid, name, thumbnail, remarks }));
  };

  const mapVerifyGroup = (group) => {
    const { verificationPoints = [] } = group;
    verificationPoints.forEach(mapVerifyPoint(group));
  };

  verificationGroups.forEach(mapVerifyGroup);

  return listOfExtraCharges;
};

export const getSpectList = ({ isSet = false, passed = false }) => {
  if (isSet) {
    return passed ? 'BUEN ESTADO' : 'DETALLE';
  }
  return 'SIN INFORMACIÓN';
};
