import _get from 'lodash/get';

export const passportFormatter = (values) => ({
  uuid: _get(values, 'uuid', ''),
  identifier: _get(values, 'identifier', ''),
  identityKey: _get(values, 'passportKey', ''),
  issuedYear: _get(values, 'passportYearIssue', ''),
  validityYear: _get(values, 'passportValidity', ''),
  country: _get(values, 'passportCountry', ''),
});
