import CustomersModuleIcon from '@casanova/casanova-common/lib/Icons/CustomersModule';
import CustomerEditor from 'containers/customers/CustomerEditor';
import {
  CUSTOMERS_MODULE,
  VIEW_CUSTOMER_CATALOG,
} from 'utils/roles/permissions/customerPermissions';
import CustomerDetail from '@customers/CustomerDetail';
import CustomerCreator from '@customers/CustomerCreator';
import CustomerList from '@customers/CustomerList';
import { withRoleRestrictions } from 'utils/auth';
import { withReconcileNav } from 'containers/Breadcrumb';
import { validateRolePermissions } from 'utils/roles';

export const customers = {
  moduleName: CUSTOMERS_MODULE,
  route: '/customers',
  label: 'Clientes',
  icon: CustomersModuleIcon,
  main: 'all',
  show: validateRolePermissions(VIEW_CUSTOMER_CATALOG),
  children: {
    all: {
      route: '',
      label: '',
      component: withReconcileNav(
        'customers',
        'all'
      )(withRoleRestrictions(CustomerList)),
    },
    add: {
      route: `/add`,
      label: 'Nuevo',
      format: () => `/customers/add`,
      onlyRoute: true,
      component: withReconcileNav(
        'customers',
        'add'
      )(withRoleRestrictions(CustomerCreator)),
    },
    detail: {
      route: `/:id/detail`,
      label: 'Detalle',
      format: (id) => `/customers/${id}/detail`,
      onlyRoute: true,
      component: withReconcileNav(
        'customers',
        'detail'
      )(withRoleRestrictions(CustomerDetail)),
    },
    edit: {
      route: `/:id/edit`,
      label: 'Editar',
      format: (id) => `/customers/${id}/edit`,
      onlyRoute: true,
      component: withReconcileNav(
        'customers',
        'edit'
      )(withRoleRestrictions(CustomerEditor)),
    },
  },
};
