import { useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import { useSelector } from 'react-redux';

import { ActionModal, Icons } from '@casanova/casanova-common';
import i18n from '@i18n';

export default function ResponseDialog({
  SuccessIcon = Icons.IcoCarOk,
  ErrorIcon = Icons.IcoCarError,
  hideWhenLoaderIsOpen = true,
  closeButton = true,
  messageBold = false,
  textBold = [],
  onSuccess = _noop,
  onError = _noop,
  onTryAgain,
  onClose = _noop,
  modalType = 'confirmation',
  open = false,
  success,
  errorCode,
  successMessage,
  errorMessage = i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
  successTitle,
  errorTitle = i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
  successLabel,
  errorLabel = i18n('COMMONS__TRY_AGAIN'),
}) {
  const loader = useSelector((state) => state.commons.loader);

  const handleSuccess = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  const handleError = useCallback(() => {
    if (onTryAgain) onTryAgain();
    else onError();
  }, [onError, onTryAgain]);

  return (
    <ActionModal
      icon={success ? <SuccessIcon /> : <ErrorIcon />}
      open={hideWhenLoaderIsOpen ? open && !loader : open}
      success={success}
      title={success ? successTitle : errorTitle}
      actionLabel={success ? successLabel : errorLabel}
      message={success ? successMessage : errorMessage}
      code={errorCode}
      closeButton={closeButton}
      textBold={textBold}
      messageBold={messageBold}
      onAction={success ? handleSuccess : handleError}
      onClose={onClose}
      modalType={modalType}
    />
  );
}

ResponseDialog.propTypes = {
  open: PropTypes.bool,
  success: PropTypes.bool,
  errorCode: PropTypes.string,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  successTitle: PropTypes.string,
  errorTitle: PropTypes.string,
  successLabel: PropTypes.string,
  errorLabel: PropTypes.string,
  SuccessIcon: PropTypes.any,
  ErrorIcon: PropTypes.any,
  hideWhenLoaderIsOpen: PropTypes.bool,
  closeButton: PropTypes.bool,
  messageBold: PropTypes.bool,
  textBold: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onTryAgain: PropTypes.func,
  onClose: PropTypes.func,
  modalType: PropTypes.string,
};
