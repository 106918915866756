import { PAYMENTS_MODULE } from 'utils/roles/permissions';
import { PAYMENTS_LIST_PATH, PAYMENTS_DETAIL_PATH } from 'utils/roles/paths';

export function paymentsAuth(permissions) {
  
  const allowedModules = [];

  const paymentModule = permissions.filter((permission) =>
    permission.includes('PAYMENT')
  );

  if (paymentModule.length > 0) {
    allowedModules.push(PAYMENTS_MODULE);
  }

  return allowedModules;
}

export function paymentsPathsAuth() {
  const allowedPaths = [PAYMENTS_LIST_PATH, PAYMENTS_DETAIL_PATH];

  return allowedPaths;
}
