import React from 'react';
import i18n from '@i18n';
import { Section, Card, TextField } from 'components';
import './clientInfo.scss';

export default function ClientInfo({
  title = '',
  folio = '',
  fullName = '',
  cellphone = '',
  email = '',
  landline = '',
}) {
  return (
    <Section title={title} topBar={false}>
      <Card noStatus noTitle padding="px-5 py-3">
        <div className="container-fluid px-1">
          <div className="row my-2">
            <div className="col-12">
              <h6>{i18n('RESERVATIONS__SECTION__CLIENT_INFO__TITLE')}</h6>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-2">
              <TextField noMb title="Nombre" value={fullName} />
            </div>
            <div className="col-2">
              <TextField noMb title="Número de celular" value={cellphone} />
            </div>
            <div className="col-2">
              <TextField
                noMb
                title="Número de casa y oficina"
                value={landline}
              />
            </div>
            <div className="col">
              <TextField noMb title="Correo electrónico" value={email} />
            </div>
            <div className="col-4 align-items-center d-flex justify-content-end">
              <h4 className="Folio__title text-right">{`${i18n(
                'RESERVATIONS__DETAIL__FOLIO'
              )} ${folio}`}</h4>
            </div>
          </div>
        </div>
      </Card>
    </Section>
  );
}
