import React from 'react';
import classNames from 'classnames';
import { Icons } from '@casanova/casanova-common';
import moment from 'moment';
import PropTypes from 'prop-types';
import './SchedDate.scss';

function SchedDate({ date, branch, title, className, end = false }) {
  const dateFormated = moment(date, 'yyyy-MM-DD hh:mm')
    .locale('es')
    .format('LLL');
  const cls = classNames('SchedDate', className);
  return (
    <div className={cls}>
      <div className="icon">
        {end ? (
          <Icons.SchedEndVehicle height="2.75rem" width="2.75rem" />
        ) : (
          <Icons.SchedStartVehicle height="2.75rem" width="2.75rem" />
        )}
      </div>
      <div className="sched">
        <span className="title">{title}</span>
        <div className="date">{dateFormated}</div>
        <div className="branch">Sucursal {branch}</div>
      </div>
    </div>
  );
}

SchedDate.propTypes = {
  date: PropTypes.string,
  branch: PropTypes.string,
  title: PropTypes.string,
};

export default SchedDate;
