import SchedEndVehicle from '@casanova/casanova-common/lib/Icons/SchedEndVehicle';
import {
  RETURNS_MODULE,
  VIEW_RETURNS,
} from 'utils/roles/permissions/returnsPermissions';
import { withRoleRestrictions } from 'utils/auth';
import { withReconcileNav } from 'containers/Breadcrumb';
import { validateRolePermissions } from 'utils/roles';
import ListOfReturns from '@reservations/ListOfReturns';
import {
  ReturnStepOne,
  ReturnStepTwo,
  ReturnStepThree,
} from '@reservations/Refund';
import ExtraCharges from '@reservations/ExtraCharges';
import PaymentExtraCharges from '@reservations/PaymentExtraCharges';
import i18n from '@i18n';

export const returns = {
  moduleName: RETURNS_MODULE,
  route: '/returns',
  label: 'Devoluciones',
  icon: SchedEndVehicle,
  main: 'main',
  show: validateRolePermissions(VIEW_RETURNS),
  children: {
    main: {
      route: '',
      label: '',
      component: withReconcileNav(
        'returns',
        'main'
      )(withRoleRestrictions(ListOfReturns)),
    },
    refund: {
      route: `/:id/refund`,
      label: i18n('RESERVATIONS__REFUND__BREADCRUMB'),
      format: (id) => `/returns/${id}/refund`,
      onlyRoute: true,
      component: withReconcileNav(
        'returns',
        'refund'
      )(withRoleRestrictions(ReturnStepOne)),
    },
    refundVehicleParts: {
      route: `/:id/refund-vehicle-parts`,
      label: i18n('RESERVATIONS__REFUND__BREADCRUMB'),
      format: (id) => `/returns/${id}/refund-vehicle-parts`,
      onlyRoute: true,
      component: withReconcileNav(
        'returns',
        'refundVehicleParts'
      )(withRoleRestrictions(ReturnStepTwo)),
    },
    refundInformationAndFirm: {
      route: `/:id/refund-information-and-firm`,
      label: i18n('RESERVATIONS__REFUND__BREADCRUMB'),
      format: (id) => `/returns/${id}/refund-information-and-firm`,
      onlyRoute: true,
      component: withReconcileNav(
        'returns',
        'refundInformationAndFirm'
      )(withRoleRestrictions(ReturnStepThree)),
    },
    extraCharges: {
      route: `/:id/extra-charges`,
      label: i18n('RESERVATIONS__EXTRA_CHARGES__BREADCRUMB'),
      format: (id) => `/returns/${id}/extra-charges`,
      onlyRoute: true,
      component: withReconcileNav(
        'returns',
        'extraCharges'
      )(withRoleRestrictions(ExtraCharges)),
    },
    paymentExtraCharges: {
      route: `/:id/extra-charges/payment`,
      label: i18n('RESERVATIONS__PAYMENT_EXTRA_CHARGES__BREADCRUMB'),
      format: (id) => `/returns/${id}/extra-charges/payment`,
      onlyRoute: true,
      component: withReconcileNav(
        'returns',
        'paymentExtraCharges'
      )(withRoleRestrictions(PaymentExtraCharges)),
    },
  },
};
