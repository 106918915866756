import {
  CUSTOMERS_MODULE,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_USER,
  CREATE_CUSTOMER_VALIDATED,
  CREATE_CUSTOMER_SPECIAL,
  VIEW_CUSTOMER_CATALOG,
  EDIT_CUSTOMER_INFORMATION,
} from 'utils/roles/permissions/customerPermissions';
import {
  CUSTOMERS_VIEW_CATALOG_PATH,
  CUSTOMERS_ADD_PATH,
  CUSTOMERS_DETAIL_PATH,
  CUSTOMERS_EDIT_PATH,
} from 'utils/roles/paths';

export function customersAuth(permissions) {
  const allowedModules = [];

  const customerModule = permissions.some((permission) =>
    permission.includes('CUSTOMER')
  );

  if (customerModule) {
    allowedModules.push(CUSTOMERS_MODULE);
  }

  return allowedModules;
}

export function customersPathsAuth(permissions) {
  const allowedPaths = [];

  if (permissions.includes(VIEW_CUSTOMER_CATALOG)) {
    allowedPaths.push(CUSTOMERS_VIEW_CATALOG_PATH);
  }

  const viewCustomerPermissions = permissions.filter((permission) =>
    permission.startsWith('VIEW_CUSTOMER')
  );

  const CREATE_PERMISSIONS = [
    CREATE_CUSTOMER,
    CREATE_CUSTOMER_USER,
    CREATE_CUSTOMER_VALIDATED,
    CREATE_CUSTOMER_SPECIAL,
  ];

  if (CREATE_PERMISSIONS.some((current) => permissions.includes(current))) {
    allowedPaths.push(CUSTOMERS_ADD_PATH);
  }

  const editCustomerPermissions = permissions.filter(
    (permission) =>
      permission.startsWith(EDIT_CUSTOMER_INFORMATION) ||
      permission.startsWith('EDIT_USER')
  );

  if (viewCustomerPermissions.length > 0)
    allowedPaths.push(CUSTOMERS_DETAIL_PATH);
  if (editCustomerPermissions.length > 0)
    allowedPaths.push(CUSTOMERS_EDIT_PATH);

  return allowedPaths;
}
