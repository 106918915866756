import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import { Icons } from '@casanova/casanova-common';
import _head from 'lodash/head';
import _concat from 'lodash/concat';
import styles from './styles.module.css';

export default function DropFile({
  icon,
  height,
  accept,
  isMultiple = false,
  files = [],
  onDropFile,
  buttonLabel = '',
  error,
}) {
  const [isDragRejected, setIsDragRejected] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (isMultiple) {
        onDropFile(_concat(files, acceptedFiles));
      } else {
        onDropFile(acceptedFiles);
      }
      setIsDragRejected(false);
    },
    [files, isMultiple, onDropFile]
  );

  const onDropRejected = useCallback(() => {
    setIsDragRejected(true);
  }, []);

  const onDragEnter = useCallback(() => {
    setIsDragRejected(false);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    multiple: isMultiple,
    onDrop,
    accept,
    onDropRejected,
    onDragEnter,
  });
  const hasFile = !isMultiple && !!files.length;
  const hasMultipleFiles = isMultiple && !!files.length;
  const classes = classnames(styles.dropFile, {
    [styles.dragRejected]: isDragReject,
    [styles.dragRejected]: isDragRejected,
    [styles.dragActive]: isDragActive,
    'flex-sm-column': isMultiple,
    [styles.empty]: !hasFile,
    [styles.hasFile]: hasFile || hasMultipleFiles,
  });

  return (
    <div>
      {isDragRejected && (
        <h6 className="font-weight-normal text-uppercase text-danger">
          Error al cargar archivo
        </h6>
      )}
      <div
        className={classes}
        {...getRootProps()}
        style={{ maxHeight: height, minHeight: height }}
      >
        <input {...getInputProps()} />
        {hasMultipleFiles ? '' : icon(hasFile)}
        {!hasFile && !hasMultipleFiles && (
          <div style={{ width: '321px' }}>
            Presiona para agregar. O si prefieres, arrastra y suelta el archivo
            en este espacio.
          </div>
        )}
        {hasFile && (
          <span className="text-secondary font-weight-bolder">
            {_head(files).name}
          </span>
        )}
        {hasMultipleFiles && (
          <ul
            className={styles.list}
            style={{ maxHeight: height, minHeight: height }}
          >
            {files.map((file) => (
              <li key={file.name}>
                {icon(hasMultipleFiles)} {file.name}
              </li>
            ))}
            <li>
              <button className="btn btn-link p-0">
                <span className="h4 font-weight-normal">+</span> {buttonLabel}
              </button>
            </li>
          </ul>
        )}
      </div>
      {error && error !== '' && <p className={styles.comment}>{error}</p>}
      {isDragRejected && (
        <p className={styles.comment}>
          <span>
            <Icons.Comment />
          </span>
          El formato es inválido.
        </p>
      )}
    </div>
  );
}
