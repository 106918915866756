import PropTypes from 'prop-types';
import { Modal, Button } from '@casanova/casanova-common';

import i18n from '@i18n';

import './PDFModal.scss';

export default function PDFModal({
  show,
  onClose,
  url,

  id = 'current_pdf',
  name = 'current_pdf',

  showLeftButton = false,
  leftButtonLabel,
  onClickLeft,
}) {
  return (
    <Modal
      open={show}
      onClose={onClose}
      closeButton
      bigModal
      containerClassName="PDFModal"
    >
      <div className="PDFModal__Pdf">
        <iframe
          title={i18n('RESERVATIONS__LEASING__STEPPER__TITLE')}
          src={url}
          frameBorder="0"
          id={id}
          name={name}
        />
      </div>
      <div className="row mt-1">
        {showLeftButton && (
          <div className="col-4">
            <Button outline block onClick={onClickLeft}>
              {leftButtonLabel}
            </Button>
          </div>
        )}

        <div className="col" />

        <div className="col-4">
          <Button block onClick={onClose}>
            {i18n('COMMONS__CLOSE__TEXT')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

PDFModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,

  id: PropTypes.string,
  name: PropTypes.string,

  showLeftButton: PropTypes.bool,
  leftButtonLabel: PropTypes.string,
  onClickLeft: PropTypes.func,
};
