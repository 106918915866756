import { sendCustomRequest } from '@casanova/casanova-common/lib/utils/service';

import { userRoot } from 'services/roots';

export async function postAvaibleUser(body, options) {
  const endpoint = `${userRoot}/users/available${
    body.naturalPerson ? `/?naturalPerson=${body.naturalPerson}` : ''
  }`;
  const response = await sendCustomRequest(endpoint, options, body, 'post');
  return response;
}
