import React from 'react';
import { FormModal, ConnectedSelect } from 'components';
import PropTypes from 'prop-types';
import i18n from '@i18n';

function UpgradeModal({ id, onCancel, onUpgrade, open }) {
  return (
    <FormModal
      id={id}
      open={open}
      onCancel={onCancel}
      onSubmit={onUpgrade}
      onSubmitLabel={i18n('RESERVATIONS__CONTRACT__MODAL__ON_UPGRADE')}
      title={i18n('RESERVATIONS__CONTRACT__MODAL__TITLE')}
      description={i18n('RESERVATIONS__CONTRACT__MODAL__DESCRIPTION')}
    >
      <ConnectedSelect
        name="vehicleUpgradeType"
        label={i18n('RESERVATIONS__CONTRACT__MODAL__SELECT')}
        placeholder={i18n('RESERVATIONS__CONTRACT__MODAL__SELECT')}
        options="vehicles.types"
      />
    </FormModal>
  );
}

UpgradeModal.propTypes = {
  open: PropTypes.bool,
};

UpgradeModal.defaultProps = {
  open: true,
};

export default UpgradeModal;
