import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { fetchCatalog } from 'store/commons/actions';
import CRPInformation from './CRPInformation';

export default compose(
  connect(
    (state) => ({
      invoiceDetails: state.invoices.invoiceDetails.data,
    }),
    { fetchCatalog }
  )
)(CRPInformation);
