import {
  VERIFY_TOKEN_REQUESTED,
  VERIFY_TOKEN_SUCCESSED,
  VERIFY_TOKEN_FAILED,
  USER_ACTIVATION_REQUESTED,
  USER_ACTIVATION_SUCCESSED,
  USER_ACTIVATION_FAILED,
} from './action-types';

export function verifyValidToken(payload) {
  return { type: VERIFY_TOKEN_REQUESTED, payload };
}

export function verifyValidTokenSuccessed(payload) {
  return { type: VERIFY_TOKEN_SUCCESSED, payload };
}

export function verifyValidTokenFailed(payload) {
  return { type: VERIFY_TOKEN_FAILED, payload };
}

export function userActivation(payload) {
  return { type: USER_ACTIVATION_REQUESTED, payload };
}

export function userActivationSuccessed(payload) {
  return { type: USER_ACTIVATION_SUCCESSED, payload };
}

export function userActivationFailed(payload) {
  return { type: USER_ACTIVATION_FAILED, payload };
}
