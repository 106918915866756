/* eslint-disable func-names */
import * as yup from 'yup';
import moment from 'moment';
import 'moment/locale/es';
import { isToday } from 'utils/date';
import i18n from '@i18n';
import { hourValidation } from 'utils/validations/common';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index.js';

export const transfersInformationSchema = yup.object().shape({
  requester: yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  requester_search: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3]))
    .test(
      'required-requester-search',
      i18n('ERROR__COMMONS__REQUIRED__ITEM_OF_LIST', ['un solicitante']),
      function (value) {
        return value && this.parent.requester;
      }
    ),
  plate: yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  plate_search: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .min(2, i18n('ERROR__COMMONS__MIN_LENGTH', [2]))
    .test(
      'required-plate-search',
      i18n('ERROR__COMMONS__REQUIRED__ITEM_OF_LIST', ['una placa']),
      function (value) {
        return value && this.parent.plate;
      }
    )
    .test(
      'same-plate-of-last-search',
      i18n('ERROR__COMMONS__REQUIRED__ITEM_OF_LIST', ['una placa']),
      function (value) {
        return value === this.parent.plate_lastSearch;
      }
    ),
  plate_lastSearch: yup.string(),
  driver: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5])),
  returnRealDate: yup.string(),
  arrivalRealTime: hourValidation.test(
    'min-current-time',
    i18n('ERROR__LOANS__ENTRY_TIME_CANNOT_BE_LESS_THAN_CURRENT_TIME'),
    function (departureTime) {
      const { departureDate } = this.parent;

      if (!departureDate || !departureTime) return true;

      if (isToday(departureDate)) {
        return moment(`${departureDate} ${departureTime}`) > moment();
      }
      return true;
    }
  ),
  departureRealDate: yup.string(),
  departureRealTime: hourValidation.test(
    'min-current-time',
    i18n('ERROR__LOANS__ENTRY_TIME_CANNOT_BE_LESS_THAN_CURRENT_TIME'),
    function (departureTime) {
      const { departureDate } = this.parent;

      if (!departureDate || !departureTime) return true;

      if (isToday(departureDate)) {
        return moment(`${departureDate} ${departureTime}`) > moment();
      }

      return true;
    }
  ),
  departureDate: yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  departureTime: hourValidation
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .test(
      'min-current-time',
      i18n('ERROR__LOANS__ENTRY_TIME_CANNOT_BE_LESS_THAN_CURRENT_TIME'),
      function (departureTime) {
        const { departureDate } = this.parent;

        if (!departureDate || !departureTime) return true;

        if (isToday(departureDate)) {
          return moment(`${departureDate} ${departureTime}`) > moment();
        }

        return true;
      }
    ),
  returnDate: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .test(
      'higher-return-date',
      'La fecha de llegada no puede ser menor a la fecha de salida',
      function (returnDate) {
        const { departureDate } = this.parent;

        if (!returnDate || !departureDate) return true;

        return moment(returnDate) >= moment(departureDate);
      }
    ),
  arrivalTime: hourValidation
    .required(REQUIRED_MESSAGE)
    .test(
      'greater-that-departure',
      i18n('ERROR__COMMONS__HOUR__GREATER_THAN_MINUTES', [
        'llegada',
        'salida',
        '30',
      ]),
      function (arrivalTime) {
        const { departureDate, departureTime, returnDate } = this.parent;

        if (!returnDate || !arrivalTime || !departureDate || !departureTime)
          return true;

        const fullReturnDate = moment(`${returnDate} ${arrivalTime}`);
        const fullDepartureDate = moment(`${departureDate} ${departureTime}`);

        fullReturnDate.subtract('minutes', 30);

        return moment(fullReturnDate).isAfter(fullDepartureDate);
      }
    ),
  source: yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  destination: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .test(
      'destination-different-of-source',
      'El destino debe ser diferente al origen',
      function (value) {
        const { source } = this.parent;
        if (!value || !source) return true;
        return value !== source;
      }
    ),
  observations: yup
    .string()
    .nullable()
    .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5])),
  realDepartureDate: yup.string(),
  realArrivalDate: yup.string(),
  licenseNumber: yup.string().required(REQUIRED_MESSAGE),
  validityDriverLicense: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .test(
      'higher-departure-date',
      'La vigencia no puede ser menor a la fecha de salida',
      function (validityDate) {
        const { departureDate } = this.parent;

        if (!validityDate || !departureDate) return true;

        return moment(validityDate) >= moment(departureDate);
      }
    )
    .test(
      'higher-return-date',
      'La vigencia no puede ser menor a la fecha de llegada',
      function (validityDate) {
        const { returnDate } = this.parent;

        if (!validityDate || !returnDate) return true;

        return moment(validityDate) >= moment(returnDate);
      }
    ),
  emissionState: yup.string().required(REQUIRED_MESSAGE),
});
