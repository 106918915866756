import * as yup from 'yup';

export default yup.object().shape({
  folio: yup.string(),
  noContrato: yup.string(),
  startDate: yup.string(),
  endDate: yup.string(),
  juridicalPersonName: yup.string(),
  businessArea: yup.string(),
  maxAmount: yup.string(),
  minAmount: yup.string(),
  includedVehicles: yup.string(),
  branch: yup.string(),
});
