import i18n from '@i18n';
import { CellText, CellDashed } from 'components/ColumnsCells';

export const columns = [
  {
    label: i18n('COMMONS__ID'),
    field: 'folio',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_LOANS__TABLE__REGISTER_DATE'),
    field: 'registerDate',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_LOANS__TABLE__DEPARTURE_DATE'),
    field: 'departureDateAndTime',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_LOANS__TABLE__RETURN_DATE'),
    field: 'returnDateAndTime',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_LOANS__TABLE__REAL_RETURN_DATE'),
    field: 'realReturnDateAndTime',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_CONTRACTS__TABLE__APERTURE_USER'),
    field: 'apertureUser',
    align: 'left',
    renderer: CellText,
  },
  {
    label: 'Solicitante',
    field: 'requester',
    align: 'left',
    renderer: CellText,
  },
  {
    label: i18n('VEHICLES__SECTIONS__VEHICLE_ESTATUS__TABLE__ESTATUS'),
    field: 'status',
    align: 'center',
    renderer: (props) => {
      const { data = {} } = props;
      return (
        <CellDashed
          {...props}
          fixedColor={data.status.color}
          value={data.status.name}
          align="left"
          textClass="text-left"
        />
      );
    },
  },
];
