import * as yup from 'yup';
import moment from 'moment';
import 'moment/locale/es';
import { getWithHoursFormat } from '@casanova/casanova-common/lib/utils/strings';
import i18n from '@i18n';
import { saveTransfer } from 'store/transfers/actions';
import { YYYY_MM_DD, MX_DATE_FORMAT_NO_HOURS } from 'utils/constants';
import _get from 'lodash/get';

const schema = yup.object().shape({});

const initialValues = {};

export const initialTouched = {};

export const formatDate = (values) =>
  moment(values, YYYY_MM_DD).format(MX_DATE_FORMAT_NO_HOURS);

export const transformer = (values) => {
  const formattedDepartureDate = formatDate(values.departureDate);
  const formattedRealDepartureDate = values.realDepartureDate
    ? formatDate(values.realDepartureDate)
    : i18n('COMMONS__NOT_INFO');
  const formattedReturnDate = formatDate(values.arrivalDate);
  const formattedRealReturnDate = values.realArrivalDate
    ? formatDate(values.realArrivalDate)
    : i18n('COMMONS__NOT_INFO');
  const formattedValidityDate = formatDate(values.validityDate)
    ? formatDate(values.validityDate)
    : i18n('COMMONS__NOT_INFO');

  return {
    ...values,
    emissionState: _get(values, 'issuingState.name', ''),
    formattedDepartureDate,
    formattedRealDepartureDate,
    realDepartureTime: values.realDepartureTime
      ? getWithHoursFormat(values.realDepartureTime)
      : i18n('COMMONS__NOT_INFO'),
    formattedReturnDate,
    formattedRealReturnDate,
    formattedValidityDate,
    realArrivalTime: values.realArrivalTime
      ? getWithHoursFormat(values.realArrivalTime)
      : i18n('COMMONS__NOT_INFO'),
  };
};

export const formatter = (values) => values;

export const config = {
  schema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter,
  transformer,
  submitForm: saveTransfer,
  btnCancelLabel: 'Rechazar',
  btnSubmitLabel: 'Autorizar',
  withButtons: false,
  dialogs: {
    confirmation: {
      title: 'SOLICITAR TRASLADO',
      message:
        'Se solicitará un traslado del vehículo ¿Estas seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage: 'Se realizó la acción de traslado de manera exitosa',
      errorMessage:
        'Hubo un error mientras procesábamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a traslados',
      errorLabel: 'Aceptar',
      cancelLabel: 'Cancelar',
      successPath: '/transfers',
    },
  },
};

export default config;
