import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icons } from '@casanova/casanova-common';
import './BackButton.scss';

export const BackButton = ({ page, onClick = () => {} }) => {
  const history = useHistory();

  const handleBackButton = useCallback(() => {
    history.push(page);
  }, [page, history, onClick]);

  return (
    <Button
      onClick={handleBackButton}
      outline
      className="mr-3 button-outline-icon BackButton"
    >
      <span className="BackButton__Icon">
        <Icons.Atras height="1.25rem" /> Atrás
      </span>
    </Button>
  );
};

export default BackButton;
