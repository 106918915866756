import React, { useState, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import _isEmpty from 'lodash/isEmpty';

import {
  MaskedInput as Input,
  TextAreaInput,
  Icons,
  Section,
  DatePickerV2,
  SearchableSelect,
} from '@casanova/casanova-common';
import { PreventChange } from 'components';

import { getFieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import {
  getNextYear,
  getCurrentTime,
} from '@casanova/casanova-common/lib/utils/date';
import {
  alphaMaskWithSpaces,
  hourMask,
  textAreaRegex,
} from '@casanova/casanova-common/lib/utils/masks';

import i18n from '@i18n';
import { FORM_ACTIONS } from 'utils/constants/formActions';

import { isSafari } from 'utils/browser';

const { ADD, VIEW, EDIT } = FORM_ACTIONS;

export default function LoanInformation({
  action = ADD,
  searchVehicles,
  searchEmployees,
  loanUuid = null,
  getLoanDetails,
  onUpdateForm,
  data,
  user = {},
  employees = [],
}) {
  const [fetchedCurrentUser, setFetchedCurrentUser] = useState(false);
    const [searchResults, setSearchResults] = useState([]);


  const {
    setFieldValue,
    setFieldTouched,
    initialValues,
    values,
    errors,
    dirty,
    isSubmitting,
  } = useFormikContext();

  const handleSelectEmployee = useCallback(
    (plate) => {
      setFieldTouched('requester', true);
      setFieldValue('requester', plate);
    },
    [setFieldValue, setFieldTouched]
  );

  const handleSearchEmployee = useCallback(
    (name) => {
      if (name && name.length > 2) {
        setFieldValue('requester', '');
        searchEmployees({ name, status: true });
      }
    },
    [setFieldValue, searchEmployees]
  );

  const handleBlurEmployee = useCallback(() => {
    setFieldTouched(getFieldSearch('requester'), true);
    setFieldTouched('requester', true);
  }, [setFieldTouched]);

  const handleSelectPlate = useCallback(
    (plate) => {
      setFieldValue('plateNumber', plate);
    },
    [setFieldValue, setFieldTouched]
  );

  const handleSearchPlate = useCallback(
    (plateNumber) => {
      if (plateNumber && plateNumber.length > 1) {
        setFieldValue('plateNumber', '');
        searchVehicles({ plateNumber, isLoan: true });
      }
    },
    [setFieldValue, searchVehicles]
  );

  const handleBlurPlate = useCallback(() => {
    setFieldTouched(getFieldSearch('plateNumber'), true);
    setFieldTouched('plateNumber', true);
  }, [setFieldTouched]);

  const handleDepartureBlur = useCallback(() => {
    if (errors.departureTime && !values.returnTime) {
      setFieldValue('returnTime', values.departureTime);
    }
  }, [errors, values, setFieldValue]);

  useEffect(() => {
    if (action === ADD && !fetchedCurrentUser) {
      searchEmployees({ name: user.fullName, status: true });
    }
  }, [action, user, fetchedCurrentUser, searchEmployees]);

  useEffect(() => {
    const { list = [] } = employees;
    if (!fetchedCurrentUser && list.length > 0) {
      const currentUser = list.find(
        ({ fullName }) => fullName === user.fullName
      );
      if (currentUser) {
        setFieldValue('requester', currentUser.uuid);
        setFieldValue(getFieldSearch('requester'), currentUser.fullName);
        setFetchedCurrentUser(true);
      }
    }
  }, [fetchedCurrentUser, employees, user, setFieldValue]);

  useEffect(() => {
    const currentTime = getCurrentTime({ incrementMinutes: 10 });

    setFieldValue('departureTime', currentTime);
    setFieldValue('returnTime', currentTime);

    setTimeout(() => {
      setFieldTouched('departureTime', true);
      setFieldTouched('returnTime', true);
    }, 1);
  }, [setFieldValue, setFieldTouched]);

  useEffect(() => {
    if (loanUuid && getLoanDetails) {
      getLoanDetails(loanUuid);
    }
  }, [getLoanDetails, loanUuid, setFieldValue]);

  useEffect(() => {
    if (!_isEmpty(data)) {
      onUpdateForm({ ...data, action });
    }
  }, [onUpdateForm, data, action]);

  useEffect(() => {
    setFieldValue('action', action);
  }, [setFieldValue, action]);

  const isEdit = action === EDIT;
  const disabledInput = action === VIEW || isEdit;
  const partialDisabledInput = isEdit ? false : disabledInput;

  return (
    <PreventChange
      preventChange={dirty && !isSubmitting}
      title={i18n(
        isEdit
          ? 'LOANS__LOAN_EDITOR__PREVENT_CHANGE__TITLE'
          : 'LOANS__LOAN_CREATOR__PREVENT_CHANGE__TITLE'
      )}
    >
      <Section title={i18n('LOANS__LOAN_CREATOR__LOANS_DATA__TITLE')}>
        <div className="row">
          <div className="col-4">
            <SearchableSelect
              mask={alphaMaskWithSpaces(200)}
              onChange={handleSelectEmployee}
              onSearching={handleSearchEmployee}
              // onBlur={handleBlurEmployee}
              name="requester"
              label={i18n('LOANS__LOAN_CREATOR__REQUESTER', ['*'])}
              placeholder={i18n('LOANS__LOAN_CREATOR__REQUESTER', ['*'])}
              options="customers.employees"
              disabled={disabledInput}
              icon="svg-icon-search"
            />
          </div>
          {isEdit && (
            <div className="col-4">
              <Input
                mask={alphaMaskWithSpaces(20)}
                maskPlaceholder=""
                label={i18n('LOANS__LOAN_CREATOR__AUTHORIZED', [''])}
                placeholder={i18n('LOANS__LOAN_CREATOR__AUTHORIZED', [''])}
                name="responsible"
                disabled={disabledInput}
                showLabel={disabledInput}
              />
            </div>
          )}
          <div className="col-md-4">
            <SearchableSelect
              mask={alphaMaskWithSpaces(10)}
              onChange={handleSelectPlate}
              onSearching={handleSearchPlate}
              // onBlur={handleBlurPlate}
              name="plateNumber"
              label={i18n('LOANS__LOAN_CREATOR__PLATE', ['*'])}
              placeholder={i18n('LOANS__LOAN_CREATOR__PLATE', ['*'])}
              options="vehicles.vehiclesByPlate"
              disabled={disabledInput}
              icon="svg-icon-search"
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={alphaMaskWithSpaces(20)}
              maskPlaceholder=""
              label={i18n('LOANS__LOAN_CREATOR__DRIVER', ['*'])}
              placeholder={i18n('LOANS__LOAN_CREATOR__DRIVER', ['*'])}
              name="driverName"
              disabled={disabledInput}
              showLabel={disabledInput}
            />
          </div>
          <div className="col-md-4">
            <DatePickerV2
              initialValue={initialValues.departureDate}
              placeholder={i18n('LOANS__LOAN_CREATOR__DEPERTURE_DATE', ['*'])}
              name="departureDate"
              label={i18n('LOANS__LOAN_CREATOR__DEPERTURE_DATE', ['*'])}
              disabled={disabledInput}
              placement={isSafari() ? 'bottom' : undefined}
              maximumDate={getNextYear({
                years: 0,
                months: new Date().getMonth() + 1,
              })}
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={hourMask}
              maskPlaceholder=""
              label={i18n('LOANS__LOAN_CREATOR__DEPERTURE_TIME', ['*'])}
              placeholder={i18n('LOANS__LOAN_CREATOR__DEPERTURE_TIME', ['*'])}
              name="departureTime"
              disabled={disabledInput}
              showLabel={disabledInput}
              onBlur={handleDepartureBlur}
              right={<Icons.Time width="85%" height="85%" />}
            />
          </div>
          <div className="col-md-4">
            <DatePickerV2
              initialValue={initialValues.returnDate}
              placeholder={i18n('LOANS__LOAN_CREATOR__RETURN_DATE', ['*'])}
              name="returnDate"
              label={i18n('LOANS__LOAN_CREATOR__RETURN_DATE', ['*'])}
              disabled={partialDisabledInput}
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={hourMask}
              maskPlaceholder=""
              label={i18n('LOANS__LOAN_CREATOR__RETURN_TIME', ['*'])}
              placeholder={i18n('LOANS__LOAN_CREATOR__RETURN_TIME', ['*'])}
              name="returnTime"
              disabled={partialDisabledInput}
              showLabel={partialDisabledInput}
              right={<Icons.Time width="85%" height="85%" />}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TextAreaInput
              label={i18n('LOANS__LOAN_CREATOR__OBSERVATIONS', ['*'])}
              placeholder={i18n('LOANS__LOAN_CREATOR__OBSERVATIONS', ['*'])}
              name="observations"
              disabled={disabledInput}
              regex={textAreaRegex}
              maxLength={500}
            />
          </div>
        </div>
      </Section>
    </PreventChange>
  );
}
