import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { fetchCatalog, selectBrand, selectLine } from 'store/catalogs/actions';
import {
  openConfirmationMessage,
  closeConfirmationMessage,
  storeVehicleData,
  editVehicleRequested,
  closeResultMessage,
  fetchVehicleInfo,
  cleanVehicleData,
  exportVehicleDetail,
  cleanAdditional,
} from 'store/vehicles/actions';
import VehicleEditor from './VehicleEditor';

export default compose(
  connect(
    (state) => ({
      brandSelected: state.catalogs.brandSelected,
      lineSelected: state.catalogs.lineSelected,
      confirmationMessage: state.vehicles.confirmationMessage,
      resultMessage: state.vehicles.resultMessage,
      vehicle: state.vehicles.vehicleData,
      loader: state.vehicles.loader,
      listBrands: state.catalogs.brand.list,
      policySuccess: _get(state, `vehicles.additionals.policy.success`, null),
      policy: _get(state, `vehicles.additionals.policy.data`, null),
    }),
    {
      fetchCatalog,
      openConfirmationMessage,
      closeConfirmationMessage,
      closeResultMessage,
      storeVehicleData,
      editVehicleRequested,
      fetchVehicleInfo,
      cleanVehicleData,
      selectBrand,
      selectLine,
      exportVehicleDetail,
      cleanAdditional,
    }
  )
)(VehicleEditor);
