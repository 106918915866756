import * as Yup from 'yup';
import i18n from '@i18n';
import { fileTypeValidation } from 'utils/validations/common';
import {
  REQUIRED_MESSAGE,
  ALLOWED_FORMATS,
  FORMAT_TYPES_ALLOWED_MESSAGE,
} from 'utils/constants';
import { HOSPEDAY_TYPES } from './constants';

const isRent = ({ identifier = '' }) => identifier === HOSPEDAY_TYPES.RENT;
const isHotel = (value) => value === HOSPEDAY_TYPES.HOTEL;
const isFamilyFriend = (value) => value === HOSPEDAY_TYPES.FAMILY_FRIEND;

const accommodationDocTest = Yup.mixed().when('hospedySelected', {
  is: (hopedySelected) => isRent(hopedySelected || {}),
  then: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
      fileTypeValidation(file, ALLOWED_FORMATS)
    ),
});

const isFamilyFriendSchema = (schema) =>
  Yup.mixed().when('hospedyIdentifier', {
    is: isFamilyFriend,
    then: schema.required(REQUIRED_MESSAGE),
    otherwise: schema,
  });

export const accommodationInformationProps = {
  hospedyType: Yup.string().required(REQUIRED_MESSAGE),
  hospedySelected: Yup.object().required(REQUIRED_MESSAGE),
  subtype: Yup.mixed().when('hospedyIdentifier', {
    is: isFamilyFriend,
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
  hospedyHotelName: Yup.string().when('hospedyIdentifier', {
    is: isHotel,
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3]))
      .max(50, i18n('ERROR__COMMONS__MAX_LENGTH', [50])),
    otherwise: Yup.string(),
  }),
  hospedyReservationNumber: Yup.string().when('hospedyIdentifier', {
    is: isHotel,
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3]))
      .max(50, i18n('ERROR__COMMONS__MAX_LENGTH', [50])),
    otherwise: Yup.string(),
  }),
  hospedyConfirmation: Yup.bool(),
  hospedyContract: accommodationDocTest,
  hospedyIdentificationLessor: accommodationDocTest,
  hospedyRelationLabor: accommodationDocTest,
  endorsement: isFamilyFriendSchema(Yup.bool()).test(
    'required-endorsementUuid',
    REQUIRED_MESSAGE,
    function () {
      const { hospedyIdentifier, endorsementUuid } = this.parent;
      return isFamilyFriend(hospedyIdentifier)
        ? Boolean(endorsementUuid)
        : true;
    }
  ),
  endorsementUuid: isFamilyFriendSchema(Yup.string()),
};

export const AccommodationInformationSchema = Yup.object().shape({
  ...accommodationInformationProps,
});

export default AccommodationInformationSchema;
