import { Modal, Section } from '@casanova/casanova-common';
import { useCallback } from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import { ISO_DATE_FORMAT, MX_DATE_FORMAT } from 'utils/constants';
const ViewExpiringContracts = (props) => {
  const { show = true, setShow, data = {} } = props;
  const { title, contracts = [] } = data;
  const handleCloseModal = useCallback(() => {
    setShow(false);
  }, [setShow]);
  return (
    <>
      <Modal
        open={show}
        header={null}
        footer={null}
        className="ViewRefundDetail"
        contentClassName="px-4 py-4 container"
        closeButton
        onClose={handleCloseModal}
        bigModal
      >
        <Section title={title}>
          <div className={`container-fluid ${styles.contractContainer}`}>
            <div className="row">
              <div className="col-12">
                <div className={styles.contractGrid}>
                  {contracts.map((contract) => (
                    <div key={contract} className="card">
                      <div className="card-body">
                        <h5 className="card-title">
                          Contrato #{contract.contractNumber}
                        </h5>
                        {/* <p className="card-text">
                          Número de Reservación: {contract.numeroReservacion}
                        </p> */}
                        <p className="card-text">
                          Cliente: {contract.customer}
                        </p>
                        <p className="card-text">
                          Fecha de Vencimiento:{' '}
                          {moment(
                            contract.returnDateTime,
                            ISO_DATE_FORMAT
                          ).format(MX_DATE_FORMAT)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Section>
      </Modal>
    </>
  );
};

export default ViewExpiringContracts;
