import * as Yup from 'yup';
import { REQUIRED_MESSAGE } from 'utils/constants';

export const discountFormSchema = Yup.object().shape({
  user: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(4, 'El usuario debe tener mínimo 4 caracteres.'),
  password: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(8, 'La contraseña debe tener mínimo 8 caracteres.'),
});

export const formatter = (values) => ({
  amount: values.discount,
  discountTypeUuid: values.discountType[0],
  password: values.password,
  username: values.user,
  vehicleAmount: values.units,
});
