import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { closeResponseDialog } from 'store/payments/actions';
import { searchEmployees } from 'store/customers/actions';
import { fetchCatalog } from 'store/commons/actions';
import { getCustomerCards } from 'store/reservations/actions';
import ClientCards from './ClientCards';

export default compose(
  connect(
    (state) => ({
      quoteValues: _get(state, 'reservations.quote.values', {}),
      loader: state.commons.loader,
      responseDialog: state.reservations.responseDialog,
    }),
    {
      getCustomerCards,
      closeResponseDialog,
    }
  )
)(ClientCards);
