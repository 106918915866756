import {
  sendPostRequestRaw,
  sendCustomRequest,
} from '@casanova/casanova-common/lib/utils/service';
import _get from 'lodash/get';
import jwtDecode from 'jwt-decode';
import { APIError } from 'utils/exceptions';
import { AUTH } from 'utils/constants/auth';
import {
  transformUser,
  transformPermission,
  getPathPermissions,
  getModulePermissions,
} from './transformers';

const { TOKEN } = AUTH;

const root = 'user/v1';

export async function login(data) {
  const path = 'user/v1/auth/login';
  const result = await sendPostRequestRaw(path, data, {});
  storeToken(result.data.headers);
  await getRolePermissions();
  return transformUser(result.data);
}

export async function getRolePermissions() {
  const path = 'user/v1/catalogs/roles';
  const response = await sendCustomRequest(path, {}, undefined, 'get');
  const { aut } = getDecodedToken();
  const role = aut[0].toLowerCase();
  const permissions = transformPermission(response.data, role);
  storePermissions(permissions);
  storeAllowedPaths(permissions);
  storeAllowedModules(permissions);
}

export async function refresh() {
  const path = 'user/v1/auth/refresh';
  try {
    const result = await sendPostRequestRaw(path, {});
    storeToken(result.data.headers);
    return true;
  } catch (e) {
    return false;
  }
}

export function logout() {
  removeToken();
  removeAllowedPaths();
  removePermissions();
  removeAllowedModules();
  window.location.reload();
}

export function storeToken(headers) {
  const token = headers.get('Authorization').substring('Bearer '.length);
  localStorage.setItem(TOKEN, token);
}

export function removeToken() {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem('br');
}

export function storePermissions(permissions) {
  localStorage.setItem('role_permissions', permissions);
}

export function removePermissions() {
  localStorage.removeItem('role_permissions');
}

export function storeAllowedPaths(permissions) {
  const pathPermissions = getPathPermissions(permissions);
  localStorage.setItem('role_paths', pathPermissions);
}

export function removeAllowedPaths() {
  localStorage.removeItem('role_paths');
}

export function storeAllowedModules(permissions) {
  const allowedModules = getModulePermissions(permissions);
  console.log(permissions);
  localStorage.setItem('role_modules', allowedModules);
}

export function removeAllowedModules() {
  localStorage.removeItem('role_modules');
}

export function getPermissions() {
  const permissions = `${localStorage.getItem('role_permissions')}`;
  return permissions ? permissions.split(',') : undefined;
}

export function getAllowedPaths() {
  const paths = `${localStorage.getItem('role_paths')}`;
  return paths === null ? [] : paths.split(',');
}

export function getAllowedModules() {
  const modules = localStorage.getItem('role_modules');
  return modules === null ? [] : `${modules}`.split(',');
}

export function getToken() {
  return localStorage.getItem(TOKEN);
}

export function getDecodedToken() {
  try {
    const decodeToken = jwtDecode(localStorage.getItem(TOKEN));
    return decodeToken;
  } catch (error) {
    logout();
    return null;
  }
}

export function getRoleName() {
  if (!getToken()) return '';
  return _get(getDecodedToken(), 'aut', [''])[0];
}

export function getBrach() {
  if (!getToken()) return '';
  return _get(getDecodedToken(), 'br', '');
}

export function getOptionsAuth() {
  if (!getToken()) return {};

  const name = _get(getDecodedToken(), 'nam', '');
  const lna = _get(getDecodedToken(), 'lna', '');
  const sln = _get(getDecodedToken(), 'sln', '');

  return {
    authToken: getToken(),
    user: {
      fullName: `${name} ${lna} ${sln}`.trim(),
      branch: _get(getDecodedToken(), 'br', ''),
    },
  };
}

export async function validateCurp(curp, options = {}) {
  const url = `${root}/curps`;
  const response = await sendCustomRequest(url, options, { curp }, 'post');

  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}
