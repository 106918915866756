import moment from 'moment';
import 'moment/locale/es';
import {
  formattedDateToPickerV2,
  YYYY_MM_DD,
  timeZone,
} from '@casanova/casanova-common/lib/utils/date';

export const VEHICLE_DETAIL_FIELDS = {
  NO_CONTRACT: 'contractNumber',
  CUSTOMER: 'customer',
  BRAND: 'brand',
  LINE: 'line',
  VERSION: 'version',
  MODEL: 'model',
  SERIAL_NUMBER: 'serialNumber',
  PLATE: 'plateNumber',
  FUEL: 'fuelType',
  TRANSMISSION_TYPE: 'transmissionType',
  COLOR: 'color',
  GPS: 'gps',
  KM: 'km',
  STATUS: 'status',
  SUBSTATUS: 'subStatus',
  BUSINESS_AREA: 'businessArea',
  DEPOT: 'warehouse',
  CATEGORY: 'category',
};

export const VEHICLE_STATUS_FIELDS = {
  USER: 'user',
  STATUS_DATE: 'statusSetDate',
  STATUS: 'status',
  ACTIVE: 'active',
};

export const VEHICLE_TRANSFERS_FIELDS = {
  ID: 'folio',
  REGISTER_DATE: 'createdAt',
  DEPARTURE_DATE_AND_TIME: 'departureDateAndTime',
  ARRIVAL_DATE: 'arrivalDateAndTime',
  REAL_ARRIVAL_DATE: 'realArrivalDateAndTime',
  APERTURE_USER: 'createdBy',
  REQUESTER: 'requesterName',
  STATUS: 'transferStatus',
};

export const MARK_FOR_SALE_FIELDS = {
  CONSIGNEE: 'consignee',
};

export const VEHICLE_LIST_FIELDS = {
  ...VEHICLE_DETAIL_FIELDS,
  PROVIDER: 'provider',
  OWNER: 'owner',
};

export const MINIMUM_DATE = formattedDateToPickerV2('2011-01-01');
export const MAXIMUM_DATE = formattedDateToPickerV2(
  moment().tz(timeZone).format(YYYY_MM_DD)
);

export const VEHICLES_STATUS = {
  ON_FLOOR: 'Piso',
  SOLD: 'VENDIDO',
};

export const MASSIVE_LOAD_TYPES = {
  VEHICLES: 'VEHICLES',
  VEHICLES_FOR_SALES: 'VEHICLES_FOR_SALES',
};

export const MASSIVE_LOAD_PARAMS = {
  [MASSIVE_LOAD_TYPES.VEHICLES]: 'massive-load',
  [MASSIVE_LOAD_TYPES.VEHICLES_FOR_SALES]: 'massive-load/sale',
};

export const MASSIVE_LOAD_ROUTES = {
  'vehicle/massive-load': MASSIVE_LOAD_TYPES.VEHICLES,
  'vehicle/massive-load-for-sales': MASSIVE_LOAD_TYPES.VEHICLES_FOR_SALES,
};

export const BUSINESS_AREA_UUIDS = {
  RENTA_DIARIA: '69b2b421-9591-4440-a3ad-32b2307b4ade',
};

export const VEHICLE_STATUS_UUIDS = {
  DISPONIBLE: '940c7c42-0031-43d8-aa21-91f4910c9601',
};
