import { call, put } from 'redux-saga/effects';
import { postAvaibleUser } from 'services/adminPanel/users';
import { getOptionsAuth } from 'services/auth';
import { postAvailableUserFailed, postAvailableUserSuccessed } from './actions';

export function* postAvailableUserSaga(action) {
  try {
    const { value = '', field = '', naturalPerson = null } = action.payload;
    const params = { [field]: value, naturalPerson };
    const data = yield call(postAvaibleUser, params, getOptionsAuth());
    yield put(postAvailableUserSuccessed({ data, field }));
  } catch (error) {
    yield put(postAvailableUserFailed(error));
  }
}
