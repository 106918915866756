import { fieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import i18n from '@i18n';
import { saveContract } from 'store/contracts/actions';
import { schema as contractInfoSchema } from '@contracts/sections/ContractInformation/schema';
import { schema as vehiclesSchema } from '@contracts/sections/Vehicles/schema';
import { contractFormatter } from './formatters';
import { contractTransformer } from './transformer';
import { branch } from 'recompose';

export const config = {
  schema: contractInfoSchema.concat(vehiclesSchema),
  initialTouched: {
    startDate: true,
    endDate: true,
    customer: true,
    [fieldSearch('customer')]: true,
    businessArea: true,
    amount: true,
    vehicles: true,
    vehicleSearch: true,
    noContrato: true,
    minAmount: true,
    maxAmount: true,
    branch: true,
  },
  initialValues: {
    startDate: '',
    endDate: '',
    customer: '',
    [fieldSearch('customer')]: '',
    businessArea: '',
    amount: '',
    vehicles: [],
    vehicleSearch: '',
  },
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  submitForm: saveContract,
  formatter: contractFormatter,
  transformer: contractTransformer,
  dialogs: {
    confirmation: {
      title: i18n('CONTRACTS__CONTRACT_CREATOR__MODAL__TITLE'),
      message: i18n('CONTRACTS__CONTRACT_CREATOR__MODAL__DESCRIPTION'),
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: i18n('CONTRACTS__CONTRACT_CREATOR__SUCCESS_MODAL__TITLE'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: i18n(
        'CONTRACTS__CONTRACT_CREATOR__SUCCESS_MODAL__SUCCESS_LABEL'
      ),
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: '/contracts',
    },
  },
};
