import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  CardSection,
  TextField,
  UserInfo,
  FileViewer,
} from '@casanova/casanova-common';
import i18n from '@i18n';
import { getTextColor } from 'utils/customers';
import { CUSTOMER_DETAIL_KEYS } from '@customers/common/constants';
import { isMoralCustomer, isForeignCustomer } from '@customers/common/utils';
import { TITLE_OF_DOCUMENTS } from './constants';

const OfficialIdentification = ({
  identityDocument,
  listOfInvalidsDocuments = [],
  data,
}) => {
  const { identityKey, issuedYear, validityYear } = identityDocument;

  const [invalidDocument, setInvalidDocument] = useState({});

  const isMoral = isMoralCustomer(data);
  const isForeign = isForeignCustomer(data);
  const documentStatus = _get(identityDocument, 'documentStatus.name', '');
  const documentType = _get(identityDocument, 'identityDocumentType.name', '');
  const statusColor = getTextColor(
    invalidDocument ? 'rechazada' : documentStatus
  );

  const title = useMemo(() => {
    if (isMoral) {
      return i18n(
        'CUSTOMERS__DOCUMENT_TYPE__OFICIAL_IDENTIFICATION__JURIDICAL'
      );
    }
    if (isForeign) return documentType;
    return i18n('CUSTOMERS__DOCUMENT_TYPE__OFICIAL_IDENTIFICATION__FISICAL');
  }, [isForeign, isMoral, documentType]);

  const files = useMemo(() => {
    if (_isEmpty(identityDocument)) return;

    const DOCUMENTS = ['frontScanFile', 'backScanFile'];

    return DOCUMENTS.map((doc) => ({
      file: _get(identityDocument, `${doc}.presignedUrl`, undefined),
      title: TITLE_OF_DOCUMENTS[doc],
    }));
  }, [identityDocument]);

  useEffect(() => {
    setInvalidDocument(
      listOfInvalidsDocuments.find(
        ({ documentKey = '' }) =>
          documentKey === CUSTOMER_DETAIL_KEYS.IDENTITY_DOCUMENT
      )
    );
  }, [listOfInvalidsDocuments]);

  return (
    <CardSection status={statusColor} title={title} upperCase>
      <div className="row">
        <div className="col-7">
          <FileViewer files={files} />
        </div>
        <div className="col-5">
          <TextField
            title="Estatus"
            value={documentStatus.toUpperCase()}
            status={statusColor}
          />
          {!isForeign && (
            <TextField title="TIPO DE IDENTIFICACIÓN" value={documentType} />
          )}
          <TextField title="CLAVE DE IDENTIFICACIÓN " value={identityKey} />
          <div className="row">
            <div className="col-6">
              <TextField title="Emisi&oacute;n" value={issuedYear} />
            </div>
            <div className="col-6">
              <TextField
                title="Vigencia"
                status={
                  (invalidDocument && 'danger') || statusColor === 'warning'
                    ? statusColor
                    : undefined
                }
                value={validityYear}
              />
            </div>
          </div>
          <UserInfo {...data} />
        </div>
      </div>
    </CardSection>
  );
};

OfficialIdentification.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  identityDocument: PropTypes.object,
};

OfficialIdentification.defaultProps = {
  identityDocument: {},
};

export default OfficialIdentification;
