import React, { useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import {
  withForm,
  Section,
  TextField,
  UserInfo,
  Button,
} from '@casanova/casanova-common';
import { Card } from 'components';
import i18n from '@i18n';
import { config } from './config';

const TransferDetail = ({
  match,
  history,
  getTransferDetail,
  transferDetail,
  onUpdateForm,
}) => {
  const { values } = useFormikContext();

  const {
    folio,
    requesterName,
    responsibleName = '',
    plateNumber,
    transferStatus = {},
    departureWarehouse = {},
    destinyWarehouse = {},
    formattedDepartureDate,
    formattedRealDepartureDate,
    formattedReturnDate,
    formattedRealReturnDate,
    observations,
    departureTime,
    realDepartureTime,
    arrivalTime,
    realArrivalTime,
    driverName,
    licenseNumber,
    validityDate,
    emissionState,
  } = values;

  const handleOnBack = useCallback(() => {
    history.push('/transfers');
  }, [history.push]);

  useEffect(() => {
    if (transferDetail) {
      onUpdateForm(transferDetail);
    }
  }, [onUpdateForm, transferDetail]);

  useEffect(() => {
    getTransferDetail(match.params.id);
  }, [getTransferDetail, match.params.id]);

  return (
    <>
      <Section title={i18n('TRANSFERS__DETAIL__SECTION_TITLE')}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Card noStatus>
                <div className="row">
                  <div className="col-12">
                    <h4
                      className="text-left text-uppercase"
                      style={{ color: transferStatus.color }}
                    >
                      {transferStatus.name}
                    </h4>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__FOLIO_FIELD')}
                      value={folio}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__REQUESTER_FIELD')}
                      value={requesterName}
                    />
                  </div>
                  {/* <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__AUTHORIZER_FIELD')}
                      value={responsibleName}
                    />
                  </div> */}
                  <div className="col-6">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__PLATE_FIELD')}
                      value={plateNumber}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__DRIVER_FIELD')}
                      value={driverName}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__LICENSE_NUMBER')}
                      value={licenseNumber}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__VALIDITY_DATE')}
                      value={validityDate}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__EMISSION_STATE')}
                      value={emissionState}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__DEPARTURE_DATE_FIELD')}
                      value={formattedDepartureDate}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__DEPARTURE_HOUR_FIELD')}
                      value={`${departureTime} HRS`}
                    />
                  </div>

                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__RETURN_DATE_FIELD')}
                      value={formattedReturnDate}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__ARRIVAL_HOUR_FIELD')}
                      value={arrivalTime ? `${arrivalTime} HRS` : ''}
                    />
                  </div>

                  <div className="col-3">
                    <TextField
                      title={i18n(
                        'TRANSFERS__DETAIL__REAL_DEPARTURE_DATE_FIELD'
                      )}
                      value={formattedRealDepartureDate}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n(
                        'TRANSFERS__DETAIL__REAL_DEPARTURE_HOUR_FIELD'
                      )}
                      value={realDepartureTime}
                    />
                  </div>

                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__REAL_RETURN_DATE_FIELD')}
                      value={formattedRealReturnDate}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__REAL_RETURN_HOUR_FIELD')}
                      value={realArrivalTime}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__SOURCE_FIELD')}
                      value={departureWarehouse.name}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      title={i18n('TRANSFERS__DETAIL__ARRIVAL_FIELD')}
                      value={destinyWarehouse.name}
                    />
                  </div>
                </div>

                {observations && (
                  <div className="row">
                    <div className="col-12">
                      <TextField
                        title={i18n(
                          'TRANSFERS__DETAIL__OBSERVATIONS_HOUR_FIELD'
                        )}
                        value={observations}
                      />
                    </div>
                  </div>
                )}

                <UserInfo {...transferDetail} />
              </Card>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col" />
          <div className="col-3 col-xl-3">
            <Button block onClick={handleOnBack}>
              {i18n('COMMONS__BACK')}
            </Button>
          </div>
        </div>
      </Section>
    </>
  );
};

TransferDetail.propTypes = {};

export default withForm({ config })(TransferDetail);
