import _noop from 'lodash/noop';
import transformer from './transformer';

const initialValues = {
  series: '',
  folio: '',
  rfc: '',
  customerName: '',
  invoiceDate: '',
  vat: '',
  amount: '',
  totalAmount: '',
  invoiceStatus: '',
};

const initialTouched = {
  series: false,
  folio: false,
  rfc: false,
  customerName: false,
  invoiceDate: false,
  vat: false,
  amount: false,
  totalAmount: false,
  invoiceStatus: false,
};

const config = {
  schema: {},
  initialTouched,
  initialValues,
  enableReinitialize: true,
  withButtons: false,
  transformer,
  submitForm: _noop,
  btnSubmitLabel: '',
  dialogs: {
    confirmation: {},
    response: {},
  },
};

export default config;
