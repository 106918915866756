import * as Yup from 'yup';
import { pdfRule, xmlRule } from 'utils/validations';

const REQUIRED_MESSAGE = 'Este campo es obligatorio.';

const CONVERTED_STRING_RULE = {
  is: true,
  then: Yup.string().nullable().required(REQUIRED_MESSAGE),
  otherwise: Yup.string().nullable(),
};
const CONVERTED_COST_RULE = {
  is: true,
  then: Yup.string()
    .required(REQUIRED_MESSAGE)
    .nullable()
    .test(
      'adaptedCost',
      'El costo total de la adapación debe estar entre $ 10,000.00 y $ 999,999.99.',
      (adaptedCost) => {
        if (adaptedCost) {
          adaptedCost = adaptedCost.replace(/[^0-9.]+/g, '');
          return (
            parseFloat(adaptedCost) >= 10000 &&
            parseFloat(adaptedCost) <= 9999999
          );
        }
        return false;
      }
    ),
  otherwise: Yup.string().nullable(),
};

const ADAPTED_PDF_INVOICE_RULE = {
  is: true,
  then: Yup.mixed().test(
    'fileFormat',
    'El tipo de archivo debe ser pdf.',
    function (file) {
      const {
        adaptationInvoiceNumber,
        initialAdaptationInvoiceNumber,
        adaptationCost,
        initialAdaptationCost,
      } = this.parent;

      const noChanges =
        initialAdaptationCost &&
        adaptationCost &&
        initialAdaptationCost === adaptationCost.replace(/[^0-9.]+/g, '') &&
        initialAdaptationInvoiceNumber === adaptationInvoiceNumber;

      if (!file || noChanges) {
        return true;
      }

      return pdfRule(file);
    }
  ),
  otherwise: Yup.mixed().nullable(),
};

const ADAPTED_XML_INVOICE_RULE = {
  is: true,
  then: Yup.mixed().test(
    'fileFormat',
    'El tipo de archivo debe ser xml.',
    function (file) {
      const {
        adaptationInvoiceNumber,
        initialAdaptationInvoiceNumber,
        adaptationCost,
        initialAdaptationCost,
      } = this.parent;

      const noChanges =
        initialAdaptationCost &&
        adaptationCost &&
        initialAdaptationCost === adaptationCost.replace(/[^0-9.]+/g, '') &&
        initialAdaptationInvoiceNumber === adaptationInvoiceNumber;

      if (!file || noChanges) {
        return true;
      }

      return xmlRule(file);
    }
  ),
  otherwise: Yup.mixed().nullable(),
};

export const conversionValidationProps = {
  originConversion: Yup.string().when('hasConversion', CONVERTED_STRING_RULE),
  conversionCost: Yup.string().when('hasConversion', CONVERTED_COST_RULE),
  conversionInvoiceNumber: Yup.string().when(
    'hasConversion',
    CONVERTED_STRING_RULE
  ),
  conversionType: Yup.string().when('hasConversion', CONVERTED_STRING_RULE),
  conversionInvoicePDF: Yup.mixed()
    .test('', REQUIRED_MESSAGE, function (value) {
      const { conversionInvoiceXML } = this.parent;
      if (conversionInvoiceXML) return value != null;
      return true;
    })
    .when('hasConversion', ADAPTED_PDF_INVOICE_RULE),
  conversionInvoiceXML: Yup.mixed()
    .test('', REQUIRED_MESSAGE, function (value) {
      const { conversionInvoicePDF } = this.parent;
      if (conversionInvoicePDF) return value != null;
      return true;
    })
    .when('hasConversion', ADAPTED_XML_INVOICE_RULE),
};

export const ConversionOnlySchema = Yup.object().shape({
  ...conversionValidationProps,
});
