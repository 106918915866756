import {
  CONTRACTS_MODULE,
  VIEW_CONTRACTS,
  CONTRACT_DETAIL,
  NEW_CONTRACT,
  EDIT_CONTRACT,
} from 'utils/roles/permissions/contractsPermissions';
import {
  CONTRACTS_LIST_PATH,
  CONTRACTS_ADD_PATH,
  CONTRACTS_EDIT_PATH,
  CONTRACTS_DETAIL_PATH,
} from 'utils/roles/paths';

export function contractsAuth(permissions) {
  const allowedModules = [];

  if (permissions.includes(VIEW_CONTRACTS)) {
    allowedModules.push(CONTRACTS_MODULE);
  }

  return allowedModules;
}

export function contractsPathsAuth(permissions) {
  const allowedPaths = [];

  if (permissions.includes(VIEW_CONTRACTS)) {
    allowedPaths.push(CONTRACTS_LIST_PATH);
  }
  if (permissions.includes(CONTRACT_DETAIL)) {
    allowedPaths.push(CONTRACTS_DETAIL_PATH);
  }
  if (permissions.includes(NEW_CONTRACT)) {
    allowedPaths.push(CONTRACTS_ADD_PATH);
  }
  if (permissions.includes(EDIT_CONTRACT)) {
    allowedPaths.push(CONTRACTS_EDIT_PATH);
  }

  return allowedPaths;
}
