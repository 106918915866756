import * as Yup from 'yup';
import { sendFormLink } from 'store/reservations/actions';
import { cellphone, email, getInitialValues } from 'utils/validations/fields';
import { REQUIRED_MESSAGE } from 'utils/constants';

export const AREA_OPTIONS = {
  EMAIL: 'email',
  CELLPHONE: 'cellphone',
  USER: 'user',
};

const config = {
  schema: Yup.object().shape({
    cellphone,
    email: email.required(REQUIRED_MESSAGE),
  }),
  initialTouched: {
    email: false,
    cellphone: true,
  },
  initialValues: {
    email: '',
    cellphone: '',
    area: AREA_OPTIONS.USER,
    ...getInitialValues({ fields: AREA_OPTIONS }),
  },
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter: (values) => values,
  transformer: (values) => values,
  submitForm: sendFormLink,
  alwaysSetTemporalValues: true,
  withButtons: false,
  disableSubmit: true,
  btnSubmitLabel: 'Continuar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE REGISTRO',
      message:
        'Una vez cargada la información del cliente tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a Clientes',
      errorLabel: 'Aceptar',
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};

export default config;
