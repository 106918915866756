import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import SearchableSelect from 'components/SercheableSelect';
import { debounce } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import ResponseDialog from 'components/ResponseDialog';
import { utils } from 'react-modern-calendar-datepicker';
import { transformDisableSelectOption } from '@casanova/casanova-common/lib/utils/transformHelpers';
import i18n from '@i18n';
import {
  Modal,
  Section,
  DatePickerV2,
  TableNavigation,
  Table,
  withForm,
  ActionModal,
  Icons,
  MaskedInput as Input,
  ConnectedSelect,
  Select,
  RadioGroupInput,
} from '@casanova/casanova-common';
import { lettersAndNumbersWithSpaces } from '../../../../utils/masks';
import config from './config.js';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _noop from 'lodash/noop';

import {
  formattedDateToPickerV2,
  isToday,
  YYYY_MM_DD,
  HH_MM,
} from 'utils/date';
import { ISO_DATE_FORMAT, MX_DATE_FORMAT } from 'utils/constants';
import { RENTAL_PLANS } from '@reservations/commons/constants';
import {
  setStartAvailableHours,
  setEndAvailableHours,
  setEndLimitHours,
} from 'store/reservations/actions';

const Component = ({
  resetCustomers,
  getDetailOfCustomerInUpdateModal,
  search,
  customersSelected,
  onUpdateForm,
  reservationDetails,
  notPriceQuote = false,
  categories,
  getTimeLimits,
  setParams,
  setCategorySelected,
  getVehicles,
  vehicleTypes,
  vehicles,
  setRentalPlansToUse,
  rentalPlansToUse,
  // ...rest
}) => {
  // client
  const handleSelectCustomer = async (element) => {
    getDetailOfCustomerInUpdateModal({ id: element });
  };

  const {
    values,
    dirty,
    initialValues,
    validateForm,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext();
  const handleSearchCustomer = (query) => {
    debouncedSearch(query);
  };
  const handleRemove = () => {
    resetCustomers();
  };

  useEffect(() => {
    if (!_isEmpty(customersSelected)) {
      const {
        userInfo: { email },
        fullName,
        uuid: customer,
      } = customersSelected;
      setFieldValue('customerName', fullName);
      setFieldValue('email', email);
      setFieldValue('customer', customer);
    }
  }, [customersSelected]);

  useEffect(() => {
    if (!_isEmpty(reservationDetails.data)) {
      setCategorySelected(
        reservationDetails.data.priceQuote.selectedVehicleLinePrice.vehicle
          .vehicleCategoryUuid
      );
      getVehiclesByStartRentalDayAndVehicleTypes(
        reservationDetails.data.priceQuote.selectedVehicleLinePrice.vehicle
          .vehicleTypeUuid
      );
      onUpdateForm(reservationDetails.data);
    }
  }, [reservationDetails.isUpdateReservationModalOpen, onUpdateForm]);

  useEffect(() => {
    getTimeLimits();
  }, []);

  // Envolver la función search con debounce
  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query && query.length > 3) search({ text: query });
    }, 300),
    []
  );

  /* -------------------------------------------------------------------------------------------- */

  const [planTypesToDisabled, setPlanTypesToDisabled] = useState([]);
  const [
    isFirstTimeBranchOrTimeChanged,
    setIsFirstTimeBranchOrTimeChanged,
  ] = useState({ branch: true, time: true });

  // const [shouldUpdateEndHour, setShouldUpdateEndHour] = useState(true);

  const automaticReturnDate = false;

  const dispatch = useDispatch();

  // const quoteValues = useSelector((state) =>
  //   _get(state, 'reservations.quote.values', {})
  // );

  // const availableTime = useSelector((state) =>
  //   _get(state, 'reservations.quote.availableTime.list', [])
  // );

  const branches = useSelector((state) =>
    _get(state, 'commons.branches.list', [])
  );

  // const rentalPlans = useSelector((state) =>
  //   _get(state, 'dailyRentContracts.rentalPlans.list', [])
  // );

  // const rentalPlansToUse = useSelector((state) =>
  //   _get(state, 'dailyRentContracts.rentalPlans.toUse.list', [])
  // );

  // const availableStartHours = useSelector((state) =>
  //   _get(state, 'reservations.quote.selectedDates.start.list', [])
  // );

  const [chipReservationDays, setChipReservationDays] = useState(0);
  const [permitedBranches, setPermitedBranches] = useState([]);

  // const handleDaysNumberChange = (evt, currentValues = {}) => {
  //   const daysNumber = evt?.target?.value || values.daysNumber;
  //   const dateStart = currentValues?.dateStart || values.dateStart;
  //   const startHour = currentValues?.startHour || values.startHour;

  //   if (!daysNumber || !dateStart || !startHour) return;

  //   const returnDateTime = getReturnDateTime({
  //     daysNumber,
  //     dateStart,
  //     startHour,
  //   });

  //   const endDateMoment = moment(returnDateTime);
  //   const dateEnd = endDateMoment.format(YYYY_MM_DD);
  //   const endHour = endDateMoment.format(HH_MM);

  //   setFieldValue('dateEnd', dateEnd);
  //   setFieldValue('endHour', endHour);
  //   setFieldValue('returnDateTime', returnDateTime);
  // };

  useEffect(() => {
    if (branches <= 0) return;
    setPermitedBranches(
      branches.filter(
        (b) =>
          b.uuid !== '7911c109-0f58-4e3e-9334-b031cbd2ecf9' &&
          b.uuid !== 'b399666e-a92c-4a43-9448-0baffcfdfe0e' &&
          b.uuid !== '74908e93-4c32-4298-b049-565a2a243b10' &&
          b.uuid !== '98aec74c-a4d0-4394-ab99-fd710a383c6b' &&
          b.uuid !== 'f7ef995f-13c0-4b04-a3cf-0f0fe0df81e9' &&
          b.uuid !== 'e99f2bd9-a7ae-4adf-800d-364f3bbd359d' &&
          b.uuid !== 'b89506be-d5ef-41c0-a350-e41dd98336ac' &&
          b.uuid !== '561c5595-e904-46b4-bea0-bcacaf62431f'
      )
    );
  }, [branches]);

  // const setBranchEnd = useCallback(
  //   (branchUuid) => {
  //     if (branches.length > 0 && branchUuid) {
  //       const { name = null, uuid = null } =
  //         branches.find((branch) => branch.uuid === branchUuid) || {};

  //       if (uuid && name) {
  //         setFieldValue('branchEnd', uuid);
  //         setFieldValue('branchEndName', name);
  //       }
  //     }
  //   },
  //   [branches]
  // );

  const handleDateEndChange = useCallback(
    (value) => {
      if (value && value !== '' && dirty) {
        setFieldTouched('dateEnd', true);

        if (!automaticReturnDate) {
          setFieldValue('endHour', '');
        }
      }

      handleChipResevationNumber();
      // eslint-disable-next-line no-shadow
      validateForm(values).then((errors) => {
        const notErrors = !errors || (errors && !errors.dateEnd);
        const canSetAvailableDates = dirty && notErrors;
        if (canSetAvailableDates && !notPriceQuote) {
          dispatch(setEndAvailableHours(value));
        }
      });
    },
    [
      dirty,
      dispatch,
      validateForm,
      values,
      setEndAvailableHours,
      setFieldTouched,
      setFieldValue,
      notPriceQuote,
      automaticReturnDate,
    ]
  );

  const handleDateStartChange = useCallback(
    (value) => {
      if (value && value !== '' && dirty) {
        setFieldTouched('dateStart', true);
        setFieldValue('startHour', '');
      }

      // eslint-disable-next-line no-shadow
      validateForm({ ...values, dateStart: value }).then((errors) => {
        const canSetAvailableDates =
          dirty && (!errors || (errors && !errors.dateStart));
        if (canSetAvailableDates) {
          const now = moment(new Date(), `${YYYY_MM_DD} ${HH_MM}`);

          const remainder = 30 - (now.minute() % 30);

          const limitStart = isToday(value)
            ? moment(now).add(remainder, 'minutes').format(HH_MM)
            : null;
          // console.log({ value, limitStart });
          dispatch(setStartAvailableHours({ value, limitStart }));
          handleChipResevationNumber();
        }
      });
    },
    [
      dirty,
      dispatch,
      validateForm,
      values,
      setStartAvailableHours,
      setFieldTouched,
      setFieldValue,
      notPriceQuote,
      // handleDaysNumberChange,
      setRentalPlansToUse,
    ]
  );

  const handleChipResevationNumber = useCallback(() => {
    const star = moment(values.dateStart, 'YYYY-M-D');
    const end = moment(values.dateEnd, 'YYYY-M-D');
    const daysDiff =
      Number.isNaN(end.diff(star, 'days')) || end.diff(star, 'days') < 0
        ? 0
        : end.diff(star, 'days');
    setChipReservationDays(daysDiff);
  }, [values.dateStart, values.dateEnd]);

  const handleChangeBranchStart = useCallback(
    (evt) => {
      const branchStart = evt.target.value;
      if (isFirstTimeBranchOrTimeChanged.branch)
        setFieldValue('branchEnd', branchStart);
    },
    [branches, isFirstTimeBranchOrTimeChanged]
  );

  const minimumDateEnd = useCallback(() => {
    if (values.dateStart) {
      const formatedDate = formattedDateToPickerV2(values.dateStart);
      return formatedDate;
    }
    return utils().getToday();
  }, [values.dateStart]);

  const onStartHourChange = (evt) => {
    const { value } = evt.target;
    const hour = value.split(' ')[1];
    const mintsOfHour = hour && Number(hour.substr(3, 3));
    const naturalHour = hour && Number(hour.substr(0, 2));
    const increiseHour = naturalHour + 1;
    const startHour = `${increiseHour}:${mintsOfHour}`;

    if (isFirstTimeBranchOrTimeChanged.time) {
      const formatedDateEnd = moment(values.dateEnd);
      setFieldValue('endHour', `${formatedDateEnd.format(YYYY_MM_DD)} ${hour}`);
    }

    if (startHour) {
      dispatch(setEndLimitHours({ limitStart: startHour, limitEnd: null }));
    }

    if (values.dateEnd && !automaticReturnDate) {
      dispatch(setEndAvailableHours(values.dateEnd));
    }
  };

  // useEffect(() => {
  //   if (alwaysDirty) {
  //     setTimeout(() => {
  //       touchDates();
  //     }, [10]);
  //   }
  // }, [touchDates, handleSubmit]);

  useEffect(() => {
    const daysNumber = Number(chipReservationDays);

    if (daysNumber <= 0 && rentalPlansToUse.length > 0) {
      setRentalPlansToUse([]);
    } else if (daysNumber > 0 && rentalPlansToUse.length === 0) {
      const { vehicleLineUuid } = values;
      handleVehicleLineUuidChange({ target: { value: vehicleLineUuid } });
    }

    if (daysNumber > 0 && rentalPlansToUse.length > 0) {
      const { TWO_HUNDRED_FIFTY_KM, FREE_KM, WEEKLY, MONTHLY } = RENTAL_PLANS;

      const isTwoHundredFiftyPlan = daysNumber === 1;

      if (isTwoHundredFiftyPlan) {
        const twoHundredFiftyKmPlan = rentalPlansToUse.find(({ name }) =>
          name.includes(TWO_HUNDRED_FIFTY_KM)
        );
        if (twoHundredFiftyKmPlan) {
          setFieldValue('vehicleLinePrice', twoHundredFiftyKmPlan.uuid);
          setPlanTypesToDisabled(rentalPlansToUse);
        }
      }

      const isFreeKmPlan = daysNumber > 1 && daysNumber < 7;

      if (isFreeKmPlan) {
        const freeKmPlan = rentalPlansToUse.find(({ name }) =>
          name.includes(FREE_KM)
        );

        if (freeKmPlan) {
          setFieldValue('vehicleLinePrice', freeKmPlan.uuid);

          const newDisabledPlans = rentalPlansToUse.filter(
            ({ name }) =>
              !name.includes(FREE_KM) && !name.includes(TWO_HUNDRED_FIFTY_KM)
          );
          setPlanTypesToDisabled(newDisabledPlans);
        }
      }

      const isWeeklyPlan = daysNumber > 6 && daysNumber < 30;

      if (isWeeklyPlan) {
        const weeklyPlan = rentalPlansToUse.find(({ name }) =>
          name.includes(WEEKLY)
        );

        if (weeklyPlan) {
          setFieldValue('vehicleLinePrice', weeklyPlan.uuid);

          const newDisabledPlans = rentalPlansToUse.filter(({ name }) =>
            name.includes(MONTHLY)
          );
          setPlanTypesToDisabled(newDisabledPlans);
        }
      }

      const isMonthlyPlan = daysNumber > 29;

      if (isMonthlyPlan) {
        const monthlyPlan = rentalPlansToUse.find(({ name }) =>
          name.includes(MONTHLY)
        );

        if (monthlyPlan) {
          setFieldValue('vehicleLinePrice', monthlyPlan.uuid);
          setPlanTypesToDisabled(rentalPlansToUse);
        }
      }
    }
  }, [chipReservationDays, rentalPlansToUse, setFieldValue]);

  const handleChangeFilter = (option) => {
    setParams({ page: 0 });
    setCategorySelected(option.value);
    setFieldValue('types', '');
    setFieldValue('vehicleLineUuid', '');
    setFieldValue('vehicleLinePrice', '');
  };

  const handleChangeTypes = ({ target: { value } }) => {
    setFieldValue('vehicleLineUuid', '');
    setFieldValue('vehicleLinePrice', '');
    getVehiclesByStartRentalDayAndVehicleTypes(value);
  };

  const getVehiclesByStartRentalDayAndVehicleTypes = (type) => {
    const { dateStart, branchStart } = values;
    const startRentalDay = dateStart
      ? moment(dateStart).format(YYYY_MM_DD)
      : moment(
          reservationDetails.data.priceQuote.pickupDateTime,
          ISO_DATE_FORMAT
        ).format(YYYY_MM_DD);

    getVehicles({
      vehicleTypes: [[type]],
      brands: [],
      branch: branchStart,
      lineSelected: '',
      page: 0,
      startRentalDay,
      noPageable: true,
    });
  };

  const handleVehicleLineUuidChange = ({ target: { value } }) => {
    setFieldValue('vehicleLinePrice', '');
    const vehicleLine = vehicles.find(
      (vehicle) => vehicle.vehicleLineUuid === value
    );
    setRentalPlansToUse(vehicleLine.vehicleLinePrices);
  };

  useEffect(() => {
    // Actualiza la hora de devolución cuando cambia la hora de inicio y la opción automática está deshabilitada
    if (
      !automaticReturnDate &&
      values.startHour &&
      isFirstTimeBranchOrTimeChanged.time
    ) {
      const formatedDateEnd = moment(values.dateEnd);
      setFieldValue(
        'endHour',
        formatedDateEnd.format(YYYY_MM_DD) +
          ' ' +
          values.startHour.split(' ')[1]
      );
    }
  }, [values.dateEnd]);

  return (
    <div className="row">
      <div className="col-12">
        <SearchableSelect
          onChange={handleSelectCustomer}
          // onBlur={handleOnBlur}
          name="customer"
          label="Buscar cliente"
          placeholder="Buscar cliente"
          options="customers.basicInfo"
          mask={lettersAndNumbersWithSpaces(100)}
          onSearching={handleSearchCustomer}
          infoText="Si deseas modificar el cliente, puedes buscarlo aquí."
          icon="svg-icon-search"
          remove
          onRemove={handleRemove}
        />
      </div>
      <div className="col-6">
        <Input
          name="customerName"
          label="Cliente"
          placeholder="Cliente"
          maskPlaceholder=""
          disabled
        />
      </div>
      <div className="col-6">
        <Input
          name="email"
          placeholder="Correo"
          label="Correo"
          disabled
          maskPlaceholder=""
        />
      </div>
      <div className="col-12 text-center">
        <div className="gray-disclaimer">
          <h5 className="mb-3">Datos de la reservación original:</h5>
          <div className="row">
            <div className="pl-5 col-6 text-left">
              <p className="font-weight-bolder">
                Fecha de salida:{' '}
                <span className="font-weight-normal">
                  {moment(
                    reservationDetails.data.priceQuote.pickupDateTime,
                    ISO_DATE_FORMAT
                  ).format(MX_DATE_FORMAT)}
                </span>
              </p>
              <p className="font-weight-bolder">
                Fecha de devolución:{' '}
                <span className="font-weight-normal">
                  {moment(
                    reservationDetails.data.priceQuote.returnDateTime,
                    ISO_DATE_FORMAT
                  ).format(MX_DATE_FORMAT)}
                </span>
              </p>
            </div>
            <div className="col-6 text-left">
              <p className="font-weight-bolder">
                Sucursal de salida:{' '}
                <span className="font-weight-normal">
                  {reservationDetails.data.priceQuote.pickupBranch.name}
                </span>
              </p>
              <p className="font-weight-bolder">
                Sucursal de devolución:{' '}
                <span className="font-weight-normal">
                  {reservationDetails.data.priceQuote.returnBranch.name}
                </span>
              </p>
            </div>
            <div className="col-12 text-center">
              <p className="font-weight-bolder">
                Plan reservado:{' '}
                <span className="font-weight-normal">
                  {
                    reservationDetails.data.priceQuote.selectedVehicleLinePrice
                      .vehicle.vehicleBrandName
                  }{' '}
                  {
                    reservationDetails.data.priceQuote.selectedVehicleLinePrice
                      .vehicle.vehicleLineName
                  }{' '}
                  {
                    reservationDetails.data.priceQuote.selectedVehicleLinePrice
                      .vehicleLinePrice.rentalPlanName
                  }
                </span>
              </p>
            </div>
          </div>
        </div>
        <p className="text-danger">
          Para validar planes y horarios es necesario volver a llenar las fechas
        </p>
      </div>
      <div className="col-5">
        <DatePickerV2
          // initialValue={initialValues.dateStart}
          placeholder="Fecha de salida*"
          onChange={handleDateStartChange}
          name="dateStart"
          label="Fecha de salida*"
          autoComplete="off"
        />
      </div>
      <div className="col-2">
        <ConnectedSelect
          label="Hora*"
          name="startHour"
          options="reservations.quote.selectedDates.start"
          onChange={onStartHourChange}
        />
      </div>
      <div className="col-5">
        <Select
          label="Sucursal de salida*"
          name="branchStart"
          options={permitedBranches}
          selectProps={{ fullKey: true, availableFirstValue: true }}
          onChange={handleChangeBranchStart}
        />
      </div>
      <div className="col-5">
        <DatePickerV2
          initialValue={initialValues.dateEnd}
          placeholder="Fecha de devolución*"
          onChange={handleDateEndChange}
          name="dateEnd"
          label="Fecha de devolución*"
          autoComplete="off"
          minimumDate={minimumDateEnd()}
        />
      </div>
      <div className="col-2">
        <ConnectedSelect
          label="Hora*"
          name="endHour"
          options="reservations.quote.selectedDates.end"
          disabled={!values.startHour}
          onChange={() =>
            setIsFirstTimeBranchOrTimeChanged((prev) => ({
              ...prev,
              time: false,
            }))
          }
        />
      </div>

      <div className="col-5">
        <Select
          label="Sucursal de devolución*"
          name="branchEnd"
          options={permitedBranches}
          selectProps={{ fullKey: true, availableFirstValue: true }}
          onChange={() =>
            setIsFirstTimeBranchOrTimeChanged((prev) => ({
              ...prev,
              branch: false,
            }))
          }
        />
      </div>
      <div className="col-4 offset-2 align-self-center mt-4">
        <p className="text-primary-500 text-lg font-semibold">
          Total d&iacute;as de renta del veh&iacute;culo: {chipReservationDays}
        </p>
      </div>
      <div className="col-12 switch-inverted">
        <RadioGroupInput
          labelClass="d-flex align-items-center"
          groupClass="col-12 d-flex"
          parentClass="vehicle-radio-input"
          label=""
          name="selectedCategory"
          options={categories}
          onChange={handleChangeFilter}
        />
      </div>
      <div className="col-4">
        <Select
          label="Categoría*"
          name="types"
          options={vehicleTypes.filter((item) => item.label !== 'Sedan')}
          selectProps={{ fullKey: true, availableFirstValue: true }}
          onChange={handleChangeTypes}
        />
      </div>
      <div className="col-4">
        <ConnectedSelect
          label="Línea*"
          name="vehicleLineUuid"
          fullKey
          options="reservations.catalog.results"
          onChange={handleVehicleLineUuidChange}
        />
      </div>
      <div className="col-4">
        <ConnectedSelect
          label="Plan*"
          name="vehicleLinePrice"
          options="dailyRentContracts.rentalPlans.toUse"
          disabled={planTypesToDisabled.length === rentalPlansToUse.length}
          transformer={(options) =>
            transformDisableSelectOption(options, planTypesToDisabled)
          }
        />
      </div>
    </div>
  );
};

const ModifyReservationModal = (props) => {
  const {
    reservationDetails,
    clearReservationInfo,
    responseDialogUpdateReservation,
    resetResponseDialogUpdateReservation,
    resetCustomers,
    setRentalPlansToUse,
  } = props;

  const handleCloseModal = useCallback(() => {
    clearReservationInfo();
    resetResponseDialogUpdateReservation();
    resetCustomers();
    setRentalPlansToUse([]);
  }, [reservationDetails.isUpdateReservationModalOpen]);

  const FormUpdate = withForm({ config })(Component)({
    ...props,
    onCancel: handleCloseModal,
  });

  const handelOnCloseConfirm = () => {
    clearReservationInfo();
    resetResponseDialogUpdateReservation();
    resetCustomers();
    setRentalPlansToUse([]);
  };

  return (
    <>
      <Modal
        open={reservationDetails?.isUpdateReservationModalOpen}
        header={null}
        footer={null}
        // className="ViewRefundDetail"
        contentClassName="px-4 py-4 container"
        style={{ zIndex: -1 }}
        closeButton
        onClose={handleCloseModal}
        bigModal
      >
        <Section title="Modificación de reservación">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">{FormUpdate}</div>
            </div>
          </div>
        </Section>
      </Modal>
      <ResponseDialog
        open={responseDialogUpdateReservation.open}
        success={responseDialogUpdateReservation.success}
        errorCode={responseDialogUpdateReservation.errorCode}
        successTitle="RESERVACIÓN MODIFICADA"
        successLabel={i18n('COMMONS__CLOSE__TEXT')}
        successMessage="La reservación fue modificada exitosamente"
        onError={handelOnCloseConfirm}
        onClose={handelOnCloseConfirm}
        onSuccess={handelOnCloseConfirm}
        errorLabel={i18n('COMMONS__CLOSE__TEXT')}
      />
    </>
  );
};

export default ModifyReservationModal;
