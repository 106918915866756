import * as Yup from 'yup';
import i18n from '@i18n';
import {
  dateValidationGreat,
  differentValue,
  fileValidation,
} from 'utils/validations/common';
import { REQUIRED_MESSAGE } from 'utils/constants';
import { FIELDS } from './config';

const identityFileValidation = fileValidation({
  schema: Yup.mixed().required(REQUIRED_MESSAGE),
  fields: FIELDS,
});

export const passportInformationProps = {
  passportKey: Yup.string()
    .required(REQUIRED_MESSAGE)
    .max(20, i18n('ERROR__COMMONS__MAX_LENGTH', [20]))
    .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5])),
  passportYearIssue: Yup.string().required(REQUIRED_MESSAGE),
  passportValidity: differentValue(
    dateValidationGreat.required(REQUIRED_MESSAGE),
    'passportYearIssue',
    i18n('ERROR__CUSTOMERS__PASSPORT__INVALID_VALIDTY_INFO')
  ),
  passportCountry: Yup.string().required(REQUIRED_MESSAGE),
  passportCoverFront: identityFileValidation,
  passportCoverBack: identityFileValidation,
};

export const PassportInformationSchema = Yup.object().shape({
  ...passportInformationProps,
});

export default PassportInformationSchema;
