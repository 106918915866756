import i18n from '@i18n';
import { cancelLoan } from 'store/loans/actions';
import { getInitialValues, getInitialTouched } from 'utils/validations/fields';
import { successPath } from '@loans/common/config';

export const config = {
  schema: {},
  initialTouched: getInitialTouched({ fields: [] }),
  initialValues: getInitialValues({ fields: [] }),
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: false,
  submitForm: cancelLoan,
  dialogs: {
    confirmation: {},
    response: {
      successTitle: i18n('LOANS__CANCEL_LOAN__MODAL_SUCCESS__TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: i18n('LOANS__CANCEL_LOAN__MODAL_SUCCESS__MESSAGE'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: i18n('LOANS__LOAN_CREATOR__MODAL_SUCCESS__LABEL'),
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath,
    },
  },
};
