import React from 'react';
import { Loader } from '@casanova/casanova-common';
import Modal from '@casanova/casanova-common/lib/Modal';
import i18n from '@i18n';

function WaitingModal(props) {
  const {
    open,
    title = i18n('RESERVATIONS__CONTRACT__WAITING_SIGNATURES__TITLE'),
  } = props;

  const header = (
    <div className="modal-header justify-content-center align-items-center pt-3">
      <Loader show inner />
    </div>
  );

  return (
    <Modal open={open} header={header} className="action-modal">
      <div className="modal-body text-center mt-4">
        <h4 className="font-weight-bold mb-4">{title}</h4>
        <div className="font-weight-normal">
          {i18n('RESERVATIONS__CONTRACT__WAITING_SIGNATURES__BODY')}
        </div>
        <br />
        <br />
        <br />
      </div>
    </Modal>
  );
}

WaitingModal.defaultProps = {
  closeLabel: 'Cancelar',
};

export default WaitingModal;
