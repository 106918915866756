/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import {
  Modal,
  Section,
  Table,
  ActionModal,
  Icons,
} from '@casanova/casanova-common';
import { BANK_CARD_TYPES } from 'utils/constants/bankCardTypes';
import columns from './columns';
import ModifyCardModal from '@reservations/sections/ModifyCardModal';

const ClientCards = ({
  show,
  onClose,
  getCustomerCards,
  loader,
  uuidCustomer,
  setValues,
  index = 0,
}) => {
  const [listCards, setListCards] = useState([]);
  const [isShowActionModalOpen, setIsShowActionModalOpen] = useState(false);
  const [isShowModifyCardModalOpen, setIsShowModifyCardModalOpen] = useState(
    false
  );
  const [cardSelected, setCardSelected] = useState({});

  const handleOpenCloseActionModal = () =>
    setIsShowActionModalOpen((prev) => !prev);

  const handleOpenCloseModifyCardModal = () =>
    setIsShowModifyCardModalOpen((prev) => !prev);

  useEffect(() => {
    if (show) getCustomerCards(uuidCustomer);
  }, [show]);

  const cardsCustomer = useSelector(
    (state) => _get(state, 'reservations.cards', {}),
    (prev, next) => prev === next
  );

  useEffect(() => {
    const hash = {};
    let filterCards = cardsCustomer.data.filter((current) => {
      const exists = !hash[current.number];
      hash[current.number] = true;
      return exists;
    });

    filterCards = cardsCustomer.data.map((card) => ({
      id: card.id,
      uuid: card.uuid,
      name: card.bank,
      cardHolderName: card.cardHolderName,
      bankUuid: card.bankUuid,
      number: card.number,
      validity: card.validity,
      cvc: card.cvc,
      type: card.typeId === 1 ? BANK_CARD_TYPES.CREDIT : BANK_CARD_TYPES.DEBIT,
      typeId: card.typeId,
      bankCardTypeUuid: card.bankCardTypeUuid,
    }));
    setListCards(filterCards);
  }, [cardsCustomer]);

  const handleRowClick = ({
    uuid,
    cardHolderName,
    bankUuid,
    number,
    validity,
    cvc,
    bankCardTypeUuid,
    typeId,
  }) => {
    setIsShowActionModalOpen(true);
    setCardSelected({
      uuid,
      cardHolderName,
      bankUuid,
      number,
      validity,
      cvc,
      bankCardTypeUuid,
      typeId,
    });
    onClose();
  };

  const handleSelectCard = () => {
    const {
      cardHolderName,
      bankUuid,
      number,
      validity = '',
      cvc,
      bankCardTypeUuid,
      typeId,
    } = cardSelected;
    if (typeId === 1) {
      setValues(`cardNumber`, number);
      setValues(`cardType`, bankCardTypeUuid);
      setValues(`bank`, bankUuid);
      setValues(`holdbackCardHolder`, cardHolderName);
      setValues(`holdbackCode`, cvc);
      setValues(
        'holdbackValidity',
        `${validity.split('-')[1]}/${validity.split('-')[0].substring(2)}`
      );
    } else {
      setValues(`cardNumber`, number);
      setValues(`cardType`, bankCardTypeUuid);
      setValues(`bank`, bankUuid);
    }
    handleOpenCloseActionModal();
    onClose();
    // setCardSelected({});
  };

  const handleModifyCard = () => {
    handleOpenCloseActionModal();
    onClose();
    handleOpenCloseModifyCardModal();
  };

  return (
    <>
      <ActionModal
        open={isShowActionModalOpen}
        icon={<Icons.StatusWarning />}
        onClose={handleModifyCard}
        onAction={handleSelectCard}
        title="Acciones"
        message="¿Qué acción desea realizar?"
        actionLabel="Seleccionar tarjeta"
        closeLabel="Modificar tarjeta"
      />
      <ModifyCardModal
        open={isShowModifyCardModalOpen}
        handleOpenCloseModifyCardModal={handleOpenCloseModifyCardModal}
        cardSelected={cardSelected}
      />
      <Modal
        open={show}
        onClose={onClose}
        closeButton
        bigModal
        topContainerClassName={loader ? 'd-none' : ''}
      >
        <Section title="Tarjetas asociadas al cliente">
          <Table
            additionalHeader
            rowClick={handleRowClick}
            rowClassName="row-clickable"
            emptyMessage="No se encontraron resultados"
            dataList={listCards}
            columns={columns}
          />
        </Section>
      </Modal>
    </>
  );
};

ClientCards.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  getCustomerCards: PropTypes.func,
  loader: PropTypes.bool,
  uuidCustomer: PropTypes.string,
};

export default ClientCards;
