import _isEmpty from 'lodash/isEmpty';

export const formatAddress = (values) => ({
  exteriorNumber: values.outdoorNumber,
  interiorNumber: values.indoorNumber,
  proofAddressPeriod: values.period,
  proofAddressTypeUuid: values.addressDocumentType,
  street: values.street,
  suburbUuid: values.colony,
});
