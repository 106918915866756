// Funciónes para convertir precio a texto
const unidades = [
  '',
  'uno',
  'dos',
  'tres',
  'cuatro',
  'cinco',
  'seis',
  'siete',
  'ocho',
  'nueve',
];
const decenas = [
  '',
  'diez',
  'veinte',
  'treinta',
  'cuarenta',
  'cincuenta',
  'sesenta',
  'setenta',
  'ochenta',
  'noventa',
];
const especiales = {
  10: 'diez',
  11: 'once',
  12: 'doce',
  13: 'trece',
  14: 'catorce',
  15: 'quince',
  16: 'dieciséis',
  17: 'diecisiete',
  18: 'dieciocho',
  19: 'diecinueve',
};
const centenas = [
  '',
  'ciento',
  'doscientos',
  'trescientos',
  'cuatrocientos',
  'quinientos',
  'seiscientos',
  'setecientos',
  'ochocientos',
  'novecientos',
];

export const convertirNumeroALetras = (numero) => {
  let textoPrecio = '';
  const arrPrecio = numero.toString().split('.');
  const numeros = arrPrecio[0];
  const valor = separarTexto(numeros);
  if (valor[0]) {
    const millares = convertirNumeroATexto(valor[0]);
    if (millares === 'uno') {
      textoPrecio += `un millon `;
    } else {
      textoPrecio += `${millares} millones `;
    }
  }
  if (valor[1]) {
    const miles = convertirNumeroATexto(valor[1]);
    if (miles === 'uno') {
      textoPrecio += `mil `;
    } else {
      textoPrecio += `${miles} mil `;
    }
  }
  if (valor[2]) {
    const centenares = convertirNumeroATexto(valor[2]);
    textoPrecio += `${centenares} `;
  }
  // Condición para mostrar los centavos
  if (arrPrecio[1]) {
    textoPrecio += `PESOS ${arrPrecio[1]}/100`;
  } else {
    textoPrecio += `PESOS 00/100`;
  }
  return textoPrecio.toUpperCase();
};

function separarTexto(texto) {
  const longitudTotal = texto.length;

  // Determinar las longitudes de las partes
  let longitudParte1;
  let longitudParte2;

  if (longitudTotal <= 9) {
    longitudParte1 = longitudTotal - 6;
    longitudParte2 = 3;
  } else if (longitudTotal <= 6) {
    longitudParte1 = 1;
    longitudParte2 = longitudTotal - 3;
  } else {
    longitudParte1 = 0;
    longitudParte2 = longitudTotal;
  }

  // Separar el texto en tres partes: "", "parte1", "parte2"
  const parte1 = texto.substring(0, longitudParte1);
  const parte2 = texto.substring(
    longitudParte1,
    longitudParte1 + longitudParte2
  );
  const parte3 = texto.substring(longitudParte1 + longitudParte2);

  return [parte1, parte2, parte3];
}

function convertirNumeroATexto(numero) {
  // Convertir el número textual a su forma en palabras
  let num = parseInt(numero, 10);
  if (Number.isNaN(num) || num < 0 || num > 999) {
    return 'Número no válido';
  }

  if (num === 0) {
    return 'cero';
  }

  let textoFinal = '';
  if (num >= 100) {
    const centena = Math.floor(num / 100);
    textoFinal += centenas[centena];
    num %= 100;
    if (num > 0) {
      textoFinal += ' ';
    }
  }

  if (num > 0) {
    textoFinal += convertirMenorMil(num);
  }

  return textoFinal;
}

// Función para convertir números menores a 1000 a su forma textual en español
function convertirMenorMil(num) {
  if (num < 10) {
    return unidades[num];
  }
  if (num < 20) {
    return especiales[num];
  }
  const unidad = num % 10;
  const decena = Math.floor(num / 10) % 10;
  const centena = Math.floor(num / 100);

  let texto = '';
  if (centena > 0) {
    texto += centenas[centena];
    if (decena > 0 || unidad > 0) {
      texto += ' ';
    }
  }
  if (decena >= 2) {
    texto += decenas[decena];
    if (unidad > 0) {
      texto += ` y ${unidades[unidad]}`;
    }
  } else {
    texto += unidades[num];
  }
  return texto;
}
