import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { catalogReducer } from './catalogs/reducer';
import { vehiclesReducer } from './vehicles/reducer';
import { commonReducer } from './commons/reducer';
import { customerReducer } from './customers/reducer';
import { reservationsReducer } from './reservations/reducer';
import { adminPanelReducer } from './adminPanel/reducer';
import { activationReducer } from './activation/reducer';
import { transfersReducer } from './transfers/reducer';
import { loansReducer } from './loans/reducer';
import { contractsReducer } from './contracts/reducer';
import { invoicesReducer } from './invoices/reducer';
import { paymentsReducer } from './payments/reducer';
import { dailyRentContractsReducer } from './dailyRentContracts/reducer';
import { reportsReducer } from './reports/reducer';
import authReducer from './auth/reducer';
import appReducer from './app/reducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    commons: commonReducer,
    customers: customerReducer,
    catalogs: catalogReducer,
    vehicles: vehiclesReducer,
    reservations: reservationsReducer,
    adminPanel: adminPanelReducer,
    activation: activationReducer,
    transfers: transfersReducer,
    loans: loansReducer,
    contracts: contractsReducer,
    invoices: invoicesReducer,
    payments: paymentsReducer,
    dailyRentContracts: dailyRentContractsReducer,
    reports: reportsReducer,
    app: appReducer,
    auth: authReducer,
  });
export default createRootReducer;
