import * as Yup from 'yup';
import _noop from 'lodash/noop';
import { setInProgressQuote } from 'store/reservations/actions';

const customer = Yup.string().nullable();

const schema = Yup.object().shape({
  customer,
});

const config = {
  schema,
  initialTouched: {
    customer: false,
    selectedCategory: true,
  },
  initialValues: {
    customer: '',
    vehicleTypes: [''],
    brands: [],
    selectedCategory: '',
  },
  showContinue: false,
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: true,
  onCloseAction: () => setInProgressQuote(false),
  submitForm: _noop,
  btnSubmitLabel: 'Continuar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE REGISTRO',
      message:
        'Una vez cargada la información del cliente tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a Clientes',
      errorLabel: 'Aceptar',
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};

export default config;
