import * as Yup from 'yup';
import i18n from '@i18n';
import { fileValidation } from 'utils/validations/common';
import { CUSTOMER_TYPES } from '@customers/common/constants';
import { REQUIRED_MESSAGE } from 'utils/constants';

const companyDataDocumentTest = fileValidation({
  schema: Yup.mixed().required(REQUIRED_MESSAGE),
  fields: [],
});

const validation = (test) =>
  Yup.mixed().when('personType', {
    is: (personType) => personType === CUSTOMER_TYPES.JURIDICAL,
    then: test,
  });

const validationTender = (test) =>
  Yup.mixed().when('personType', {
    is: (personType) => personType === CUSTOMER_TYPES.TENDER,
    then: test,
  });

const requiredValidation = Yup.string().required(REQUIRED_MESSAGE);

export const companyDataProps = {
  companyName: validation(
    requiredValidation
      .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3]))
      .max(70, i18n('ERROR__COMMONS__MAX_LENGTH', [70]))
  ),
  companyRfc: validation(
    requiredValidation.min(12, i18n('ERROR__COMMONS__MIN_LENGTH', [12]))
  ),
  companySocialReason: validation(
    requiredValidation
      .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3]))
      .max(70, i18n('ERROR__COMMONS__MAX_LENGTH', [70]))
  ),
  companyPhone: Yup.string(),
  companyPhoneExt: Yup.string(),
  companyPhoneTwo: Yup.string().test(
    'different-phone',
    i18n('ERROR__CUSTOMERS__DIFFERENT_PHONES'),
    function different(companyPhoneTwo) {
      const { companyPhone } = this.parent;

      if (!companyPhoneTwo || !companyPhone) return true;

      return companyPhone !== companyPhoneTwo;
    }
  ),
  companyPhoneTwoExt: Yup.string(),
  companyConstituentAct: validation(companyDataDocumentTest),
  companyCompyRec: validation(companyDataDocumentTest),
  companyCouponFiscal: validation(companyDataDocumentTest),
  companyOpinionCompliance: validation(companyDataDocumentTest),
  companyRequestVehicles: validation(companyDataDocumentTest),
  companyPowerAttornery: validation(companyDataDocumentTest),
  companyLetterAuthorization: validation(companyDataDocumentTest),
};

export const companyTenderDataProps = {
  companyName: validationTender(
    requiredValidation
      .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3]))
      .max(70, i18n('ERROR__COMMONS__MAX_LENGTH', [70]))
  ),
  companyRfc: validationTender(
    requiredValidation.min(12, i18n('ERROR__COMMONS__MIN_LENGTH', [12]))
  ),
  companySocialReason: validationTender(
    requiredValidation
      .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3]))
      .max(70, i18n('ERROR__COMMONS__MAX_LENGTH', [70]))
  ),
  companyPhone: Yup.string(),
  companyPhoneExt: Yup.string(),
  companyPhoneTwo: Yup.string().test(
    'different-phone',
    i18n('ERROR__CUSTOMERS__DIFFERENT_PHONES'),
    function different(companyPhoneTwo) {
      const { companyPhone } = this.parent;

      if (!companyPhoneTwo || !companyPhone) return true;

      return companyPhone !== companyPhoneTwo;
    }
  ),
  companyPhoneTwoExt: Yup.string(),
  companyCompyRec: validationTender(companyDataDocumentTest),
  companyCouponFiscal: validationTender(companyDataDocumentTest),
};

export const CompanyDataSchema = Yup.object().shape({
  ...companyDataProps,
});

export default CompanyDataSchema;
