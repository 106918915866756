import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withForm } from '@casanova/casanova-common';
import {
  saveCustomerBaseInfo,
  saveCustomerForeginerBase,
} from 'store/customers/actions';
import config, {
  minimalImportantCustomerInformation,
} from '@customers/common/config';
import { CustomerFormBase } from '@customers/sections';
import { getFormatter } from '@customers/common/formatter';
import { getCustomerType } from '@customers/common/utils';
import { commonCustomerCatalogs } from 'utils/customers';
import {
  getQuotePath,
  isContractQuote as isContractQuoteRes,
} from 'utils/navigation';

export function CustomerCreatorBase(props) {
  const {
    fetchCatalog,
    dirty,
    submitted,
    history,
    quoteId,
    isMoral,
    onlyFisic = false,
    getAllCatalogs = true,
    customersPreventChange,
    avalCustomer,
  } = props;

  const isReservation = !!history?.location?.pathname?.includes('reservations');
  const isContractQuote = isContractQuoteRes();

  const [shouldBlock, setShouldBlock] = useState(true);

  const handleNoBlock = useCallback(
    (block) => {
      setShouldBlock(block);
    },
    [setShouldBlock]
  );

  const nextPath = isReservation
    ? '/reservations/customer/<%= id %>/detail'
    : '/customers/<%= id %>/detail';

  const getPreventChange = () => {
    if (customersPreventChange || avalCustomer) {
      const preventChangeInReservation =
        !submitted && (isReservation || isContractQuote) && shouldBlock;
      const preventChangeInCustomers = dirty && shouldBlock && !submitted;

      return preventChangeInReservation || preventChangeInCustomers;
    }
    return false;
  };

  useEffect(() => {
    if (isReservation && !quoteId && !isContractQuote) {
      window.location.href = getQuotePath('one');
    }

    if (getAllCatalogs) {
      commonCustomerCatalogs(fetchCatalog);
    }
  }, [fetchCatalog, getAllCatalogs, isContractQuote]);

  const formsProps = {
    ...props,
    preventChange: getPreventChange(),
    nextPath,
    handleNoBlock,
    isReservation,
    isJuridicalCustomer: isMoral,
    onlyFisic,
    avalCustomer,
  };

  return <CustomerFormBase {...formsProps} />;
}

export default (props) => {
  const { customerType, customerPersonType } = props;

  const isForeigner = useMemo(() => getCustomerType({ customerType }), [
    customerType,
  ]);

  const isJuridical = useMemo(
    () =>
      getCustomerType({
        customerType: customerPersonType,
        validationType: 'moral',
      }),
    [customerPersonType]
  );

  const isTender = useMemo(
    () =>
      getCustomerType({
        customerType: customerPersonType,
        validationType: 'tender',
      }),
    [customerPersonType]
  );

  const formattedConfig = useMemo(
    () => ({
      ...config,
      submitForm: isForeigner
        ? saveCustomerForeginerBase
        : saveCustomerBaseInfo,
      formatter: getFormatter({ isForeigner, isJuridical, isTender }),
      schema: minimalImportantCustomerInformation,
      dialogs: {
        ...config.dialogs,
        response: {
          ...config.dialogs.response,
          successLabel: 'Regresar',
          successPath: '/quoter/',
        },
      },
    }),
    [isForeigner, isJuridical, isTender]
  );

  const Component = withForm({ config: formattedConfig })(CustomerCreatorBase);

  return Component({ ...props, isForeigner, isMoral: isJuridical, isTender });
};
