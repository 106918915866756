import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { withForm, Modal, ActionModal, Icons } from '@casanova/casanova-common';
import { CRPInformation } from '@invoices/sections';
import i18n from '@i18n';

import { config } from './config';

function CreateCRP(props) {
  const { setHide, openConfirmationMessage = false } = props;
  useEffect(() => {
    setHide(openConfirmationMessage);
  }, [setHide, openConfirmationMessage]);

  return <CRPInformation {...props} />;
}

const CreateCRPWithForm = withForm({ config })(CreateCRP);

export default function CreateCRPWithModal({ show, onClose, ...props }) {
  const { responseDialog, loader } = props;

  const [hide, setHide] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleCancel = ({ dirty, submitted }) => {
    setShowConfirmModal(false);

    if (dirty && !submitted) {
      setShowConfirmModal(true);
      setHide(true);
      return;
    }

    setHide(false);
    onClose();
  };

  const hideModal = responseDialog.open || hide || loader;

  return (
    <Modal
      open={show}
      onClose={onClose}
      bigModal
      containerClassName="CreateCRP__Modal"
      contentClassName="CreateCRP__Modal"
      topContainerClassName={classNames(hideModal && 'd-none')}
    >
      <ActionModal
        open={showConfirmModal}
        icon={<Icons.StatusWarning />}
        onClose={() => {
          setShowConfirmModal(false);
          setHide(false);
        }}
        onAction={onClose}
        title={i18n('PREVENT_CHANGE__TITLE')}
        message={i18n('PREVENT_CHANGE__MESSAGE')}
        actionLabel={i18n('PREVENT_CHANGE__ACTION')}
        closeLabel={i18n('PREVENT_CHANGE__CLOSE')}
        closeButton
      />
      <CreateCRPWithForm
        onCancel={handleCancel}
        onSuccess={onClose}
        setHide={setHide}
        hide={hide}
        {...props}
      />
    </Modal>
  );
}
