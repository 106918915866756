import * as Yup from 'yup';
import i18n from '@i18n';
import {
  cardFormValidations,
  bankFormValidations,
  transferFormValidations,
  invoiceFormValidation,
} from '@reservations/Payment/schema';

export const schema = Yup.object().shape({
  methodSelected: Yup.string().when('totallyPaidWithRefund', {
    is: (totallyPaidWithRefund) => !totallyPaidWithRefund,
    then: Yup.string().required(i18n('ERROR__RESERVATIONS__PAYMENT__METHOD')),
    otherwise: Yup.string(),
  }),
  ...cardFormValidations(),
  ...bankFormValidations,
  ...transferFormValidations,
  ...invoiceFormValidation,
});
