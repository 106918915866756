import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getReservationInfo } from 'store/reservations/actions';
import {
  closeResponseDialog,
  getDailyRentContractsList,
} from 'store/dailyRentContracts/actions';
import { getVehicleLinePrices } from 'store/reservations/actions';
import ViewRefundDetail from './ViewRefundDetail';

export default compose(
  connect(
    (state) => ({
      responseDialog: state.dailyRentContracts.responseDialog,
      loader: state.commons.loader,
      reservationDetails: state.reservations.reservationInfo,
      refundDetails: state.dailyRentContracts.refund,
      contractsList: state.dailyRentContracts.list,
    }),
    {
      closeResponseDialog,
      getReservationInfo,
      getVehicleLinePrices,
      getDailyRentContractsList,
    }
  )
)(ViewRefundDetail);
