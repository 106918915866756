import types from './actions-types';

export function getDailyRentContractsList(payload) {
  return { type: types.GET_LIST, payload };
}

export function getDailyRentContractsListSucceed(payload) {
  return { type: types.GET_LIST_SUCCEED, payload };
}

export function getDailyRentContractsListFailed(payload) {
  return { type: types.GET_LIST_FAILED, payload };
}

export function setListParams(payload) {
  return { type: types.SET_PARAMS, payload };
}

export function resetDailyRent(payload) {
  return { type: types.RESET_PARAMS, payload };
}

export function setListPage(payload) {
  return { type: types.SET_LIST_PAGE, payload };
}

export function closeResponseDialog() {
  return { type: types.CLOSE_RESPONSE_DIALOG };
}

export function closeResponseDialogValidCode() {
  return { type: types.CLOSE_RESPONSE_DIALOG_VALID_CODE };
}

export function closeContract(payload) {
  return { type: types.CLOSE_CONTRACT, payload };
}

export function closeContractSucceed(payload) {
  return { type: types.CLOSE_CONTRACT_SUCCEED, payload };
}

export function closeContractFailed(payload) {
  return { type: types.CLOSE_CONTRACT_FAILED, payload };
}

export function getCloseContractDetails(payload) {
  return { type: types.GET_CLOSE_CONTRACT_DETAILS, payload };
}

export function getCloseContractDetailsSucceed(payload) {
  return { type: types.GET_CLOSE_CONTRACT_DETAILS_SUCCEED, payload };
}

export function getCloseContractDetailsFailed(payload) {
  return { type: types.GET_CLOSE_CONTRACT_DETAILS_FAILED, payload };
}

export function saveContractRefund(payload) {
  return { type: types.SAVE_CONTRACT_REFUND, payload };
}

export function saveContractRefundSucceed(payload) {
  return { type: types.SAVE_CONTRACT_REFUND_SUCCEED, payload };
}

export function saveContractRefundFailed(payload) {
  return { type: types.SAVE_CONTRACT_REFUND_FAILED, payload };
}

export function saveContractPay(payload) {
  return { type: types.SAVE_CONTRACT_PAY, payload };
}

export function saveContractPaySucceed(payload) {
  return { type: types.SAVE_CONTRACT_PAY_SUCCEED, payload };
}

export function saveContractPayFailed(payload) {
  return { type: types.SAVE_CONTRACT_PAY_FAILED, payload };
}

export function getTotalPay(payload) {
  return { type: types.GET_TOTAL_PAY, payload };
}

export function getTotalPaySucceed(payload) {
  return { type: types.GET_TOTAL_PAY_SUCCEED, payload };
}

export function getTotalPayFailed(payload) {
  return { type: types.GET_TOTAL_PAY_FAILED, payload };
}

export function setRentalPlanOptions(payload) {
  return { type: types.SET_RENTAL_PLAN_OPTIONS, payload };
}

export function setRentalPlansToUse(payload) {
  return { type: types.SET_RENTAL_PLAN_TO_USE, payload };
}
