import moment from 'moment';
import { YYYY_MM_DD, timeZone } from 'utils/constants';
import { LOAN_CREATOR_FIELDS as FIELDS } from '@loans/common/constants';

export const formatter = (values) => ({
  departureDate: moment(values[FIELDS.DEPERTURE_DATE], YYYY_MM_DD)
    .tz(timeZone)
    .format(YYYY_MM_DD),
  departureTime: values[FIELDS.DEPERTURE_TIME],
  returnDate: moment(values[FIELDS.RETURN_DATE], YYYY_MM_DD)
    .tz(timeZone)
    .format(YYYY_MM_DD),
  returnTime: values[FIELDS.RETURN_TIME],
  driverName: values[FIELDS.DRIVER],
  observations: values[FIELDS.OBSERVATIONS] || null,
  requester: values[FIELDS.REQUESTER],
  vehicle: values[FIELDS.PLATE],
});
