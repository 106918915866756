import * as Yup from 'yup';

import { REQUIRED_MESSAGE } from 'utils/validations';

const requiredValidation = Yup.string().required(REQUIRED_MESSAGE);

export const banProps = {
  contractNumber: requiredValidation,
  reason: requiredValidation,
};

export const BanSchema = Yup.object().shape({
  ...banProps,
});

export default BanSchema;
