import { initialValues } from './config';

const transformer = (data) => ({
  ...initialValues,
  contractNumber: data.number,
  contractUuid: data.uuid,
  customerUuid: data.naturalPerson,
  movementType: data.movementType,
});

export default transformer;
