import * as yup from 'yup';
import i18n from '@i18n';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index.js';

export const initialValues = {
  vehicles: [],
  listOfVehicles: [],
  searchText: '',
  hasSearched: false,
  hasAddVehicle: false,
  vehicleCategory: undefined,
};

export const initialTouched = {
  vehicles: true,
  listOfVehicles: true,
  searchText: true,
  hasSearched: true,
  hasAddVehicle: true,
  vehicleCategory: true,
};

const ERROR_MESSAGE = i18n(
  'ERROR__RESERVATIONS__NOT_PRICE_QUOTE__VEHICLES_REQUIRED'
);

export const validationSchema = yup.object().shape({
  vehicles: yup.array().min(1, ERROR_MESSAGE).required(REQUIRED_MESSAGE),
  listOfVehicles: yup.array(),
  searchText: yup
    .string()
    .test(
      'is-empty-search',
      i18n('ERROR__COMMONS__NOT_FOUND'),
      function isEmptySearch() {
        const {
          hasSearched = false,
          hasAddVehicle = false,
          listOfVehicles = [],
        } = this.parent;

        if (hasSearched && !hasAddVehicle && listOfVehicles.length === 0) {
          return false;
        }

        return true;
      }
    ),
  hasSearched: yup.boolean(),
  hasAddVehicle: yup.boolean(),
});
