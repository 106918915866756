"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usernameSchema = exports.username = exports.uniqueUsername = exports.uniquePhoneNumber = exports.uniqueEmail = exports.requiredSearch = exports.phoneSchema = exports.phoneNumber = exports.getInitialValues = exports.getInitialTouched = exports.emailSchema = exports.email = exports.cellphone = exports.UniquePhoneSchema = exports.UniqueEmailSchema = void 0;
var Yup = _interopRequireWildcard(require("yup"));
var _i18n = _interopRequireDefault(require("../i18n"));
var _constants = require("../constants");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
var cellphone = exports.cellphone = Yup.string().min(10, _constants.PHONE_LENGTH);
var email = exports.email = Yup.string().min(5, _constants.EMAIL_LENGHT_MIN).max(50, _constants.EMAIL_LENGHT_MAX).email(_constants.EMAIL_NOT_VALID);
var getInitialValues = exports.getInitialValues = function getInitialValues(_ref) {
  var _ref$fields = _ref.fields,
    fields = _ref$fields === void 0 ? [] : _ref$fields;
  return Object.values(fields).reduce(function (acc) {
    var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    acc[value] = '';
    return acc;
  }, {});
};
var getInitialTouched = exports.getInitialTouched = function getInitialTouched(_ref2) {
  var _ref2$fields = _ref2.fields,
    fields = _ref2$fields === void 0 ? [] : _ref2$fields;
  return Object.values(fields).reduce(function (acc, value) {
    acc[value] = true;
    return acc;
  }, {});
};
var phoneNumber = exports.phoneNumber = Yup.string().required(_constants.REQUIRED_MESSAGE).min(10, _constants.PHONE_LENGTH);
var uniquePhoneNumber = exports.uniquePhoneNumber = phoneNumber.test('unique-phone', (0, _i18n["default"])('ERROR__COMMONS__CELLPHONE__NO_UNIQUE'), function validate(value) {
  if (!value) return true;
  var isUniquePhone = this.parent.isUniquePhone;
  if (typeof isUniquePhone === 'undefined') return true;
  return isUniquePhone;
});
var uniqueEmail = exports.uniqueEmail = email.required(_constants.REQUIRED_MESSAGE).test('unique-email', (0, _i18n["default"])('ERROR__COMMONS__EMAIL__NO_UNIQUE'), function validate(value) {
  if (!value) return true;
  var isUniqueEmail = this.parent.isUniqueEmail;
  if (typeof isUniqueEmail === 'undefined') return true;
  return isUniqueEmail;
});
var emailSchema = exports.emailSchema = Yup.object().shape({
  email: email.required(_constants.REQUIRED_MESSAGE)
});
var UniqueEmailSchema = exports.UniqueEmailSchema = Yup.object().shape({
  email: uniqueEmail
});
var UniquePhoneSchema = exports.UniquePhoneSchema = Yup.object().shape({
  cellphone: cellphone.required(_constants.REQUIRED_MESSAGE)
});
var phoneSchema = exports.phoneSchema = Yup.object().shape({
  cellphone: phoneNumber
});
var username = exports.username = Yup.string().required(_constants.REQUIRED_MESSAGE).min(4, _constants.USERNAME_LENGHT);
var uniqueUsername = exports.uniqueUsername = username.required(_constants.REQUIRED_MESSAGE).test('unique-username', (0, _i18n["default"])('ERROR__COMMONS__USERNAME__NO_UNIQUE'), function validate(value) {
  if (!value) return true;
  var isUniqueUsername = this.parent.isUniqueUsername;
  if (typeof isUniqueUsername === 'undefined') return true;
  return isUniqueUsername;
});
var usernameSchema = exports.usernameSchema = Yup.object().shape({
  username: username
});
var requiredSearch = exports.requiredSearch = function requiredSearch(path) {
  return function (value) {
    return value && this.parent[path];
  };
};