import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { closeResponseDialog } from 'store/invoices/actions';
import CreateCRP from './CreateCRP';

export default compose(
  connect(
    (state) => ({
      responseDialog: state.invoices.responseDialog,
      loader: state.commons.loader,
    }),
    { closeResponseDialog }
  )
)(CreateCRP);
