import React, { useEffect } from 'react';
import {
  VehicleDetail,
  VehicleContracts,
  VehicleLoans,
  VehicleTransfers,
  VehicleStatus,
  VehicleVirtualContracts,
} from '@vehicles/sections';

import { withForm } from '@casanova/casanova-common';
import RoleVerifier from 'components/RoleVerifier';

import { scrollTop } from 'utils/screen';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { VIEW_VEHICLE_GENERAL_INFORMATION_HISTORY } from 'utils/roles/permissions';

import { config } from './config';

function VehicleHistoricDetail(props) {
  const { match, fetchHistoricDetails } = props;
  const vehicleUuid = match.params.id;

  useEffect(() => {
    fetchHistoricDetails({ vehicleUuid });
  }, [fetchHistoricDetails, vehicleUuid]);

  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <RoleVerifier identifier={VIEW_VEHICLE_GENERAL_INFORMATION_HISTORY}>
      <VehicleDetail {...props} action={FORM_ACTIONS.VIEW} fetchData={false} />
      <VehicleStatus {...props} onlyFetchParams />
      <VehicleContracts {...props} onlyFetchParams />
      {/* <VehicleLoans {...props} onlyFetchParams /> */}
      {/* <VehicleTransfers {...props} onlyFetchParams /> */}
      <VehicleVirtualContracts {...props} onlyFetchParams />
    </RoleVerifier>
  );
}

export default withForm({ config })(VehicleHistoricDetail);
