import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, yupToFormErrors, validateYupSchema, FieldArray } from 'formik';
import { Button, Icons } from '@casanova/casanova-common';
import * as validations from 'utils/validations/additionals';
import { gps as gpsMeta } from 'services/vehicles/additionals';
import { CREATE_VEHICLE_GPS_ORDERS } from 'utils/roles/permissions';
import UserInfo from 'components/UserInfo';
import { useSelector, useDispatch } from 'react-redux';
import RoleVerifier from 'components/RoleVerifier';
import PreventChange from 'components/PreventChange';
import Tabs from 'components/Tabs/Tabs';
import BoxAdd from 'components/BoxAdd';
import {
  clearImeiGPSSelect,
  clearSelectGPS,
  modeRemoveGps,
} from 'store/catalogs/actions';
import { useVehicle } from 'hooks';
import ActionModal from '../components/ActionModal';
import { withAdditionalCollapse } from '../AdditionalCollapse';
import WrapperOrder from './components/WrapperOrder';
import CardGps from './components/CardGps';
import Section from './Aditional/Section';
import PanelTab from './Aditional/PanelTab';

function Gps(props) {
  const {
    addNew,
    closeAdditionalResult,
    onCancelModal,
    editable,
    fetchInstaller,
    fetchCatalog,
    getAdditional,
    data,
    isCancelModalVisible,
    isNew,
    name,
    onEdit,
    saveAdditional,
    vehicle,
    vehicleId,
    resultMessage,
    show,
  } = props;
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState([
    {
      label: 'GPS 1',
      content: <Section />,
    },
  ]);
  const [activeTab, setActiveTab] = useState(
    data ? data.gps[0].label : tabs[0].label
  );
  useEffect(() => {
    setActiveTab(data ? data.gps[0].label : tabs[0].label);
  }, [data, tabs]);
  const [newGps, setNewGps] = useState(false);
  const [instalationType, setInstalationType] = useState({
    statu: false,
    type: 'Instalación',
  });
  const [labelAuxS, setlabelAuxS] = useState('');
  const [removeType, setRemoveType] = useState(false);
  const [reinstallType, setReinstallType] = useState(false);
  const [modeRemove, setModeRemove] = useState(false);
  const [modeReinstall, setModeReinstall] = useState(false);
  const formRef = useRef(null);
  const [submit, setSubmit] = useState(false);
  const orderType = useSelector((state) => state.catalogs.gpsOrderType);
  const selectGPS = useSelector((state) => state.catalogs.selectGPS);

  const { isSold } = useVehicle();

  useEffect(() => {
    if (!show && formRef) {
      formRef.current.resetForm(gpsMeta.empty);
    }
  }, [show]);

  useEffect(() => {
    if (vehicleId) {
      getAdditional({ name, data: vehicleId });
    }
    fetchCatalog('gps-installers');
    fetchCatalog('gps');
    fetchCatalog('gps-order-type');
    fetchCatalog('telematic-icon');
  }, [name, fetchInstaller, fetchCatalog, vehicleId, getAdditional]);

  const getOrderType = (types) => {
    const order = orderType.list.filter((type) => type.name === types);
    return order[0].uuid;
  };

  const handleFormik = useCallback(
    (values) => {
      //console.log("Values: " ,values)
      setSubmit(true);
      onCancelModal(true);
    },
    [data, addNew, saveAdditional, name, vehicleId, vehicle]
  );

  const handleCancel = (formikProps, action) => {
    if (editable) {
      onEdit(false);
      props.cancelNewAdditional({ name });

      if (isNew) {
        props.forceClose(name);
      }
    } else {
      if (!reinstallType) {
        formikProps.resetForm(gpsMeta.empty);
      }
      props.addNewAdditional({ name });
    }
  };

  const handleValidation = (values) => {
    const { buyDate } = vehicle;
    try {
      validateYupSchema(values, validations.gps, true, {
        buyDate,
        reinstallType,
        modeReinstall,
        modeRemove,
        removeType,
        selectGPS,
      });
    } catch (error) {
      return yupToFormErrors(error);
    }
    return {};
  };

  const handleConfirmCancelModal = (formikProps) => {
    // setReinstallType(false)
    onEdit(false);
    formikProps.resetForm();
    props.onCancelModal(false);
    props.onRequestClose(name);
    if (addNew) {
      props.cancelNewAdditional({ name });
    }
  };

  const handleConfirmModal = (values) => {
    // console.log("VALUES: ", values);
    saveAdditional({
      name,
      data: {
        ...values,
        vehicleId,
        serialNumber: vehicle.serialNumber,
        type: getOrderType(
          removeType
            ? 'Retiro'
            : reinstallType
            ? 'Re-instalación'
            : addNew || isNew
            ? instalationType.type
            : 'Instalación'
        ),
        mode: removeType
          ? 'Retiro'
          : reinstallType
          ? 'Re-instalación'
          : addNew || isNew
          ? instalationType.type
          : 'Instalación',
      },
      forceCreate: true,
      addNew,
    });
    setSubmit(false);
    dispatch(clearImeiGPSSelect());
    dispatch(modeRemoveGps(''));
    handleCloseCancelModal();
  };

  const handleCloseCancelModal = (formikProps) => {
    setSubmit(false);
    props.onCancelModal(false);
  };
  const cancelLabel = editable
    ? 'Cancelar'
    : !editable
    ? 'Retirar'
    : 'Instalar';

  const cancelEditTitle = !addNew
    ? 'Instalar equipo satelital'
    : submit
    ? 'Instalar equipo satelital'
    : 'Estás a punto de salir';
  const typeTittle = reinstallType
    ? 'REINSTALACIÓN DE\nEQUIPO SATELITAL'
    : 'RETIRO DE EQUIPO SATELITAL';

  const handleCloseResultModal = () => {
    fetchCatalog('gps');
    closeAdditionalResult({ name });
    setInstalationType({
      statu: false,
      type: 'Instalación',
    });
    setModeRemove(false);
    setRemoveType(false);
    setModeReinstall(false);
    setReinstallType(false);
    dispatch(clearSelectGPS());
    dispatch(modeRemoveGps(''));
  };
  const handleOpenPdf = () => {
    window.open(resultMessage.result.presignedUrl);
  };
  const handleAddSection = (section) => {
    setTabs([...tabs, section]);
  };
  const handleDeleteSection = (section) => {
    setTabs(section);
  };
  const installNewGPS = (formikProps, setValues, values = {}) => {
    // setlabelAuxS(values.gps[0].label)
    if (removeType) {
      setModeRemove(true);

      selectGPS.forEach((gps, index) => {
        if (values.gps[index] === undefined) {
          values.gps.push({
            tagGPS: gps.gps.uuid,
            imeiGPS: gps.gps.imei,
            installationPlace: gps.installationPlace,
            remarks: gps.remarks,
            label: gps.label,
            SelectedtagGPS: gps.gps.tag,
          });
        }
        if (gps.label !== values.gps[index].label) {
          if (index < selectGPS.length) {
            try {
              values.gps[index].tagGPS = gps.gps.uuid;
              values.gps[index].imeiGPS = gps.gps.imei;
              values.gps[index].installationPlace = gps.installationPlace;
              values.gps[index].remarks = gps.remarks;
              values.gps[index].label = gps.label;
              values.gps[index].SelectedtagGPS = gps.gps.tag;
            } catch (error) {
              console.log('---error', error);
            }
          }
        }
      });
      // console.log('RemoveValues', values.gps);
      setActiveTab(values.gps[0].label);
      setValues(values);
      handleCancel(formikProps);
      setNewGps(true);
      return;
    }
    if (reinstallType) {
      setModeReinstall(true);
      selectGPS.forEach((gps, index) => {
        if (values.gps[index] === undefined) {
          values.gps.push({
            tagGPS: gps.gps.uuid,
            imeiGPS: gps.gps.imei,
            installationPlace: gps.installationPlace,
            remarks: gps.remarks,
            label: gps.label,
            SelectedtagGPS: gps.gps.tag,
          });
        }
        if (gps.label !== values.gps[index].label) {
          if (index < selectGPS.length) {
            try {
              values.gps[index].tagGPS = gps.gps.uuid;
              values.gps[index].imeiGPS = gps.gps.imei;
              values.gps[index].installationPlace = gps.installationPlace;
              values.gps[index].remarks = gps.remarks;
              values.gps[index].label = gps.label;
              values.gps[index].SelectedtagGPS = gps.gps.tag;
            } catch (error) {
              console.log('---error', error);
            }
          }
        }
      });
      // values.gps = values.gps.filter((value, index) => _isEmpty(value.tagGPS_search))
      values.gps.splice(selectGPS.length, 4);
      // console.log('ReinstallValues: ', values.gps);
      // let test = []
      // values.gps.forEach(gp => {
      //   test.push({
      //     tagGPS: gp.tagGPS,
      //     imeiGPS: gp.imeiGPS,
      //     installationPlace: gp.installationPlace,
      //     remarks: gp.remarks,
      //     label: gp.label,
      //     SelectedtagGPS: gp.SelectedtagGPS
      //   })
      // })
      // values.gps = test
      setActiveTab(values.gps[0].label);
      setValues(values);
      handleCancel(formikProps);
      setNewGps(true);
      return;
    }
    if (values.gps.length === 2) {
      values.gps.pop();
    }
    if (values.gps.length === 3) {
      values.gps.splice(1, 2);
    }
    values.gps.map((val, i) => {
      val.isCustom = false;
      val.gpsTag = '';
      val.tagGPS = '';
      val.imeiGPS = '';
      val.installationPlace = '';
      val.remarks = '';
      if (val.label === 'GPS 3') {
        val.isCustom = false; 
        val.insertOrder = 3;
      }
      if (val.label === 'GPS 2') {
        val.isCustom = false; 
        val.insertOrder = 2;
      }

      val.label = `GPS ${values.totalInstalledGps + 1}`;
      // console.log('InstallValues', values.gps);
    });
    setActiveTab(values.gps[0].label);
    setValues(values);
    handleCancel(formikProps);
    setNewGps(true);
  };
  const optionsHandler = (formikProps, reinstall = false) => {
    if (!editable) {
      if (reinstall) {
        setReinstallType(true);
        dispatch(modeRemoveGps('Reinstall'));
        return;
      }
      setRemoveType(true);
      dispatch(modeRemoveGps('Retiro'));
      return;
    }
    handleCancel(formikProps);
    setActiveTab(`GPS ${formikProps.values.orders.length + 1}`);
    setInstalationType({
      statu: false,
      type: 'Instalación',
    });
    setRemoveType(false);
    setModeReinstall(false);
    setModeRemove(false);
    setNewGps(false);
    dispatch(clearSelectGPS());
    dispatch(modeRemoveGps(''));
  };
  const messageSingular = `Se ${
    removeType
      ? 'desvinculó el GPS del vehículo de forma exitosa. ¿Deseas imprimir la orden de retiro antes de salir?'
      : reinstallType
      ? 'reinstaló el GPS del vehículo de forma exitosa. ¿Deseas imprimir la orden de reinstalación antes de salir?'
      : 'vinculó el GPS al vehículo de forma exitosa. ¿Deseas imprimir la orden de instalación antes de salir?'
  }`;
  const messagePlural = `Se ${
    removeType
      ? 'desvincularon los GPS del vehículo de forma exitosa. ¿Deseas imprimir la orden de retiro antes de salir?'
      : reinstallType
      ? 'reinstalaron los GPS del vehículo de forma exitosa. ¿Deseas imprimir la orden de reinstalación antes de salir?'
      : 'vincularon los GPS al vehículo de forma exitosa. ¿Deseas imprimir la orden de instalación antes de salir?'
  }`;
  const messageSingularConfirmation = `Se ${
    reinstallType
      ? 'reinstalará el GPS seleccionado en el vehículo actual'
      : 'desvinculará el GPS del\nvehículo actual\n'
  } ¿Estás seguro que quieres continuar?`;
  const messagePluralConfirmation = `Se ${
    reinstallType
      ? 'reinstalarán los GPS seleccionados en el vehículo actual'
      : 'desvincularán los GPS\ndel vehículo actual\n'
  } ¿Estás seguro que quieres continuar?`;
  const conditionMessage = (values) =>
    removeType || reinstallType
      ? selectGPS.length <= 1
      : values.gps.length <= 1;
  const conditionMessageSucces = (values) =>
    removeType || reinstallType
      ? selectGPS.length <= 1
      : values.gps.length <= 1;

  return (
    <div className="container-fluid">
      <Formik
        initialValues={
          addNew ? (newGps ? data : gpsMeta.empty) : data || gpsMeta.empty
        }
        validate={handleValidation}
        validateOnChange
        enableReinitialize
        onSubmit={handleFormik}
        innerRef={formRef}
      >
        {(formikProps) => {
          const { handleSubmit, values, dirty, setValues } = formikProps;
          return (
            <PreventChange preventChange={dirty && editable}>
              <div className="vehicleEditor">
                {resultMessage && (
                  <ActionModal
                    open={resultMessage.open}
                    icon={
                      resultMessage.success ? (
                        <Icons.IcoCarOk />
                      ) : (
                        <Icons.IcoCarError />
                      )
                    }
                    onClose={handleCloseResultModal}
                    onAction={
                      resultMessage.success
                        ? handleOpenPdf
                        : handleCloseResultModal
                    }
                    title={
                      resultMessage.success
                        ? '¡BIEN HECHO!'
                        : resultMessage.title
                    }
                    actionLabel={resultMessage.success ? 'Si, imprimir' : 'OK'}
                    closeLabel="Salir"
                    message={
                      resultMessage.success
                        ? conditionMessageSucces(values)
                          ? messageSingular
                          : messagePlural
                        : resultMessage.message
                    }
                    modalType={
                      resultMessage.success ? 'action' : 'confirmation'
                    }
                    code={resultMessage.errorCode}
                    closeButton
                  />
                )}
                <ActionModal
                  open={isCancelModalVisible}
                  icon={<Icons.StatusWarning />}
                  onClose={handleCloseCancelModal}
                  onAction={
                    submit
                      ? () => handleConfirmModal(values)
                      : () => handleConfirmCancelModal(formikProps)
                  }
                  title={
                    removeType || reinstallType ? typeTittle : cancelEditTitle
                  }
                  actionLabel="Sí, continuar"
                  closeLabel="Cancelar"
                  message={
                    submit
                      ? conditionMessage(values)
                        ? removeType || reinstallType
                          ? messageSingularConfirmation
                          : 'Se vinculará el GPS al vehículo.\n¿Estás seguro que quieres continuar?'
                        : removeType || reinstallType
                        ? messagePluralConfirmation
                        : 'Se vincularán los GPS al vehículo.\n¿Estás seguro que quieres continuar?'
                      : 'Al cancelar se perderán todos los datos previamente llenados. ¿Estás seguro que quieres continuar?'
                  }
                  closeButton
                  data={formikProps}
                  classModalTitle={modeReinstall ? 'modal__title--center' : ''}
                />
                <div className="row">
                  <div className="col-12 mt-2">
                    {(removeType || reinstallType) &&
                      !modeRemove &&
                      !modeReinstall && (
                        <div
                          className={`${
                            removeType || reinstallType ? 'd-flex' : 'd-none'
                          } justify-content-between mb-4`}
                        >
                          <div className="my-auto">
                            <p className="font-weight-bold n-orden">
                              Selecciona los equipos que deseas{' '}
                              {reinstallType ? 'reinstalar' : 'retirar'}
                            </p>
                          </div>
                        </div>
                      )}
                    {!editable && (
                      <>
                        <FieldArray
                          name="gps"
                          render={(arrayHelpers) => (
                            <>
                              {data &&
                                data.orders.map((dat, index) => (
                                  <WrapperOrder
                                    values={values}
                                    name={index}
                                    data={dat}
                                    folio={dat.folio}
                                    key={dat.uuid}
                                    isNew={isNew}
                                    editable={editable}
                                    removeType={removeType}
                                  >
                                    {dat.containedGps.map((gps, k) => (
                                      <CardGps
                                        key={gps.gps.uuid}
                                        data={gps}
                                        gpsNumber={gps.insertOrder}
                                        removeType={removeType}
                                        reinstallType={reinstallType}
                                        value={gps.gps.uuid}
                                        gps={gps}
                                        modeRemove={modeRemove}
                                        dateOperation={dat.operationDate}
                                      />
                                    ))}
                                    <UserInfo {...dat} />
                                  </WrapperOrder>
                                ))}
                            </>
                          )}
                        />
                      </>
                    )}
                    {editable && (
                      <WrapperOrder
                        isNew={isNew}
                        editable={editable}
                        removeType={removeType}
                        reinstallType={reinstallType}
                      >
                        {!isNew && !removeType && !reinstallType && (
                          <Tabs
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            tabs={tabs}
                            handleDeleteSection={handleDeleteSection}
                            handleAddSection={handleAddSection}
                            isNew={isNew}
                            setValues={setValues}
                            name="gps"
                            nameTab="gps"
                          >
                            <FieldArray
                              name="gps"
                              render={() => (
                                <>
                                  {values.gps.map((gp, index) => {
                                    if (gp.label !== activeTab)
                                      return undefined;
                                    return <Section key={index} name={index} />;
                                  })}
                                </>
                              )}
                            />
                          </Tabs>
                        )}
                        {isNew && (
                          <Tabs
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            tabs={tabs}
                            handleDeleteSection={handleDeleteSection}
                            handleAddSection={handleAddSection}
                            setValues={setValues}
                            name="gps"
                            nameTab="gps"
                          >
                            <FieldArray
                              name="gps"
                              render={() => (
                                <>
                                  {values.gps.map((gp, index) => {
                                    if (gp.label !== activeTab)
                                      return undefined;
                                    return <Section name={index} />;
                                  })}
                                </>
                              )}
                            />
                          </Tabs>
                        )}
                        {removeType && (
                          <>
                            {selectGPS.map((gps, index) => (
                              <>
                                <CardGps
                                  key={gps.uuid}
                                  data={gps}
                                  gpsNumber={gps.label}
                                  removeType={removeType}
                                  value={gps.gps.uuid}
                                  gps={gps.gps}
                                  modeRemove={modeRemove}
                                />
                                <FieldArray
                                name="gps"
                                render={() => (
                                  <>
                                    {values.gps.map((gp, index) => {
                                      return <Section name={index} />;
                                    })}
                                  </>
                                )}
                              />
                            </>
                            ))}
                          </>
                        )}
                        {reinstallType && (
                          <Tabs
                            sections={values.gps}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                            handleDeleteSection={handleDeleteSection}
                            handleAddSection={handleAddSection}
                            setValues={setValues}
                            modeReinstall={modeReinstall}
                            name="gps"
                            nameTab="gps"
                          >
                            <FieldArray
                              name="gps"
                              render={() => (
                                <>
                                  {values.gps.map((gp, index) => {
                                    if (gp.label !== activeTab)
                                      return undefined;
                                    return <Section name={index} />;
                                  })}
                                </>
                              )}
                            />
                          </Tabs>
                        )}
                      </WrapperOrder>
                    )}
                  </div>
                </div>
                <UserInfo {...data} />
                {!isSold && (
                  <RoleVerifier identifier={CREATE_VEHICLE_GPS_ORDERS}>
                    <div className="row mt-4 mb-4">
                      <div className="col-12 col-md-12 col-lg-4 d-flex footer-content">
                        {!removeType && !reinstallType ? (
                          <>
                            {!editable && data && data.totalInstalledGps < 3 ? (
                              <div className="add-gps">
                                <BoxAdd
                                  fn={() =>
                                    installNewGPS(
                                      formikProps,
                                      setValues,
                                      values
                                    )
                                  }
                                  classname="add-tab"
                                />
                                <p className="ml-3">Instalar nuevo GPS</p>
                              </div>
                            ) : (
                              <>
                                {data && data.orders.length < 3 && (
                                  <p className="mandatory">
                                    <span>
                                      <Icons.Comment />
                                    </span>{' '}
                                    *Campos obligatorios.
                                  </p>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <p className="mandatory">
                            <span>
                              <Icons.Comment />
                            </span>{' '}
                            *Campos obligatorios.
                          </p>
                        )}
                      </div>
                      <div className="col-12 col-md-12 col-lg-8 d-flex bottom-actions">
                        {!removeType && !reinstallType ? (
                          <>
                            {!editable && (
                              <Button
                                outline={!editable}
                                onClick={() => {
                                  optionsHandler(formikProps, true);
                                }}
                              >
                                Reinstalar
                              </Button>
                            )}
                            <Button
                              outline={editable}
                              onClick={() => {
                                optionsHandler(formikProps);
                              }}
                            >
                              {cancelLabel}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              outline
                              onClick={() => {
                                if (
                                  (removeType && modeRemove) ||
                                  (reinstallType && modeReinstall)
                                ) {
                                  optionsHandler(formikProps);
                                }
                                setRemoveType(false);
                                setReinstallType(false);
                                setModeRemove(false);
                                setModeReinstall(false);
                                dispatch(clearSelectGPS());
                                dispatch(modeRemoveGps(''));
                              }}
                            >
                              CANCELAR
                            </Button>
                            {selectGPS.length >= 1 &&
                              !modeRemove &&
                              !modeReinstall && (
                                <Button
                                  outline={false}
                                  onClick={() => {
                                    installNewGPS(
                                      formikProps,
                                      setValues,
                                      values
                                    );
                                  }}
                                >
                                  CONTINUAR
                                </Button>
                              )}
                          </>
                        )}
                        {(addNew || isNew) && (
                          <Button onClick={handleSubmit} type="submit">
                            {modeRemove
                              ? 'Retirar'
                              : modeReinstall
                              ? 'Reinstalar'
                              : 'Instalar'}
                          </Button>
                        )}
                      </div>
                    </div>
                  </RoleVerifier>
                )}
              </div>
            </PreventChange>
          );
        }}
      </Formik>
    </div>
  );
}

Gps.propTypes = {
  fetchCatalog: PropTypes.func,
  getAdditional: PropTypes.func,
  saveAdditional: PropTypes.func,
};

export default withAdditionalCollapse({ title: 'Equipo satelital' })(Gps);
