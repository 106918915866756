import * as yup from 'yup';
import i18n from '@i18n';
import {
  REMARK_TYPES,
  DELIVERY_RETURN_TYPES,
  ADDITIONAL_FIELDS,
  GROUPS,
} from '@reservations/commons/constants';
import {
  additionalFieldName,
  getAdditionalFieldsValue,
} from '@reservations/sections/DeliveryRefluxSteps/StepTwo/config';
import { validations, makeRemarkSchema } from './schema';

export const mapPropsToValues = ({
  additionalFields = {},
  selectedVerifyPoint = {},
  checkPointIsSet = false,
  remarkName,
}) => {
  const { uuid, infoSet = false } = selectedVerifyPoint;

  const cleanInitialValues = Object.keys(additionalFields).reduce(
    (acc, fieldKey) => {
      const name = additionalFieldName({
        fieldUuid: uuid,
        fieldKey,
      });

      acc[name] = '';

      return acc;
    },
    {}
  );

  const fields = Object.entries(additionalFields).map(([fieldKey, value]) => {
    const name = additionalFieldName({
      fieldUuid: uuid,
      fieldKey,
    });
    return { name, fieldKey, value };
  });

  const initialValues = fields.reduce(
    (acc, { name, value }) => {
      if (name) {
        const isKm = name.includes(ADDITIONAL_FIELDS.KM);
        const isGas = name.includes(ADDITIONAL_FIELDS.GAS);
        const setOriginalValue = checkPointIsSet || infoSet || isKm || isGas;
        const valueToSet = Number(value.value);

        acc[name] = setOriginalValue ? (valueToSet >= 0 ? valueToSet : '') : 1;
      }
      return acc;
    },
    { [remarkName]: '' }
  );

  return { ...cleanInitialValues, ...initialValues };
};

export const validationSchema = ({
  additionalFields = {},
  selectedVerifyPoint = {},
  checkPointIsSet,
  inRefund: isReturn,
  reservationInfo = {},
  remarkName,
}) => {
  const { infoSet = false, uuid = '', customRemark = {} } = selectedVerifyPoint;
  const km = reservationInfo?.assignedVehicle?.km;

  const fields = Object.entries(additionalFields).map(([fieldKey, value]) => {
    const name = additionalFieldName({
      fieldUuid: uuid,
      fieldKey,
    });
    return { name, fieldKey, value };
  });

  if (checkPointIsSet || infoSet) return yup.object().shape({});

  const deliveryAdditionalFields = getAdditionalFieldsValue({
    ...reservationInfo,
    type: DELIVERY_RETURN_TYPES.DELIVERY,
    groupPosition: GROUPS.TABLERO.position,
    checkPointPosition: GROUPS.TABLERO.verificationPoints.TABLERO.position,
  });

  const additionalFieldsToMap = { ...deliveryAdditionalFields };

  delete additionalFieldsToMap.gas;

  const isDetailRemark = customRemark.type === REMARK_TYPES.DETAIL;

  const schemaProps = fields.reduce((acc, { name, fieldKey }) => {
    const validationField = validations[fieldKey];

    if (!validationField?.schema) return acc;

    const deliveryField = additionalFieldsToMap[fieldKey];

    const minValue = deliveryField ? Number(deliveryField.value) : 0;

    let fieldSchema = validationField.schema;

    if (isReturn && minValue) {
      fieldSchema = fieldSchema.min(
        minValue,
        i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MIN', [minValue])
      );
    }

    if (!isReturn && minValue) {
      fieldSchema = fieldSchema.min(
        km,
        i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MIN', [km])
      );
    }

    acc[name] = fieldSchema;

    return acc;
  }, {});

  return yup.object().shape({
    ...schemaProps,
    ...makeRemarkSchema(remarkName, isDetailRemark),
  });
};
