import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import {
  Select,
  Button,
  Icons,
  DatePickerV2,
  FileInput,
} from '@casanova/casanova-common';
import { useSelector } from 'react-redux';
import { getPrevYear } from '@casanova/casanova-common/lib/utils/date';
import { MAXIMUM_DATE } from '@vehicles/common/constants';
import * as attachIcon from '@images/attach.png';

const PermissionForm = ({ action, onSaveEdit, onCancelEdit }) => {
  const permissions = useSelector(
    (state) => state.catalogs.permissionType.list
  );
  const { values = {} } = useFormikContext();

  return (
    <>
      <div className="section">
        <legend className="section__title">INFORMACIÓN DE PERMISOS</legend>
        {action === 'edit' && (
          <span className="section__icon">
            <Icons.Edit height="1.3rem" /> Editar
          </span>
        )}
      </div>
      <hr className="mt-0" width="100%" />
      <div className="row">
        <div className="col-md-4">
          <Select
            disabled={action === 'view'}
            label="Tipo de permiso"
            name="permissionType"
            options={permissions}
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={action !== 'add' ? values.processingDate : undefined}
            placeholder="Fecha de tramite"
            name="processingDate"
            label="Fecha de tramite"
            disabled={action === 'view'}
            calendarTop
            minimumDate={getPrevYear({ years: 11 })}
            maximumDate={MAXIMUM_DATE}
          />
        </div>
        <div className="col-md-4">
          <FileInput
            url={
              action !== 'add' &&
              values.permissionPDFUrl === values.initialInvoiceNumber
                ? values.pdfUrl
                : undefined
            }
            right={
              action !== 'add' &&
              values.permissionPDFUrl === values.initialInvoiceNumber ? (
                <Icons.Eye width="85%" height="85%" />
              ) : (
                <img src={attachIcon} alt="attach icon" />
              )
            }
            displayText={values.permissionPDF}
            type="file"
            label="Permiso pdf"
            placeholder="Permiso pdf"
            name="permissionPDF"
            accept="application/pdf"
            disabled={action === 'view'}
          />
        </div>
      </div>
      <br />

      {action === 'edit' && (
        <div className="row">
          <div className="offset-md-8 col-md-4 text-right">
            <div className="row">
              <div className="col-md-6">
                <Button onClick={onCancelEdit} outline block>
                  Cancelar
                </Button>
              </div>
              <div className="col-md-6">
                <Button type="submit" block onClick={onSaveEdit}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

PermissionForm.propTypes = {
  action: PropTypes.string,
  onSaveEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
};

PermissionForm.defaultProps = {
  action: 'add',
};

export default PermissionForm;
