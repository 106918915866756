import './SkeletonLoader.scss';

const SkeletonLoader = () => (
  <div className="loading">
    <div className="content">
      <div className="d-flex gap-5 justify-content-evenly">
        <h4></h4>
        <h4></h4>
        <h4></h4>
      </div>
      <div className="d-flex gap-5 justify-content-evenly my-5">
        <h4></h4>
        <h4></h4>
        <h4></h4>
      </div>
      <div className="d-flex gap-5 justify-content-evenly my-5">
        <h4></h4>
        <h4></h4>
        <h4></h4>
      </div>
      <div className="description"></div>
      <div className="description"></div>
      <div className="description"></div>
      <div className="description"></div>
    </div>
  </div>
);

export default SkeletonLoader;
