import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import Section from '@vehicles/VehicleEditor/additionals/Gps/Aditional/Section';
import Tab from './Tab';
import BoxAdd from '../BoxAdd';
import './index.scss';

const Tabs = ({ activeTab, setActiveTab, ...props }) => {
  const { values, setValues, setFieldValue } = useFormikContext(); // formikProps

  const exctractIndex = (data) =>
    values[props.name].map((e) => e.label).indexOf(data);

  const onClickTabItem = (tab) => {
    setFieldValue('tabSelected', exctractIndex(tab));
    setActiveTab(tab);
  };
  const addTAb = () => {
    const section = {
      label: 'GPS 1',
      content: <Section />,
      tagGPS: '',
      imeiGPS: '',
      remarks: '',
      installationPlace: '',
    };
    if (!values[props.name].some((e) => e.label === 'GPS 1')) {
      section.label = 'GPS 1';
      section.insertOrder = 1;
    }
    if (!values[props.name].some((e) => e.label === 'GPS 2')) {
      section.label = 'GPS 2';
      section.insertOrder = 2;
    }
    if (
      !values[props.name].some((e) => e.label === 'GPS 3') &&
      values[props.name].some((e) => e.label === 'GPS 2')
    ) {
      section.label = 'GPS 3';
      section.insertOrder = 3;
    }
    props.handleAddSection(section);
    values[props.name].push(section);
    setValues(values);
  };

  const { handleDeleteSection } = props;

  // Adjust size of tabs according to the number of tabs
  let width;

  if (values[props.name] && values[props.name].length <= 1) {
    width = 'w-60';
  } else if (values[props.name] && values[props.name].length <= 2) {
    width = 'w-70';
  } else {
    width = 'w-100';
  }

  const showAddTab = props.modeReinstall
    ? false
    : values[props.name] &&
      values[props.name].length < 3 &&
      values[props.name] &&
      values[props.name].length < values.available;

  return (
    <>
      <div className="tabs">
        <div className={`tabs-list ${width}`}>
          {values[props.name] &&
            values[props.name].map((child) => {
              const { label } = child;

              return (
                <Tab
                  editable={props.editable}
                  handleDeleteSection={handleDeleteSection}
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={onClickTabItem}
                  setActiveTab={setActiveTab}
                  delete={values}
                  name={props.name}
                  nameTab={props.nameTab}
                  deleteTabs={props.deleteTabs}
                />
              );
            })}
        </div>

        <div
          className="add-gps-tab"
          style={{ display: showAddTab ? 'flex' : 'none' }}
        >
          <p className="font-weight-bold mr-3">Agregar GPS</p>
          <BoxAdd fn={addTAb} classname="add-tab" />
        </div>
      </div>

      <div className="tab-content">
        {/** CONTENT HERE */}
        {props.children}
      </div>
    </>
  );
};

Tabs.propTypes = {
  children: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default Tabs;
