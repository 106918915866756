import _get from 'lodash/get';

export const countryEntranceTransformer = (countryEntranceTypeData = {}) => ({
  entranceType: _get(countryEntranceTypeData, 'entranceType.uuid', ''),
  entranceItinerary: _get(
    countryEntranceTypeData,
    'itineraryFile.originalName'
  ),
  entranceItineraryUrl: _get(
    countryEntranceTypeData,
    'itineraryFile.presignedUrl'
  ),
  entranceFFME: _get(countryEntranceTypeData, 'fmmeFile.originalName'),
  entranceFFMEUrl: _get(countryEntranceTypeData, 'fmmeFile.presignedUrl'),
  entranceFMM: _get(countryEntranceTypeData, 'fmmeFile.originalName'),
  entranceFMMUrl: _get(countryEntranceTypeData, 'fmmeFile.presignedUrl'),
  selectedEntrance: _get(countryEntranceTypeData, 'entranceType', {}),
});
