import { Icons, Modal } from '@casanova/casanova-common';
import CodeForm from './CodeForm';

const ModalForm = ({
  open,
  message,
  id,
  title,
  onClose,
  onAction,
  topContainerClassName = '',
  backdropClassName = '',
}) => {
  const header = (
    <div className="modal-header justify-content-center align-items-center">
      <div className="icon">
        <Icons.IcoUserAlert />
      </div>
      <h5 className="modal-title text-center" id={`${id}-label`}>
        Confirmación de {title}
      </h5>
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={onClose}
      header={header}
      className="action-modal"
      topContainerClassName={topContainerClassName}
      containerClassName="max-w-max"
      backdropClassName={backdropClassName}
      closeButton={true}
    >
      <div className="modal-body">
        <p className="text-center">
          Se necesita autorización de un {message} para continuar
        </p>
        <CodeForm onAction={onAction} onClose={onClose} />
      </div>
    </Modal>
  );
};

export default ModalForm;
