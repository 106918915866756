import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { withLayout } from 'hooks';
import {
  closeResponseDialog,
  getContractsPDF,
  rejectSignatures,
  tempAcceptSignatures,
  acceptSignatures,
  getReservationForm,
  finishExtendContract,
} from 'store/reservations/actions';
import { getSummaryInfo } from 'store/customers/actions';
import i18n from '@i18n';
import Leasing from './Leasing';
import { CHANGE_OF_VEHICLE_MAX_STEPS, MAX_STEPS } from './config';

const getMaxSteps = (isChangeVehicle) =>
  isChangeVehicle ? CHANGE_OF_VEHICLE_MAX_STEPS : MAX_STEPS;

const mapStateToProps = (state) => ({
  loader: _get(state, 'commons.loader', false),
  maxSteps: getMaxSteps(state.reservations.changeVehicle),

  isCompleted: _get(state, 'reservations.signatures.isCompleted', false),
  hasCreated: _get(state, 'reservations.contract.hasCreated', false),

  formUuid: _get(
    state,
    'reservations.contract.reservationContract.data.form.uuid',
    ''
  ),
  quoteValues: _get(state, 'reservations.quote.values', {}),
  reservationContract: state.reservations.contract.reservationContract.data,

  reservation: state.reservations.reservation,
  reservationInfo: state.reservations.reservationInfo.data,
});

const mapDispatchToProps = {
  getContractsPDF,
  closeResponseDialog,
  rejectSignatures,
  tempAcceptSignatures,
  acceptSignatures,
  getReservationForm,
  getSummaryInfo,
  finishExtendContract,
};

const withSignaturesProps = {
  step: 4,
  title: i18n('RESERVATIONS__LEASING_WITH_SIGNATURES__STEPPER__TITLE'),
  subtitle: i18n('RESERVATIONS__LEASING_WITH_SIGNATURES__STEPPER__SUBTITLE'),
};

const withoutSignaturesProps = (state) => ({
  accepted: _get(state, 'reservations.signatures.accepted', null),
  isSending: _get(state, 'reservations.signatures.isSending', false),
});

export const LeasingWithSignatures = withLayout(
  compose(
    connect(
      (state) => ({ ...mapStateToProps(state), ...withSignaturesProps }),
      mapDispatchToProps
    )
  )(Leasing)
);

export default withLayout(
  compose(
    connect(
      (state) => ({
        ...mapStateToProps(state),
        ...withoutSignaturesProps(state),
      }),
      mapDispatchToProps
    )
  )(Leasing)
);
