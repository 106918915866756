import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { fetchCatalog } from 'store/commons/actions';
import {
  closeResponseDialog,
  setCustomerType,
  setPreventChange,
  getDetail,
  setAvalPreventChange,
} from 'store/customers/actions';
import { iconsResponseDialog } from '@customers/common/config';
import AvalCreatorContainer from './AvalCreatorContainer';

export default compose(
  connect(
    (state) => ({
      ...iconsResponseDialog,
      responseDialog: _get(state, 'customers.responseDialog', {}),
      quoteId: _get(state, 'reservations.quote.values.quoteId', undefined),
      loader: state.commons.loader,
      customerType: _get(state, 'customers.customerType', ''),
      customerPersonType: _get(state, 'customers.customerPersonType', ''),
      avalCustomer: state.customers.avalCustomer,
      avalPreventChange: _get(state, 'customers.preventChange', true),
      avalCustomerByDetail: state.customers.avalCustomerByDetail,
    }),
    {
      fetchCatalog,
      closeResponseDialog,
      setCustomerType,
      setPreventChange,
      getDetail,
      setAvalPreventChange,
    }
  )
)(AvalCreatorContainer);
