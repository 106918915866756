import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import branch from 'recompose/branch';
import compose from 'recompose/compose';
import { validatePath } from './roles';
import { getToken } from '../services/auth';

function Redirect({ history, path = '/login' }) {
  useEffect(() => {
    history.push(path);
  }, [history, path]);
  return null;
}

function Redirect403({ history, path = '/vehicle' }) {
  useEffect(() => {
    history.push(path);
  }, [history, path]);
  return null;
}

Redirect.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

function RedirectHOC() {
  return Redirect;
}

function RedirectForbbiden() {
  return Redirect403;
}

export const withAuth = compose(
  connect((state) => {
    const { authToken, user } = state.auth;
    return {
      authToken,
      user,
    };
  }),
  branch(() => !getToken(), RedirectHOC)
);

export const withRoleRestrictions = compose(
  connect((state) => {
    const { authToken, user } = state.auth;
    return {
      authToken,
      user,
    };
  }),
  branch(
    (props) => !getToken() || !validatePath(props.history.location.pathname),
    RedirectForbbiden
  )
);
