import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import SimpleSearchInput from 'components/SimpleSearchInput';
import VERSIONS from 'utils/constants/versions';
import './AdditionalHeaderCamp.scss';

export default function AdditionalHeaderCamp(props) {
  const {
    searchKey,
    selectProps,
    dateProps,
    action,
    version = VERSIONS.V1,
    datePickerProps = {},
    formikSubmit,
    limitOfSearch = true,
    limitLength = 3,
    disabled = false,
    // staticValue,
  } = props;

  const dispatch = useDispatch();

  const { handleSubmit, setFieldValue } = useFormikContext();

  // useEffect(() => {
  //   if (!staticValue) return;
  //   setFieldValue(searchKey, staticValue);
  // }, [values[searchKey]]);

  const searchByParams = useCallback(
    // eslint-disable-next-line no-shadow
    ({ value = '', searchKey }) => {
      if (
        limitOfSearch &&
        (value?.length === 0 || value?.length >= limitLength)
      ) {
        const search = (searchParams) => {
          if (action) {
            dispatch(action(searchParams));
          } else if (handleSubmit) {
            handleSubmit();
          }
        };

        const searchParams = {
          [searchKey]: value,
        };
        search(searchParams);
      }
    },
    [dispatch, action, handleSubmit, limitOfSearch, limitLength]
  );

  return (
    <SimpleSearchInput
      {...props}
      className="AdditionalHeaderCamp mt-0 mb-3"
      onSubmit={(value) => {
        searchByParams({ value, searchKey });
      }}
      onChange={(evt) => {
        searchByParams({ value: evt.target.value, searchKey });
        setFieldValue(searchKey, evt.target.value);
      }}
      selectProps={selectProps}
      dateProps={dateProps}
      formikSubmit={formikSubmit}
      version={version}
      datePickerProps={datePickerProps}
      disabled={disabled}
    />
  );
}
