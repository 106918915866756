import { COLORS } from 'utils/colors';

export const columns = [
  {
    label: 'Id movimiento',
    field: 'movementId',
  },
  {
    label: 'Tipo de movimiento',
    field: 'movementType',
  },
  {
    label: 'Concepto',
    field: 'concept',
  },
  {
    label: 'Sucursal',
    field: 'branch',
  },
  {
    label: 'Cargo',
    field: 'charge',
    styles: [{ color: COLORS.NEGATIVE }],
  },
  {
    label: 'Abono',
    field: 'payment',
    styles: [{ color: COLORS.SUCCESS }],
  },
  {
    label: 'Fecha',
    field: 'date',
  },
];

export const MOCK_DATA = Array.from({ length: 50 }).map((_, index) => ({
  id: index,
  movementId: '0001',
  movementType: 'Verificada',
  concept: 'Sebastián',
  branch: 'COACALCO',
  charge: '50.00',
  payment: '100.100',
  date: '21-12-2021',
}));
