import {
  OFFICIAL_IDENTIFICATION_FIELDS,
  OFFICIAL_IDENTIFICATION_REQUIRED_UPDATE_FIELDS,
} from '@customers/sections/OfficialIdentification/constants';
import {
  DRIVER_LICENSE_FIELDS,
  DRIVER_LICENSE__REQUIRED_UPDATE_FIELDS,
} from '@customers/sections/DriverLicense/constants';
import { ADDRESS_REQUIRED_UPDATE_FIELDS } from '@customers/sections/Address/constants';
import driverLicenseConfig from '@customers/sections/DriverLicense/config';
import officialIdentificationConfig from '@customers/sections/OfficialIdentification/config';
import customerInformationConfig from '@customers/sections/CustomerInformation/config';
import passportInformationConfig from '@customers/sections/PassportInformation/config';
import { PASSPORT_REQUIRED_UPDATE_FIELDS } from '@customers/sections/PassportInformation/constants';
import visaInformationConfig from '@customers/sections/VisaInformation/config';
import { VISA_REQUIRED_UPDATE_FIELDS } from '@customers/sections/VisaInformation/constants';
import accommodationInformationConfig from '@customers/sections/AccommodationInformation/config';
import invoiceDataConfig from '@customers/sections/InvoiceData/config';
import addressConfig from '@customers/sections/Address/config';

export const CUSTOMER_TYPES = {
  FISIC: 'FISIC',
  JURIDICAL: 'JURIDICAL',
  FOREGINER: 'FOREGINER',
  FOREIGNER: 'FOREGINER',
  NATIONAL: 'NATIONAL',
  TENDER: 'TENDER',
};

export const TENDER_TYPES = {
  FISIC: 'FISIC',
  JURIDICAL: 'JURIDICAL',
};

export const VALUES_BY_CUSTOMER_TYPE = {
  [CUSTOMER_TYPES.FOREGINER]: {
    foreignCustomerInfo: {
      ...customerInformationConfig.initialValues,
    },
    createPassportReq: {
      ...passportInformationConfig.initialValues,
    },
    createVisaReq: {
      ...visaInformationConfig.initialValues,
    },
    createAccommodationReq: {
      ...accommodationInformationConfig.initialValues,
    },
    createDriverLicenseReq: {
      ...driverLicenseConfig.initialValues,
    },
    billingdetail: {
      ...invoiceDataConfig.initialValues,
    },
  },
};

export const CUSTOMER_DETAIL_KEYS = {
  INE: 'INE',
  PASSPORT: 'PASSPORT',
  PROFESSIONAL_LICENSE: 'PROFESSIONAL_LICENSE',
  INAPAM: 'INAPAM',
  VISA: 'VISA',
  DRIVER_LICENSE: 'driverLicense',
  ADDRESS: 'address',
};

export const CUSTOMER_FIELDS = {
  ...OFFICIAL_IDENTIFICATION_FIELDS,
  ...DRIVER_LICENSE_FIELDS,
};

const { IDENTITY_DOCUMENT, DRIVER_LICENSE, ADDRESS } = CUSTOMER_DETAIL_KEYS;

export const CONFIG_SECTIONS = {
  [CUSTOMER_DETAIL_KEYS.INE]: officialIdentificationConfig,
  [CUSTOMER_DETAIL_KEYS.PROFESSIONAL_LICENSE]: officialIdentificationConfig,
  [CUSTOMER_DETAIL_KEYS.INAPAM]: officialIdentificationConfig,
  [CUSTOMER_DETAIL_KEYS.PASSPORT]: officialIdentificationConfig,
  [CUSTOMER_DETAIL_KEYS.VISA]: officialIdentificationConfig,
  [DRIVER_LICENSE]: driverLicenseConfig,
  [ADDRESS]: addressConfig,
};

export const FOREIGN_CONFIG_SECTIONS = {
  [CUSTOMER_DETAIL_KEYS.PASSPORT]: passportInformationConfig,
  [CUSTOMER_DETAIL_KEYS.VISA]: visaInformationConfig,
  [DRIVER_LICENSE]: driverLicenseConfig,
};

export const VALIDITY_FIELDS = {
  [CUSTOMER_DETAIL_KEYS.INE]: 'validity',
  [CUSTOMER_DETAIL_KEYS.PROFESSIONAL_LICENSE]: 'validity',
  [CUSTOMER_DETAIL_KEYS.INAPAM]: 'validity',
  [CUSTOMER_DETAIL_KEYS.PASSPORT]: 'validity',
  [CUSTOMER_DETAIL_KEYS.VISA]: 'validity',
  [DRIVER_LICENSE]: 'validityDriverLicense',
  [ADDRESS]: 'period',
};

export const FOREIGN_VALIDITY_FIELDS = {
  ...VALIDITY_FIELDS,
  [CUSTOMER_DETAIL_KEYS.PASSPORT]: 'passportValidity',
  [CUSTOMER_DETAIL_KEYS.VISA]: 'visaValidity',
};

export const FIELDS_TO_UPDATE_VALUE = {
  [CUSTOMER_DETAIL_KEYS.INE]: OFFICIAL_IDENTIFICATION_REQUIRED_UPDATE_FIELDS,
  [CUSTOMER_DETAIL_KEYS.PROFESSIONAL_LICENSE]: OFFICIAL_IDENTIFICATION_REQUIRED_UPDATE_FIELDS,
  [CUSTOMER_DETAIL_KEYS.INAPAM]: OFFICIAL_IDENTIFICATION_REQUIRED_UPDATE_FIELDS,
  [CUSTOMER_DETAIL_KEYS.PASSPORT]: OFFICIAL_IDENTIFICATION_REQUIRED_UPDATE_FIELDS,
  [CUSTOMER_DETAIL_KEYS.VISA]: OFFICIAL_IDENTIFICATION_REQUIRED_UPDATE_FIELDS,
  [DRIVER_LICENSE]: DRIVER_LICENSE__REQUIRED_UPDATE_FIELDS,
  [ADDRESS]: ADDRESS_REQUIRED_UPDATE_FIELDS,
};

export const FOREIGN_FIELDS_TO_UPDATE_VALUE = {
  ...FIELDS_TO_UPDATE_VALUE,
  [CUSTOMER_DETAIL_KEYS.PASSPORT]: PASSPORT_REQUIRED_UPDATE_FIELDS,
  [CUSTOMER_DETAIL_KEYS.VISA]: VISA_REQUIRED_UPDATE_FIELDS,
};

const identificationFiles = {
  front: 'identificationFrontSide',
  back: 'identificationBackSide',
};

export const FILE_FIELDS = {
  [CUSTOMER_DETAIL_KEYS.INE]: identificationFiles,
  [CUSTOMER_DETAIL_KEYS.PROFESSIONAL_LICENSE]: identificationFiles,
  [CUSTOMER_DETAIL_KEYS.INAPAM]: identificationFiles,
  [CUSTOMER_DETAIL_KEYS.PASSPORT]: identificationFiles,
  [CUSTOMER_DETAIL_KEYS.VISA]: identificationFiles,
  [IDENTITY_DOCUMENT]: {
    front: 'identificationFrontSide',
    back: 'identificationBackSide',
  },
  [DRIVER_LICENSE]: {
    front: 'licenseFrontSide',
    back: 'licenseBackSide',
  },
  [ADDRESS]: {
    oneFile: 'addressDocument',
  },
};

export const FOREIGN_FILE_FIELDS = {
  ...FILE_FIELDS,
  [CUSTOMER_DETAIL_KEYS.PASSPORT]: {
    front: 'passportCoverFront',
    back: 'passportCoverBack',
  },
  [CUSTOMER_DETAIL_KEYS.VISA]: {
    front: 'visaCoverFront',
    back: 'visaCoverBack',
  },
};

export const CUSTOMER_STATUS = {
  BANNED: 'Boletinado',
  NORMAL: 'Normal',
};

export const FIELDS_THAT_USE_NATIONALITIES_CATALOG = [
  'originCountry',
  'passportCountry',
  'visaCountry',
];

export const TYPES_OF_DOCUMENTS = {
  INE: 'INE',
  PASSPORT: 'PASSPORT',
  PROFESSIONAL_LICENSE: 'PROFESSIONAL_LICENSE',
  INAPAM: 'INAPAM',
  VISA: 'VISA',
};

export const ENTRANCE_TYPES = {
  MARITIME: 'MARITIME',
  AERIAL: 'AERIAL',
};

export const VALID_DOCUMENTS_STATUS = {
  OPTIONAL_VERIFICATION: 'OPTIONAL_VERIFICATION',
  VERIFIED: 'VERIFIED',
};

export const NOT_VALID_DOCUMENTS_STATUS = {
  NOT_VERIFIED: 'NOT_VERIFIED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  NEEDS_VERIFICATION: 'NEEDS_VERIFICATION',
};

export const DOCUMENTS_STATUS = {
  ...VALID_DOCUMENTS_STATUS,
  ...NOT_VALID_DOCUMENTS_STATUS,
};

export const ACCOMMODATION_TYPES = {
  HOTEL: 'HOTEL',
  FAMILY_FRIEND: 'FAMILYFRIEND',
  RENT: 'RENT',
};

export const HAS_BIRTH_IN_FOREIGN = 'Nacido en el extranjero';

export const MOVEMENTS_TYPES = {
  PAYMENT: 'PAYMENT',
  CHARGE: 'CHARGE',
  BONUS: 'BONUS',
};

export const IS_NATURAL = 'natural-person';
export const IS_JURIDICAL = 'juridical-person';
