import _get from 'lodash/get';

export const accommodationInformationTransformer = (
  hospedyInformationData = {}
) => ({
  hospedyType: _get(hospedyInformationData, 'type.uuid', ''),
  hospedySelected: _get(hospedyInformationData, 'subtype', ''),
  subtype: _get(hospedyInformationData, 'subtype.uuid', ''),
  hospedyHotelName: _get(hospedyInformationData, 'hotel', ''),
  hospedyReservationNumber: _get(
    hospedyInformationData,
    'reservationNumber',
    ''
  ),
  hospedyConfirmation: _get(hospedyInformationData, 'reservationConfirmed'),
  hospedyContract: _get(hospedyInformationData, 'leaseContract.originalName'),
  hospedyContractUrl: _get(
    hospedyInformationData,
    'leaseContract.presignedUrl'
  ),
  hospedyIdentificationLessor: _get(
    hospedyInformationData,
    'leaseIdentityDocument.originalName'
  ),
  hospedyIdentificationLessorUrl: _get(
    hospedyInformationData,
    'leaseIdentityDocument.presignedUrl'
  ),
  hospedyRelationLabor: _get(
    hospedyInformationData,
    'employmentLetter.originalName'
  ),
  hospedyRelationLaborUrl: _get(
    hospedyInformationData,
    'employmentLetter.presignedUrl'
  ),
  endorsement: false,
  endorsementUuid: _get(hospedyInformationData, 'naturalPersonGuarantee', ''),
});
