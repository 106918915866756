import React, { useEffect } from 'react';
import { withForm } from '@casanova/casanova-common';
import { TransfersInformation } from '@transfers/sections';
import { config } from './config';

const CreateTransfer = ({ fetchCatalog }) => {
  useEffect(() => {
    fetchCatalog('states');
  }, [fetchCatalog]);

  return <TransfersInformation />;
};

CreateTransfer.propTypes = {};

export default withForm({ config })(CreateTransfer);
