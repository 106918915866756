import * as Yup from 'yup';
import i18n from '@i18n';
import { REQUIRED_MESSAGE } from 'utils/constants';
import { ADDITIONAL_FIELDS } from '@reservations/commons/constants';

export const validations = {
  [ADDITIONAL_FIELDS.GAS]: {
    min: 0,
    max: 8,
    schema: Yup.number()
      .required(REQUIRED_MESSAGE)
      .min(0, i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MIN', [0]))
      .max(8, i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MAX', [8])),
  },
  [ADDITIONAL_FIELDS.KM]: {
    min: 1,
    max: 999999,
    schema: Yup.number()
      .required(REQUIRED_MESSAGE)
      .min(1, i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MIN', [1]))
      .max(
        999999,
        i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MAX', [999999])
      ),
  },
};

export const makeRemarkSchema = (field, withFieldProps) => {
  if (!field) return {};
  const schemaProps = {
    [field]: withFieldProps
      ? Yup.string()
          .required(REQUIRED_MESSAGE)
          .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5]))
      : Yup.string(),
  };
  return schemaProps;
};
