import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import CountryEntranceType from './CountryEntranceType';

export default compose(
  connect((state) => ({
    countryEntranceTypeEntities: _get(
      state,
      'commons.countryEntranceTypeEntities.list',
      []
    ),
  }))
)(CountryEntranceType);
