import {
  RESERVATIONS_PATH,
  RESERVATIONS_QUOTE_PATH,
  RESERVATIONS_NOT_PRICE_QUOTE_PATH,
  RESERVATIONS_QUOTE_PLAN_PATH,
  RESERVATIONS_QUOTE_SUMMARY_PATH,
  RESERVATIONS_CUSTOMER_ADD_PATH,
  RESERVATIONS_CUSTOMER_DETAIL_PATH,
  RESERVATIONS_CUSTOMER_EDIT_PATH,
  RESERVATIONS_DETAIL_PATH,
  RESERVATIONS_PAYMENT_PATH,
  RESERVATIONS_EXTRA_CHARGES_PATH,
  RESERVATIONS_EXTRA_CHARGES_PAYMENT_PATH,
  RESERVATIONS_REFUND_PATH,
  RESERVATIONS_REFUND_VEHICLE_PARTS_PATH,
  RESERVATIONS_REFUND_INFORMATION_AND_FIRM_PATH,
  QUOTER_PATH,
  QUOTER_PLAN_PATH,
  QUOTER_SUMMARY_PATH,
  QUOTER_CUSTOMER_PATH,
  RETURNS_LIST_PATH,
} from 'utils/roles/paths';
import {
  RESERVATIONS_MODULE,
  QUOTER_MODULE,
  CREATE_INTERNAL_PRICE_QUOTE,
  CREATE_RESERVATION,
} from 'utils/roles/permissions/reservationsPermissions';

export function reservationsModulesAuth(permissions) {
  const allowedModules = [];

  const hasReservationsPermissions =
    permissions.filter((permission) => permission.includes('RESERVATION'))
      .length > 0;
  const hasPriceQuotePermissions =
    permissions.filter((permission) => permission.includes('PRICE_QUOTE'))
      .length > 0;

  if (hasReservationsPermissions) {
    allowedModules.push(RESERVATIONS_MODULE);
  }

  if (hasPriceQuotePermissions) {
    allowedModules.push(QUOTER_MODULE);
  }

  return allowedModules;
}

export function reservationsPathsAuth(permissions) {
  const allowedPaths = [];

  if (permissions.includes(CREATE_INTERNAL_PRICE_QUOTE)) {
    allowedPaths.push(RESERVATIONS_QUOTE_PATH);
    allowedPaths.push(RESERVATIONS_NOT_PRICE_QUOTE_PATH);
    allowedPaths.push(RESERVATIONS_QUOTE_PLAN_PATH);
    allowedPaths.push(RESERVATIONS_QUOTE_SUMMARY_PATH);

    allowedPaths.push(QUOTER_PATH);
    allowedPaths.push(QUOTER_PLAN_PATH);
    allowedPaths.push(QUOTER_SUMMARY_PATH);
    allowedPaths.push(QUOTER_CUSTOMER_PATH);
  }

  if (permissions.includes(CREATE_RESERVATION)) {
    allowedPaths.push(RESERVATIONS_PATH);

    allowedPaths.push(RESERVATIONS_CUSTOMER_ADD_PATH);
    allowedPaths.push(RESERVATIONS_CUSTOMER_DETAIL_PATH);
    allowedPaths.push(RESERVATIONS_CUSTOMER_EDIT_PATH);

    allowedPaths.push(RESERVATIONS_DETAIL_PATH);
    allowedPaths.push(RESERVATIONS_PAYMENT_PATH);
  }

  return allowedPaths;
}
