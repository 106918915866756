export const initialTouched = {
  personType: true,
  customerType: true,
  names: true,
  lastName: true,
  secondLastName: true,
  birthDate: true,
  birthPlace: true,
  originCountry: true,
  gender: true,
  curp: true,
  phoneNumber: true,
  homeNumber: true,
  email: true,
  ban: true,
};

export const initialImportantInitialTouched = {
  personType: true,
  customerType: true,
  names: true,
  lastName: true,
  secondLastName: true,
  phoneNumber: true,
  homeNumber: true,
  email: true,
};

export const initialValues = {
  personType: '',
  customerType: 'NATIONAL',
  names: '',
  lastName: '',
  secondLastName: '',
  birthDate: '',
  birthPlace: '',
  originCountry: '',
  gender: '',
  phoneNumber: '',
  homeNumber: '',
  email: '',
  curp: '',
  isUniqueEmail: true,
  isUniquePhone: true,
  ban: false,
};

export const initialImportantCustomerValues = {
  personType: '',
  customerType: 'NATIONAL',
  names: '',
  lastName: '',
  secondLastName: '',
  phoneNumber: '',
  homeNumber: '',
  email: '',
};

export default {
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
  initialTouched,
};
