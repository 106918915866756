import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import AccountStatement from './AccountStatement';

export default withLayout(
  compose(
    connect((state) => ({
      statementList: state.customers.statement.list,
      movementTypes: state.customers.statement.movementTypes,
    }))
  )(AccountStatement)
);
