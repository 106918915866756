import { MASSIVE_LOAD_TYPES } from '@vehicles/common/constants';

const labelsForVehicles = {
  1: {
    title: 'Carga de archivos',
    subtitle: 'Adjunta los archivos Excel con la información de los vehículos.',
  },
  2: {
    title: 'Valida los datos extraídos',
    subtitle: 'Asegúrate que la información de los vehículos sea correcta.',
  },
  3: {
    title: 'Valida los datos extraídos',
    subtitle: 'Asegúrate que la información de los vehículos sea correcta.',
  },
};

const labelsForSales = {
  1: {
    title: 'Carga de vehículos vendidos',
    subtitle:
      'Adjunta los archivos Excel con la información de los vehículos vendidos ',
  },
  2: {
    title: 'Valida los datos extraídos',
    subtitle:
      'Asegúrate que la información de los vehículos vendidos sea correcta.',
  },
};

export const LABELS_OF_MASSIVE_LOAD_TYPES = {
  [MASSIVE_LOAD_TYPES.VEHICLES]: (currentStep) =>
    labelsForVehicles[currentStep],
  [MASSIVE_LOAD_TYPES.VEHICLES_FOR_SALES]: (currentStep) =>
    labelsForSales[currentStep],
};
