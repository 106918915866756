import { transformIdOfList } from 'utils/transformHelpers';
import types from './action-types';

const initialState = {
  responseDialog: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
  },
  list: {
    params: {
      page: 0,
      size: 10,
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: [],
  },
  loanDetails: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
  editLoan: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
  closeLoan: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
  cancelLoan: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
  authorizeLoan: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
};

export function loansReducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.SET_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            ...payload,
            page: 0,
          },
        },
      };
    case types.SET_LIST_PAGE:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            page: payload,
          },
        },
      };
    case types.GET_LIST_FAILED:
    case types.GET_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          results: [],
        },
      };
    case types.GET_LIST_SUCCEED:
      return {
        ...state,
        list: {
          ...state.list,
          results: transformIdOfList(payload.content, 'uuid'),
          totalPages: payload.totalPages,
          totalElements: payload.totalElements,
        },
      };
    case types.SAVE_LOAN:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.SAVE_LOAN_SUCCEED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.SAVE_LOAN_FAILED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.CLOSE_RESPONSE_DIALOG:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: false,
        },
      };
    case types.GET_LOAN_DETAILS:
      return {
        ...state,
        loanDetails: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
      };
    case types.GET_LOAN_DETAILS_SUCCEED:
      return {
        ...state,
        loanDetails: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
      };
    case types.GET_LOAN_DETAILS_FAILED:
      return {
        ...state,
        loanDetails: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
      };
    case types.EDIT_LOAN:
      return {
        ...state,
        editLoan: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.EDIT_LOAN_SUCCEED:
      return {
        ...state,
        editLoan: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.EDIT_LOAN_FAILED:
      return {
        ...state,
        editLoan: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.CLOSE_LOAN:
      return {
        ...state,
        closeLoan: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.CLOSE_LOAN_SUCCEED:
      return {
        ...state,
        closeLoan: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.CLOSE_LOAN_FAILED:
      return {
        ...state,
        closeLoan: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.CANCEL_LOAN:
      return {
        ...state,
        cancelLoan: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.CANCEL_LOAN_SUCCEED:
      return {
        ...state,
        cancelLoan: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.CANCEL_LOAN_FAILED:
      return {
        ...state,
        cancelLoan: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.AUTHORIZE_LOAN:
      return {
        ...state,
        authorizeLoan: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.AUTHORIZE_LOAN_SUCCEED:
      return {
        ...state,
        authorizeLoan: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.AUTHORIZE_LOAN_FAILED:
      return {
        ...state,
        authorizeLoan: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}
