import _get from 'lodash/get';

export const visaFormatter = (values) => ({
  uuid: _get(values, 'uuid', ''),
  identifier: _get(values, 'identifier', ''),
  identityKey: _get(values, 'visaNumber', ''),
  issuedYear: _get(values, 'visaYearIssue', ''),
  validityYear: _get(values, 'visaValidity', ''),
  country: _get(values, 'visaCountry', ''),
});
