import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  vehicleVirtualContracts,
  setPageVehicleVirtualContracts,
} from 'store/vehicles/actions';
import VehicleVirtualContracts from './VehicleVirtualContracts';

export default compose(
  connect(
    (state) => ({
      responseDialog: state.vehicles.responseDialog,
      showTitle: false,
      virtualContracts: state.vehicles.virtualContracts,
      vehicleDetails: state.vehicles.vehicleDetails.data,
    }),
    { vehicleVirtualContracts, setPageVehicleVirtualContracts }
  )
)(VehicleVirtualContracts);
