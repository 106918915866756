import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { setPreventChange } from 'store/customers/actions';
import AccommodationInformation from './AccommodationInformation';

export default compose(
  connect(
    (state) => ({
      accommodationTypeEntities: _get(
        state,
        'commons.accommodationTypeEntities.list',
        []
      ),
      loader: state.commons.loader,
      avalCustomer: state.customers.avalCustomer,
      avalCustomerByDetail: state.customers.avalCustomerByDetail,
    }),
    { setPreventChange }
  )
)(AccommodationInformation);
