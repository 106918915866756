import * as Yup from 'yup';
import { REQUIRED_MESSAGE } from 'utils/constants';

import { saveStepOne } from 'store/reservations/actions';
import {
  reservationDatesProps,
  config as rdConfig,
  formatter,
  transformer,
} from '../../../sections/ReservationDates/config';

const schema = Yup.object().shape({
  customer_search: Yup.string().nullable().required(REQUIRED_MESSAGE),
  vehicleCategory: Yup.string().required(REQUIRED_MESSAGE),
  vehicleQuantity: Yup.number()
    .required(REQUIRED_MESSAGE)
    .min(1, 'La cantidad debe ser mayor que 0'),
  ...reservationDatesProps,
});

const passengersConfig = {
  schema,
  initialTouched: {
    customer: true,
    customer_search: true,
    vehicleCategory: true,
    vehicleQuantity: true,
    ...rdConfig.initialTouched,
  },
  initialValues: {
    customer: '',
    customer_search: '',
    vehicleQuantity: '1',
    vehicleCategory: undefined,
    ...rdConfig.initialValues,
  },
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  alwaysSetTemporalValues: true,
  withInitialValues: true,
  formatter,
  transformer,
  submitForm: saveStepOne,
  btnSubmitLabel: 'Continuar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE REGISTRO',
      message:
        'Una vez cargada la información del cliente tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a Clientes',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};

export default passengersConfig;
