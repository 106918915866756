import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik, yupToFormErrors, validateYupSchema } from 'formik';
import _get from 'lodash/get';
import {
  DatePickerV2,
  MaskedInput,
  PlainInput,
  FileInput,
  RadioGroupInput,
  TextAreaInput,
  NumberInput,
} from '@casanova/casanova-common';
import { getPrevYear } from '@casanova/casanova-common/lib/utils/date';
import { MAXIMUM_DATE } from '@vehicles/common/constants';
import * as validations from 'utils/validations/additionals';
import { plate as plateMeta } from 'services/vehicles/additionals';
import { CREATE_VEHICLE_PLATE } from 'utils/roles/permissions';
import UserInfo from 'components/UserInfo';
import AdditionalWrapper from '@additionals/components/AdditionalModals';

import { TEXT_AREA_NOT_ALLOWED_CHARACTERS } from 'utils/validations';
import PreventChange from 'components/PreventChange';
import { alphaMask } from 'utils/masks';
import { withAdditionalCollapse } from '../AdditionalCollapse';
import { SelectWithOptions } from '../components';

function AdditionalPlate(props) {
  const {
    addNew,
    editable,
    fetchCatalog,
    getAdditional,
    data,
    isNew,
    name,
    saveAdditional,
    vehicle,
    vehicleId,
    show,
  } = props;
  const formRef = useRef(null);
  useEffect(() => {
    if (!show && formRef) {
      formRef.current.resetForm(plateMeta.empty);
    }
  }, [show]);

  useEffect(() => {
    if (vehicleId) {
      getAdditional({ name, data: vehicleId, withFiles: ['receipt'] });
      fetchCatalog('service-type');
      fetchCatalog('country-state');
    }
  }, [name, fetchCatalog, vehicleId, getAdditional]);

  const handleFormik = useCallback(
    (values) => {
      const plateNumber = _get(data, 'plateNumber');
      saveAdditional({
        name,
        data: {
          ...values,
          vehicleId,
          serialNumber: vehicle.serialNumber,
          receipt: values.externalPlate ? undefined : values.receipt,
        },
        forceCreate: plateNumber && plateNumber !== values.plateNumber,
        addNew,
      });
    },
    [data, addNew, saveAdditional, name, vehicleId, vehicle]
  );

  const handleValidation = (values) => {
    try {
      const plateNumber = _get(data, 'plateNumber');
      validateYupSchema(values, validations.plate, true, {
        vehicle,
        isNewPlate: plateNumber && plateNumber !== values.plateNumber,
      });
    } catch (error) {
      return yupToFormErrors(error);
    }
    return {};
  };

  const listValues = [
    { value: false, label: 'Casanova' },
    { value: true, label: 'Cliente' },
  ];

  return (
    <div className="container-fluid">
      <Formik
        initialValues={addNew ? plateMeta.empty : data || plateMeta.empty}
        validate={handleValidation}
        validateOnChange
        enableReinitialize
        onSubmit={handleFormik}
        innerRef={formRef}
      >
        {(formikProps) => {
          const { initialValues, handleSubmit, values, dirty } = formikProps;

          return (
            <PreventChange preventChange={dirty && editable}>
              <AdditionalWrapper
                roleVerifierIdentifier={CREATE_VEHICLE_PLATE}
                handleSubmit={handleSubmit}
                formikProps={formikProps}
                emptyValues={plateMeta.empty}
                extraProps={props}
              >
                <div className="row">
                  <div className="col-12 switch-inverted">
                    <RadioGroupInput
                      labelClass="d-flex align-items-center"
                      groupClass="col-12 d-flex justify-content-between"
                      parentClass="vehicle-radio-input"
                      name="externalPlate"
                      options={listValues}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  {!isNew && !addNew && (
                    <div className="col-12 col-md-4">
                      <PlainInput
                        name="plateStatus"
                        disabled
                        label="Estatus"
                        limit={10}
                      />
                    </div>
                  )}
                  <div className="col-12 col-md-4">
                    <MaskedInput
                      name="plateNumber"
                      mask={alphaMask(10)}
                      maskPlaceholder=""
                      label="Numero de Placa*"
                      placeholder="Numero de Placa*"
                      limit={10}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="countryState"
                      label="Estado*"
                      options="catalogs.countryState"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <DatePickerV2
                      name="plateProceed"
                      label="Fecha de trámite*"
                      initialValue={values.plateProceed}
                      placeholder="Fecha de trámite*"
                      disabled={!editable && !isNew}
                      minimumDate={getPrevYear({ years: 11 })}
                      maximumDate={MAXIMUM_DATE}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="serviceType"
                      label="Tipo de Servicio*"
                      options="catalogs.serviceType"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  {!values.externalPlate && (
                    <div className="col-12 col-md-4">
                      <NumberInput
                        maskSuffix=" mxn"
                        decimals={2}
                        max={99_999}
                        name="price"
                        label="Importe de Pago*"
                        placeholder="Importe de Pago*"
                        disabled={!editable && !isNew}
                      />
                    </div>
                  )}
                  {!values.externalPlate && (
                    <div className="col-12 col-md-4">
                      <FileInput
                        url={!isNew ? initialValues.receiptUrl : undefined}
                        displayText={initialValues.initialReceipt}
                        type="file"
                        label="Comprobante de pago*"
                        placeholder="Comprobante de pago*"
                        name="receipt"
                        accept="application/pdf"
                        disabled={!editable && !isNew}
                      />
                    </div>
                  )}
                  <div className="col-12">
                    <TextAreaInput
                      label="Observaciones"
                      placeholder="Observaciones"
                      name="remarks"
                      disabled={!editable && !isNew}
                      regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS}
                      maxLength={200}
                    />
                  </div>
                </div>
                <UserInfo {...data} />
              </AdditionalWrapper>
            </PreventChange>
          );
        }}
      </Formik>
    </div>
  );
}

AdditionalPlate.propTypes = {
  fetchCatalog: PropTypes.func,
  getAdditional: PropTypes.func,
  saveAdditional: PropTypes.func,
};

AdditionalPlate.defaultProps = {};

export default withAdditionalCollapse({ title: 'Placa' })(AdditionalPlate);
