import * as Yup from 'yup';
import { pdfRule, xmlRule } from 'utils/validations';

const REQUIRED_MESSAGE = 'Este campo es obligatorio.';

const ADAPTED_STRING_RULE = {
  is: true,
  then: Yup.string().nullable().required(REQUIRED_MESSAGE),
  otherwise: Yup.string().nullable(),
};

const ADAPTED_COST_RULE = {
  is: true,
  then: Yup.string()
    .required(REQUIRED_MESSAGE)
    .nullable()
    .test(
      'adaptedCost',
      'El costo total de la adapación debe estar entre $ 10,000.00 y $ 999,999.99.',
      (adaptedCost) => {
        if (adaptedCost) {
          adaptedCost = adaptedCost.replace(/[^0-9.]+/g, '');
          return (
            parseFloat(adaptedCost) >= 10000 &&
            parseFloat(adaptedCost) <= 9999999
          );
        }
        return false;
      }
    ),
  otherwise: Yup.string().nullable(),
};

const ADAPTED_PDF_INVOICE_RULE = {
  is: true,
  then: Yup.mixed()
    .required(REQUIRED_MESSAGE)
    .test('fileFormat', 'El tipo de archivo debe ser pdf.', function (file) {
      const {
        adaptationInvoiceNumber,
        initialAdaptationInvoiceNumber,
        adaptationCost,
        initialAdaptationCost,
      } = this.parent;

      const noChanges =
        initialAdaptationCost &&
        adaptationCost &&
        initialAdaptationCost === adaptationCost.replace(/[^0-9.]+/g, '') &&
        initialAdaptationInvoiceNumber === adaptationInvoiceNumber;

      if (!file || noChanges) {
        return true;
      }

      return pdfRule(file);
    }),
  otherwise: Yup.mixed().nullable(),
};

const ADAPTED_XML_INVOICE_RULE = {
  is: true,
  then: Yup.mixed()
    .required(REQUIRED_MESSAGE)
    .test('fileFormat', 'El tipo de archivo debe ser xml.', function (file) {
      const {
        adaptationInvoiceNumber,
        initialAdaptationInvoiceNumber,
        adaptationCost,
        initialAdaptationCost,
      } = this.parent;

      const noChanges =
        initialAdaptationCost &&
        adaptationCost &&
        initialAdaptationCost === adaptationCost.replace(/[^0-9.]+/g, '') &&
        initialAdaptationInvoiceNumber === adaptationInvoiceNumber;

      if (!file || noChanges) {
        return true;
      }

      return xmlRule(file);
    }),
  otherwise: Yup.mixed().nullable(),
};

export const adaptationValidationProps = {
  originAdaptation: Yup.string().when('hasAdaptation', ADAPTED_STRING_RULE),
  adaptationCost: Yup.string().when('hasAdaptation', ADAPTED_COST_RULE),
  adaptationType: Yup.string().when('hasAdaptation', ADAPTED_STRING_RULE),
  adaptationInvoiceNumber: Yup.string().when(
    'hasAdaptation',
    ADAPTED_STRING_RULE
  ),
  adaptationInvoicePDF: Yup.mixed().when(
    'hasAdaptation',
    ADAPTED_PDF_INVOICE_RULE
  ),
  adaptationInvoiceXML: Yup.mixed().when(
    'hasAdaptation',
    ADAPTED_XML_INVOICE_RULE
  ),
};

export const AdaptationOnlySchema = Yup.object().shape({
  ...adaptationValidationProps,
});
