import { AdditionalHeaderCamp } from 'components';
import { CellText } from 'components/ColumnsCells';

export default [
  {
    label: 'Titular',
    field: 'name',
    align: 'center',
    renderer: (props) => <CellText {...props} />,
    // AdditionalHeaderCamp: () => (
    //   <AdditionalHeaderCamp name="name" searchKey="name" />
    // ),
  },
  {
    label: 'Número de tarjeta',
    field: 'number',
    align: 'center',
    renderer: (props) => <CellText {...props} />,
    // AdditionalHeaderCamp: () => (
    //   <AdditionalHeaderCamp name="number" searchKey="number" />
    // ),
  },
  {
    label: 'Tipo de tarjeta',
    field: 'type',
    align: 'center',
    renderer: (props) => <CellText {...props} />,
    // AdditionalHeaderCamp: () => (
    //   <AdditionalHeaderCamp name="type" searchKey="type" />
    // ),
  },
];
