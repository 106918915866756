import _get from 'lodash/get';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getSuburbsByZipCode } from 'store/commons/actions';
import { setNewInvoicePayment } from 'store/reservations/actions';
import InvoicePayment from './InvoicePayment';

export default compose(
  connect(
    (state) => {
      return {
        quote: _get(state, 'reservations.quote', {}),
        loader: _get(state, 'commons.loader', false),
        reservationId: _get(state, 'reservations.reservation.id', ''),
        invoiceElements: _get(
          state,
          'customers.customerDetail.data.billingDetails',
          []
        ),
        customerJuridical: _get(
          state,
          'customers.customerDetail.data.juridicalPerson'
        ),
        catalogsCfdiUsesFiscalRegime: _get(
          state,
          'commons.catalogsCfdiUsesFiscalRegime.list',
          []
        ),
        catalogsCfdiFiscalRegimeJuridicalPerson: _get(
          state,
          'commons.catalogsCfdiFiscalRegimeJuridicalPerson.list',
          []
        ),
        catalogsCfdiFiscalRegimeNaturalPerson: _get(
          state,
          'commons.catalogsCfdiFiscalRegimeNaturalPerson.list',
          []
        ),
        email: _get(state, 'customers.customerDetail.data.userInfo.email', ''),
        newInvoicePayment: _get(
          state,
          'reservations.reservation.newInvoicePayment',
          false
        ),
        zipCode: _get(state, 'commons.zipCodeSelected.state', []),
        confirmationMessage: false,
        catalogsCfdiPaymentMethod: _get(
          state,
          'commons.catalogsPaymentMethods',
          false
        ),
        catalogsCfdiPaymentFrom: _get(
          state,
          'commons.catalogsPaymentForms',
          false
        ),
      };
    },
    { setNewInvoicePayment, getSuburbsByZipCode }
  )
)(InvoicePayment);
