/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import i18n from '@i18n';
import { generateSelectYearsObject } from 'utils/date';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index.js';
import {
  CONFIG_SECTIONS as NATIONAL_CONFIG_SECTIONS,
  FOREIGN_CONFIG_SECTIONS,
  FIELDS_TO_UPDATE_VALUE as NATIONAL_FIELDS_TO_UPDATE_VALUE,
  FOREIGN_FIELDS_TO_UPDATE_VALUE,
  VALIDITY_FIELDS as NATIONAL_VALIDITY_FIELDS,
  FOREIGN_VALIDITY_FIELDS,
  FILE_FIELDS as NATIONAL_FILE_FIELDS,
  FOREIGN_FILE_FIELDS,
  CUSTOMER_TYPES,
  CUSTOMER_STATUS,
  FIELDS_THAT_USE_NATIONALITIES_CATALOG,
  NOT_VALID_DOCUMENTS_STATUS,
  HAS_BIRTH_IN_FOREIGN,
} from '@customers/common/constants';

export const getCustomerType = ({
  customerType = '',
  validationType = 'foreigner',
}) => {
  if (validationType === 'foreigner') {
    return customerType === CUSTOMER_TYPES.FOREGINER;
  }
  if (validationType === 'fisic') {
    return customerType === CUSTOMER_TYPES.FISIC;
  }

  if (validationType === 'tender')
    return customerType === CUSTOMER_TYPES.TENDER;

  return customerType === CUSTOMER_TYPES.JURIDICAL;
};

export const validityAndExpeditionDate = (params = {}) => {
  const { expeditionYear = 10, validityYear = 10 } = params;
  const currentYear = new Date().getFullYear();
  const expeditionYears = generateSelectYearsObject(
    currentYear,
    expeditionYear,
    true
  );
  const validityYears = generateSelectYearsObject(currentYear, validityYear);
  return { expeditionYears, validityYears };
};

export const isForeignCustomer = (customerData = {}) => {
  const { isForeign = false } = customerData;
  return isForeign;
};

export const isMoralCustomer = (customerData = {}) => {
  const { juridicalPerson = null } = customerData;
  return Boolean(juridicalPerson);
};

export const isJuridicalCustomer = (customerData = {}) => {
  const { juridicalPerson = null } = customerData;
  return Boolean(juridicalPerson);
};

export const isTenderCustomer = (customerData = {}) => {
  const { tender = null } = customerData;
  return Boolean(tender);
};

export const getCustomerSubtitle = (customerData = {}) => {
  if (isForeignCustomer(customerData))
    return i18n('CUSTOMERS__CUSTOMER_TYPE__FOREIGN');
  if (isJuridicalCustomer(customerData))
    return i18n('CUSTOMERS__CUSTOMER_TYPE__JURIDICAL');
  if (isTenderCustomer(customerData))
    return i18n('CUSTOMERS__CUSTOMER_TYPE__TENDER');
  return i18n('CUSTOMERS__CUSTOMER_TYPE__FISICAL');
};

export const isBanned = (customerStatus) => {
  if (customerStatus) {
    const status = _get(customerStatus, 'name', '');
    const ban = status.toLowerCase() === CUSTOMER_STATUS.BANNED.toLowerCase();
    return ban;
  }
  return false;
};

export const validateContactInfo = (availableUser, fieldKey) => {
  if (!availableUser || !availableUser.listOfFields) return true;
  if (
    !(
      availableUser &&
      availableUser.listOfFields &&
      availableUser.listOfFields[fieldKey]
    ) ||
    _isEmpty(availableUser.listOfFields[fieldKey].data) ||
    (!_isEmpty(availableUser.listOfFields[fieldKey].data) &&
      availableUser.listOfFields[fieldKey].data.success)
  )
    return true;

  return false;
};

export const getFieldsWithNationalitiesCatalog = (values) => {
  const fields = Object.entries(values).filter(([key]) =>
    FIELDS_THAT_USE_NATIONALITIES_CATALOG.find((field) => field === key)
  );
  return fields;
};

const DRIVER_LICENSE_IGNORE_VALIDATION_FIELDS = {
  emissionState: 'emissionState',
  expedition: 'expedition',
};

export const getFormikPropsForInvalidDocsVerification = ({
  listOfInvalidsDocuments,
  values,
  errors,
  schema,
}) => {
  const { personType } = values;

  const isJuridical = personType === CUSTOMER_TYPES.JURIDICAL;
  const isFisic = personType === CUSTOMER_TYPES.FISIC;
  const isForeign = personType === CUSTOMER_TYPES.FOREIGNER;
  const isNational = isFisic || isJuridical;

  const CONFIG_SECTIONS = isNational
    ? NATIONAL_CONFIG_SECTIONS
    : FOREIGN_CONFIG_SECTIONS;

  const FILE_FIELDS = isNational ? NATIONAL_FILE_FIELDS : FOREIGN_FILE_FIELDS;

  const VALIDITY_FIELDS = isNational
    ? NATIONAL_VALIDITY_FIELDS
    : FOREIGN_VALIDITY_FIELDS;

  const FIELDS_TO_UPDATE_VALUE = isNational
    ? NATIONAL_FIELDS_TO_UPDATE_VALUE
    : FOREIGN_FIELDS_TO_UPDATE_VALUE;

  const reduceListOfInvalidDocuments = (acc, invalidDocument) => {
    if (invalidDocument) {
      const { documentKey = '', documentType } = invalidDocument;

      const hasInvalidStatus = Object.values(NOT_VALID_DOCUMENTS_STATUS).some(
        (status) => status === documentType
      );
      const configSection = CONFIG_SECTIONS[documentKey];

      if (configSection && hasInvalidStatus) {
        const {
          initialValues = {},
          initialTouched: fieldsToTouch = {},
        } = configSection;

        const invalidValues = Object.keys(initialValues).filter((key) => {
          /*
            This field is for a driver's license, it does not enter into validation since it changes if the client is national or foreign
          */
          if (DRIVER_LICENSE_IGNORE_VALIDATION_FIELDS[key]) return false;

          if ((isJuridical || isForeign) && key === 'addressDocument')
            return false;
          return !values[key];
        });

        const withoutInvalidStatus = invalidValues.length === 0;

        const validityFields = VALIDITY_FIELDS[documentKey];
        const fileFields = FILE_FIELDS[documentKey] || {};
        const errorMessage = i18n(`ERROR__CUSTOMERS__DOCUMENT_${documentType}`);

        if (withoutInvalidStatus && validityFields) {
          const fieldToSetValidityError = validityFields;
          const { front = '', back = '', oneFile = null } = fileFields;

          // Docs values
          const docsValues = {};

          if (front) docsValues[front] = '';
          if (back) docsValues[back] = '';
          if (oneFile) docsValues[oneFile] = '';

          // Initial touched
          acc.fieldsToTouch = { ...acc.fieldsToTouch, ...fieldsToTouch };

          // Values
          acc.values = { ...acc.values, ...values, ...docsValues };

          // Errors
          const newFilesErrors = oneFile
            ? { [oneFile]: errorMessage }
            : { [front]: errorMessage, [back]: errorMessage };

          const newErrors = {
            ...errors,
            ...newFilesErrors,
            [fieldToSetValidityError]: errorMessage,
          };

          acc.errors = { ...acc.errors, ...newErrors };

          // Schema
          const filterSchema = (key) => {
            const fieldsToUpdateValue = Object.values(
              FIELDS_TO_UPDATE_VALUE[documentKey]
            );
            return fieldsToUpdateValue.find((field) => field === key);
          };

          const reduceSchema = (schemaAcc, key) => {
            const currentSchema = yup
              .string()
              .required(REQUIRED_MESSAGE)
              .test(
                'different-value',
                errorMessage,
                function differentValue(value) {
                  const hasDifferentValue = value !== values[key];
                  return (
                    hasDifferentValue ||
                    this.createError({
                      message: errorMessage,
                      path: key,
                    })
                  );
                }
              );

            schemaAcc[key] = currentSchema;

            return schemaAcc;
          };

          const schemaProps = !_isEmpty(initialValues)
            ? Object.keys(initialValues)
                .filter(filterSchema)
                .reduce(reduceSchema, {})
            : {};

          const newSchema = yup.object().shape(schemaProps);

          acc.schema = acc.schema
            ? acc.schema.concat(newSchema)
            : schema.concat(newSchema);
        }
      }
    }

    return acc;
  };

  const validFieldsOfInvalidDocuments = listOfInvalidsDocuments.reduce(
    reduceListOfInvalidDocuments,
    {
      fieldsToTouch: {},
      values,
      errors,
    }
  );

  return validFieldsOfInvalidDocuments;
};

export const hasBirthInForeign = (customerData) => {
  const customerBirthInForeign =
    _get(customerData, 'birthState.name', '') === HAS_BIRTH_IN_FOREIGN;

  return customerBirthInForeign;
};

export const isPermanentLicense = (validityYear) =>
  moment(validityYear).isAfter(moment().add(10, 'years'));
