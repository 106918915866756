import SignatureImage from '@images/signature.png';
import {
  Page,
  Image,
  Text,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
  },
  title: {
    backgroundColor: '#c0c0c0',
    fontSize: 20,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 25,
    paddingRight: 25,
    fontStyle: 'bold',
  },
  expedition: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  expedition_text: {
    fontSize: 13,
  },
  expedition_text_under: {
    fontSize: 13,
    textDecoration: 'underline',
    marginRight: 5,
    marginLeft: 5,
  },
  date_box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    fontSize: 10,
    borderTop: '1px solid #000',
    borderBottom: '1px solid #000',
    borderRight: '1px solid #000',
    marginRight: 6,
    marginLeft: 6,
  },
  date_box_text: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    fontSize: 10,
  },
  date_text_box: {
    paddingBottom: 5,
    paddingTop: 5,
    paddingLeft: 20,
    paddingRight: 20,
    borderLeft: '1px solid #000',
  },
  spacing_date_box: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
  },
  price_box: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 5,
  },
  price: {
    paddingBottom: 5,
    paddingTop: 5,
    paddingLeft: 40,
    paddingRight: 40,
    border: '1px solid #000',
  },
  section: {
    width: '97.5%',
    margin: 10,
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  grey_box: {
    backgroundColor: '#c0c0c0',
    marginTop: 14,
    marginLeft: 20,
    marginRight: 20,
    padding: 5,
  },
  pb_15_s_13: {
    fontSize: 13,
    paddingBottom: 15,
  },
  s_13: {
    fontSize: 13,
  },
  image_container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  image_width: {
    width: '90%',
    height: 250,
  },
});

// Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>PAGARE ÚNICO (1 de 1)</Text>
        <Text style={styles.title}>Rd-04</Text>
      </View>
      <View style={styles.expedition}>
        <Text style={styles.expedition_text}>Lugar de Expedición:</Text>
        <Text style={styles.expedition_text_under}>
          &nbsp;&nbsp;&nbsp;Ciudad de México&nbsp;&nbsp;&nbsp;
        </Text>
        <Text style={styles.expedition_text}>Fecha: </Text>
        <View>
          <View style={styles.date_box}>
            <Text style={styles.date_text_box}>04</Text>
            <Text style={styles.date_text_box}>05</Text>
            <Text style={styles.date_text_box}>2023</Text>
          </View>
          <View style={styles.date_box_text}>
            <Text style={styles.spacing_date_box}>Día</Text>
            <Text style={styles.spacing_date_box}>Mes</Text>
            <Text style={styles.spacing_date_box}>Año</Text>
          </View>
        </View>
        <View style={styles.price_box}>
          <Text style={styles.expedition_text}>
            Bueno por el importe de: &nbsp;$
          </Text>
          <Text style={styles.price}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Text>
        </View>
      </View>
      <View style={styles.grey_box}>
        <Text style={styles.pb_15_s_13}>
          Debo(emos) y pagaré(mos) incondicionalmente este pagaré en el domicilio ubicado en (Av. Patriotismo, No. 730, Col. San Juan,
          Del. Benito Juárez, CDMX, C.P.03730. CDMX) o en el lugar donde elija el tenedor a la orden de: Casanova Rent Volks, S.A. de C.V.,
          C.V., pagadero a la vista. La cantidad de: $<br />
        </Text>
      </View>
      <View style={styles.grey_box}>
        <Text style={styles.pb_15_s_13}>
          Valor recibido a mi (nuestra) entera satisfacción, este pagaré es único y está sujeto a la condición de pagarse al día de su
          excibición y hasta el día de su liquidación causará intereses moratorios del 3% (tres por ciento) mensual, por cada mes transcurrido
          o fracción pagaderos juntamente con el principal conforme a lo dispuesto por el artículo 174 de la Ley General de Títulos y
          Operaciones de Crédito más el Impuesto al Valor Agregado correspondiente.
        </Text>
        <Text style={styles.s_13}>
          El presente pagaré reúne todos y cada uno de los requisitos señalados en el articulo 170 de la Ley General de Títulos y Operaciones
          de Crédito.
        </Text>
      </View>
      <View style={styles.image_container}>
        <Image style={styles.image_width} src={SignatureImage} />
      </View>
    </Page>
  </Document>
);

export default MyDocument;
