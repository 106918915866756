import types from './action-types';

export function getTransfers(payload) {
  return { type: types.GET_LIST, payload };
}

export function getTransfersSucceed(payload) {
  return { type: types.GET_LIST_SUCCEED, payload };
}

export function getTransfersFailed(payload) {
  return { type: types.GET_LIST_FAILED, payload };
}

export function closeResponseDialog() {
  return { type: types.CLOSE_RESPONSE_DIALOG };
}

export function saveTransfer(payload) {
  return { type: types.SAVE_TRANSFER, payload };
}

export function saveTransferSucceed(payload) {
  return { type: types.SAVE_TRANSFER_SUCCEED, payload };
}

export function saveTransferFailed(payload) {
  return { type: types.SAVE_TRANSFER_FAILED, payload };
}

export function getTransferDetail(payload) {
  return { type: types.GET_TRANSFER_DETAIL, payload };
}

export function getTransferDetailSucceed(payload) {
  return { type: types.GET_TRANSFER_DETAIL_SUCCEED, payload };
}

export function getTransferDetailFailed(payload) {
  return { type: types.GET_TRANSFER_DETAIL_FAILED, payload };
}

export function authorizeTransfer(payload) {
  return { type: types.AUTHORIZE_TRANSFER, payload };
}

export function authorizeTransferSucceed(payload) {
  return { type: types.AUTHORIZE_TRANSFER_SUCCEED, payload };
}

export function authorizeTransferFailed(payload) {
  return { type: types.AUTHORIZE_TRANSFER_FAILED, payload };
}

export function cancelTransfer(payload) {
  return { type: types.CANCEL_TRANSFER, payload };
}

export function cancelTransferSucceed(payload) {
  return { type: types.CANCEL_TRANSFER_SUCCEED, payload };
}

export function cancelTransferFailed(payload) {
  return { type: types.CANCEL_TRANSFER_FAILED, payload };
}

export function closeTransfer(payload) {
  return { type: types.CLOSE_TRANSFER, payload };
}

export function closeTransferSucceed(payload) {
  return { type: types.CLOSE_TRANSFER_SUCCEED, payload };
}

export function closeTransferFailed(payload) {
  return { type: types.CLOSE_TRANSFER_FAILED, payload };
}

export function rejectTransfer(payload) {
  return { type: types.REJECT_TRANSFER, payload };
}

export function rejectTransferSucceed(payload) {
  return { type: types.REJECT_TRANSFER_SUCCEED, payload };
}

export function rejectTransferFailed(payload) {
  return { type: types.REJECT_TRANSFER_FAILED, payload };
}

export function reset() {
  return { type: types.RESET };
}

export function setTableParams(payload) {
  return { type: types.SET_TABLE_PARAMS, payload };
}

export function setTablePage(payload) {
  return { type: types.SET_TABLE_PAGE, payload };
}

export function editTransfer(payload) {
  return { type: types.EDIT_TRANSFER, payload };
}

export function editTransferSucceed(payload) {
  return { type: types.EDIT_TRANSFER_SUCCEED, payload };
}

export function editTransferFailed(payload) {
  return { type: types.EDIT_TRANSFER_FAILED, payload };
}
