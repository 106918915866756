import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import _get from 'lodash/get';
import types from './actions-types';
import { names } from './actions';
import { getOptionsAuth, logout } from '../../services/auth';

const initialState = {
  user: _get(getOptionsAuth(), 'user', null),
  authToken: _get(getOptionsAuth(), 'authToken', null),
  loading: false,
  hydratedData: {
    success: false,
    error: false,
    errorMessage: '',
  },
};

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case names.login[0]: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case names.login[1]: {
      return {
        ...state,
        loading: false,
        ...payload,
      };
    }

    case names.login[2]: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case names.login[3]: {
      return {
        ...state,
        error: null,
      };
    }
    case types.HYDRATE_USER_DATA:
      return {
        ...state,
        hydratedData: initialState.hydratedData,
      };
    case types.HYDRATE_USER_DATA_SUCCESS:
      return {
        ...state,
        hydratedData: {
          ...initialState.hydratedData,
          success: true,
        },
      };
    case types.HYDRATE_USER_DATA_FALSE:
      return {
        ...state,
        hydratedData: {
          ...initialState.hydratedData,
          error: true,
          errorMessage: payload,
        },
      };
    case names.logout: {
      logout();
      return {
        ...state,
        user: null,
        authToken: null,
      };
    }
    default: {
      return state;
    }
  }
}

export default persistReducer(
  {
    key: 'auth',
    whitelist: ['user', 'authToken'],
    storage,
  },
  reducer
);
