import {
  VIEW_VEHICLE_KEYS,
  CREATE_VEHICLE_KEYS,
} from 'utils/roles/permissions';
import Keys from './Keys';
import { withConnectedAdditional } from '../AdditionalCollapse';

export default withConnectedAdditional({
  dependsOnElement: 'keys',
  identifierForView: VIEW_VEHICLE_KEYS,
  identifierForAdd: CREATE_VEHICLE_KEYS,
})(Keys);
