import {
  WORKSHOP_MODULE,
  WORKSHOP,
  VIEW_WORKSHOP,
} from 'utils/roles/permissions/workshopPermissions';
import { WORKSHOP_LIST_PATH } from 'utils/roles/paths';

export function workshopAuth(permissions) {
  const allowedModules = [];

  const workshopModule = permissions.some((permission) =>
    permission.includes(WORKSHOP)
  );

  if (workshopModule) {
    allowedModules.push(WORKSHOP_MODULE);
  }

  return allowedModules;
}

export function workshopPathsAuth(permissions) {
  const allowedPaths = [];

  const viewWorkshopPermissions = permissions.filter((permission) =>
    permission.startsWith(VIEW_WORKSHOP)
  );

  if (viewWorkshopPermissions.length > 0) allowedPaths.push(WORKSHOP_LIST_PATH);

  return allowedPaths;
}
