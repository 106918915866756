import React from 'react';
import { Button, Loader } from '@casanova/casanova-common';
import Modal from '@casanova/casanova-common/lib/Modal';
import PropTypes from 'prop-types';
import i18n from '@i18n';

const FormModal = ({
  id,
  onCancel,
  onSubmit,
  open = true,
  title = '',
  description = '',
  cancelLabel = i18n('COMMONS__CANCEL__TEXT'),
  submitLabel = i18n('COMMONS__ACCEPT__TEXT'),
  showLoader = false,
  children,
  onlySubmit = false,
}) => {
  const header = (
    <div className="modal-header justify-content-center align-items-center pt-3">
      <h5 className="modal-title text-center" id={`${id}-label`}>
        {title}
      </h5>
    </div>
  );

  const footer = (
    <div className="modal-footer container">
      <div className="row mt-4">
        {onlySubmit ? (
          <div className="col-md-8 m-auto">
            <Button block onClick={onSubmit}>
              {submitLabel}
            </Button>
          </div>
        ) : (
          <>
            <div className="col-md-6">
              <Button block outline onClick={onCancel}>
                {cancelLabel}
              </Button>
            </div>
            <div className="col-md-6">
              <Button block onClick={onSubmit}>
                {submitLabel}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      header={header}
      containerClassName="modal-lg"
      footer={footer}
      className="action-modal"
    >
      <Loader show={showLoader} />
      <div className="modal-body">
        <div className="text-center my-3 text-lg">{description}</div>
        <>{children}</>
      </div>
    </Modal>
  );
};

FormModal.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  showLoader: PropTypes.bool,
  children: PropTypes.node,
  onlySubmit: PropTypes.bool,
};

export default FormModal;
