import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import {
  Button,
  Section,
  DatePickerV2 as DatePicker,
  MaskedInput as Input,
  ConnectedSelect,
  NumberInput,
  Select,
} from '@casanova/casanova-common';
import { QUOTE_CONTRACT_QUERY } from 'utils/navigation';
import { SearchableSelect } from 'components';
import i18n from '@i18n';
import { isSafari } from 'utils/browser';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { lettersAndNumbersWithSpaces } from 'utils/masks';
import { fieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import {
  formattedDateToPickerV2,
  getNextYear,
  timeZone,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/date';
import { AMOUNT_MAX } from './schema';
import _get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ContractInformation({
  action = FORM_ACTIONS.ADD,
  searchTenderCustomer,
  fetchCatalog,
}) {
  const {
    initialValues,
    setFieldTouched,
    setFieldValue,
    values,
  } = useFormikContext();

  const { startDate, customer } = values;
  const { VIEW, EDIT } = FORM_ACTIONS;
  const [permitedBranches, setPermitedBranches] = useState([]);

  const branches = useSelector((state) =>
    _get(state, 'commons.branches.list', [])
  );

  const handleSelectCustomer = useCallback(
    (plate) => {
      setFieldTouched('customer', true);
      setFieldValue('customer', plate);
    },
    [setFieldValue, setFieldTouched]
  );

  const handleSearchTenderCustomer = useCallback(
    (query) => {
      if (query && query.length > 2) {
        setFieldValue('customer', '');
        searchTenderCustomer(query);
      }
    },
    [setFieldValue, searchTenderCustomer]
  );

  const handleBlurCustomer = useCallback(() => {
    setFieldTouched(fieldSearch('customer'), true);
    setFieldTouched('customer', true);
  }, [setFieldTouched]);

  useEffect(() => {
    fetchCatalog({
      catalogPath: 'pricequotes',
      catalogId: 'catalogs/business-areas',
    });
  }, [fetchCatalog]);

  const startDateMaximumDate = formattedDateToPickerV2(
    moment().add(3, 'months').tz(timeZone).format(YYYY_MM_DD)
  );

  const endDateMinimumDate = useMemo(
    () =>
      startDate &&
      formattedDateToPickerV2(
        moment(startDate).add(2, 'day').tz(timeZone).format(YYYY_MM_DD)
      ),
    [startDate]
  );

  const history = useHistory();

  const handleAddCustomer = useCallback(() => {
    setTimeout(() => {
      history.push(`/contracts/customer/add`);
    }, 1);
  }, [history]);

  useEffect(() => {
    if (branches <= 0) return;
    setPermitedBranches(
      branches.filter(
        (b) =>
          b.uuid !== '7911c109-0f58-4e3e-9334-b031cbd2ecf9' &&
          b.uuid !== 'b399666e-a92c-4a43-9448-0baffcfdfe0e' &&
          b.uuid !== '74908e93-4c32-4298-b049-565a2a243b10' &&
          b.uuid !== '98aec74c-a4d0-4394-ab99-fd710a383c6b' &&
          b.uuid !== 'f7ef995f-13c0-4b04-a3cf-0f0fe0df81e9' &&
          b.uuid !== 'e99f2bd9-a7ae-4adf-800d-364f3bbd359d' &&
          b.uuid !== 'b89506be-d5ef-41c0-a350-e41dd98336ac' &&
          b.uuid !== '561c5595-e904-46b4-bea0-bcacaf62431f'
      )
    );
  }, [branches]);

  useEffect(() => {
    console.log("aplica");
    console.log(action);
  }, [useFormikContext])
  const InputView = () => (
    <>
      <div className="col-md-4">
        <Input
          placeholder="RFC"
          name="rfc"
          label="RFC"
          disabled={disabledInput}
        />
      </div>
      <div className="col-md-4">
        <Input
          placeholder="nombre del contacto"
          name="naturalName"
          label="nombre del contacto"
          disabled={disabledInput}
        />
      </div>
      <div className="col-md-4">
        <Input
          placeholder="Folio"
          name="folio"
          label="Folio"
          disabled={disabledInput}
        />
      </div>
    </>
  );

  const disabledInput = action === FORM_ACTIONS.VIEW;
  return (
    <Section title={i18n('CONTRACTS__CONTRACT_DETAIL__TITLE')} upperCase>
      <div className="row">
        <div className="col-md-4">
          <DatePicker
            initialValue={initialValues.startDate}
            placeholder={i18n('CONTRACTS__CONTRACT_DETAIL__START_DATE', ['*'])}
            name="startDate"
            label={i18n('CONTRACTS__CONTRACT_DETAIL__START_DATE', ['*'])}
            disabled={disabledInput}
            placement={isSafari() ? 'bottom' : undefined}
            maximumDate={startDateMaximumDate}
          />
        </div>
        <div className="col-md-4">
          <DatePicker
            initialValue={initialValues.endDate}
            placeholder={i18n('CONTRACTS__CONTRACT_DETAIL__END_DATE', ['*'])}
            name="endDate"
            label={i18n('CONTRACTS__CONTRACT_DETAIL__END_DATE', ['*'])}
            disabled={disabledInput}
            placement={isSafari() ? 'bottom' : undefined}
            minimumDate={endDateMinimumDate}
            maximumDate={getNextYear({ years: 5 })}
          />
        </div>
        <div className="col-md-4">
          <SearchableSelect
            onChange={handleSelectCustomer}
            onBlur={handleBlurCustomer}
            name="customer"
            label={i18n('CONTRACTS__CONTRACT_DETAIL__CUSTOMER', ['*'])}
            placeholder={i18n('CONTRACTS__CONTRACT_DETAIL__CUSTOMER', ['*'])}
            options="customers.tenderCustomers"
            mask={lettersAndNumbersWithSpaces(100)}
            onSearching={handleSearchTenderCustomer}
            icon="svg-icon-search"
            disabled={disabledInput}
            infoText={
              action !== FORM_ACTIONS.VIEW
                ? 'La búsqueda de cliente es por razón social o RFC.'
                : undefined
            }
            remove={!disabledInput}
            showRightIcon={!customer}
          />
        </div>
        {/* <div className="col-md-4">
          <NumberInput
            placeholder={i18n('CONTRACTS__CONTRACT_DETAIL__AMOUNT', ['*'])}
            name="amount"
            label={i18n('CONTRACTS__CONTRACT_DETAIL__AMOUNT', ['*'])}
            disabled={disabledInput}
            decimals={2}
            max={AMOUNT_MAX}
            min={1}
          />
        </div> */}
        <div className="col-md-4">
          <ConnectedSelect
            placeholder={i18n('CONTRACTS__CONTRACT_DETAIL__BUSINESS_UNIT', [
              '*',
            ])}
            name="businessArea"
            label={i18n('CONTRACTS__CONTRACT_DETAIL__BUSINESS_UNIT', ['*'])}
            disabled={disabledInput}
            options="commons.catalogsBusinessAreas"
          />
        </div>
        <div className="col-md-4">
          <Input
            placeholder="No. De Contrato *"
            name="noContrato"
            label="No. De Contrato *"
            disabled={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <NumberInput
            placeholder="Monto mínimo *"
            name="minAmount"
            label="Monto mínimo *"
            disabled={disabledInput}
            decimals={2}
            max={AMOUNT_MAX}
            min={1}
          />
        </div>
        <div className="col-md-4">
          <NumberInput
            placeholder="Máximo de operación *"
            name="maxAmount"
            label="Máximo de operación *"
            disabled={disabledInput}
            decimals={2}
            max={AMOUNT_MAX}
            min={1}
          />
        </div>
        <div className="col-md-4">
          <Select
            disabled={action === VIEW}
            label="Sucursal *"
            name="branch"
            options={permitedBranches}
            selectProps={{ fullKey: true, availableFirstValue: true }}
            // onChange={handleChangeDateStart}
          />
        </div>
        {action === 'view' ? <InputView /> : ''}
        <div className="col-md-4">
          {action !== 'view' ? (
            <Button
              className="px-5"
              style={{ marginTop: '2rem' }}
              onClick={handleAddCustomer}
            >
              Nuevo Cliente
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
    </Section>
  );
}
