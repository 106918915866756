import { call, put } from 'redux-saga/effects';
import { showLoader, hideLoader } from 'store/commons/actions';
import {
  getContractsFiltersService,
  getListOfContractsService,
  postContract,
  getContractDetails,
  postChangeOfVehicle,
  postCreateLoan,
} from 'services/contracts';
import {
  getContractsSucceed,
  getContractsFailed,
  getContractsFiltersSucceed,
  getContractsFiltersFailed,
  saveContractSucceed,
  saveContractFailed,
  getContractDetailsSucceed,
  getContractDetailsFailed,
  changeOfVehicleSucceed,
  changeOfVehicleFailed,
  createLoanSucceed,
  createLoanFailed,
} from './actions';

export function* getListOfContractsSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getListOfContractsService, action.payload);
    yield put(getContractsSucceed(data));
  } catch (error) {
    yield put(getContractsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getContractsFiltersSaga() {
  try {
    yield put(showLoader());
    const data = yield call(getContractsFiltersService);
    yield put(getContractsFiltersSucceed(data));
  } catch (error) {
    console.log(error);
    yield put(getContractsFiltersFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveContractSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(postContract, action.payload);
    yield put(saveContractSucceed(data));
  } catch (error) {
    yield put(saveContractFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getContractDetailsSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getContractDetails, action.payload);
    yield put(getContractDetailsSucceed(data));
  } catch (error) {
    yield put(getContractDetailsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* changeOfVehicleSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(postChangeOfVehicle, action.payload);
    const contractDetailsData = yield call(getContractDetails, {
      uuid: action.payload.uuid,
    });
    yield put(getContractDetailsSucceed(contractDetailsData));
    yield put(changeOfVehicleSucceed(data));
  } catch (error) {
    yield put(changeOfVehicleFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* createLoanSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(postCreateLoan, action.payload);
    yield put(createLoanSucceed(data));
  } catch (error) {
    yield put(createLoanFailed(error));
  } finally {
    yield put(hideLoader());
  }
}
