import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import {
  MaskedInput as Input,
  FileInput,
  DatePickerV2,
  ConnectedSelect,
  Section,
  SmallCheckbox as Checkbox,
  Icons,
} from '@casanova/casanova-common';
import { alphaMask } from '@casanova/casanova-common/lib/utils/masks';
import { acceptFiles } from 'utils/files';
import { EMISSION_AND_VALIDITY_VALIDATIONS } from '@customers/common/validations';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { PERMANENT_VALIDITY } from './constants';
import config from './config';
import { initialValues as driverLivenseInitialValues } from './config';

const { EMISSION, VALIDITY, ANTIQUITY } = EMISSION_AND_VALIDITY_VALIDATIONS;

export default function DriverLicense({
  action = FORM_ACTIONS.VIEW,
  isForeigner,
}) {
  const [hasChangedPermanent, setHasChangedPermanent] = useState(false);
  const {
    values,
    initialValues,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const { isPermanentLicense = false } = values;

  const handleOnChangeLicenseType = (event) => {
    setValues({
      ...values,
      ...config.initialValues,
      licenseType: event.target.value,
    });
  };

  const handlePermanentLicenseChange = useCallback(
    (permanentLicense) => {
      setHasChangedPermanent(true);
      if (permanentLicense) {
        setFieldValue('validityDriverLicense', PERMANENT_VALIDITY);
      } else {
        setFieldValue('validityDriverLicense', '');
      }

      setTimeout(() => {
        setFieldTouched('validityDriverLicense', true);
        setFieldTouched('licenseFrontSide', true);
        setFieldTouched('licenseBackSide', true);
      }, 1);
    },
    [setFieldValue, setFieldTouched]
  );

  const disabledInput = action === 'view';

  const hasFieldWithValue = useMemo(() => {
    const filterValues = ([key]) =>
      driverLivenseInitialValues[key] !== undefined;
    const someFieldWithValue = ([, value]) => Boolean(value);

    const currentVisaValues = Object.entries(values).filter(filterValues);
    return currentVisaValues.some(someFieldWithValue);
  }, [values]);

  const cleanLicenseButton = useCallback(() => {    
    setValues({ ...values, ...driverLivenseInitialValues });
  }, [setValues, values]);

  return (
    <>
      <Section
        title="LICENCIA DE CONDUCIR"
        right={
          <div className="d-flex justify-content-end">
            <Checkbox
              name="isPermanentLicense"
              label="Permanente"
              onChange={handlePermanentLicenseChange}
            />
          </div>
        }
      >
        <div className="row">
          <div className="col-md-4">
            <ConnectedSelect
              label={`Tipo de licencia ${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="licenseType"
              options="commons.driverLicenseTypeEntities"
              onChange={handleOnChangeLicenseType}
              disabled={disabledInput}
            />
          </div>
          <div className="col-4">
            <Input
              mask={alphaMask(20)}
              maskPlaceholder=""
              label={`Número de licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              placeholder={`Número de licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="licenseNumber"
              disabled={disabledInput}
              upperCase
            />
          </div>
          <div className="col-md-4">
            <DatePickerV2
              // initialValue={initialValues.antiquity || values.antiquity}
              placeholder={`Antigüedad${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="antiquity"
              label={`Antigüedad${hasFieldWithValue || isForeigner ? '*' : ''}`}
              disabled={disabledInput}
              minimumDate={ANTIQUITY.MIN_DATE}
              maximumDate={ANTIQUITY.MAX_DATE}
              formatDateValue
            />
          </div>
          <div className="col-md-4">
            <DatePickerV2
              // initialValue={initialValues.expedition || values.expedition}
              label={`EMISIÓN`}
              placeholder={`EMISIÓN`}
              name="expedition"
              disabled={disabledInput}
              minimumDate={EMISSION.MIN_DATE}
              maximumDate={EMISSION.MAX_DATE}
              formatDateValue
            />
          </div>
          {!isPermanentLicense && (
            <div className="col-md-4">
              <DatePickerV2
                // initialValue={
                //   !hasChangedPermanent
                //     ? initialValues.validityDriverLicense ||
                //       values.validityDriverLicense
                //     : undefined
                // }
                label={`Vigencia${hasFieldWithValue || isForeigner ? '*' : ''}`}
                placeholder={`Vigencia${
                  hasFieldWithValue || isForeigner ? '*' : ''
                }`}
                name="validityDriverLicense"
                disabled={disabledInput}
                minimumDate={VALIDITY.MIN_DATE}
                maximumDate={VALIDITY.MAX_DATE}
                formatDateValue
              />
            </div>
          )}
          <div className="col-md-4">
            <ConnectedSelect
              disabled={disabledInput}
              label={`Nacionalidad${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="nationality"
              options="commons.nationalities"
            />
          </div>
          {!isForeigner && (
            <div className="col-md-4">
              <ConnectedSelect
                disabled={disabledInput}
                label={`Estado de emisión${
                  hasFieldWithValue || isForeigner ? '*' : ''
                }`}
                name="emissionState"
                options="commons.states"
              />
            </div>
          )}
          <div className="col-12 col-md-4">
            <FileInput
              url={action !== 'add' ? values.licenseFrontSideUrl : undefined}
              displayText={values.licenseFrontSide}
              type="file"
              label={`Frontal de la licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              placeholder={`Frontal de la licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="licenseFrontSide"
              accept={acceptFiles}
              disabled={disabledInput}
            />
          </div>
          <div className="col-12 col-md-4">
            <FileInput
              url={action !== 'add' ? values.licenseBackSideUrl : undefined}
              displayText={values.licenseBackSide}
              type="file"
              label={`Trasera de la licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              placeholder={`Trasera de la licencia${
                hasFieldWithValue || isForeigner ? '*' : ''
              }`}
              name="licenseBackSide"
              accept={acceptFiles}
              disabled={disabledInput}
            />
          </div>
          <div className="col-md-4 d-flex align-items-center">
            {hasFieldWithValue && disabledInput && (
              <span
                onClick={cleanLicenseButton}
                onKeyPress={cleanLicenseButton}
                className="removable svg-icon svg-icon-center"
                role="button"
                tabIndex="0"
              >
                <Icons.CloseGroup width="100%" height="100%" />
              </span>
            )}
          </div>
        </div>
      </Section>
    </>
  );
}

DriverLicense.propTypes = {
  action: PropTypes.string,
};
