import { useCallback, useState, useEffect } from 'react';
import { withForm } from '@casanova/casanova-common';
import {
  isChangeOfVehicleFlow,
  isExtendOfContractFlow,
} from '@reservations/commons/utils';
import Contract from './Contract';
import config from './config';

export default function ContractContainer({ closeResponseDialog, ...props }) {
  const { responseDialog } = props;

  const [showObservation, setShowObservation] = useState(false);
  const [vehicleSelected, setVehicleSelected] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [showChangeOfVehicleForm, setShowChangeOfVehicleForm] = useState(
    isChangeOfVehicleFlow()
  );

  const handleClickSubmit = useCallback(
    ({ setErrors, errors }) => {
      if (!vehicleSelected.uuid) {
        setErrors({
          ...errors,
          notValidSubmit: 'Debes seleccionar un vehículo para continuar.',
        });
      } else {
        setErrors({
          ...errors,
          notValidSubmit: '',
        });
        setShowObservation(true);
      }
    },
    [vehicleSelected]
  );

  const submitForm = useCallback(
    (values) => {
      const data = {
        vehicle: vehicleSelected,
        dataReservationContract: values,
      };
      setIsSending(true);
      setShowChangeOfVehicleForm(false);
      return config.submitForm(data);
    },
    [config, vehicleSelected]
  );

  useEffect(() => {
    if (isExtendOfContractFlow()) {
      setShowObservation(true);
    }
  }, []);

  const onCloseResponseDialog = useCallback(() => {
    closeResponseDialog();

    if (!responseDialog.success) {
      setShowObservation(true);
    }
  }, [closeResponseDialog, responseDialog]);

  const Component = withForm({
    config: {
      ...config,
      disableSubmit: !showObservation,
      handleClickSubmit,
      submitForm,
    },
  })(Contract);

  return Component({
    ...props,
    closeResponseDialog: onCloseResponseDialog,
    showObservation,
    setShowObservation,
    vehicleSelected,
    setVehicleSelected,
    isSending,
    setShowChangeOfVehicleForm,
    showChangeOfVehicleForm,
  });
}
