import { call, put, select } from 'redux-saga/effects';
import { showLoader, hideLoader } from 'store/commons/actions';
import { setHasAddedBillingDetailsOfPayment } from 'store/reservations/actions';
import {
  getContractBySearchSucceed,
  getContractBySearchFailed,
  getContractDetailsSucceed,
  getContractDetailsFailed,
  getMovementListSucceed,
  getMovementListFailed,
  setMovementTypes,
  savePaymentSucceed,
  savePaymentFailed,
  validCodeFailed,
  setCancelMovementSuccess,
  setCancelMovementFailed,
} from 'store/payments/actions';
import { getContractDetails, cancelMovementService } from 'services/payments';
import {
  fetchMovementTypes,
  fetchContractMovements,
  postMovement,
} from 'services/shared';
import {
  addBillingInfo,
  editBillingInfo,
  deleteBillingInfo,
} from 'services/customers';
import _get from 'lodash/get';

export function* getContractBySearchSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getContractDetails, action.payload);
    yield put(getContractBySearchSucceed(data));
  } catch (error) {
    yield put(getContractBySearchFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

function* updateContractsMovements(contractUuid) {
  const contractMovements = yield call(fetchContractMovements, contractUuid);
  yield put(
    getMovementListSucceed({
      content: contractMovements.map((item) => ({
        ...item,
        movementType: item.movementType.name,
      })),
      totalElements: contractMovements.length,
      totalPages: 1,
    })
  );
}

export function* getContractDetailsSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getContractDetails, action.payload);

    let movementTypes = yield select(
      (state) => state.payments.movementTypes.list
    );

    if (movementTypes.length === 0) {
      movementTypes = yield call(fetchMovementTypes);
      yield put(setMovementTypes(movementTypes));
    }

    yield updateContractsMovements(data.uuid);

    yield put(getContractDetailsSucceed(data));
  } catch (error) {
    const hasNotFound = error.message.includes('No se encontró');
    yield put(getContractDetailsFailed({ error, hasNotFound }));
  } finally {
    yield put(hideLoader());
  }
}

export function* getMovementsListSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(fetchContractMovements, action.payload);
    yield put(getMovementListSucceed(data));
  } catch (error) {
    yield put(getMovementListFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* savePaymentSaga(action) {
  try {
    yield put(showLoader());
    let { payload } = action;
    const hasAddedBillingDetails = yield select((state) =>
      _get(
        state,
        'reservations.reservation.payment.hasAddedBillingDetails',
        false
      )
    );

    if (payload.invoiceDeleted) {
      yield call(deleteBillingInfo, payload.invoiceDeleted);
    }

    if (payload.invoiceForm && !hasAddedBillingDetails) {
      const {
        emailInvoice,
        invoiceSocialReason,
        invoiceRfc,
        invoiceCfdiUse,
        invoicePayForm = '',
        invoicePayMethod = '',
        invoiceSelected = '',
        invoiceZipCode = '',
        invoiceTaxRegime = '',
        observationsInvoice = '',
      } = action.payload.invoiceForm;

      const addBillingRequest = {
        cfdiUseUuid: invoiceCfdiUse,
        name: invoiceSocialReason,
        naturalPerson: action.payload.customerUuid,
        rfc: invoiceRfc,
        paymentForm: invoicePayForm,
        paymentMethod: invoicePayMethod,
        taxZipCode: invoiceZipCode,
        fiscalRegime: invoiceTaxRegime,
      };

      if (invoiceSelected) {
        addBillingRequest.uuid = invoiceSelected;
        addBillingRequest.isPrimary = false;
      }

      const billing = yield call(
        invoiceSelected ? editBillingInfo : addBillingInfo,
        addBillingRequest
      );

      payload = {
        ...payload,
        invoice: {
          billingDetails: invoiceSelected || billing.data.uuid,
          email: emailInvoice,
          observations: observationsInvoice,
        },
      };
      delete payload.invoiceForm;

      yield put(setHasAddedBillingDetailsOfPayment(true));
    }
    const data = yield call(postMovement, payload);
    yield updateContractsMovements(payload.contract);
    yield put(savePaymentSucceed(data));
  } catch (error) {
    if (error.code === 'MSUSER-900') yield put(validCodeFailed(error));
    else yield put(savePaymentFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* setCancelMovementSaga(action) {
  try {
    yield put(showLoader());
    const currentContract = yield select((state) =>
      _get(state, 'payments.contractDetails.data', {})
    );
    const params = {
      movementUuid: action.payload,
      contractUuid: currentContract.uuid,
    };
    const data = yield call(cancelMovementService, params);

    yield updateContractsMovements(currentContract.uuid);

    yield put(setCancelMovementSuccess());
  } catch (error) {
    const hasNotFound = error.message.includes('No se encontró');
    yield put(setCancelMovementFailed({ error, hasNotFound }));
  } finally {
    yield put(hideLoader());
  }
}
