import moment from 'moment';
import {
  FULL_DATE_FORMAT,
  timeZone,
} from '@casanova/casanova-common/lib/utils/date';

const formatter = (values) => {
  const { date, time } = values;
  return {
    invoiceUuid: values.invoiceUuid,
    paymentForm: values.paymentForm,
    paymentMethod: values.paymentMethod,
    amount: values.amount,
    paymentDate: moment(`${date} ${time}`)
      .tz(timeZone)
      .format(FULL_DATE_FORMAT),
  };
};

export default formatter;
