import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withLayout } from '@casanova/casanova-common';
import { fetchCatalog } from 'store/commons/actions';
import { closeResponseDialog, setCustomerType } from 'store/customers/actions';
import { iconsResponseDialog } from '@customers/common/config';
// eslint-disable-next-line import/no-named-as-default
import CustomerCreatorBase from './CustomerCreatorBase';

export default withLayout(
  compose(
    connect(
      (state) => ({
        ...iconsResponseDialog,
        responseDialog: _get(state, 'customers.responseDialog', {}),
        quoteId: _get(state, 'reservations.quote.values.quoteId', undefined),
        onCancelPath: '/customers/',
        customerType: _get(state, 'customers.customerType', ''),
        customerPersonType: _get(state, 'customers.customerPersonType', ''),
        customersPreventChange: state.customers.preventChange,
      }),
      {
        fetchCatalog,
        closeResponseDialog,
        setCustomerType,
      }
    )
  )(CustomerCreatorBase)
);
