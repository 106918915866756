export const initialValues = {
  km: '',
  brand: '',
  line: '',
  version: '',
  model: '',
  color: '',
  status: '',
  subStatus: '',
  businessArea: '',
  warehouse: '',
  vehicleUuid: '',
  fuelType: '',
  transmissionType: '',
  serialNumber: '',
  plateNumber: '',
  customer: '',
  contractNumber: '',
  category: '',
};

export const initialTouched = {
  km: true,
  brand: true,
  line: true,
  version: true,
  model: true,
  color: true,
  status: true,
  subStatus: true,
  businessArea: true,
  warehouse: true,
  vehicleUuid: true,
  fuelType: true,
  transmissionType: true,
  serialNumber: true,
  plateNumber: true,
  customer: true,
  contractNumber: true,
  category: true,
};
