import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSession } from '@casanova/casanova-common';
import { inUserActivation, isLogin } from 'utils/navigation';
import Login from './containers/Login';
import Forbidden from './containers/Forbidden';
import Activation from './containers/Activation';
import Root from './containers/Root';
import PDF from './containers/PDF';
import { withAuth } from './utils/auth';

function Routes() {
  const [currentToken] = useSession({
    redirectPath: '/vehicle',
    exception: inUserActivation() || isLogin(),
  });

  return (
    <Switch>
      {!currentToken && <Route exact path="/login" component={Login} />}
      <Route exact path="/forbidden" component={Forbidden} />
      <Route exact path="/activacion" component={Activation} />
      <Route exact path="/pdf" component={PDF} />
      <Route component={withAuth(Root)} />
      <Route path="*">
        <Redirect to="/vehicle" />
      </Route>
    </Switch>
  );
}

export default Routes;
