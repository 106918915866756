import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { fetchCatalog } from 'store/catalogs/actions';
import {
  openConfirmationMessage,
  closeConfirmationMessage,
  storeVehicleData,
  saveVehicleRequested,
  closeResultMessage,
  cleanVehicleData,
} from 'store/vehicles/actions';
import VehicleCreator from './VehicleCreator';

export default compose(
  connect(
    (state) => ({
      listBrands: state.catalogs.brand.list,
      brandSelected: state.catalogs.brandSelected,
      lineSelected: state.catalogs.lineSelected,
      confirmationMessage: state.vehicles.confirmationMessage,
      resultMessage: state.vehicles.resultMessage,
      vehicle: state.vehicles.vehicleData,
      loader: _get(state, 'commons.loader', false),
    }),
    {
      fetchCatalog,
      openConfirmationMessage,
      closeConfirmationMessage,
      closeResultMessage,
      storeVehicleData,
      saveVehicleRequested,
      cleanVehicleData,
    }
  )
)(VehicleCreator);
