import React, { useEffect, useState, useCallback } from 'react';
import i18n from '@i18n';
import { Button, Icons } from '@casanova/casanova-common';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import { BackButton } from '@reservations/components';
import { withForm } from 'hooks';
import { DELIVERY_REFUND_TYPES } from '@reservations/commons/constants';
import { getFolioMessage } from '@reservations/commons/utils';
import Details from '../Details';

const { DELIVERY } = DELIVERY_REFUND_TYPES;

function DeliveryReturnStepOne(props) {
  const {
    history,
    verificationCheckList = {},
    verifyCompletedCheckListState = {},
    verifyCompletedCheckList,
    type = '',
    reservationInfo = {},
    fetchCatalog,
    hydrateExtendContractFlow,
  } = props;

  const [showVerify, setShowVerify] = useState(false);
  const [showExtendInProgressAlert, setShowExtendInProgressAlert] = useState(
    false
  );
  const [showCancelExtension, setShowCancelExtension] = useState(false);

  const { uuid = '' } = verificationCheckList;
  const { data = false } = verifyCompletedCheckListState;
  const { folio = '' } = reservationInfo;

  const handleCancel = useCallback(() => {
    setShowVerify(false);
  }, []);

  const handleContinue = useCallback(() => {
    history.push(`/daily-rent-contracts/`);
  }, [verifyCompletedCheckList, uuid]);

  const handleSend = useCallback(() => {
    if (type === DELIVERY) setShowVerify(true);
    else if (reservationInfo.currentContract)
      setShowExtendInProgressAlert(true);
    else history.push(`./refund-vehicle-parts`);
  }, [type, history, reservationInfo.currentContract]);

  const handleCancelSend = useCallback(() => {
    if (type === DELIVERY) history.push(`/daily-rent-contracts`);
    else history.push(`/returns`);
  }, [history]);

  useEffect(() => {
    if (data && type === DELIVERY) {
      const basePath = type === DELIVERY ? 'delivery' : 'refund';
      history.push(`./${basePath}-vehicle-parts`);
    }
  }, [data, history]);

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/bank-card-types',
      catalogPath: 'pricequotes',
    });
  }, []);

  const folioMessage = getFolioMessage({ folio });

  const handleContinueExtendFlow = () => {
    setShowExtendInProgressAlert(false);
    hydrateExtendContractFlow({
      currentContract: reservationInfo.currentContract,
    });
  };

  const handleCancelExtendFlow = () => {
    setShowExtendInProgressAlert(false);
    setShowCancelExtension(true);
  };

  const handleContinueCancelExtension = () => {
    history.push(`./refund-vehicle-parts`);
  };

  const handleCloseCancelExtension = () => {
    setShowCancelExtension(false);
  };

  return (
    <div>
      <ActionModal
        open={showVerify}
        actionLabel={i18n('COMMONS__CONTINUE__TEXT')}
        closeLabel={i18n('COMMONS__CANCEL__TEXT')}
        title={i18n('RESERVATIONS__DELIVERY__CONFIRM_MODAL__TITLE')}
        message={i18n('RESERVATIONS__DELIVER_REFUND__CONFIRM_FIRST_STEP', [
          folio,
        ])}
        messageBold
        textBold={[folioMessage]}
        icon={<Icons.StatusWarning />}
        onAction={handleContinue}
        onClose={handleCancel}
      />
      <ActionModal
        open={showExtendInProgressAlert}
        actionLabel={i18n('COMMONS__CONTINUE__TEXT')}
        closeLabel={i18n('COMMONS__CANCEL__TEXT')}
        title="Extensión en Progreso"
        message="La reservación tiene una extensión de contrato en progreso</br>¿Desea continuar con el proceso de extensión?"
        messageBold
        textBold={[folioMessage]}
        icon={<Icons.StatusWarning />}
        onAction={handleContinueExtendFlow}
        onClose={handleCancelExtendFlow}
      />
      <ActionModal
        open={showCancelExtension}
        actionLabel={i18n('COMMONS__CONTINUE__TEXT')}
        closeLabel={i18n('COMMONS__CLOSE__TEXT')}
        title="Cancelar Extensión"
        message="Al cancelar el proceso de extensión está no será aplicada a la reservación</br>Esta acción se reflejara una vez terminada la reservación</br>¿Desea continuar?"
        messageBold
        textBold={[folioMessage]}
        icon={<Icons.WarningBlack />}
        onAction={handleContinueCancelExtension}
        onClose={handleCloseCancelExtension}
      />

      <Details {...props} />

      <div className="row mt-4">
        {type !== DELIVERY && (
          <div className="col-2 p-0 pr-1">
            <BackButton page="/reservations" />
          </div>
        )}

        <div className="col" />

        {type === DELIVERY && (
          <div className="col-2 p-0 pr-1">
            <Button block outline onClick={handleCancelSend}>
              {i18n('COMMONS__CANCEL__TEXT')}
            </Button>
          </div>
        )}

        <div className="col-2 p-0 pl-1">
          <Button block onClick={handleSend}>
            {type === DELIVERY ? 'Entregar' : 'Devolución'}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default (props) => {
  const Component = withForm({ config: props.config })(DeliveryReturnStepOne);
  return Component(props);
};
