import React, { useState, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  MaskedInput as Input,
  TextAreaInput,
  Icons,
  ConnectedSelect,
  Section,
  DatePickerV2,
  SearchableSelect,
} from '@casanova/casanova-common';
import { getFieldSearch } from '@casanova/casanova-common/lib/utils/transformHelpers';
import i18n from '@i18n';
import { PreventChange } from 'components';
import { hourMask } from 'utils/masks';
import {
  textAreaRegex,
  alphaMask,
  alphaMaskWithSpaces,
} from '@casanova/casanova-common/lib/utils/masks';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { EMISSION_AND_VALIDITY_VALIDATIONS } from '@customers/common/validations';
import {
  getNextYear,
  getCurrentTime,
} from '@casanova/casanova-common/lib/utils/date';
import { isSafari } from 'utils/browser';

const { ADD } = FORM_ACTIONS;
const { VALIDITY } = EMISSION_AND_VALIDITY_VALIDATIONS;

export default function TransfersInformation({
  action = ADD,
  searchEmployees,
  searchVehicles,
  fetchCatalog,
  user,
  transfersSearch,
}) {
  const [fetchedCurrentUser, setFetchedCurrentUser] = useState(false);

  const {
    dirty,
    isSubmitting,
    initialValues,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const handleSearchEmployee = useCallback(
    (query) => {
      if (query && query.length > 2) {
        searchEmployees(query);
      }
    },
    [searchEmployees]
  );

  const handleSearchPlate = useCallback(
    (plateNumber) => {
      if (plateNumber && plateNumber.length > 1) {
        setFieldValue('plate', '');
        searchVehicles({ plateNumber, isTransfer: true });
      }
    },
    [searchVehicles]
  );

  useEffect(() => {
    if (action === ADD && !fetchedCurrentUser) {
      searchEmployees({ name: user.fullName, status: true });
    }
  }, [action, user, fetchedCurrentUser, searchEmployees]);

  useEffect(() => {
    if (!fetchedCurrentUser && transfersSearch.length > 0) {
      const currentUser = transfersSearch.find(
        ({ fullName }) => fullName === user.fullName
      );

      if (currentUser) {
        setFieldValue('requester', currentUser.uuid);
        setFieldValue(getFieldSearch('requester'), currentUser.fullName);
        setFetchedCurrentUser(true);
      }
    }
  }, [fetchedCurrentUser, transfersSearch, user, setFieldValue]);

  useEffect(() => {
    const currentTime = getCurrentTime({ incrementMinutes: 10 });

    setFieldValue('departureTime', currentTime);
    // setFieldValue('departureRealTime', currentTime);
    // setFieldValue('arrivalRealTime', currentTime);
    setFieldValue('arrivalTime', currentTime);

    setTimeout(() => {
      setFieldTouched('departureTime', true);
      setFieldTouched('departureRealTime', true);
      setFieldTouched('arrivalRealTime', true);
      setFieldTouched('arrivalTime', true);
    }, 1);
  }, [setFieldValue, setFieldTouched]);

  useEffect(() => {
    if (action) setFieldValue('action', action);
  }, [setFieldValue, action]);

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/warehouses',
      catalogPath: 'vehicle',
    });
  }, [fetchCatalog]);

  const disabledInput = action !== ADD;

  const departureRealDateName =
    action === ADD ? 'departureRealDate' : 'realDepartureDate';

  const departureRealTimeName =
    action === ADD ? 'departureRealTime' : 'realDepartureTime';

  const returnRealDateName =
    action === ADD ? 'returnRealDate' : 'realArrivalDate';

  const arrivalRealTimeName =
    action === ADD ? 'arrivalRealTime' : 'realArrivalTime';

  return (
    <>
      <PreventChange preventChange={dirty && !isSubmitting} />
      <Section title={i18n('TRANSFERS__CREATE__SECTION_TITLE')}>
        <div className="row">
          <div className="col-md-4">
            <SearchableSelect
              name="requester"
              label="SOLICITANTE*"
              placeholder="SOLICITANTE*"
              options="customers.employees"
              mask={alphaMaskWithSpaces(200)}
              onSearching={handleSearchEmployee}
              icon="svg-icon-search"
              disabled={disabledInput}
            />
          </div>
          <div className="col-md-4">
            <SearchableSelect
              name="plate"
              label="PLACA*"
              placeholder="PLACA*"
              options="vehicles.vehiclesByPlate"
              mask={alphaMaskWithSpaces(10)}
              onSearching={handleSearchPlate}
              icon="svg-icon-search"
              disabled={disabledInput}
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={alphaMaskWithSpaces(20)}
              maskPlaceholder=""
              label="CONDUCTOR*"
              placeholder="CONDUCTOR*"
              name="driver"
              disabled={disabledInput}
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={alphaMask(20)}
              maskPlaceholder=""
              label="Número de licencia*"
              placeholder="Número de licencia*"
              name="licenseNumber"
              upperCase
            />
          </div>
          <div className="col-md-4">
            <DatePickerV2
              label="Vigencia*"
              placeholder="Vigencia*"
              name="validityDriverLicense"
              minimumDate={VALIDITY.MIN_DATE}
              maximumDate={VALIDITY.MAX_DATE}
              formatDateValue
            />
          </div>
          <div className="col-md-4">
            <ConnectedSelect
              label="Estado de emisión*"
              name="emissionState"
              options="commons.states"
            />
          </div>

          <div className="col-md-4">
            <DatePickerV2
              initialValue={initialValues.departureDate}
              placeholder="FECHA DE SALIDA PROGRAMADA*"
              name="departureDate"
              label="FECHA DE SALIDA PROGRAMADA*"
              autoComplete="off"
              disabled={disabledInput}
              placement={isSafari() ? 'bottom' : undefined}
              maximumDate={getNextYear({
                years: 0,
                months: new Date().getMonth() + 1,
              })}
              formatDateValue
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={hourMask}
              maskPlaceholder=""
              label="Hora de salida programada*"
              placeholder="Hora de salida programada*"
              name="departureTime"
              right={<Icons.Time width="85%" height="85%" />}
              disabled={disabledInput}
            />
          </div>

          <div className="col-md-4">
            <DatePickerV2
              initialValue={initialValues.returnDate}
              placeholder="FECHA DE LLEGADA PROGRAMADA*"
              name="returnDate"
              label="FECHA DE LLEGADA PROGRAMADA*"
              autoComplete="off"
              placement={isSafari() ? 'bottom' : undefined}
              formatDateValue
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={hourMask}
              maskPlaceholder=""
              label="HORA DE LLEGADA PROGRAMADA*"
              placeholder="HORA DE LLEGADA PROGRAMADA*"
              name="arrivalTime"
              right={<Icons.Time width="85%" height="85%" />}
            />
          </div>

          <div className="col-md-4">
            <DatePickerV2
              placeholder="FECHA DE SALIDA REAL"
              name={departureRealDateName}
              label="FECHA DE SALIDA REAL"
              autoComplete="off"
              placement={isSafari() ? 'bottom' : undefined}
              maximumDate={getNextYear({
                years: 0,
                months: new Date().getMonth() + 1,
              })}
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={hourMask}
              maskPlaceholder=""
              label="Hora de salida real"
              placeholder="Hora de salida real"
              name={departureRealTimeName}
              right={<Icons.Time width="85%" height="85%" />}
            />
          </div>

          <div className="col-md-4">
            <DatePickerV2
              placeholder="FECHA DE LLEGADA REAL"
              name={returnRealDateName}
              label="FECHA DE LLEGADA REAL"
              autoComplete="off"
              placement={isSafari() ? 'bottom' : undefined}
              maximumDate={getNextYear({
                years: 0,
                months: new Date().getMonth() + 1,
              })}
              formatDateValue
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={hourMask}
              maskPlaceholder=""
              label="HORA DE LLEGADA real"
              placeholder="Hora de llegada real"
              name={arrivalRealTimeName}
              right={<Icons.Time width="85%" height="85%" />}
            />
          </div>

          <div className="col-md-4">
            <ConnectedSelect
              label="ORIGEN*"
              name="source"
              options="commons.catalogsWarehouses"
              disabled={disabledInput}
            />
          </div>
          <div className="col-md-4">
            <ConnectedSelect
              label="DESTINO*"
              name="destination"
              options="commons.catalogsWarehouses"
              disabled={disabledInput}
            />
          </div>
          <div className="col-12">
            <TextAreaInput
              label="Observaciones"
              placeholder="Observaciones"
              name="observations"
              regex={textAreaRegex}
              maxLength={500}
              disabled={disabledInput}
            />
          </div>
        </div>
      </Section>
    </>
  );
}
