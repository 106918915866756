import moment from 'moment';
import { YYYY_MM_DD, timeZone } from 'utils/constants';
import { LOAN_CREATOR_FIELDS as FIELDS } from '@loans/common/constants';

export const formatter = (values) => ({
  returnDate: moment(values[FIELDS.RETURN_DATE], YYYY_MM_DD)
    .tz(timeZone)
    .format(YYYY_MM_DD),
  returnTime: values[FIELDS.RETURN_TIME],
  uuid: values.uuid,
});
