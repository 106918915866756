import { takeLatest } from 'redux-saga/effects';
import types from './action-types';
import {
  getContractBySearchSaga,
  getContractDetailsSaga,
  getMovementsListSaga,
  savePaymentSaga,
  setCancelMovementSaga,
} from './sagas';

export default function* watchers() {
  yield takeLatest(types.GET_CONTRACT_BY_SEARCH, getContractBySearchSaga);
  yield takeLatest(types.GET_CONTRACT_DETAILS, getContractDetailsSaga);
  yield takeLatest(types.GET_CONTRACT_MOVEMENT_LIST, getMovementsListSaga);
  yield takeLatest(types.SAVE_PAYMENT, savePaymentSaga);
  yield takeLatest(types.SET_CANCEL_MOVEMENT, setCancelMovementSaga);
}
