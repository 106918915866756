import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _isString from 'lodash/isString';
import { setPreventChange } from 'store/commons/actions';

export default function usePreventChange() {
  const dispatch = useDispatch();

  const preventChange = useSelector((state) => state.commons.preventChange);

  const changePreventChange = useCallback(
    (status) => {
      const currentStatus =
        _isString(status) && status === 'reset'
          ? { success: false, cancel: false, active: false }
          : status;
      dispatch(setPreventChange(currentStatus));
    },
    [dispatch]
  );

  return [preventChange, changePreventChange];
}
