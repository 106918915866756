import React, { useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import ResponseDialog from 'components/ResponseDialog';
import i18n from '@i18n';
import {
  Modal,
  Section,
  withForm,
  MaskedInput as Input,
  ConnectedSelect,
} from '@casanova/casanova-common';
import config from './config.js';

import {
  alphaMaskWithSpacesAndAccent,
  cardValidityMask,
  cardNumberValidations,
} from 'utils/masks';

const Component = ({
  onUpdateForm,
  cardSelected,
  // ...rest
}) => {
  useEffect(() => {
    if (!_isEmpty(cardSelected)) onUpdateForm(cardSelected);
  }, [cardSelected, onUpdateForm]);

  /* -------------------------------------------------------------------------------------------- */

  return (
    <div className="row">
      <div className="col-6">
        <Input
          mask={alphaMaskWithSpacesAndAccent(40)}
          maskPlaceholder=""
          label="Titular de la tarjeta*"
          placeholder="Titular de la tarjeta*"
          name="holdbackCardHolderModify"
        />
      </div>
      <div className="col-6">
        <ConnectedSelect
          label="Banco*"
          name="holdbackBankModify"
          options="commons.catalogsBanks"
        />
      </div>
      <div className="col-6">
        <Input
          label="No. de tarjeta*"
          placeholder="No. de tarjeta*"
          name="holdbackCardModify"
          mask={cardNumberValidations}
          maskPlaceholder="#### #### #### ####"
        />
      </div>
      <div className="col-4">
        <Input
          mask={cardValidityMask}
          maskPlaceholder=""
          label="Vigencia*"
          placeholder="Vigencia*"
          name="holdbackValidityModify"
        />
      </div>
    </div>
  );
};

const ModifyReservationModal = (props) => {
  const {
    responseDialogUpdateCard = {},
    open,
    handleOpenCloseModifyCardModal,
    closeResponseDialogUpdateCard,
  } = props;

  const handleCloseModal = () => {
    handleOpenCloseModifyCardModal();
  };

  const FormUpdate = withForm({
    config: {
      ...config,
      submitForm: (...args) => {
        handleCloseModal();
        return config.submitForm(...args);
      },
    },
  })(Component)({
    ...props,
    onCancel: handleCloseModal,
  });

  const handelOnCloseConfirm = () => {
    closeResponseDialogUpdateCard();
  };

  return (
    <>
      <Modal
        open={open}
        header={null}
        footer={null}
        contentClassName="px-4 py-4 container"
        style={{ zIndex: -1 }}
        closeButton
        onClose={handleCloseModal}
        bigModal
      >
        <Section title="Modificación de tarjeta">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">{FormUpdate}</div>
            </div>
          </div>
        </Section>
      </Modal>
      <ResponseDialog
        open={responseDialogUpdateCard.open}
        success={responseDialogUpdateCard.success}
        errorCode={responseDialogUpdateCard.errorCode}
        successTitle="TARJETA MODIFICADA"
        successLabel={i18n('COMMONS__CLOSE__TEXT')}
        successMessage="La tarjeta fue modificada exitosamente"
        onError={handelOnCloseConfirm}
        onClose={handelOnCloseConfirm}
        onSuccess={handelOnCloseConfirm}
        errorLabel={i18n('COMMONS__CLOSE__TEXT')}
      />
    </>
  );
};

export default ModifyReservationModal;
