import moment from 'moment';
import { ISO_DATE_FORMAT, MX_DATE_FORMAT } from 'utils/constants';
import { transformIdOfList } from 'utils/transformHelpers';
import types from './action-types';

const initialState = {
  responseDialog: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
  },
  list: {
    params: {
      page: 0,
      size: 10,
      enable: true,
      property: 'folio',
      direction: 'desc',
    },
    searchPattern: '',
    filters: [],
    totalPages: 1,
    totalElements: 10,
    results: [],
  },
  detail: {},
};

export function transfersReducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.CLOSE_RESPONSE_DIALOG:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: false,
        },
      };
    case types.SET_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            ...payload,
          },
        },
      };
    case types.GET_LIST_FAILED:
    case types.GET_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          results: [],
        },
      };
    case types.GET_LIST_SUCCEED:
      const transformed = payload.content.map((transfer) => {
        const {
          departureDate,
          departureTime,
          realDepartureDate,
          realDepartureTime,
          arrivalDate,
          arrivalTime,
          realArrivalDate,
          realArrivalTime,
        } = transfer;
        const departureDateTime = moment(
          `${departureDate}T${departureTime}`,
          ISO_DATE_FORMAT
        ).format(MX_DATE_FORMAT);

        const realDepartureDateTime = moment(
          `${realDepartureDate}T${realDepartureTime}`,
          ISO_DATE_FORMAT
        ).format(MX_DATE_FORMAT);

        const arrivalDateTime = moment(
          `${arrivalDate}T${arrivalTime}`,
          ISO_DATE_FORMAT
        ).format(MX_DATE_FORMAT);

        const realArrivalDateTime = moment(
          `${realArrivalDate}T${realArrivalTime}`,
          ISO_DATE_FORMAT
        ).format(MX_DATE_FORMAT);

        return {
          ...transfer,
          departureDateTime,
          realDepartureDateTime,
          arrivalDateTime,
          realArrivalDateTime,
        };
      });
      return {
        ...state,
        list: {
          ...state.list,
          results: transformIdOfList(transformed),
          totalPages: payload.totalPages,
          totalElements: payload.totalElements,
        },
      };
    case types.GET_TRANSFER_DETAIL:
    case types.SAVE_TRANSFER:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
        detail: {},
      };
    case types.GET_TRANSFER_DETAIL_FAILED:
    case types.SAVE_TRANSFER_FAILED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
        detail: {},
      };
    case types.SAVE_TRANSFER_SUCCEED:
    case types.CANCEL_TRANSFER_SUCCEED:
    case types.CLOSE_TRANSFER_SUCCEED:
    case types.AUTHORIZE_TRANSFER_SUCCEED:
    case types.REJECT_TRANSFER_SUCCEED:
    case types.EDIT_TRANSFER_SUCCEED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.GET_TRANSFER_DETAIL_SUCCEED:
      return {
        ...state,
        detail: payload,
      };

    case types.CANCEL_TRANSFER:
    case types.CLOSE_TRANSFER:
    case types.AUTHORIZE_TRANSFER:
    case types.REJECT_TRANSFER:
    case types.EDIT_TRANSFER:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.CANCEL_TRANSFER_FAILED:
    case types.CLOSE_TRANSFER_FAILED:
    case types.AUTHORIZE_TRANSFER_FAILED:
    case types.REJECT_TRANSFER_FAILED:
    case types.EDIT_TRANSFER_FAILED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.SET_TABLE_PARAMS:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            ...payload,
            page: 0,
          },
        },
      };

    case types.SET_TABLE_PAGE:
      return {
        ...state,
        list: {
          ...state.list,
          params: {
            ...state.list.params,
            page: payload,
          },
        },
      };

    case types.RESET:
      return initialState;
    default:
      return state;
  }
}
