import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import {
  applyBranchDiscount,
  cancelBranchDiscount,
  closeResponseDialog,
  validatePromocode,
  openSendQuoteDialog,
  editPlan,
  editDiscount,
  cancelEditDiscount,
  savePayment,
  getVehicleLinePrices,
} from 'store/reservations/actions';
import { DELIVERY_REFUND_TYPES } from '@reservations/commons/constants';
import {
  mapStateToPropsOfDeliveryAndReturn,
  dispatchToPropsOfDeliveryAndReturn,
} from '@reservations/commons/deliveryAndRefundConfig';
import ExtraChargeSummary from './ExtraChargeSummary';

export default compose(
  connect(
    (state) => ({
      ...mapStateToPropsOfDeliveryAndReturn(
        state,
        DELIVERY_REFUND_TYPES.REFUND
      ),
      ..._get(state, 'reservations.quote.values', {}),
      branches: _get(state, 'commons.branches.list', []),
      discountOptions: _get(state, 'reservations.filters.discounts', []),
      customerRefund: _get(
        state,
        'customers.customerDetail.data.customerMovementRefund',
        {}
      ),
      customerName: _get(state, 'customers.customerDetail.data.fullName', ''),
      selectedCategoryName: _get(
        state,
        'reservations.filters.selectedCategory.label',
        ''
      ),
      reservationUuid: _get(
        state,
        'reservations.reservationInfo.data.uuid',
        ''
      ),
      loader: state.commons.loader,
    }),
    {
      ...dispatchToPropsOfDeliveryAndReturn,
      applyBranchDiscount,
      cancelBranchDiscount,
      closeResponseDialog,
      validatePromocode,
      openSendQuoteDialog,
      getVehicleLinePrices,
      editPlan,
      editDiscount,
      cancelEditDiscount,
      savePayment,
    }
  )
)(ExtraChargeSummary);
