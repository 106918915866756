"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformIdOfList = exports.transformForSelect = exports.transformForRadioGroup = exports.transformForOptionBar = exports.transformDropdownElements = exports.transformDisableSelectOption = exports.replaceElement = exports.getLastSearchField = exports.getFieldSearch = exports.fieldSearch = void 0;
var _isArray2 = _interopRequireDefault(require("lodash/isArray"));
var _isObject2 = _interopRequireDefault(require("lodash/isObject"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var getObjectProp = function getObjectProp(obj, prop) {
  return prop.split('.').reduce(function (r, e) {
    return r[e];
  }, obj);
};
var transformForSelect = exports.transformForSelect = function transformForSelect(elements, value, label) {
  var levels = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var _levels$moreLevels = levels.moreLevels,
    moreLevels = _levels$moreLevels === void 0 ? false : _levels$moreLevels,
    _levels$levelsValuesK = levels.levelsValuesKey,
    levelsValuesKey = _levels$levelsValuesK === void 0 ? '' : _levels$levelsValuesK,
    _levels$levelsLabelsK = levels.levelsLabelsKey,
    levelsLabelsKey = _levels$levelsLabelsK === void 0 ? '' : _levels$levelsLabelsK;
  var selectElements = elements.map(function (element) {
    return _objectSpread(_objectSpread({}, element), {}, {
      value: moreLevels ? getObjectProp(element[value], levelsValuesKey) : element[value],
      label: moreLevels ? getObjectProp(element[label], levelsLabelsKey) : element[label]
    });
  });
  return selectElements;
};
var transformIdOfList = exports.transformIdOfList = function transformIdOfList(list, id) {
  var transformedList = list.map(function () {
    var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var idx = arguments.length > 1 ? arguments[1] : undefined;
    // eslint-disable-next-line no-param-reassign
    element.id = id ? element[id] : idx;
    return element;
  });
  return transformedList;
};
var transformForRadioGroup = exports.transformForRadioGroup = function transformForRadioGroup(elements, value, label) {
  return elements.map(function (element) {
    return _objectSpread(_objectSpread({}, element), {}, {
      value: Boolean(element[value]),
      label: element[label]
    });
  });
};
var replaceElement = exports.replaceElement = function replaceElement() {
  var elements = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var elementToReplace = arguments.length > 1 ? arguments[1] : undefined;
  var elementIdToReplace = arguments.length > 2 ? arguments[2] : undefined;
  var valueToCompare = arguments.length > 3 ? arguments[3] : undefined;
  return elements.map(function (element) {
    var sameElement = element[elementIdToReplace] === valueToCompare;
    return sameElement ? elementToReplace : element;
  });
};
var transformForOptionBar = exports.transformForOptionBar = function transformForOptionBar(_ref) {
  var elements = _ref.elements,
    value = _ref.value,
    label = _ref.label,
    _ref$sub = _ref.sub,
    sub = _ref$sub === void 0 ? '' : _ref$sub,
    _ref$check = _ref.check,
    check = _ref$check === void 0 ? '' : _ref$check,
    _ref$verifyCheck = _ref.verifyCheck,
    verifyCheck = _ref$verifyCheck === void 0 ? null : _ref$verifyCheck;
  return elements.map(function (element) {
    return _objectSpread(_objectSpread({}, element), {}, {
      value: element[value],
      label: element[label],
      sub: sub ? element[sub] : '',
      check: verifyCheck ? verifyCheck(element) : element[check] || ''
    });
  });
};
var transformDropdownElements = exports.transformDropdownElements = function transformDropdownElements(_ref2) {
  var _ref2$elements = _ref2.elements,
    elements = _ref2$elements === void 0 ? [] : _ref2$elements,
    _ref2$name = _ref2.name,
    name = _ref2$name === void 0 ? 'name' : _ref2$name,
    _ref2$label = _ref2.label,
    label = _ref2$label === void 0 ? 'label' : _ref2$label,
    _ref2$disabled = _ref2.disabled,
    disabled = _ref2$disabled === void 0 ? 'disabled' : _ref2$disabled,
    options = _ref2.options;
  return elements.map(function (element) {
    return _objectSpread(_objectSpread({}, element), {}, {
      name: element[name],
      label: element[label],
      disabled: element[disabled],
      options: element[options]
    });
  });
};
var getFieldSearch = exports.getFieldSearch = function getFieldSearch() {
  var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return "".concat(field, "_search");
};
var fieldSearch = exports.fieldSearch = getFieldSearch;
var getLastSearchField = exports.getLastSearchField = function getLastSearchField() {
  var field = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return "".concat(field, "_lastSearch");
};
var transformDisableSelectOption = exports.transformDisableSelectOption = function transformDisableSelectOption() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var optionToDisable = arguments.length > 1 ? arguments[1] : undefined;
  if (!optionToDisable) return options;
  var filteredOptions = options.map(function (option) {
    var sameField = option.uuid === optionToDisable;
    if ((0, _isArray2["default"])(optionToDisable)) {
      var disabled = optionToDisable.map(function (current) {
        return (0, _isObject2["default"])(current) ? current.uuid : current;
      }).includes(option.uuid);
      return _objectSpread(_objectSpread({}, option), {}, {
        disabled: disabled
      });
    }
    return _objectSpread(_objectSpread({}, option), {}, {
      disabled: sameField
    });
  });
  return filteredOptions;
};