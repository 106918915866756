import * as yup from 'yup';
import i18n from '@i18n';
import { setListParams } from 'store/loans/actions';
import { successPath } from '@loans/common/config';
import { YYYY_MM_DD, timeZone } from 'utils/constants';
import moment from 'moment-timezone';

export const config = {
  schema: yup.object().shape({
    folio: yup.string(),
    observations: yup.string(),
    requesterName: yup.string(),
    departureDate: yup.string(),
    departureTime: yup.string(),
    returnDate: yup.string(),
    returnTime: yup.string(),
    status: yup.string(),
  }),
  initialTouched: {
    folio: true,
    observations: true,
    requesterName: true,
    departureDate: true,
    departureTime: true,
    returnDate: true,
    returnTime: true,
    status: true,
  },
  initialValues: {
    folio: '',
    observations: '',
    requesterName: '',
    departureDate: '',
    departureTime: '',
    returnDate: '',
    returnTime: '',
    status: '',
  },
  enableReinitialize: true,
  validateOnBlur: true,
  withButtons: false,
  formatter: (values) => {
    const { departureDate, returnDate } = values;
    return {
      ...values,
      departureDate: departureDate
        ? moment(departureDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
      returnDate: returnDate
        ? moment(returnDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
    };
  },
  submitForm: setListParams,
  dialogs: {
    confirmation: {
      title: i18n('VEHICLES__MARK_FOR_SALE__MODAL__TITLE'),
      message: i18n('VEHICLES__MARK_FOR_SALE__MODAL__DESCRIPTION'),
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('VEHICLES__MARK_FOR_SALE__SUCCESS__TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: i18n('VEHICLES__MARK_FOR_SALE__SUCCESS__DESCRIPTION'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: i18n('VEHICLES__MODAL_SUCCESS__LABEL'),
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath,
    },
  },
};
