import { TabView, TabPanel } from 'primereact/tabview';
import ListOfReservationContract from './ListOfReservationContract';
import ListOfDRContracts from './ListOfDRContracts';
import {
  VIEW_ONLY_CONTRACTS_RD,
  VIEW_ONLY_RESERVATIONS_RD,
} from 'utils/roles/permissions/contractsRDPermissions';
import { validateRolePermissions } from 'utils/roles';
import { useEffect, useState } from 'react';

const DailyRentContracts = ({ history }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    if (validateRolePermissions(VIEW_ONLY_CONTRACTS_RD)) setActiveIndex(1);
  }, []);
  return (
    <TabView
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
    >
      {!validateRolePermissions(VIEW_ONLY_CONTRACTS_RD) && (
        <TabPanel header="Reservaciones">
          <ListOfReservationContract />
        </TabPanel>
      )}
      {!validateRolePermissions(VIEW_ONLY_RESERVATIONS_RD) && (
        <TabPanel header="Contratos">
          <ListOfDRContracts history={history} />
        </TabPanel>
      )}
    </TabView>
  );
};

export default DailyRentContracts;
