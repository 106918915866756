import { components } from '@casanova/casanova-common';

const { CellText } = components.ColumnsCells;

export default [
  {
    label: 'Marca',
    field: 'brand',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Línea',
    field: 'line',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Versión',
    field: 'version',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Modelo',
    field: 'model',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'No. de serie',
    field: 'serialNumber',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'No. placa',
    field: 'plateNumber',
    renderer: CellText,
    align: 'center',
  },
  {
    label: 'Estatus',
    field: 'statusName',
    renderer: CellText,
    align: 'center',
  },
];

export const VEHICLES_ACTIONS = {
  CHANGE_OF_VEHICLE: {
    label: 'Cambio de vehículo',
    name: 'changeOfVehicle',
    disabled: false,
    options: [],
    showExpanded: false,
  },
  LOAN: {
    label: 'Préstamo',
    name: 'loan',
    disabled: false,
    options: [],
    showExpanded: false,
  },
};

export const VEHICLE_CARD_ROW_FIELDS = {
  brand: { bold: false },
  line: { bold: true },
  version: { bold: false },
  model: { bold: false },
  serialNumber: { bold: true },
  plateNumber: { bold: true },
  status: { bold: false },
};
