import * as yup from 'yup';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index';

export const initialValues = {
  user: '',
  code: '',
};

export const initialTouched = {
  user: true,
  code: true,
};

export const schema = yup.object().shape({
  user: yup.string().required(REQUIRED_MESSAGE),
  code: yup.string().required(REQUIRED_MESSAGE),
});
