import * as yup from 'yup';
import 'moment/locale/es';
import { REQUIRED_MESSAGE, EMAIL_NOT_VALID } from 'utils/constants';
import i18n from '@i18n';
import { MESSAGE_ZIP_CODE } from './constants';
import { RFC } from '../../../../utils/constants/regularExpressions';

const invoiceFormSchema = {
  invoiceSocialReason: yup
    .string()
    .when(['wantInvoice', 'isAdding', 'withBillingInfo'], {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && (isAdding || !withBillingInfo),
      then: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5]))
        .test(
          'is-different-2-current',
          i18n('ERROR__RESERVATIONS__PAYMENT__SOCIAL_REASON__REPEATED'),
          function validateRepeatedSocialReasons(value) {
            if (!value) return true;

            const { invoices, invoiceSelected } = this.parent;

            if (invoiceSelected) return true;

            const equalInvoice = invoices.find(
              (invoice) => invoice.name.toLowerCase() === value.toLowerCase()
            );

            return !equalInvoice;
          }
        ),
      otherwise: yup.string(),
    }),
  invoiceRfc: yup
    .string()
    .when(['wantInvoice', 'isAdding', 'withBillingInfo'], {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && (isAdding || !withBillingInfo),
      then: yup
        .string()
        .required(REQUIRED_MESSAGE)
        .test(
          'invalid-rfc-format',
          i18n('ERROR__RESERVATIONS__PAYMENT__RFC__INVALID'),
          (value) => {
            if (!value) return true;
            return RFC.test(value);
          }
        )
        .test(
          'is-different-2-current-rfc',
          i18n('ERROR__RESERVATIONS__PAYMENT__RFC__REPEATED'),
          function validateRepeatedRfc(value) {
            if (!value) return true;

            const { invoices, invoiceSelected } = this.parent;

            if (invoiceSelected) return true;

            const equalRfc = invoices.find(
              (invoice) => invoice.rfc.toLowerCase() === value.toLowerCase()
            );

            return !equalRfc;
          }
        ),
      otherwise: yup.string(),
    }),
  invoiceCfdiUse: yup
    .string()
    .when(['wantInvoice', 'isAdding', 'withBillingInfo'], {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && (isAdding || !withBillingInfo),
      then: yup.string().required(REQUIRED_MESSAGE),
      otherwise: yup.string(),
    }),
  invoicePayForm: yup
    .string()
    .when(['wantInvoice', 'isAdding', 'withBillingInfo'], {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && (isAdding || !withBillingInfo),
      then: yup.string().required(REQUIRED_MESSAGE),
      otherwise: yup.string(),
    }),
  invoicePayMethod: yup
    .string()
    .when(['wantInvoice', 'isAdding', 'withBillingInfo'], {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && (isAdding || !withBillingInfo),
      then: yup.string().required(REQUIRED_MESSAGE),
      otherwise: yup.string(),
    }),
  emailInvoice: yup.string().when('wantInvoice', {
    is: true,
    then: yup.string().required(REQUIRED_MESSAGE).email(EMAIL_NOT_VALID),
    otherwise: yup.string(),
  }),
  observationsInvoice: yup.string(),
  invoiceSelected: yup
    .string()
    .when(['wantInvoice', 'isAdding', 'withBillingInfo'], {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && !isAdding && withBillingInfo,
      then: yup
        .string()
        .required(i18n('ERROR__RESERVATIONS__INVOICE__SELECTED')),
      otherwise: yup.string().nullable(),
    }),
  zipCode: yup.string().when(['wantInvoice', 'invoiceZipCodeExist'], {
    is: (wantInvoice, invoiceZipCodeExist) =>
      wantInvoice && invoiceZipCodeExist,
    then: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(5, 'El codigo postal debe de ser de 5 digitos')
      .max(5, 'El codigo postal debe de ser de 5 digitos')
      .test({
        name: 'invoiceZipCodeExist',
        exclusive: true,
        message: MESSAGE_ZIP_CODE,
        test: (value) => value === true,
      }),
    otherwise: yup
      .string()
      .min(5, 'El codigo postal debe de ser de 5 digitos')
      .max(5, 'El codigo postal debe de ser de 5 digitos'),
  }),
  invoiceTaxRegime: yup.string().when('wantInvoice', {
    is: true,
    then: yup.string().required(REQUIRED_MESSAGE),
    otherwise: yup.string(),
  }),
};

export default invoiceFormSchema;
