import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { ReturnStepOne, ReturnStepTwo, ReturnStepThree } from './steps';

export default function Return(props) {
  const { match, step, getReservationInfo } = props;

  const reservationUuid = useMemo(() => match.params.id, [match]);

  useEffect(() => {
    if (reservationUuid) {
      getReservationInfo({ uuid: reservationUuid, redirect: false });
    }
  }, [reservationUuid, getReservationInfo]);

  const renderComponents = [
    <ReturnStepOne {...props} />,
    <ReturnStepTwo {...props} />,
    <ReturnStepThree {...props} />,
  ];

  if (step > 3) {
    return <Redirect to="/reservations" />;
  }

  return renderComponents[step - 1];
}

Return.propTypes = {
  step: PropTypes.number.isRequired,
  getReservationInfo: PropTypes.func.isRequired,
};
