import { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getReservationInfo } from 'store/reservations/actions';
import { capitalizeFirstChar } from 'utils/masks';
import { getDiscount } from '@reservations/commons/utils';
import { IVA } from 'utils/constants/amounts';
import { PAYMENTS_TYPES } from 'utils/constants/payments';

export default function useReservation({ fetchReservationInfo = false } = {}) {
  const reservationInfo = useSelector(
    (state) => state.reservations.reservationInfo
  );
  const extraCharges = useSelector((state) => state.reservations.extraCharges);

  const [daysDifference, setDaysDifference] = useState(0);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (!reservationInfo.success && fetchReservationInfo && id)
      dispatch(getReservationInfo({ uuid: id }));
  }, [fetchReservationInfo, id]);

  const {
    priceQuote = {},
    assignedVehicle = {},
    payments = [],
  } = reservationInfo.data;

  const paymentInfo = payments.find(
    (payment) => payment.type === PAYMENTS_TYPES.RESERVATION
  );
  const { line = '', brand = '' } = assignedVehicle;
  const {
    pickupDateTime,
    returnDateTime,
    selectedVehicleLinePrice = {},
    total = 0,
  } = priceQuote;

  const { isPromocode = false, discountAmount = 0 } = getDiscount(priceQuote);
  const { vehicleLinePrice = {} } = selectedVehicleLinePrice;
  const { period = 0, price = 0 } = vehicleLinePrice;

  const amount = price / period; // This amount contains the price per day with IVA
  const IVAOfAmount = (amount * IVA) / (1 + IVA); // Real IVA;
  const rental = amount - IVAOfAmount; // This amount contains the price per day without IVA;
  const discountWord = 'DESCUENTO';
  const subtotal = 0;

  const invoiceInfo = {
    amount,
    IVAOfAmount,
    rental,
    total,
    discountWord,
    isPromocode,
    discountAmount,
    daysDifference,
    subtotal,
  };

  useEffect(() => {
    if (pickupDateTime && returnDateTime) {
      const startDate = moment(pickupDateTime, 'yyyy-MM-DD hh:mm');
      const endDate = moment(returnDateTime, 'yyyy-MM-DD hh:mm');

      const differenceD = endDate.diff(startDate, 'days');
      const differenceM = endDate.diff(startDate, 'minutes');

      const minutesToDays = differenceM / (24 * 60);
      const hasRemaining = minutesToDays - differenceD > 0;

      const daysOfDifference = hasRemaining ? differenceD + 1 : differenceD;

      setDaysDifference(daysOfDifference);
    }
  }, [pickupDateTime, returnDateTime]);

  const avoidCapitalization = ["HB20", "MG", "MG5", "KIA", "HR-V", "CR-V", "GMC"];
  let vehicleTitle = ''

  if(line == 'HAVAL JOLION'){
    vehicleTitle = 'GWM Haval Jolion'
  } else if(line == 'SILVERADO 3500 HD'){
    vehicleTitle = 'Chevrolet Silverado 3500 HD'
  } else {
    vehicleTitle = `${avoidCapitalization.includes(brand) ? brand : capitalizeFirstChar(brand.toLowerCase())} ${
      avoidCapitalization.includes(line) ? line : capitalizeFirstChar(line.toLowerCase())
    }`;
  }

  const vehicleInfo = {
    ...assignedVehicle,
    vehicleTitle,
  };

  return {
    reservationInfo: reservationInfo.data,
    vehicleInfo,
    invoiceInfo,
    paymentInfo,
    extraCharges,
  };
}
