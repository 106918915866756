import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { ConnectedSelect, Section } from 'components';
import {
  MaskedInput as Input,
  FileInput,
  Select,
} from '@casanova/casanova-common';
import {
  alphaMaskWithSpacesAndAccent,
  alphaMask,
  numericMask,
} from 'utils/masks';
import { generateSelectMonthsObject } from 'utils/date';
import { acceptFiles } from 'utils/files';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import config from './config';

const periodDates = generateSelectMonthsObject(new Date(), 3, true);

const { ADD } = FORM_ACTIONS;

const Address = ({
  action,
  getSuburbsByZipCode,
  zipCode,
  isJuridicalCustomer,
}) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const { initialZipCode } = values;

  const handleGetSuburbs = useCallback(
    (e) => {
      const { value } = e.target;
      if (value && value.length >= 5) {
        getSuburbsByZipCode(value);
        if (action === ADD) {
          setFieldValue('colony', '');
        }
      }
    },
    [getSuburbsByZipCode, setFieldValue, action]
  );

  useEffect(() => {
    setFieldValue('state', _get(zipCode, 'state.list.0.uuid', ''));
    setFieldValue(
      'municipality',
      _get(zipCode, 'municipality.list.0.uuid', '')
    );
    const suburbs = _get(zipCode, 'suburbs.list', []);

    if (suburbs && suburbs.length === 1) {
      setFieldValue('colony', _get(suburbs, '0.uuid', ''));
    }
  }, [zipCode, setFieldValue, action]);

  useEffect(() => {
    if (initialZipCode && initialZipCode.length >= 5) {
      getSuburbsByZipCode(initialZipCode);
    }
  }, [initialZipCode, getSuburbsByZipCode]);

  const handleOnChangeProofType = useCallback(
    (event) => {
      setValues({
        ...values,
        ...config.initialValues,
        addressDocumentType: event.target.value,
      });
    },
    [values, setValues]
  );

  return (
    <Section title={`DOMICILIO ${isJuridicalCustomer ? 'DE LA EMPRESA' : ''}`}>
      <div className="row">
        <div className="col-md-4">
          <ConnectedSelect
            disabled={action === 'view'}
            label="Tipo de comprobante*"
            name="addressDocumentType"
            options="commons.proofAddressTypeEntities"
            onChange={handleOnChangeProofType}
          />
        </div>
        <div className="col-4">
          <Input
            mask={alphaMaskWithSpacesAndAccent(35)}
            maskPlaceholder=""
            label="Calle*"
            placeholder="Calle*"
            name="street"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-4">
          <Input
            mask={alphaMask(10)}
            maskPlaceholder=""
            label="Número exterior*"
            placeholder="Número exterior*"
            name="outdoorNumber"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-4">
          <Input
            mask={alphaMask(10)}
            maskPlaceholder=""
            label="Número interior"
            placeholder="Número interior"
            name="indoorNumber"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-4">
          <Input
            mask={numericMask(5)}
            maskPlaceholder=""
            label="Código Postal*"
            placeholder="Código Postal*"
            name="zipCode"
            onChange={handleGetSuburbs}
            disabled={action === 'view'}
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            disabled={action === 'view'}
            label="Estado*"
            name="state"
            options="commons.zipCodeSelected.state"
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            disabled={action === 'view'}
            label="Delegación / Municipio*"
            name="municipality"
            options="commons.zipCodeSelected.municipality"
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            disabled={action === 'view'}
            label="Colonia*"
            name="colony"
            options="commons.zipCodeSelected.suburbs"
          />
        </div>
        <div className="col-md-4">
          <Select
            disabled={action === 'view'}
            label="Periodo*"
            name="period"
            options={periodDates}
          />
        </div>
        {!isJuridicalCustomer && (
          <div className="col-12 col-md-4">
            <FileInput
              url={action !== 'add' ? values.addressDocumentUrl : undefined}
              displayText={values.addressDocument}
              type="file"
              label="Comprobante de domicilio*"
              placeholder="Comprobante de domicilio*"
              name="addressDocument"
              accept={acceptFiles}
              disabled={action === 'view'}
            />
          </div>
        )}
      </div>
    </Section>
  );
};

Address.propTypes = {
  action: PropTypes.string,
};

Address.defaultProps = {
  action: 'add',
};

export default Address;
