import i18n from '@i18n';
import { Base64ToFile } from 'utils/files';
import { number2mxn } from 'utils/currency';
import { removeSpaces } from 'utils/masks';
import {
  VEHICLE_DETAILS_LABEL,
  REMARK_TYPES,
  DELIVERY_RETURN_TYPES,
  CUSTOM_EXTRA_CHARGE,
  CUSTOM_EXTRA_CHARGE_TYPES,
  GROUPS,
  ADDITIONAL_FIELDS,
} from '@reservations/commons/constants';
import {
  isGeneralGroup,
  getPrincipalFieldsGeneral,
  getCirculationFieldsGeneral,
} from '@reservations/commons/utils';
import moment from 'moment';

export const specItemProps = {
  iconStyle: {
    width: '2rem',
    height: '2rem',
  },
  textClassName: 'm-0',
  disableIconType: 2,
};

export const generalAdditionalFieldTransformer = (field, vehicleInfo = {}) => {
  const fieldName = VEHICLE_DETAILS_LABEL[field.fieldKey];
  const fieldVehicleValue = vehicleInfo[field.fieldKey];

  if (!fieldName) {
    return {
      ...field,
      fieldValue: {
        ...field.fieldValue,
        label: field.fieldValue.label,
        labelValue: fieldVehicleValue,
      },
    };
  }
  return {
    ...field,
    fieldValue: {
      ...field.fieldValue,
      label: fieldName,
      labelValue: fieldVehicleValue,
    },
  };
};

export const generalOtherFieldsTransformer = (field, vehicleInfo = {}) => {
  const fieldVehicleValue = vehicleInfo[field.fieldKey];
  return {
    ...field,
    fieldValue: {
      ...field.fieldValue,
      label: field.fieldValue.label,
      labelValue: fieldVehicleValue,
    },
  };
};

export const addOverflowHiddenInContainer = (type = 'add') => {
  const container = document.getElementsByClassName('main-content')[0];
  if (container) {
    if (type === 'add') {
      container.className += ' overflow-hidden';
    } else {
      container.className -= ' overflow-hidden';
    }
  }
};

export const getFistImageOfGroupTransformer = (group = {}) => {
  if (group) {
    const { verificationPoints = [] } = group;
    const firstPoint = verificationPoints[0];
    if (firstPoint) {
      const { images = [] } = firstPoint;
      const firstImage = images[0];
      if (firstImage) {
        const { presignedUrl = '' } = firstImage;
        return presignedUrl;
      }
      return '';
    }
  }
  return '';
};

export const isSetedGroup = (group = {}) => {
  let isSeted = true;

  group.verificationPoints.forEach(({ isSet = false }) => {
    if (!isSet) isSeted = false;
  });

  return isSeted;
};

export const dataToSubmitVerifyPointTransformer = (generalGroup) => ({
  customRemark = {},
  uuid,
  additionalFields = {},
  listOfImages = [],
  actualImage = '',
  images = [],
  selectedExtraCharges = [],
  imagesRequired = false,
  customExtraCharges = [],
}) => {
  const withOriginalImages = images.length > 0;
  const listOfImagesAndActualImage =
    listOfImages.length > 0 ? listOfImages : [actualImage];

  const extraCharges = selectedExtraCharges.filter((extraCharge) => {
    const customExtraCharge = customExtraCharges.find((customCharge) => {
      const formatedUuid = removeSpaces(customCharge.uuid).toLowerCase();
      const formatedExtraCharge = removeSpaces(extraCharge).toLowerCase();
      return formatedUuid === formatedExtraCharge;
    });

    return extraCharge !== CUSTOM_EXTRA_CHARGE.uuid && !customExtraCharge;
  });

  const formatedCustomExtraCharges = customExtraCharges.map(
    ({ name = '', price = 1 }) => ({ name, price })
  );

  let dataSubmit = {};

  const checkPointData = {
    remarks: customRemark.remark,
    extraCharges,
    customExtraCharges: formatedCustomExtraCharges,
  };

  if (generalGroup) {
    const principalAditionalFields = getPrincipalFieldsGeneral(
      additionalFields
    );
    const circulationAditionalFields = getCirculationFieldsGeneral(
      additionalFields
    );

    const allAditionalFields = principalAditionalFields.concat(
      circulationAditionalFields
    );

    const passed = principalAditionalFields.some(
      ({ fieldValue }) => !fieldValue.value
    );

    const formaterAdditionalFields = allAditionalFields.reduce(
      (acc, { fieldValue, fieldKey }) => {
        acc[fieldKey] = { value: fieldValue.value, label: fieldValue.label };
        return acc;
      },
      {}
    );

    const checkPointBody = {
      ...checkPointData,
      passed,
      additionalFields: formaterAdditionalFields,
    };

    dataSubmit = {
      checkPointBody,
      uuid,
    };
  } else {
    const formatedAditionalFields = Object.keys(additionalFields).reduce(
      (acc, key) => {
        const field = additionalFields[key];
        const isNumber = field.type === 'number';
        const stringWithMask =
          field.value.includes !== undefined && field.value.includes(',');
        const value = isNumber
          ? Number(
              stringWithMask ? field.value.replaceAll(',', '') : field.value
            )
          : field.value;

        acc[key] = { value };

        return acc;
      },
      {}
    );

    const validImages =
      !withOriginalImages &&
      imagesRequired &&
      listOfImagesAndActualImage.length > 0;

    const listOfFileImages = validImages
      ? listOfImagesAndActualImage.map((image, i) =>
          image
            ? { file: Base64ToFile(image), name: `${uuid}_${i + 1}_IMG.jpeg` }
            : {}
        )
      : [];

    const checkPointBody = {
      ...checkPointData,
      additionalFields: formatedAditionalFields,
      passed: Boolean(customRemark.type === REMARK_TYPES.GOOD_STATE),
    };

    dataSubmit = {
      checkPointBody,
      listOfImages: listOfFileImages,
      uuid,
    };

    if (!imagesRequired || withOriginalImages) delete dataSubmit.listOfImages;
  }

  return dataSubmit;
};

export const isImageSet = ({ images = [] }) => images.length > 0;

export const isCheckPointSet = ({
  images = [],
  infoSet = false,
  position = 0,
}) => {
  let isSet = false;
  if (isGeneralGroup({ position })) {
    isSet = infoSet;
  } else {
    isSet = isImageSet({ images }) && infoSet;
  }
  return isSet;
};

export const getAdditionalFieldsValue = ({
  type: checkListType = DELIVERY_RETURN_TYPES.DELIVERY,
  verificationChecklists = [],
  groupUuid = '',
  groupPosition = 0,
  checkPointUuid = '',
}) => {
  const findCheckList = ({ uuid, type, createdDate }) => {
    const hasSame = type === checkListType;

    const isLastDate =
      verificationChecklists.filter((checkList) => {
        const isBefore = moment(checkList.createdDate).isAfter(createdDate);
        return checkList.type === type && uuid !== checkList.uuid && isBefore;
      }).length === 0;

    return Boolean(hasSame && isLastDate);
  };

  if (groupUuid || groupPosition || checkPointUuid) {
    const { verificationPoints = [] } =
      verificationChecklists
        .filter(findCheckList)
        .reduce((_, current) => current.verificationGroups, [])
        .find((group) =>
          groupUuid
            ? group.uuid === groupUuid
            : group.position === groupPosition
        ) || {};

    const additionalFields = verificationPoints.reduce(
      (_, current) => current.additionalFields,
      {}
    );
    return additionalFields || {};
  }
  return {};
};

export const remarkFieldName = ({ uuid = '' }) => `remark_${uuid}`;

export const additionalFieldName = ({ fieldUuid, fieldKey }) =>
  `additionalField_${fieldUuid}_${fieldKey}`;

export const extraChargeFieldName = ({
  uuid,
  customExtraCharge = false,
  customExtraCharges = [],
  customExtraChargeType = CUSTOM_EXTRA_CHARGE_TYPES.CUSTOM_EXTRA_CHARGE_AMOUNT,
}) => {
  if (customExtraCharge) {
    const id = customExtraCharges.length;
    return `${CUSTOM_EXTRA_CHARGE_TYPES.BASE}${customExtraChargeType}_${id}`;
  }
  return `extra-charge_${uuid}`;
};

export const extraChargeName = ({
  name,
  price = null,
  onlyName = false,
  onlyPrice = false,
}) => {
  if (!name) return '';

  if (onlyName) return `${name}`;

  const formatedPrice = number2mxn(Number(price));
  if (onlyPrice) return `${formatedPrice}`;

  return `${name}${price ? `  ${formatedPrice}` : ''}`;
};

export const extraChargeLabel = ({ isCustom = false }) =>
  i18n('RESERVATIONS__REFUND__VEHICLE_PARTS__EXTRA_CHARGE__LABEL', [
    isCustom ? `${i18n('COMMONS__OTHER')} ` : '',
  ]);

const searchFieldValue = ({ fieldValue }) => fieldValue.value;

export const verifyCompletedInfoPoint = (activeCheckList, checked) => (
  checkPoint
) => {
  const {
    images = [],
    listOfImages = [],
    actualImage = '',
    infoSet = false,
    imageSet = false,
    additionalFields = {},
    customRemark = {
      remark: '',
      type: null,
    },
    name = '',
    selectedExtraCharges = [],
  } = checkPoint;

  const generalGroup = isGeneralGroup({ ...checkPoint, type: 'CHECK' });
  const boardPoint = name === GROUPS.TABLERO.name;

  let validAdditionalFields = !boardPoint || !generalGroup;

  if (generalGroup) {
    const circulationField = getCirculationFieldsGeneral(additionalFields).some(
      searchFieldValue
    );
    const principalFields = getPrincipalFieldsGeneral(additionalFields).some(
      searchFieldValue
    );
    validAdditionalFields = circulationField && principalFields;
  } else if (boardPoint) {
    const completedFields = Object.entries(additionalFields).filter(
      (array) => Number(array[1].value) >= 0
    );

    validAdditionalFields =
      completedFields.length === Object.entries(additionalFields).length;
  }

  const validImages =
    images.length < 1 && (listOfImages.length > 0 || actualImage)
      ? true
      : images.length > 0 ||
        imageSet ||
        listOfImages.length > 0 ||
        actualImage ||
        generalGroup;
  const isDetail = customRemark.type === REMARK_TYPES.DETAIL;

  const validRemark = isDetail
    ? customRemark.type && customRemark.remark.length > 5
    : customRemark.type;

  const validInfo = infoSet || validRemark;

  const isReturn = activeCheckList.type === DELIVERY_RETURN_TYPES.RETURN;

  // const withExtraCharges =
  //   isDetail && isReturn ? selectedExtraCharges.length > 0 : true;

  const withExtraCharges =
    isDetail && isReturn && checked ? selectedExtraCharges.length > 0 : true;

  const validGroup =
    validImages && validInfo && validAdditionalFields && withExtraCharges;
  const validGeneralGroup = validAdditionalFields;

  return generalGroup ? validGeneralGroup : validGroup;
};

export const getBoardFields = (fields, selectedVerifyPoint) => {
  const boardFields = Object.keys(fields)
    .map((fieldKey) => {
      const name = additionalFieldName({
        fieldUuid: selectedVerifyPoint.uuid,
        fieldKey,
      });
      return { name, fieldKey };
    })
    .filter(
      ({ fieldKey }) =>
        fieldKey === ADDITIONAL_FIELDS.GAS || fieldKey === ADDITIONAL_FIELDS.KM
    );

  return boardFields;
};
