/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import _noop from 'lodash/noop';

import { MaskedInput as Input, Icons } from '@casanova/casanova-common';
import {
  transformIdOfList,
  getFieldSearch,
} from '@casanova/casanova-common/lib/utils/transformHelpers';

import './SercheableSelect.scss';

const SercheableSelect = (props) => {
  const {
    initialValue,
    disabled,
    name,
    onChange,
    onSearching,
    placeholder,
    mask,
    icon,
    noResultsLabel,
    options = [],
    infoText,
    remove,
    onRemove,
    autoComplete = 'off',
    showRightIcon = true,
  } = props;

  const [open, setOpen] = useStateWithCallbackLazy(false);
  const [elementSelected, setElementSelected] = useState();
  const {
    setFieldValue,
    setFieldTouched,
    values = {},
    initialValues = {},
  } = useFormikContext();
  const inputRef = useRef();

  const searchField = getFieldSearch(name);
  const lasSearchField = `${name}_lastSearch`;

  const handleSelection = useCallback(
    ({ value, label }, disabled) => () => {
      if (disabled) return;

      setFieldValue(name, value);
      setFieldValue(searchField, label);
      setFieldValue(`${name}_lastSearch`, label);

      setElementSelected(value);
      if (onChange) onChange(value);

      setOpen(false);
    },
    [onChange, name, setFieldValue, searchField]
  );

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleOpen = useCallback(() => {
    setOpen(true, () => setFieldTouched(searchField, false));
  }, [setFieldTouched, searchField]);

  const handleRemove = useCallback(() => {
    setFieldValue(name, initialValues[name] || '');
    setFieldValue(searchField, initialValues[searchField] || '');
    setFieldValue(lasSearchField, initialValues[lasSearchField] || '');

    onRemove();
  }, [
    setFieldValue,
    name,
    searchField,
    lasSearchField,
    initialValues,
    onRemove,
  ]);

  useEffect(() => {
    if (initialValue) {
      setFieldValue(searchField, initialValue);
    }
  }, [initialValue, name, setFieldValue]);

  const handleBlur = useCallback(
    (e) => {
      setTimeout(() => {
        const target = document.activeElement;
        const focusedElement = target
          ? target.id || target || target.tagName
          : '';
        const isFocusedCalendar =
          focusedElement.className.indexOf(
            'SercheableSelect__options--open'
          ) !== -1;
        setOpen(isFocusedCalendar, () => {
          if (props.onBlur) props.onBlur(e);
        });
      }, 1);
    },
    [props]
  );

  const handleOnChange = useCallback(
    (e) => {
      if (onSearching) {
        const { value } = e.target;
        onSearching(value);
      }
    },
    [onSearching]
  );

  // Starts classNames block
  const containerCN = classNames('SercheableSelect__input', {
    'SercheableSelect__input--open': open,
  });
  const calendarCN = classNames(`SercheableSelect__options`, {
    'SercheableSelect__options--open': open,
  });
  const iconOpen = open ? 'svg-icon-chevron-up' : 'svg-icon-chevron-down';
  const iconCn = classNames(`svg-icon`, icon || iconOpen);
  const withoutResultsLabel = noResultsLabel || 'No hay resultados';

  return (
    <div className="SercheableSelect">
      <div className={containerCN}>
        <Input
          ref={inputRef}
          label={props.label}
          type="text"
          mask={mask}
          placeholder={props.placeholder}
          name={`${props.name}_search`}
          id={props.id}
          maskPlaceholder=""
          disabled={disabled}
          onBlur={handleBlur}
          onChange={handleOnChange}
          infoText={!open && infoText}
          right={
            <div
              className={classNames(
                remove && values[name] && 'd-flex rightContent',
                !showRightIcon && 'm-0'
              )}
            >
              {remove && values[name] && (
                <Icons.IcoDeleteGray
                  width="1.5rem"
                  height="1.5rem"
                  onClick={handleRemove}
                  className={classNames(!showRightIcon && 'm-0')}
                />
              )}
              {showRightIcon && (
                <div
                  onClick={() => (disabled ? {} : toggleOpen())}
                  onKeyPress={() => (disabled ? {} : toggleOpen())}
                  role="button"
                  tabIndex="0"
                  className={iconCn}
                />
              )}
            </div>
          }
          onFocus={handleOpen}
          autoComplete={autoComplete}
        />
      </div>
      <div
        tabIndex={0}
        role="button"
        onBlur={(e) => {
          setOpen(false, () => {
            if (props.onBlur) props.onBlur(e);
          });
        }}
        className={calendarCN}
      >
        <label className="col-form-label">{placeholder}</label>
        {options &&
          transformIdOfList(options).map(({ value, label, disabled, id }) => (
            <div
              key={id}
              onClick={handleSelection({ value, label }, disabled)}
              className={classNames(
                `SercheableSelect__options__element ${
                  disabled ? '__element-disabled' : ''
                }`,
                {
                  'SercheableSelect__options__element--selected':
                    value === elementSelected,
                }
              )}
            >
              {label}
            </div>
          ))}
        {(!options || (options && options.length === 0)) && (
          <div
            className={classNames(
              'SercheableSelect__options__element',
              '__element-disabled'
            )}
          >
            {withoutResultsLabel}
          </div>
        )}
      </div>
    </div>
  );
};

SercheableSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  remove: PropTypes.bool,
  onRemove: PropTypes.func,
  autoComplete: PropTypes.string,
  showRightIcon: PropTypes.bool,
};

SercheableSelect.defaultProps = {
  label: '',
  name: '',
  placeholder: '',
  remove: false,
  onRemove: _noop,
};

export default SercheableSelect;
