export const initialTouched = {
  passportKey: true,
  passportYearIssue: true,
  passportValidity: true,
  passportCountry: true,
  passportCoverFront: true,
  passportCoverBack: true,
};

const initialValues = {
  passportKey: '',
  passportYearIssue: '',
  passportValidity: '',
  passportCountry: '',
  passportCoverFront: '',
  passportCoverBack: '',
};

export default {
  initialTouched,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
};

export const FIELDS = Object.keys(initialValues);
