import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  getVehicleTypes,
  getTimeLimits,
  closeResponseDialog,
  getMostQuoted,
  setTitles,
  setEndAvailableHours,
  setVehicleCategory,
  setInProgressQuote,
  resetReservations,
} from 'store/reservations/actions';
import { search, reset } from 'store/customers/actions';
import { fetchCatalog } from 'store/commons/actions';
import _get from 'lodash/get';
import One from './One';

export default compose(
  connect(
    (state) => {
      // console.log(_get(state, 'customers.basicInfo', ''))
      return {
        loader: _get(state, 'commons.loader', false),
        quote: _get(state, 'reservations.quote', {}),
        responseDialog: _get(state, 'reservations.responseDialog', {}),
        customersSearch: _get(state, 'customers.basicInfo.list', []),
        categories: _get(state, 'reservations.filters.categories', []),
        quoteValues: _get(state, 'reservations.quote.values', {}),
        availableTime: _get(state, 'reservations.quote.availableTime.list', []),
        selectedCategory: _get(
          state,
          'reservations.filters.selectedCategory.value',
          ''
        ),
        leftCol: 4,
        middleCol: 4,
        confirmationMessage: false,
        mostQuoted: _get(state, 'reservations.quote.mostQuoted.list', []),
        leaveStepOne: _get(state, 'reservations.quote.leaveStepOne', false),
        vehicleCategory: _get(state, 'reservations.quote.vehicleCategory', null),
        inProgress: _get(state, 'reservations.quote.inProgress', false),
      }
    },
    {
      fetchCatalog,
      closeResponseDialog,
      search,
      getVehicleTypes,
      getTimeLimits,
      getMostQuoted,
      setTitles,
      setEndAvailableHours,
      resetCustomers: reset,
      setVehicleCategory,
      setInProgressQuote,
      resetReservations,
    }
  )
)(One);
