import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/es';
import { generateDateFromValidity } from 'utils/date';
import i18n from '@i18n';

moment.locale('es');

export const validityCardValidation = Yup.string()
  .test(
    'valid-validity-card',
    i18n('ERROR__COMMONS__CARD__VALIDITY'),
    (value) => {
      if (!value) {
        return true;
      }

      const elements = value.split('/');
      const month = parseInt(elements[0], 10);
      const year = parseInt(elements[1], 10) + 2000;

      if (month < 1 || month > 12) return false;

      const today = new Date();
      const realMonth = today.getMonth() + 1;
      if (year < today.getFullYear()) return false;
      if (year === today.getFullYear() && month < realMonth) return false;

      return month <= 12;
    }
  )
  .test(
    'less-than-five-years',
    i18n('ERROR__COMMONS__CARD__VALIDITY_YEAR'),
    (value) => {
      if (!value) {
        return true;
      }

      const date = moment(generateDateFromValidity(value));
      const today = new Date();
      return date.diff(today, 'years') <= 15;
    }
  );
