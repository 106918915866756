import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import deleteIcon from './delete/group-copy-4.png';
import './index.scss';
import { clearOneSelectGPS } from '../../store/catalogs/actions';

const Tab = (props) => {
  const dispath = useDispatch();
  const { values, setValues, errors } = useFormikContext(); // formikProps
  const onClick = () => {
    if (props.editable && Object.keys(errors).length > 0) return null;
    const { label, onClick } = props;
    onClick(label);
  };
  const exctractIndex = (data) =>
    values[props.name].map((e) => e.label).indexOf(data);

  const replaceValues = (values, sectionLabel) => {
    const { name, nameTab } = props;
    const index = values[props.name].length - 1;
    if (values[props.name].length === 3) {
      console.log('Hay 3', sectionLabel);
    } else if (values[props.name].length === 2) {
      if (sectionLabel === `${nameTab.toUpperCase()} 3`) {
        values[props.name][index] = {
          ...values[props.name][index],
          label: `${nameTab.toUpperCase()} 2`,
        };
        props.setActiveTab(`${nameTab.toUpperCase()} 1`);
      } else if (sectionLabel === `${nameTab.toUpperCase()} 2`) {
        values[props.name][index] = {
          ...values[name][index],
          label: `${nameTab.toUpperCase()} 2`,
        };
        props.setActiveTab(`${nameTab.toUpperCase()} 2`);
      } else {
        values[props.name][0] = {
          ...values[name][0],
          label: `${nameTab.toUpperCase()} 1`,
        };
        values[props.name][1] = {
          ...values[name][1],
          label: `${nameTab.toUpperCase()} 2`,
        };
        props.setActiveTab(`${nameTab.toUpperCase()} 2`);
      }
    } else if (sectionLabel === `${nameTab.toUpperCase()} 3`) {
      values[props.name][index] = {
        ...values[props.name][index],
        label: `${nameTab.toUpperCase()} 2`,
      };
      props.setActiveTab(`${nameTab.toUpperCase()} 2`);
    } else if (sectionLabel === `${nameTab.toUpperCase()} 2`) {
      if (values[props.name][index].label === `${nameTab.toUpperCase()} 1`) {
        values[props.name][index] = {
          ...values[name][index],
          label: `${nameTab.toUpperCase()} 1`,
        };
        props.setActiveTab(`${nameTab.toUpperCase()} 1`);
        return;
      }
      values[props.name][index] = {
        ...values[name][index],
        label: `${nameTab.toUpperCase()} 2`,
      };
      props.setActiveTab(`${nameTab.toUpperCase()} 2`);
    } else {
      values[props.name][index] = {
        ...values[name][index],
        label: `${nameTab.toUpperCase()} 1`,
      };
      props.setActiveTab(`${nameTab.toUpperCase()} 1`);
    }
  };
  const onDeleteSection = (sectionLabel) => {
    const index = exctractIndex(sectionLabel);
    if (values.gps[index].tagGPS) {
      dispath(clearOneSelectGPS(values.gps[index].tagGPS));
    }
    values[props.name].splice(index, 1);
    replaceValues(values, sectionLabel);
    setValues(values);
  };

  let className = 'tab-list-item';

  if (values[props.name].length === 3) {
    if (exctractIndex(props.activeTab) === 1) {
      className += ' delete-borders--1';
    } else if (exctractIndex(props.activeTab) === 2) {
      className += ' delete-borders--1';
    }
  } else if (values[props.name].length === 2) {
    if (exctractIndex(props.activeTab) === 1) {
      className += ' delete-borders--1';
    }
  }

  if (props.activeTab === props.label) {
    className += ' tab-list-active';
  }

  return (
    <li className={className}>
      <div className="click-tab" onClick={onClick}>
        {props.label}
      </div>
      <div className={`icon-tab ${props.deleteTabs && 'd-none'}`}>
        <img
          onClick={() => onDeleteSection(props.label)}
          src={deleteIcon}
          alt="delete icon"
        />
      </div>
    </li>
  );
};

Tab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Tab;
