import { sendCustomRequest } from '@casanova/casanova-common/lib/utils/service';
import { APIError } from 'utils/exceptions';
import { customerRoot, priceQuoteRootV1 } from '../roots';
import qs from 'qs';
import { cleanParams } from '@casanova/casanova-common/lib/utils/requests';

export async function getContractDetails(contractNumber) {
  const url = `${priceQuoteRootV1}/contracts/${contractNumber}/details`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function cancelMovementService(params) {
  const queryParams = `?${qs.stringify(cleanParams(params))}`;
  const url = `${customerRoot}/billing-details/movement/cancel${queryParams}`;
  const response = await sendCustomRequest(url, {}, undefined, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  return response;
}
