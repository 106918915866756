import * as Yup from 'yup';
import moment from 'moment-timezone';
import 'moment/locale/es';
import {
  getDateByTime,
  isToday,
} from '@casanova/casanova-common/lib/utils/date';
import { validityCardValidation } from 'utils/validations/cards';
import {
  REQUIRED_MESSAGE,
  FORMAT_TYPES_ALLOWED_MESSAGE,
  ALLOWED_FORMATS,
  EMAIL_NOT_VALID,
} from 'utils/constants';
import {
  dateValidation,
  fileTypeValidation,
  hourValidation,
} from 'utils/validations/common';
import i18n from '@i18n';
import { MESSAGE_ZIP_CODE } from '../sections/InvoicePayment/constants';
import { RFC } from '../../../utils/constants/regularExpressions';

export const cardFormValidations = () =>
  [0, 1, 2].reduce((acc, index) => {
    acc = {
      ...acc,
      [`cardType_${index}`]: Yup.string().when('methodSelected', {
        is: (methodSelected) => methodSelected === `card_${index}`,
        then: Yup.string().required(REQUIRED_MESSAGE),
        otherwise: Yup.string(),
      }),
      [`bank_${index}`]: Yup.string().when('methodSelected', {
        is: (methodSelected) => methodSelected === `card_${index}`,
        then: Yup.string().required(REQUIRED_MESSAGE),
        otherwise: Yup.string(),
      }),
      [`cardNumber_${index}`]: Yup.string().when('methodSelected', {
        is: (methodSelected) => methodSelected === `card_${index}`,
        then: Yup.string()
          .required(REQUIRED_MESSAGE)
          .min(15, i18n('ERROR__COMMONS__MIN_LENGTH', [15])),
        otherwise: Yup.string(),
      }),
      [`reference_${index}`]: Yup.string().when('methodSelected', {
        is: (methodSelected) => methodSelected === `card_${index}`,
        then: Yup.string()
          .required(REQUIRED_MESSAGE)
          .min(6, i18n('ERROR__COMMONS__MIN_LENGTH', [6])),
        otherwise: Yup.string(),
      }),
      [`authorization_${index}`]: Yup.string().when('methodSelected', {
        is: (methodSelected) => methodSelected === `card_${index}`,
        then: Yup.string()
          .required(REQUIRED_MESSAGE)
          .min(6, i18n('ERROR__COMMONS__MIN_LENGTH', [6])),
        otherwise: Yup.string(),
      }),
      [`movementDate_${index}`]: Yup.string().when('methodSelected', {
        is: (methodSelected) => methodSelected === `card_${index}`,
        then: dateValidation
          .required(REQUIRED_MESSAGE)
          .test(
            'max-10-days-ago',
            i18n('ERROR__RESERVATIONS__MOVEMENT_DAY__TEN_DAYS'),
            (value) => {
              if (!value) {
                return true;
              }
              const date = moment(value, 'YYYY-MM-DD').toDate();
              const today = moment();
              const difference = today.diff(date, 'days', true);
              return difference <= 10;
            }
          ),
        otherwise: Yup.string(),
      }),
      [`movementHour_${index}`]: Yup.string(),
      //   .when('methodSelected', {
      //   is: (methodSelected) => methodSelected === 'card',
      //   then: hourValidation
      //     .required(REQUIRED_MESSAGE)
      //     .test(
      //       'more-than-current-time',
      //       'Debe ser menor a la hora actual',
      //       function (movementHour) {
      //         const { movementDate } = this.parent;

      //         if (!movementDate || !movementHour) return true;

      //         if (!isToday(movementDate)) return true;

      //         const movementDay = moment(movementDate).day();
      //         const currentDate = moment();
      //         const currentDay = currentDate.day();

      //         if (movementDay === currentDay && movementHour) {
      //           return getDateByTime(movementHour) < currentDate;
      //         }

      //         return true;
      //       }
      //     ),
      //   otherwise: Yup.string(),
      // }),
      [`voucher_${index}`]: Yup.mixed().when('methodSelected', {
        is: (methodSelected) => methodSelected === `card_${index}`,
        then: Yup.mixed()
          .required(REQUIRED_MESSAGE)
          .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
            fileTypeValidation(file, ALLOWED_FORMATS)
          ),
        otherwise: Yup.mixed(),
      }),
      [`accountStatus_${index}`]: Yup.mixed().when('methodSelected', {
        is: (methodSelected) => methodSelected === `card_${index}`,
        then: Yup.mixed().when('hasHolderName', {
          is: false,
          then: Yup.mixed()
            .required(REQUIRED_MESSAGE)
            .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
              fileTypeValidation(file, ALLOWED_FORMATS)
            ),
          otherwise: Yup.mixed(),
        }),
        otherwise: Yup.mixed(),
      }),
      [`amountCard_${index}`]: Yup.mixed().when('methodSelected', {
        is: (methodSelected) => methodSelected === `card_${index}`,
        then: Yup.mixed().when('diffMetods', {
          is: (diffMetods) => diffMetods === true,
          then: Yup.mixed()
            .required(REQUIRED_MESSAGE)
            .test(
              'invalidPrice',
              'La suma de los montos no es igual al total de la reservación',
              function (value) {
                if (!value) {
                  return true;
                }
                const validPrice = this.parent.priceIsInvalid;
                return validPrice === true;
              }
            ),
          otherwise: Yup.mixed(),
        }),
        otherwise: Yup.mixed(),
      }),
    };
    return acc;
  }, {});

export const bankFormValidations = {
  bankAuthorization: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'deposit',
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(6, i18n('ERROR__COMMONS__MIN_LENGTH', [6])),
    otherwise: Yup.string(),
  }),
  bankDate: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'deposit',
    then: dateValidation
      .required(REQUIRED_MESSAGE)
      .test(
        'max-10-days-ago',
        i18n('ERROR__RESERVATIONS__MOVEMENT_DAY__TEN_DAYS'),
        (value) => {
          if (!value) {
            return true;
          }
          const date = moment(value, 'YYYY-MM-DD').toDate();
          const today = moment();
          const difference = today.diff(date, 'days', true);
          return difference <= 10;
        }
      ),
    otherwise: Yup.string(),
  }),
  bankHour: Yup.string(),
  //   .when('methodSelected', {
  //   is: (methodSelected) => methodSelected === 'deposit',
  //   then: hourValidation.required(REQUIRED_MESSAGE),
  //   otherwise: Yup.string(),
  // }),
  bankVoucher: Yup.mixed().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'deposit',
    then: Yup.mixed()
      .required(REQUIRED_MESSAGE)
      .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
        fileTypeValidation(file, ALLOWED_FORMATS)
      ),
    otherwise: Yup.mixed(),
  }),
  amountDeposit: Yup.mixed().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'deposit',
    then: Yup.mixed().when('diffMetods', {
      is: (diffMetods) => diffMetods === true,
      then: Yup.mixed()
        .required(REQUIRED_MESSAGE)
        .test(
          'invalidPrice',
          'La suma de los montos no es igual al total de la reservación',
          function (value) {
            if (!value) {
              return true;
            }
            const validPrice = this.parent.priceIsInvalid;
            return validPrice === true;
          }
        ),
      otherwise: Yup.mixed(),
    }),
    otherwise: Yup.mixed(),
  }),
};

export const transferFormValidations = {
  bankTo: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
  bankClave: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(20, i18n('ERROR__COMMONS__MIN_LENGTH', [20])),
    otherwise: Yup.string(),
  }),
  bankReference: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(6, i18n('ERROR__COMMONS__MIN_LENGTH', [6])),
    otherwise: Yup.string(),
  }),
  transferDate: Yup.string().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: dateValidation
      .required(REQUIRED_MESSAGE)
      .test(
        'max-10-days-ago',
        i18n('ERROR__RESERVATIONS__MOVEMENT_DAY__TEN_DAYS'),
        (value) => {
          if (!value) {
            return true;
          }
          const date = moment(value, 'YYYY-MM-DD').toDate();
          const today = moment();
          const difference = today.diff(date, 'days', true);
          return difference <= 10;
        }
      ),
    otherwise: Yup.string(),
  }),
  transferHour: Yup.string(),
  //   .when('methodSelected', {
  //   is: (methodSelected) => methodSelected === 'transfer',
  //   then: hourValidation.required(REQUIRED_MESSAGE),
  //   otherwise: Yup.string(),
  // }),
  transferVoucher: Yup.mixed().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: Yup.mixed()
      .required(REQUIRED_MESSAGE)
      .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
        fileTypeValidation(file, ALLOWED_FORMATS)
      ),
    otherwise: Yup.mixed(),
  }),
  amountTransfer: Yup.mixed().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'transfer',
    then: Yup.mixed().when('diffMetods', {
      is: (diffMetods) => diffMetods === true,
      then: Yup.mixed()
        .required(REQUIRED_MESSAGE)
        .test(
          'invalidPrice',
          'La suma de los montos no es igual al total de la reservación',
          function (value) {
            if (!value) {
              return true;
            }
            const validPrice = this.parent.priceIsInvalid;
            return validPrice === true;
          }
        ),
      otherwise: Yup.mixed(),
    }),
    otherwise: Yup.mixed(),
  }),
};

export const cashFormValidations = {
  amountCash: Yup.mixed().when('methodSelected', {
    is: (methodSelected) => methodSelected === 'cash',
    then: Yup.mixed().when('diffMetods', {
      is: (diffMetods) => diffMetods === true,
      then: Yup.mixed()
        .required(REQUIRED_MESSAGE)
        .test(
          'invalidPrice',
          'La suma de los montos no es igual al total de la reservación',
          function (value) {
            if (!value) {
              return true;
            }
            const validPrice = this.parent.priceIsInvalid;
            return validPrice === true;
          }
        ),
      otherwise: Yup.mixed(),
    }),
    otherwise: Yup.mixed(),
  }),
};

export const holdbackFormValidations = {
  // amount with held
  holdbackRetention: Yup.string().required(REQUIRED_MESSAGE),
  holdbackOtherRetention: Yup.string().when('holdbackRetention', {
    is: (retention) => retention === 'otro',
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
  holdbackCardHolder: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3])),
  holdbackBank: Yup.string().required(REQUIRED_MESSAGE),
  holdbackCard: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(13, i18n('ERROR__COMMONS__MIN_LENGTH', [13])),
  holdbackValidity: validityCardValidation.required(REQUIRED_MESSAGE),
  holdbackCode: Yup.string(),
};

export const invoiceFormValidation = {
  invoiceSocialReason: Yup.string().when(
    ['wantInvoice', 'isAdding', 'withBillingInfo'],
    {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && (isAdding || !withBillingInfo),
      then: Yup.string()
        .required(REQUIRED_MESSAGE)
        .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5]))
        .test(
          'is-different-2-current',
          i18n('ERROR__RESERVATIONS__PAYMENT__SOCIAL_REASON__REPEATED'),
          function validateRepeatedSocialReasons(value) {
            if (!value) return true;

            const { invoices, invoiceSelected } = this.parent;

            if (invoiceSelected) return true;

            const equalInvoice = invoices.find(
              (invoice) => invoice.name.toLowerCase() === value.toLowerCase()
            );

            return !equalInvoice;
          }
        ),
      otherwise: Yup.string(),
    }
  ),
  invoiceRfc: Yup.string().when(
    ['wantInvoice', 'isAdding', 'withBillingInfo'],
    {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && (isAdding || !withBillingInfo),
      then: Yup.string()
        .required(REQUIRED_MESSAGE)
        .test(
          'invalid-rfc-format',
          i18n('ERROR__RESERVATIONS__PAYMENT__RFC__INVALID'),
          (value) => {
            if (!value) return true;
            return RFC.test(value);
          }
        )
        .test(
          'is-different-2-current-rfc',
          i18n('ERROR__RESERVATIONS__PAYMENT__RFC__REPEATED'),
          function validateRepeatedRfc(value) {
            if (!value) return true;

            const { invoices, invoiceSelected } = this.parent;

            if (invoiceSelected) return true;

            const equalRfc = invoices.find(
              (invoice) => invoice.rfc.toLowerCase() === value.toLowerCase()
            );

            return !equalRfc;
          }
        ),
      otherwise: Yup.string(),
    }
  ),
  invoiceCfdiUse: Yup.string().when(
    ['wantInvoice', 'isAdding', 'withBillingInfo'],
    {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && (isAdding || !withBillingInfo),
      then: Yup.string().required(REQUIRED_MESSAGE),
      otherwise: Yup.string(),
    }
  ),
  invoicePayForm: Yup.string().when(
    ['wantInvoice', 'isAdding', 'withBillingInfo'],
    {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && (isAdding || !withBillingInfo),
      then: Yup.string().required(REQUIRED_MESSAGE),
      otherwise: Yup.string(),
    }
  ),
  invoicePayMethod: Yup.string().when(
    ['wantInvoice', 'isAdding', 'withBillingInfo'],
    {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && (isAdding || !withBillingInfo),
      then: Yup.string().required(REQUIRED_MESSAGE),
      otherwise: Yup.string(),
    }
  ),
  emailInvoice: Yup.string().when('wantInvoice', {
    is: true,
    then: Yup.string().required(REQUIRED_MESSAGE).email(EMAIL_NOT_VALID),
    otherwise: Yup.string(),
  }),
  observationsInvoice: Yup.string(),
  invoiceSelected: Yup.string().when(
    ['wantInvoice', 'isAdding', 'withBillingInfo'],
    {
      is: (wantInvoice, isAdding, withBillingInfo) =>
        wantInvoice && !isAdding && withBillingInfo,
      then: Yup.string().required(
        i18n('ERROR__RESERVATIONS__INVOICE__SELECTED')
      ),
      otherwise: Yup.string().nullable(),
    }
  ),
  zipCode: Yup.string().when(['wantInvoice', 'invoiceZipCodeExist'], {
    is: (wantInvoice, invoiceZipCodeExist) =>
      wantInvoice && invoiceZipCodeExist,
    then: Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(5, 'El codigo postal debe de ser de 5 digitos')
      .max(5, 'El codigo postal debe de ser de 5 digitos')
      .test({
        name: 'invoiceZipCodeExist',
        exclusive: true,
        message: MESSAGE_ZIP_CODE,
        test: (value) => value === true,
      }),
    otherwise: Yup.string()
      .min(5, 'El codigo postal debe de ser de 5 digitos')
      .max(5, 'El codigo postal debe de ser de 5 digitos'),
  }),
  invoiceTaxRegime: Yup.string().when('wantInvoice', {
    is: true,
    then: Yup.string().required(REQUIRED_MESSAGE),
    otherwise: Yup.string(),
  }),
};

export const schema = Yup.object().shape({
  methodSelected: Yup.string().required(
    i18n('ERROR__RESERVATIONS__PAYMENT__METHOD')
  ),
  ...cardFormValidations(),
  ...bankFormValidations,
  ...transferFormValidations,
  ...cashFormValidations,
  ...holdbackFormValidations,
  ...invoiceFormValidation,
});
