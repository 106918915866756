export const LOAN_CREATOR_FIELDS = {
  DEPERTURE_DATE: 'departureDate',
  DEPERTURE_TIME: 'departureTime',
  PLATE: 'plateNumber',
  REQUESTER: 'requester',
  AUTHORIZER: 'responsible',
  RETURN_DATE: 'returnDate',
  RETURN_TIME: 'returnTime',
  DRIVER: 'driverName',
  OBSERVATIONS: 'observations',
};
