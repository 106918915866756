import React from 'react';
import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import {
  setTitles,
  getDiscounts,
  closeSendQuoteDialog,
} from 'store/reservations/actions';
import { fetchCatalog } from 'store/commons/actions';
import { getQuotePath } from 'utils/navigation';
import { getDetail } from 'store/customers/actions';
import { withLayout } from 'hooks';
import { Blank } from 'components';
import Detail from './Detail';

export default withLayout(
  compose(
    connect(
      (state) => ({
        ..._get(state, 'reservations.quote.values', {}),
        ..._get(state, 'reservations.quote.values.discountForm', {}),
        extendContract: _get(state, 'reservations.contract.extendContract', {}),
        customerFullName: _get(
          state,
          'customers.customerDetail.data.fullName',
          ''
        ),
        customerBillings: _get(
          state,
          'customers.customerDetail.data.billingDetails',
          []
        ),
        folio: _get(state, 'reservations.reservation.folio', ''),
        sendQuoteDialog: _get(state, 'reservations.sendQuoteDialog', false),
        branches: _get(state, 'commons.branches.list', []),
        selectedCategoryName: _get(
          state,
          'reservations.filters.selectedCategory.label',
          ''
        ),
        loader: _get(state, 'commons.loader', false),
        confirmationMessage: false,
        onCancelPath: `${getQuotePath('one')}?isCanceling=true`,
        paymentFormValues: _get(
          state,
          'reservations.reservation.payment.values.request',
          {}
        ),
        cfdiCatalog: _get(state, 'commons.catalogsCfdiUses.list', []),
        cardTypesCatalog: _get(state, 'commons.catalogsBankCardTypes.list', []),
        left: <Blank />,
        middleCol: 2,
        rightCol: 6,
      }),
      {
        setTitles,
        fetchCatalog,
        getDiscounts,
        closeSendQuoteDialog,
        getCustomerDetail: getDetail,
      }
    )
  )(Detail)
);
