import { connect } from 'react-redux';
import { closeResponseDialog } from 'store/contracts/actions';
import { fetchCatalog } from 'store/commons/actions';
import _get from 'lodash/get';
import ChangeOfVehicle from './ChangeOfVehicle';

export default connect(
  (state) => ({
    responseDialogVc: _get(
      state,
      'vehicles.vehiclesByPlate.responseDialog',
      {}
    ),
    responseDialogChangeOfVehicle: _get(state, 'contracts.responseDialog'),
    loader: state.commons.loader,
    contractDetails: state.contracts.contractDetails,
  }),
  { closeResponseDialog, fetchCatalog }
)(ChangeOfVehicle);
