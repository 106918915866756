export const IT_ADMIN = 'IT_ADMIN';
export const FUEL_ADMOR = 'FUEL_ADMOR';
export const DATABASE_ADMINISTRATOR_SAP = 'DATABASE_ADMINISTRATOR_SAP';
export const FUEL_ADMINISTRATOR = 'FUEL_ADMINISTRATOR';
export const JUNIOR_FLEET_ADMINISTRATOR = 'JUNIOR_FLEET_ADMINISTRATOR';
export const LEASING_FLEET_ADMINISTRATOR = 'LEASING_FLEET_ADMINISTRATOR';
export const R_D_FLEET_ADMINISTRATOR = 'R_D_FLEET_ADMINISTRATOR';
export const SR_FLEET_ADMINISTRATOR = 'SR_FLEET_ADMINISTRATOR';
export const ANALYST_ADMIN_BRANCHES = 'ANALYST_ADMIN_BRANCHES';
export const ANALYST_CREDIT_SR = 'ANALYST_CREDIT_SR';
export const ANALYST_TRAINING = 'ANALYST_TRAINING';
export const ANALYST_PURCHASING = 'ANALYST_PURCHASING';
export const ANALYST_EXPENSES = 'ANALYST_EXPENSES';
export const ANALYST_PAYROLL = 'ANALYST_PAYROLL';
export const ANALYST_RECRUITMENT_SELECTION = 'ANALYST_RECRUITMENT_SELECTION';
export const ANALYST_EXPENDITURES_FUNDING = 'ANALYST_EXPENDITURES_FUNDING';
export const ANALYST_BILLING = 'ANALYST_BILLING';
export const ANALYST_FISCAL = 'ANALYST_FISCAL';
export const ANALYST_LEGAL_CONTENTIOUS = 'ANALYST_LEGAL_CONTENTIOUS';
export const ANALYST_CORPORATE_LEGAL = 'ANALYST_CORPORATE_LEGAL';
export const ANALYST_LEGAL = 'ANALYST_LEGAL';
export const ANALYST_MASTER_DATA = 'ANALYST_MASTER_DATA';
export const ANALYST_CONTINUOUS_IMPROVEMENT = 'ANALYST_CONTINUOUS_IMPROVEMENT';
export const ANALYST_LOGISTICS_OPERATION = 'ANALYST_LOGISTICS_OPERATION';
export const ANALYST_HUMAN_RESOURCES = 'ANALYST_HUMAN_RESOURCES';
export const ANALYST_TRANSFER_REQUESTS = 'ANALYST_TRANSFER_REQUESTS';
export const ANALYST_SAP_SUPPORT = 'ANALYST_SAP_SUPPORT';
export const ANALYST_VEHICLE_PROCEDURES = 'ANALYST_VEHICLE_PROCEDURES';
export const ANALYST_VEHICLE_PROCEDURES_F = 'ANALYST_VEHICLE_PROCEDURES_F';
export const UNIT_ASSEMBLER = 'UNIT_ASSEMBLER';
export const COMMERCIAL_ADVISOR = 'COMMERCIAL_ADVISOR';
export const SERVICE_ADVISOR = 'SERVICE_ADVISOR';
export const CONTROL_SERVICE_ADVISOR = 'CONTROL_SERVICE_ADVISOR';
export const ASSISTANCE_AND_SUPPORT = 'ASSISTANCE_AND_SUPPORT';
export const COORDINATOR_ASSISTANT = 'COORDINATOR_ASSISTANT';
export const DIRECTOR_ASSISTANT = 'DIRECTOR_ASSISTANT';
export const MANAGER_ASSISTANT = 'MANAGER_ASSISTANT';
export const OPERATIONS_ASSISTANT = 'OPERATIONS_ASSISTANT';
export const SUPPLIER_PAYMENT_ASSISTANT = 'SUPPLIER_PAYMENT_ASSISTANT';
export const AUDITOR = 'AUDITOR';
export const AUX_ADMIN_DOCUMENTARY_FLEET = 'AUX_ADMIN_DOCUMENTARY_FLEET';
export const AUX_ADMIN_TENDERS = 'AUX_ADMIN_TENDERS';
export const AUX_ADMIN = 'AUX_ADMIN';
export const AUX_ADMIN_PART_TIME = 'AUX_ADMIN_PART_TIME';
export const AUX_ADMIN_WORKSHOP = 'AUX_ADMIN_WORKSHOP';
export const AUX_ADMIN_TRANSFERS = 'AUX_ADMIN_TRANSFERS';
export const AUX_ADMIN_ASSET_SALES = 'AUX_ADMIN_ASSET_SALES';
export const AUX_ADMIN_CHECKS = 'AUX_ADMIN_CHECKS';
export const FIXED_ASSETS_ASSISTANT = 'FIXED_ASSETS_ASSISTANT';
export const ANNEX_LALA_ASSISTANT = 'ANNEX_LALA_ASSISTANT';
export const ARCHIVE_ASSISTANT = 'ARCHIVE_ASSISTANT';
export const REGULATORY_AFFAIRS_ASSISTANT = 'REGULATORY_AFFAIRS_ASSISTANT';
export const BONUSES_ASSISTANT = 'BONUSES_ASSISTANT';
export const PART_TIME_ACCOUNTANT = 'PART_TIME_ACCOUNTANT';
export const GOVERNMENT_ACCOUNTS_RECEIVABLE_ASSISTANT =
  'GOVERNMENT_ACCOUNTS_RECEIVABLE_ASSISTANT';
export const BILLING_AND_COLLECTIONS_ASSISTANT =
  'BILLING_AND_COLLECTIONS_ASSISTANT';
export const FINANCING_ASSISTANT = 'FINANCING_ASSISTANT';
export const EXPENSES_OP_MANAGEMENT_EXPENSES =
  'EXPENSES_OP_MANAGEMENT_EXPENSES';
export const AUX_ACCOUNTANT_B = 'AUX_ACCOUNTANT_B';
export const AUX_ACCOUNTANT_C = 'AUX_ACCOUNTANT_C';
export const EXPENSES_OP_CHECKS = 'EXPENSES_OP_CHECKS';
export const AUX_ACCOUNTANT_A = 'AUX_ACCOUNTANT_A';
export const HELP_DESK_ASSISTANT = 'HELP_DESK_ASSISTANT';
export const PARTS_MONITOR_ASSISTANT = 'PARTS_MONITOR_ASSISTANT';
export const FINES_ASSISTANT = 'FINES_ASSISTANT';
export const OPERATIONAL_CHECKS_ASSISTANT = 'OPERATIONAL_CHECKS_ASSISTANT';
export const MATERIAL_RESOURCES_ASSISTANT = 'MATERIAL_RESOURCES_ASSISTANT';
export const NETWORKS_AND_SUPPORT_ASSISTANT = 'NETWORKS_AND_SUPPORT_ASSISTANT';
export const VEHICLE_PROCEDURES_ASSISTANT = 'VEHICLE_PROCEDURES_ASSISTANT';
export const WORKSHOP_VALIDATION_ASSISTANT = 'WORKSHOP_VALIDATION_ASSISTANT';
export const PER_DIEM_ASSISTANT = 'PER_DIEM_ASSISTANT';
export const GENERAL_HELPER = 'GENERAL_HELPER';
export const CASHIER = 'CASHIER';
export const DATA_ENTRY_OPERATOR = 'DATA_ENTRY_OPERATOR';
export const DRIVER = 'DRIVER';
export const OPERATOR_DRIVER = 'OPERATOR_DRIVER';
export const DRIVER_R = 'DRIVER_R';
export const SAP_CONSULTANT = 'SAP_CONSULTANT';
export const ACCOUNTANT_A = 'ACCOUNTANT_A';
export const ACCOUNTANT_C = 'ACCOUNTANT_C';
export const PERSONNEL_ADMIN_COORDINATOR = 'PERSONNEL_ADMIN_COORDINATOR';
export const FLEET_ADMIN_COORDINATOR_RD = 'FLEET_ADMIN_COORDINATOR_RD';
export const ASSET_HIGH_COORDINATOR = 'ASSET_HIGH_COORDINATOR';
export const ARCHIVE_COORDINATOR = 'ARCHIVE_COORDINATOR';
export const FLEET_DOC_ARCHIVE_COORDINATOR = 'FLEET_DOC_ARCHIVE_COORDINATOR';
export const OPERATIONAL_AND_SPECIAL_AUDITS_COORDINATOR =
  'OPERATIONAL_AND_SPECIAL_AUDITS_COORDINATOR';
export const EXTERNAL_WORKSHOP_AUTHORIZATIONS_COORDINATOR =
  'EXTERNAL_WORKSHOP_AUTHORIZATIONS_COORDINATOR';
export const BONUSES_COORDINATOR = 'BONUSES_COORDINATOR';
export const TRAINING_AND_DEVELOPMENT_COORDINATOR =
  'TRAINING_AND_DEVELOPMENT_COORDINATOR';
export const COMMERCIAL_COORDINATOR = 'COMMERCIAL_COORDINATOR';
export const PURCHASING_COORDINATOR = 'PURCHASING_COORDINATOR';
export const PURCHASING_AND_LOGISTICS_COORDINATOR =
  'PURCHASING_AND_LOGISTICS_COORDINATOR';
export const EXPENSE_VERIFICATION_COORDINATOR =
  'EXPENSE_VERIFICATION_COORDINATOR';
export const WORKSHOP_FOLLOW_UP_COORDINATOR = 'WORKSHOP_FOLLOW_UP_COORDINATOR';
export const FINANCING_COORDINATOR = 'FINANCING_COORDINATOR';
export const FINANCE_AND_FUNDING_COORDINATOR =
  'FINANCE_AND_FUNDING_COORDINATOR';
export const FISCAL_COORDINATOR = 'FISCAL_COORDINATOR';
export const LEASING_FLEET_COORDINATOR = 'LEASING_FLEET_COORDINATOR';
export const FLEET_SP_COORDINATOR = 'FLEET_SP_COORDINATOR';
export const GPS_INSTALLATIONS_COORDINATOR = 'GPS_INSTALLATIONS_COORDINATOR';
export const LEGAL_COORDINATOR = 'LEGAL_COORDINATOR';
export const LEGAL_AND_CLEARANCES_COORDINATOR =
  'LEGAL_AND_CLEARANCES_COORDINATOR';
export const TENDER_COORDINATOR = 'TENDER_COORDINATOR';
export const MAINTENANCE_COORDINATOR = 'MAINTENANCE_COORDINATOR';
export const CONTINUOUS_IMPROVEMENT_COORDINATOR =
  'CONTINUOUS_IMPROVEMENT_COORDINATOR';
export const RECRUITMENT_AND_SELECTION_COORDINATOR =
  'RECRUITMENT_AND_SELECTION_COORDINATOR';
export const LABOR_RELATIONS_COORDINATOR = 'LABOR_RELATIONS_COORDINATOR';
export const GENERAL_SERVICES_COORDINATOR = 'GENERAL_SERVICES_COORDINATOR';
export const APPLICATION_SUPPORT_COORDINATOR =
  'APPLICATION_SUPPORT_COORDINATOR';
export const IT_SUPPORT_COORDINATOR = 'IT_SUPPORT_COORDINATOR';
export const CONTROL_TOWER_COORDINATOR = 'CONTROL_TOWER_COORDINATOR';
export const VEHICLE_PROCEDURES_COORDINATOR = 'VEHICLE_PROCEDURES_COORDINATOR';
export const CRANE_TRANSFER_COORDINATOR = 'CRANE_TRANSFER_COORDINATOR';
export const VERIFICATIONS_COORDINATOR = 'VERIFICATIONS_COORDINATOR';
export const SAP_DEVELOPER = 'SAP_DEVELOPER';
export const AUDIT_DIRECTOR = 'AUDIT_DIRECTOR';
export const ACCOUNTING_AND_FINANCE_DIRECTOR =
  'ACCOUNTING_AND_FINANCE_DIRECTOR';
export const LEGAL_DIRECTOR = 'LEGAL_DIRECTOR';
export const MARKETING_DIRECTOR = 'MARKETING_DIRECTOR';
export const HUMAN_RESOURCES_DIRECTOR = 'HUMAN_RESOURCES_DIRECTOR';
export const DAILY_RENTAL_DIRECTOR = 'DAILY_RENTAL_DIRECTOR';
export const IT_DIRECTOR = 'IT_DIRECTOR';
export const SALES_ADMIN_EXECUTIVE = 'SALES_ADMIN_EXECUTIVE';
export const STRATEGIC_ALLIANCES_EXECUTIVE = 'STRATEGIC_ALLIANCES_EXECUTIVE';
export const CUSTOMER_SERVICE_EXECUTIVE = 'CUSTOMER_SERVICE_EXECUTIVE';
export const NIGHT_CUSTOMER_SERVICE_EXECUTIVE =
  'NIGHT_CUSTOMER_SERVICE_EXECUTIVE';
export const WEEKEND_CUSTOMER_SERVICE_EXECUTIVE =
  'WEEKEND_CUSTOMER_SERVICE_EXECUTIVE';
export const USED_CAR_DOWN_EXECUTIVE = 'USED_CAR_DOWN_EXECUTIVE';
export const VEHICLE_PURCHASE_EXECUTIVE = 'VEHICLE_PURCHASE_EXECUTIVE';
export const PURCHASING_AND_LOGISTICS_EXECUTIVE =
  'PURCHASING_AND_LOGISTICS_EXECUTIVE';
export const CORPORATE_ACCOUNTS_EXECUTIVE = 'CORPORATE_ACCOUNTS_EXECUTIVE';
export const QUALITY_EXECUTIVE = 'QUALITY_EXECUTIVE';
export const PAYMENTS_EXECUTIVE = 'PAYMENTS_EXECUTIVE';
export const DOCUMENTATION_AND_FINANCING_EXECUTIVE_SN =
  'DOCUMENTATION_AND_FINANCING_EXECUTIVE_SN';
export const BILLING_AND_COLLECTIONS_EXECUTIVE =
  'BILLING_AND_COLLECTIONS_EXECUTIVE';
export const BILLING_AND_COLLECTIONS_EXECUTIVE_SN =
  'BILLING_AND_COLLECTIONS_EXECUTIVE_SN';
export const LEGAL_EXECUTIVE = 'LEGAL_EXECUTIVE';
export const OPERATIONS_EXECUTIVE = 'OPERATIONS_EXECUTIVE';
export const OPERATIONS_EXECUTIVE_SR = 'OPERATIONS_EXECUTIVE_SR';
export const SALES_EXECUTIVE = 'SALES_EXECUTIVE';
export const RD_EXECUTIVE = 'RD_EXECUTIVE';
export const BRANCH_ADMINISTRATOR = 'BRANCH_ADMINISTRATOR';
export const WAREHOUSE_MANAGER = 'WAREHOUSE_MANAGER';
export const COMMERCIAL_AREA_OPERATIONS_SPECIALIST =
  'COMMERCIAL_AREA_OPERATIONS_SPECIALIST';
export const FLEET_MANAGER = 'FLEET_MANAGER';
export const VALUATION_AND_CLAIMS_MANAGER = 'VALUATION_AND_CLAIMS_MANAGER';
export const FLEET_SP_ADMIN_MANAGER = 'FLEET_SP_ADMIN_MANAGER';
export const FLEET_AND_MANAGEMENT_MANAGER = 'FLEET_AND_MANAGEMENT_MANAGER';
export const PROCESS_AUDIT_MANAGER = 'PROCESS_AUDIT_MANAGER';
export const COMMERCIAL_MANAGER = 'COMMERCIAL_MANAGER';
export const ACCOUNTING_MANAGER = 'ACCOUNTING_MANAGER';
export const CONTACT_CENTER_MANAGER = 'CONTACT_CENTER_MANAGER';
export const CREDIT_MANAGER = 'CREDIT_MANAGER';
export const ADMINISTRATION_AND_FINANCE_MANAGER =
  'ADMINISTRATION_AND_FINANCE_MANAGER';
export const REGULATORY_AFFAIRS_MANAGER = 'REGULATORY_AFFAIRS_MANAGER';
export const FISCAL_MANAGER = 'FISCAL_MANAGER';
export const MASTER_DATA_AND_GPS_MONITORING_MANAGER =
  'MASTER_DATA_AND_GPS_MONITORING_MANAGER';
export const SAP_MANAGER = 'SAP_MANAGER';
export const USED_CAR_MANAGER = 'USED_CAR_MANAGER';
export const BRANCH_A_MANAGER = 'BRANCH_A_MANAGER';
export const BRANCH_B_MANAGER = 'BRANCH_B_MANAGER';
export const TREASURY_AND_FINANCE_MANAGER = 'TREASURY_AND_FINANCE_MANAGER';
export const TRACKING_MANAGER = 'TRACKING_MANAGER';
export const TRANSFER_MANAGER = 'TRANSFER_MANAGER';
export const PANEL_BEATER_A = 'PANEL_BEATER_A';
export const PANEL_BEATER_B = 'PANEL_BEATER_B';
export const PAINTING_AND_POLISHING_MANAGER = 'PAINTING_AND_POLISHING_MANAGER';
export const BILLING_AND_COLLECTIONS_MANAGER =
  'BILLING_AND_COLLECTIONS_MANAGER';
export const SHEET_METAL_MANAGER = 'SHEET_METAL_MANAGER';
export const CAR_WASH_MANAGER = 'CAR_WASH_MANAGER';
export const SUPPLIER_PAYMENT_MANAGER = 'SUPPLIER_PAYMENT_MANAGER';
export const MATERIALS_AND_GENERAL_SERVICES_MANAGER =
  'MATERIALS_AND_GENERAL_SERVICES_MANAGER';
export const WORKSHOP_MANAGER = 'WORKSHOP_MANAGER';
export const WORKSHOP_1ST_FLOOR_MANAGER = 'WORKSHOP_1ST_FLOOR_MANAGER';
export const WORKSHOP_3RD_FLOOR_MANAGER = 'WORKSHOP_3RD_FLOOR_MANAGER';
export const SPECIALIST_TECH_DIAGNOSTICS_MANAGER =
  'SPECIALIST_TECH_DIAGNOSTICS_MANAGER';
export const LA_VIGA = 'LA_VIGA';
export const CAR_WASHER = 'CAR_WASHER';
export const QUALITY_LEADER = 'QUALITY_LEADER';
export const ELECTRICAL_MECHANIC = 'ELECTRICAL_MECHANIC';
export const CCTV_MONITOR = 'CCTV_MONITOR';
export const GPS_MONITOR = 'GPS_MONITOR';
export const GPS_FLOOR_BALANCING_MONITOR = 'GPS_FLOOR_BALANCING_MONITOR';
export const TRACKING_MONITOR = 'TRACKING_MONITOR';
export const NIÑOS_HEROES = 'NIÑOS_HEROES';
export const PAINTER_A = 'PAINTER_A';
export const PAINTER_B = 'PAINTER_B';
export const PAINTER_C = 'PAINTER_C';
export const GPS_PROGRAMMER = 'GPS_PROGRAMMER';
export const POLISHER_A = 'POLISHER_A';
export const POLISHER_B = 'POLISHER_B';
export const POLISHER_C = 'POLISHER_C';
export const CUSTOMER_SERVICE_RESPONSIBLE = 'CUSTOMER_SERVICE_RESPONSIBLE';
export const SUBDIR_ADMINISTRATION_PROPERTY = 'SUBDIR_ADMINISTRATION_PROPERTY';
export const NEW_BUSINESS_SUB_MANAGER = 'NEW_BUSINESS_SUB_MANAGER';
export const BRANCH_SUB_MANAGER = 'BRANCH_SUB_MANAGER';
export const QUALITY_SUPERVISOR = 'QUALITY_SUPERVISOR';
export const GPS_MONITOR_SUPERVISOR = 'GPS_MONITOR_SUPERVISOR';
export const MONITOR_AND_REC_UNIT_SUPERVISOR =
  'MONITOR_AND_REC_UNIT_SUPERVISOR';
export const SAP_SUPPORT_SUPERVISOR = 'SAP_SUPPORT_SUPERVISOR';
export const WORKSHOP_EXTERNAL_AUTHORIZATIONS_TECH =
  'WORKSHOP_EXTERNAL_AUTHORIZATIONS_TECH';
export const SPECIALIST_DIAGNOSTICS_TECH = 'SPECIALIST_DIAGNOSTICS_TECH';
export const TECH_SPECIALIST_TRANSMISSIONS = 'TECH_SPECIALIST_TRANSMISSIONS';
export const TECHNICIAN_GPS_INSTALLER = 'TECHNICIAN_GPS_INSTALLER';
export const TECHNICIAN_DIESEL_MECHANIC_A = 'TECHNICIAN_DIESEL_MECHANIC_A';
export const TECHNICIAN_DIESEL_MECHANIC_B = 'TECHNICIAN_DIESEL_MECHANIC_B';
export const TECHNICIAN_GASOLINE_MECHANIC_A = 'TECHNICIAN_GASOLINE_MECHANIC_A';
export const TECHNICIAN_GASOLINE_MECHANIC_B = 'TECHNICIAN_GASOLINE_MECHANIC_B';
export const TECHNICIAN_RD_MECHANIC = 'TECHNICIAN_RD_MECHANIC';
export const SECURITY_GUARD = 'SECURITY_GUARD';
export const TIRE_REPAIRER = 'TIRE_REPAIRER';
export const PAYMENTS_ADMIN = 'PAYMENTS_ADMIN';
export const CUSTOMER_ADMIN = 'CUSTOMER_ADMIN';
export const DAYLI_RENT_ADMIN = 'DAYLI_RENT_ADMIN';
export const VIRTUAL_CONTRACT_ADMIN = 'VIRTUAL_CONTRACT_ADMIN';
export const INVOICE_ADMIN = 'INVOICE_ADMIN';
export const LOAN_ADMIN = 'LOAN_ADMIN';
export const REPORTING_ADMIN = 'REPORTING_ADMIN';
export const WORKSHOP_ADMIN = 'WORKSHOP_ADMIN';
export const TRANSFER_ADMIN = 'TRANSFER_ADMIN';
export const VEHICLES_ADMIN = 'VEHICLES_ADMIN';
export const PRICE_QUOTE_ADMIN = 'PRICE_QUOTE_ADMIN';
export const RETURN_ADMIN = 'RETURN_ADMIN';
export const RESERVATION_ADMIN = 'RESERVATION_ADMIN';
export const DB_ADMIN = 'DB_ADMIN';
export const CONTACT_CENTER = 'CONTACT_CENTER';
