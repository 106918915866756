const stepInfo = {
  1: {
    title: 'Seleccionar nueva fecha de devolución',
    subtitle:
      'Elija la nueva fecha que indique el cliente para devolver el vehículo ',
  },
  2: {
    title: 'Contrato de arrendamiento y Pagaré',
    subtitle: 'Contrato y Pagaré',
  },
  3: {
    title: 'Pago',
    subtitle: 'Pago',
  },
};

export const progressColors = ['#e2e2e2', '#007aff'];

export const maxSteps = 3;

export const getProgressInfo = (step = 1) => {
  return stepInfo[step];
};
