export const TRANSFERS_MODULE = 'TRANSFERS_MODULE';

export const VIEW_TRANSFERS = 'VIEW_TRANSFERS';

export const CREATE_TRANSFER_REQUEST = 'CREATE_TRANSFER_REQUEST';

export const EDIT_ANY_TRANSFER_REQUEST = 'EDIT_ANY_TRANSFER_REQUEST';
export const EDIT_OWN_TRANSFER_REQUEST = 'EDIT_OWN_TRANSFER_REQUEST';
export const EDIT_STATUS_AUTHORIZE_TRANSFER_REQUEST =
  'EDIT_STATUS_AUTHORIZE_TRANSFER_REQUEST';
export const EDIT_STATUS_CANCELLED_ANY_TRANSFER_REQUEST =
  'EDIT_STATUS_CANCELLED_ANY_TRANSFER_REQUEST';
export const EDIT_STATUS_CANCELLED_OWN_TRANSFER_REQUEST =
  'EDIT_STATUS_CANCELLED_OWN_TRANSFER_REQUEST';
export const EDIT_STATUS_CLOSED_ANY_TRANSFER_REQUEST =
  'EDIT_STATUS_CLOSED_ANY_TRANSFER_REQUEST';
export const EDIT_STATUS_CLOSED_OWN_TRANSFER_REQUEST =
  'EDIT_STATUS_CLOSED_OWN_TRANSFER_REQUEST';
export const EDIT_STATUS_REJECTED_OWN_TRANSFER_REQUEST =
  'EDIT_STATUS_REJECTED_OWN_TRANSFER_REQUEST';
export const EDIT_STATUS_REJECTED_ANY_TRANSFER_REQUEST =
  'EDIT_STATUS_REJECTED_ANY_TRANSFER_REQUEST';
