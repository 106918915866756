import * as yup from 'yup';
import moment from 'moment';
import 'moment/locale/es';
import { getWithHoursFormat } from '@casanova/casanova-common/lib/utils/strings';
import i18n from '@i18n';
import { saveTransfer } from 'store/transfers/actions';
import { YYYY_MM_DD, MX_DATE_FORMAT_NO_HOURS } from 'utils/constants';

const schema = yup.object().shape({});

const initialValues = {};

export const initialTouched = {};

export const formatDate = (values) =>
  moment(values, YYYY_MM_DD).format(MX_DATE_FORMAT_NO_HOURS);

export const transformer = (values) => {
  const formattedDepartureDate = formatDate(values.departureDate);
  const formattedRealDepartureDate = values.realDepartureDate
    ? formatDate(values.realDepartureDate)
    : i18n('COMMONS__NOT_INFO');
  const formattedReturnDate = formatDate(values.returnDate);
  const formattedRealReturnDate = values.realReturnDate
    ? formatDate(values.realReturnDate)
    : i18n('COMMONS__NOT_INFO');

  return {
    ...values,
    formattedDepartureDate,
    formattedRealDepartureDate,
    realDepartureTime: values.realDepartureTime
      ? getWithHoursFormat(values.realDepartureTime)
      : i18n('COMMONS__NOT_INFO'),
    formattedReturnDate,
    formattedRealReturnDate,
    realReturnTime: values.realReturnTime
      ? getWithHoursFormat(values.realReturnTime)
      : i18n('COMMONS__NOT_INFO'),
  };
};

export const formatter = (values) => values;

export const config = {
  schema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter,
  transformer,
  submitForm: saveTransfer,
  btnCancelLabel: 'Rechazar',
  btnSubmitLabel: 'Autorizar',
  withButtons: false,
  dialogs: {
    confirmation: {},
    response: {},
  },
};

export default config;
