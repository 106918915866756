import * as yup from 'yup';
import i18n from '@i18n';
import { REQUIRED_MESSAGE } from 'utils/constants';
import { reservationDatesContractQuoteProps } from '@reservations/sections/ReservationDates/config';
import { validationSchema as vehicleValidationSchema } from '@reservations/sections/VehicleAssignment/config';

const updateCustomerFieldsMessage =
  'Tienes que actualizar los documentos inválidos';
const changeCustomerFieldsMessage = 'Se necesita actualizar cliente';

export const schema = yup
  .object()
  .shape({
    personType: yup.string().required(REQUIRED_MESSAGE),
    customer: yup.string().required(REQUIRED_MESSAGE),
    customer_search: yup
      .string()
      .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
      .min(3, i18n('ERROR__COMMONS__MIN_LENGTH', [3]))
      .test(
        'required-customer-search',
        i18n('ERROR__COMMONS__REQUIRED__ITEM_OF_LIST', ['un cliente']),
        function customerSearchTest(value) {
          return value && this.parent.customer;
        }
      )
      .test('update-documents-test', updateCustomerFieldsMessage, function () {
        return !this.parent.withInvalidDocuments;
      })
      .test('without-documents-test', changeCustomerFieldsMessage, function () {
        const personType = this.parent.personType;
        if (personType !== 'JURIDICAL') {
          return !this.parent.withoutDocuments;
        }
        return true;
      }),

    withoutDocuments: yup
      .boolean()
      .test(
        'without-documents-test',
        changeCustomerFieldsMessage,
        function (withoutDocuments) {
          const personType = this.parent.personType;
          if (personType !== 'JURIDICAL') {
            return !withoutDocuments;
          }
          return true;
        }
      )
      .required(REQUIRED_MESSAGE),

    withInvalidDocuments: yup
      .boolean()
      .test(
        'invalid-documents-test',
        updateCustomerFieldsMessage,
        (withInvalidDocuments) => !withInvalidDocuments
      )
      .required(REQUIRED_MESSAGE),
    ...reservationDatesContractQuoteProps,
  })
  .concat(vehicleValidationSchema);
