import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  getListOfReservations,
  setListOfReservationsPage,
  setInitialParamsListOfReservations,
  getReservationInfo,
  getReservationListFilters,
  closeResponseDialog,
  hydrateChangeOfVehicleFlow,
  resetReservations,
} from 'store/reservations/actions';
import { fetchCatalog } from 'store/commons/actions';
import ListOfRervationContract from './ListOfRervationContract';

export default compose(
  connect(
    (state) => ({
      listOfReservations: state.reservations.listOfReservations.list,
      confirmationMessage: false,
      responseDialog: {
        ...state.reservations.responseDialog,
        closeInConfirmation: true,
      },
    }),
    {
      getListOfReservations,
      setListOfReservationsPage,
      setInitialParamsListOfReservations,
      getReservationInfo,
      fetchCatalog,
      getReservationListFilters,
      closeResponseDialog,
      hydrateChangeOfVehicleFlow,
      resetReservations,
    }
  )
)(ListOfRervationContract);
