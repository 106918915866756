import * as yup from 'yup';
import i18n from '@i18n';
import { editVehicleDetails } from 'store/vehicles/actions';
import { REQUIRED_MESSAGE } from 'utils/constants';
import { vehicleDetailTransform } from '@vehicles/common/transformers';
import {
  initialValues,
  initialTouched,
} from '@vehicles/sections/VehicleDetail/config';
import { editVehicleDetailFormatter } from './formatter';

export const config = {
  schema: {},
  initialTouched,
  initialValues,
  transformer: vehicleDetailTransform,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  disableOnDirty: true,
  withButtons: false,
  btnSubmitLabel: i18n('COMMONS__EDIT'),
  submitForm: () => {},
  dialogs: {
    confirmation: {},
    response: {},
  },
};

const schema = yup.object().shape({
  warehouse: yup.string().required(REQUIRED_MESSAGE),
});

export const editConfig = {
  schema,
  initialTouched,
  initialValues: {
    ...initialValues,
    vehicleUuid: '',
  },
  transformer: vehicleDetailTransform,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  redirectInSuccess: false,
  submitForm: editVehicleDetails,
  formatter: editVehicleDetailFormatter,
  btnSubmitLabel: i18n('COMMONS__CONTINUE__TEXT'),
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN EDICION DE DETALLES',
      message:
        'Una vez cargada la información del vehículo tendrás que editarlo manualmente\n¿Estás seguro que quieres continuar?',
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: `Se actualizó la información con éxito, 
      si deseas realizar algún cambio tendrás que buscarlo y editarlo.`,
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: 'Ir a Vehículos',
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: './',
    },
  },
};
