import {
  CATALOG_FETCH_SUCCEEDED,
  CATALOG_FETCH_FAILED,
  CATALOG_FETCH_REQUESTED,
  GET_SUBURBS_BY_ZIPCODE,
  GET_SUBURBS_BY_ZIPCODE_FAILED,
  GET_SUBURBS_BY_ZIPCODE_SUCCEEDED,
  GET_TAX_ZIPCODE,
  GET_TAX_ZIPCODE_FAILED,
  GET_TAX_ZIPCODE_SUCCEEDED,
  SHOW_LOADER,
  HIDE_LOADER,
  RESET,
  SET_PREVENT_CHANGE,
} from './action-types';

export function fetchCatalog(payload) {
  return { type: CATALOG_FETCH_REQUESTED, payload };
}

export function fetchCatalogSucceeded(payload) {
  return { type: CATALOG_FETCH_SUCCEEDED, payload };
}

export function fetchCatalogFailed(payload) {
  return { type: CATALOG_FETCH_FAILED, payload };
}

export function getSuburbsByZipCode(payload) {
  return { type: GET_SUBURBS_BY_ZIPCODE, payload };
}

export function getSuburbsByZipCodeSucceeded(payload) {
  return { type: GET_SUBURBS_BY_ZIPCODE_SUCCEEDED, payload };
}

export function getSuburbsByZipCodeFailed(payload) {
  return { type: GET_SUBURBS_BY_ZIPCODE_FAILED, payload };
}

export function getTaxZipCode(payload) {
  return { type: GET_TAX_ZIPCODE, payload };
}

export function getTaxZipCodeSucceeded(payload) {
  return { type: GET_TAX_ZIPCODE_SUCCEEDED, payload };
}

export function getTaxZipCodeFailed(payload) {
  return { type: GET_TAX_ZIPCODE_FAILED, payload };
}

export function hideLoader() {
  return { type: HIDE_LOADER };
}

export function showLoader() {
  return { type: SHOW_LOADER };
}

export function reset() {
  return { type: RESET };
}

export function setPreventChange(payload) {
  return { type: SET_PREVENT_CHANGE, payload };
}
