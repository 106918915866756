import React from 'react';
import { Icons } from '@casanova/casanova-common';
import profileImage from '@casanova/casanova-common/assets/images/profile.jpg';
import { COLORS } from 'utils/colors';
import { CUSTOMER_LEVELS } from 'utils/constants/customerLevels';

import './CustomerLevel.scss';

export default function CustomerLevel({
  src = profileImage,
  name = '',
  color = COLORS.WOOD,
  level = 0,
}) {
  return (
    <div className="CustomerPodium__container-image">
      <img
        src={src}
        alt="Customer profile"
        className="CustomerPodium__profile-image"
        style={{ borderColor: color }}
      />
      {level !== CUSTOMER_LEVELS.MADERA && (
        <div
          className="CustomerPodium__range"
          style={{ backgroundColor: color }}
        >
          <span className="CustomerPodium__range-icon">
            <Icons.Podium width="1.25rem" />
          </span>
          <span className="CustomerPodium__range-title">{name}</span>
        </div>
      )}
    </div>
  );
}
