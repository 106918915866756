import types from './action-types';

export function reset() {
  return { type: types.RESET };
}

export function getLoans(payload) {
  return { type: types.GET_LIST, payload };
}

export function getLoansSucceed(payload) {
  return { type: types.GET_LIST_SUCCEED, payload };
}

export function getLoansFailed(payload) {
  return { type: types.GET_LIST_FAILED, payload };
}

export function setListParams(payload) {
  return { type: types.SET_PARAMS, payload };
}

export function setListPage(payload) {
  return { type: types.SET_LIST_PAGE, payload };
}

export function closeResponseDialog() {
  return { type: types.CLOSE_RESPONSE_DIALOG };
}

export function saveLoan(payload) {
  return { type: types.SAVE_LOAN, payload };
}

export function saveLoanSucceed(payload) {
  return { type: types.SAVE_LOAN_SUCCEED, payload };
}

export function saveLoanFailed(payload) {
  return { type: types.SAVE_LOAN_FAILED, payload };
}

export function getLoanDetails(payload) {
  return { type: types.GET_LOAN_DETAILS, payload };
}

export function getLoanDetailsSucceed(payload) {
  return { type: types.GET_LOAN_DETAILS_SUCCEED, payload };
}

export function getLoanDetailsFailed(payload) {
  return { type: types.GET_LOAN_DETAILS_FAILED, payload };
}

export function editLoan(payload) {
  return { type: types.EDIT_LOAN, payload };
}

export function editLoanSucceed(payload) {
  return { type: types.EDIT_LOAN_SUCCEED, payload };
}

export function editLoanFailed(payload) {
  return { type: types.EDIT_LOAN_FAILED, payload };
}

export function closeLoan(payload) {
  return { type: types.CLOSE_LOAN, payload };
}

export function closeLoanSucceed(payload) {
  return { type: types.CLOSE_LOAN_SUCCEED, payload };
}

export function closeLoanFailed(payload) {
  return { type: types.CLOSE_LOAN_FAILED, payload };
}

export function cancelLoan(payload) {
  return { type: types.CANCEL_LOAN, payload };
}

export function cancelLoanSucceed(payload) {
  return { type: types.CANCEL_LOAN_SUCCEED, payload };
}

export function cancelLoanFailed(payload) {
  return { type: types.CANCEL_LOAN_FAILED, payload };
}

export function authorizeLoan(payload) {
  return { type: types.AUTHORIZE_LOAN, payload };
}

export function authorizeLoanSucceed(payload) {
  return { type: types.AUTHORIZE_LOAN_SUCCEED, payload };
}

export function authorizeLoanFailed(payload) {
  return { type: types.AUTHORIZE_LOAN_FAILED, payload };
}
