import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  CardSection,
  TextField,
  FileViewer,
  UserInfo,
} from '@casanova/casanova-common';
import { getTextColor } from 'utils/customers';
import { ENTRANCE_TYPES } from '@customers/common/constants';

const CountryEntranceType = ({ data }) => {
  const { documentStatus, entranceType, statusColor, files } = useMemo(() => {
    const currentEntranceType = _get(data, 'entranceType.name');
    const currentDocumentStatus = _get(data, 'documentStatus.name', '');
    const getFiles = () => {
      if (!_isEmpty(data) && currentEntranceType) {
        return currentEntranceType === ENTRANCE_TYPES.AERIAL
          ? [
              _get(data, 'itineraryFile.presignedUrl', undefined),
              _get(data, 'fmmeFile.presignedUrl', undefined),
            ]
          : [_get(data, 'fmmeFile.presignedUrl', undefined)];
      }
      return [];
    };

    return {
      documentStatus: currentDocumentStatus,
      entranceType: currentEntranceType,
      statusColor: getTextColor(currentDocumentStatus),
      files: getFiles(),
    };
  }, [data]);

  return (
    <CardSection
      status={statusColor}
      title="Forma de ingreso al país"
      upperCase
    >
      <div className="row">
        <div className="col-7">
          <FileViewer files={files} />
        </div>
        <div className="col-5">
          <TextField
            title="Estatus"
            status={statusColor}
            value={documentStatus.toUpperCase()}
          />
          <TextField title="Forma de ingreso" value={entranceType} />
          <UserInfo {...data} />
        </div>
      </div>
    </CardSection>
  );
};

CountryEntranceType.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

CountryEntranceType.defaultProps = {
  data: {},
};

export default CountryEntranceType;
