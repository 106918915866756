export const transformToFilters = (data) => {
  const categories = data.map((item) => ({
    value: item.uuid,
    label: item.name,
    types: item.types.map((type) => ({
      value: type.uuid,
      label: type.name,
      icon: type.originalImgUrl,
      brands: type.brands,
    })),
    isDefault: item.isDefault,
    brands: item.brands,
  }));
  const defaultSelected = categories.filter((cat) => cat.isDefault);

  const brands = defaultSelected[0].brands.map((item) => ({
    value: item.uuid,
    label: item.name,
    icon: item.originalImgUrl,
    ...item,
  }));

  return {
    categories,
    brands,
  };
};

export const transformReservationListFilters = (data) => {
  const { statuses, deliveryBranches, returnBranches, steps } = data;
  return {
    ...data,
    statuses: statuses.map((status) => ({
      ...status,
      value: status.uuid,
      label: status.name,
    })),
    deliveryBranches: deliveryBranches.map((branch) => ({
      ...branch,
      value: branch.uuid,
      label: branch.name,
    })),
    returnBranches: returnBranches.map((branch) => ({
      ...branch,
      value: branch.uuid,
      label: branch.name,
    })),
    steps: steps.map((step) => ({
      ...step,
      value: step.uuid,
      label: step.name,
    })),
  };
};
