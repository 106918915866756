import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { config as addConfig } from './config';
import { Loader, Modal, withForm } from '@casanova/casanova-common';
import { PayDetail } from '../sections';
import Blank from '../../../components/Blank';

const ViewPayDetail = (props) => {
  const {
    show = true,
    setShow,
    loader = false,
    contract = {},
    getReservationInfo,
    getTotalPay,
    closeResponseDialog,
    responseDialog,
    reservationDetails,
    refundDetails,
  } = props;

  const [hide, setHide] = useState(false);
  const { success: succesRefundDetails = false } = refundDetails;

  const handleCancel = useCallback(() => {
    setShow(false);
  });

  const handleBeforeSubmit = useCallback(() => {
    setHide(true);
  }, []);

  const handleSuccess = useCallback(() => {
    setHide(false);
    setShow(false);
  }, []);

  const handleClose = useCallback(() => {
    setHide(false);
  });

  const handleCloseModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  useEffect(() => {
    if (contract && show) {
      getReservationInfo({ uuid: contract.reservationUuid, redirect: false });
      getTotalPay({ uuid: contract.uuid });
    }
  }, [getReservationInfo, contract, show]);

  const config = useMemo(() => {
    const finalConfig = addConfig;
    return {
      ...finalConfig,
      onBeforeSubmit: handleBeforeSubmit,
      onSuccess: handleSuccess,
      onClose: handleClose,
    };
  }, [handleBeforeSubmit, handleSuccess, handleClose]);

  useEffect(() => {
    if (succesRefundDetails) {
      setHide(false);
      setShow(false);
    }
  }, [succesRefundDetails]);

  const PayDetailWithForm = withForm({ config })(PayDetail)({
    ...props,
    reservationUuid: contract,
    layout: false,
    left: <Blank />,
    onCancel: handleCancel,
    closeResponseDialog: () => {
      if (responseDialog.open) {
        handleSuccess();
      }
      closeResponseDialog();
    },
  });

  return (
    <>
      <Loader show={hide && !loader && show} />
      <Modal
        open={!loader && show}
        header={null}
        footer={null}
        className="ViewRefundDetail"
        topContainerClassName={`${hide ? 'd-none' : ''}`}
        contentClassName="px-4 py-4 container"
        closeButton
        onClose={handleCloseModal}
        bigModal
      >
        {PayDetailWithForm}
      </Modal>
    </>
  );
};

export default ViewPayDetail;
