import React, { useState, useCallback } from 'react';
import Modal from '@casanova/casanova-common/lib/Modal';
import PageNavigator from 'components/PageNavigator';
import { SpecItem } from '@reservations/components';
import { getSpectList as getSpecList } from '@reservations/commons/transformers';
import './VerificationPointModal.scss';
import { boolean } from 'yup';

const VALUES_IDENTIFIERS = {
  KM: 'KM',
  GAS: '/ 8',
};

export default function VerificationPointModal({
  open = false,
  verificationPoint = {},
  onClose,
}) {
  const [swiperIndex, setSwiperIndex] = useState(0);
  const {
    images = [],
    remarks = '',
    name = '',
    isSet = false,
    passed = false,
    additionalFields = {},
    title = '',
    position = 1,
    vehicleImage = '',
    showDetails = true,
    signature = null,
  } = verificationPoint;

  const handleChangeCurrentPage = useCallback((currentPage) => {
    setSwiperIndex(currentPage);
  }, []);

  const imageSrc = images[swiperIndex] ? images[swiperIndex].presignedUrl : '';

  const subItems = Object.values(additionalFields).map(
    ({ label = '', value = '' }) => {
      const isKM = label.toLowerCase().includes('kilometraje');
      const isGas = label.toLowerCase().includes('gasolina');

      const valueIdentifier = isKM
        ? VALUES_IDENTIFIERS.KM
        : isGas
        ? VALUES_IDENTIFIERS.GAS
        : '';

      let valueToShow;

      if (typeof value === 'boolean') {
        valueToShow = `${label}: ${value ? '✔️' : '✖'}`;
        return label ? valueToShow : label;
      }
      valueToShow = `${label}: ${value} ${valueIdentifier}`;
      return label && value ? valueToShow : label;
    }
  );

  return (
    <Modal
      open={open}
      header={null}
      footer={null}
      className="VerificationPointModal"
      containerClassName="VerificationPointModal__Container"
      contentClassName="VerificationPointModal__Content px-4 py-2"
    >
      <div className="modal-header justify-content-center align-items-end p-0">
        <span
          className="btn py-0 pr-2"
          role="button"
          tabIndex="0"
          onClick={onClose}
          onKeyDown={onClose}
        >
          <h3 className="text-secondary m-0 p-2">X</h3>
        </span>
      </div>
      <div className="row text-center ">
        <div className="col-12">
          <h3 className="text-center mb-3">
            {title && title.length > 4 ? title : name}
          </h3>
        </div>
        <div className="col-12 VerificationPointModal__Content--Image-container">
          {(vehicleImage || imageSrc || signature) && (
            <img
              src={signature || (position === 1 ? vehicleImage : imageSrc)}
              className="view-image__image mt-2 VerificationPointModal__Image"
              alt=""
            />
          )}
          {images.length > 1 && (
            <PageNavigator
              containerClassName="view-image__paginator-container mt-1 VerificationPointModal__Paginator"
              elements={images}
              onChangeCurrentPage={handleChangeCurrentPage}
            />
          )}
        </div>
      </div>
      {showDetails && (
        <>
          <div className="row mt-4 p-0 d-flex">
            <div className="col-6 mt-5">
              <div className="row ">
                <div className="col-12 d-flex alig-items-center justify-content-start">
                  <SpecItem
                    specList={[
                      getSpecList({
                        isSet,
                        passed,
                      }),
                    ]}
                    check={passed}
                    subItems={subItems}
                    className="CardInfo__info-container--Spec-item"
                    textClassName="m-0"
                    iconStyle={{
                      width: '2.25rem',
                      height: '2.25rem',
                    }}
                    disableIconType={3}
                  />
                </div>
              </div>
            </div>
            <div className="col" />
          </div>
          <div className="row">
            <div className="col-12 text-left Remarks">
              <p>{remarks || 'Sin comentarios.'}</p>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}
