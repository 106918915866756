import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { withForm } from '@casanova/casanova-common';
import { useFormikContext } from 'formik';
import { ContractInformation, Vehicles } from '@contracts/sections';
import { PreventChange } from 'components';
import { FORM_ACTIONS } from 'utils/constants/formActions';

function Contract(props) {
  const {
    match,
    action = FORM_ACTIONS.ADD,
    getContractDetails,
    contractDetails,
    onUpdateForm,
    cleanContractDetails,
  } = props;

  const { dirty, isSubmitting } = useFormikContext();

  const contractId = match.params.id;

  useEffect(() => {
    if (action !== FORM_ACTIONS.ADD) {
      getContractDetails({ uuid: contractId });
    }
  }, [contractId, action, getContractDetails]);

  useEffect(() => {
    if (action !== FORM_ACTIONS.ADD && !_isEmpty(contractDetails.data)) {
      onUpdateForm(contractDetails.data);
    }
  }, [action, onUpdateForm, contractDetails]);

  useEffect(
    () => () => {
      cleanContractDetails();
    },
    [cleanContractDetails]
  );

  const hasPreventChange =
    action !== FORM_ACTIONS.VIEW && dirty && !isSubmitting;

  return (
    <PreventChange preventChange={hasPreventChange}>
      <ContractInformation {...props} />
      <Vehicles {...props} />
    </PreventChange>
  );
}

export default ({
  responseDialog: initialResponseDialog = {},
  config: initialConfig,
  ...props
}) => {
  const [config, setConfig] = useState(initialConfig);
  const [responseDialog, setResponseDialog] = useState(initialResponseDialog);

  useEffect(() => {
    setResponseDialog(initialResponseDialog);
  }, [initialResponseDialog]);

  const Component = withForm({ config })(Contract);
  return Component({ ...props, setConfig, responseDialog, setResponseDialog });
};
