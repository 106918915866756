import React from 'react';
import { Card } from 'components';
import './CardFirm.scss';

export default function CardFirm({
  signature = '',
  name = '',
  className = '',
  onClick,
}) {
  return (
    <Card
      noStatus
      title="FIRMA DE ACEPTACIÓN"
      padding="p-3"
      className={`CardInfo__container my-2 p-3 ${className}`}
      titleClassName=""
      upperCase
      onClick={onClick}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {signature ? (
              <div className="justify-content-center d-flex align-items-center">
                <img
                  src={signature}
                  alt={`${name}`}
                  className="CardFirm__Firm"
                />
              </div>
            ) : (
              <div className="CardFirm__Firm" />
            )}
          </div>
        </div>
        <div className="row p-0 m-0">
          <p className="m-0 text-left text-overflow-ellipsis">{name}</p>
        </div>
      </div>
    </Card>
  );
}
