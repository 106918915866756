import _get from 'lodash/get';
import { call, put, select } from 'redux-saga/effects';
import { showLoader, hideLoader } from 'store/commons/actions';
import {
  getReportsList,
  getTypesOfReports,
  getDownloadReports,
} from 'services/reports';
import {
  getReportsListSucceed,
  getReportsListFailed,
  getTypesOfReportsSucceed,
  getTypesOfReportsFailed,
  downloadReportsSucceed,
  downloadReportsFailed,
} from './actions';
import { getOptionsAuth } from '../../services/auth';

export function* getReportsListSaga(action) {
  try {
    yield put(showLoader());
    const branch = _get(getOptionsAuth(), 'user.branch', null);
    const request = { params: action.payload.params.params };
    const response = yield call(
      getReportsList,
      action.payload.params,
      request,
      branch
    );

    yield put(getReportsListSucceed(response));
  } catch (error) {
    yield put(getReportsListFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getTypesOfReportsSaga(action) {
  try {
    yield put(showLoader());
    const typesOfReports = yield select(
      (state) => state.reports.typesOfReports
    );

    if (typesOfReports.list.length > 0 && typesOfReports.success) return;

    const data = yield call(getTypesOfReports, action.payload);
    yield put(getTypesOfReportsSucceed(data));
  } catch (error) {
    yield put(getTypesOfReportsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* downloadReportsSaga(action) {
  try {
    yield put(showLoader());
    const branch = _get(getOptionsAuth(), 'user.branch', null);
    const request = { params: action.payload.params.params };
    const data = yield call(
      getDownloadReports,
      action.payload.params,
      request,
      branch
    );

    yield put(downloadReportsSucceed(data));
  } catch (error) {
    yield put(downloadReportsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}
