import { takeLatest } from 'redux-saga/effects';
import types from './action-types';
import {
  getContractsFiltersSaga,
  getListOfContractsSaga,
  saveContractSaga,
  getContractDetailsSaga,
  changeOfVehicleSaga,
  createLoanSaga,
} from './sagas';

export default function* watchers() {
  yield takeLatest(types.GET_LIST, getListOfContractsSaga);
  yield takeLatest(types.GET_FILTERS, getContractsFiltersSaga);
  yield takeLatest(types.SAVE_CONTRACT, saveContractSaga);
  yield takeLatest(types.GET_CONTRACT_DETAILS, getContractDetailsSaga);
  yield takeLatest(types.CHANGE_OF_VEHICLE, changeOfVehicleSaga);
  yield takeLatest(types.CREATE_LOAN, createLoanSaga);
}
