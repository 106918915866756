import React from 'react';
import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { getQuotePath } from 'utils/navigation';
import { closeResponseDialog } from 'store/customers/actions';
import { resetReservations } from 'store/reservations/actions';
import { withLayout } from 'hooks';
import {
  DeliveryReturnStepThree,
  LeftDeliveryReturnStepThree as Left,
} from '@reservations/sections/DeliveryRefluxSteps';
import { config } from './config';

export default withLayout(
  compose(
    connect(
      (state) => ({
        loader: _get(state, 'commons.loader', false),
        onCancelPath: `${getQuotePath('one')}?isCanceling=true`,
        left: <Left />,
        responseDialog: _get(state, 'reservations.responseDialog', {}),
        refund: _get(state, 'reservations.refund', false),
        leftCol: 4,
        middleCol: 4,
        confirmationMessage: false,
        config: config({
          folio: _get(state, 'reservations.reservationInfo.data.folio', ''),
          reservationStep: _get(
            state,
            'reservations.reservationInfo.data.step',
            ''
          ),
        }),
      }),
      {
        closeResponseDialog,
        resetReservations,
      }
    )
  )(DeliveryReturnStepThree)
);
