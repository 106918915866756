import React, { useEffect, useState, useCallback } from 'react';
import './Payment.scss';
import { useFormikContext } from 'formik';
import { Checkbox } from '@casanova/casanova-common';
import {
  CardMethod,
  CashMethod,
  DepositMethod,
  TransferMethod,
  InvoicePayment,
} from '..';
import i18n from '@i18n';
import { number2mxn } from 'utils/currency';
import { obtenerHoraActual } from '../index';
import _isEmpty from 'lodash/isEmpty';

const MultiPayments = ({
  touched,
  onUpdateForm,
  uuidCustomer,
  fetchCatalog,
}) => {
  const [hasUpdatedHoldBack, setHasUpdatedHoldBack] = useState(false);
  const [hasTotalPay, setHasTotalPay] = useState(0);

  const { errors, setFieldValue, values } = useFormikContext();

  const [hasUpdateDifMethods, setHasUpdateDifMethods] = useState(false);

  const handleMethodChange = useCallback(() => {
    if (hasUpdatedHoldBack) {
      setFieldValue('holdbackCard', '');
      setFieldValue('holdbackBank', '');
    }
  }, [hasUpdatedHoldBack]);

  const setPaymentHour = () =>
    setInterval(() => {
      setFieldValue('paymentTime', obtenerHoraActual());
    }, 1000);

  useEffect(() => {
    setPaymentHour();
  }, []);

  useEffect(() => {
    if (_isEmpty(values.selectedMethods)) {
      clearInterval(setPaymentHour);
    }
  }, [values.selectedMethods]);

  useEffect(() => {
    const sumAmounts = sumaTotal();
    setHasTotalPay(sumAmounts);
    if (sumAmounts === Number(values.amount)) {
      values.priceIsInvalid = true;
    } else {
      values.priceIsInvalid = false;
    }
  }, [
    values.amountCard,
    values.amountCash,
    values.amountTransfer,
    values.amountDeposit,
  ]);

  function sumaTotal() {
    const total =
      Number(values.amountCard) +
      Number(values.amountCash) +
      Number(values.amountDeposit) +
      Number(values.amountTransfer);

    return total;
  }

  useEffect(() => {
    setFieldValue('diffMethods', hasUpdateDifMethods);
  }, [hasUpdateDifMethods]);

  const handleDifMethodsChange = () => {
    if (hasUpdateDifMethods) {
      setHasUpdateDifMethods(false);
      setHasTotalPay(0);
      values.amountCard = '';
      values.amountCash = '';
      values.amountTransfer = '';
      values.amountDeposit = '';
      values.methodSelected = '';
      values.selectedMethods = [];
      return;
    }
    setHasUpdateDifMethods(true);
  };

  return (
    <>
      <div className="payment-page">
        <div className="payment-page__main">
          <div className="payment-page__main__title my-4 d-flex justify-content-between align-items-center">
            <p>Método de pago</p>
            {hasUpdateDifMethods && <p>{number2mxn(hasTotalPay)}</p>}
            <div>
              {/* <Checkbox
                name="diffMethods"
                label="Pagar con diferentes métodos"
                onChange={handleDifMethodsChange}
              /> */}
            </div>
          </div>
          <CardMethod
            setHasUpdatedHoldBack={setHasUpdatedHoldBack}
            hasUpdatedHoldBack={hasUpdatedHoldBack}
            hasUpdateDifMethods={hasUpdateDifMethods}
            uuidCustomer={uuidCustomer}
            fetchCatalog={fetchCatalog}
          />
          <DepositMethod
            onSelect={handleMethodChange}
            hasUpdateDifMethods={hasUpdateDifMethods}
          />
          <TransferMethod
            onSelect={handleMethodChange}
            hasUpdateDifMethods={hasUpdateDifMethods}
          />
          <CashMethod
            onSelect={handleMethodChange}
            hasUpdateDifMethods={hasUpdateDifMethods}
          />
        </div>
        <InvoicePayment onUpdateForm={onUpdateForm} />
        {errors.methodSelected && touched.methodSelected && (
          <div className="col-form-error text-danger">
            {errors.methodSelected}
          </div>
        )}
      </div>
    </>
  );
};

MultiPayments.defaultProps = {
  maxSteps: 2,
  step: 2,
  title: i18n('RESERVATIONS__STEPPER__TITLE'),
  subtitle: i18n('RESERVATIONS__STEPPER__SUBTITLE'),
};

export default MultiPayments;
