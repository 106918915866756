import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import OfficialIdentificationDetail from './OfficialIdentification';

export default compose(
  connect(
    (state) => ({
      data: _get(state, 'customers.customerDetail.data', {}),
      listOfInvalidsDocuments: _get(
        state,
        'customers.customerDetail.data.listOfInvalidsDocuments',
        []
      ),
    }),
    {}
  )
)(OfficialIdentificationDetail);
