const transformer = (data) => ({
  invoiceUuid: data.uuid,
  socialReason: data.customerFullName,
  rfc: data.rfc,
  paymentForm: '',
  paymentMethod: '',
  amount: '',
  date: '',
  time: '',
});

export default transformer;
