import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { setContractQuote } from 'store/reservations/actions';
import CustomerForms from './CustomerForms';

export default compose(
  connect(
    (state) => ({
      loans: state.loans.list,
      confirmationMessage: false,
    }),
    { setContractQuote }
  )
)(CustomerForms);
