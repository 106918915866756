import { useMemo, useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import { Button, Loader, ActionModal, Icons } from '@casanova/casanova-common';

import BackButton from 'components/BackButton';
import PDF from 'containers/PDF';
import moment from 'moment';
import { YYYY_MM_DD } from '@casanova/casanova-common/lib/utils/date';

const PDF_ID = 'current_pdf';

export default function CloseContract({ match, loader }) {
  const fechaCierre = useSelector((state) =>
    _get(
      state,
      'dailyRentContracts.detailsOfCloseContract.data.fechaCierre',
      ''
    )
  );
  const [hasAvailablePrint, setHasAvailablePrint] = useState(false);
  const [showCancelPromissoryNote, setShowCancelPromissoryNote] = useState(
    false
  );
  const [isLoaded, setIsLoaded] = useState(false);

  const pdfRef = useRef(null);

  const handlePrint = () => {
    pdfRef?.current?.contentWindow.print();
  };

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const VIEW_PDF_URL = useMemo(
    () =>
      `${window.location.origin}/pdf?view=true&uuid=${match.params.id}&parentUrl=${window.location.href}`,
    [match.params.id]
  );

  useEffect(() => {
    if (pdfRef.current && window && window.parent) {
      window.addEventListener(
        'message',
        (e) => {
          if (e.origin !== window.location.origin) {
            return;
          }
          const hasFetched = e.data === 'hasFetched';

          if (hasFetched) {
            setHasAvailablePrint(true);
          }
        },
        false
      );
    }
  }, [pdfRef.current]);

  useEffect(() => {
    const fechaCierreMoment = moment(fechaCierre, YYYY_MM_DD);
    const momentNow = moment(new Date(), YYYY_MM_DD);

    if (fechaCierreMoment.isSame(momentNow.format(YYYY_MM_DD))) {
      setShowCancelPromissoryNote(true);
    }
  }, [fechaCierre]);

  return (
    <>
      <Loader show={!loader && !hasAvailablePrint} />
      <ActionModal
        open={showCancelPromissoryNote}
        onClose={() => setShowCancelPromissoryNote(false)}
        closeButton
        topContainerClassName={loader ? 'd-none' : ''}
        onlySubmit={true}
        onAction={() => setShowCancelPromissoryNote(false)}
        modalType="confirmation"
        actionLabel="Continuar"
        title="El pagaré fué cancelado"
        icon={<Icons.StatusWarning />}
      />
      <section className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-8">
            <PDF withLoader={false} />
          </div>
          <div className="col-12 d-none">
            <iframe
              ref={pdfRef}
              title="Detalle cierre de contrato"
              id={PDF_ID}
              name={PDF_ID}
              src={VIEW_PDF_URL}
              onLoad={handleLoad}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 col-xl-3">
            <BackButton path="/daily-rent-contracts/" />
          </div>
          <div className="col" />
          <div className="col-md-4 col-xl-3">
            {hasAvailablePrint && isLoaded && (
              <Button block onClick={handlePrint}>
                Imprimir
              </Button>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

CloseContract.propTypes = {
  match: PropTypes.object,
};
