import _get from 'lodash/get';
import { connect } from 'react-redux';
import RefundDetail from './RefundDetail';
import compose from 'recompose/compose';

export default compose(
  connect(
    (state) => ({
      reservationDetails: _get(state, 'reservations.reservationInfo.data', {}),
      vehicleLinePricesDetails: _get(
        state,
        'reservations.vehicleLinePrices.data',
        []
      ),
      rentalPlanName: _get(
        state,
        'reservations.reservationInfo.data.priceQuote.selectedVehicleLinePrice.vehicleLinePrice.rentalPlanName',
        ''
      ),
      customerRefund: _get(
        state,
        'customers.customerDetail.data.customerMovementRefund',
        {}
      ),
    }),
    {}
  )
)(RefundDetail);
