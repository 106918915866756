import React, { useEffect, useCallback, useRef } from 'react';
import { Formik, yupToFormErrors, validateYupSchema } from 'formik';
import _get from 'lodash/get';
import {
  MaskedInput as Input,
  DatePickerV2,
  FileInput,
  NumberInput,
  TextAreaInput,
} from '@casanova/casanova-common';
import { policy as policyMeta } from 'services/vehicles/additionals';
import { policyValidation } from 'utils/validations/additionals';
import { numericMask } from 'utils/masks';
import { CREATE_VEHICLE_POLICY } from 'utils/roles/permissions';
import PreventChange from 'components/PreventChange';

import moment from 'moment';
import PlainInput from '@casanova/casanova-common/lib/Input/PlainInput';

import UserInfo from 'components/UserInfo';
import AdditionalWrapper from '@additionals/components/AdditionalModals';
import { MINIMUM_DATE, MAXIMUM_DATE } from '@vehicles/common/constants';
import { TEXT_AREA_NOT_ALLOWED_CHARACTERS } from 'utils/validations';
import { withAdditionalCollapse } from '../AdditionalCollapse';
import { SelectWithOptions } from '../components';

function Policy(props) {
  const {
    addNew,
    name,
    vehicleId,
    fetchCatalog,
    getAdditional,
    editable,
    data,
    isNew,
    vehicle,
    saveAdditional,
    show,
  } = props;
  const formRef = useRef(null);
  useEffect(() => {
    if (!show && formRef) {
      formRef.current.resetForm(policyMeta.empty);
    }
  }, [show]);

  useEffect(() => {
    if (vehicleId) {
      getAdditional({
        name,
        data: vehicleId,
        withFiles: ['receipt', 'policy'],
      });
      fetchCatalog('insurance');
      fetchCatalog('policy-status');
      fetchCatalog('policy-use');
    }
  }, [name, fetchCatalog, vehicleId, getAdditional]);

  const months = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];

  const handleFormik = useCallback(
    (values) => {
      const policyNumber = _get(data, 'policyNumber');
      saveAdditional({
        name,
        data: {
          ...values,
          vehicleId,
          receipt: values.policyReceipt,
          serialNumber: vehicle.serialNumber,
        },
        forceCreate: policyNumber && policyNumber !== values.policyNumber,
        addNew,
      });
    },
    [data, addNew, saveAdditional, name, vehicleId, vehicle]
  );

  const handleValidation = (values) => {
    try {
      validateYupSchema(values, policyValidation, true, {
        vehicle,
        serialNumber: vehicle.serialNumber,
      });
    } catch (error) {
      return yupToFormErrors(error);
    }
    return {};
  };

  return (
    <div className="container-fluid">
      <Formik
        initialValues={addNew ? policyMeta.empty : data || policyMeta.empty}
        validateOnChange
        validateOnBlur
        onSubmit={handleFormik}
        validate={handleValidation}
        enableReinitialize
        innerRef={formRef}
      >
        {(formikProps) => {
          const {
            initialValues,
            values,
            handleSubmit,
            dirty,
            setFieldValue,
          } = formikProps;

          const handleChangeValidityStart = (e) => {
            if (moment(e, 'YYYY-MM-DD').isValid()) {
              const endDate = moment(e, 'YYYY-MM-DD').add(1, 'year').toDate();
              const day = `${
                endDate.getDate() >= 10
                  ? endDate.getDate()
                  : `0${endDate.getDate()}`
              }`;
              const display = ` ${day} / ${
                months[endDate.getMonth()]
              } / ${endDate.getFullYear()}`;
              setFieldValue(
                'validityEnd',
                moment(endDate).format('YYYY-MM-DD')
              );
              setFieldValue('validityEndDisplay', display);
            }
          };
          return (
            <PreventChange preventChange={dirty && editable}>
              <AdditionalWrapper
                roleVerifierIdentifier={CREATE_VEHICLE_POLICY}
                handleSubmit={handleSubmit}
                formikProps={formikProps}
                emptyValues={policyMeta.empty}
                extraProps={props}
              >
                <div className="row">
                  {!isNew && !addNew && (
                    <div className="col-12 col-md-4">
                      <PlainInput
                        name="status"
                        disabled
                        label="Estatus"
                        limit={10}
                      />
                    </div>
                  )}
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="insurance"
                      label="Aseguradora*"
                      options="catalogs.insurance"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Input
                      mask={numericMask(30)}
                      maskPlaceholder=""
                      name="policyNumber"
                      label="Número de Póliza*"
                      placeholder="Número de Póliza*"
                      whitespace={false}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Input
                      mask={numericMask(5)}
                      maskPlaceholder=""
                      name="endorsement"
                      label="Endoso*"
                      placeholder="Endoso*"
                      whitespace={false}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Input
                      mask={numericMask(5)}
                      maskPlaceholder=""
                      name="topic"
                      label="Inciso*"
                      placeholder="Inciso*"
                      whitespace={false}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <DatePickerV2
                      name="validityStart"
                      onChange={handleChangeValidityStart}
                      label="Vigencia desde*"
                      placeholder="Vigencia desde*"
                      initialValue={initialValues.validityStart}
                      disabled={!editable && !isNew}
                      minimumDate={MINIMUM_DATE}
                      maximumDate={MAXIMUM_DATE}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <PlainInput
                      name="validityEndDisplay"
                      label="Vigencia hasta*"
                      placeholder="Vigencia hasta*"
                      disabled={values.validityEnd}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <DatePickerV2
                      name="emissionDate"
                      label="Fecha de emisión*"
                      placeholder="Fecha de emisión*"
                      initialValue={initialValues.emissionDate}
                      disabled={!editable && !isNew}
                      minimumDate={MINIMUM_DATE}
                      maximumDate={MAXIMUM_DATE}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="policyUse"
                      label="Uso de Póliza*"
                      options="catalogs.policyUse"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <NumberInput
                      maskSuffix=" mxn"
                      decimals={2}
                      max={99_999.99}
                      name="totalBonus"
                      label="Prima Total*"
                      placeholder="Prima Total*"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FileInput
                      url={!isNew ? values.policyReceiptUrl : undefined}
                      displayText={values.policyReceipt}
                      type="file"
                      label="Comprobante de pago*"
                      placeholder="Comprobante de pago*"
                      name="policyReceipt"
                      accept="application/pdf"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FileInput
                      url={!isNew ? values.policyUrl : undefined}
                      displayText={values.policy}
                      type="file"
                      label="Poliza*"
                      placeholder="Poliza*"
                      name="policy"
                      accept="application/pdf"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12">
                    <TextAreaInput
                      label="Observaciones"
                      placeholder="Observaciones"
                      name="policyRemarks"
                      disabled={!editable && !isNew}
                      regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS}
                      maxLength={200}
                    />
                  </div>
                </div>
                <UserInfo {...data} />
              </AdditionalWrapper>
            </PreventChange>
          );
        }}
      </Formik>
    </div>
  );
}
export default withAdditionalCollapse({ title: 'Póliza de Seguro' })(Policy);
