import React from 'react';

export const GrayDisclaimer = () => (
  <div className="row ">
    <div className="col-12 text-center">
      <div className="gray-disclaimer">
        La hora de salida y devolución son con horario de la Zona Centro de
        México.
      </div>
    </div>
  </div>
);

export default GrayDisclaimer;
