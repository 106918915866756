import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { closeResponseDialog } from 'store/customers/actions';
import _get from 'lodash/get';
import {
  Switch,
  MaskedInput as Input,
  TextAreaInput,
  withForm,
} from '@casanova/casanova-common';
import { numericMask } from 'utils/masks';
import { TEXT_AREA_NOT_ALLOWED_CHARACTERS } from 'utils/validations';
import config from './config';

export const FieldsBan = ({ action = 'add' }) => (
  <div className="row">
    <div className="col-3">
      <Input
        mask={numericMask(50)}
        maskPlaceholder=""
        label="No. de contrato*"
        placeholder="No. de contrato*"
        name="contractNumber"
        disabled={action === 'view'}
      />
    </div>
    <div className="col-12 col-md-12">
      <TextAreaInput
        name="reason"
        label="Motivo"
        placeholder="Escribe aquí el motivo*"
        disabled={action === 'view'}
        regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS}
        maxLength={200}
      />
    </div>
  </div>
);

const Fields = withForm({ config })(FieldsBan);

const FieldsConnected = compose(
  connect(
    (state) => ({
      responseDialog: _get(state, 'customers.banResponseDialog', {}),
    }),
    {
      closeResponseDialog,
    }
  )
)(Fields);

const Ban = ({ title, disabled, showComments, ban = false }) => (
  <Formik initialValues={{ ban }}>
    {({ values, setFieldValue }) => {
      const handleCancel = () => {
        setFieldValue('ban', false);
      };

      return (
        <>
          <div>
            <div>
              <label htmlFor="ban">{title}</label>
            </div>
            <div>
              <Switch name="ban" disabled={disabled} />
            </div>
          </div>
          {values.ban && showComments && (
            <FieldsConnected onCancel={handleCancel} />
          )}
        </>
      );
    }}
  </Formik>
);

Ban.propTypes = {
  values: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  disabled: PropTypes.bool,
  showComments: PropTypes.bool,
};

Ban.defaultProps = {
  values: {},
  title: '¿Quieres boletinar al cliente?',
  disabled: false,
  showComments: true,
};

export default Ban;
