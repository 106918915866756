import qs from 'qs';
import { sendCustomRequest } from '@casanova/casanova-common/lib/utils/service';
import { APIError } from 'utils/exceptions';
import { cleanParams } from 'utils/requests';

const root = 'vehicle/v1';

export async function getLoansList({ params = {} }, options = {}) {
  const queryParams = `?${qs.stringify(cleanParams(params), {
    encode: false,
  })}`;
  const endpoint = `${root}/loans${queryParams}`;

  const response = await sendCustomRequest(endpoint, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { data = { content: [] } } = response.data;

  return data;
}

export async function postLoan(request) {
  const endpoint = `${root}/loans`;
  const response = await sendCustomRequest(endpoint, {}, request, 'post');
  if (!response.success || response.data.errorCode) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getLoanDetails(uuid, options = {}) {
  const endpoint = `${root}/loans/${uuid}`;

  const response = await sendCustomRequest(endpoint, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { data = {} } = response.data;
  return data;
}

export async function patchLoan(request) {
  const endpoint = `${root}/loans/${request.uuid}`;
  delete request.uuid;
  const response = await sendCustomRequest(endpoint, {}, request, 'PATCH');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function postCloseLoan(request) {
  const endpoint = `${root}/loans/${request.uuid}/closure`;
  delete request.uuid;
  const response = await sendCustomRequest(endpoint, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function postCancelLoan(request) {
  const endpoint = `${root}/loans/${request.uuid}/cancellation`;
  delete request.uuid;
  const response = await sendCustomRequest(endpoint, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function postAuthorizeLoan(request) {
  const endpoint = `${root}/loans/${request.uuid}/authorization`;
  delete request.uuid;
  const response = await sendCustomRequest(endpoint, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}
