import {
  getToken,
  getPermissions,
  getAllowedPaths,
  getAllowedModules,
  logout,
} from 'services/auth';
import pathRegexs from 'utils/roles/paths';
import { getRoleName } from '../../services/auth';

export function validatePath(current) {
  const allowedPaths = getAllowedPaths();
  try {
    const paths = allowedPaths.filter((path) => pathRegexs[path].test(current));
    return paths.length > 0;
  } catch (e) {
    logout();
  }
}

export function validateElement(identifier) {
  const permissions = getPermissions();
  if (permissions) {
    const elements = permissions.filter((element) => element === identifier);
    return elements.length > 0;
  }
  return false;
}

export const validateRolePermissions = validateElement;

export function validateModuleAccess(moduleName) {
  if (!getToken()) return false;
  const modules = getAllowedModules();
  if (modules) {
    return modules.indexOf(moduleName) !== -1;
  }
  return false;
}

export function validateRole(identifier) {
  const role = getRoleName();
  if (role) {
    const elements = identifier.filter((element) => element === role);
    return elements.length > 0;
  }
  return false;
}
