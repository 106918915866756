import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import moment from 'moment';
import _get from 'lodash/get';

import {
  DatePickerV2 as DatePicker,
  Select,
  Button,
  Input,
} from '@casanova/casanova-common';

import { SelectWithOptions } from 'components';

import { transformForSelect } from '@casanova/casanova-common/lib/utils/transformHelpers';
import {
  formattedDateToPickerV2,
  getPrevYear,
  getNextYear,
  timeZone,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/date';
import i18n from '@i18n';

import { REPORTS_NO_FILTERS_TO_SHOW } from '../../common/constants';
import { CheckBox } from '@reports/components';

const MIN_DATE = getPrevYear({ years: 2 });
const MAX_DATE = formattedDateToPickerV2(
  moment().add(1, 'day').tz(timeZone).format(YYYY_MM_DD)
);

const setTouchedValuesByReportType = {
  '38edfcf6-7ce3-4ec0-a33b-2e8cb49bdb68': {
    name: 'Contratos aperturados / cerrados / vencidos',
    MAX_DATE: getNextYear(),
  },
  'cffa7a6b-e405-4945-85f2-d9827f9f532d': {
    name: 'Devoluciones de vehículos',
    MAX_DATE: getNextYear(),
  },
  'bf17b12c-79b8-416d-9e89-17f792b1e7cc': {
    name: 'Traslados llegadas / salidas',
    MAX_DATE: getNextYear(),
  },
  'ff5b204d-1282-4ebd-a6db-d177d957c22d': {
    name: 'Servicios por vehículo',
    MAX_DATE: getNextYear(),
  },
};

export default function SearchReportForm({
  typesOfReports,
  getTypesOfReports,
  setHasResetList,
  fetchCatalog,
}) {
  const { handleSubmit, resetForm, setFieldValue } = useFormikContext();
  const [listFields, setListFields] = useState([]);
  const [secondDatePickerOrder, setSecondDatePickerOrder] = useState('');
  const [dynamicMinDate, setDynamicMinDate] = useState();
  const [chooseReportType, setChooseReportType] = useState(false);
  const [datePickerFields, setDatePickerFields] = useState([]);
  const [maximumDate, setMaximumDate] = useState(MAX_DATE);

  const renderFields = (e) => {
    const options = typesOfReports.find((item) => item.uuid === e.target.value);
    const dateFields =
      options &&
      options.params &&
      options.params.filter((item) => item.type === 'DATE');
    const secondDatePicker = _get(dateFields, '1.order', null);

    setSecondDatePickerOrder(secondDatePicker);
    setListFields(options.params);
    resetForm();
    setFieldValue('reportType', e.target.value);

    options.params.forEach((field) => setFieldValue(`key${field.order}`, ''));

    setMaximumDate(
      setTouchedValuesByReportType[e.target.value]?.MAX_DATE ?? MAX_DATE
    );
    setChooseReportType(true);
    setDatePickerFields(dateFields);
  };

  useEffect(() => {
    getTypesOfReports();
  }, [getTypesOfReports, typesOfReports]);

  useEffect(() => {
    if (listFields.length > 0) {
      listFields.forEach(
        (field) => field.path && fetchCatalog(JSON.parse(field.path))
      );
    }
  }, [listFields]);

  return (
    <div className="row">
      <div className="col-md-4">
        <Select
          label={i18n('REPORTS__SEARCH_REPORT_FORM__REPORT_TYPES', ['*'])}
          placeholder={i18n('REPORTS__SEARCH_REPORT_FORM__REPORT_TYPES', ['*'])}
          name="reportType"
          options={transformForSelect(typesOfReports, 'uuid', 'name')}
          onChange={(e) => {
            renderFields(e);
          }}
        />
      </div>

      {listFields.length > 0
        ? listFields.map((item) => {
            const fieldToShow = [];

            switch (item.type) {
              case 'NUMBER':
                fieldToShow.push(
                  <div className="col-md-4" key={`key_container_${item.order}`}>
                    <Input
                      label={item.name}
                      placeholder={item.name}
                      name={`key${item.order}`}
                    />
                  </div>
                );
                break;
              case 'DATE':
                fieldToShow.push(
                  <div className="col-md-4" key={`key_container_${item.order}`}>
                    <DatePicker
                      label={item.name}
                      placeholder={item.name}
                      name={`key${item.order}`}
                      minimumDate={
                        datePickerFields.length === 2 &&
                        secondDatePickerOrder === item.order
                          ? dynamicMinDate
                          : MIN_DATE
                      }
                      maximumDate={maximumDate}
                      onChange={(value) => {
                        if (
                          datePickerFields.length === 2 &&
                          secondDatePickerOrder !== item.order
                        ) {
                          setFieldValue(`key${secondDatePickerOrder}`, '');
                          setDynamicMinDate(formattedDateToPickerV2(value));
                        }
                      }}
                      resetDate={true}
                      onResetDate={() => {
                        setFieldValue(`key${item.order}`, '');
                      }}
                    />
                  </div>
                );
                break;
              case 'STRING':
                fieldToShow.push(
                  <div className="col-md-4" key={`key_container_${item.order}`}>
                    <Input
                      type="text"
                      label={item.name}
                      placeholder={item.name}
                      name={`key${item.order}`}
                    />
                  </div>
                );
                break;
              case 'DROPDOWN':
                fieldToShow.push(
                  <div className="col-md-4" key={`key_container_${item.order}`}>
                    <SelectWithOptions
                      label={item.name}
                      placeholder={item.name}
                      name={`key${item.order}`}
                      options={`commons.${JSON.parse(item.path).catalogName}`}
                      className="m-0 select"
                    />
                  </div>
                );
                break;
              case 'CHECKBOX':
                fieldToShow.push(
                  <div
                    className="col-md-4 d-flex align-items-end"
                    key={`key_container_${item.order}`}
                  >
                    <CheckBox name={`key${item.order}`} label={item.name} />
                  </div>
                );
                break;
              default:
                break;
            }

            return fieldToShow;
          })
        : chooseReportType && (
            <div className="col-md-4 mt-5">
              <b>{REPORTS_NO_FILTERS_TO_SHOW}</b>
            </div>
          )}

      <div className="col-md-3 offset-md-9">
        <Button
          block
          type="submit"
          onClick={() => {
            setHasResetList(false);
            handleSubmit();
          }}
        >
          {i18n('COMMONS__SEARCH')}
        </Button>
      </div>
    </div>
  );
}

SearchReportForm.propTypes = {
  typesOfReports: PropTypes.arrayOf(PropTypes.object).isRequired,
  getTypesOfReports: PropTypes.func.isRequired,
  setHasResetList: PropTypes.func.isRequired,
};
