import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icons } from '@casanova/casanova-common';
import { Offer } from '@reservations/components';
import './Carrousel.scss';

const Carrousel = ({
  loop = false,
  elements,
  onSelect,
  pageSize = 3,
  title = '',
  initial = undefined,
}) => {
  const [selected, setSelected] = useState(initial);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (elements.length > 0) {
      const allElements = [...elements];
      const allPages = [];
      while (allElements.length > 0) {
        const page = allElements.splice(0, pageSize);
        allPages.push(page);
      }
      setPages(allPages);
    }
  }, [elements, pageSize]);

  const handleOnSelect = useCallback(
    (lineUuid) => {
      if (selected === lineUuid) {
        setSelected(undefined);
        onSelect(undefined);
      } else {
        setSelected(lineUuid);
        onSelect(lineUuid);
      }
    },
    [onSelect, selected]
  );

  const handleDecreasePage = useCallback(() => {
    if (!loop && currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }

    if (loop && currentPage === 0) {
      setCurrentPage(pages.length - 1);
    }
  }, [setCurrentPage, currentPage, pages, loop]);

  const handleIncreasePage = useCallback(() => {
    if (!loop && currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }

    if (loop && currentPage === pages.length - 1) {
      setCurrentPage(0);
    }
  }, [setCurrentPage, currentPage, pages, loop]);

  return (
    <>
      <div className="OfferCarrousel__title">{title}</div>
      <div className="OfferCarrousel">
        <span
          className="OfferCarrousel__control OfferCarrousel__control__left"
          onClick={handleDecreasePage}
        >
          {(currentPage > 0 || loop) && (
            <Icons.ChevronLeft width="1.375rem" height="1.375rem" />
          )}
        </span>
        <div className="OfferCarrousel__elements">
          {pages &&
            pages.length > 0 &&
            pages[currentPage].map((viewed) => (
              <Offer
                image={viewed.originalImgUrl}
                vehicle={viewed.vehicleLineName}
                amount={viewed.minDailyPrice}
                line={viewed.vehicleLineUuid}
                onClick={handleOnSelect}
                selected={selected}
                key={`${viewed.vehicleLineName}_${viewed.vehicleLineUuid}_${viewed.originalImgUrl}`}
              />
            ))}
        </div>
        <span
          className="OfferCarrousel__control OfferCarrousel__control__right"
          onClick={handleIncreasePage}
        >
          {(currentPage < pages.length - 1 || loop) && (
            <Icons.ChevronRight width="1.375rem" height="1.375rem" />
          )}
        </span>
      </div>
    </>
  );
};

Carrousel.propTypes = {
  loop: PropTypes.bool,
};

export default Carrousel;
