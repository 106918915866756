import moment from 'moment';
import _get from 'lodash/get';
import {
  getFieldSearch,
  getLastSearchField,
} from '@casanova/casanova-common/lib/utils/transformHelpers';
import { transformIdOfList } from 'utils/transformHelpers';
import { generateHoursSelect, isToday } from 'utils/date';
import {
  LIMIT_HOUR_TODAY_SATURDAY,
  LIMIT_HOUR_TODAY_NATURAL_DAY,
} from 'utils/constants/dateAndTime';
import { YYYY_MM_DD, ISO_DATE_FORMAT, MX_DATE_FORMAT } from 'utils/constants';
import types from './action-types';
import { getBrach, getRoleName } from '../../services/auth';

const initialState = {
  listOfReservations: {
    list: {
      params: {
        page: 0,
        size: 10,
      },
      searchPattern: '',
      filters: [],
      totalPages: 1,
      totalElements: 10,
      results: {
        page: 0,
        list: [],
        loading: false,
        loaded: false,
      },
    },
  },
  responseDialog: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
    modalType: '',
  },
  responseDialogUpdateReservation: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
    modalType: '',
  },
  responseDialogUpdateCard: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
    modalType: '',
  },
  responseDialogReservationVehicle: {
    open: false,
    success: false,
    errorCode: '',
    message: '',
  },
  quote: {
    title: 'Seleccionar tipo de cotización',
    subtitle: 'Elige los parametrós de búsqueda que te indique el cliente.',
    editScheduleAction: 'view',
    values: {},
    availableTime: {
      success: true,
      list: [],
    },
    selectedDates: {
      start: {
        limitStart: null,
        limitEnd: null,
      },
      end: {
        limitStart: null,
        limitEnd: null,
      },
    },
    leaveStepOne: false,
    vehicleCategory: null,
    isContract: false,
    inProgress: false,
  },
  notPriceQuote: {
    error: false,
    errorCode: '',
    message: '',
    success: false,
    data: {},
  },
  catalog: {
    params: {
      page: 0,
      size: 10,
      property: 'id',
      direction: 'asc',
    },
    totalPages: 1,
    totalElements: 10,
    last: false,
    results: [],
  },
  filters: {
    categories: [],
    brands: [],
    selectedCategory: { types: [] },
    discounts: [],
    previousSelectedCategory: null,
    reservationList: {
      statuses: [],
      steps: [],
      deliveryBranches: [],
      returnBranches: [],
      minCreatedDate: '',
      maxCreatedDate: '',
      minDeliveryDate: '',
      maxDeliveryDate: '',
      minReturnDate: '',
      maxReturnDate: '',
    },
  },
  delivery: {
    stepOne: {
      success: false,
    },
    stepTwo: {
      success: false,
    },
    stepThree: {
      success: false,
    },
  },
  extendContract: false,
  changeVehicle: false,
  refund: {
    stepOne: {
      success: false,
    },
    stepTwo: {
      success: false,
    },
    stepThree: {
      success: false,
    },
  },
  reservationInfo: {
    success: false,
    error: false,
    errorMessage: '',
    isUpdateReservationModalOpen: false,
    data: {},
  },
  sendFirmForm: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
  verifyCheckPoint: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
    loading: false,
    actualPosition: 1,
  },
  verifyCompletedCheckList: {
    success: false,
    error: false,
    errorMessage: '',
    data: false,
  },
  reservation: {
    id: '',
    newInvoicePayment: false,
    payment: {
      data: {},
      hasAddedBillingDetails: false,
    },
    sendReservation: {
      data: {},
      success: false,
      error: false,
      errorMessage: '',
    },
  },
  contract: {
    setVehicle: {
      data: {},
      success: false,
      error: false,
      errorMessage: '',
      vehicle: {},
      responseDialog: {
        open: false,
        success: false,
        errorCode: '',
        errorMessage: '',
        modalType: '',
      },
    },
    changeVehicle: {
      data: {},
      success: false,
      error: false,
      errorMessage: '',
      loading: false,
    },
    extendContract: {
      data: [],
      success: false,
      error: false,
      errorMessage: '',
      loading: false,
      assignedVehicle: '',
      extendContractInProgress: false,
    },
    catalogueOfVehicles: {
      params: {
        page: 0,
        size: 10,
        statuses: [],
      },
      searchPattern: '',
      filters: [],
      totalPages: 1,
      totalElements: 10,
      results: [],
      success: false,
      error: false,
      errorMessage: '',
    },
    reservationContract: {
      data: {},
      success: false,
      error: false,
      errorMessage: '',
    },
    contracts: {
      data: {},
      success: false,
      error: false,
      errorMessage: '',
    },
    sendFormLink: {
      data: {},
      success: false,
      error: false,
      errorMessage: '',
    },
    hasCreated: false,
  },
  signatures: {
    isSending: false,
    success: true,
    errorCode: '',
    message: '',
    isCompleted: false,
    accepted: null,
  },
  extraCharges: {
    success: false,
    errorCode: '',
    message: '',
    listOfExtraCharges: [],
  },
  vehicleLinePrices: {
    data: [],
    success: false,
    errorCode: '',
    message: '',
  },
  hydrateChangeOfVehicleFlow: {
    success: false,
    error: false,
  },
  hydrateExtendContractFlow: {
    success: false,
    error: false,
  },
  cards: {
    data: [],
    customer: '',
  },
};

export function reservationsReducer(state = initialState, action = 'reset') {
  const { type, payload } = action;
  switch (type) {
    case types.CLOSE_RESPONSE_DIALOG:
      return {
        ...state,
        responseDialog: initialState.responseDialog,
      };
    case types.CLOSE_RESPONSE_DIALOG_S:
      return {
        ...state,
        contract: {
          ...state.contract,
          setVehicle: {
            ...state.contract.setVehicle,
            responseDialog: initialState.responseDialog,
          },
        },
      };
    case types.SET_RESPONSE_DIALOG:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          ...action.payload,
        },
      };
    case types.SET_START_HOURS:
      const startDate = moment(payload.value, YYYY_MM_DD);
      const limitStartHoursToday = isToday(startDate);
      const selectedHours = state.quote.availableTime.list.find(
        (element) => element.dayValue - 1 === startDate.weekday()
      );

      const isSaturday = selectedHours && selectedHours.dayValue === 6;

      const validStartHours =
        selectedHours &&
        selectedHours.pickupStartTime &&
        selectedHours.pickupEndTime;

      const getEndHourStartHour = () => {
        if (limitStartHoursToday) {
          if (isSaturday) return LIMIT_HOUR_TODAY_SATURDAY;
          return LIMIT_HOUR_TODAY_NATURAL_DAY;
        }
        return selectedHours?.pickupEndTime;
      };

      const getStartHour = () =>
        payload.limitStart || selectedHours?.pickupStartTime;

      const listOfStartHours = generateHoursSelect(
        payload.value,
        getStartHour(),
        getEndHourStartHour()
      );

      return {
        ...state,
        quote: {
          ...state.quote,
          selectedDates: {
            ...state.quote.selectedDates,
            start: validStartHours
              ? {
                  ...state.quote.selectedDates.start,
                  date: payload.value,
                  list: listOfStartHours,
                }
              : { limitEnd: null, limitStart: null },
          },
        },
      };
    case types.SET_START_LIMIT_HOURS:
      return {
        ...state,
        quote: {
          ...state.quote,
          selectedDates: {
            ...state.quote.selectedDates,
            start: {
              ...state.quote.selectedDates.start,
              limitStart:
                action.payload.limitStart ||
                state.quote.selectedDates.end.limitStart,
              limitEnd:
                action.payload.limitEnd ||
                state.quote.selectedDates.end.limitEnd,
            },
          },
        },
      };
    case types.SET_END_HOURS:
      const { limitStart, limitEnd } = state.quote.selectedDates.end;
      const endDate = moment(payload, YYYY_MM_DD);
      const limitEndHours = moment(endDate).isSame(
        state.quote.selectedDates.start.date
      );

      const selectedEndHours = state.quote.availableTime.list.find(
        (element) => element.dayValue - 1 === endDate.weekday()
      );

      const validEndHours =
        selectedEndHours &&
        selectedEndHours?.returnStartTime &&
        selectedEndHours?.returnEndTime;

      const listOfEndHours = generateHoursSelect(
        payload,
        limitEndHours && limitStart
          ? limitStart
          : selectedEndHours?.returnStartTime,
        selectedEndHours?.returnEndTime
      );

      return {
        ...state,
        quote: {
          ...state.quote,
          selectedDates: {
            ...state.quote.selectedDates,
            end: validEndHours
              ? {
                  ...state.quote.selectedDates.end,
                  date: payload,
                  list: listOfEndHours,
                }
              : {
                  limitStart,
                  limitEnd,
                },
          },
        },
      };
    case types.SET_END_LIMIT_HOURS:
      return {
        ...state,
        quote: {
          ...state.quote,
          selectedDates: {
            ...state.quote.selectedDates,
            end: {
              ...state.quote.selectedDates.end,
              limitStart: action.payload.limitStart
                ? action.payload.limitStart
                : state.quote.selectedDates.end.limitStart,
            },
          },
        },
      };
    case types.SET_STEP:
      return {
        ...state,
        quote: {
          ...state.quote,
          step: payload,
        },
      };
    case types.EDIT_QUOTE_STEP_ONE:
    case types.SAVE_QUOTE_STEP_ONE:
    case types.SAVE_QUOTE_STEP_TWO:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            ...payload,
          },
          selectedVehicle: {},
        },
      };
    case types.CLEAN_QUOTE_ID:
      const values = { ...state.quote.values };
      delete values.quoteId;
      return {
        ...state,
        quote: {
          ...state.quote,
          values,
          selectedVehicle: {},
        },
      };
    case types.EDIT_QUOTE_STEP_ONE_SUCCEED:
    case types.SAVE_QUOTE_STEP_ONE_SUCCEED:
      const selectedCategories = state.filters.categories.filter(
        (category) => category.value === state.quote.values.vehicleCategory
      );

      return {
        ...state,
        quote: {
          ...state.quote,
          editScheduleAction: 'view',
          values: {
            ...state.quote.values,
            quoteId: payload.data,
          },
        },
        catalog: {
          ...state.catalog,
          params: {
            ...state.catalog.params,
            page: 0,
          },
          last: false,
          results: [],
        },
        filters: {
          ...state.filters,
          selectedCategory: {
            ...selectedCategories[0],
            types: [...selectedCategories[0].types],
          },
          brands: selectedCategories[0].brands.map((item) => ({
            value: item.uuid,
            label: item.name,
            icon: item.originalImgUrl,
            ...item,
          })),
        },
      };

    case types.SET_SELECTED_CATEGORY:
      const cats = state.filters.categories.filter(
        (category) => category.value === payload
      );

      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategory: {
            ...cats[0],
            types: [...cats[0].types],
          },
          brands: cats[0].brands.map((item) => ({
            value: item.uuid,
            label: item.name,
            icon: item.originalImgUrl,
            ...item,
          })),
        },
      };
    case types.SET_PREVIOUS_SELECTED_CATEGORY:
      const prevCats = state.filters.categories.find(
        (category) => category.value === payload
      );

      return {
        ...state,
        filters: {
          ...state.filters,
          previousSelectedCategory:
            prevCats && prevCats.types
              ? {
                  categories: {
                    ...prevCats,
                    types: [{ value: '', label: 'TODOS' }, ...prevCats.types],
                  },
                  brands: prevCats.brands.map((item) => ({
                    value: item.uuid,
                    label: item.name,
                    icon: item.originalImgUrl,
                    ...item,
                  })),
                }
              : state.filters.previousSelectedCategory,
        },
      };
    case types.SET_BRANDS:
      const selectedType = state.filters.selectedCategory.types.filter(
        (typeVehicle) => typeVehicle.value === payload
      );

      const brands =
        payload === ''
          ? state.filters.selectedCategory.brands
          : selectedType[0].brands;

      return {
        ...state,
        filters: {
          ...state.filters,
          brands: brands.map((item) => ({
            value: item.uuid,
            label: item.name,
            icon: item.originalImgUrl,
            ...item,
          })),
        },
      };
    case types.SAVE_QUOTE_STEP_TWO_SUCCEED:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            quoteId: payload.data.priceQuote,
            discountForm: {
              ...state.quote.values.discountForm,
              units: 1,
              openForm: false,
              allowEditDiscount: true,
              promocodeDiscount: 0,
              finished: false,
              success: false,
              prevBranchDiscount: '',
            },
          },
        },
        catalog: {
          ...state.catalog,
          params: {
            ...state.catalog.params,
            page: 0,
          },
          last: false,
          results: [],
        },
      };
    case types.SET_IN_PROGRESS_QUOTE:
      return {
        ...state,
        quote: {
          ...state.quote,
          inProgress: payload,
        },
      };
    case types.EDIT_QUOTE_STEP_ONE_FAILED:
    case types.SAVE_QUOTE_STEP_ONE_FAILED:
    case types.SAVE_QUOTE_STEP_TWO_FAILED:
    case types.GET_LIST_OF_RESERVATIONS_FAILED:
      return {
        ...state,
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.SET_TITLES:
      return {
        ...state,
        quote: {
          ...state.quote,
          title: payload.title,
          subtitle: payload.subtitle,
        },
      };
    case types.SET_CONTRACT_QUOTE:
      return {
        ...state,
        quote: {
          ...state.quote,
          isContract: payload,
        },
      };
    case types.SET_QUOTE_VALUES:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            ...payload,
          },
        },
      };
    case types.GET_VEHICLES:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          params: {
            ...state.catalog.params,
            vehicleTypes: payload.vehicleTypes.join(','),
            brands: payload.brands.join(','),
            branch: payload.branch,
          },
          results: [],
        },
      };
    case types.GET_VEHICLES_SUCCEED:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          results: payload.data.content,
          params: {
            ...state.catalog.params,
            page: state.catalog.params.page + 1,
          },
          totalPages: payload.data.totalPages,
          totalElements: payload.data.totalElements,
          last: payload.data.last,
        },
      };
    case types.SET_PARAMS:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          params: {
            ...state.catalog.params,
            ...payload,
          },
        },
      };
    case types.GET_VEHICLES_FAILED:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          results: [],
          success: false,
          error: payload.error.message,
        },
      };
    case types.GET_VEHICLE_TYPES:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
        },
        filters: {
          ...state.filters,
          vehicles: [],
        },
      };

    case types.GET_MOST_QUOTED:
      return {
        ...state,
        quote: {
          ...state.quote,
          mostQuoted: {
            success: false,
            list: [],
          },
        },
      };
    case types.GET_MOST_QUOTED_SUCCEED:
      return {
        ...state,
        quote: {
          ...state.quote,
          mostQuoted: {
            success: true,
            list: payload.data,
          },
        },
      };
    case types.GET_MOST_QUOTED_FAILED:
      return {
        ...state,
        quote: {
          ...state.quote,
          mostQuoted: {
            success: false,
            error: payload.message,
            list: [],
          },
        },
      };
    case types.GET_TIME_LIMITS:
      return {
        ...state,
        quote: {
          ...state.quote,
          availableTime: {
            success: false,
            list: [],
          },
        },
      };
    case types.GET_TIME_LIMITS_SUCCEED:
      return {
        ...state,
        quote: {
          ...state.quote,
          availableTime: {
            success: true,
            list: payload.data,
          },
        },
      };
    case types.GET_TIME_LIMITS_FAILED:
      return {
        ...state,
        quote: {
          ...state.quote,
          availableTime: {
            success: false,
            error: payload.message,
            list: [],
          },
        },
      };
    case types.SAVE_NOT_PRICE_QUOTE:
      return {
        ...state,
        notPriceQuote: initialState.notPriceQuote,
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
          errorCode: '',
          message: '',
        },
        quote: {
          ...state.quote,
          inProgress: false,
        },
      };
    case types.SAVE_NOT_PRICE_QUOTE_SUCCEED: {
      const newQuoteValues = { ...state.quote.values };

      delete newQuoteValues.notPriceQuoteValues;

      return {
        ...state,
        notPriceQuote: {
          error: false,
          errorCode: '',
          message: '',
          success: true,
          data: payload,
        },
        quote: {
          ...state.quote,
          values: newQuoteValues,
        },
      };
    }
    case types.SAVE_NOT_PRICE_QUOTE_FAILED:
      return {
        ...state,
        notPriceQuote: {
          error: true,
          errorCode: payload,
          message: '',
          success: false,
          data: {},
        },
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: action.payload.code,
          message: action.payload.message,
        },
      };
    case types.GET_VEHICLE_TYPES_SUCCEED:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload.data,
          brands: state.filters.brands,
        },
      };
    case types.GET_VEHICLE_TYPES_FAILED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: action.payload.code,
          message: action.payload.message,
        },
        filters: {
          ...state.filters,
          vehicles: [],
        },
      };
    case types.GET_DISCOUNTS:
      return {
        ...state,
        filters: {
          ...state.filters,
          discounts: [],
        },
      };
    case types.GET_DISCOUNTS_SUCCEED:
      return {
        ...state,
        filters: {
          ...state.filters,
          discounts: payload.data,
        },
      };
    case types.GET_DISCOUNTS_FAILED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: action.payload.code,
          message: action.payload.message,
        },
        filters: {
          ...state.filters,
          discounts: [],
        },
      };
    case types.APPLY_BRANCH_DISCOUNT:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            discountForm: {
              ...state.quote.values.discountForm,
              openForm: true,
              success: true,
              finished: true,
              message: '',
              ...payload,
            },
          },
        },
      };
    case types.CANCEL_BRANCH_DISCOUNT:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            discountForm: {
              ...state.quote.values.discountForm,
              openForm: false,
            },
          },
        },
      };
    case types.SAVE_DISCOUNT:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            discountForm: {
              ...state.quote.values.discountForm,
              isPromocode: false,
              openForm: true,
              success: false,
              finished: false,
              submiting: true,
              message: '',
              ...payload,
            },
          },
        },
      };
    case types.SAVE_DISCOUNT_SUCCEED:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            discountForm: {
              ...state.quote.values.discountForm,
              isPromocode: false,
              openForm: false,
              success: true,
              finished: true,
              message: '',
              submiting: false,
              allowEditDiscount: false,
              prevBranchDiscount: state.quote.values.discountForm.discount,
              ...payload,
            },
          },
        },
      };
    case types.SAVE_DISCOUNT_FAILED:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            discountForm: {
              ...state.quote.values.discountForm,
              isPromocode: false,
              success: false,
              finished: true,
              errorCode: payload.code,
              message: payload.message,
              submiting: false,
              allowEditDiscount: true,
            },
          },
        },
      };
    case types.VALIDATE_PROMOCODE:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            discountForm: {
              ...state.quote.values.discountForm,
              isPromocode: true,
              promoCode: payload.promocode,
              promocodeDiscount: 0,
              submiting: true,
            },
          },
        },
      };
    case types.VALIDATE_PROMOCODE_SUCCEED:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            discountForm: {
              ...state.quote.values.discountForm,
              openForm: false,
              success: true,
              finished: true,
              message: '',
              submiting: false,
              allowEditDiscount: true,
              promocodeDiscount: payload.data.discount,
            },
          },
        },
      };
    case types.VALIDATE_PROMOCODE_FAILED:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            discountForm: {
              ...state.quote.values.discountForm,
              isPromocode: true,
              success: false,
              finished: true,
              errorCode: payload.code,
              message: payload.message,
              submiting: false,
              promocodeDiscount: 0,
            },
          },
        },
      };

    case types.OPEN_SET_RESERVATION_DIALOG:
      return {
        ...state,
        sendReservationDialog: true,
        successPath: '/reservations',
      };
    case types.CLOSE_SET_RESERVATION_DIALOG:
      return {
        ...state,
        sendReservationDialog: false,
      };
    case types.OPEN_SET_QUOTE_DIALOG:
      return {
        ...state,
        sendQuoteDialog: true,
      };
    case types.CLOSE_SET_QUOTE_DIALOG:
      return {
        ...state,
        sendQuoteDialog: false,
      };
    case types.SAVE_QUOTE_STEP_THREE:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: false,
          success: false,
        },
        quote: {
          ...state.quote,
          confirmQuoteDialog: {
            open: false,
            email: '',
          },
        },
      };

    case types.UPDATE_RESERVATION_FAILED:
      return {
        ...state,
        responseDialogUpdateReservation: {
          ...state.responseDialogUpdateReservation,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.SAVE_QUOTE_STEP_THREE_FAILED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };

    case types.UPDATE_RESERVATION_SUCCEED:
      return {
        ...state,
        responseDialogUpdateReservation: {
          ...state.responseDialogUpdateReservation,
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.SAVE_QUOTE_STEP_THREE_SUCCEED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
        },
      };

    case types.UPDATE_RESERVATION: {
      return {
        ...state,
        reservationInfo: {
          ...state.reservationInfo,
          isUpdateReservationModalOpen: false,
        },
      };
    }
    case types.SET_OPEN_CLOSE_UPDATE_RESERVATION_MODAL: {
      return {
        ...state,
        reservationInfo: {
          ...state.reservationInfo,
          isUpdateReservationModalOpen: payload.toggle,
        },
      };
    }
    case types.RESET_RESPONSE_DIALOG_UPDATE_RESERVATION: {
      return {
        ...state,
        responseDialogUpdateReservation: {
          open: false,
          success: false,
          errorCode: '',
          errorMessage: '',
          modalType: '',
        },
        filters: {
          ...state.filters,
          brands: [],
          selectedCategory: { types: [] },
        },
        catalog: {
          params: {
            page: 0,
            size: 10,
            property: 'id',
            direction: 'asc',
          },
          totalPages: 1,
          totalElements: 10,
          last: false,
          results: [],
        },
      };
    }
    case types.OPEN_EDIT_SCHEDULE_DATES:
      return {
        ...state,
        quote: {
          ...state.quote,
          editScheduleAction: 'edit',
        },
      };
    case types.EDIT_DISCOUNT:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            discountForm: {
              ...state.quote.values.discountForm,
              allowEditDiscount: true,
            },
          },
        },
      };
    case types.CANCEL_EDIT_DISCOUNT:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            discountForm: {
              ...state.quote.values.discountForm,
              allowEditDiscount: false,
            },
          },
        },
      };
    case types.CLOSE_EDIT_SCHEDULE_DATES:
      const { previousSelectedCategory } = state.filters;

      const setPreviousSelectedCategory = action.payload;

      const getSelectedCategory = () => {
        if (previousSelectedCategory) {
          return previousSelectedCategory.categories;
        }
        return state.filters.selectedCategory;
      };

      const getBrands = () => {
        if (previousSelectedCategory) {
          return previousSelectedCategory.brands;
        }
        return state.filters.brands;
      };

      return {
        ...state,
        quote: {
          ...state.quote,
          editScheduleAction: 'view',
        },
        filters: setPreviousSelectedCategory
          ? {
              ...state.filters,
              selectedCategory: getSelectedCategory(),
              brands: getBrands(),
            }
          : { ...state.filters },
      };
    case types.SET_TERMS_AND_CONDITIONS:
      return {
        ...state,
        openMarket: {
          ...state.openMarket,
          termsAndConditions: action.payload,
        },
      };
    case types.SET_LEAVE_STEP_ONE:
      return {
        ...state,
        quote: {
          ...state.quote,
          leaveStepOne: action.payload,
        },
      };
    case types.SET_VEHICLE_CATEGORY:
      return {
        ...state,
        quote: {
          ...state.quote,
          vehicleCategory: action.payload,
        },
      };
    case types.SET_QUOTE_VEHICLE_CATEGORY:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            vehicleCategory: action.payload,
          },
        },
      };
    case types.SET_QUOTE_INTERNAL_ERROR:
      return {
        ...state,
        quote: {
          ...state.quote,
          internalError: action.payload,
        },
      };
    case types.SET_DELIVERY_STEP_ONE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          stepOne: {
            success: true,
          },
        },
        responseDialog: {
          open: true,
          success: true,
          errorCode: '',
          message: 'No cierre ni actualice el navegador',
          modalType: 'empty',
        },
      };
    case types.SET_DELIVERY_STEP_TWO:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          stepTwo: {
            success: true,
          },
        },
      };
    case types.SET_DELIVERY_STEP_THREE:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          stepThree: {
            success: true,
          },
        },
      };
    case types.CONFIRM_DELIVERY:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          confirm: true,
        },
        responseDialog: {
          open: true,
          success: true,
          errorCode: '',
          message: '',
        },
      };
    case types.SET_DELIVERY_STEP:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          step: action.payload,
        },
        responseDialog: {
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.FINALLY_DELIVERY:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          finally: true,
        },
        responseDialog: {
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.SET_CHANGE_VEHICLE:
      return {
        ...state,
        changeVehicle: payload,
      };
    case types.SET_REFUND_STEP_ONE:
      return {
        ...state,
        refund: {
          ...state.refund,
          stepOne: {
            success: true,
          },
        },
      };
    case types.SET_REFUND_STEP_TWO:
      return {
        ...state,
        refund: {
          ...state.refund,
          stepTwo: {
            success: true,
          },
        },
      };
    case types.SET_REFUND_STEP_THREE:
      return {
        ...state,
        refund: {
          ...state.refund,
          stepThree: {
            success: true,
          },
        },
      };
    case types.VERIFY_REFUND:
      return {
        ...state,
        refund: {
          ...state.refund,
          success: true,
        },
        responseDialog: {
          open: true,
          success: true,
          errorCode: '',
          message: 'No cierre ni actualice el navegador',
          modalType: 'empty',
        },
      };
    case types.CONFIRM_REFUND:
      return {
        ...state,
        refund: {
          ...state.refund,
          confirm: true,
        },
        responseDialog: {
          open: true,
          success: true,
          errorCode: '',
          message: '',
        },
      };
    case types.SET_REFUND_STEP:
      return {
        ...state,
        refund: {
          ...state.refund,
          step: action.payload,
        },
        responseDialog: {
          open: false,
          success: false,
          errorCode: '',
        },
      };
    case types.FINALLY_REFUND:
      return {
        ...state,
        refund: {
          ...state.refund,
          finally: true,
        },
        responseDialog: {
          open: true,
          success: true,
          errorCode: '',
        },
      };
    case types.GET_LIST_OF_RESERVATIONS_REQUESTED:
      return {
        ...state,
        listOfReservations: {
          list: {
            ...state.listOfReservations.list,
            results: {
              page: state.listOfReservations.list.params.page,
              list: [],
              loading: true,
              loaded: false,
            },
          },
        },
        responseDialog: initialState.responseDialog,
      };
    case types.GET_LIST_OF_RESERVATIONS_SUCCESSED:
      const { content, totalElements, totalPages } = payload;

      const transformed = content.map((reservation) => {
        const { deliveryDate, returnDate, createdDate } = reservation;
        const deliveryDateFormatted = moment(
          deliveryDate,
          ISO_DATE_FORMAT
        ).format(MX_DATE_FORMAT);
        const returnDateFormatted = moment(returnDate, ISO_DATE_FORMAT).format(
          MX_DATE_FORMAT
        );
        const createdDateFormatted = moment(
          createdDate,
          ISO_DATE_FORMAT
        ).format(MX_DATE_FORMAT);

        const star = moment(deliveryDate, 'YYYY-M-D');
        const end = moment(returnDate, 'YYYY-M-D');
        const daysDiff =
          isNaN(end.diff(star, 'days')) || end.diff(star, 'days') < 0
            ? 0
            : end.diff(star, 'days');

        return {
          ...reservation,
          deliveryDateFormatted,
          returnDateFormatted,
          createdDateFormatted,
          daysDiff,
        };
      });

      return {
        ...state,
        listOfReservations: {
          list: {
            ...state.listOfReservations.list,
            results: {
              list: transformIdOfList(transformed),
              loading: false,
              loaded: true,
              page: state.listOfReservations.list.params.page,
            },
            totalPages,
            totalElements,
          },
        },
      };
    case types.SET_LIST_OF_RESERVATION_PAGE:
      return {
        ...state,
        listOfReservations: {
          list: {
            ...state.listOfReservations.list,
            params: {
              ...state.listOfReservations.list.params,
              page: action.payload,
            },
          },
        },
      };
    case types.SET_INITIAL_PARAMS_LIST_OF_RESERVATIONS:
      return {
        ...state,
        listOfReservations: {
          list: {
            ...state.listOfReservations.list,
            params: {
              ...initialState.listOfReservations.list.params,
              tableSelected: action.payload,
              deliveryBranch:
                getRoleName() != 'CONTACT_CENTER' && getBrach()
                  ? getBrach()
                  : '',
            },
          },
        },
      };
    case types.SET_PARAMS_LIST_OF_RESERVATIONS:
      delete action.payload.tableSelected;
      return {
        ...state,
        listOfReservations: {
          list: {
            ...state.listOfReservations.list,
            params: {
              ...state.listOfReservations.list.params,
              ...action.payload,
              page: 0,
            },
          },
        },
      };
    case types.RESET_PARAMS_LIST_OF_RESERVATIONS:
      return {
        ...state,
        listOfReservations: {
          list: {
            ...state.listOfReservations.list,
            params: initialState.listOfReservations.list.params,
          },
        },
      };
    case types.GET_RESERVATION_INFO:
    case types.CLEAR_RESERVATION_INFO:
      return {
        ...state,
        reservationInfo: {
          success: false,
          error: false,
          errorMessages: null,
          isUpdateReservationModalOpen: false,
          data: {},
        },
        quote: {
          ...state.quote,
          inProgress: false,
        },
      };
    case types.GET_RESERVATION_INFO_SUCCESSED:
      const {
        previousAssignedVehicle,
        verificationChecklists,
      } = action.payload;
      const isChangeVehicleFlow = Boolean(previousAssignedVehicle);
      const originalVehicleCheckList = verificationChecklists.filter(
        (vcl) => !vcl.isEnabled
      );

      return {
        ...state,
        reservationInfo: {
          success: true,
          error: false,
          errorMessages: null,
          isUpdateReservationModalOpen:
            action.payload.isUpdateReservationModalOpen,
          data: isChangeVehicleFlow
            ? {
                ...action.payload,
                assignedVehicle: previousAssignedVehicle,
                newAssignedVehicle: action.payload.assignedVehicle,
                verificationChecklists: originalVehicleCheckList,
              }
            : { ...action.payload },
        },
      };
    case types.GET_RESERVATION_INFO_FAILED:
      return {
        ...state,
        reservationInfo: {
          success: false,
          error: true,
          errorMessages: action.payload,
          data: {},
        },
      };
    case types.EDIT_RESERVATION_INFO:
      return {
        ...state,
        reservationInfo: {
          ...state.reservationInfo,
          ...payload,
        },
      };
    case types.VERIFY_CHECK_POINT:
      return {
        ...state,
        verifyCheckPoint: {
          ...state.verifyCheckPoint,
          success: false,
          error: false,
          errorMessage: '',
          data: {},
          loading: true,
        },
      };
    case types.VERIFY_CHECK_POINT_SUCCESSED:
      const groupName = payload.dataOfRequests[0].verifyCheckListPoint.data.data.name.trim();
      return {
        ...state,
        verifyCheckPoint: {
          ...state.verifyCheckPoint,
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
          loading: false,
        },
        reservationInfo: {
          ...state.reservationInfo,
          data: {
            ...state.reservationInfo.data,
            assignedVehicle: {
              ...state.reservationInfo.data.assignedVehicle,
              gas:
                groupName === 'Tablero'
                  ? payload.dataOfRequests[0]?.verifyCheckListPoint?.data?.data
                      ?.additionalFields?.gas?.value
                  : state.reservationInfo.data.assignedVehicle.gas,
              km:
                groupName === 'Tablero'
                  ? payload.dataOfRequests[0]?.verifyCheckListPoint?.data?.data
                      ?.additionalFields?.km?.value
                  : state.reservationInfo.data.assignedVehicle.km,
            },
          },
        },
      };
    case types.VERIFY_CHECK_POINT_FAILED:
      return {
        ...state,
        verifyCheckPoint: {
          ...state.verifyCheckPoint,
          success: true,
          error: false,
          errorMessage: payload,
          data: {},
          loading: false,
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.SET_ACTUAL_POSITION:
      return {
        ...state,
        verifyCheckPoint: {
          ...state.verifyCheckPoint,
          actualPosition: payload,
        },
      };
    case types.VERIFY_COMPLETED_CHECK_LIST:
      return {
        ...state,
        verifyCompletedCheckList: {
          success: false,
          error: false,
          errorMessage: '',
          data: false,
        },
      };
    case types.VERIFY_COMPLETED_CHECK_LIST_SUCCEED:
      return {
        ...state,
        verifyCompletedCheckList: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
      };
    case types.VERIFY_COMPLETED_CHECK_LIST_FAILED:
      return {
        ...state,
        verifyCompletedCheckList: {
          success: false,
          error: true,
          errorMessage: payload,
          data: false,
        },
      };
    case types.CLEAN_SEND_FORM_LINK_FIRM:
    case types.SEND_FORM_LINK_FIRM:
      return {
        ...state,
        sendFirmForm: {
          success: false,
          error: false,
          errorMessage: '',
          data: {},
        },
        responseDialog: initialState.responseDialog,
      };
    case types.SEND_FORM_LINK_FIRM_SUCCEED:
      return {
        ...state,
        sendFirmForm: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
      };
    case types.SEND_FORM_LINK_FIRM_FAILED:
      return {
        ...state,
        sendFirmForm: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.CREATE_RESERVATION_SUCCEED:
      return {
        ...state,
        reservation: {
          ...state.reservation,
          id: payload.data.uuid,
          folio: payload.data.folio,
          success: true,
          error: '',
        },
      };
    case types.CREATE_RESERVATION_FAILED:
      return {
        ...state,
        reservation: {
          ...state.reservation,
          id: '',
          success: false,
          error: payload,
        },
      };
    case types.SET_NEW_INVOICE_PAYMENT:
      return {
        ...state,
        reservation: {
          ...state.reservation,
          newInvoicePayment: payload,
        },
      };
    case types.SAVE_PAYMENT:
      return {
        ...state,
        reservation: {
          ...state.reservation,
          payment: {
            ...state.reservation.payment,
            values: payload,
            withoutPayment: payload.request.withoutPayment,
          },
        },
        responseDialog: initialState.responseDialog,
      };
    case types.SAVE_PAYMENT_SUCCEED:
      return {
        ...state,
        reservation: {
          ...state.reservation,
          payment: {
            ...state.reservation.payment,
            data: payload,
          },
        },
      };
    case types.SAVE_PAYMENT_FAILED:
      return {
        ...state,
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
        reservation: {
          ...state.reservation,
          payment: {
            ...state.reservation.payment,
            data: {},
            withoutPayment: false,
          },
        },
      };
    case types.HAS_ADDED_BILLING_DETAILS_OF_PAYMENT:
      return {
        ...state,
        reservation: {
          ...state.reservation,
          payment: {
            ...state.reservation.payment,
            hasAddedBillingDetails: payload,
          },
        },
      };
    case types.GET_VEHICLE_LINE_PRICES:
      return {
        ...state,
        vehicleLinePrices: initialState.vehicleLinePrices,
      };
    case types.GET_VEHICLE_LINE_PRICES_SUCCED:
      return {
        ...state,
        vehicleLinePrices: {
          ...state.vehicleLinePrices,
          data: action.payload.data,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    case types.GET_VEHICLE_LINE_PRICES_FAILED:
      return {
        ...state,
        vehicleLinePrices: {
          ...state.vehicleLinePrices,
          data: [],
          success: false,
          error: true,
          errorMessage: action.payload,
        },
      };
    case types.SET_CUSTOMER_VALUE:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            customer: payload,
          },
        },
      };
    case types.SET_CUSTOMER_VALUE_NO_PRICE_QUOTE: {
      const customerName = `${_get(payload, 'userInfo.names', '')} ${_get(
        payload,
        'userInfo.lastName',
        ''
      )}`;
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            customer: _get(payload, 'uuid', ''),
            [getFieldSearch('customer')]: customerName,
            [getLastSearchField('customer')]: customerName,
            withInvalidDocuments: false,
          },
        },
      };
    }
    case types.SET_VEHICLE_OF_RESERVATION:
      return {
        ...state,
        contract: {
          ...state.contract,
          setVehicle: {
            data: {},
            success: false,
            error: false,
            errorMessage: '',
            responseDialog: initialState.responseDialog,
            vehicle: payload,
          },
        },
      };
    case types.SET_VEHICLE_OF_RESERVATION_SUCCEED:
      return {
        ...state,
        contract: {
          ...state.contract,
          setVehicle: {
            ...state.contract.setVehicle,
            responseDialog: initialState.responseDialog,
            data: payload,
            success: true,
            error: false,
            errorMessage: '',
          },
        },
      };
    case types.SET_VEHICLE_OF_RESERVATION_FAILED:
      return {
        ...state,
        contract: {
          ...state.contract,
          setVehicle: {
            data: {},
            responseDialog: {
              open: true,
              success: false,
              errorCode: payload.code,
              message: payload.message,
            },
            vehicle: {},
          },
        },
      };
    case types.CHANGE_VEHICLE_OF_RESERVATION:
      return {
        ...state,
        contract: {
          ...state.contract,
          changeVehicle: {
            data: {},
            success: false,
            error: false,
            errorMessage: '',
            vehicle: payload,
            loading: true,
          },
        },
      };
    case types.CHANGE_VEHICLE_OF_RESERVATION_SUCCEED:
      return {
        ...state,
        contract: {
          ...state.contract,
          changeVehicle: {
            ...state.contract.changeVehicle,
            data: payload,
            success: true,
            error: false,
            errorMessage: '',
            loading: false,
          },
        },
      };
    case types.CHANGE_VEHICLE_OF_RESERVATION_FAILED:
      return {
        ...state,
        contract: {
          ...state.contract,
          changeVehicle: {
            data: {},
            success: false,
            error: true,
            errorMessage: payload.message,
            errorCode: payload.code,
            vehicle: {},
            loading: false,
          },
        },
      };
    case types.GET_CATALOGUE_OF_VEHICLES:
    case types.CLEAR_CATALOGUE_OF_VEHICLES:
      return {
        ...state,
        contract: {
          ...state.contract,
          catalogueOfVehicles: {
            ...state.contract.catalogueOfVehicles,
            results: [],
            success: false,
            error: false,
            errorMessage: '',
          },
        },
        responseDialog: initialState.responseDialog,
      };
    case types.GET_CATALOGUE_OF_VEHICLES_SUCCEED:
      const vehiclesCatalogueList = { ...payload };
      return {
        ...state,
        contract: {
          ...state.contract,
          catalogueOfVehicles: {
            ...state.contract.catalogueOfVehicles,
            results: transformIdOfList(vehiclesCatalogueList.data),
            totalPages: vehiclesCatalogueList.totalPages,
            totalElements: vehiclesCatalogueList.totalElements,
            success: true,
            error: false,
            errorMessage: '',
          },
        },
      };
    case types.GET_CATALOGUE_OF_VEHICLES_FAILED:
      return {
        ...state,
        contract: {
          ...state.contract,
          catalogueOfVehicles: {
            ...state.contract.catalogueOfVehicles,
            results: [],
            success: false,
            error: true,
            errorMessage: action.payload,
          },
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
          closeInConfirmation: true,
        },
      };
    case types.SET_PARAMS_OF_VEHICLES_CATALOGUE:
      const resetParamsVehiclesCatalogue =
        typeof payload === 'string' && payload === 'RESET';

      return {
        ...state,
        contract: {
          ...state.contract,
          catalogueOfVehicles: {
            ...state.contract.catalogueOfVehicles,
            params: !resetParamsVehiclesCatalogue
              ? {
                  ...state.contract.catalogueOfVehicles.params,
                  ...payload,
                }
              : {
                  ...initialState.contract.catalogueOfVehicles.params,
                },
          },
        },
      };

    case types.GET_RESERVATION_FORM:
    case types.CREATE_RESERVATION_CONTRACT:
      return {
        ...state,
        contract: {
          ...state.contract,
          reservationContract: {
            data: {},
            success: false,
            error: false,
            errorMessage: '',
          },
          hasCreated: false,
        },
        responseDialog: initialState.responseDialog,
      };
    case types.GET_RESERVATION_FORM_SUCCEED:
    case types.CREATE_RESERVATION_CONTRACT_SUCCEED: {
      const isChangeOfVehicle = Boolean(payload.contractVehicleChange);
      return {
        ...state,
        signatures: {
          ...state.signatures,
          isCompleted: payload.form.completed,
        },
        contract: {
          ...state.contract,
          reservationContract: {
            data: payload,
            success: true,
            error: false,
            errorMessage: '',
          },
          hasCreated: Boolean(payload.hasCreated),
        },
        changeVehicle: isChangeOfVehicle || state.changeVehicle,
      };
    }
    case types.GET_RESERVATION_FORM_FAILED:
    case types.CREATE_RESERVATION_CONTRACT_FAILED:
      return {
        ...state,
        contract: {
          ...state.contract,
          reservationContract: {
            data: {},
            success: false,
            error: true,
            errorMessage: payload,
          },
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.GET_CONTRACTS_PDF:
      return {
        ...state,
        contract: {
          ...state.contract,
          contracts: {
            data: {},
            success: false,
            error: false,
            errorMessage: '',
          },
        },
      };
    case types.GET_CONTRACTS_PDF_SUCCEED:
      return {
        ...state,
        contract: {
          ...state.contract,
          contracts: {
            data: payload,
            success: true,
            error: false,
            errorMessage: '',
          },
        },
      };
    case types.GET_CONTRACTS_PDF_FAILED:
      return {
        ...state,
        contract: {
          ...state.contract,
          contracts: {
            data: {},
            success: false,
            error: true,
            errorMessage: payload,
          },
        },
      };
    case types.SEND_FORM_REQ:
      return {
        ...state,
        contract: {
          ...state.contract,
          sendFormLink: {
            data: {},
            success: false,
            error: false,
            errorMessage: '',
          },
        },
      };
    case types.SEND_FORM_REQ_SUCCEED:
      return {
        ...state,
        contract: {
          ...state.contract,
          sendFormLink: {
            data: payload,
            success: true,
            error: false,
            errorMessage: '',
          },
        },
      };
    case types.SEND_FORM_REQ_FAILED:
      return {
        ...state,
        contract: {
          ...state.contract,
          sendFormLink: {
            data: {},
            success: false,
            error: true,
            errorMessage: payload,
          },
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.SEND_RESERVATION_REQ:
      return {
        ...state,
        reservation: {
          ...state.reservation,
          sendReservation: {
            data: {},
            success: false,
            error: false,
            errorMessage: '',
          },
        },
      };
    case types.SEND_RESERVATION_REQ_SUCCEED:
      return {
        ...state,
        reservation: {
          ...state.reservation,
          sendReservation: {
            data: payload,
            success: true,
            error: false,
            errorMessage: '',
          },
        },
      };
    case types.SEND_RESERVATION_REQ_FAILED:
      return {
        ...state,
        reservation: {
          ...state.reservation,
          sendReservation: {
            data: {},
            success: false,
            error: true,
            errorMessage: payload,
          },
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.VERIFY_FORM_COMPLETED:
      return {
        ...state,
        signatures: {
          isSending: true,
          success: true,
          errorCode: '',
          message: '',
          isCompleted: false,
        },
      };
    case types.VERIFY_FORM_COMPLETED_SUCCEED:
      return {
        ...state,
        signatures: {
          ...state.signatures,
          isSending: false,
          isCompleted: payload,
        },
      };
    case types.REJECT_SIGNATURES_FAILED:
      return {
        ...state,
        signatures: {
          success: false,
          isSending: false,
          errorCode: payload.code,
          message: payload.message,
          isCompleted: false,
          accepted: null,
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.ACCEPT_SIGNATURES_FAILED:
      return {
        ...state,
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.VERIFY_FORM_COMPLETED_FAILED:
      return {
        ...state,
        signatures: {
          success: false,
          isSending: false,
          errorCode: payload.code,
          message: payload.message,
          isCompleted: false,
          accepted: null,
        },
      };
    case types.REJECT_SIGNATURES:
    case types.ACCEPT_SIGNATURES:
      return {
        ...state,
        signatures: {
          ...state.signatures,
          isSending: true,
          success: false,
          errorCode: '',
          message: '',
          accepted: null,
        },
      };
    case types.TEMP_ACCEPT_SIGNATURES:
    case types.ACCEPT_SIGNATURES_SUCCEED:
      return {
        ...state,
        signatures: {
          ...state.signatures,
          isSending: false,
          accepted: true,
        },
        contract: {
          ...state.contract,
          sendFormLink: {
            data: {},
            success: false,
            error: false,
            errorMessage: '',
          },
        },
      };
    case types.REJECT_SIGNATURES_SUCCEED:
      return {
        ...state,
        signatures: {
          ...state.signatures,
          isSending: false,
          accepted: false,
          isCompleted: false,
        },
        contract: {
          ...state.contract,
          sendFormLink: {
            data: {},
            success: false,
            error: false,
            errorMessage: '',
          },
        },
      };
    case types.RESET_SIGNATURES:
      return {
        ...state,
        signatures: {
          isSending: false,
          success: true,
          errorCode: '',
          message: '',
          isCompleted: false,
          accepted: null,
        },
      };
    case types.REHYDRATE_CUSTOMER_STEP:
      const { priceQuote } = payload;
      const {
        vehicle,
        vehicleLinePrice,
        specs,
      } = priceQuote.selectedVehicleLinePrice;

      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.values,
            quoteId: priceQuote.uuid,
            vehicleCategory: vehicle.vehicleCategoryUuid,
            pickupBranch: priceQuote.pickupBranch.uuid,
            pickupDateTime: priceQuote.pickupDateTime,
            returnBranch: priceQuote.returnBranch.uuid,
            returnDateTime: priceQuote.returnDateTime,
            vehicleLinePrice: vehicleLinePrice.uuid,
            fullVehicle: {
              ...vehicle,
              specs,
              vehicleLineImagesOriginalUrls: [vehicle.originalImgUrl],
              vehicleLinePrices: [
                {
                  ...vehicleLinePrice,
                  name: vehicleLinePrice.rentalPlanName,
                  period: vehicleLinePrice.period,
                  includedCoveragePlan: {
                    uuid: '3012485e-be99-49e7-b70f-97316d29ff46',
                    price: 0,
                    features: [
                      'Eliminación de deducible en Pérdida Total',
                      'Responsabilidad por daños a terceros',
                      'Pérdida o extravío de llaves',
                      'Gastos médicos ocupantes',
                      'Asistencia víal',
                      'Auto sustituto',
                      'Robo total',
                      'Cristales',
                    ],
                  },
                },
              ],
            },
            discountForm: {},
          },
        },
        reservation: {
          id: payload.uuid,
          folio: payload.folio,
        },
      };
    case types.REHYDRATE_PAYMENT_STEP:
      const {
        vehicleCategoryUuid,
        vehicleCategoryName,
      } = payload.priceQuote.selectedVehicleLinePrice.vehicle;
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedCategory: {
            ...state.filters.selectedCategory,
            value: vehicleCategoryUuid,
            label: vehicleCategoryName,
          },
        },
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            customer: payload.naturalPerson.uuid,
            discountForm: getDiscount(payload.priceQuote),
          },
        },
      };
    case types.GET_RESERVATION_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          reservationList: initialState.filters.reservationList,
        },
      };
    case types.GET_RESERVATION_LIST_FILTERS_SUCCEED:
      return {
        ...state,
        filters: {
          ...state.filters,
          reservationList: payload,
        },
      };
    case types.SET_EXTRA_CHARGES:
      return {
        ...state,
        extraCharges: {
          ...state.extraCharges,
          ...payload,
        },
      };
    case types.ASSIGN_CUSTOMER_SUCCEED:
      return {
        ...state,
        quote: {
          ...state.quote,
          values: {
            ...state.quote.values,
            customer: payload,
          },
        },
      };
    case types.HYDRATE_CHANGE_OF_VEHICLE_FLOW_SUCCESS:
      return {
        ...state,
        hydrateChangeOfVehicleFlow: {
          success: true,
          error: false,
        },
        reservationInfo: {
          success: true,
          error: false,
          errorMessage: '',
          data: {
            ...payload.reservationInfo,
            assignedVehicle: payload.reservationInfo.previousAssignedVehicle,
            newAssignedVehicle: payload.reservationInfo.assignedVehicle,
          },
        },
        reservation: {
          id: payload.reservationInfo.uuid,
          folio: payload.reservationInfo.folio,
          success: true,
          error: '',
        },
        contract: payload.changeRequestUuid
          ? {
              ...state.contract,
              changeVehicle: {
                ...state.contract.changeVehicle,
                data: {
                  changeRequestUuid: payload.changeRequestUuid,
                  reservationUuid: payload.reservationInfo.uuid,
                },
                success: true,
                error: false,
                loading: false,
              },
            }
          : state.contract,
        quote: {
          ...state.quote,
          inProgress: false,
        },
        changeVehicle: true,
      };
    case types.HYDRATE_CHANGE_OF_VEHICLE_FLOW_FAILED:
      return {
        ...state,
        hydrateChangeOfVehicleFlow: {
          success: false,
          error: true,
        },
      };
    case types.HYDRATE_EXTEND_CONTRACT_INIT_FLOW_SUCCESS:
      return {
        ...state,
        hydrateExtendContractFlow: initialState.hydrateExtendContractFlow,
        responseDialog: initialState.responseDialog,
      };
    case types.HYDRATE_EXTEND_CONTRACT_FLOW_SUCCESS:
      return {
        ...state,
        hydrateExtendContractFlow: {
          success: true,
          error: false,
        },
        reservationInfo: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload.reservationInfo,
        },
        reservation: {
          id: payload.reservationInfo.uuid,
          folio: payload.reservationInfo.folio,
          success: true,
          error: '',
        },
        contract: payload.daysAdded
          ? {
              ...state.contract,
              extendContract: {
                ...state.contract.extendContract,
                extendContractUuid: payload.extendContractUuid,
                daysAdded: payload.daysAdded,
                extendNumber: payload.extendNumber,
                newDateEnd: payload.newDateEnd,
                reservationUuid: payload.reservationInfo.uuid,
                assignedVehicle: payload.assignedVehicle,
                success: true,
                error: false,
                loading: false,
              },
            }
          : state.contract,
        quote: {
          ...state.quote,
          inProgress: false,
        },
        responseDialog: initialState.responseDialog,
      };
    case types.HYDRATE_EXTEND_CONTRACT_FLOW_FAILED:
      return {
        ...state,
        hydrateExtendContractFlow: {
          success: false,
          errorMessage: payload,
          error: true,
        },
        responseDialog: {
          ...state.responseDialog,
          openEc: true,
          successEc: false,
          errorCodeEc: payload.code,
          messageEc: payload.message,
        },
      };
    case types.SET_IN_PROGRESS_EXTEND:
      return {
        ...state,
        contract: {
          ...state.contract,
          extendContract: {
            ...state.contract.extendContract,
            extendContractInProgress: payload,
          },
        },
      };
    case types.SET_EXTEND_CONTRACT_VEHICLE:
      return {
        ...state,
        contract: {
          ...state.contract,
          extendContract: {
            ...state.contract.extendContract,
            assignedVehicle: payload,
          },
        },
      };
    case types.GET_EXTENDED_CONTRACTS_SUCCESS:
      return {
        ...state,
        contract: {
          ...state.contract,
          extendContract: {
            ...state.contract.extendContract,
            data: [...payload.extendedContracts],
            currentContract: payload.currentContract,
            success: true,
            error: false,
            loading: false,
          },
        },
      };
    case types.GET_EXTENDED_CONTRACTS_FAILED:
      return {
        ...state,
        contract: {
          ...state.contract,
          extendContract: {
            ...state.contract.extendContract,
            success: false,
            error: payload.error,
            loading: false,
          },
        },
      };
    case types.RESET: {
      const exceptions = _get(payload, 'exceptions', []);

      const exceptionsToNotReset = exceptions.reduce((acc, current) => {
        acc[current] = state[current];
        return acc;
      }, {});

      return {
        ...initialState,
        ...exceptionsToNotReset,
      };
    }
    case types.CANCEL_RESERVATION:
      return {
        ...state,
        responseDialog: initialState.responseDialog,
      };
    case types.CANCEL_RESERVATION_SUCCESSED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: true,
          errorCode: '',
          message: '',
        },
      };
    case types.CANCEL_RESERVATION_FAILED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.GET_CUSTOMER_CARDS_SUCCESSED:
      return {
        ...state,
        cards: {
          data: transformIdOfList(payload),
          customer: state.quote.values.customer,
          success: true,
          error: false,
          loading: false,
        },
      };
    case types.GET_CUSTOMER_CARDS_FAILED:
      return {
        ...state,
        responseDialog: {
          ...state.responseDialog,
          success: false,
          error: '',
          loading: false,
        },
      };
    case types.SET_RESERVATION_VEHICLE_FAILED:
      return {
        ...state,
        responseDialogReservationVehicle: {
          open: true,
          success: false,
          errorCode: payload.errorCode,
          message: payload.message,
        },
      };
    case types.UPDATE_CARD:
    case types.CLOSE_RESPONSE_DIALOG_UPDATE_CARD:
      return {
        ...state,
        responseDialogUpdateCard: {
          open: false,
          success: false,
          errorCode: '',
          errorMessage: '',
          modalType: '',
        },
      };
    case types.UPDATE_CARD_FAILED:
      return {
        ...state,
        responseDialogUpdateCard: {
          open: true,
          success: false,
          errorCode: payload.errorCode,
          errorMessage: payload.errorMessage,
          modalType: '',
        },
      };
    case types.UPDATE_CARD_SUCCEED:
      return {
        ...state,
        responseDialogUpdateCard: {
          open: true,
          success: true,
          errorCode: '',
          errorMessage: '',
          modalType: '',
        },
      };
    default:
      return state;
  }
}

// TODO:  wait for octavio changes
const getDiscount = (quote) => {
  const { managerDiscount, total } = quote;
  if (!managerDiscount) return {};
  let discountAmount = 0;

  if (managerDiscount.managerDiscountType.symbol === '%') {
    discountAmount =
      (total / (100 - managerDiscount.amount)) * managerDiscount.amount;
  } else {
    discountAmount = managerDiscount.amount;
  }

  return {
    units: 1,
    openForm: false,
    submiting: false,
    finished: true,
    success: true,
    // promocodeDiscount: 0,
    discountAmount,
    total,
    // isPromocode: false,
    // isPromocode:amount: '10',
    // discountTypeUuid: '19171ded-7bfc-43b2-8672-c91f2f746d5e',
  };
};
