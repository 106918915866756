import React, { useCallback, useEffect } from 'react';
import { ProgressIndicator } from '@casanova/casanova-common';
import { PROGRESS_COLOR } from 'utils/colors';
import NotPriceQuote from './NotPriceQuote';

const maxSteps = 2;

const Parent = (props) => {
  const { quote, step = 1, history, setTitles } = props;
  const { title, subtitle } = quote;

  const handleOnCancel = useCallback(() => {
    const cancelPath = `/daily-rent-contracts/`;
    history.push(cancelPath);
  }, [history]);

  useEffect(() => {
    setTitles({
      title: 'Información general',
      subtitle: 'Registra la información para generar el contrato',
    });
  }, [setTitles]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0 d-flex align-items-center">
          <ProgressIndicator
            size={120}
            stroke={PROGRESS_COLOR}
            strokeWidth={6}
            progress={(step / maxSteps) * 100}
            label={`${step} de ${maxSteps}`}
          />
          <div className="ml-4">
            <h3 className="text-primary">{title}</h3>
            <p>{subtitle}</p>
          </div>
        </div>
      </div>
      {step === 1 && <NotPriceQuote {...props} onCancel={handleOnCancel} />}
    </div>
  );
};

export default Parent;
