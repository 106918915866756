export const saveCheckPointFormatter = (
  listOfGroups,
  currentPosition,
  dataOfVerifyPoints,
  activeGroup
) => {
  const lastItem = listOfGroups[listOfGroups.length - 1] || {};
  const inLastItem = currentPosition === lastItem.position;
  const position = inLastItem ? 1 : currentPosition + 1;
  const formatted = {
    dataOfVerifyPoints,
    position,
    groupUuid: activeGroup.uuid,
  };

  return formatted;
};
