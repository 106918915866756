import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getLoans, setListPage } from 'store/loans/actions';
import { fetchCatalog } from 'store/commons/actions';
import { withLayout } from 'hooks';
import ListOfLoans from './ListOfLoans';

export default withLayout(
  compose(
    connect(
      (state) => ({
        loans: state.loans.list,
        confirmationMessage: false,
      }),
      {
        getLoans,
        setListPage,
        fetchCatalog,
      }
    )
  )(ListOfLoans)
);
