import qs from 'qs';
import _isString from 'lodash/isString';
import {
  sendCustomRequest,
  sendFormRequest,
  sendRawRequest,
} from '@casanova/casanova-common/lib/utils/service';
import { APIError } from '@casanova/casanova-common/lib/utils/exceptions';
import { downloadFileFromBase64 } from 'services/commons';
import { CUSTOMER_STATUS } from 'utils/constants';
import { TYPES_OF_DOCUMENTS } from '@customers/common/constants';
import {
  toCustomersPageRequest,
  transformCustomersListFilters,
} from './transformers';
import { cleanParams } from '@casanova/casanova-common/lib/utils/requests';
import { CUSTOMER_TYPES } from '../../containers/customers/common/constants';

const { BANNED, UNBANED, BANNED_EDITED } = CUSTOMER_STATUS;

const root = 'customer/v1';

export async function getEstadoCuentaSirena(name) {
  const url = `https://pco.casanova.wundertec.com/casanova_pco_back/api/dashboard/estado-cuenta?name=${name}`;
  // const url = `https://pco.casanova.wundertec.com/casanova_pco_back/api/dashboard/estado-cuenta?name=LUIS%20GUILLERMO%20AGUILAR%20RIVAS`;
  const headers = new Headers();
  headers.append('content-type', 'application/json');
  const request = await fetch(url, {
    method: 'GET',
    headers,
  });
  const response = await request.json();
  return response;
}

export async function getCustomerInfo(id, options = {}) {
  const url = `${root}/natural-person/${id}`;
  const response = await sendCustomRequest(url, options, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function checkDebtsByNaturalPerson(id, options = {}) {
  const url = `${root}/natural-person/${id}/check-debts`;
  const response = await sendCustomRequest(url, options, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getSummaryInfo(id, options = {}) {
  const url = `${root}/natural-person/${id}/summary`;
  const response = await sendCustomRequest(url, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getCustomers({ params }, options = {}) {
  const queryParams = `?${qs.stringify(cleanParams(params))}`;
  const url = `${root}/customer/byFilter${queryParams}`;

  const response = await sendCustomRequest(url, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export const getFileExtension = (name) => {
  const splitted = name.split('.');
  return splitted[splitted.length - 1].trim();
};

async function uploadFiles(url, uuid, elements = []) {
  if (elements.length === 0) return;

  const form = new FormData();

  elements.forEach(({ file, fieldName, suffix }) => {
    if (file && file.name) {
      form.append(
        fieldName,
        file,
        `${uuid}_${suffix}.${getFileExtension(file.name)}`
      );
    }
  });
  const response = await sendFormRequest(url, form, {});

  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  return response;
}

export async function uploadAddressProf(request, uuid) {
  const url = `${root}/addresses/${uuid}/proof`;
  return uploadFiles(url, uuid, request.files.addressProof);
}

export async function uploadLicense(request, uuid) {
  const url = `${root}/driver-licenses/${uuid}/scan`;
  return uploadFiles(url, uuid, request.files.license);
}

export async function uploadTaxSituation(request, uuid) {
  const url = `${root}/billing-details/${uuid}/scan`;
  return uploadFiles(url, uuid, request.files.billing);
}

export async function uploadIdentification(request, uuid) {
  const url = `${root}/identity-documents/${uuid}/scan`;
  return uploadFiles(url, uuid, request.files.identification);
}

export async function uploadPassport(request, uuid) {
  const url = `${root}/identity-documents/${uuid}/scan`;
  return uploadFiles(
    url,
    uuid,
    request.files.passport || request.files.identification
  );
}

export async function uploadVisa(request, uuid) {
  const url = `${root}/identity-documents/${uuid}/scan`;
  return uploadFiles(
    url,
    uuid,
    request.files.visa || request.files.identification
  );
}

export async function uploadAccommodations(request, uuid) {
  const url = `${root}/accommodations/${uuid}/files`;
  return uploadFiles(url, uuid, request.files.accommodation);
}

export async function uploadCountryEntrances(request, uuid) {
  const url = `${root}/country-entrances/${uuid}/files`;
  return uploadFiles(url, uuid, request.files.countryEntrance);
}

export async function uploadJuridicalPerson(request, uuid) {
  const { companyData = [] } = request.files;
  if (companyData.length <= 0) return;
  const url = `${root}/juridical-people/${uuid}/files`;
  return uploadFiles(url, uuid, companyData);
}

export async function uploadTender(request, uuid) {
  const url = `${root}/tender/${uuid}/files`;
  return uploadFiles(url, uuid, request.files.companyData);
}

export async function uploadDrivers(request, drivers) {
  if (drivers.length <= 0) return {};

  const driversEditedFiles = request.files.drivers.filter((files) => {
    if (files[0].file instanceof Object) return files;
  });

  const driversReqs = driversEditedFiles.map(async (files, index) => {
    const url = `${root}/authorized-drivers/${drivers[index].uuid}/files`;
    const form = new FormData();
    files.forEach(({ file, fieldName, suffix }) => {
      form.append(
        fieldName,
        file,
        `${drivers[index].uuid}_${suffix}.${getFileExtension(file.name)}`
      );
    });
    return sendFormRequest(url, form, {});
  });

  const response = await Promise.all(driversReqs);
  return response;
}

export async function uploadDriversNp(request, drivers) {
  if (drivers.length <= 0) return {};

  const driversEditedFiles = request.files.drivers.filter((files) => {
    if (files[0].file instanceof Object) return files;
  });

  const driversReqs = driversEditedFiles.map(async (files) => {
    const driver = drivers.find((driver) => driver.curp === files[0].fileUuid);
    const url = `${root}/authorized-drivers/${driver.uuid}/files-np`;
    const form = new FormData();
    files.forEach(({ file, fieldName, suffix }) => {
      form.append(
        fieldName,
        file,
        `${driver.uuid}_${suffix}.${getFileExtension(file.name)}`
      );
    });
    return sendFormRequest(url, form, {});
  });

  const response = await Promise.all(driversReqs);
  return response;
}

const UPLOAD_FILES_BY_DOCUMENT_TYPE = {
  [TYPES_OF_DOCUMENTS.INE]: uploadIdentification,
  [TYPES_OF_DOCUMENTS.PROFESSIONAL_LICENSE]: uploadIdentification,
  [TYPES_OF_DOCUMENTS.INAPAM]: uploadIdentification,
  [TYPES_OF_DOCUMENTS.PASSPORT]: uploadPassport,
  [TYPES_OF_DOCUMENTS.VISA]: uploadVisa,
};

const getUploadIdentityDocuments = async (request, identityDocuments) => {
  const uploadIdentityDocuments = identityDocuments
    .map((identityDocument) => {
      const { identityDocumentType } = identityDocument;
      const uploadService =
        UPLOAD_FILES_BY_DOCUMENT_TYPE[identityDocumentType.identifier];
      return { uploadService, identityDocument };
    })
    .map(async ({ uploadService, identityDocument = {} }) => {
      if (uploadService) {
        return uploadService(request, identityDocument.uuid);
      }
    });

  const response = await Promise.all(uploadIdentityDocuments);
  return response;
};

export async function saveCustomer(request) {
  request.customerInfo.landline = request.customerInfo.landline
    ? request.customerInfo.landline
    : null;
  request.createAddressReq.interiorNumber = request.createAddressReq
    .interiorNumber
    ? request.createAddressReq.interiorNumber
    : null;
  request.customerInfo.secondLastName = request.customerInfo.secondLastName
    ? request.customerInfo.secondLastName
    : null;
  const url = `${root}/customer`;
  const branch =
    localStorage.getItem('br') || 'ff515133-ef70-45e0-8f7e-7659dbcb9c32';
  const sucursales = {
    'ff515133-ef70-45e0-8f7e-7659dbcb9c32': {
      sucursal: 'CHAPULTEPEC',
      rfc: 'CCA870615FV0',
    },
    '8349bc4b-7a41-4e10-bf04-7501d937a815': {
      sucursal: 'TLALNEPANTLA',
      rfc: 'CRE970630HJ3',
    },
    '84461aa3-28f4-4496-8472-0a483a14277a': {
      sucursal: 'PATRIOTISMO',
      rfc: 'CRV821223DY5',
    },
    'c589db6b-73f7-4840-8375-89e8bfedb01b': {
      sucursal: 'TLALPAN',
      rfc: 'CRV821223DY5',
    },
    '29e168fc-86a6-43ea-b4c6-9e50a5330bb4': {
      sucursal: 'VALLEJO',
      rfc: 'CVA910402GI5',
    },
  };
  const getSucursal = sucursales[branch];
  const response = await sendCustomRequest(
    url,
    {},
    { ...request, ...getSucursal },
    'post'
  );
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const {
    address,
    driverLicense,
    identityDocuments = [],
    authorizedDrivers = [],
    billingDetails,
  } = response.data.data;

  driverLicense?.uuid && (await uploadLicense(request, driverLicense.uuid));
  await uploadAddressProf(request, address.uuid);

  const { files } = request;
  for (let index = 0; index < files.billing.length; index++) {
    const billingFile = files.billing[index];
    if (billingFile.file) {
      await uploadTaxSituation(request, billingDetails[index].uuid);
    }
  }
  if (authorizedDrivers.length > 0) {
    await uploadDriversNp(request, authorizedDrivers);
  }

  await getUploadIdentityDocuments(request, identityDocuments);

  return response;
}

export async function saveCustomerBase(request) {
  const url = `${root}/customer/base-info`;
  console.log(request)
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    const messageMatch = message.match(/"message":"([^"]+)"/);
    const extractedMessage = messageMatch ? messageMatch[1] : message;

    console.log(extractedMessage);
    throw new APIError(errorCode, extractedMessage);
  }
  sessionStorage.setItem('tmp_set_uuid_user', response.data.data.uuid);
  sessionStorage.setItem('tmp_set_fullname_user', response.data.data.fullName);
  return response;
}

export async function saveLegalCustomer(request) {
  const url = `${root}/customer/juridical-person`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const {
    identityDocuments = [],
    juridicalPerson = {},
    billingDetails,
  } = response.data.data;

  if (juridicalPerson && juridicalPerson.uuid) {
    await uploadJuridicalPerson(request, juridicalPerson.uuid);
    const { authorizedDrivers = [] } = juridicalPerson;
    await uploadDrivers(request, authorizedDrivers);
  }
  const { files } = request;
  if (files.billing[0] && billingDetails[0].uuid) {
    if (files.billing[0].file)
      await uploadTaxSituation(request, billingDetails[0].uuid);
  }

  await getUploadIdentityDocuments(request, identityDocuments);

  return response;
}

export async function saveLegalCustomerBase(request) {
  const url = `${root}/customer/juridical-person-base-info`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { identityDocuments = [], juridicalPerson = {} } = response.data.data;

  if (juridicalPerson && juridicalPerson.uuid) {
    // await uploadJuridicalPerson(request, juridicalPerson.uuid);
    const { authorizedDrivers = [] } = juridicalPerson;
    await uploadDrivers(request, authorizedDrivers);
  }

  await getUploadIdentityDocuments(request, identityDocuments);

  return response;
}

export async function saveTenderCustomer(request) {
  const url = `${root}/customer/tender`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { tender = {}, address, billingDetails } = response.data.data;
  const { tenderType } = tender;
  await uploadAddressProf(request, address.uuid);

  const { files } = request;
  if (request.files.billing[0] && billingDetails[0].uuid) {
    if (request.files.billing[0].file)
      await uploadTaxSituation(request, billingDetails[0].uuid);
  }

  if (tender && tender.uuid && tenderType === CUSTOMER_TYPES.JURIDICAL) {
    await uploadTender(request, tender.uuid);
  }

  return response;
}

export async function saveTenderCustomerBase(request) {
  const url = `${root}/customer/tender-base-info`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { tender = {}, address } = response.data.data;
  // await uploadAddressProf(request, address.uuid);
  if (tender && tender.uuid) {
    // await uploadTender(request, tender.uuid);
  }
  return response;
}

export async function saveForeignerCustomer(request) {
  console.log(
    '🚀 ~ saveForeignerCustomer ~ request:',
    request.foreignCustomerInfo.secondLastName
  );
  request.foreignCustomerInfo.secondLastName = request.foreignCustomerInfo
    .secondLastName
    ? request.foreignCustomerInfo.secondLastName
    : null;
  const url = `${root}/customer/foreign`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  const {
    identityDocuments = [],
    countryEntrance = {},
    accommodation = {},
    driverLicense = {},
    billingDetails,
  } = response.data.data;

  await getUploadIdentityDocuments(request, identityDocuments);

  const { files } = request;
  if (request.files.billing[0] && billingDetails[0].uuid) {
    if (request.files.billing[0].file)
      await uploadTaxSituation(request, billingDetails[0].uuid);
  }

  if (request.files.accommodation && accommodation.uuid) {
    await uploadAccommodations(request, accommodation.uuid);
  }

  if (request.files.countryEntrance && countryEntrance) {
    await uploadCountryEntrances(request, countryEntrance.uuid);
  }
  await uploadLicense(request, driverLicense.uuid);

  return response;
}

export async function saveForeignerCustomerBase(request) {
  const url = `${root}/customer/foreign-base-info`;
  request.createDriverLicenseReq.sinceDate = '';
  request.foreignCustomerInfo.birthDate = '';
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    const messageMatch = message.match(/"message":"([^"]+)"/);
    const extractedMessage = messageMatch ? messageMatch[1] : message;
    throw new APIError(errorCode, extractedMessage);
  }

  sessionStorage.setItem('tmp_set_uuid_user', response.data.data.uuid);
  sessionStorage.setItem('tmp_set_fullname_user', response.data.data.fullName);

  const {
    identityDocuments = [],
    countryEntrance = {},
    accommodation = {},
    driverLicense = {},
  } = response.data.data;

  await getUploadIdentityDocuments(request, identityDocuments);

  if (request.files.accommodation && accommodation.uuid) {
    await uploadAccommodations(request, accommodation.uuid);
  }
  // if (request.files.countryEntrance && countryEntrance) {
  //   await uploadCountryEntrances(request, countryEntrance.uuid);
  // }
  // await uploadLicense(request, driverLicense.uuid);
  return response;
}

export async function editCustomer(request, uuid) {
  const url = `${root}/customer/${uuid}`;
  const { initialStatus, finalStatus } = request;
  const response = await sendCustomRequest(url, {}, request, 'PATCH');

  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  if (initialStatus !== finalStatus) {
    switch (finalStatus) {
      case BANNED:
        await banCustomer(uuid, request.banFields);
        break;
      case UNBANED:
        await unbanCustomer(uuid);
        break;
      case BANNED_EDITED:
      default:
        await editBanCustomer(uuid, request.banFields);
        break;
    }
  }

  const {
    address,
    driverLicense,
    identityDocuments = [],
    juridicalPerson,
    countryEntrance,
    accommodation,
    authorizedDrivers = [],
    billingDetails,
  } = response.data.data;

  const { files } = request;

  if (files.identification || files.passport || files.visa) {
    await getUploadIdentityDocuments(request, identityDocuments);
  }

  if (files.license && driverLicense) {
    await uploadLicense(request, driverLicense.uuid);
  }
  if (files.addressProof && address) {
    await uploadAddressProf(request, address.uuid);
  }
  if (authorizedDrivers.length > 0) {
    await uploadDriversNp(request, authorizedDrivers);
  }

  if (juridicalPerson && juridicalPerson.uuid) {
    await uploadJuridicalPerson(request, juridicalPerson.uuid);
    const { authorizedDrivers = [] } = juridicalPerson;
    await uploadDrivers(request, authorizedDrivers);
  }

  if (files.countryEntrance && countryEntrance) {
    await uploadCountryEntrances(request, countryEntrance.uuid);
  }

  if (files.accommodation && accommodation.uuid) {
    await uploadAccommodations(request, accommodation.uuid);
  }

  for (let index = 0; index < files.billing.length; index++) {
    const billingFile = files.billing[index];
    if (billingFile.file) {
      await uploadTaxSituation(request, billingDetails[index].uuid);
    }
  }

  return response;
}

// export async function editCustomer(request, uuid) {
//   console.log('uuid', uuid);
//   console.log('request', request);
// }

export async function updateCustomerStatus(request) {
  const url = `${root}/customer/updateCustomerStatus`;
  const response = await sendCustomRequest(url, {}, request, 'PATCH');

  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  return response;
}

export async function banCustomer(uuid, request) {
  const url = `${root}/customer/${uuid}/status/banned`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function editBanCustomer(uuid, request) {
  const url = `${root}/customer/${uuid}/status/banned`;
  const response = await sendCustomRequest(url, {}, request, 'PATCH');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function unbanCustomer(uuid) {
  const url = `${root}/customer/${uuid}/status/unbanned`;
  const response = await sendCustomRequest(url, {}, undefined, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

export async function getCustomerFiles(uuid) {
  const url = `${root}/customer/${uuid}/files/zip/base64`;
  const headers = new Headers();
  const response = await sendCustomRequest(url, { headers }, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  downloadFileFromBase64(
    'application/zip',
    response.data.data,
    `customer_${uuid}_files.zip`
  );

  return response;
}

export async function getCustomerDetailXlsx(uuid) {
  const url = `${root}/customer/${uuid}/files/xlsx`;
  const headers = new Headers();
  const response = await sendCustomRequest(url, { headers }, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }

  downloadFileFromBase64(
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    response.data.data,
    `customer_${uuid}_detail.xlsx`
  );

  return response;
}

export async function searchCustomers(params, options = {}) {
  const url = `${root}/customer/basic-info?${qs.stringify(params)}`;
  const response = await sendCustomRequest(url, options, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data.content;
}

export async function searchEmployees(query, options = {}) {
  const url = `user/v1/users/employees?${qs.stringify(
    _isString(query) ? { name: query, status: true } : query,
    { encode: false }
  )}`;

  const response = await sendCustomRequest(url, options, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data.content;
}

export async function searchJuridicalCustomers(text, options = {}) {
  const params = { text, status: true };

  const url = `${root}/juridical-people/basic-info?${qs.stringify(params, {
    encode: false,
  })}`;
  const response = await sendCustomRequest(url, options, undefined, 'get');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data.content;
}

export async function searchTenderCustomers(text, options = {}) {
  const params = { text, status: true };
  console.log(text)
  const url = `${root}/tender/basic-info?${qs.stringify(params, {
    encode: false,
  })}`;
  const response = await sendCustomRequest(url, options, undefined, 'get');
  console.log(response)
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data.content;
}

export async function addBillingInfo(request, options = {}) {
  const url = `${root}/billing-details`;
  const response = await sendCustomRequest(url, options, request, 'POST');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data;
}

export async function editBillingInfo({ uuid, ...request }, options = {}) {
  const url = `${root}/billing-details/${uuid}`;
  const response = await sendCustomRequest(url, options, request, 'PUT');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data;
}

export async function deleteBillingInfo(uuid, options = {}) {
  const url = `${root}/billing-details/${uuid}`;
  const response = await sendCustomRequest(url, options, undefined, 'DELETE');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data;
}

export async function fetchMovementTypes() {
  const url = `${root}/catalogs/movement-types`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data.content;
}

export async function getCustomerStatement(uuid) {
  const url = `${root}/billing-details/${uuid}/statement`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getCustomersListFilter() {
  const url = `${root}/customer/filter`;

  const response = await sendCustomRequest(url, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return transformCustomersListFilters(response.data.data);
}
