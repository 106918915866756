import {
  VIEW_VEHICLE_PLATE,
  CREATE_VEHICLE_PLATE,
} from 'utils/roles/permissions';
import Plate from './Plate';
import { withConnectedAdditional } from '../AdditionalCollapse';

export default withConnectedAdditional({
  identifierForView: VIEW_VEHICLE_PLATE,
  identifierForAdd: CREATE_VEHICLE_PLATE,
})(Plate);
