import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { verifyValidToken, userActivation } from 'store/activation/actions';
import Activation from './Activation';

export default compose(
  connect(
    (state) => ({
      verifyToken: state.activation.verifyToken,
      userActivation: state.activation.userActivation,
    }),
    {
      verifyValidToken,
      postUserActivation: userActivation,
    }
  )
)(Activation);
