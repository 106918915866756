import TransfersModuleIcon from '@casanova/casanova-common/lib/Icons/TransfersModule';
import { TRANSFERS_MODULE } from 'utils/roles/permissions/transfersPermissions';
import TransfersList from 'containers/transfers/TransfersList';
import CreateTransfer from 'containers/transfers/CreateTransfer';
import EditTransfer from 'containers/transfers/EditTransfer';
import TransferDetail from 'containers/transfers/TransferDetail';
import { withRoleRestrictions } from 'utils/auth';
import { withReconcileNav } from 'containers/Breadcrumb';

export const transfers = {
  moduleName: TRANSFERS_MODULE,
  route: '/transfers',
  label: 'Traslados',
  icon: TransfersModuleIcon,
  main: 'all',
  children: {
    all: {
      route: '',
      label: '',
      format: () => `/transfers`,
      component: withReconcileNav(
        'transfers',
        'all'
      )(withRoleRestrictions(TransfersList)),
    },
    add: {
      route: `/add`,
      label: 'Nuevo',
      format: () => `/transfers/add`,
      onlyRoute: true,
      component: withReconcileNav(
        'transfers',
        'add'
      )(withRoleRestrictions(CreateTransfer)),
    },
    edit: {
      route: `/:id/edit`,
      label: 'Editar',
      format: (id) => `/transfers/${id}/edit`,
      onlyRoute: true,
      component: withReconcileNav(
        'transfers',
        'edit'
      )(withRoleRestrictions(EditTransfer)),
    },
    detail: {
      route: `/:id/detail`,
      label: 'Detalle',
      format: (id) => `/transfers/${id}/detail`,
      onlyRoute: true,
      component: withReconcileNav(
        'transfers',
        'detail'
      )(withRoleRestrictions(TransferDetail)),
    },
  },
};
