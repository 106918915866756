import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import {
  validateCurpDriver,
  cleanValidateCurpDriver,
} from 'store/customers/actions';
import { assignCustomer } from 'store/reservations/actions';
import DriversInformation from './DriversInformation';

export default compose(
  connect(
    (state) => ({
      driverCurpInfo: _get(state, 'customers.curpDriverValidation', {}),
    }),
    {
      validateCurpDriver,
      cleanValidateCurpDriver,
      assignCustomer,
    }
  )
)(DriversInformation);
