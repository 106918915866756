import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  getDailyRentContractsList,
  setListPage,
  closeContract,
  resetDailyRent,
} from 'store/dailyRentContracts/actions';
import {
  hydrateChangeOfVehicleFlow,
  hydrateExtendContractFlow,
} from 'store/reservations/actions';
import ListOfDRContracts from './ListOfDRContracts';

export default compose(
  connect(
    (state) => ({
      // withForm props
      confirmationMessage: false,
      responseDialog: state.reservations.responseDialog,
      contractsList: state.dailyRentContracts.list,
      hasClosedContract: state.dailyRentContracts.closeContract.success,
      hasFailedCloseContract: state.dailyRentContracts.closeContract.error,
      responseDialogValidCode:
        state.dailyRentContracts.closeContract.responseDialog,
      loader: state.commons.loader,
    }),
    {
      getDailyRentContractsList,
      setListPage,
      hydrateChangeOfVehicleFlow,
      hydrateExtendContractFlow,
      resetDailyRent,
      closeContract,
    }
  )
)(ListOfDRContracts);
