import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { useFormikContext } from 'formik';
import {
  Section,
  ConnectedSelect,
  MaskedInput as Input,
  NumberInput,
  withForm,
  Modal,
  ActionModal,
  Icons,
  Checkbox,
} from '@casanova/casanova-common';
import { MODES } from '@payments/commons/constants';
import { SearchableSelect } from 'components';
import {
  alphaMaskWithSpaces,
  lettersAndNumbersAndAccentsWithSpace,
  lettersNumbersAndSpecialChars,
} from '@casanova/casanova-common/lib/utils/masks';
import i18n from '@i18n';
import { MultiPayments } from '../sectionsMethodsPayment';
// import { scrollTop } from 'utils/screen';
import {
  IT_ADMIN,
  BRANCH_A_MANAGER,
  BRANCH_B_MANAGER,
} from 'utils/roles/roles';
import ResponseDialog from '../../../components/ResponseDialog';
import { validateRole } from '../../../utils/roles';
import { useDispatch } from 'react-redux';
import {
  savePayment,
  closeResponseDialogValidCode,
} from 'store/payments/actions';
import formatter from './formatter';
import { CodeModal } from '../../../components/CodeModal';
import { config } from './config';

function PaymentDetail({
  openConfirmationMessage,
  setHideModal,
  responseDialog,
  loader,
  mode,
  setMode,
  searchEmployees,
  fetchCatalog,
  onUpdateForm,
  movementTypes,
  contractDetails,
  customerJuridical,
  getDetail,
  handleGetValues,
}) {
  const [typePayment, setTypePayment] = useState('');
  const [checkBonificacion, setCheckBonificacion] = useState(false);

  const { setFieldValue, values, touched } = useFormikContext();
  const isJuridical = Boolean(customerJuridical);
  const naturalPersonUuid = useMemo(() => contractDetails.naturalPerson, [
    contractDetails.naturalPerson,
  ]);

  const handleSearchEmployee = (query) => {
    if (query && query.length > 2) {
      searchEmployees(query);
    }
  };

  useEffect(() => {
    handleGetValues(values);
  }, [values]);

  useEffect(() => {
    if (!_isEmpty(contractDetails) && movementTypes.length > 0) {
      const movement = movementTypes.find((type) => type.identifier === mode);
      if (movement) {
        onUpdateForm({
          ...contractDetails,
          movementType: movement.uuid,
        });
      }
    }
  }, [onUpdateForm, contractDetails, movementTypes, mode]);

  useEffect(() => {
    setHideModal(openConfirmationMessage || responseDialog.open || loader);
  }, [openConfirmationMessage, responseDialog.open, loader]);

  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/payment-forms',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/banks',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/bank-card-types',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-fiscal-regime/natural-person',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/cfdi-fiscal-regime/juridical-person',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/payment-methods',
      catalogPath: 'pricequotes',
    });
    fetchCatalog('branches');
  }, [fetchCatalog]);

  const handleChangePaymentType = () => {
    if (checkBonificacion) {
      setCheckBonificacion(false);
      setTypePayment('');
      setFieldValue('wantInvoice', false);
      setMode(MODES.PAYMENT);
      return;
    }
    setCheckBonificacion(true);
    setMode(MODES.BONUS);
  };

  useEffect(() => {
    if (values.invoiceTaxRegime !== '') {
      fetchCatalog({
        catalogId: 'catalogs/cfdi-uses/fiscal-regime',
        params: {
          uuid: values.invoiceTaxRegime,
          isJuridical,
        },
        catalogPath: 'pricequotes',
      });
    }
  }, [values.invoiceTaxRegime]);

  useEffect(() => {
    getDetail({ id: contractDetails.naturalPerson, withLoader: true });
  }, [naturalPersonUuid]);

  return (
    <>
      <Section
        title={i18n(`PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__TITLE_${mode}`)}
      >
        <div className="row">
          {(mode === 'PAYMENT' || mode === 'BONUS') && (
            <div className="col-md-12 text-right">
              <Checkbox
                name="checkBonificacion"
                label="BONIFICACIÓN"
                onChange={handleChangePaymentType}
              />
            </div>
          )}
          <div className="col-md-4">
            <NumberInput
              label={`${i18n(
                'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__AMOUNT'
              )}*`}
              placeholder={`${i18n(
                'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__AMOUNT'
              )}*`}
              decimals={2}
              max={999_999}
              name="amount"
            />
          </div>
          <div className="col-md-4">
            <ConnectedSelect
              label={`${i18n('COMMONS__BRANCH')}*`}
              placeholder={`${i18n('COMMONS__BRANCH')}*`}
              name="branch"
              options="commons.branches"
              disabled
            />
          </div>
          {false && (
            <div className="col-md-4">
              <ConnectedSelect
                label="Forma de pago*"
                name="paymentForm"
                options="commons.catalogsPaymentForms"
              />
            </div>
          )}
          <div className="col-md-4">
            <Input
              mask={lettersAndNumbersAndAccentsWithSpace(40)}
              maskPlaceholder=""
              label={`${i18n(
                'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__LIST__CONCEPT'
              )}*`}
              placeholder={`${i18n(
                'PAYMENTS_AND_CHARGES__CONTRACT_DETAIL__LIST__CONCEPT'
              )}*`}
              name="concept"
            />
          </div>
          <div className="col-md-4">
            <SearchableSelect
              name="requester"
              label="SOLICITANTE*"
              placeholder="SOLICITANTE*"
              options="customers.employees"
              mask={alphaMaskWithSpaces(200)}
              onSearching={handleSearchEmployee}
              icon="svg-icon-search"
            />
          </div>
          <div className="col-md-4">
            <Input
              label={`${i18n('PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__REASON')}*`}
              placeholder={`${i18n(
                'PAYMENTS_AND_CHARGES__PAYMENT_DETAIL__REASON'
              )}*`}
              mask={lettersNumbersAndSpecialChars(70)}
              maskPlaceholder=""
              name="reason"
            />
          </div>
          <div className="col-md-4">
            <Input
              label={`${i18n('COMMONS__REMARKS')}`}
              placeholder={`${i18n('COMMONS__REMARKS')}`}
              mask={lettersNumbersAndSpecialChars(70)}
              maskPlaceholder=""
              name="observations"
            />
          </div>
          <div className="col-md-4">
            <Input
              mask={lettersAndNumbersAndAccentsWithSpace(40)}
              maskPlaceholder=""
              label="Error de"
              placeholder="Error de"
              name="error"
            />
          </div>
        </div>
      </Section>

      {mode === 'PAYMENT' && (
        <MultiPayments
          touched={touched}
          onUpdateForm={onUpdateForm}
          uuidCustomer={naturalPersonUuid}
          fetchCatalog={fetchCatalog}
        />
      )}
    </>
  );
}

export default function PaymentDetailContainer({
  loader,
  responseDialogValidCode,
  show,
  onClose,
  ...props
}) {
  const dispatch = useDispatch();
  const { mode } = props;
  const [hideModal, setHideModal] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [formValues, setFormValues] = useState({});

  const handleGetValues = (paymentsValues) => {
    setFormValues(formatter(paymentsValues));
  };

  const handleCancel = ({ dirty, submitted }) => {
    setConfirmModal(false);

    if (dirty && !submitted) {
      setConfirmModal(true);
      setHideModal(true);
      return;
    }

    setHideModal(false);
    onClose();
  };

  const handleClickSubmit = () => {
    if (
      mode === MODES.BONUS &&
      !validateRole([IT_ADMIN, BRANCH_A_MANAGER, BRANCH_B_MANAGER])
    ) {
      setHideModal(true);
      setShowCodeModal(true);
    }
  };

  const handleOnCloseCodeModal = () => {
    setShowCodeModal(false);
    setHideModal(false);
  };

  const handleConfirmCode = (codeForm = {}) => {
    setShowCodeModal(false);
    dispatch(savePayment({ ...formValues, validCodeRequest: codeForm }));
  };

  const handleOnCloseValidCode = () => {
    dispatch(closeResponseDialogValidCode());
    setShowCodeModal(true);
  };

  const newConfig = {
    ...config(mode),
    disableSubmit:
      mode === MODES.BONUS &&
      !validateRole([IT_ADMIN, BRANCH_A_MANAGER, BRANCH_B_MANAGER]),
    handleClickSubmit,
  };

  const PaymentWithForm = withForm({
    config: newConfig,
  })(PaymentDetail);

  return (
    <Modal
      open={show}
      onClose={onClose}
      bigModal
      topContainerClassName={hideModal || loader ? 'd-none' : ''}
    >
      <ActionModal
        open={showConfirmModal}
        icon={<Icons.StatusWarning />}
        onClose={() => {
          setConfirmModal(false);
          setHideModal(false);
        }}
        onAction={() => {
          setConfirmModal(false);
          setHideModal(false);
          onClose();
        }}
        title={i18n('PREVENT_CHANGE__TITLE')}
        message={i18n('PREVENT_CHANGE__MESSAGE')}
        actionLabel={i18n('PREVENT_CHANGE__ACTION')}
        closeLabel={i18n('PREVENT_CHANGE__CLOSE')}
        closeButton
      />
      <ResponseDialog
        open={responseDialogValidCode.open}
        errorTitle={i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE')}
        errorLabel={i18n('COMMONS__CLOSE__TEXT')}
        errorMessage={responseDialogValidCode.errorMessage}
        errorCode={responseDialogValidCode.errorCode}
        onError={handleOnCloseValidCode}
        onClose={handleOnCloseValidCode}
      />
      <CodeModal
        open={showCodeModal && !responseDialogValidCode.open && !loader}
        message={'gerente de sucursal'}
        title={'Realizar Bonificación'}
        onAction={handleConfirmCode}
        onClose={handleOnCloseCodeModal}
      />
      {PaymentWithForm({
        ...props,
        closeResponseDialog: () => {
          props.closeResponseDialog();
          onClose();
        },
        onCancel: handleCancel,
        onCancelPath: './',
        onSuccess: () => onClose(),
        setHideModal,
        handleGetValues,
      })}
    </Modal>
  );
}

PaymentDetailContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setNewInvoicePayment: PropTypes.func,
};
