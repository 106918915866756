import * as Yup from 'yup';
import { REQUIRED_MESSAGE } from 'utils/constants';

export const sendQuoteSchema = Yup.object().shape({
  email: Yup.string()
    .required(REQUIRED_MESSAGE)
    .email('Correo electrónico no valido'),
});

export const formatter = (values) => ({
  amount: values.discount,
  discountTypeUuid: values.discountType[0],
  password: values.password,
  username: values.user,
  vehicleAmount: values.units,
});
