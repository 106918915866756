import * as Yup from 'yup';

const REQUIRED_MESSAGE = 'Este campo es obligatorio.';

export const permissionValidationProps = {
  processingDate: Yup.string().test('', REQUIRED_MESSAGE, function (value) {
    const { permissionType, permissionPDF } = this.parent;
    if (permissionType || permissionPDF) return value != null;
    return true;
  }),

  permissionType: Yup.string().test('', REQUIRED_MESSAGE, function (value) {
    const { processingDate, permissionPDF } = this.parent;
    if (processingDate || permissionPDF) return value != null;
    return true;
  }),
  permissionPDF: Yup.mixed().test('', REQUIRED_MESSAGE, function (value) {
    const { processingDate, permissionType } = this.parent;
    if (permissionType || processingDate) return value != null;
    return true;
  }),
};

export const PurchaseOnlySchema = Yup.object().shape({
  ...permissionValidationProps,
});
