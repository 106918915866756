import React from 'react';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
// PENDING VALIDATION IF HAS OWN TRANSFER
// import store from 'store';
// import _get from 'lodash/get';
import { components } from '@casanova/casanova-common';
import withNotEmptyValue from '@casanova/casanova-common/lib/hocs/withNotEmptyValue';
import { setTableParams } from 'store/transfers/actions';
import { AdditionalHeaderCamp } from 'components';
import { getContextualMenuItem } from 'utils/table';
import { onlyLettersWithSpaces, numericMask } from 'utils/masks';
import VERSIONS from 'utils/constants/versions';
import { TRANSFER_STATUSES } from 'utils/constants/transferStatuses';
import {
  EDIT_STATUS_AUTHORIZE_TRANSFER_REQUEST,
  EDIT_STATUS_REJECTED_ANY_TRANSFER_REQUEST,
  EDIT_STATUS_CLOSED_ANY_TRANSFER_REQUEST,
  EDIT_ANY_TRANSFER_REQUEST,
  EDIT_STATUS_CANCELLED_ANY_TRANSFER_REQUEST,

  /*
  PENDING VALIDATION IF HAS OWN TRANSFER
    EDIT_STATUS_REJECTED_OWN_TRANSFER_REQUEST,
    EDIT_OWN_TRANSFER_REQUEST,
    EDIT_STATUS_CLOSED_OWN_TRANSFER_REQUEST,
    EDIT_STATUS_CANCELLED_OWN_TRANSFER_REQUEST,
  */
} from 'utils/roles/permissions/transfersPermissions';
import { validateRolePermissions } from 'utils/roles';

const { CellText: Cell, CellDashed } = components.ColumnsCells;

const CellText = withNotEmptyValue(Cell);

export const MIN_SEARCH_DATE = '01-01-2021';

export const getContextualMenu = () => {
  /*
  PENDING VALIDATION IF HAS OWN TRANSFER

  const userWhoInsertedTheTransfer = _get(transferData, 'inserted.by', {})
  const currentUser = _get(store, 'auth.user', {})
  const hasOwnTransfer = userWhoInsertedTheTransfer.fullname === currentUser.fullname
  */
  const authorizeMenuItem = {
    AUTHORIZE: {
      label: 'Autorizar',
      name: 'authorizeTransfer',
      disabled: false,
      options: [],
      showExpanded: false,
    },
  };

  const authorizeItem = getContextualMenuItem(
    authorizeMenuItem,
    EDIT_STATUS_AUTHORIZE_TRANSFER_REQUEST
  );

  const rejectMenuItem = {
    REJECT: {
      label: 'Rechazar',
      name: 'rejectTransfer',
      disabled: false,
      options: [],
      showExpanded: false,
    },
  };

  const rejectItem = getContextualMenuItem(
    rejectMenuItem,
    EDIT_STATUS_REJECTED_ANY_TRANSFER_REQUEST
  );

  const closeMenuItem = {
    CLOSE: {
      label: 'Cerrar',
      name: 'closeTransfer',
      disabled: false,
      options: [],
      showExpanded: false,
    },
  };

  const closeItem = getContextualMenuItem(
    closeMenuItem,
    EDIT_STATUS_CLOSED_ANY_TRANSFER_REQUEST
  );

  const editMenuItem = {
    EDIT: {
      label: 'Editar',
      name: 'editTransfer',
      disabled: false,
      options: [],
      showExpanded: false,
    },
  };

  const editItem = getContextualMenuItem(
    editMenuItem,
    EDIT_ANY_TRANSFER_REQUEST
  );

  const cancelMenuItem = {
    CANCEL: {
      label: 'Cancelar',
      name: 'cancelTransfer',
      disabled: false,
      options: [],
      showExpanded: false,
    },
  };

  const cancelItem = getContextualMenuItem(
    cancelMenuItem,
    EDIT_STATUS_CANCELLED_ANY_TRANSFER_REQUEST
  );

  const allItems = {
    /* ...authorizeItem,  TODO: */
    ...rejectItem,
    ...closeItem,
    ...editItem,
    ...cancelItem,
  };

  return {
    allItems,
    closeItem,
  };
};

export const contextualMenu = getContextualMenu().allItems;

export const getTransferContextualMenuItems = (transferStatus) => {
  const { closeItem, allItems } = getContextualMenu();
  let menuItems = {};

  switch (transferStatus) {
    case TRANSFER_STATUSES.PROCESS:
      menuItems = allItems;
      // menuItems = {
      //   ...closeItem,
      // };
      break;
    case TRANSFER_STATUSES.CONCLUDED:
    case TRANSFER_STATUSES.CANCELED:
    case TRANSFER_STATUSES.REJECTED:
      menuItems = {};
      break;
    case TRANSFER_STATUSES.REQUESTED:
      menuItems = allItems;
      break;
    default:
      menuItems = allItems;
      break;
  }
  if (!validateRolePermissions(EDIT_ANY_TRANSFER_REQUEST))
    delete menuItems.EDIT;
  if (!validateRolePermissions(EDIT_STATUS_AUTHORIZE_TRANSFER_REQUEST))
    delete menuItems.AUTHORIZE;
  if (!validateRolePermissions(EDIT_STATUS_CANCELLED_ANY_TRANSFER_REQUEST))
    delete menuItems.CANCEL;
  if (!validateRolePermissions(EDIT_STATUS_CLOSED_ANY_TRANSFER_REQUEST))
    delete menuItems.CLOSE;
  if (!validateRolePermissions(EDIT_STATUS_REJECTED_ANY_TRANSFER_REQUEST))
    delete menuItems.REJECT;

  return Object.values(menuItems);
};

const haveToShowDropdownCell = () => {
  const PERMISSIONS = [
    EDIT_STATUS_AUTHORIZE_TRANSFER_REQUEST,
    EDIT_STATUS_REJECTED_ANY_TRANSFER_REQUEST,
    EDIT_STATUS_CLOSED_ANY_TRANSFER_REQUEST,
    EDIT_ANY_TRANSFER_REQUEST,
    EDIT_STATUS_CANCELLED_ANY_TRANSFER_REQUEST,
  ];

  const hasValidPermission = PERMISSIONS.some((permission) =>
    validateRolePermissions(permission)
  );

  console.log(hasValidPermission);

  return hasValidPermission;
};

export const showDropdownCell = haveToShowDropdownCell();

export default [
  {
    label: 'Id',
    field: 'folio',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={numericMask(10)}
        name="folio"
        searchKey="folio"
        action={setTableParams}
        formikSubmit={false}
      />
    ),
  },
  {
    label: 'Observaciones',
    field: 'observations',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(40)}
        name="observations"
        searchKey="observations"
        action={setTableParams}
        formikSubmit={false}
      />
    ),
  },
  {
    label: 'Salida',
    field: 'departureDateTime',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();

      return (
        <AdditionalHeaderCamp
          name="departureDate"
          mask={numericMask(10)}
          searchKey="departureDate"
          type="DATE"
          version={VERSIONS.V2}
          action={setTableParams}
          datePickerProps={{
            resetDate: Boolean(values.departureDate),
            onResetDate: () => {
              setFieldValue('departureDate', '');
              dispatch(setTableParams({ departureDate: '' }));
            },
            minimumDate: MIN_SEARCH_DATE,
          }}
        />
      );
    },
  },
  {
    label: 'Salida real',
    field: 'realDepartureDateTime',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();

      return (
        <AdditionalHeaderCamp
          name="realDepartureDate"
          mask={numericMask(10)}
          searchKey="realDepartureDate"
          type="DATE"
          version={VERSIONS.V2}
          action={setTableParams}
          datePickerProps={{
            resetDate: Boolean(values.realDepartureDate),
            onResetDate: () => {
              setFieldValue('realDepartureDate', '');
              dispatch(setTableParams({ realDepartureDate: '' }));
            },
            minimumDate: MIN_SEARCH_DATE,
          }}
        />
      );
    },
  },
  {
    label: 'Llegada',
    field: 'arrivalDateTime',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();

      return (
        <AdditionalHeaderCamp
          name="arrivalDate"
          mask={numericMask(10)}
          searchKey="arrivalDate"
          type="DATE"
          version={VERSIONS.V2}
          action={setTableParams}
          datePickerProps={{
            resetDate: Boolean(values.arrivalDate),
            onResetDate: () => {
              setFieldValue('arrivalDate', '');
              dispatch(setTableParams({ arrivalDate: '' }));
            },
            minimumDate: MIN_SEARCH_DATE,
          }}
        />
      );
    },
  },
  {
    label: 'Llegada real',
    field: 'realArrivalDateTime',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => {
      const { setFieldValue, values } = useFormikContext();
      const dispatch = useDispatch();

      return (
        <AdditionalHeaderCamp
          name="realArrivalDate"
          mask={numericMask(10)}
          searchKey="realArrivalDate"
          type="DATE"
          version={VERSIONS.V2}
          action={setTableParams}
          datePickerProps={{
            resetDate: Boolean(values.realArrivalDate),
            onResetDate: () => {
              setFieldValue('realArrivalDate', '');
              dispatch(setTableParams({ realArrivalDate: '' }));
            },
            minimumDate: MIN_SEARCH_DATE,
          }}
        />
      );
    },
  },
  {
    label: 'Origen',
    field: 'departureWarehouseName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="departureWarehouse"
        searchKey="departureWarehouse"
        options="commons.catalogsWarehouses"
        selectProps={{ availableFirstValue: true }}
        type="SELECT"
        action={setTableParams}
      />
    ),
  },
  {
    label: 'Destino',
    field: 'destinyWarehouseName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="destinyWarehouse"
        searchKey="destinyWarehouse"
        options="commons.catalogsWarehouses"
        selectProps={{ availableFirstValue: true }}
        type="SELECT"
        action={setTableParams}
      />
    ),
  },
  {
    label: 'Conductor',
    field: 'driverName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(40)}
        name="driverName"
        searchKey="driverName"
        action={setTableParams}
        formikSubmit={false}
      />
    ),
  },
  {
    label: 'Usuario abrió',
    field: 'requesterName',
    align: 'center',
    renderer: CellText,
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        mask={onlyLettersWithSpaces(40)}
        name="requesterName"
        searchKey="requesterName"
        action={setTableParams}
        formikSubmit={false}
      />
    ),
  },
  {
    label: 'Estatus',
    field: 'transferStatus',
    align: 'center',
    renderer: (props) => {
      const { data = {} } = props;
      return (
        <CellDashed
          {...props}
          fixedColor={data.transferStatus.color}
          value={data.transferStatus.name}
          align="left"
          textClass="text-left"
        />
      );
    },
    AdditionalHeaderCamp: () => (
      <AdditionalHeaderCamp
        name="transferStatus"
        searchKey="status"
        options="commons.catalogsTransferStatuses"
        selectProps={{ availableFirstValue: true }}
        type="SELECT"
        action={setTableParams}
      />
    ),
  },
];
