import {
  MaskedInput as Input,
  Section,
  TextField,
} from '@casanova/casanova-common';
import {
  currencyMask,
  numericMask,
} from '@casanova/casanova-common/lib/utils/masks';
import { useFormikContext } from 'formik';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';

const PayDetail = (props) => {
  const { reservationDetails, contract, totalPay } = props;
  const [total, setTotal] = useState('');
  const [payTotal, setPayTotal] = useState('0');

  const { setFieldValue } = useFormikContext();

  console.log(reservationDetails, totalPay);

  useEffect(() => {
    if (!_isEmpty(reservationDetails)) {
      setTotal(reservationDetails.priceQuote.total);
      setPayTotal(totalPay);
      setFieldValue('contractUuid', contract.uuid);
      setFieldValue('userUuid', reservationDetails.naturalPerson.uuid);
      setFieldValue(
        'branchUuid',
        reservationDetails.priceQuote.returnBranch.uuid
      );
    }
  }, [reservationDetails]);

  return (
    <Section title="Detalles de abono">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <TextField title="Total" value={`$${total}`} />
              </div>
              <div className="col-6">
                <TextField title="Total abonado" value={`$${payTotal}`} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Input
                  mask={currencyMask(5, 2)}
                  maskPlaceholder=""
                  label="Cantidad a abonar"
                  placeholder="Cantidad a abonar"
                  name="amount"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default (props) => <PayDetail {...props} />;
