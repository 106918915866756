import React, { useMemo } from 'react';
import { withForm } from 'hooks';
import { LoanInformation } from '@loans/sections';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { config } from './config';

function LoanEditor(props) {
  const { match } = props;
  const loanUuid = useMemo(() => match.params.id, [match]);
  return (
    <LoanInformation
      {...props}
      loanUuid={loanUuid}
      action={FORM_ACTIONS.EDIT}
    />
  );
}

export default withForm({ config })(LoanEditor);
