import React from 'react';
import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import {
  setTitles,
  getDiscounts,
  closeSendQuoteDialog,
  closeSendReservationDialog,
  sendReservation,
} from 'store/reservations/actions';
import { fetchCatalog } from 'store/commons/actions';
import { BackButton } from '@reservations/components';
import { getQuotePath } from 'utils/navigation';
import { getDetail } from 'store/customers/actions';
import Three from './Three';

const stepConfig = {
  page: getQuotePath('two'),
};

export default compose(
  connect(
    (state) => ({
      ..._get(state, 'reservations.quote.values', {}),
      sendQuoteDialog: _get(state, 'reservations.sendQuoteDialog', false),
      sendReservationDialog: _get(
        state,
        'reservations.sendReservationDialog',
        false
      ),
      successPath: _get(state, 'reservations.successPath', ''),
      summaryInfo: _get(state, 'customers.summaryInfo.data', {}),
      reservationSendReservation: _get(
        state,
        'reservations.reservation.sendReservation',
        {}
      ),
      branches: _get(state, 'commons.branches.list', []),
      selectedCategoryName: _get(
        state,
        'reservations.filters.selectedCategory.label',
        ''
      ),
      left: <BackButton {...stepConfig} />,
      loader: _get(state, 'commons.loader', false),
      confirmationMessage: false,
      onCancelPath: `${getQuotePath('one')}?isCanceling=true`,
    }),
    {
      setTitles,
      fetchCatalog,
      getDiscounts,
      closeSendQuoteDialog,
      closeSendReservationDialog,
      sendReservation,
      getCustomerDetail: getDetail,
    }
  )
)(Three);
