import { call, put } from 'redux-saga/effects';
import _get from 'lodash/get';
import { fetchCatalog, getDataByZipCode } from 'services/commons';
import {
  fetchCatalogSucceeded,
  fetchCatalogFailed,
  getSuburbsByZipCodeFailed,
  getSuburbsByZipCodeSucceeded,
  getTaxZipCodeFailed,
  getTaxZipCodeSucceeded,
} from './actions';

export function* fetchCommonCatalogSaga(action) {
  const catalogId = _get(action, 'payload.catalogId', action.payload);
  const catalogPath = _get(action, 'payload.catalogPath', null);
  const catalogName = action?.payload?.catalogName;
  const params = _get(action, 'payload.params', {});

  try {
    const identifier = catalogName || action.payload;

    const data = yield call(
      fetchCatalog,
      catalogId,
      catalogPath,
      params,
      {},
      catalogName
    );

    const dataCustom =
      identifier.initialLows === undefined ? [] : identifier.initialLows;

    if (dataCustom?.length <= 0) {
      dataCustom.push(data);
    } else {
      dataCustom[identifier?.index] = data;
    }

    yield put(fetchCatalogSucceeded({ data, identifier, dataCustom }));
  } catch (error) {
    yield put(fetchCatalogFailed({ error, identifier: action.payload }));
    return error;
  }
}

export function* getSuburbsByZipCodeSaga(action) {
  try {
    const data = yield call(getDataByZipCode, action.payload);
    yield put(getSuburbsByZipCodeSucceeded({ data }));
  } catch (error) {
    yield put(getSuburbsByZipCodeFailed({ error }));
  }
}

export function* getTaxZipCodeSaga(action) {
  try {
    const data = yield call(getDataByZipCode, action.payload);
    yield put(getTaxZipCodeSucceeded({ data }));
  } catch (error) {
    yield put(getTaxZipCodeFailed({ error }));
  }
}
