import qs from 'qs';
import { sendCustomRequest } from '@casanova/casanova-common/lib/utils/service';
import { APIError } from 'utils/exceptions';
import { cleanParams } from 'utils/requests';

const root = 'vehicle/v1';

export async function getTransfersList({ params }, options = {}) {
  const queryParams = `?${qs.stringify(cleanParams(params), {
    encode: false,
  })}`;
  const endpoint = `${root}/transfers${queryParams}`;

  const response = await sendCustomRequest(endpoint, options);
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  const { data = {} } = response.data;
  return data;
}

export async function saveDatesTransfers(uuid, request) {
  const endpoint = `${root}/transfers/${uuid}/closure/date`;
  const response = await sendCustomRequest(endpoint, {}, request, 'post');
  return response.data.data;
}

export async function saveTransfer(request) {
  const endpoint = `${root}/transfers`;
  const response = await sendCustomRequest(endpoint, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function getTransferDetail(uuid) {
  const endpoint = `${root}/transfers/${uuid}`;
  const response = await sendCustomRequest(endpoint, {}, undefined, 'GET');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function authorizeTransfer(uuid) {
  const endpoint = `${root}/transfers/${uuid}/authorization`;
  const response = await sendCustomRequest(endpoint, {}, {}, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function cancelTransfer(uuid) {
  const endpoint = `${root}/transfers/${uuid}/cancellation`;
  const response = await sendCustomRequest(endpoint, {}, {}, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function closeTransfer(uuid) {
  const endpoint = `${root}/transfers/${uuid}/closure`;
  const response = await sendCustomRequest(endpoint, {}, {}, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}
export async function rejectTransfer(uuid) {
  const endpoint = `${root}/transfers/${uuid}/rejected`;
  const response = await sendCustomRequest(endpoint, {}, {}, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}

export async function editTransfer(request, uuid) {
  const endpoint = `${root}/transfers/${uuid}`;
  const response = await sendCustomRequest(endpoint, {}, request, 'PATCH');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data;
}
