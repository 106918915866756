import _get from 'lodash/get';
import { connect } from 'react-redux';
import ExtendList from './ExtendList';
import compose from 'recompose/compose';

export default compose(
  connect(
    (state) => ({
      extendContractDetails: _get(
        state,
        'reservations.contract.extendContract',
        {}
      ),
    }),
    {}
  )
)(ExtendList);
