import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useField, useFormikContext } from 'formik';
import { setParamsListOfReservations } from 'store/reservations/actions';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import classNames from 'classnames';
import {
  MaskedInput as Input,
  DatePickerV2 as DatePicker,
  Select,
  ConnectedSelect,
  NumberInput,
} from '@casanova/casanova-common';
import {
  today2CalendarDate,
  date2CalendarDate,
  generateCalendarMaxDate,
} from '@casanova/casanova-common/lib/utils/date';
import { KEYBOARD_CODES } from 'utils/constants/keyboardCodes';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import moment from 'moment';

export default function SimpleSearchInput({
  placeholder = 'BUSCAR...',
  name,
  mask,
  type = 'INPUT',
  selectLabel = 'BUSCAR...',
  disableLabel = true,
  options,
  onChange,
  onSubmit,
  onBlur = _noop,
  disabled = false,
  className,
  inputMaskProps = {},
  label = '',
  selectProps = { availableFirstValue: true },
  dateProps = {},
  formikSubmit = true,
  defaultStyle = true,
  datePickerProps = {},
  decimals = 2,
}) {
  addLocale('es', {
    firstDayOfWeek: 0,
    dayNames: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    monthNamesShort: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ],
    today: 'Hoy',
    clear: 'Limpiar',
  });

  const [minimumDate, setMinimumDate] = useState(today2CalendarDate());
  const [maxDate, setMaxDate] = useState(generateCalendarMaxDate(1, 0, 0));
  const [primeMinDate, setPrimeMinDate] = useState('');
  const [primeMaxDate, setPrimeMaxDate] = useState('');
  const [dateClick, setDateClick] = useState(1);
  const [dateClickStart, setDateClickStart] = useState('');

  const { handleSubmit } = useFormikContext();
  const [dates, setDates] = useState(null);
  const dispatch = useDispatch();
  const [, meta, helpers] = useField(name);
  const { value, error } = meta;
  const { setTouched } = helpers;

  const minimumDateRx = useSelector((state) =>
    _get(state, dateProps.minimumDate, '')
  );

  const maxDateRx = useSelector((state) =>
    _get(state, dateProps.maximumDate, '')
  );

  const isNumber = type === 'number';

  const handleKeyPress = ({
    target: { value: currentValue },
    nativeEvent: { charCode },
  }) => {
    if (charCode === KEYBOARD_CODES.ENTER) {
      return formikSubmit
        ? handleSubmit({ [name]: currentValue })
        : onSubmit(currentValue); 
    }
  };

  const setParamDate = (value) => {
    const { start, end } = value;
    const fieldStart = name + 'Start';
    const fieldEnd = name + 'End';
    if (start === undefined)
      dispatch(
        setParamsListOfReservations({
          [fieldEnd]: end,
        })
      );
    else
      dispatch(
        setParamsListOfReservations({
          [fieldStart]: start,
          [fieldEnd]: end,
        })
      );
  };

  const handleDateSelected = (target) => {
    const date = moment(target.value);
    if (dateClick == 1) {
      setParamDate({
        start: date.format('YYYY-MM-DD'),
        end: date.format('YYYY-MM-DD'),
      });
      setDateClickStart(date);
    } else {
      if (date > dateClickStart)
        setParamDate({ end: date.format('YYYY-MM-DD') });
      else
        setParamDate({
          start: date.format('YYYY-MM-DD'),
          end: dateClickStart.format('YYYY-MM-DD'),
        });
    }
    setDateClick(dateClick > 1 ? 1 : 2);
  };

  const handleClearButtonClick = () => {
    setParamDate({ start: '', end: '' });
    setDateClick(1);
  };

  const handleBlur = useCallback(() => {
    if (!error && value) onBlur(value);
    else setTouched(true);
  }, [error, value, onBlur, setTouched]);

  const handleBlurProof = ({
    target: { value: currentValue },
    nativeEvent: { charCode },
  }) => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if(/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent))
      return formikSubmit
        ? handleSubmit({ [name]: currentValue })
        : onSubmit(currentValue);
  };

  const getCalendarDateLimit = (dateRx) => {
    const date = moment(dateRx).startOf('day');
    let limit = new Date();
    limit.setFullYear(date.year());
    limit.setMonth(date.month());
    limit.setDate(date.date());
    return limit;
  };

  useEffect(() => {
    if (type == 'DATERANGE') {
      if (minimumDateRx !== '')
        setPrimeMinDate(getCalendarDateLimit(minimumDateRx));
      if (maxDateRx !== '') setPrimeMaxDate(getCalendarDateLimit(maxDateRx));
    } else if (type == 'DATE') {
      setMinimumDate(date2CalendarDate(minimumDateRx));
      setMaxDate(date2CalendarDate(maxDateRx));
    }
  }, [minimumDateRx, maxDateRx]);

  const InputComponent = isNumber ? NumberInput : Input;

  const renderNumberInput = (
    <InputComponent
      label={label}
      placeholder={placeholder}
      name={name}
      onKeyPress={handleKeyPress}
      // onInput={handleKeyPress}
      onBlur={handleBlur}
      disabled={disabled}
      className={className}
      decimals={decimals}
    />
  );

  const renderInput = (
    <InputComponent
      mask={mask}
      maskPlaceholder=""
      label={label}
      placeholder={placeholder}
      name={name}
      onKeyPress={handleKeyPress}
      onBlur={handleBlurProof}
      disabled={disabled}
      className={className}
      {...inputMaskProps}
    />
  );

  const SelectComponent =
    typeof options === 'object' ? Select : ConnectedSelect;

  const renderSelect = (
    <SelectComponent
      disableLabel={disableLabel}
      label={selectLabel}
      name={name}
      options={options}
      onChange={onChange}
      className={className}
      disabled={disabled}
      {...selectProps}
    />
  );

  const renderDate = (
    <DatePicker
      placeholder={placeholder}
      name={name}
      label={label}
      disabled={disabled}
      onBlur={handleBlur}
      onChange={onChange}
      initialYear={2010}
      numberYears={72}
      fromSearch
      minimumDate={minimumDate}
      maximumDate={maxDate}
      autoComplete="off"
      {...datePickerProps}
    />
  );

  const renderDateRange = (
    <Calendar
      value={dates}
      placeholder={placeholder}
      name={name}
      className="p-input-filled"
      disabled={disabled}
      onBlur={handleBlur}
      onChange={(e) => setDates(e.value)}
      onSelect={handleDateSelected}
      onClearButtonClick={handleClearButtonClick}
      onTodayButtonClick={handleDateSelected}
      minDate={primeMinDate}
      maxDate={primeMaxDate}
      dateFormat="dd/M/y"
      selectionMode="range"
      locale="es"
      showButtonBar
    />
  );

  const RENDERS = {
    INPUT: renderInput,
    number: renderNumberInput,
    SELECT: renderSelect,
    DATE: renderDate,
    DATERANGE: renderDateRange,
  };

  return (
    <div className={classNames(defaultStyle && 'my-2', className)}>
      {RENDERS[type]}
    </div>
  );
}
