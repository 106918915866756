import types from './actions-types';
import { names } from './actions';

const initialState = {
  activeRoute: null,
  prevSpecificLabel: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case names.setActiveRoute: {
      return {
        ...state,
        prevActiveRoute: state.activeRoute,
        activeRoute: payload,
      };
    }
    case names.setPrevActiveRoute: {
      return {
        ...state,
        activeRoute: state.prevActiveRoute,
      };
    }
    case types.SET_SPECIFIC_LABEL:
      if (!state.activeRoute) return state;

      return {
        ...state,
        activeRoute: {
          ...state.activeRoute,
          specific: {
            ...state.activeRoute.specific,
            label: payload,
          },
        },
        prevSpecificLabel: state.activeRoute?.specific?.label,
      };
    default: {
      return state;
    }
  }
}
