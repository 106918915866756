import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const BoxAdd = ({ fn, classname }) => (
  <button onClick={fn} className={classname}>
    <p>+</p>
  </button>
);

BoxAdd.propTypes = {
  classname: PropTypes.string.isRequired,
  fn: PropTypes.func.isRequired,
};

export default BoxAdd;
