import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import i18n from '@i18n';
import { Icons } from '@casanova/casanova-common';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import { withForm } from 'hooks';
import { config } from './config';

function CancelLoan({
  show = false,
  setShow = () => {},
  loader = false,
  uuid = '',
}) {
  const { handleSubmit, setFieldValue } = useFormikContext();

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleAction = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  useEffect(() => {
    setFieldValue('uuid', uuid);
  }, [setFieldValue, uuid]);

  return (
    <ActionModal
      open={show && !loader}
      icon={<Icons.StatusWarning />}
      onClose={handleClose}
      onAction={handleAction}
      actionLabel={i18n('COMMONS__YES_CONTINUE')}
      title={i18n('LOANS__CANCEL_LOAN__MODAL__TITLE')}
      message={i18n('LOANS__CANCEL_LOAN__MODAL__MESSAGE')}
    />
  );
}

export default (props) => {
  const { setShow } = props;

  const handleBeforeSubmit = useCallback(() => {
    setShow(false);
  }, []);

  const Component = withForm({
    config: {
      ...config,
      onBeforeSubmit: handleBeforeSubmit,
    },
  })(CancelLoan);

  return Component(props);
};
