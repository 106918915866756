import * as Yup from 'yup';
import i18n from '@i18n';
import {
  differentValue,
  dynamicRequired,
  fileValidation,
  dateValidationGreat,
} from 'utils/validations/common';
import { initialValues, FIELDS } from './config';

const validateFieldOfSection = dynamicRequired({ sectionKeys: initialValues });

const documentTest = fileValidation({
  schema: dynamicRequired({ schema: Yup.mixed(), sectionKeys: initialValues }),
  fields: FIELDS,
});

export const visaInformationProps = {
  visaNumber: validateFieldOfSection
    .max(20, i18n('ERROR__COMMONS__MAX_LENGTH', [20]))
    .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5])),
  visaYearIssue: validateFieldOfSection,
  visaValidity: differentValue(
    dynamicRequired({
      sectionKeys: initialValues,
      schema: dateValidationGreat,
    }),
    'visaYearIssue',
    i18n('ERROR__CUSTOMERS__PASSPORT__INVALID_VALIDTY_INFO')
  ),
  visaCountry: validateFieldOfSection,
  visaCoverFront: documentTest,
  visaCoverBack: documentTest,
};

const VisaInformationSchema = Yup.object().shape(visaInformationProps);

export default VisaInformationSchema;
