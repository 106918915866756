import { connect } from 'react-redux';
import {
  uploadVehicles,
  downloadTemplate,
  downloadFailedRecords,
  closeResultMessage,
  openConfirmationMessage,
  closeConfirmationMessage,
  setMassiveLoadType,
} from 'store/vehicles/actions';
import VehicleMassLoad from './VehicleMassLoad';

export default connect(
  (state) => ({
    massiveLoadType: state.vehicles.massiveLoadType,
    massiveRecords: state.vehicles.massiveRecords,
    loader: state.vehicles.loader,
    resultMessage: state.vehicles.resultMessage,
    confirmationMessage: state.vehicles.confirmationMessage,
  }),
  {
    closeResultMessage,
    openConfirmationMessage,
    closeConfirmationMessage,
    uploadVehicles,
    downloadFailedRecords,
    downloadTemplate,
    setMassiveLoadType,
  }
)(VehicleMassLoad);
