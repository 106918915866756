import { all, fork } from 'redux-saga/effects';
import authSaga from './auth';
import catalogsSaga from './catalogs';
import vehiclesSage from './vehicles';
import commonsSaga from './commons';
import customerSaga from './customers';
import reservationsSaga from './reservations';
import adminPanelSaga from './adminPanel';
import activation from './activation';
import transfersSaga from './transfers';
import loansSaga from './loans';
import contractsSaga from './contracts';
import invoicesSaga from './invoices';
import paymentsSaga from './payments';
import dailyRentContractsSaga from './dailyRentContracts';
import reportsSaga from './reports';
import appSaga from './app';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(catalogsSaga),
    fork(vehiclesSage),
    fork(commonsSaga),
    fork(appSaga),
    fork(customerSaga),
    fork(reservationsSaga),
    fork(adminPanelSaga),
    fork(activation),
    fork(transfersSaga),
    fork(loansSaga),
    fork(contractsSaga),
    fork(invoicesSaga),
    fork(paymentsSaga),
    fork(dailyRentContractsSaga),
    fork(reportsSaga),
  ]);
}
