import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icons } from '@casanova/casanova-common';

import './BackButton.scss';

export default function BackButton({ path = '', onClick }) {
  const history = useHistory();

  const handleClick = useCallback(() => {
    if (path && !onClick) {
      history.push(path);
    } else {
      onClick();
    }
  }, [history, path, onClick]);

  return (
    <Button
      outline
      className="left-button-form mb-4 mb-md-0 back-icon"
      onClick={handleClick}
    >
      <Icons.Atras width={24} />
      <h6 className="d-inline ml-2">Atrás</h6>
    </Button>
  );
}
