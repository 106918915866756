export const CREATE_EMPLOYEE_USER = 'CREATE_EMPLOYEE_USER';
export const CREATE_EMPLOYEE_USER_ENABLED = 'CREATE_EMPLOYEE_USER_ENABLED';
export const CREATE_USER_CATALOG_ENTRY = 'CREATE_USER_CATALOG_ENTRY';
export const CREATE_VEHICLE_ADAPTATION = 'CREATE_VEHICLE_ADAPTATION';
export const CREATE_VEHICLE_BATCH = 'CREATE_VEHICLE_BATCH';
export const CREATE_VEHICLE_CATALOG_ENTRY = 'CREATE_VEHICLE_CATALOG_ENTRY';
export const CREATE_VEHICLE_CIRCULATION_CARD =
  'CREATE_VEHICLE_CIRCULATION_CARD';
export const CREATE_VEHICLE_CONVERSION = 'CREATE_VEHICLE_CONVERSION';
export const CREATE_VEHICLE_FINES = 'CREATE_VEHICLE_FINES';
export const CREATE_VEHICLE_GENERAL_INFORMATION =
  'CREATE_VEHICLE_GENERAL_INFORMATION';
export const CREATE_VEHICLE_GPS_ORDERS = 'CREATE_VEHICLE_GPS_ORDERS';
export const CREATE_VEHICLE_GPS_ORDERS_GPS = 'CREATE_VEHICLE_GPS_ORDERS_GPS';
export const CREATE_VEHICLE_GPS_ORDERS_INSTALLERS =
  'CREATE_VEHICLE_GPS_ORDERS_INSTALLERS';
export const CREATE_VEHICLE_KEYS = 'CREATE_VEHICLE_KEYS';
export const CREATE_VEHICLE_MANUAL = 'CREATE_VEHICLE_MANUAL';
export const CREATE_VEHICLE_PERMISSIONS = 'CREATE_VEHICLE_PERMISSIONS';
export const CREATE_VEHICLE_PLATE = 'CREATE_VEHICLE_PLATE';
export const CREATE_VEHICLE_POLICY = 'CREATE_VEHICLE_POLICY';
export const CREATE_VEHICLE_PURCHASE_CFDI_COPY =
  'CREATE_VEHICLE_PURCHASE_CFDI_COPY';
export const CREATE_VEHICLE_PURCHASE_CFDI_ORIGINAL =
  'CREATE_VEHICLE_PURCHASE_CFDI_ORIGINAL';
export const CREATE_VEHICLE_PURCHASE_INFORMATION =
  'CREATE_VEHICLE_PURCHASE_INFORMATION';
export const CREATE_VEHICLE_ROAD_TAX = 'CREATE_VEHICLE_ROAD_TAX';
export const CREATE_VEHICLE_SINISTER = 'CREATE_VEHICLE_SINISTER';
export const CREATE_VEHICLE_VERIFICATION = 'CREATE_VEHICLE_VERIFICATION';
export const DELETE_VEHICLE_ADAPTATION = 'DELETE_VEHICLE_ADAPTATION';
export const DELETE_VEHICLE_CATALOG_ENTRY = 'DELETE_VEHICLE_CATALOG_ENTRY';
export const DELETE_VEHICLE_CIRCULATION_CARD =
  'DELETE_VEHICLE_CIRCULATION_CARD';
export const DELETE_VEHICLE_CONVERSION = 'DELETE_VEHICLE_CONVERSION';
export const DELETE_VEHICLE_FINES = 'DELETE_VEHICLE_FINES';
export const DELETE_VEHICLE_GENERAL_INFORMATION =
  'DELETE_VEHICLE_GENERAL_INFORMATION';
export const DELETE_VEHICLE_GPS_ORDERS = 'DELETE_VEHICLE_GPS_ORDERS';
export const DELETE_VEHICLE_KEYS = 'DELETE_VEHICLE_KEYS';
export const DELETE_VEHICLE_PERMISSIONS = 'DELETE_VEHICLE_PERMISSIONS';
export const DELETE_VEHICLE_PLATE = 'DELETE_VEHICLE_PLATE';
export const DELETE_VEHICLE_POLICY = 'DELETE_VEHICLE_POLICY';
export const DELETE_VEHICLE_PURCHASE_CFDI_COPY =
  'DELETE_VEHICLE_PURCHASE_CFDI_COPY';
export const DELETE_VEHICLE_PURCHASE_CFDI_ORIGINAL =
  'DELETE_VEHICLE_PURCHASE_CFDI_ORIGINAL';
export const DELETE_VEHICLE_PURCHASE_INFORMATION =
  'DELETE_VEHICLE_PURCHASE_INFORMATION';
export const DELETE_VEHICLE_ROAD_TAX = 'DELETE_VEHICLE_ROAD_TAX';
export const DELETE_VEHICLE_SINISTER = 'DELETE_VEHICLE_SINISTER';
export const DELETE_VEHICLE_VERIFICATION = 'DELETE_VEHICLE_VERIFICATION';

export const EDIT_USER_CATALOG_ENTRY = 'EDIT_USER_CATALOG_ENTRY';
export const EDIT_USER_INFORMATION = 'EDIT_USER_INFORMATION';
export const EDIT_ANY_USER_INFORMATION = 'EDIT_ANY_USER_INFORMATION';
export const EDIT_USER_ENABLE = 'EDIT_USER_ENABLE';
export const EDIT_USER_DISABLE = 'EDIT_USER_DISABLE';
export const EDIT_USER_ROLES = 'EDIT_USER_ROLES';
export const EDIT_VEHICLE_ADAPTATION = 'EDIT_VEHICLE_ADAPTATION';
export const EDIT_VEHICLE_CIRCULATION_CARD = 'EDIT_VEHICLE_CIRCULATION_CARD';
export const EDIT_VEHICLE_CONVERSION = 'EDIT_VEHICLE_CONVERSION';
export const EDIT_VEHICLE_FINES = 'EDIT_VEHICLE_FINES';
export const EDIT_VEHICLE_GENERAL_INFORMATION =
  'EDIT_VEHICLE_GENERAL_INFORMATION';
export const EDIT_VEHICLE_GPS_ORDERS = 'EDIT_VEHICLE_GPS_ORDERS';
export const EDIT_VEHICLE_KEYS = 'EDIT_VEHICLE_KEYS';
export const EDIT_VEHICLE_PERMISSIONS = 'EDIT_VEHICLE_PERMISSIONS';
export const EDIT_VEHICLE_PLATE = 'EDIT_VEHICLE_PLATE';
export const EDIT_VEHICLE_POLICY = 'EDIT_VEHICLE_POLICY';
export const EDIT_VEHICLE_PURCHASE_CFDI_COPY =
  'EDIT_VEHICLE_PURCHASE_CFDI_COPY';
export const EDIT_VEHICLE_PURCHASE_CFDI_ORIGINAL =
  'EDIT_VEHICLE_PURCHASE_CFDI_ORIGINAL';
export const EDIT_VEHICLE_PURCHASE_INFORMATION =
  'EDIT_VEHICLE_PURCHASE_INFORMATION';
export const EDIT_VEHICLE_ROAD_TAX = 'EDIT_VEHICLE_ROAD_TAX';
export const EDIT_VEHICLE_SINISTER = 'EDIT_VEHICLE_SINISTER';
export const EDIT_VEHICLE_VERIFICATION = 'EDIT_VEHICLE_VERIFICATION';
export const EDIT_VEHICLE_PLATE_NUMBER = 'EDIT_VEHICLE_PLATE_NUMBER';
export const EDIT_VEHICLE_MASTER_DATA = 'EDIT_VEHICLE_MASTER_DATA';

export const VIEW_USER_PERSONAL_INFO = 'VIEW_USER_PERSONAL_INFO';
export const VIEW_USER_INFORMATION = 'VIEW_USER_INFORMATION';
export const VIEW_ANY_USER_INFORMATION = 'VIEW_ANY_USER_INFORMATION';
export const VEHICLE = 'VEHICLE';
export const VIEW_VEHICLE = 'VIEW_VEHICLE';
export const EDIT_VEHICLE = 'EDIT_VEHICLE';
export const VIEW_VEHICLE_ADAPTATION = 'VIEW_VEHICLE_ADAPTATION';
export const VIEW_VEHICLE_ADAPTATION_HISTORY =
  'VIEW_VEHICLE_ADAPTATION_HISTORY';
export const VIEW_VEHICLE_CATALOG = 'VIEW_VEHICLE_CATALOG';
export const VIEW_VEHICLE_CATALOG_RD = 'VIEW_VEHICLE_CATALOG_RD';
export const VIEW_VEHICLE_CIRCULATION_CARD = 'VIEW_VEHICLE_CIRCULATION_CARD';
export const VIEW_VEHICLE_CIRCULATION_CARD_HISTORY =
  'VIEW_VEHICLE_CIRCULATION_CARD_HISTORY';
export const VIEW_VEHICLE_CONVERSION = 'VIEW_VEHICLE_CONVERSION';
export const VIEW_VEHICLE_CONVERSION_HISTORY =
  'VIEW_VEHICLE_CONVERSION_HISTORY';
export const VIEW_VEHICLE_EXCEL = 'VIEW_VEHICLE_EXCEL';
export const VIEW_VEHICLE_EXCEL_CATALOG = 'VIEW_VEHICLE_EXCEL_CATALOG';
export const VIEW_VEHICLE_FINES = 'VIEW_VEHICLE_FINES';
export const VIEW_VEHICLE_FINES_HISTORY = 'VIEW_VEHICLE_FINES_HISTORY';
export const VIEW_VEHICLE_GENERAL_INFORMATION =
  'VIEW_VEHICLE_GENERAL_INFORMATION';
export const VIEW_VEHICLE_GENERAL_INFORMATION_HISTORY =
  'VIEW_VEHICLE_GENERAL_INFORMATION_HISTORY';
export const VIEW_VEHICLE_GPS_ORDERS = 'VIEW_VEHICLE_GPS_ORDERS';
export const VIEW_VEHICLE_GPS_ORDERS_HISTORY =
  'VIEW_VEHICLE_GPS_ORDERS_HISTORY';
export const VIEW_VEHICLE_KEYS = 'VIEW_VEHICLE_KEYS';
export const VIEW_VEHICLE_KEYS_HISTORY = 'VIEW_VEHICLE_KEYS_HISTORY';
export const VIEW_VEHICLE_PERMISSIONS = 'VIEW_VEHICLE_PERMISSIONS';
export const VIEW_VEHICLE_PERMISSIONS_HISTORY =
  'VIEW_VEHICLE_PERMISSIONS_HISTORY';
export const VIEW_VEHICLE_PLATE = 'VIEW_VEHICLE_PLATE';
export const VIEW_VEHICLE_PLATE_HISTORY = 'VIEW_VEHICLE_PLATE_HISTORY';
export const VIEW_VEHICLE_POLICY = 'VIEW_VEHICLE_POLICY';
export const VIEW_VEHICLE_POLICY_HISTORY = 'VIEW_VEHICLE_POLICY_HISTORY';
export const VIEW_VEHICLE_PURCHASE_CFDI_COPY =
  'VIEW_VEHICLE_PURCHASE_CFDI_COPY';
export const VIEW_VEHICLE_PURCHASE_CFDI_COPY_HISTORY =
  'VIEW_VEHICLE_PURCHASE_CFDI_COPY_HISTORY';
export const VIEW_VEHICLE_PURCHASE_CFDI_ORIGINAL =
  'VIEW_VEHICLE_PURCHASE_CFDI_ORIGINAL';
export const VIEW_VEHICLE_PURCHASE_CFDI_ORIGINAL_HISTORY =
  'VIEW_VEHICLE_PURCHASE_CFDI_ORIGINAL_HISTORY';
export const VIEW_VEHICLE_PURCHASE_INFORMATION =
  'VIEW_VEHICLE_PURCHASE_INFORMATION';
export const VIEW_VEHICLE_PURCHASE_INFORMATION_HISTORY =
  'VIEW_VEHICLE_PURCHASE_INFORMATION_HISTORY';
export const VIEW_VEHICLE_ROAD_TAX = 'VIEW_VEHICLE_ROAD_TAX';
export const VIEW_VEHICLE_ROAD_TAX_HISTORY = 'VIEW_VEHICLE_ROAD_TAX_HISTORY';
export const VIEW_VEHICLE_SINISTER = 'VIEW_VEHICLE_SINISTER';
export const VIEW_VEHICLE_SINISTER_HISTORY = 'VIEW_VEHICLE_SINISTER_HISTORY';
export const VIEW_VEHICLE_VERIFICATION = 'VIEW_VEHICLE_VERIFICATION';
export const VIEW_VEHICLE_VERIFICATION_HISTORY =
  'VIEW_VEHICLE_VERIFICATION_HISTORY';
export const VIEW_EMPLOYEE_CATALOG = 'VIEW_EMPLOYEE_CATALOG';
export const VIEW_CURP_INFORMATION = 'VIEW_CURP_INFORMATION';

export const VIEW_VEHICLE_PURCHASE_SECTION = 'VIEW_VEHICLE_PURCHASE_SECTION';
export const VIEW_VEHICLE_ADDITIONALS_SECTION =
  'VIEW_VEHICLE_ADDITIONALS_SECTION';
export const VEHICLES_MODULE = 'VEHICLES_MODULE';
export const VIEW_VEHICLE_RECORDS = 'VIEW_VEHICLE_RECORDS';

export const PANEL_ADMIN_MODULE = 'PANEL_ADMIN_MODULE';

export const VIEW_INVOICES = 'VIEW_INVOICES';
export const VIEW_PAYMENTS = 'VIEW_PAYMENTS';

export const INVOICES_MODULE = 'INVOICES_MODULE';
export const PAYMENTS_MODULE = 'PAYMENTS_MODULE';
export const BINNACLE_MODULE = 'BINNACLE_MODULE';

export const VEHICLES_TLALPAN = 'VEHICLES_TLALPAN';
export const VEHICLES_RENTING = 'VEHICLES_RENTING';
