export const initialTouched = {
  licenseType: true,
  licenseNumber: true,
  antiquity: true,
  expedition: true,
  validityDriverLicense: true,
  nationality: true,
  emissionState: true,
  licenseFrontSide: true,
  licenseBackSide: true,
  isPermanentLicense: true,
};

export const initialValues = {
  licenseType: '',
  licenseNumber: '',
  antiquity: '',
  expedition: '',
  validityDriverLicense: '',
  nationality: '',
  emissionState: '',
  licenseFrontSide: '',
  licenseBackSide: '',
  isPermanentLicense: false,
};

export default {
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
  initialTouched,
};

export const FIELDS = Object.keys(initialValues);