import React from 'react';
import moment from 'moment';
import styles from './styles.module.css';

const DetailsLost = ({ lostDate, lostDescription }) => (
  <>
    <div className="col-12">
      <h6 className={`${styles.title} text-uppercase`}>Fecha de extravío</h6>
      <p className={styles.body}>
        {moment(lostDate).format('DD MMM YYYY').replace('.', '')}
      </p>
    </div>
    <div className="col-12">
      <h6 className={`${styles.title} text-uppercase`}>
        Descripción del extravío
      </h6>
      <p className={styles.body}>{lostDescription}</p>
    </div>
  </>
);

export default DetailsLost;
