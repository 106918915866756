import React, { useCallback, useEffect, useState, Suspense, lazy } from 'react';
import { useFormikContext, validateYupSchema } from 'formik';
import cln from 'classnames';
import {
  Button,
  MaskedInput as Input,
  Icons,
  Loader,
} from '@casanova/casanova-common';
import { alphaEmailMask } from 'utils/masks';
import Modal from '@casanova/casanova-common/lib/Modal';
import { getQuotePath } from 'utils/navigation';
import i18n from '@i18n';
import { sendQuoteSchema } from './config';

const ActionModal = lazy(() =>
  import('@casanova/casanova-common/lib/Modal/ActionModal')
);
const ResponseDialog = lazy(() => import('components/ResponseDialog'));

function SendQuote(props) {
  const {
    open,
    id,
    onClose,
    modalType,
    closeButton,
    data,
    discountForm,
    saveStepThree,
    loader,
    responseDialog,
    closeResponseDialog,
    customerMail,
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    values,
    setErrors,
    setTouched,
    setFieldValue,
    errors,
  } = useFormikContext();

  const handleOnSendDiscount = useCallback(() => {
    validateYupSchema(values, sendQuoteSchema)
      .then(() => {
        setErrors({});
        saveStepThree({
          units: discountForm.units,
          email: values.email,
          save: values.preserveQuote,
          comment: values.comment.trim(),
        });
        // setFieldValue('comment', '');
      })
      .catch((errorsdd) => {
        const err = errorsdd.inner.reduce(
          (prev, current) => ({
            ...prev,
            [current.path]: current.message,
          }),
          {}
        );
        setTouched({ email: true });
        setErrors(err);
      });
  }, [values, setErrors, setTouched, discountForm, saveStepThree]);

  // const [email, setEmail] = useState(customerMail);

  useEffect(() => {
    setFieldValue('email', customerMail);
  }, [open]);

  useEffect(() => {
    if (!discountForm.isPromocode) {
      if (discountForm.finished && !discountForm.success) {
        setErrors({ apiResponse: discountForm.message });
      } else {
        setErrors({});
      }
    }
  }, [discountForm, setErrors]);

  const handleFieldsChange = useCallback(() => {
    setErrors({});
  }, [setErrors]);

  const handleSendQuoteSuccess = useCallback(() => {
    // window.location.href = getQuotePath('one');
    onClose(data);
    closeResponseDialog();
  }, []);

  const header = (
    <div className="modal-header justify-content-center align-items-center">
      <div className="icon">
        <Icons.Email />
      </div>
      <h5 className="modal-title text-center" id={`${id}-label`}>
        ENVIAR COTIZACIÓN
      </h5>
    </div>
  );
  const footer = (
    <div className="modal-footer container">
      {(modalType === 'action' || !modalType) && (
        <div className="row">
          <div className="col-md-6">
            <Button block outline onClick={() => onClose(data)}>
              Cancelar
            </Button>
          </div>
          <div className="col-md-6">
            <Button block onClick={() => setShowConfirm(true)}>
              Enviar
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  const hideModal = loader || responseDialog.open || showConfirm;

  return (
    <Suspense fallback={<Loader show />}>
      <ActionModal
        open={showConfirm}
        icon={<Icons.StatusWarning />}
        title={i18n('RESERVATIONS__SEND_QUOTE__CONFIRMATION__TITLE')}
        actionLabel={i18n('RESERVATIONS__SEND_QUOTE__CONFIRMATION__LABEL')}
        message={i18n('RESERVATIONS__SEND_QUOTE__CONFIRMATION__MESSAGE')}
        closeButton
        onAction={() => {
          setShowConfirm(false);
          handleOnSendDiscount();
        }}
        onClose={() => setShowConfirm(false)}
        modalType={modalType}
      />

      <ResponseDialog
        open={responseDialog.open}
        success={responseDialog.success}
        successTitle={i18n('RESERVATIONS__SEND_QUOTE__SUCCESS__TITLE')}
        successMessage={i18n('RESERVATIONS__SEND_QUOTE__SUCCESS__MESSAGE')}
        // successLabel={i18n('RESERVATIONS__SEND_QUOTE__SUCCESS__LABEL')}
        successLabel={i18n('COMMONS__CLOSE__TEXT')}
        onSuccess={handleSendQuoteSuccess}
        errorMessage={responseDialog.message}
        onError={() => handleOnSendDiscount()}
        onClose={handleSendQuoteSuccess}
      />

      <Modal
        open={open}
        closeButton={closeButton}
        onClose={() => onClose(data)}
        header={header}
        footer={footer}
        className="action-modal"
        topContainerClassName={cln(hideModal && 'd-none')}
        data={data}
      >
        <Loader show={discountForm.submiting} />
        <div className="modal-body">
          <Input
            name="email"
            label="Correo electrónico"
            placeholder="Correo electrónico"
            mask={alphaEmailMask(50)}
            maskPlaceholder=""
            // value={email}
            onChange={handleFieldsChange}
          />

          {/* Waiting for Carlos definition
            <Checkbox
              label="Guardar reservación por 24 hrs para su confirmación."
              name="preserveQuote"
            />
          */}

          {errors && errors.apiResponse && (
            <div className="col-form-error text-danger">
              {errors.apiResponse}
            </div>
          )}
          <br />
        </div>
      </Modal>
    </Suspense>
  );
}

SendQuote.defaultProps = {
  closeLabel: 'Cancelar',
};

export default SendQuote;
