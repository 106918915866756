import i18n from '@i18n';

export const ACTIVATION_STATUS = {
  WELCOME: {
    title: i18n('ACTIVATION__WELCOME__TITLE'),
    subtitle: i18n('ACTIVATION__WELCOME__SUBTITLE'),
  },
  TOKEN_ERROR: {
    title: i18n('ACTIVATION__TOKEN__ERROR__TITLE'),
    subtitle: i18n('ACTIVATION__TOKEN__ERROR__SUBTITLE'),
  },
  PASSWORD_CREATED: {
    title: i18n('ACTIVATION__PASSWORD__CREATED__TITLE'),
    subtitle: i18n('ACTIVATION__PASSWORD__CREATED__SUBTITLE'),
  },
};
