import _isObject from 'lodash/isObject';

export class APIError extends Error {
  constructor(dataOrCode, message, status, ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.name = 'APIError';

    if (_isObject(dataOrCode)) {
      this.data = dataOrCode.data;
      this.code = this.data.errorCode;
      this.message = this.data.message;
      this.status = dataOrCode.status;
    }

    if (!_isObject(dataOrCode)) {
      this.code = dataOrCode;
      this.message = message;
      this.status = status;
    }

    this.date = new Date();
  }
}
