import { call, put } from 'redux-saga/effects';
import { showLoader, hideLoader } from 'store/commons/actions';
import {
  getDailyRentContractsListSucceed,
  getDailyRentContractsListFailed,
  closeContractSucceed,
  closeContractFailed,
  getCloseContractDetailsSucceed,
  getCloseContractDetailsFailed,
} from 'store/dailyRentContracts/actions';
import { getReservationInfoSucceed } from 'store/reservations/actions';
import {
  getDailyRentContracts,
  closeRDContract,
  cancelRDContract,
  getCloseDetailsOfCloseRDContract,
  saveContractRefund,
  saveContractPay,
  getTotalPay,
} from 'services/dailyRentContracts';
import {
  getTotalPayFailed,
  getTotalPaySucceed,
  saveContractRefundFailed,
  saveContractRefundSucceed,
} from './actions';

export function* getDailyRentContractsSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getDailyRentContracts, action.payload.params);
    yield put(getDailyRentContractsListSucceed(data));
  } catch (error) {
    yield put(getDailyRentContractsListFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* closeContractSaga(action) {
  try {
    yield put(showLoader());
    const { name, uuid, authReq } = action.payload;
    const data =
      name === 'closeContract'
        ? yield call(closeRDContract, uuid, authReq)
        : yield call(cancelRDContract, uuid, authReq);
    yield put(closeContractSucceed(data));
  } catch (error) {
    yield put(closeContractFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getCloseContractDetailsSaga(action) {
  try {
    yield put(showLoader());
    const data = yield call(getCloseDetailsOfCloseRDContract, action.payload);
    yield put(getReservationInfoSucceed(data.reservationData));
    yield put(getCloseContractDetailsSucceed(data));
  } catch (error) {
    yield put(getCloseContractDetailsFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveContractRefundSaga({ payload = {} }) {
  try {
    yield put(showLoader());
    const data = yield call(saveContractRefund, payload);
    yield put(saveContractRefundSucceed(data));
  } catch (error) {
    yield put(saveContractRefundFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveContractPaySaga({ payload = {} }) {
  try {
    yield put(showLoader());
    const data = yield call(saveContractPay, payload);
    yield put(saveContractRefundSucceed(data));
  } catch (error) {
    yield put(saveContractRefundFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getTotalPaySaga({ payload = {} }) {
  try {
    yield put(showLoader());
    const data = yield call(getTotalPay, payload);
    yield put(getTotalPaySucceed(data));
  } catch (error) {
    yield put(getTotalPayFailed(error));
  } finally {
    yield put(hideLoader());
  }
}
