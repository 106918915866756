import {
  VERIFY_TOKEN_REQUESTED,
  VERIFY_TOKEN_SUCCESSED,
  VERIFY_TOKEN_FAILED,
  USER_ACTIVATION_REQUESTED,
  USER_ACTIVATION_SUCCESSED,
  USER_ACTIVATION_FAILED,
} from './action-types';

const initialState = {
  verifyToken: {
    data: null,
    error: false,
    errorMessage: '',
  },
  userActivation: {
    data: null,
    error: false,
    errorMessage: '',
  },
};

export function activationReducer(state = initialState, action) {
  switch (action.type) {
    case VERIFY_TOKEN_REQUESTED:
      return {
        ...state,
        verifyToken: {
          data: null,
          error: false,
          errorMessage: '',
        },
      };
    case USER_ACTIVATION_REQUESTED: {
      return {
        ...state,
        userActivation: {
          data: null,
          error: false,
          errorMessage: '',
        },
      };
    }
    case VERIFY_TOKEN_SUCCESSED:
    case USER_ACTIVATION_SUCCESSED: {
      return {
        ...state,
        [action.payload.key]: {
          data: action.payload.data,
          error: false,
          errorMessage: '',
        },
      };
    }
    case VERIFY_TOKEN_FAILED:
    case USER_ACTIVATION_FAILED: {
      return {
        ...state,
        [action.payload.key]: {
          data: null,
          error: true,
          errorMessage: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}
