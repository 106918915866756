import React from 'react';

const VehicleCell = ({ value }) => (
  <td>
    <span className="text-uppercase">{value}</span>
  </td>
);

const VehicleForSaleCell = ({ value }) => (
  <td>
    <span className="text-uppercase">{value}</span>
  </td>
);

export const VehicleArray = [
  {
    id: 'brand',
    label: 'Marca',
    field: 'brand.name',
    renderer: VehicleCell,
  },
  {
    id: 'line',
    label: 'Línea',
    field: 'line.name',
    renderer: VehicleCell,
  },
  {
    id: 'version',
    label: 'Versión',
    field: 'version.name',
    renderer: VehicleCell,
  },
  {
    id: 'model',
    label: 'Modelo',
    field: 'model.name',
    renderer: VehicleCell,
  },
  {
    id: 'serialNumber',
    label: 'No. de serie',
    field: 'serialNumber',
    renderer: VehicleCell,
  },
  {
    id: 'plate',
    label: 'No. placa',
    field: 'plate',
    renderer: VehicleCell,
  },
  {
    id: 'provider',
    label: 'Proveedor',
    field: 'provider.name',
    renderer: VehicleCell,
  },
  {
    id: 'owner',
    label: 'Propietario',
    field: 'owner.name',
    renderer: VehicleCell,
  },
  {
    id: 'status',
    label: 'Estatus',
    field: 'status.name',
    renderer: VehicleCell,
  },
];

export const VehicleForSale = [
  {
    id: 'brand',
    label: 'No de Serie',
    field: 'brand.name',
    renderer: VehicleForSaleCell,
  },
  {
    id: 'line',
    label: 'Vendido a',
    field: 'line.name',
    renderer: VehicleForSaleCell,
  },
  {
    id: 'version',
    label: 'No placa',
    field: 'version.name',
    renderer: VehicleForSaleCell,
  },
  {
    id: 'model',
    label: 'Estado',
    field: 'model.name',
    renderer: VehicleForSaleCell,
  },
  {
    id: 'serialNumber',
    label: 'Fecha de venta',
    field: 'serialNumber',
    renderer: VehicleForSaleCell,
  },
  {
    id: 'plate',
    label: 'Importe de pago',
    field: 'plate',
    renderer: VehicleForSaleCell,
  },
];
