import _get from 'lodash/get';

export const visaTransformer = (visaData = {}) => ({
  visaNumber: _get(visaData, 'identityKey', ''),
  visaYearIssue: _get(visaData, 'issuedYear', ''),
  visaValidity: _get(visaData, 'validityYear', ''),
  visaCountry: _get(visaData, 'country.uuid', ''),
  visaCoverFront: _get(visaData, 'frontScanFile.originalName'),
  visaCoverFrontUrl: _get(visaData, 'frontScanFile.presignedUrl'),
  visaCoverBack: _get(visaData, 'backScanFile.originalName'),
  visaCoverBackUrl: _get(visaData, 'backScanFile.presignedUrl'),
});
