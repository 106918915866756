const RESET = 'transfers/RESET';
const SET_PARAMS = 'transfers/SET_PARAMS';
const GET_LIST = 'transfers/GET_LIST';
const GET_LIST_SUCCEED = 'transfers/GET_LIST_SUCCEED';
const GET_LIST_FAILED = 'transfers/GET_LIST_FAILED';

const SAVE_TRANSFER = 'transfers/SAVE_TRANSFER';
const SAVE_TRANSFER_SUCCEED = 'transfers/SAVE_TRANSFER_SUCCEED';
const SAVE_TRANSFER_FAILED = 'transfers/SAVE_TRANSFER_FAILED';

const GET_TRANSFER_DETAIL = 'transfers/GET_TRANSFER_DETAIL';
const GET_TRANSFER_DETAIL_SUCCEED = 'transfers/GET_TRANSFER_DETAIL_SUCCEED';
const GET_TRANSFER_DETAIL_FAILED = 'transfers/GET_TRANSFER_DETAIL_FAILED';

const CLOSE_TRANSFER = 'transfers/CLOSE_TRANSFER';
const CLOSE_TRANSFER_SUCCEED = 'transfers/CLOSE_TRANSFER_SUCCEED';
const CLOSE_TRANSFER_FAILED = 'transfers/CLOSE_TRANSFER_FAILED';

const AUTHORIZE_TRANSFER = 'transfers/AUTHORIZE_TRANSFER';
const AUTHORIZE_TRANSFER_SUCCEED = 'transfers/AUTHORIZE_TRANSFER_SUCCEED';
const AUTHORIZE_TRANSFER_FAILED = 'transfers/AUTHORIZE_TRANSFER_FAILED';

const CANCEL_TRANSFER = 'transfers/CANCEL_TRANSFER';
const CANCEL_TRANSFER_SUCCEED = 'transfers/CANCEL_TRANSFER_SUCCEED';
const CANCEL_TRANSFER_FAILED = 'transfers/CANCEL_TRANSFER_FAILED';

const REJECT_TRANSFER = 'transfers/REJECT_TRANSFER';
const REJECT_TRANSFER_SUCCEED = 'transfers/REJECT_TRANSFER_SUCCEED';
const REJECT_TRANSFER_FAILED = 'transfers/REJECT_TRANSFER_FAILED';

const EDIT_TRANSFER = 'transfers/EDIT_TRANSFER';
const EDIT_TRANSFER_SUCCEED = 'transfers/EDIT_TRANSFER_SUCCEED';
const EDIT_TRANSFER_FAILED = 'transfers/EDIT_TRANSFER_FAILED';

const CLOSE_RESPONSE_DIALOG = 'transfers/CLOSE_RESPONSE_DIALOG';
const SET_TABLE_PARAMS = 'transfers/SET_TABLE_PARAMS';
const SET_TABLE_PAGE = 'transfers/SET_TABLE_PAGE';

export default {
  GET_LIST,
  GET_LIST_SUCCEED,
  GET_LIST_FAILED,
  RESET,
  SET_PARAMS,
  CLOSE_RESPONSE_DIALOG,
  SAVE_TRANSFER,
  SAVE_TRANSFER_SUCCEED,
  SAVE_TRANSFER_FAILED,
  GET_TRANSFER_DETAIL,
  GET_TRANSFER_DETAIL_SUCCEED,
  GET_TRANSFER_DETAIL_FAILED,
  CLOSE_TRANSFER,
  CLOSE_TRANSFER_SUCCEED,
  CLOSE_TRANSFER_FAILED,
  AUTHORIZE_TRANSFER,
  AUTHORIZE_TRANSFER_SUCCEED,
  AUTHORIZE_TRANSFER_FAILED,
  CANCEL_TRANSFER,
  CANCEL_TRANSFER_SUCCEED,
  CANCEL_TRANSFER_FAILED,
  REJECT_TRANSFER,
  REJECT_TRANSFER_SUCCEED,
  REJECT_TRANSFER_FAILED,
  SET_TABLE_PARAMS,
  SET_TABLE_PAGE,
  EDIT_TRANSFER,
  EDIT_TRANSFER_SUCCEED,
  EDIT_TRANSFER_FAILED,
};
