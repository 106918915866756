import * as yup from 'yup';
import i18n from '@i18n';
import { hourValidation } from 'utils/validations/common';

const string = yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD'));

const schema = {
  socialReason: yup.string(),
  rfc: yup.string(),
  paymentForm: string,
  paymentMethod: string,
  amount: yup
    .number()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .min(1, i18n('ERROR__COMMONS__PRICE__INVALID', [1]))
    .max(999_999, i18n('ERROR__COMMONS__AMOUNT__INVALID', [999_999])),
  date: string,
  time: hourValidation.required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
};

export default schema;
