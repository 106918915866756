import React from 'react';

import { Button, Icons } from '@casanova/casanova-common';

import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import RoleVerifier from 'components/RoleVerifier';
import { handleAdditionalCancel } from 'utils/additionals';

import { useVehicle } from 'hooks';

const AdditionalModals = ({
  children,
  roleVerifierIdentifier,
  handleSubmit,
  formikProps,
  emptyValues,
  extraProps,
}) => {
  const {
    resultMessage,
    isCancelModalVisible,
    addNew,
    isNew,
    editable,
    name,
    onEdit,
    onCancelModal,
    onRequestClose,
    cancelNewAdditional,
    closeAdditionalResult,
  } = extraProps;

  const { isSold } = useVehicle();

  const cancelLabel = editable ? 'Cancelar' : 'Nueva';
  const handleConfirmCancelModal = () => {
    onEdit(false);
    formikProps.resetForm();
    onCancelModal(false);
    onRequestClose(name);
    if (addNew) {
      cancelNewAdditional({ name });
    }
  };

  const handleCloseCancelModal = () => {
    onCancelModal(false);
  };

  const handleCloseResultModal = () => {
    closeAdditionalResult({ name });
  };

  return (
    <div className="vehicleEditor">
      {resultMessage && (
        <ActionModal
          open={resultMessage.open}
          icon={
            resultMessage.success ? <Icons.IcoCarOk /> : <Icons.IcoCarError />
          }
          onClose={handleCloseResultModal}
          onAction={handleCloseResultModal}
          title={resultMessage.title}
          actionLabel="Ok"
          message={resultMessage.message}
          modalType="confirmation"
          code={resultMessage.errorCode}
          closeButton
        />
      )}
      <ActionModal
        open={isCancelModalVisible}
        icon={<Icons.StatusWarning />}
        onClose={handleCloseCancelModal}
        onAction={handleConfirmCancelModal}
        title="Cancelar Proceso"
        actionLabel="Sí"
        closeLabel="No"
        message={
          !addNew
            ? 'Al cancelar se perderán todos los datos previamente llenados. ¿Estás seguro que quieres continuar?'
            : 'Se perderán los datos previamente llenados, te recomentados guardar tus cambios antes de salir.\n ¿Estás seguro que quieres continuar?'
        }
        closeButton
        data={formikProps}
      />
      {children}
      {!isSold && (
        <RoleVerifier identifier={roleVerifierIdentifier}>
          <div className="row mt-4 mb-4">
            <div className="col-12 col-md-12 col-lg-4 d-flex footer-content">
              {(addNew || isNew) && (
                <p className="mandatory">
                  <span>
                    <Icons.Comment />
                  </span>{' '}
                  *Campos obligatorios.
                </p>
              )}
            </div>
            <div className="col-12 col-md-12 col-lg-8 d-flex bottom-actions">
              <Button
                outline={editable}
                onClick={() =>
                  handleAdditionalCancel(
                    formikProps,
                    editable,
                    isNew,
                    name,
                    emptyValues,
                    onEdit,
                    extraProps
                  )
                }
              >
                {cancelLabel}
              </Button>
              {(addNew || isNew) && (
                <Button onClick={handleSubmit} type="submit">
                  Guardar
                </Button>
              )}
            </div>
          </div>
        </RoleVerifier>
      )}
    </div>
  );
};

export default AdditionalModals;
