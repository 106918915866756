import { setDeliveryStepOne } from 'store/reservations/actions';

const schema = {};

export const config = ({ folio = '' }) => ({
  schema,
  textBold: [folio],
  messageBold: true,
  modalType: 'empty',
  initialTouched: {},
  initialValues: {},
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  showResponseDialog: false,
  withButtons: false,
  submitForm: setDeliveryStepOne,
  btnSubmitLabel: 'Entregar',
  dialogs: {
    confirmation: {
      title: 'VERIFICAR ENTREGA',
      message: `Debes verificar la entrega del vehículo con el ${folio}`,
      actionLabel: 'Continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: 'Procesando entrega…',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage: `No cierre, ni actualice el navegador.`,
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a Usuarios',
      errorLabel: 'Reintentar',
      cancelLabel: 'Cancelar',
      successPath: '/daily-rent-contracts/',
    },
  },
});
