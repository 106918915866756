import {
  sendCustomRequest,
  sendFormRequest,
} from '@casanova/casanova-common/lib/utils/service';
import { APIError } from '@casanova/casanova-common/lib/utils/exceptions';
import { customerRoot } from '../roots';
import { contractMovementsTransformer } from './transformers';

export async function fetchMovementTypes() {
  const url = `${customerRoot}/catalogs/movement-types`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response.data.data.content;
}

export async function fetchContractMovements(contractUuid) {
  const url = `${customerRoot}/billing-details/movement/contract/${contractUuid}`;
  const response = await sendCustomRequest(url, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return contractMovementsTransformer(response.data.data);
}

// async function getInvoice(uuid, branch, payment, amount) {
//   const url = `api/v1/invoice/setInvoice/${uuid}/${branch}/${payment}/${amount}`;
//   const response = await sendCustomRequest(url, {});
//   return response;
// }

export async function postMovement({
  customerUuid,
  methodPayment = [],
  invoice = {},
  ...request
}) {
  let isInvoice = Object(invoice).hasOwnProperty('billingDetails')
    ? invoice
    : null;
  if (request.movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2') {
    const { uuidsPayment } = await savePaymentAbono(methodPayment, isInvoice);
    request.uuidsPayment = uuidsPayment;
    try {
      await getInvoiceContract(request.contract);
    } catch (error) {
      console.error('Failed to get invoice, continuing without it:', error);
    }
  }
  const url = `${customerRoot}/billing-details/${customerUuid}/movement`;
  const response = await sendCustomRequest(url, {}, request, 'post');
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  // await getInvoice(request.contract, request.branch, request.paymentForm, request.amount);
  return response.data.data;
}

async function savePaymentAbono(request, isInvoice) {
  const url = `pricequotes/v1/payments/charge_payment`;
  let uuid;
  const uuidsPayment = [];
  if (Array.isArray(request)) {
    for await (const element of request) {
      const newReq = {
        ...element,
        invoice: isInvoice,
      };
      const response = await sendCustomRequest(url, {}, newReq, 'POST');

      if (!response.success) {
        const { errorCode, message } = response.data;
        throw new APIError(errorCode, message);
      }
      uuid = response.data.data;
      uuidsPayment.push(uuid);
      if (element.paymentForm !== 'CASH') {
        const files = [
          {
            file: element.files.paymentProof,
            name: getPaymentProofFileName(element.files.paymentProof, uuid),
          },
        ];

        if (
          element.paymentForm.includes('CARD') &&
          !element.cardDetails.cardHasCustomerName
        ) {
          files.push({
            file: element.files.accountStatus,
            name: getAccountFileName(element.files.accountStatus, uuid),
          });
        }

        await saveFiles(
          `pricequotes/v1/payments/charge_payment/${uuid}/files`,
          'files',
          files
        );
      }
    }
  } else {
    const response = await sendCustomRequest(
      url,
      {},
      { ...request, invoice: isInvoice },
      'POST'
    );

    if (!response.success) {
      const { errorCode, message } = response.data;
      throw new APIError(errorCode, message);
    }
    uuid = response.data.data;
    uuidsPayment.push(uuid);
    if (request.paymentForm !== 'CASH') {
      const files = [
        {
          file: request.files.paymentProof,
          name: getPaymentProofFileName(request.files.paymentProof, uuid),
        },
      ];

      if (
        request.paymentForm.includes('CARD') &&
        !request.cardDetails.cardHasCustomerName
      ) {
        files.push({
          file: request.files.accountStatus,
          name: getAccountFileName(request.files.accountStatus, uuid),
        });
      }

      await saveFiles(
        `pricequotes/v1/payments/charge_payment/${uuid}/files`,
        'files',
        files
      );
    }
  }
  return { uuidsPayment, uuid };
}

const getAccountFileName = (file, uuid) =>
  `${uuid}_ACC_STATEMENT.${getFileExtension(file.name)}`;

const getPaymentProofFileName = (file, uuid) =>
  `${uuid}_PAYMENT_PROOF.${getFileExtension(file.name)}`;

const getFileExtension = (name) => {
  const splitted = name.split('.');
  return splitted[splitted.length - 1].trim();
};

async function saveFiles(url, fieldName, files = []) {
  const form = new FormData();
  files.forEach((element) => {
    form.append(fieldName, element.file, element.name);
  });
  const response = await sendFormRequest(url, form, {});
  if (!response.success) {
    const { errorCode, message } = response.data;
    throw new APIError(errorCode, message);
  }
  return response;
}

async function getInvoiceContract(uuid) {
  const url = `api/v1/invoice/setInvoice/${uuid}`;
  const response = await sendCustomRequest(url, {});
  return response;
}
