import { useEffect, useState } from 'react';
import _get from 'lodash/get';
import { withForm } from '@casanova/casanova-common';
import {
  isJuridicalCustomer,
  isForeignCustomer,
  isTenderCustomer,
} from '@customers/common/utils';
import {
  editConfig as config,
  getSchemaCustomerEditor,
} from '@customers/common/config';

import CustomerEditor from './CustomerEditor';

export default function CustomerEditorParent({ resDialog, ...props }) {
  const [schema, setSchema] = useState({});
  const { tenderType } = props;
  const customersData = _get(props.customer, 'data', {});
  const isJuridical = isJuridicalCustomer(customersData);
  const isForeigner = isForeignCustomer(customersData);
  const isTender = isTenderCustomer(customersData);

  useEffect(() => {
    const newSchema = getSchemaCustomerEditor({
      isForeigner,
      isJuridical,
      isTender: { isTender, tenderType },
    });
    setSchema(newSchema);
  }, [isForeigner, isJuridical, isTender, tenderType]);

  const Component = withForm({ config: { ...config, schema } })(CustomerEditor);

  const componentProps = {
    ...props,
    schema,
    setSchema,
    isForeigner,
    isJuridicalCustomer: isJuridical,
    isTender,
  };

  return Component(componentProps);
}
