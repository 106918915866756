import { vehicleDetailTransform } from '@vehicles/common/transformers';
import { VEHICLE_DETAIL_FIELDS } from '@vehicles/common/constants';
import { getInitialValues, getInitialTouched } from 'utils/validations/fields';

export const config = {
  schema: {},
  initialTouched: getInitialTouched({ fields: VEHICLE_DETAIL_FIELDS }),
  initialValues: {
    ...getInitialValues({ fields: VEHICLE_DETAIL_FIELDS }),
    vehicleUuid: '',
  },
  transformer: vehicleDetailTransform,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: false,
  submitForm: () => {},
  dialogs: {
    confirmation: {},
    response: {},
  },
};
