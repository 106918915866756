import * as yup from 'yup';

import { saveCRP } from 'store/invoices/actions';
import CRPInfoConfig from '@invoices/sections/CRPInformation/config';
import CRPInfoConfigSchemaProps from '@invoices/sections/CRPInformation/schema';
import i18n from '@i18n';

import transformer from './transformer';
import formatter from './formatter';

export const config = {
  schema: yup.object().shape({
    invoiceUuid: yup.string(),
    ...CRPInfoConfigSchemaProps,
  }),
  initialTouched: {
    invoiceUuid: true,
    ...CRPInfoConfig.initialTouched,
  },
  initialValues: {
    invoiceUuid: '',
    ...CRPInfoConfig.initialValues,
  },
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
  redirectInSuccess: false,
  formatter,
  transformer,
  submitForm: saveCRP,
  btnSubmitLabel: i18n('COMMONS__SAVE'),
  dialogs: {
    confirmation: {
      title: 'SOLICITAR CRP',
      message:
        'Se emitirá un CRP de la factura ¿Estas seguro que quieres continuar?',
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: 'Se emitió el CRP de la factura de manera exitosa.',
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: 'Ir a facturas',
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: '',
    },
  },
};
