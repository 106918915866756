import * as Yup from 'yup';

export const circulationCardValidation = Yup.object().shape({
  plate: Yup.string().required('Este campo es obligatorio'),
  cardNumber: Yup.string().required('Este campo es obligatorio'),
  cardClass: Yup.string().required('Este campo es obligatorio'),
  type: Yup.string().required('Este campo es obligatorio'),
});

export const handleAdditionalCancel = (
  formikProps,
  editable,
  isNew,
  name,
  empty,
  onEdit,
  props
) => {
  if (editable) {
    if (formikProps.dirty) {
      props.onCancelModal(true);
    } else {
      onEdit(false);
      props.cancelNewAdditional({ name });
      if (isNew) {
        props.forceClose(name);
      }
    }
  } else {
    formikProps.resetForm(empty);
    props.addNewAdditional({ name });
  }
};
