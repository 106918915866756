import React from 'react';
import { MaskedInput } from '@casanova/casanova-common';

const SinisterForm = ({ isNew, addNew, values, name }) => (
  <div className="row">
    <div className="col-12 col-md-4">
      <MaskedInput
        name={`key.${name}.sinister.sinister`}
        label="Numero de siniestro*"
        placeholder="Numero de siniestro*"
        limit={10}
        disabled={!addNew && !isNew}
      />
    </div>
    <div className="col-12 col-md-4">
      <MaskedInput
        name={`key.${name}.sinister.sinisterResponsible`}
        label="Responsable*"
        placeholder="Responsable*"
        limit={10}
        disabled={!addNew && !isNew}
      />
    </div>
  </div>
);

export default SinisterForm;
