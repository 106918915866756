import { useState } from 'react';
// import { useFormikContext } from 'formik';
import { withForm } from '@casanova/casanova-common';

import { SearchReportForm, ListOfReports } from '@reports/sections';

import config from './config';

// TODO: Waiting for Carlos pagination to be implemented

function SearchReports() {
  const [hasResetList, setHasResetList] = useState(false);
  // const { isValid, isSubmitting } = useFormikContext();

  // const { params } = reportsList;

  // useEffect(() => {
  // if (isValid && isSubmitting) {
  // getReportsList({ params });
  // }
  // }, [isValid, isSubmitting, getReportsList, params]);

  return (
    <section>
      <SearchReportForm
        setHasResetList={setHasResetList}
        hasResetList={hasResetList}
      />
      <ListOfReports
        setHasResetList={setHasResetList}
        hasResetList={hasResetList}
      />
    </section>
  );
}

export default withForm({ config })(SearchReports);
