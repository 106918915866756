/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionModal } from '@casanova/casanova-common/lib/Modal';
import { Icons } from '@casanova/casanova-common';
import { backActive } from 'store/app/actions';
import usePreventChange from 'hooks/usePreventChange';
import i18n from '@i18n';

export const PreventChange = ({
  preventChange,
  icon = <Icons.StatusWarning />,
  children,
  fullReload = false,
  onlyWhenCanceling = false,
  title = i18n('PREVENT_CHANGE__TITLE'),
  actionLabel = i18n('PREVENT_CHANGE__ACTION'),
  message = i18n('PREVENT_CHANGE__MESSAGE'),
  closeLabel = i18n('PREVENT_CHANGE__CLOSE'),
  stockInCurrentPath = false,
  onChange = _noop,
  onConfirmation,
  onCancel,
  closeButton = true,
}) => {
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [nextLocation, setNextLocation] = useState();
  const [currentLocation, setCurrentLocation] = useState();
  const unblockHandle = useRef();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentPreventChange, setPreventChange] = usePreventChange();

  useEffect(() => {
    if (preventChange) {
      unblockHandle.current = history.block((targetLocation) => {
        let shouldBlock = true;
        let isCanceling = false;

        const hasParams = targetLocation.search !== '';
        if (hasParams) {
          isCanceling = targetLocation.search.includes('isCanceling');
        }

        if (onlyWhenCanceling && !isCanceling) {
          shouldBlock = false;
        }

        if (shouldBlock) {
          setNextLocation(targetLocation.pathname);
          setCurrentLocation(location.pathname);
          setConfirmationMessage(true);
          return false;
        }

        return true;
      });

      return function () {
        if (unblockHandle.current.current) {
          unblockHandle.current.current();
        }
      };
    }

    if (unblockHandle && unblockHandle.current) {
      unblockHandle.current();
    }
  }, [history, preventChange, onlyWhenCanceling, location]);

  const handleOnCancel = () => {
    dispatch(backActive());
    setPreventChange({ cancel: true, success: false, active: false });
    setConfirmationMessage(false);
    if (onCancel) onCancel();
  };

  const handleOnConfirmation = () => {
    const newLocation = stockInCurrentPath ? location.pathname : nextLocation;

    if (unblockHandle) {
      unblockHandle.current();
    }

    if (fullReload) {
      window.location.href = newLocation;
    } else {
      setConfirmationMessage(false);
      history.push(newLocation);
    }

    setPreventChange({ cancel: false, success: true });

    if (onConfirmation) {
      onConfirmation({ stockInCurrentPath, location: newLocation });
    }
  };

  useEffect(() => {
    onChange(confirmationMessage);
    setPreventChange({ active: confirmationMessage });
  }, [confirmationMessage]);

  useEffect(
    () => () => {
      if (
        currentPreventChange.success ||
        currentPreventChange.active ||
        currentPreventChange.cancel
      ) {
        setPreventChange('reset');
      }
    },
    [setPreventChange, currentPreventChange]
  );

  return (
    <>
      <ActionModal
        open={confirmationMessage}
        icon={icon}
        onClose={handleOnCancel}
        onAction={handleOnConfirmation}
        title={title}
        actionLabel={actionLabel}
        closeLabel={closeLabel}
        message={message}
        closeButton={closeButton}
      />
      {children}
    </>
  );
};

PreventChange.propTypes = {
  preventChange: PropTypes.bool.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string,
  actionLabel: PropTypes.string,
  message: PropTypes.string,
  closeLabel: PropTypes.string,
  stockInCurrentPath: PropTypes.bool,
  onChange: PropTypes.func,
  onConfirmation: PropTypes.func,
  onCancel: PropTypes.func,
  closeButton: PropTypes.bool,
};
