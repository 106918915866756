import * as yup from 'yup';
import { removeSpaces } from '@casanova/casanova-common/lib/utils/masks';
import i18n from '@i18n';
import { REQUIRED_MESSAGE } from 'utils/constants';

export const validations = {
  minPrice: 1,
  maxPrice: 5000000,
  minRemark: 3,
  maxRemark: 50,
};

const { maxPrice, minPrice, minRemark, maxRemark } = validations;

export const makeSchema = ({
  extraChargeRemarkName = '',
  extraChargeAmountName = '',
  customExtraCharges = [],
}) => ({
  [extraChargeRemarkName]: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(minRemark, i18n('ERROR__COMMONS__DATE__INVALID', [minRemark]))
    .max(maxRemark, i18n('ERROR__COMMONS__DATE__INVALID', [maxRemark]))
    .test(
      'already-exists-extra-charges',
      i18n('ERROR__RESERVATIONS__EXTRA_CHARGE__REMARK__ALREADY_EXISTS'),
      (value) => {
        if (!value) return true;

        const alreadyExistsExtraCharge = customExtraCharges.find(
          ({ name = '' } = {}) =>
            removeSpaces(name.toLowerCase()) ===
            removeSpaces(value.toLowerCase())
        );

        if (alreadyExistsExtraCharge && alreadyExistsExtraCharge.name) {
          return value !== alreadyExistsExtraCharge.name;
        }

        return true;
      }
    ),
  [extraChargeAmountName]: yup
    .number()
    .required(REQUIRED_MESSAGE)
    .min(minPrice, i18n('ERROR__COMMONS__PRICE__INVALID', [minPrice]))
    .max(maxPrice, i18n('ERROR__COMMONS__AMOUNT__INVALID', [maxPrice])),
});
