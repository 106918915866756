import React, { Fragment } from 'react';
import { Avatar } from 'primereact/avatar';
import { Icons } from '@casanova/casanova-common';
import classNames from 'classnames';
import { DisableIcons } from '@reservations/commons/constantsComponents';
import { SPECT_ITEM_TYPES } from '@reservations/commons/constants';
import './SpecItem.scss';
import icono_aire from './iconos/AIRE.png';
import icono_gasolina from './iconos/gasolina.png';
import icono_maletas from './iconos/MALETAS.png';
import icono_medidas from './iconos/medidas.png';
import icono_pasajeros from './iconos/pasajeros.png';
import icono_peso from './iconos/peso.png';
import icono_transmision from './iconos/Transmision.png';
import icono_asiento from './iconos/asiento.png';
import icono_puertas from './iconos/puertas.png';
import icono_motor from './iconos/motor.png';

const SpecItem = ({
  feat,
  specList = [],
  spectListType = SPECT_ITEM_TYPES.DIVISION,
  check = true,
  subItems = [],
  className = '',
  textContainerClassName = '',
  textClassName = '',
  iconStyle = {
    width: '1.5rem',
    height: '1.5rem',
  },
  disableIconType = 1,
  onClick,
  reverse = false,
}) => {
  const containerClassName = classNames(
    'SpecItem feature-item d-flex align-items-center extra-row',
    reverse && 'SpecItem__reverse',
    className
  );
  const checkClassName = classNames(reverse ? 'ml-3' : 'mr-3');

  const iconos = [
    {imagen: icono_aire}, 
    {imagen: icono_gasolina},
    {imagen: icono_maletas},
    {imagen: icono_medidas},
    {imagen: icono_pasajeros},
    {imagen: icono_peso},
    {imagen: icono_transmision},
    {imagen: icono_asiento},
    {imagen: icono_puertas},
    {imagen: icono_motor},
  ];

  const currentUrl = window.location.href;

  function formatSpanList(spanList) {
    const copeteIndex = spanList.indexOf('Copete:');
    if (copeteIndex === -1) {
      return [spanList];
    }
  
    const cajaPart = spanList.substring(0, copeteIndex).trim();
    const copetePart = '\n' + spanList.substring(copeteIndex).trim();
  
    return [cajaPart, copetePart];
  }
  
  let formattedSpanList;

  if(currentUrl.includes('quoter')){
    if(feat.includes('medidas de la caja')){
      formattedSpanList = formatSpanList(specList.join(' / '));
    }
  }
  
  const renderSpectItem = (
    <>
      {spectListType === SPECT_ITEM_TYPES.DIVISION ? (
        <div className={`text ${textContainerClassName}`} style={{ display: 'flex', alignItems: 'center' }}>
          {(currentUrl.includes('quoter')) ? (
            <>
              {(specList.includes('Gasolina') || specList.includes('Diésel') || specList.includes('Gas natural') || specList.includes('Eléctrico') || specList.includes('MILD HYB') || specList.includes('Híbrido')) && (
                <img src={iconos[1].imagen} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              )}
              {(specList.includes('de capacidad') || feat.includes('de capacidad') || feat.includes('capacidad')) && (
                <img src={iconos[2].imagen} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              )}
              {(feat.includes('medidas de la caja')) && (
                <img src={iconos[3].imagen} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              )}
              {(specList.includes('pasajeros') || feat.includes('pasajeros')) && (
                <img src={iconos[4].imagen} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              )}
              {(feat.includes('kg.')) && (
                <img src={iconos[5].imagen} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              )}
              {(specList.includes('Manual') || specList.includes('Automático') || specList.includes('Automática')) && (
                <img src={iconos[6].imagen} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              )}
              {(specList.includes('Tela') || specList.includes('Piel') || specList.includes('Cuero Sintético') || specList.includes('Tactopiel') || specList.includes('Tela con tactopiel')) && (
                <img src={iconos[7].imagen} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              )}
              {(specList.includes('puertas') || feat.includes('puertas')) && (
                <img src={iconos[8].imagen} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              )}
              {(specList.includes('cilindros') || feat.includes('cilindros')) && (
                <img src={iconos[9].imagen} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
              )}
              {(feat.includes('medidas de la caja')) && (
                <div>
                  {formattedSpanList.map((part, index) => (
                    <span className={`text d-block ${textClassName}`} key={index}>{part}</span> 
                  ))}
                </div>
              )}
              {(feat.includes('de capacidad') || feat.includes('capacidad')) && (
                <span className={`text d-block ${textClassName}`}>
                  {specList.join(' / ')}
                </span>
              )}
              {(!feat.includes('de capacidad') && !feat.includes('capacidad') && !feat.includes('medidas de la caja')) && (
                <span className={`text d-block ${textClassName}`}>
                  {specList.join(' / ')} {feat}
                </span>
              )}
            </>
          ): <span className={`text d-block ${textClassName}`}>
          {specList.join(' / ')} {feat}
        </span>}
          
        </div>
      ) : (
        specList.map((textValue) => {
          const isString = typeof textValue === 'string';
          const { label = isString ? textValue : '', value = '' } = isString ? {} : textValue;
          return (
            <Fragment key={`${label}_${value}`}>
              <span className={`text d-inline ${textClassName}`}>{label}:</span>
              <span className={`d-inline font-weight-normal ${textClassName}`}>
                {`  ${value}`}
              </span>
            </Fragment>
          );
        })
      )}
    </>
  );

  const DisableIcon = DisableIcons[disableIconType - 1];

  if (currentUrl.includes('quoter')) {
    return specList.length > 0 ? (
      <div className={containerClassName} onClick={onClick}>
        <span className={checkClassName}></span>
        <div className={`text ${textContainerClassName}`}>
          {renderSpectItem}
        </div>
      </div>
    ) : null;
  } else {
    return specList.length > 0 ? (
      <div className={containerClassName} onClick={onClick}>
        <span className={checkClassName}>
          {check ? (
            <Icons.CheckAvailable
              width={iconStyle.width}
              height={iconStyle.height}
            />
          ) : (
            <DisableIcon width={iconStyle.width} height={iconStyle.height} />
          )}
        </span>
        <div className={`text ${textContainerClassName}`}>
          {renderSpectItem}
          {subItems.length > 0 &&
            subItems.map((subItem, i) => (
              <p
                // eslint-disable-next-line react/no-array-index-key
                key={`${subItem}_${i}`}
                className="m-0 SpecItem__sub-item text-dark"
              >{`${subItem}`}</p>
            ))}
        </div>
      </div>
    ) : null;
  }
  
};

export default SpecItem;
