import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import store from 'store';
import {
  customerInfoFormatter,
  foreignCustomerInfoFormatter,
} from '@customers/sections/CustomerInformation/formatter';
import { officialIdentificationFormatter } from '@customers/sections/OfficialIdentification/formatter';
import { passportFormatter } from '@customers/sections/PassportInformation/formatter';
import { visaFormatter } from '@customers/sections/VisaInformation/formatter';
import { formatDriverLicense } from '@customers/sections/DriverLicense/formatter';
import { formatAuthorizedDrivers } from '@customers/sections/DriversInformation/formatter';
import { formatAddress } from '@customers/sections/Address/formatter';
import { accommodationFormatter } from '@customers/sections/AccommodationInformation/formatter';
import {
  formatBillingDetails,
  formatBillingDetailsForeigner,
} from '@customers/sections/InvoiceData/formatter';
import { juridicalPersonDataFormatter } from '@customers/sections/CompanyData/formatter';
import { countryEntranceFormatter } from '@customers/sections/CountryEntranceType/formatter';
import { FIELDS as JURIDICAL_CUSTOMER_COMPANY_FIELDS } from '@customers/sections/CompanyData/config';
import { FIELDS as PASSPORT_FIELDS } from '@customers/sections/PassportInformation/config';
import { FIELDS as VISA_FIELDS } from '@customers/sections/VisaInformation/config';
import { FIELDS as COUNTRY_ENTRANCE_FIELDS } from '@customers/sections/CountryEntranceType/config';
import {
  ADDRESS_FIELDS,
  BILLING_FIELDS,
  DRIVER_LICENSE_FIELDS,
  IDENTITY_DOCUMENT_FIELDS,
  JURIDICAL_FIELDS,
  CUSTOMER_INFO_FIELDS,
  CUSTOMER_STATUS,
  BAN_FIELDS,
} from 'utils/constants';
import {
  ENTRANCE_TYPES,
  CUSTOMER_TYPES,
  ACCOMMODATION_TYPES,
  TYPES_OF_DOCUMENTS,
} from '@customers/common/constants';
import { getOnlyFilesOfFormatterFiles } from 'utils/files';
import { existsChanges } from 'utils/validations/form';

const { BANNED, NORMAL, UNBANED, BANNED_EDITED } = CUSTOMER_STATUS;

const formatJuridicalPerson = (values) => ({
  businessName: values.companySocialReason,
  name: values.companyName,
  rfc: values.companyRfc,
});

export const getLegalCustomerFiles = (values) => ({
  companyData: [
    {
      fieldName: 'files',
      file: values.companyConstituentAct,
      suffix: 'IAC',
    },
    {
      fieldName: 'files',
      file: values.companyCompyRec,
      suffix: 'RFCJ',
    },
    {
      fieldName: 'files',
      file: values.companyCouponFiscal,
      suffix: 'TAXIDJ',
    },
    {
      fieldName: 'files',
      file: values.companyOpinionCompliance,
      suffix: 'CMO',
    },
    {
      fieldName: 'files',
      file: values.companyRequestVehicles,
      suffix: 'VRJ',
    },
    {
      fieldName: 'files',
      file: values.companyPowerAttornery,
      suffix: 'APC',
    },
    {
      fieldName: 'files',
      file: values.companyLetterAuthorization,
      suffix: 'AULJ',
    },
  ],
  identification: [
    {
      fieldName: 'front',
      file: values.identificationFrontSide,
      suffix: 'IDF',
    },
    {
      fieldName: 'back',
      file: values.identificationBackSide,
      suffix: 'IDB',
    },
  ],
  drivers: formatAuthorizedDrivers(values)[1],
  billing: formatBillingDetails(values)[1],
});

export const getTenderCustomerFiles = (values) => ({
  companyData: [
    {
      fieldName: 'files',
      file: values.companyCompyRec,
      suffix: 'RFCJ',
    },
    {
      fieldName: 'files',
      file: values.companyCouponFiscal,
      suffix: 'TAXIDJ',
    },
  ],
  addressProof: [
    {
      fieldName: 'proof',
      file: values.addressDocument,
      suffix: 'ADR',
    },
  ],
  billing: formatBillingDetails(values)[1],
});

const formatJuridicalPersonLegalCustomer = (values) => ({
  address: formatAddress(values),
  authorizedDriverReqs: formatAuthorizedDrivers(values)[0],
  createBillingDetailReqs: formatBillingDetailsForeigner(values),
  ...juridicalPersonDataFormatter(values),
});

const formatTenderCustomer = (values) => ({
  address: formatAddress(values),
  createBillingDetailReqs: formatBillingDetailsForeigner(values),
  ...juridicalPersonDataFormatter(values),
  customerSector: values.customerSector,
});

const formatLegalCustomerInfo = (values) => ({
  cellphone: values.phoneNumber,
  curp: values.curp,
  email: values.email,
  landline:
    !_isEmpty(values.homeNumber) && values.homeNumber !== ''
      ? values.homeNumber
      : null,
  lastName: values.lastName,
  names: values.names,
  secondLastName: !_isEmpty(values.secondLastName)
    ? values.secondLastName
    : null,
});

const getFiles = (values) => ({
  identification: [
    {
      fieldName: 'front',
      file: values.identificationFrontSide,
      suffix: 'IDF',
    },
    {
      fieldName: 'back',
      file: values.identificationBackSide,
      suffix: 'IDB',
    },
  ],
  addressProof: [
    {
      fieldName: 'proof',
      file: values.addressDocument,
      suffix: 'ADR',
    },
  ],
  license: [
    { fieldName: 'front', file: values.licenseFrontSide, suffix: 'DLF' },
    { fieldName: 'back', file: values.licenseBackSide, suffix: 'DLB' },
  ],
  drivers: formatAuthorizedDrivers(values)[1],
  billing: formatBillingDetails(values)[1],
});

export const CustomerFormatter = (values) => {
  const { personType } = values;
  const data=  {
    createAddressReq: formatAddress(values),
    createBillingDetailReqs: formatBillingDetails(values)[0],
    createDriverLicenseReq: formatDriverLicense(values),
    createIdentityDocumentReq: officialIdentificationFormatter(values),
    createJuridicalPersonReq:
      personType === CUSTOMER_TYPES.JURIDICAL
        ? formatJuridicalPerson(values)
        : null,
    authorizedDriverReqs: formatAuthorizedDrivers(values)[0],
    customerInfo: customerInfoFormatter(values),
    files: getFiles(values),
  };
  if (!values.licenseType || !values.antiquity) {
    delete data.createDriverLicenseReq;
    delete data.files.license;
  }
  return data;
};

export const juridicalCustomerFormatter = (values) => ({
  createIdentityDocumentReq: officialIdentificationFormatter(values),
  createJuridicalPersonReq: formatJuridicalPersonLegalCustomer(values),
  customerInfo: values.curp
    ? formatLegalCustomerInfo(values)
    : customerInfoFormatter(values),
  files: getLegalCustomerFiles(values),
});

export const tenderCustomerFormatter = (values) => ({
  createTenderReq: formatTenderCustomer(values),
  customerInfo: values.curp
    ? formatLegalCustomerInfo(values)
    : customerInfoFormatter(values),
  files: getTenderCustomerFiles(values),
});

const getFilesForeigner = (values) => {
  const { hospedySelected, selectedEntrance } = values;

  return {
    passport: [
      {
        fieldName: 'front',
        file: values.passportCoverFront,
        suffix: 'IDF',
      },
      {
        fieldName: 'back',
        file: values.passportCoverBack,
        suffix: 'IDB',
      },
    ],
    visa: [
      {
        fieldName: 'front',
        file: values.visaCoverFront,
        suffix: 'IDF',
      },
      {
        fieldName: 'back',
        file: values.visaCoverBack,
        suffix: 'IDB',
      },
    ],
    countryEntrance:
      selectedEntrance && selectedEntrance.identifier === ENTRANCE_TYPES.AERIAL
        ? [
            {
              fieldName: 'itinerary',
              file: values.entranceItinerary,
              suffix: 'ITY',
            },
            {
              fieldName: 'fmme',
              file: values.entranceFFME,
              suffix: 'FMM',
            },
          ]
        : [{ fieldName: 'fmme', file: values.entranceFMM, suffix: 'FMM' }],
    accommodation:
      hospedySelected && hospedySelected.identifier === ACCOMMODATION_TYPES.RENT
        ? [
            {
              fieldName: 'files',
              file: values.hospedyContract,
              suffix: 'LCN',
            },
            {
              fieldName: 'files',
              file: values.hospedyIdentificationLessor,
              suffix: 'LID',
            },
            {
              fieldName: 'files',
              file: values.hospedyRelationLabor,
              suffix: 'ELT',
            },
          ]
        : undefined,
    license: [
      { fieldName: 'front', file: values.licenseFrontSide, suffix: 'DLF' },
      { fieldName: 'back', file: values.licenseBackSide, suffix: 'DLB' },
    ],
    billing: formatBillingDetails(values)[1],
  };
};

export const foreignerCustomerFormatter = (values) => {
  const data = {
    createBillingDetailReqs: formatBillingDetailsForeigner(values),
    createDriverLicenseReq: formatDriverLicense(values, true),
    createAccommodationReq: accommodationFormatter(values),
    createPassportReq: passportFormatter(values),
    createVisaReq: visaFormatter(values),
    createCountryEntranceReq: countryEntranceFormatter(values),
    foreignCustomerInfo: foreignCustomerInfoFormatter(values),
    files: getFilesForeigner(values),
  };

  if (!values.visaValidity || !values.visaYearIssue) {
    delete data.createVisaReq;
    delete data.files.visa;
  }

  return data;
};

const isBillingChanged = (values, initialValues) => {
  if (values.invoiceData.length !== initialValues.invoiceData.length)
    return true;
  let isChanged = false;
  values.invoiceData.forEach((billing, index) => {
    const result = existsChanges(
      billing,
      initialValues.invoiceData[index],
      BILLING_FIELDS
    );
    if (result.withChanges) isChanged = true;
  });
  return isChanged;
};

const getBanFields = (values) => {
  const { reason, contractNumber, initialStatus, finalStatus } = values;
  return {
    banReason: reason,
    bannedContract: contractNumber,
    initialStatus,
    finalStatus,
  };
};

const isAddressChanged = (values, initialValues) => {
  const result = existsChanges(values, initialValues, ADDRESS_FIELDS);
  return result.withChanges;
};

const isDriverLicenseChanged = (values, initialValues) => {
  const result = existsChanges(values, initialValues, DRIVER_LICENSE_FIELDS);
  return result.withChanges;
};

const isIdentityDocumentChanged = (values, initialValues) => {
  const result = existsChanges(values, initialValues, IDENTITY_DOCUMENT_FIELDS);
  return result.withChanges;
};

const isPassportChanged = (values, initialValues) => {
  const result = existsChanges(values, initialValues, PASSPORT_FIELDS);
  return result.withChanges;
};

const isVisaChanged = (values, initialValues) => {
  const result = existsChanges(values, initialValues, VISA_FIELDS);
  return result.withChanges;
};

const isJuridicalPersonChanged = (values, initialValues, fields) => {
  const result = existsChanges(values, initialValues, fields);
  return result.withChanges;
};

const isCustomerInfoChanged = (values, initialValues) => {
  const result = existsChanges(values, initialValues, CUSTOMER_INFO_FIELDS);
  return result.withChanges;
};

const isBannedInfoChanged = (values, initialValues) => {
  const result = existsChanges(values, initialValues, BAN_FIELDS);
  return result.withChanges;
};

const isCountryEntranceChanged = (values, initialValues) => {
  const result = existsChanges(values, initialValues, COUNTRY_ENTRANCE_FIELDS);
  return result.withChanges;
};

const getFinalStatus = (initialStatus, banFields, values, initialValues) => {
  let finalStatus = initialStatus;

  if (initialStatus === NORMAL) {
    if (banFields === null) finalStatus = NORMAL;
    if (banFields !== null) finalStatus = BANNED;
  }

  if (initialStatus === BANNED) {
    if (banFields === null) finalStatus = UNBANED;
    if (banFields !== null && isBannedInfoChanged(values, initialValues))
      finalStatus = BANNED_EDITED;
  }

  return finalStatus;
};

const formatIdentityDocuments = (values, isForeign) => {
  console.log('aqui');

  const IDENTITY_DOCUMENTS_FORMATTER = {
    [TYPES_OF_DOCUMENTS.INE]: officialIdentificationFormatter,
    [TYPES_OF_DOCUMENTS.PROFESSIONAL_LICENSE]: officialIdentificationFormatter,
    [TYPES_OF_DOCUMENTS.INAPAM]: officialIdentificationFormatter,
    [TYPES_OF_DOCUMENTS.PASSPORT]: isForeign
      ? passportFormatter
      : officialIdentificationFormatter,
    [TYPES_OF_DOCUMENTS.VISA]: isForeign
      ? visaFormatter
      : officialIdentificationFormatter,
  };

  const state = store.getState();
  const identityDocuments = _get(
    state,
    'customers.customerDetail.data.identityDocuments',
    []
  );

  const formattedIdentityDocuments = identityDocuments.map(
    ({ identityDocumentType, uuid }) => {
      const { identifier } = identityDocumentType;
      const identityDocumentFormatter =
        IDENTITY_DOCUMENTS_FORMATTER[identifier];
      if (identityDocumentFormatter) {
        return identityDocumentFormatter({
          ...values,
          ...identityDocumentType,
          uuid,
        });
      }
      return {};
    }
  );

  return formattedIdentityDocuments;
};

export const CustomerEditFormatter = (values, initialValues) => {
  console.log('aqui2');
  const { personType, customerType, ban, initialStatus } = values;
  const isJuridical = personType === CUSTOMER_TYPES.JURIDICAL;
  const isForeign =
    personType === CUSTOMER_TYPES.FOREGINER ||
    customerType === CUSTOMER_TYPES.FOREGINER;
  const isTender = personType === CUSTOMER_TYPES.TENDER;
  const banFields = ban ? getBanFields(values) : null;
  console.log('aqui2, ', isJuridical);

  const getEditJuridicalPersonReq = () => {
    if (!isForeign) {
      return isJuridical
        ? juridicalPersonDataFormatter(values)
        : isTender
        ? {
            ...juridicalPersonDataFormatter(values),
            customerSector: values.customerSector,
          }
        : null;
    }
    console.log(juridicalPersonDataFormatter, ' juridical');
    // return
    return null;
  };

  const formatterFiles = {
    ...getFiles(values),
    ...getLegalCustomerFiles(values),
    ...getFilesForeigner(values),
  };

  const formattedValues = {
    personType: values.personType,
    editAddressReq: formatAddress(values),
    editBillingDetailReqs: formatBillingDetails(values)[0],
    editDriverLicenseReq:
      !isJuridical && !isTender ? formatDriverLicense(values) : null,
    editIdentityDocumentReqs: formatIdentityDocuments(values, isForeign),
    editAuthorizedDriverReqs: formatAuthorizedDrivers(values)[0],
    createVisaReq: visaFormatter(values),
    editJuridicalPersonReq: getEditJuridicalPersonReq(),
    editNaturalPersonInfoReq: isForeign
      ? foreignCustomerInfoFormatter(values)
      : customerInfoFormatter(values),
    editCountryEntrance: countryEntranceFormatter(values),
    finalStatus: getFinalStatus(
      values.initialStatus,
      banFields,
      values,
      initialValues
    ),
    banFields,
    initialStatus,
    files: {
      ...getOnlyFilesOfFormatterFiles(formatterFiles),
      drivers: formatAuthorizedDrivers(values)[1],
      billing: formatBillingDetails(values)[1],
    },
  };

  if (!isForeign) {
    // console.log('aqui entra en los dos');
    const isJuridicalValuesChanged = isJuridicalPersonChanged(
      values,
      initialValues,
      isJuridical ? JURIDICAL_CUSTOMER_COMPANY_FIELDS : JURIDICAL_FIELDS
    );

    // console.log({
    //   values,
    //   initialValues,
    //   isJuridical,
    //   JURIDICAL_CUSTOMER_COMPANY_FIELDS,
    //   JURIDICAL_FIELDS,
    // });

    if (!isJuridicalValuesChanged) {
      delete formattedValues.editJuridicalPersonReq;
    }

    delete formattedValues.editCountryEntrance;
  }

  if (!isCustomerInfoChanged(values, initialValues))
    delete formattedValues.editNaturalPersonInfoReq;

  if (!isBillingChanged(values, initialValues))
    delete formattedValues.editBillingDetailReqs;

  if (!isAddressChanged(values, initialValues)) {
    delete formattedValues.editAddressReq;
    delete formattedValues.files.addressProof;
  }

  if (!isDriverLicenseChanged(values, initialValues)) {
    delete formattedValues.editDriverLicenseReq;
    delete formattedValues.files.license;
  }

  // Identity documents
  if (isForeign) {
    console.log('is foreing');
    console.log(formattedValues.files, ', formated');
    if (!isPassportChanged(values, initialValues)) {
      formattedValues.editIdentityDocumentReqs = formattedValues.editIdentityDocumentReqs.filter(
        ({ identifier }) => identifier !== TYPES_OF_DOCUMENTS.PASSPORT
      );
      delete formattedValues.files.passport;
    }
    if (!isVisaChanged(values, initialValues)) {
      formattedValues.editIdentityDocumentReqs = formattedValues.editIdentityDocumentReqs.filter(
        ({ identifier }) => identifier !== TYPES_OF_DOCUMENTS.VISA
      );
      delete formattedValues.files.visa;
    }
  } else if (!isIdentityDocumentChanged(values, initialValues)) {
    console.log('not is foreing');
    console.log(formattedValues.files, ', formated');
    delete formattedValues.editIdentityDocumentReqs;
    delete formattedValues.files.identification;
  }

  if (isForeign) {
    if (!isCountryEntranceChanged(values, initialValues)) {
      delete formattedValues.editCountryEntrance;
      delete formattedValues.files.countryEntrance;
    }
  }

  return formattedValues;
};

export default CustomerFormatter;

export const getFormatter = ({ isForeigner, isJuridical, isTender }) => {
  if (isForeigner) return foreignerCustomerFormatter;
  if (isTender) return tenderCustomerFormatter;
  return isJuridical ? juridicalCustomerFormatter : CustomerFormatter;
};
