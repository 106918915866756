import {
  VALID_DOCUMENTS_STATUS,
  NOT_VALID_DOCUMENTS_STATUS,
} from './constants/documentsStatus';

export const commonCustomerCatalogs = (fetchCatalog) => {
  fetchCatalog({
    catalogId: 'catalogs/cfdi-fiscal-regime/natural-person',
    catalogPath: 'pricequotes',
  });
  fetchCatalog({
    catalogId: 'catalogs/cfdi-fiscal-regime/juridical-person',
    catalogPath: 'pricequotes',
  });
  fetchCatalog({
    catalogId: 'catalogs/cfdi-fiscal-regime/natural-person',
    catalogPath: 'pricequotes',
  });
  fetchCatalog({
    catalogId: 'catalogs/cfdi-fiscal-regime/juridical-person',
    catalogPath: 'pricequotes',
  });
  fetchCatalog('states');
  fetchCatalog({
    catalogId: 'sexes/search/byActive',
    catalogPath: 'catalog',
    catalogName: 'sexes',
  });
  fetchCatalog('municipalities');
  fetchCatalog('nationalities');
  fetchCatalog('countries');
  fetchCatalog({
    catalogId: 'identityDocumentTypeEntities',
    catalogPath: 'customer',
  });
  fetchCatalog({
    catalogId: 'driverLicenseTypeEntities',
    catalogPath: 'customer',
  });
  fetchCatalog({
    catalogId: 'documentStatusEntities',
    catalogPath: 'customer',
  });
  fetchCatalog({
    catalogId: 'proofAddressTypeEntities',
    catalogPath: 'customer',
  });
  fetchCatalog({
    catalogId: 'accommodationTypeEntities',
    catalogPath: 'customer',
  });
  fetchCatalog({
    catalogId: 'countryEntranceTypeEntities',
    catalogPath: 'customer',
  });
  fetchCatalog({
    catalogId: 'catalogs/customer-type-sector',
    catalogPath: 'customer',
    catalogName: 'customerSectorCatalog',
  });

  // If your add a new catalog, add the key in the array
  return [
    'states',
    'sexes',
    'municipalities',
    'nationalities',
    'identityDocumentTypeEntities',
    'driverLicenseTypeEntities',
    'documentStatusEntities',
    'proofAddressTypeEntities',
    'cfdiUseEntities',
    'countries',
    'accommodationTypeEntities',
    'countryEntranceTypeEntities',
    'abonos',
  ];
};

export const getTextColor = (text) => {
  switch (text.toLowerCase()) {
    case 'verificada':
      return 'success';
    case 'rechazada':
      return 'danger';
    case 'vencida':
      return 'warning';
    case 'necesita verificación':
      return 'danger';
    case 'sin verificar':
    default:
      return 'default';
  }
};

export const synEventObject = (value) => ({ target: { value } });

export const notValidDocument = ({ documentType = '' }) => {
  const isValidDocument = VALID_DOCUMENTS_STATUS[documentType];
  const isNotValidDocument = NOT_VALID_DOCUMENTS_STATUS[documentType];

  if (isValidDocument) return false;
  return isNotValidDocument;
};

export const getInvalidDocumentKey = (section = '') => `${section}Validations`;
