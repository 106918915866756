import React from 'react';
import { Button } from '@casanova/casanova-common';
import Modal from '@casanova/casanova-common/lib/Modal';

function ActionModal(props) {
  const {
    open,
    message,
    id,
    title,
    icon,
    onClose,
    onAction,
    actionLabel,
    modalType,
    closeLabel,
    closeButton,
    code,
    data,
    classModalTitle,
  } = props;
  const header = (
    <div className="modal-header justify-content-center align-items-center">
      <div className="icon">{icon}</div>
      <h5 className={`modal-title ${classModalTitle}`} id={`${id}-label`}>
        {title}
      </h5>
    </div>
  );
  const footer = (
    <div className="modal-footer container">
      {(modalType === 'action' || !modalType) && (
        <div className="row">
          <div className="col-md-6">
            <Button block outline onClick={() => onClose(data)}>
              {closeLabel}
            </Button>
          </div>
          <div className="col-md-6">
            <Button block onClick={() => onAction(data)}>
              {actionLabel}
            </Button>
          </div>
        </div>
      )}

      {modalType === 'confirmation' && (
        <div className="row">
          <div className="offset-3 col-6">
            <Button block onClick={() => onAction(data)}>
              {actionLabel}
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      open={open}
      closeButton={closeButton}
      onClose={() => onClose(data)}
      header={header}
      footer={footer}
      className="action-modal"
      data={data}
    >
      <div className="modal-body">
        <p className="text-center">{message}</p>
        <p className="text-center modal-code">{code}</p>
      </div>
    </Modal>
  );
}

ActionModal.defaultProps = {
  closeLabel: 'Cancelar',
};

export default ActionModal;
