import { transformForSelect } from 'utils/transformHelpers';
import * as _camelCase from 'lodash/camelCase';
import _get from 'lodash/get';
import {
  CATALOG_FETCH_FAILED,
  CATALOG_FETCH_REQUESTED,
  CATALOG_FETCH_SUCCEEDED,
  GET_SUBURBS_BY_ZIPCODE,
  GET_SUBURBS_BY_ZIPCODE_FAILED,
  GET_SUBURBS_BY_ZIPCODE_SUCCEEDED,
  GET_TAX_ZIPCODE,
  GET_TAX_ZIPCODE_FAILED,
  GET_TAX_ZIPCODE_SUCCEEDED,
  SHOW_LOADER,
  HIDE_LOADER,
  RESET,
  SET_PREVENT_CHANGE,
} from './action-types';

const initialState = {
  loader: false,
  states: {
    error: false,
    errorMessage: '',
    list: [],
    listwo: [],
  },
  zipCodeSelected: { error: false },
  taxZipCodeSelected: { error: false },
  preventChange: {
    active: false,
    cancel: false,
    success: false,
  },
};

export function commonReducer(state = initialState, action) {
  let catalogId = _get(action, 'payload.catalogId', action.payload);
  switch (action.type) {
    case CATALOG_FETCH_REQUESTED: {
      const catalog = _get(state, [_camelCase(catalogId)], {});
      const { list = [], error = false } = catalog;

      return {
        ...state,
        [_camelCase(catalogId)]:
          list.length === 0 || error ? { error: false, list: [] } : catalog,
      };
    }
    case CATALOG_FETCH_SUCCEEDED:
      const { data, dataCustom } = action.payload;
      catalogId = _get(
        action,
        'payload.identifier.catalogId',
        action.payload.identifier
      );

      return {
        ...state,
        [_camelCase(catalogId)]: {
          error: false,
          list: transformForSelect(data, 'uuid', 'name'),
          listwo: dataCustom,
        },
      };
    case CATALOG_FETCH_FAILED:
      catalogId = _get(
        action,
        'payload.identifier.catalogId',
        action.payload.identifier
      );
      return {
        ...state,
        [_camelCase(catalogId)]: {
          error: true,
          errorMessage: action.payload.error.message,
        },
      };
    case GET_SUBURBS_BY_ZIPCODE:
      return {
        ...state,
        loader: true,
        zipCodeSelected: { error: false },
      };
    case GET_SUBURBS_BY_ZIPCODE_SUCCEEDED:
      return {
        ...state,
        loader: false,
        zipCodeSelected: { error: false, ...action.payload.data },
      };
    case GET_SUBURBS_BY_ZIPCODE_FAILED:
      return {
        ...state,
        loader: false,
        zipCodeSelected: { error: true, ...action.payload },
      };
    case GET_TAX_ZIPCODE:
      return {
        ...state,
        loader: true,
        taxZipCodeSelected: { error: false },
      };
    case GET_TAX_ZIPCODE_SUCCEEDED:
      return {
        ...state,
        loader: false,
        taxZipCodeSelected: { error: false, ...action.payload.data },
      };
    case GET_TAX_ZIPCODE_FAILED:
      return {
        ...state,
        loader: false,
        taxZipCodeSelected: { error: true, ...action.payload },
      };
    case HIDE_LOADER:
      return {
        ...state,
        loader: false,
      };
    case SHOW_LOADER:
      return {
        ...state,
        loader: true,
      };
    case SET_PREVENT_CHANGE:
      return {
        ...state,
        preventChange: {
          ...state.preventChange,
          ...action.payload,
        },
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
