import InvoicesModuleIcon from '@casanova/casanova-common/lib/Icons/InvoicesModule';
import i18n from '@i18n';
import { INVOICES_MODULE, VIEW_INVOICES } from 'utils/roles/permissions';
import { withReconcileNav } from 'containers/Breadcrumb';
import { withRoleRestrictions } from 'utils/auth';
import { validateRolePermissions } from 'utils/roles';
import { ListOfInvoices, InvoiceDetail } from '@invoices';

const MODULE_KEY = 'invoices';

export const invoices = {
  moduleName: INVOICES_MODULE,
  route: '/invoices',
  label: i18n('INVOICES__MENU_LABEL'),
  icon: InvoicesModuleIcon,
  main: 'all',
  show: validateRolePermissions(VIEW_INVOICES),
  children: {
    all: {
      route: '',
      label: '',
      component: withReconcileNav(
        MODULE_KEY,
        'all'
      )(withRoleRestrictions(ListOfInvoices)),
    },
    detail: {
      route: `/:id/detail`,
      label: i18n('COMMONS_DETAIL'),
      format: (id) => `/invoices/${id}/detail`,
      onlyRoute: true,
      component: withReconcileNav(
        MODULE_KEY,
        'detail'
      )(withRoleRestrictions(InvoiceDetail)),
    },
  },
};
