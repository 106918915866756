import moment from 'moment-timezone';
import 'moment/locale/es';

// import { YYYY_MM_DD, ISO_DATE_FORMAT, FULL_DATE_FORMAT } from 'utils/constants';

export const transformer = (values, contractQuote = false) => {
  const { priceQuote = {}, naturalPerson = {}, uuid = '' } = values;
  const {
    pickupBranch,
    selectedVehicleLinePrice,
    // pickupDateTime,
    returnBranch,
    // returnDateTime,
  } = priceQuote;
  const { email, uuid: customer, fullName: customerName } = naturalPerson;
  // console.log({ values, contractQuote });
  // const startHour = moment(pickupDateTime, ISO_DATE_FORMAT).format(
  //   FULL_DATE_FORMAT
  // );
  // const dateStart = moment(pickupDateTime, ISO_DATE_FORMAT).format(YYYY_MM_DD);

  // const endHour = moment(returnDateTime, ISO_DATE_FORMAT).format(
  //   FULL_DATE_FORMAT
  // );
  // const dateEnd = moment(returnDateTime, ISO_DATE_FORMAT).format(YYYY_MM_DD);

  return {
    // dateStart,
    // startHour,
    branchStart: pickupBranch.uuid,
    // dateEnd,
    // endHour,
    branchEnd: returnBranch.uuid,
    availableTime: values.availableTime,
    email,
    customer,
    customerName,
    reservation: uuid,
    // vehicleLinePrice: selectedVehicleLinePrice.vehicleLinePrice.uuid,
    selectedCategory: selectedVehicleLinePrice.vehicle.vehicleCategoryUuid,
    types: selectedVehicleLinePrice.vehicle.vehicleTypeUuid,
    vehicleLineUuid: selectedVehicleLinePrice.vehicle.vehicleLineUuid,
    // ...values,
  };
};
