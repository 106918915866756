import React from 'react';
import PropTypes from 'prop-types';
import i18n from '@i18n';
import { SchedDate } from '@reservations/components';
import { number2mxn } from 'utils/currency';
import { Section, Card } from 'components';

export default function PlanAndDates({
  title = i18n('RESERVATIONS__DETAIL__PLAN_SECTION__TITLE'),
  name,
  pickupDateTime,
  returnDateTime,
  selectedStartBranch,
  selectedEndBranch,
  kmDay = 0,
}) {
  return (
    <Section title={title}>
      <Card noStatus noTitle>
        <div className="row">
          <div className="col-4 d-flex common-info-text flex-column align-content-center justify-content-center">
            <span className=" font-weight-bold">PLAN {name} MXN</span>
            <span className="common-muted-text text-lg">
              {number2mxn(kmDay)} x día
            </span>
          </div>
          <div className="col-4">
            <SchedDate
              className="mb-0"
              date={pickupDateTime}
              branch={selectedStartBranch.name}
              title="Fecha de salida"
            />
          </div>
          <div className="col-4">
            <SchedDate
              className="mb-0"
              date={returnDateTime}
              branch={selectedEndBranch.name}
              title="Fecha de devolución"
              end
            />
          </div>
        </div>
      </Card>
    </Section>
  );
}

PlanAndDates.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  pickupDateTime: PropTypes.string,
  returnDateTime: PropTypes.string,
  selectedStartBranch: PropTypes.object,
  selectedEndBranch: PropTypes.object,
  kmDay: PropTypes.number,
};
