import { formatter as contractInfoFormatter } from '@contracts/sections/ContractInformation/formatter';
import { formatter as vehiclesFormatter } from '@contracts/sections/Vehicles/formatter';

export const contractFormatter = (values) => {
  const contractInfoFormattedData = contractInfoFormatter(values);
  const formattedData = vehiclesFormatter({
    temporaryChanges: values.vehicles,
  });
  return {
    ...contractInfoFormattedData,
    ...formattedData,
  };
};
