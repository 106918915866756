import { transformIdOfList } from 'utils/transformHelpers';
import types from './action-types';

const initialState = {
  responseDialog: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
  },
  responseDialogValidCode: {
    open: false,
    success: false,
    errorCode: '',
    errorMessage: '',
  },
  contractSearch: {
    data: {},
    success: false,
    error: false,
    errorMessage: '',
  },
  movementTypes: {
    list: [],
  },
  contractDetails: {
    success: false,
    error: false,
    errorMessage: '',
    hasNotFound: false,
    data: {},
  },
  contractMovements: {
    params: {
      page: 0,
      size: 10,
    },
    totalPages: 1,
    totalElements: 10,
    results: [],
  },
  savePayment: {
    success: false,
    error: false,
    errorMessage: '',
    data: {},
  },
};

export function paymentsReducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_CONTRACT_BY_SEARCH:
      return {
        ...state,
        contractSearch: initialState.contractSearch,
        responseDialog: initialState.responseDialog,
      };
    case types.GET_CONTRACT_BY_SEARCH_SUCCEED:
      return {
        ...state,
        contractSearch: {
          data: payload,
          success: true,
          error: false,
          errorMessage: '',
        },
      };
    case types.GET_CONTRACT_BY_SEARCH_FAILED:
      return {
        ...state,
        contractSearch: {
          data: {},
          success: false,
          error: true,
          errorMessage: payload,
        },
      };
    case types.CLOSE_RESPONSE_DIALOG:
      return {
        ...state,
        responseDialog: initialState.responseDialog,
      };
    case types.CLOSE_RESPONSE_DIALOG_VALID_CODE:
      return {
        ...state,
        responseDialogValidCode: initialState.responseDialogValidCode,
      };
    case types.GET_CONTRACT_DETAILS:
    case types.CLEAN_CONTRACT_DETAILS:
      return {
        ...state,
        contractDetails: initialState.contractDetails,
        responseDialog: initialState.responseDialog,
      };
    case types.GET_CONTRACT_DETAILS_SUCCEED:
      return {
        ...state,
        contractDetails: {
          success: true,
          error: false,
          errorMessage: '',
          hasNotFound: false,
          data: payload,
        },
      };
    case types.GET_CONTRACT_DETAILS_FAILED:
      return {
        ...state,
        contractDetails: {
          success: false,
          error: true,
          errorMessage: payload.error,
          hasNotFound: payload.hasNotFound,
          data: {},
        },
      };
    case types.SET_MOVEMENT_LIST_PARAMS:
      return {
        ...state,
        contractMovements: {
          ...state.contractMovements,
          params: {
            ...state.contractMovements.params,
            ...payload,
          },
        },
      };
    case types.SET_MOVEMENT_LIST_PAGE:
      return {
        ...state,
        contractMovements: {
          ...state.contractMovements,
          params: {
            ...state.contractMovements.params,
            page: payload,
          },
        },
      };
    case types.SET_MOVEMENT_TYPES:
      return {
        ...state,
        movementTypes: {
          list: payload,
        },
      };
    case types.GET_CONTRACT_MOVEMENT_LIST_FAILED:
    case types.GET_CONTRACT_MOVEMENT_LIST:
      return {
        ...state,
        contractMovements: {
          ...state.contractMovements,
          results: [],
        },
      };
    case types.GET_CONTRACT_MOVEMENT_LIST_SUCCEED:
      return {
        ...state,
        contractMovements: {
          ...state.contractMovements,
          results: transformIdOfList(payload.content),
          totalPages: payload.totalPages,
          totalElements: payload.totalElements,
        },
      };
    case types.SAVE_PAYMENT:
      return {
        ...state,
        savePayment: initialState.savePayment,
        responseDialog: initialState.responseDialog,
      };
    case types.SAVE_PAYMENT_SUCCEED:
      return {
        ...state,
        savePayment: {
          success: true,
          error: false,
          errorMessage: '',
          data: payload,
        },
        responseDialog: {
          open: true,
          success: true,
          errorCode: '',
          errorMessage: '',
        },
      };
    case types.SAVE_PAYMENT_FAILED:
      return {
        ...state,
        savePayment: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.VALID_CODE_FAILED:
      return {
        ...state,
        savePayment: {
          success: false,
          error: true,
          errorMessage: payload,
          data: {},
        },
        responseDialogValidCode: {
          open: true,
          success: false,
          errorCode: payload.code,
          errorMessage: payload.message,
        },
      };
    case types.SET_CANCEL_MOVEMENT:
      return {
        ...state,
        responseDialog: initialState.responseDialog,
      };
    case types.SET_CANCEL_MOVEMENT_FAILED:
      return {
        ...state,
        responseDialog: {
          open: true,
          success: false,
          errorCode: payload.code,
          message: payload.message,
        },
      };
    case types.RESET:
      return initialState;
    default:
      return state;
  }
}
