import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withLayout } from '@casanova/casanova-common';
import {
  closeResponseDialog,
  getTransferDetail,
  authorizeTransfer,
  rejectTransfer,
} from 'store/transfers/actions';
import { Blank } from 'components';
import TransferDetail from './TransferDetail';

export default withLayout(
  compose(
    connect(
      (state) => ({
        loader: _get(state, 'commons.loader', false),
        responseDialog: _get(state, 'transfers.responseDialog', {}),
        transferDetail: _get(state, 'transfers.detail', {}),
        left: <Blank />,
      }),
      {
        closeResponseDialog,
        getTransferDetail,
        authorizeTransfer,
        rejectTransfer,
      }
    )
  )(TransferDetail)
);
