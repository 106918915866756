import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withLayout } from 'hooks';
import { fetchCatalog } from 'store/commons/actions';
import { getDetail } from 'store/customers/actions';
import {
  setNewInvoicePayment,
  setHasAddedBillingDetailsOfPayment,
  closeResponseDialog,
} from 'store/reservations/actions';
import { ExtraChargeSummary } from '@reservations/sections';
import PaymentExtraCharges from './PaymentExtraCharges';

let priceExtraCharge = 0;

function obtenerPrecio(totalPrice) {
  priceExtraCharge = totalPrice;
}

export default withLayout(
  compose(
    connect(
      (state) => ({
        reservationInfo: state.reservations.reservationInfo.data,
        customerJuridical: _get(
          state,
          'customers.customerDetail.data.juridicalPerson'
        ),
        refund: state.reservations.refund,
        left: <></>,
        confirmationMessage: false,
        quoteValues: _get(state, 'reservations.quote.values', {}),
        cancelClass: 'col-1 col-xl-6',
        continueClass: 'col',
        priceExtraCharge,
        customerRefund: _get(
          state,
          'customers.customerDetail.data.customerMovementRefund',
          {}
        ),
      }),
      {
        fetchCatalog,
        getCustomer: getDetail,
        setNewInvoicePayment,
        setHasAddedBillingDetailsOfPayment,
        closeResponseDialog,
      }
    )
  )(PaymentExtraCharges),
  undefined,
  <ExtraChargeSummary obtenerPrecio={obtenerPrecio} />,
  'col-12 col-lg-8 col-xl-9'
);
