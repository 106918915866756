import { AdditionalHeaderCamp } from 'components';
import { CellText } from 'components/ColumnsCells';

export default [
  {
    label: 'Titular',
    field: 'name',
    align: 'center',
    renderer: (props) => <CellText {...props} />,
  },
  {
    label: 'Número de tarjeta',
    field: 'number',
    align: 'center',
    renderer: (props) => <CellText {...props} />,
  },
  {
    label: 'Tipo de tarjeta',
    field: 'type',
    align: 'center',
    renderer: (props) => <CellText {...props} />,
  },
];
