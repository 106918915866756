export function toCustomersPageRequest(filters, searchPattern) {
  return {
    text: searchPattern,
  };
}

export const transformCustomersListFilters = (data) => {
  // const { statusesCustomer, statusesDocs, types } = data;
  return {
    ...data,
    // statusesCustomer: statusesCustomer.map((status) => ({
    //   ...status,
    //   value: status.uuid,
    //   label: status.name,
    // })),
    // types: types.map((type) => ({
    //   ...type,
    //   value: type.uuid,
    //   label: type.name,
    // })),
    // statusesDocs: statusesDocs.map((status) => ({
    //   ...status,
    //   value: status.uuid,
    //   label: status.name,
    // })),
  };
};
