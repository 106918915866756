/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import _noop from 'lodash/noop';

import { Icons, Switch } from '@casanova/casanova-common';
import { PreventChange } from 'components';

import {
  CustomerInformationBase,
  //   OfficialIdentification,
  //   Address,
  //   DriverLicense,
  //   DriversInformation,
  //   InvoiceData,
  //   CompanyData,
  //   PassportInformation,
  //   VisaInformation,
  //   AccommodationInformation,
  //   CountryEntranceType,
} from '@customers/sections';
import { FieldsBan } from '@customers/sections/Ban/Ban';
import { CustomerStepper } from '@customers/components';
import { getFieldsWithNationalitiesCatalog } from '@customers/common/utils';

import { FORM_ACTIONS } from 'utils/constants/formActions';
import { isContractQuote } from 'utils/navigation';

import './CustomerFormBase.scss';

const { ADD /* EDIT */ } = FORM_ACTIONS;

export default function CustomerForms({
  values,
  action = ADD,
  preventChange = false,
  isReservation = false,
  nextPath,
  handleNoBlock = _noop,
  isForeigner = false,
  isJuridicalCustomer = false,
  isTender = false,
  history,
  match,
  customerStepperProps = {},
  onlyFisic,
  onModalOpen = _noop,
  onModalClose = _noop,
  stockInCurrentPath = false,
  setShowResponseDialog = _noop,
  avalCustomer = false,
  setContractQuote = _noop,
  ...rest
}) {
  const { setFieldValue } = useFormikContext();

  const hasContract = isContractQuote();

  const handleChange = useCallback(
    (currentPreventChange) => {
      if (currentPreventChange) onModalOpen();
      else onModalClose();
    },
    [onModalOpen, onModalClose]
  );

  const principalProps = {
    history,
    match,
    action,
    isJuridicalCustomer,
    isForeigner,
    isTender,
    onlyFisic,
    onModalOpen,
    onModalClose,
    setShowResponseDialog,
    avalCustomer,
    ...rest,
  };

  const customerInfoProps = useMemo(
    () => ({
      nextPath,
      preventBlock: handleNoBlock,
      isReservation,
      avalCustomer,
    }),
    [handleNoBlock, nextPath, isReservation, avalCustomer]
  );

  const nationalCatalogFields = useMemo(
    () => getFieldsWithNationalitiesCatalog(values),
    [values]
  );

  const handleConfirmation = useCallback(
    ({ location }) => {
      const toCustomerDetails = location.includes('/detail');

      if (!avalCustomer && !toCustomerDetails) {
        // Reset contract quote
        setContractQuote(false);
      }
    },
    [setContractQuote, hasContract]
  );

  useEffect(() => {
    const fieldsWithValue = nationalCatalogFields.find(([, value]) =>
      Boolean(value)
    );

    if (fieldsWithValue) {
      const [keyOfFieldWithValue, valueOfFieldWithValue] = fieldsWithValue;

      nationalCatalogFields.forEach(([key, value]) => {
        if (key !== keyOfFieldWithValue && key !== 'visaCountry' && !value) {
          setFieldValue(key, valueOfFieldWithValue);
        }
      });
    }
  }, [nationalCatalogFields, setFieldValue]);

  return (
    <section className="CustomerForms">
      <PreventChange
        preventChange={preventChange}
        icon={<Icons.IcoClientesWarning />}
        onChange={handleChange}
        stockInCurrentPath={stockInCurrentPath}
        onConfirmation={handleConfirmation}
      >
        {isReservation && !avalCustomer && (
          <CustomerStepper {...principalProps} {...customerStepperProps} />
        )}
        <CustomerInformationBase {...principalProps} {...customerInfoProps} />
        {/* {!isReservation && action === EDIT && (
          <>
            <div className="row">
              <div className="col-12">
                <div className="BanSwitch switch-inverted">
                  <label htmlFor="ban" className="mr-1">
                    ¿Quieres boletinar al cliente?
                  </label>
                  <Switch id="ban" name="ban" />
                </div>
              </div>
            </div>
            <br />
            {values.ban && <FieldsBan action="edit" />}
          </>
        )}
        {!isForeigner && !isTender && (
          <OfficialIdentification {...principalProps} />
        )}
        {(isJuridicalCustomer || isTender) && !isForeigner && (
          <CompanyData {...principalProps} />
        )}
        {!isForeigner && <Address {...principalProps} />}
        {isForeigner && (
          <>
            <PassportInformation {...principalProps} />
            <VisaInformation {...principalProps} />
            <CountryEntranceType {...principalProps} />
            <AccommodationInformation {...principalProps} />
          </>
        )}
        {(!isJuridicalCustomer || isForeigner) && !isTender && (
          <DriverLicense {...principalProps} />
        )}
        {!avalCustomer && <InvoiceData {...principalProps} />}
        {isJuridicalCustomer && !isForeigner && !isTender && (
          <DriversInformation {...principalProps} />
        )} */}
      </PreventChange>
    </section>
  );
}

CustomerForms.propTypes = {
  values: PropTypes.object,
  action: PropTypes.string,
  preventChange: PropTypes.bool,
  isReservation: PropTypes.bool,
  nextPath: PropTypes.string,
  handleNoBlock: PropTypes.func,
  isForeigner: PropTypes.bool,
  isJuridicalCustomer: PropTypes.bool,
  isTender: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  customerStepperProps: PropTypes.object,
  onlyFisic: PropTypes.bool,
  onModalOpen: PropTypes.func,
  onModalClose: PropTypes.func,
  stockInCurrentPath: PropTypes.bool,
  setShowResponseDialog: PropTypes.func,
  avalCustomer: PropTypes.bool,
  setContractQuote: PropTypes.func,
};
