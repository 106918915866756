import { connect } from 'react-redux';
import {
  cleanContractDetails,
  getContractDetails,
} from 'store/contracts/actions';
import i18n from '@i18n';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import ContractDetails from './ContractDetails';

function mapStateToProps(state) {
  return {
    action: FORM_ACTIONS.VIEW,
    // This is for handle error of fetching
    responseDialog: {
      open: Boolean(state.contracts.contractDetails.error),
      success: false,
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
    },
  };
}

function mapDispatchToProps(dispatch, { match, history }) {
  return {
    onClose: () => {
      history.push('/contracts/');
      return dispatch(cleanContractDetails());
    },
    onError: () => dispatch(getContractDetails({ uuid: match.params.id })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetails);
