import _get from 'lodash/get';
import moment from 'moment-timezone';
import 'moment/locale/es';
import { YYYY_MM_DD, timeZone, CUSTOMER_STATUS } from 'utils/constants';
import { isBanned } from '@customers/common/utils';
import { invoiceDataTransformer } from '@customers/sections/InvoiceData/transformer';
import { HAS_BIRTH_IN_FOREIGN } from '@customers/common/constants';

const { BANNED, NORMAL } = CUSTOMER_STATUS;

export const customerInformationTransformer = ({
  billingDetails,
  userInfo,
  curp,
  bannedContract,
  banReason,
  customerStatus,
  personType,
  tender,
}) => {
  const bannedCustomer = isBanned(customerStatus);
  const hasBirthInForeign =
    _get(userInfo, 'birthState.name', '') === HAS_BIRTH_IN_FOREIGN;

  return {
    personType,
    initialStatus: bannedCustomer ? BANNED : NORMAL,
    finalStatus: bannedCustomer ? BANNED : NORMAL,
    names: _get(userInfo, 'names', ''),
    lastName: _get(userInfo, 'lastName', ''),
    secondLastName: _get(userInfo, 'secondLastName', ''),
    birthDate: userInfo
      ? moment(userInfo.birthDate).tz(timeZone).format(YYYY_MM_DD)
      : '',
    birthPlace: hasBirthInForeign
      ? _get(userInfo, 'originCountry.name', '')
      : _get(userInfo, 'birthState.name', ''),
    originCountry: _get(userInfo, 'originCountry.uuid', ''),
    gender: _get(userInfo, 'sex.uuid'),
    curp,
    phoneNumber: _get(userInfo, 'cellphone', ''),
    homeNumber: _get(userInfo, 'landline', ''),
    email: _get(userInfo, 'email', ''),
    customerSector: _get(tender, 'customerTypeSector.uuid', ''),
    ban: bannedCustomer,
    contractNumber: bannedCustomer ? bannedContract : '',
    reason: bannedCustomer ? banReason : '',
    invoiceData: invoiceDataTransformer(billingDetails),
  };
};
