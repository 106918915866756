import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import {
  applyBranchDiscount,
  cancelBranchDiscount,
  saveDiscount,
} from 'store/reservations/actions';
import ApplyDiscount from './ApplyDiscount';

export default compose(
  connect(
    (state) => ({
      discountForm: _get(state, 'reservations.quote.values.discountForm', {}),
      responseDialog: _get(state, 'reservations.responseDialog', {}),
    }),
    {
      applyBranchDiscount,
      cancelBranchDiscount,
      saveDiscount,
    }
  )
)(ApplyDiscount);
