export const initialValues = {
  contractUuid: '',
  amount: '',
  userUuid: '',
  branchUuid: '',
};

export const initialTouched = {
  contractUuid: true,
  amount: true,
  userUuid: true,
  branchUuid: true,
};
