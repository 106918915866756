import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { config as addConfig } from './config';
import { Loader, Modal, withForm } from '@casanova/casanova-common';
import { RefundDetail } from '../sections';
import ResponseDialog from '../../../components/ResponseDialog';
import Blank from '../../../components/Blank';
import _isEmpty from 'lodash/isEmpty';
import i18n from '@i18n';

const ViewRefundDetail = (props) => {
  const {
    show = true,
    setShow,
    loader = false,
    contract = {},
    getReservationInfo,
    closeResponseDialog,
    responseDialog,
    reservationDetails = [],
    refundDetails,
    getVehicleLinePrices,
    contractsList,
    getDailyRentContractsList,
  } = props;

  const [hide, setHide] = useState(false);
  const { success: succesRefundDetails = false } = refundDetails;

  const { params, searchPattern, filters, results } = contractsList;

  const handleCancel = useCallback(() => {
    setShow(false);
  });

  const handleCloseRD = useCallback(() => {
    closeResponseDialog();
    getDailyRentContractsList({ params, filters, searchPattern });
  });

  const handleBeforeSubmit = useCallback(() => {
    setHide(true);
  }, []);

  const handleSuccess = useCallback(() => {
    setHide(false);
    setShow(false);
  }, []);

  const handleClose = useCallback(() => {
    setHide(false);
  });

  const handleCloseModal = useCallback(() => {
    setShow(false);
  }, [setShow]);

  useEffect(() => {
    if (contract && show) {
      getReservationInfo({ uuid: contract.reservationUuid, redirect: false });
    }
  }, [getReservationInfo, contract, show]);

  useEffect(() => {
    if (!_isEmpty(reservationDetails.data)) {
      const payload = {
        vehicleLineUuid:
          reservationDetails.data.priceQuote.selectedVehicleLinePrice.vehicle
            .vehicleLineUuid,
      };
      getVehicleLinePrices(payload);
    }
  }, [getVehicleLinePrices, reservationDetails]);

  const config = useMemo(() => {
    const finalConfig = addConfig;
    return {
      ...finalConfig,
      onBeforeSubmit: handleBeforeSubmit,
      onSuccess: handleSuccess,
      onClose: handleClose,
    };
  }, [handleBeforeSubmit, handleSuccess, handleClose]);

  useEffect(() => {
    if (succesRefundDetails) {
      setHide(false);
      setShow(false);
    }
  }, [succesRefundDetails]);

  const RefundDetailWithForm = withForm({ config })(RefundDetail)({
    ...props,
    reservationUuid: contract,
    layout: false,
    left: <Blank />,
    onCancel: handleCancel,
    disabled: true,
    closeResponseDialog: () => {
      if (responseDialog.open) {
        handleSuccess();
      }
      closeResponseDialog();
    },
  });

  return (
    <>
      <Loader show={hide && !loader && show} />
      <ResponseDialog
        open={responseDialog.open}
        success={responseDialog.success}
        successTitle="Reembolso realizado exitosamente"
        successMessage="Podrás encontrar el detalle del reembolso en el estado de cuenta del cliente"
        successLabel={i18n('COMMONS__CLOSE__TEXT')}
        onSuccess={handleCloseRD}
        errorMessage={responseDialog.message}
        // onError={() => handleOnSendDiscount()}
        onClose={handleCloseRD}
      />
      <Modal
        open={!loader && show}
        header={null}
        footer={null}
        className="ViewRefundDetail"
        topContainerClassName={`${hide ? 'd-none' : ''}`}
        contentClassName="px-4 py-4 container"
        closeButton
        onClose={handleCloseModal}
        bigModal
      >
        {RefundDetailWithForm}
      </Modal>
    </>
  );
};

export default ViewRefundDetail;
