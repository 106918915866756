import React from 'react';
import {
  DatePickerV2 as DatePicker,
  TextAreaInput,
} from '@casanova/casanova-common';

import { MINIMUM_DATE, MAXIMUM_DATE } from '@vehicles/common/constants';

const LostForm = ({ isNew, addNew, values, name }) => (
  <div className="row">
    <div className="col-12 col-md-4">
      <DatePicker
        name={`key.${name}.lost.lostDate`}
        initialValue={values.key && values.key[name].lost.lostDate}
        label="Fecha de extravio*"
        placeholder="Fecha de extravio*"
        disabled={!isNew && !addNew}
        minimumDate={MINIMUM_DATE}
        maximumDate={MAXIMUM_DATE}
      />
    </div>
    <div className="col-12">
      <TextAreaInput
        label="Descripción del extravío*"
        placeholder="Descripción del extravío*"
        name={`key.${name}.lost.lostDescription`}
        disabled={!addNew && !isNew}
        regex={/[()[\]|&;$%@#"{}¿!¡?^/\\|<>+~=`':*¨]/g}
        maxLength={200}
      />
    </div>
  </div>
);

export default LostForm;
