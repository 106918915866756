import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from 'hooks';
import { Blank } from 'components';
import {
  mapStateToPropsOfDeliveryAndReturn,
  dispatchToPropsOfDeliveryAndReturn,
} from '@reservations/commons/deliveryAndRefundConfig';
import { DELIVERY_RETURN_TYPES } from '@reservations/commons/constants';
import ExtraCharges from './ExtraCharges';

export default withLayout(
  compose(
    connect(
      (state) => ({
        ...mapStateToPropsOfDeliveryAndReturn(
          state,
          DELIVERY_RETURN_TYPES.RETURN
        ),
        left: <Blank />,
        confirmationMessage: false,
      }),
      dispatchToPropsOfDeliveryAndReturn
    )
  )(ExtraCharges)
);
