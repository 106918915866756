import React from 'react';
import { useSelector } from 'react-redux';
import Breadcrumb from 'containers/Breadcrumb/Breadcrumb';
import { Layout } from '@casanova/casanova-common';

const withLayout = (Component, aside, centerClass = '') => {
  function WrappedChildren(props) {
    const loader = useSelector((state) => state.commons.loader);
    return (
      <>
        <Layout.Title
          label={
            <Breadcrumb
              activeRoute={{
                general: { label: 'COTIZAR' },
                specific: { label: 'VEHÍCULO' },
              }}
            />
          }
        />
        <Layout.Body
          showLoader={loader}
          aside={aside}
          centerClass={centerClass}
        >
          <Component {...props} />
        </Layout.Body>
      </>
    );
  }

  return WrappedChildren;
};

export default withLayout;
