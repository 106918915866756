import React from 'react';
import { ProgressIndicator } from '@casanova/casanova-common';
import i18n from '@i18n';

const getDefaultTitle = () => {
  const { href } = window.location;
  const inEditionCustomer = href.includes('customer') && href.includes('edit');

  return i18n(
    inEditionCustomer
      ? 'CUSTOMERS__STEPPER__EDITER__TITLE'
      : 'CUSTOMERS__STEPPER__REGISTER__TITLE'
  );
};

const CustomerStepper = ({ title, subtitle }) => {
  const step = 1;
  const maxSteps = 2;
  const sTitle = !title ? getDefaultTitle() : title;
  const sSubtitle = !subtitle
    ? i18n('CUSTOMERS__STEPPER__REGISTER__SUBTITLE')
    : subtitle;
  return (
    <div className="row mb-4">
      <div className="col-12 p-0 d-flex align-items-center">
        <ProgressIndicator
          size={120}
          stroke={['#e2e2e2', '#007aff']}
          strokeWidth={6}
          progress={(step / maxSteps) * 100}
          label={`${step} de ${maxSteps}`}
        />
        <div className="ml-4">
          <h3 className="text-primary">{sTitle}</h3>
          <p>{sSubtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomerStepper;
