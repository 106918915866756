import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from 'hooks';
import {
  getReservationInfo,
  getExtendedContracts,
} from 'store/reservations/actions';
import { closeResponseDialog } from 'store/dailyRentContracts/actions';
import ExtendContract from './ExtendContract';

export const ExtendDetails = withLayout(
  compose(
    connect(
      (state) => ({
        step: 1,
        loader: state.commons.loader,
        reservationDetails: state.reservations.reservationInfo,
        extendContract: state.reservations.contract.extendContract,
      }),
      {
        getReservationInfo,
        getExtendedContracts,
      }
    )
  )(ExtendContract)
);

export const ExtendSign = withLayout(
  compose(
    connect(
      (state) => ({
        step: 2,
        responseDialog: state.dailyRentContracts.responseDialog,
        loader: state.commons.loader,
        reservationDetails: state.reservations.reservationInfo,
        extendDetails: state.dailyRentContracts,
      }),
      { closeResponseDialog, getReservationInfo }
    )
  )(ExtendContract)
);

export const ExtendPayment = withLayout(
  compose(
    connect(
      (state) => ({
        step: 3,
        responseDialog: state.dailyRentContracts.responseDialog,
        loader: state.commons.loader,
        reservationDetails: state.reservations.reservationInfo,
        extendDetails: state.dailyRentContracts,
      }),
      { closeResponseDialog, getReservationInfo }
    )
  )(ExtendContract)
);
