import * as yup from 'yup';
import 'moment/locale/es';
import {
  REQUIRED_MESSAGE,
  FORMAT_TYPES_ALLOWED_MESSAGE,
  ALLOWED_FORMATS,
} from 'utils/constants';
import { fileTypeValidation } from 'utils/validations/common';
import i18n from '@i18n';

const depositFormSchema = {
  bankAuthorization: yup.string().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'deposit') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(6, i18n('ERROR__COMMONS__MIN_LENGTH', [6])),
    otherwise: yup.string(),
  }),
  bankVoucher: yup.mixed().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'deposit') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup
      .mixed()
      .required(REQUIRED_MESSAGE)
      .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
        fileTypeValidation(file, ALLOWED_FORMATS)
      ),
    otherwise: yup.string(),
  }),
  amountDeposit: yup.mixed().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'deposit') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup.mixed().when('diffMethods', {
      is: (diffMethods) => diffMethods === true,
      then: yup
        .mixed()
        .required(REQUIRED_MESSAGE)
        .test(
          'invalidPrice',
          'La suma de los montos no es igual al total del abono',
          function (value) {
            if (!value) {
              return true;
            }
            const validPrice = this.parent.priceIsInvalid;
            return validPrice === true;
          }
        ),
      otherwise: yup.mixed(),
    }),
    otherwise: yup.mixed(),
  }),
};

export default depositFormSchema;
