import {
  SET_PARAMS_LIST_OF_CUSTOMERS,
  GET_CUSTOMERS_LIST_FILTERS,
  GET_CUSTOMERS_LIST_FILTERS_SUCCEED,
  GET_CUSTOMERS_LIST_FILTERS_FAILED,
  SAVE_REQUESTED,
  SAVE_SUCCEEDED,
  SAVE_FAILED,
  UPDATE_CUSTOMER_STATUS_REQUESTED,
  UPDATE_CUSTOMER_STATUS_SUCCEEDED,
  UPDATE_CUSTOMER_STATUS_FAILED,
  EDIT_REQUESTED,
  EDIT_SUCCEEDED,
  EDIT_FAILED,
  CLOSE_RESPONSE_DIALOG,
  SET_RESPONSE_DIALOG,
  GET_DETAIL_REQUESTED,
  GET_DETAIL_SUCCEEDED,
  GET_DETAIL_FAILED,
  RESET_DETAIL,
  GET_SUMMARY_INFO_REQUESTED,
  GET_SUMMARY_INFO_SUCCEEDED,
  GET_SUMMARY_INFO_FAILED,
  GET_LIST,
  GET_LIST_SUCCEEDED,
  GET_LIST_FAILED,
  SET_PAGE_TABLE,
  MODIFY_FILTERS,
  SET_SEARCH,
  RESET,
  EXPORT_LIST,
  BAN,
  BAN_FAILED,
  BAN_SUCCEEDED,
  DOWNLOAD_ZIP,
  DOWNLOAD_ZIP_FAILED,
  DOWNLOAD_XLSX,
  DOWNLOAD_XLSX_FAILED,
  UPLOAD_DOCUMENT_ADDRESS,
  UPLOAD_DOCUMENT_LICENSE,
  UPLOAD_DOCUMENT_IDENTITY,
  UPLOAD_DOCUMENT_SUCCEEDED,
  UPLOAD_DOCUMENT_FAILED,
  SEARCH,
  SEARCH_SUCCEEDED,
  SEARCH_FAILED,
  SEARCH_EMPLOYEES,
  SEARCH_EMPLOYEES_SUCCEEDED,
  SEARCH_EMPLOYEES_FAILED,
  SEARCH_JURIDICAL_CUSTOMER,
  SEARCH_JURIDICAL_CUSTOMER_SUCCEEDED,
  SEARCH_JURIDICAL_CUSTOMER_FAILED,
  SEARCH_TENDER_CUSTOMER,
  SEARCH_TENDER_CUSTOMER_SUCCEEDED,
  SEARCH_TENDER_CUSTOMER_FAILED,
  VALIDATE_CURP,
  VALIDATE_CURP_SUCCEEDED,
  VALIDATE_CURP_FAILED,
  CLEAN_VALIDATE_CURP,
  VALIDATE_CURP_DRIVER,
  VALIDATE_CURP_DRIVER_SUCCEEDED,
  VALIDATE_CURP_DRIVER_FAILED,
  CLEAN_VALIDATE_CURP_DRIVER,
  VALIDATE_ENDORSEMENT_CURP,
  VALIDATE_ENDORSEMENT_CURP_SUCCEEDED,
  VALIDATE_ENDORSEMENT_CURP_FAILED,
  CLEAN_VALIDATE_ENDORSEMENT_CURP,
  ADD_BILLING_INFO,
  ADD_BILLING_INFO_SUCCEEDED,
  ADD_BILLING_INFO_FAILED,
  DELETE_BILLING_INFO,
  DELETE_BILLING_INFO_SUCCEEDED,
  DELETE_BILLING_INFO_FAILED,
  SET_CUSTOMER_TYPE,
  SAVE_CUSTOMER_FOREGINER,
  SET_TENDER_TYPE,
  SAVE_CUSTOMER_FOREGINER_SUCCEEDED,
  SAVE_CUSTOMER_FOREGINER_FAILED,
  SET_CUSTOMER_PERSON_TYPE,
  SAVE_AVAL_CUSTOMER,
  SAVE_AVAL_CUSTOMER_SUCCEEDED,
  SAVE_AVAL_CUSTOMER_FAILED,
  RESET_AVAL_CUSTOMER,
  SET_PREVENT_CHANGE,
  SET_FORM_VALUES,
  CLEAN_FORM_VALUES,
  SET_AVAL_PREVENT_CHANGE,
  SET_SELECTION_OF_AVAL_CUSTOMER_BY_DETAIL,
  SET_AVAL_CUSTOMER_BY_DETAIL,
  HYDRATE_CUSTOMER_EDITION,
  HYDRATE_CUSTOMER_EDITION_SUCCEEDED,
  HYDRATE_CUSTOMER_EDITION_FAILED,
  GET_STATEMENT,
  GET_STATEMENT_SUCCEEDED,
  GET_STATEMENT_FAILED,
  SEARCH_JURIDICAL_TENDER_CUSTOMER,
  SEARCH_JURIDICAL_TENDER_CUSTOMER_SUCCEEDED,
  SEARCH_JURIDICAL_TENDER_CUSTOMER_FAILED,
  SAVE_CUSTOMER_BASE,
  SAVE_CUSTOMER_BASE_SUCCEEDED,
  SAVE_CUSTOMER_BASE_FAILED,
  SAVE_CUSTOMER_FOREGINER_BASE,
  SAVE_CUSTOMER_FOREGINER_BASE_SUCCEEDED,
  SAVE_CUSTOMER_FOREGINER_BASE_FAILED,
} from './action-types';

export function setParamsListOfCustomers(payload) {
  return { type: SET_PARAMS_LIST_OF_CUSTOMERS, payload };
}

export function getCustomersListFilters() {
  return { type: GET_CUSTOMERS_LIST_FILTERS };
}

export function getCustomersListFiltersSucceed(payload) {
  return { type: GET_CUSTOMERS_LIST_FILTERS_SUCCEED, payload };
}

export function getCustomersListFiltersFailed(payload) {
  return { type: GET_CUSTOMERS_LIST_FILTERS_FAILED, payload };
}

export function addressDocumentUpload(payload) {
  return { type: UPLOAD_DOCUMENT_ADDRESS, payload };
}

export function licenseDocumentUpload(payload) {
  return { type: UPLOAD_DOCUMENT_LICENSE, payload };
}

export function identityDocumentUpload(payload) {
  return { type: UPLOAD_DOCUMENT_IDENTITY, payload };
}

export function uploadDocumentFailed(payload) {
  return { type: UPLOAD_DOCUMENT_FAILED, payload };
}

export function uploadDocumentSucceeded(payload) {
  return { type: UPLOAD_DOCUMENT_SUCCEEDED, payload };
}

export function downloadFiles() {
  return { type: DOWNLOAD_ZIP };
}

export function downloadFilesFailed(payload) {
  return { type: DOWNLOAD_ZIP_FAILED, payload };
}

export function downloadXlsx() {
  return { type: DOWNLOAD_XLSX };
}

export function downloadXlsxFailed(payload) {
  return { type: DOWNLOAD_XLSX_FAILED, payload };
}

export function modifyFilters(payload) {
  return { type: MODIFY_FILTERS, payload };
}

export function reset() {
  return { type: RESET };
}

export function setPage(payload) {
  return { type: SET_PAGE_TABLE, payload };
}

export function setSearch(payload) {
  return { type: SET_SEARCH, payload };
}

export function saveCustomer(payload) {
  return { type: SAVE_REQUESTED, payload };
}

export function saveCustomerSucceeded(payload) {
  return { type: SAVE_SUCCEEDED, payload };
}

export function saveCustomerFailed(payload) {
  return { type: SAVE_FAILED, payload };
}

export function updateCustomerStatus(payload) {
  return { type: UPDATE_CUSTOMER_STATUS_REQUESTED, payload };
}

export function updateCustomerStatusSucceeded(payload) {
  return { type: UPDATE_CUSTOMER_STATUS_SUCCEEDED, payload };
}

export function updateCustomerStatusFailed(payload) {
  return { type: UPDATE_CUSTOMER_STATUS_FAILED, payload };
}

export function editCustomer(payload) {
  return { type: EDIT_REQUESTED, payload };
}

export function editCustomerSucceeded(payload) {
  return { type: EDIT_SUCCEEDED, payload };
}

export function editCustomerFailed(payload) {
  return { type: EDIT_FAILED, payload };
}

export function closeResponseDialog() {
  return { type: CLOSE_RESPONSE_DIALOG };
}

export function setResponseDialog(payload) {
  return { type: SET_RESPONSE_DIALOG, payload };
}

export function getDetail(payload) {
  return { type: GET_DETAIL_REQUESTED, payload };
}

export function getDetailSucceeded(payload) {
  return { type: GET_DETAIL_SUCCEEDED, payload };
}

export function getDetailFailed(payload) {
  return { type: GET_DETAIL_FAILED, payload };
}

export function resetDetail(payload) {
  return { type: RESET_DETAIL, payload };
}

export function getSummaryInfo(payload) {
  return { type: GET_SUMMARY_INFO_REQUESTED, payload };
}

export function getSummaryInfoSucceeded(payload) {
  return { type: GET_SUMMARY_INFO_SUCCEEDED, payload };
}

export function getSummaryInfoFailed(payload) {
  return { type: GET_SUMMARY_INFO_FAILED, payload };
}

export function getList(payload) {
  return { type: GET_LIST, payload };
}

export function getListSucceeded(payload) {
  return { type: GET_LIST_SUCCEEDED, payload };
}

export function getListFailed(payload) {
  return { type: GET_LIST_FAILED, payload };
}

export function exportList(payload) {
  return { type: EXPORT_LIST, payload };
}

export function banCustomer(payload) {
  return { type: BAN, payload };
}

export function banCustomerFailed(payload) {
  return { type: BAN_FAILED, payload };
}

export function banCustomerSucceded(payload) {
  return { type: BAN_SUCCEEDED, payload };
}

export function search(payload) {
  return { type: SEARCH, payload };
}

export function searchSucceeded(payload) {
  return { type: SEARCH_SUCCEEDED, payload };
}

export function searchFailed(payload) {
  return { type: SEARCH_FAILED, payload };
}

export function searchEmployees(payload) {
  return { type: SEARCH_EMPLOYEES, payload };
}

export function searchEmployeesSucceeded(payload) {
  return { type: SEARCH_EMPLOYEES_SUCCEEDED, payload };
}

export function searchEmployeesFailed(payload) {
  return { type: SEARCH_EMPLOYEES_FAILED, payload };
}

export function searchJuridicalCustomer(payload, typeValue) {
  return { type: SEARCH_JURIDICAL_CUSTOMER, payload, typeValue };
}

export function searchJuridicalCustomerSucceeded(payload) {
  return { type: SEARCH_JURIDICAL_CUSTOMER_SUCCEEDED, payload };
}

export function searchJuridicalCustomerFailed(payload) {
  return { type: SEARCH_JURIDICAL_CUSTOMER_FAILED, payload };
}

export function searchTenderCustomer(payload, typeValue) {
  return { type: SEARCH_TENDER_CUSTOMER, payload, typeValue };
}

export function searchTenderCustomerSucceeded(payload) {
  return { type: SEARCH_TENDER_CUSTOMER_SUCCEEDED, payload };
}

export function searchTenderCustomerFailed(payload) {
  return { type: SEARCH_TENDER_CUSTOMER_FAILED, payload };
}

export function searchJuridicalTenderCustomer(payload, typeValue) {
  return { type: SEARCH_JURIDICAL_TENDER_CUSTOMER, payload, typeValue };
}

export function searchJuridicalTenderCustomerSucceeded(payload) {
  return { type: SEARCH_JURIDICAL_TENDER_CUSTOMER_SUCCEEDED, payload };
}

export function searchJuridicalTenderCustomerFailed(payload) {
  return { type: SEARCH_JURIDICAL_TENDER_CUSTOMER_FAILED, payload };
}

export function validateCurp(payload) {
  return { type: VALIDATE_CURP, payload };
}

export function validateCurpSucceeded(payload) {
  return { type: VALIDATE_CURP_SUCCEEDED, payload };
}

export function validateCurpFailed(payload) {
  return { type: VALIDATE_CURP_FAILED, payload };
}

export function cleanValidateCurp(payload) {
  return { type: CLEAN_VALIDATE_CURP, payload };
}

export function validateEndorsementCurp(payload) {
  return { type: VALIDATE_ENDORSEMENT_CURP, payload };
}

export function validateEndorsementCurpSucceeded(payload) {
  return { type: VALIDATE_ENDORSEMENT_CURP_SUCCEEDED, payload };
}

export function validateEndorsementCurpFailed(payload) {
  return { type: VALIDATE_ENDORSEMENT_CURP_FAILED, payload };
}

export function cleanValidateEndorsementCurp(payload) {
  return { type: CLEAN_VALIDATE_ENDORSEMENT_CURP, payload };
}

/* Drivers */
export function validateCurpDriver(payload) {
  return { type: VALIDATE_CURP_DRIVER, payload };
}

export function validateCurpDriverSucceeded(payload) {
  return { type: VALIDATE_CURP_DRIVER_SUCCEEDED, payload };
}

export function validateCurpDriverFailed(payload) {
  return { type: VALIDATE_CURP_DRIVER_FAILED, payload };
}

export function cleanValidateCurpDriver(payload) {
  return { type: CLEAN_VALIDATE_CURP_DRIVER, payload };
}

/* Billing */
export function addBillingInfo(payload) {
  return { type: ADD_BILLING_INFO, payload };
}

export function addBillingInfoSucceeded(payload) {
  return { type: ADD_BILLING_INFO_SUCCEEDED, payload };
}

export function addBillingInfoFailed(payload) {
  return { type: ADD_BILLING_INFO_FAILED, payload };
}

export function deleteBillingInfo(payload) {
  return { type: DELETE_BILLING_INFO, payload };
}

export function deleteBillingInfoSucceeded(payload) {
  return { type: DELETE_BILLING_INFO_SUCCEEDED, payload };
}

export function deleteBillingInfoFailed(payload) {
  return { type: DELETE_BILLING_INFO_FAILED, payload };
}

export function setCustomerType(payload) {
  return { type: SET_CUSTOMER_TYPE, payload };
}

export function setCustomerPersonType(payload) {
  return { type: SET_CUSTOMER_PERSON_TYPE, payload };
}

export function setTenderType(payload) {
  return { type: SET_TENDER_TYPE, payload };
}

export function saveCustomerForeginer(payload) {
  return { type: SAVE_CUSTOMER_FOREGINER, payload };
}

export function saveCustomerForeignerSucceeded(payload) {
  return { type: SAVE_CUSTOMER_FOREGINER_SUCCEEDED, payload };
}

export function saveCustomerForeignerFailed(payload) {
  return { type: SAVE_CUSTOMER_FOREGINER_FAILED, payload };
}

export function saveCustomerForeginerBase(payload) {
  return { type: SAVE_CUSTOMER_FOREGINER_BASE, payload };
}

export function saveCustomerForeignerBaseSucceeded(payload) {
  return { type: SAVE_CUSTOMER_FOREGINER_BASE_SUCCEEDED, payload };
}

export function saveCustomerForeignerBaseFailed(payload) {
  return { type: SAVE_CUSTOMER_FOREGINER_BASE_FAILED, payload };
}

export function saveAvalCustomer(payload) {
  return { type: SAVE_AVAL_CUSTOMER, payload };
}

export function saveAvalCustomerSucceeded(payload) {
  return { type: SAVE_AVAL_CUSTOMER_SUCCEEDED, payload };
}

export function saveAvalCustomerFailed(payload) {
  return { type: SAVE_AVAL_CUSTOMER_FAILED, payload };
}

export function resetAvalCustomer(payload) {
  return { type: RESET_AVAL_CUSTOMER, payload };
}

export function setPreventChange(payload) {
  return { type: SET_PREVENT_CHANGE, payload };
}

export function setFormValues(payload) {
  return { type: SET_FORM_VALUES, payload };
}

export function cleanFormValues(payload) {
  return { type: CLEAN_FORM_VALUES, payload };
}

export function setAvalPreventChange(payload) {
  return { type: SET_AVAL_PREVENT_CHANGE, payload };
}

export function setSelectionOfAvalCustomerByDetail(payload) {
  return { type: SET_SELECTION_OF_AVAL_CUSTOMER_BY_DETAIL, payload };
}

export function setAvalCustomerByDetail(payload) {
  return { type: SET_AVAL_CUSTOMER_BY_DETAIL, payload };
}

export function hydrateCustomerEdition(payload) {
  return { type: HYDRATE_CUSTOMER_EDITION, payload };
}

export function hydrateCustomerEditionSuccess(payload) {
  return { type: HYDRATE_CUSTOMER_EDITION_SUCCEEDED, payload };
}

export function hydrateCustomerEditionFailed(payload) {
  return { type: HYDRATE_CUSTOMER_EDITION_FAILED, payload };
}

export function getStatement(payload) {
  return { type: GET_STATEMENT, payload };
}

export function getStatementSuccess(payload) {
  return { type: GET_STATEMENT_SUCCEEDED, payload };
}

export function getStatementFailed(payload) {
  return { type: GET_STATEMENT_FAILED, payload };
}

export function saveCustomerBaseInfo(payload) {
  return { type: SAVE_CUSTOMER_BASE, payload };
}

export function saveCustomerBaseInfoSucceeded(payload) {
  return { type: SAVE_CUSTOMER_BASE_SUCCEEDED, payload };
}

export function saveCustomerBaseInfoFailed(payload) {
  return { type: SAVE_CUSTOMER_BASE_FAILED, payload };
}
