import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { withLayout } from 'hooks';
import {
  sendFormLink,
  closeResponseDialog,
  verifySignaturesCompleted,
  rejectSignatures,
  acceptSignatures,
  getReservationForm,
  finishExtendContract,
} from 'store/reservations/actions';
import i18n from '@i18n';
import PromissoryNote from './PromissoryNote';

export const PromissoryNoteWithSignatures = withLayout(
  compose(
    connect(
      (state) => ({
        loader: _get(state, 'commons.loader', false),
        isCompleted: _get(state, 'reservations.signatures.isCompleted', false),
        accepted: _get(state, 'reservations.signatures.accepted', null),
        isSending: _get(state, 'reservations.signatures.isSending', false),
        extendContract: _get(state, 'reservations.contract.extendContract', {}),
        confirmationMessage: false,
        reservationContract: state.reservations.contract.reservationContract,
        responseDialog: {
          ...state.reservations.responseDialog,
          closeInConfirmation: true,
        },
        summaryInfo: state.customers.summaryInfo.data,
        contractSendFormLink: state.reservations.contract.sendFormLink,
        formUuid: _get(
          state,
          'reservations.contract.reservationContract.data.form.uuid',
          ''
        ),
        step: 5,
        title: i18n(
          'RESERVATIONS__PROMISSORY_NOTE_WITH_SIGNATURES__STEPPER__TITLE'
        ),
        subtitle: i18n(
          'RESERVATIONS__PROMISSORY_NOTE_WITH_SIGNATURES__STEPPER__SUBTITLE'
        ),
        reservationId: _get(state, 'reservations.reservation.id', ''),
      }),
      {
        closeResponseDialog,
        sendFormLink,
        verifySignaturesCompleted,
        rejectSignatures,
        acceptSignatures,
        getReservationForm,
        finishExtendContract,
      }
    )
  )(PromissoryNote)
);

export default withLayout(
  compose(
    connect(
      (state) => ({
        loader: _get(state, 'commons.loader', false),
        isCompleted: _get(state, 'reservations.signatures.isCompleted', false),
        accepted: _get(state, 'reservations.signatures.accepted', null),
        isSending: _get(state, 'reservations.signatures.isSending', false),
        extendContract: _get(state, 'reservations.contract.extendContract', {}),
        confirmationMessage: false,
        reservationContract: state.reservations.contract.reservationContract,
        responseDialog: {
          ...state.reservations.responseDialog,
          closeInConfirmation: true,
        },
        summaryInfo: state.customers.summaryInfo.data,
        contractSendFormLink: state.reservations.contract.sendFormLink,
        formUuid: _get(
          state,
          'reservations.contract.reservationContract.data.form.uuid',
          ''
        ),
        reservationInfo: state.reservations.reservationInfo.data,
        reservationId: _get(state, 'reservations.reservation.id', ''),
      }),
      {
        closeResponseDialog,
        sendFormLink,
        verifySignaturesCompleted,
        getReservationForm,
        finishExtendContract,
      }
    )
  )(PromissoryNote)
);
