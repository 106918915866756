import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from '@i18n';
import {
  Table,
  withLayout,
  TableBottom,
  Section,
  useFirstRender,
} from '@casanova/casanova-common';
import { columns } from './columns';

function VehicleStatus({
  match,
  vehicleStatus,
  fetchVehicleStatus,
  setPageVehicleStatus,
  onlyFetchParams = false,
}) {
  const { params = {}, results = {} } = vehicleStatus;

  const isFirstRender = useFirstRender();

  const vehicleUuid = useMemo(() => match.params.id, [match.params.id]);

  useEffect(() => {
    if (onlyFetchParams ? !isFirstRender : true) {
      fetchVehicleStatus({ params, uuid: vehicleUuid });
    }
  }, [vehicleUuid, params, onlyFetchParams]);

  return (
    <Section title={i18n('VEHICLES__SECTIONS__VEHICLE_ESTATUS__TITLE')}>
      <Table
        primary
        emptyMessage={i18n('ERROR__COMMONS__NOT_FOUND')}
        dataList={results.list}
        columns={columns}
      />
      <TableBottom
        search={vehicleStatus}
        onChange={setPageVehicleStatus}
        showButton={false}
      />
    </Section>
  );
}

VehicleStatus.propTypes = {
  onlyFetchParams: PropTypes.bool,
};

export default withLayout(VehicleStatus, { withLoader: false });
