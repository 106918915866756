import VehiclesModuleIcon from '@casanova/casanova-common/lib/Icons/VehiclesModule';
import i18n from '@i18n';
import { VEHICLES_MODULE, VIEW_VEHICLE_CATALOG } from 'utils/roles/permissions';
import { withReconcileNav } from 'containers/Breadcrumb';
import { withRoleRestrictions } from 'utils/auth';
import { validateRolePermissions } from 'utils/roles';
import VehicleMassLoad from '@vehicles/VehicleMassLoad';
import VehicleList from '@vehicles/VehicleList';
import VehicleEditor from '@vehicles/VehicleEditor';
import VehicleCreator from '@vehicles/VehicleCreator';
import VehicleHistoricDetail from '@vehicles/VehicleHistoricDetail';

const vehicle = {
  moduleName: VEHICLES_MODULE,
  route: '/vehicle',
  label: 'Vehículos',
  icon: VehiclesModuleIcon,
  main: 'all',
  show: validateRolePermissions(VIEW_VEHICLE_CATALOG),
  children: {
    all: {
      route: '',
      label: 'Todos los vehículos',
      component: withReconcileNav(
        'vehicle',
        'all'
      )(withRoleRestrictions(VehicleList)),
    },
    add: {
      route: `/add`,
      label: 'Nuevo',
      format: () => `/vehicle/add`,
      onlyRoute: true,
      component: withReconcileNav(
        'vehicle',
        'add'
      )(withRoleRestrictions(VehicleCreator)),
    },
    massiveLoad: {
      route: `/massive-load`,
      label: 'Carga masiva excel',
      format: () => `/massive-load`,
      onlyRoute: true,
      component: withReconcileNav(
        'vehicle',
        'massiveLoad'
      )(withRoleRestrictions(VehicleMassLoad)),
    },
    massiveLoadForSales: {
      route: `/massive-load-for-sales`,
      label: 'Carga masiva vendidos',
      onlyRoute: true,
      component: withReconcileNav(
        'vehicle',
        'massiveLoadForSales'
      )(withRoleRestrictions(VehicleMassLoad)),
    },
    view: {
      route: `/:id/detail`,
      label: 'Ver',
      format: (id) => `/vehicle/${id}/detail`,
      onlyRoute: true,
      component: withReconcileNav(
        'vehicle',
        'view'
      )(withRoleRestrictions(VehicleEditor)),
    },
    edit: {
      route: `/:id/edit`,
      label: 'Editar',
      format: (id) => `/vehicle/${id}/edit`,
      onlyRoute: true,
      component: withReconcileNav(
        'vehicle',
        'edit'
      )(withRoleRestrictions(VehicleEditor)),
    },
    historicDetail: {
      route: `/:id/historic-detail`,
      label: i18n('VEHICLES__HISTORIC_DETAIL__LABEL'),
      format: (id) => `/vehicle/${id}/historic-detail`,
      onlyRoute: true,
      component: withReconcileNav(
        'vehicle',
        'historicDetail'
      )(withRoleRestrictions(VehicleHistoricDetail)),
    },
  },
};

export default vehicle;
