import { setListParams } from '../../../store/dailyRentContracts/actions';
import { YYYY_MM_DD, timeZone } from 'utils/constants';
import moment from 'moment-timezone';
import * as yup from 'yup';
import { getBrach, getRoleName } from '../../../services/auth';

const userBranchUuid =
  getRoleName() != 'CONTACT_CENTER' && getBrach() ? getBrach() : '';

const schema = yup.object().shape({
  contractNumber: yup.string(),
  folio: yup.string(),
  customerName: yup.string(),
  plateNumber: yup.string(),
  startDate: yup.string(),
  finalDate: yup.string(),
  amount: yup.string(),
  departureBranch: yup.string(),
  returnBranch: yup.string(),
  closeDate: yup.string(),
  contractStatus: yup.string(),
});

const initialTouched = {
  contractNumber: true,
  folio: true,
  customerName: true,
  plateNumber: true,
  startDate: true,
  finalDate: true,
  amount: true,
  departureBranch: true,
  returnBranch: true,
  closeDate: true,
  contractStatus: true,
};

const initialValues = {
  contractNumber: '',
  folio: '',
  customerName: '',
  plateNumber: '',
  startDate: '',
  finalDate: '',
  amount: '',
  departureBranch: userBranchUuid,
  returnBranch: '',
  closeDate: '',
  contractStatus: '',
};

const formatter = (values) => {
  const { startDate, finalDate } = values;
  return {
    ...values,
    startDate: startDate
      ? moment(startDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
      : '',
    finalDate: finalDate
      ? moment(finalDate, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
      : '',
  };
};

const config = {
  schema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnBlur: true,
  formatter,
  submitForm: setListParams,
  withButtons: false,
  dialogs: {
    confirmation: {},
    response: {},
  },
};

export default config;
