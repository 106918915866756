import { changeOfVehicle } from 'store/contracts/actions';
import i18n from '@i18n';
import { schema as vehiclesSchema } from '@contracts/sections/Vehicles/schema';
import { schema } from './schema';
import { formatter } from './formatter';
import { transformer } from './transformer';

export const config = {
  schema: vehiclesSchema.concat(schema),
  initialTouched: {
    incident: true,
    periodicity: true,
    finalKm: true,
    changeDate: true,
    reason: true,
    total: true,
    vehicleA: true,
    vehicleB: true,
    vehicles: true,
    vehicleSearch: true,
  },
  initialValues: {
    incident: '',
    periodicity: '',
    finalKm: '',
    changeDate: '',
    reason: '',
    total: '',
    vehicleA: {},
    vehicleB: {},
    vehicles: [],
    vehicleSearch: '',
    uuid: '',
    currentVehicle: '',
  },
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter,
  transformer,
  submitForm: changeOfVehicle,
  dialogs: {
    confirmation: {
      title: i18n('CONTRACTS__CONTRACT_CREATOR__MODAL__TITLE'),
      message: i18n('CONTRACTS__CONTRACT_CREATOR__MODAL__DESCRIPTION'),
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: i18n(
        'CONTRACTS__CONTRACT_CHANGE_VEHICLE__SUCCESS_MODAL__MESSAGE'
      ),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: i18n(
        'CONTRACTS__CONTRACT_CREATOR__SUCCESS_MODAL__SUCCESS_LABEL'
      ),
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: '/contracts',
    },
  },
};
