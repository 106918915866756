import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Text, View } from '@react-pdf/renderer';
import { PDFPage, PDFTable, PDFInfoText } from '@casanova/casanova-common';
import moment from 'moment';

import { timeZone, YYYY_MM_DD } from '@casanova/casanova-common/lib/utils/date';
import { number2mxn } from 'utils/currency';
import { COLORS } from 'utils/colors';

import { statementListTransformer, getTotal } from './transformers';
import { columns } from '../columns';
import styles from './styles';

export default function AccountStatementPDF({
  customerName,
  customerNumber,
  statementList,
}) {
  const today = useMemo(() => {
    const date = moment().tz(timeZone);
    return `${date.format(YYYY_MM_DD)} ${date.format('HH:mm')} HRS`;
  }, []);

  const transformedStatementList = useMemo(
    () => statementListTransformer(statementList),
    []
  );

  const total = useMemo(() => getTotal(statementList), []);
  const totalColor = total >= 0 ? COLORS.SUCCESS : COLORS.NEGATIVE;

  return (
    <PDFPage>
      <View style={styles.statusInfo}>
        <View style={styles.statusInfoText}>
          <PDFInfoText
            label="Cliente: "
            info={`${customerName} - ${customerNumber}`}
          />
        </View>
        <View style={styles.statusInfoText}>
          <PDFInfoText label="Fecha de impresión: " info={today} />
        </View>
      </View>

      <PDFTable columns={columns} dataList={transformedStatementList} />

      <View style={styles.totalAmount}>
        <Text style={[styles.totalAmountText, { color: totalColor }]}>
          Total: {number2mxn(total).toString()}
        </Text>
      </View>
    </PDFPage>
  );
}

AccountStatementPDF.propTypes = {
  customerName: PropTypes.string.isRequired,
  customerNumber: PropTypes.string.isRequired,
  statementList: PropTypes.arrayOf(PropTypes.object).isRequired,
};
