import { formatDateWithTime } from '@casanova/casanova-common/lib/utils/date';

export const listOfInvoicesTransformer = (data) => ({
  ...data,
  content: data.content.map((invoices) => ({
    ...invoices,
    id: invoices.uuid,
    // dateTime: formatDateWithTime(invoices.dateTime),
    dateTime: invoices.dateTime,
  })),
});

export const CRPsTransformer = (data) => ({
  ...data,
  content: data.content.map((crp) => ({
    ...crp,
    id: crp.uuid,
    paymentDate: formatDateWithTime(crp.paymentDate),
  })),
});
