import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { alphaMask } from 'utils/masks';

import {
  MaskedInput as Input,
  Select,
  Switch,
  FileInput,
  RadioGroupInput,
  Icons,
  Button,
  NumberInput,
} from '@casanova/casanova-common';

// redux
import { useSelector } from 'react-redux';
import * as attachIcon from '@images/attach.png';

const ConversionForm = ({ dirty, action, onSaveEdit, onCancelEdit }) => {
  const listConversionType = useSelector(
    (state) => state.catalogs.conversionType.list
  );
  const listConversionOrigin = useSelector(
    (state) => state.catalogs.conversionOrigin.list
  );

  const { values } = useFormikContext();
  const requestFiles =
    action !== 'add' &&
    values &&
    values.initialConversionCost &&
    values.conversionCost &&
    values.initialConversionCost ===
      values.conversionCost.replace(/[^0-9.]+/g, '') &&
    values.initialConversionInvoiceNumber === values.conversionInvoiceNumber;

  return (
    <>
      <div className="row">
        <div className="col-md-4 switch-inverted">
          <label htmlFor="hasAdapatation">
            ¿El vehículo tiene conversiones?
          </label>
          <Switch name="hasConversion" disabled={action === 'view'} />
        </div>
        <div className="col-md-8">
          {values.hasConversion && (
            <RadioGroupInput
              labelClass="d-flex align-items-center justify-content-end"
              groupClass="col-6 d-flex align-items-center justify-content-start"
              label="Origen de las conversiones"
              name="originConversion"
              options={listConversionOrigin}
              disabled={action === 'view'}
            />
          )}
        </div>
      </div>
      <div
        style={{ display: values.hasConversion ? 'flex' : 'none' }}
        className="row"
      >
        <div className="col-md-4">
          <Select
            label="Tipo de conversión*"
            name="conversionType"
            options={listConversionType}
            disabled={action === 'view'}
          />
        </div>
        <div className="col-md-4">
          <NumberInput
            maskSuffix=" mxn"
            decimals={2}
            max={9_999_999}
            label="Costo total de conversión*"
            placeholder="Costo total de conversión*"
            name="conversionCost"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-md-4">
          <Input
            mask={alphaMask(30)}
            maskPlaceholder=""
            label="Número de factura"
            placeholder="Número de factura*"
            name="conversionInvoiceNumber"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-md-4">
          <FileInput
            right={
              requestFiles ? (
                <Icons.Eye width="85%" height="75%" />
              ) : (
                <img src={attachIcon} alt="attach icon" />
              )
            }
            url={requestFiles ? values.conversionPDFUrl : undefined}
            displayText={values.conversionInvoicePDF}
            type="file"
            label="Factura conversión PDF"
            placeholder="Factura conversión PDF"
            name="conversionInvoicePDF"
            accept="application/pdf"
            disabled={requestFiles}
          />
        </div>
        <div className="col-md-4">
          <FileInput
            download
            right={<img src={attachIcon} alt="attach icon" />}
            url={requestFiles ? values.conversionXMLUrl : undefined}
            displayText={values.conversionInvoiceXML}
            type="file"
            label="Factura conversión XML"
            placeholder="Factura conversión XML"
            name="conversionInvoiceXML"
            accept="application/xml"
            disabled={requestFiles}
          />
        </div>
      </div>
      {action === 'edit' && (
        <div className="row">
          <div className="offset-md-8 col-md-4 text-right">
            <div className="row">
              <div className="col-md-6">
                <Button onClick={onCancelEdit} outline block>
                  Cancelar
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  disabled={!dirty}
                  type="submit"
                  block
                  onClick={onSaveEdit}
                >
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ConversionForm.propTypes = {
  action: PropTypes.string,
  onSaveEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
  onEdit: PropTypes.func,
};

ConversionForm.defaultProps = {
  action: 'add',
};

export default ConversionForm;
