const RESET = 'loans/RESET';
const SET_PARAMS = 'loans/SET_PARAMS';
const GET_LIST = 'loans/GET_LIST';
const GET_LIST_SUCCEED = 'loans/GET_LIST_SUCCEED';
const GET_LIST_FAILED = 'loans/GET_LIST_FAILED';
const SET_LIST_PAGE = 'loans/SET_LIST_PAGE';

const SAVE_LOAN = 'loans/SAVE_LOAN';
const SAVE_LOAN_SUCCEED = 'loans/SAVE_LOAN_SUCCEED';
const SAVE_LOAN_FAILED = 'loans/SAVE_LOAN_FAILED';

const CLOSE_RESPONSE_DIALOG = 'loans/CLOSE_RESPONSE_DIALOG';

const GET_LOAN_DETAILS = 'loans/GET_LOAN_DETAILS';
const GET_LOAN_DETAILS_SUCCEED = 'loans/GET_LOAN_DETAILS_SUCCEED';
const GET_LOAN_DETAILS_FAILED = 'loans/GET_LOAN_DETAILS_FAILED';

const EDIT_LOAN = 'loans/EDIT_LOAN';
const EDIT_LOAN_SUCCEED = 'loans/EDIT_LOAN_SUCCEED';
const EDIT_LOAN_FAILED = 'loans/EDIT_LOAN_FAILED';

const CLOSE_LOAN = 'loans/CLOSE_LOAN';
const CLOSE_LOAN_SUCCEED = 'loans/CLOSE_LOAN_SUCCEED';
const CLOSE_LOAN_FAILED = 'loans/CLOSE_LOAN_FAILED';

const CANCEL_LOAN = 'loans/CANCEL_LOAN';
const CANCEL_LOAN_SUCCEED = 'loans/CANCEL_LOAN_SUCCEED';
const CANCEL_LOAN_FAILED = 'loans/CANCEL_LOAN_FAILED';

const AUTHORIZE_LOAN = 'loans/AUTHORIZE_LOAN';
const AUTHORIZE_LOAN_SUCCEED = 'loans/AUTHORIZE_LOAN_SUCCEED';
const AUTHORIZE_LOAN_FAILED = 'loans/AUTHORIZE_LOAN_FAILED';

export default {
  GET_LIST,
  GET_LIST_SUCCEED,
  GET_LIST_FAILED,
  RESET,
  SET_PARAMS,
  SET_LIST_PAGE,
  CLOSE_RESPONSE_DIALOG,
  SAVE_LOAN,
  SAVE_LOAN_SUCCEED,
  SAVE_LOAN_FAILED,
  GET_LOAN_DETAILS,
  GET_LOAN_DETAILS_SUCCEED,
  GET_LOAN_DETAILS_FAILED,
  EDIT_LOAN,
  EDIT_LOAN_SUCCEED,
  EDIT_LOAN_FAILED,
  CLOSE_LOAN,
  CLOSE_LOAN_SUCCEED,
  CLOSE_LOAN_FAILED,
  CANCEL_LOAN,
  CANCEL_LOAN_SUCCEED,
  CANCEL_LOAN_FAILED,
  AUTHORIZE_LOAN,
  AUTHORIZE_LOAN_SUCCEED,
  AUTHORIZE_LOAN_FAILED,
};
