import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { alphaMask } from 'utils/masks';

import {
  MaskedInput as Input,
  Select,
  Switch,
  FileInput,
  RadioGroupInput,
  Icons,
  Button,
  NumberInput,
} from '@casanova/casanova-common';
import RoleVerifier from 'components/RoleVerifier';
import UserInfo from 'components/UserInfo';
import { useVehicle } from 'hooks';

import { EDIT_VEHICLE_ADAPTATION } from 'utils/roles/permissions';
import * as attachIcon from '@images/attach.png';

const AdaptationForm = ({
  listAdaptationOrigin,
  listAdaptationType,
  dirty,
  action,
  onSaveEdit,
  onCancelEdit,
  onEdit,
}) => {
  const { values } = useFormikContext();
  const requestFiles =
    action !== 'add' &&
    values &&
    values.initialAdaptationCost &&
    values.adaptationCost &&
    values.initialAdaptationCost ===
      values.adaptationCost.replace(/[^0-9.]+/g, '') &&
    values.initialAdaptationInvoiceNumber === values.adaptationInvoiceNumber;

  const { isSold } = useVehicle();

  return (
    <>
      <div className="row">
        <div className="col-md-4 switch-inverted">
          <label htmlFor="hasAdapatation">
            ¿El vehículo tiene adaptaciones?
          </label>
          <Switch name="hasAdaptation" disabled={action === 'view'} />
        </div>
        <div className="col-md-8">
          {values.hasAdaptation && (
            <RadioGroupInput
              labelClass="d-flex align-items-center justify-content-end"
              groupClass="col-6 d-flex align-items-center justify-content-start"
              label="Origen de las adaptaciones"
              name="originAdaptation"
              options={listAdaptationOrigin}
              disabled={action === 'view'}
            />
          )}
        </div>
      </div>
      <div
        style={{ display: values.hasAdaptation ? 'flex' : 'none' }}
        className="row"
      >
        <div className="col-md-4">
          <Select
            label="Tipo de adaptación*"
            name="adaptationType"
            options={listAdaptationType}
            disabled={action === 'view'}
          />
        </div>
        <div className="col-md-4">
          <NumberInput
            maskSuffix=" mxn"
            decimals={2}
            max={9_999_999}
            label="Costo total de adaptación*"
            placeholder="Costo total de adaptación*"
            name="adaptationCost"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-md-4">
          <Input
            mask={alphaMask(30)}
            maskPlaceholder=""
            label="Número de factura*"
            placeholder="Número de factura*"
            name="adaptationInvoiceNumber"
            disabled={action === 'view'}
          />
        </div>
        <div className="col-md-4">
          <FileInput
            right={
              requestFiles ? (
                <Icons.Eye width="85%" height="75%" />
              ) : (
                <img src={attachIcon} alt="attach icon" />
              )
            }
            url={requestFiles ? values.adaptationPDFUrl : undefined}
            displayText={values.adaptationInvoicePDF}
            type="file"
            label="Factura Adaptación PDF*"
            placeholder="Factura Adaptación PDF*"
            name="adaptationInvoicePDF"
            accept="application/pdf"
            disabled={requestFiles}
          />
        </div>
        <div className="col-md-4">
          <FileInput
            download
            right={
              requestFiles ? (
                <Icons.Download width="85%" height="75%" />
              ) : (
                <img src={attachIcon} alt="attach icon" />
              )
            }
            url={requestFiles ? values.adaptationXMLUrl : undefined}
            displayText={values.adaptationInvoiceXML}
            type="file"
            label="Factura Adaptación XML*"
            placeholder="Factura Adaptación XML*"
            name="adaptationInvoiceXML"
            accept="application/xml"
            disabled={requestFiles}
          />
        </div>
      </div>
      <RoleVerifier identifier={EDIT_VEHICLE_ADAPTATION}>
        {action === 'view' && !isSold && (
          <div className="row">
            <div className="col-6 offset-3 col-md-2 offset-md-10">
              <br />
              <Button onClick={onEdit} block>
                {' '}
                Editar{' '}
              </Button>
            </div>
          </div>
        )}
      </RoleVerifier>
      {action === 'edit' && (
        <div className="row">
          <div className="offset-md-8 col-md-4 text-right">
            <div className="row">
              <div className="col-md-6">
                <Button onClick={onCancelEdit} outline block>
                  Cancelar
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  disabled={!dirty}
                  type="submit"
                  block
                  onClick={onSaveEdit}
                >
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <br />
      <UserInfo {...values.adaptationActions} />
    </>
  );
};

AdaptationForm.propTypes = {
  action: PropTypes.string,
  onSaveEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
  onEdit: PropTypes.func,
};

AdaptationForm.defaultProps = {
  action: 'add',
};

export default AdaptationForm;
