import types from './actions-types';

export function getReportsList(payload) {
  return { type: types.GET_LIST, payload };
}

export function getReportsListSucceed(payload) {
  return { type: types.GET_LIST_SUCCEED, payload };
}

export function getReportsListFailed(payload) {
  return { type: types.GET_LIST_FAILED, payload };
}

export function setListParams(payload) {
  return { type: types.SET_PARAMS, payload };
}

export function setListPage(payload) {
  return { type: types.SET_LIST_PAGE, payload };
}

export function cleanReportsList() {
  return { type: types.CLEAN_LIST };
}

export function closeResponseDialog() {
  return { type: types.CLOSE_RESPONSE_DIALOG };
}

export function getTypesOfReports(payload) {
  return { type: types.GET_TYPES_OF_REPORTS, payload };
}

export function getTypesOfReportsSucceed(payload) {
  return { type: types.GET_TYPES_OF_REPORTS_SUCCEED, payload };
}

export function getTypesOfReportsFailed(payload) {
  return { type: types.GET_TYPES_OF_REPORTS_FAILED, payload };
}

export function setReportColumns(payload) {
  return { type: types.SET_REPORT_COLUMNS, payload };
}

export function downloadReports(payload) {
  return { type: types.DOWNLOAD_REPORTS, payload };
}

export function downloadReportsSucceed(payload) {
  return { type: types.DOWNLOAD_REPORTS_SUCCEED, payload };
}

export function downloadReportsFailed(payload) {
  return { type: types.DOWNLOAD_REPORTS_FAILED, payload };
}
