import React from 'react';
import PropTypes from 'prop-types';
import { Icons } from '@casanova/casanova-common';
import Card from 'components/CardSection';
import './offer.scss';

const Offer = ({ image, vehicle, amount, onClick, line, selected }) => {
  const currency = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(amount);

  const handleOnClick = () => {
    onClick(line);
  };
  return (
    <Card noStatus noTitle padding="py-0 px-0">
      <div className="row OfferComponent" onClick={handleOnClick}>
        <div className="col-6">
          <div className="image-container">
            <img src={image} alt="" />
          </div>
        </div>
        <div className="col-6 body-container">
          <p className="offer-vehicle mb-0">{vehicle}</p>
          <p className="offer-amount mb-0">Desde {currency}</p>
        </div>
        <div className="icon-offer">
          {selected === line ? (
            <Icons.CheckAvailable width="1.5rem" height="1.5rem" />
          ) : (
            <Icons.CheckUnavailable width="1.5rem" height="1.5rem" />
          )}
        </div>
      </div>
    </Card>
  );
};

Offer.propTypes = {
  image: PropTypes.string,
  offerName: PropTypes.string,
  vehicle: PropTypes.string,
  amount: PropTypes.number,
  offerDescription: PropTypes.string,
};

Offer.defaultProps = {
  amount: 0,
};

export default Offer;
