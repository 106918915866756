import * as yup from 'yup';
import { setParamsListOfReservations } from 'store/reservations/actions';
import i18n from '@i18n';

const schema = yup.object().shape({
  folio: yup.string().notRequired(),
  name: yup.string().notRequired(),
  deliveryDate: yup.string().notRequired(),
  returnDate: yup.string().notRequired(),
  returnBranch: yup.string().notRequired(),
  status: yup.string().notRequired(),
});

const initialValues = {
  folio: '',
  name: '',
  deliveryDate: '',
  returnDate: '',
  returnBranch: '',
  status: '',
};

export const initialTouched = {
  folio: true,
  name: true,
  deliveryDate: true,
  returnDate: true,
  returnBranch: true,
  status: true,
};

export const config = {
  schema,
  modalType: 'empty',
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  submitForm: setParamsListOfReservations,
  withButtons: false,
  dialogs: {
    confirmation: {},
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: i18n('TRANSFERS__LIST__SUCCESS_ACTION__MESSAGE'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: i18n('COMMONS__CLOSE__TEXT'),
      errorLabel: i18n('COMMONS__ACCEPT__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: '/transfers',
    },
  },
};
