import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { DELIVERY_REFUND_TYPES } from '@reservations/commons/constants';
import {
  mapStateToPropsOfDeliveryAndReturn,
  dispatchToPropsOfDeliveryAndReturn,
} from '@reservations/commons/deliveryAndRefundConfig';
import Delivery from './Delivery';

const { DELIVERY } = DELIVERY_REFUND_TYPES;

export const DeliveryStepOne = compose(
  connect(
    (state) => ({
      ...mapStateToPropsOfDeliveryAndReturn(state, DELIVERY),
      confirmationMessage: false,
      step: 1,
    }),
    dispatchToPropsOfDeliveryAndReturn
  )
)(Delivery);

export const DeliveryStepTwo = compose(
  connect(
    (state) => ({
      ...mapStateToPropsOfDeliveryAndReturn(state, DELIVERY),
      confirmationMessage: false,
      step: 2,
    }),
    dispatchToPropsOfDeliveryAndReturn
  )
)(Delivery);

export const DeliveryStepThree = compose(
  connect(
    (state) => ({
      ...mapStateToPropsOfDeliveryAndReturn(state, DELIVERY),
      step: 3,
    }),
    dispatchToPropsOfDeliveryAndReturn
  )
)(Delivery);
