import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PlanAndDates,
  Invoice,
  ClientInfo,
  VehicleInfo,
} from '@reservations/sections';
import { useReservation } from 'hooks';
import { vehicleDetailTransformer } from '@reservations/commons/transformers';

export default function DeliveryReturnDetail({
  fetchCatalog,
  branches,
  verificationCheckList = {},
  showClientInfo = true,
  showVehicleInfo = true,
  vehicleInfoProps = {},
  showPlanAndDates = true,
  showInvoice = true,
  isReadOnly = false,
}) {
  const [selectedStartBranch, setSelectedStartBranch] = useState({});
  const [selectedEndBranch, setSelectedEndBranch] = useState({});

  const {
    vehicleInfo = {},
    invoiceInfo: { amount },
    reservationInfo,
  } = useReservation();

  const { priceQuote = {}, folio = '', naturalPerson = {} } = reservationInfo;

  const {
    verificationGroups = [],
    form = {},
    completed = false,
  } = verificationCheckList;

  const { inputs = [] } = form;

  const {
    pickupDateTime = '',
    pickupBranch = {},
    returnDateTime = '',
    returnBranch = {},
  } = priceQuote;

  const rentalPlanName =
    priceQuote?.selectedVehicleLinePrice?.vehicleLinePrice?.rentalPlanName;

  useEffect(() => {
    const validBranch =
      branches && branches.length > 0 && pickupBranch.uuid && returnBranch.uuid;

    if (validBranch) {
      setSelectedStartBranch(
        branches.find((item) => item.uuid === pickupBranch.uuid)
      );
      setSelectedEndBranch(
        branches.find((item) => item.uuid === returnBranch.uuid)
      );
    }
  }, [branches, pickupBranch, returnBranch]);

  useEffect(() => {
    fetchCatalog('branches');
  }, [fetchCatalog]);

  const {
    vehicleTitle = '',
    image = '',
    type = '',
    category = '',
  } = vehicleInfo;

  return (
    <section className="Detail">
      {showClientInfo && <ClientInfo {...naturalPerson} folio={folio} />}

      {showVehicleInfo && (
        <VehicleInfo
          customerFullName={naturalPerson.fullName}
          folio={folio}
          vehicleImage={image}
          selectedCategoryName={category}
          vehicleTypeName={type}
          vehicleTitle={vehicleTitle}
          detailInfo={vehicleDetailTransformer(vehicleInfo)}
          verificationGroups={verificationGroups}
          firmInfo={inputs[0] || {}}
          completed={completed}
          {...vehicleInfoProps}
        />
      )}

      {!isReadOnly && priceQuote && showPlanAndDates && (
        <PlanAndDates
          name={rentalPlanName}
          selectedStartBranch={selectedStartBranch}
          selectedEndBranch={selectedEndBranch}
          pickupDateTime={pickupDateTime}
          returnDateTime={returnDateTime}
          kmDay={amount}
        />
      )}

      {!isReadOnly && showInvoice && <Invoice />}
    </section>
  );
}

DeliveryReturnDetail.propTypes = {
  reservationInfo: PropTypes.object.isRequired,
  fetchCatalog: PropTypes.func,
  priceQuote: PropTypes.shape({
    pickupDateTime: PropTypes.string,
    pickupBranch: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    }),
    returnDateTime: PropTypes.string,
    returnBranch: PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    }),
    selectedVehicleLinePrice: PropTypes.shape({
      vehicleLinePrice: PropTypes.shape({
        rentalPlanName: PropTypes.string,
        period: PropTypes.number,
        price: PropTypes.number,
      }),
    }),
    total: PropTypes.number,
  }),
  showClientInfo: PropTypes.bool,
  showVehicleInfo: PropTypes.bool,
  showPlanAndDates: PropTypes.bool,
  showInvoice: PropTypes.bool,
  branches: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};
