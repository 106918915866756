import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import { getContractDetails } from 'store/payments/actions';

import ContractSearch from './ContractSearch';

export default withLayout(
  compose(
    connect(
      (state) => ({
        contractDetails: state.payments.contractDetails.data,
        hasNotFound: state.payments.contractDetails.hasNotFound,
      }),
      { getContractDetails }
    )
  )(ContractSearch)
);
