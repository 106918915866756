import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import { Icons, Button } from '@casanova/casanova-common';
import { number2mxn } from 'utils/currency';
import { saveStepTwo } from 'store/reservations/actions';

import './PlanDetails.scss';

const PlanDetails = ({ plans, fullVehicle }) => {
  const dispatch = useDispatch();

  const [currentPlan, setCurrentPlan] = useState(plans[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const quoteValues = useSelector((state) =>
    _get(state, 'reservations.quote.values', {})
  );

  const quoteInternalError = useSelector((state) =>
    _get(state, 'reservations.quote.internalError', {})
  );

  const {
    name,
    price,
    requiredDays,
    period,
    includedCoveragePlan,
  } = currentPlan;
  const amount = (price * quoteValues.vehicleQuantity) / period;

  const startDate = moment(quoteValues.pickupDateTime, 'yyyy-MM-DD hh:mm');
  const endDate = moment(quoteValues.returnDateTime, 'yyyy-MM-DD hh:mm');

  const differenceD = endDate.diff(startDate, 'days');
  const differenceM = endDate.diff(startDate, 'minutes');

  const minutesToDays = differenceM / (24 * 60);
  const hasRemaining = minutesToDays - differenceD > 0;

  const daysDifference = hasRemaining ? differenceD + 1 : differenceD;
  const total = amount * daysDifference;
  const hasMinusDays = daysDifference < requiredDays;

  const handleSelectPlan = useCallback(
    (planId) => {
      if (quoteInternalError.status) {
        return;
      }
      dispatch(saveStepTwo({ vehicleLinePrice: planId, fullVehicle }));
    },
    [dispatch, quoteInternalError]
  );

  const handleDecreasePlan = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentPlan(plans[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);
    }
  }, [currentIndex, plans]);

  const handleIncreasePlan = useCallback(() => {
    if (currentIndex < plans.length - 1) {
      setCurrentPlan(plans[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
    }
  }, [currentIndex, plans]);

  return (
    <div className="plan-details">
      <div className="plan-header">
        <span className="control-left" onClick={handleDecreasePlan}>
          {currentIndex > 0 && (
            <Icons.ChevronLeft width="1.375rem" height="1.375rem" />
          )}
        </span>
        <div className="plan-title">Plan {name}</div>
        <span className="control-right" onClick={handleIncreasePlan}>
          {currentIndex < plans.length - 1 && (
            <Icons.ChevronRight width="1.375rem" height="1.375rem" />
          )}
        </span>
      </div>
      <div className="plan-data">
        <p>
          <span className="common-amount-text">MXN {number2mxn(amount)}</span>
          <span className="period">POR DÍA</span>
        </p>
        <div className="plan-features">
          <span className="bold">RENTA:</span> {number2mxn(total)} x{' '}
          {daysDifference} días <br />
          <span className="bold">INCLUYE:</span>
          <br />
          <ul className="plan-features-list">
            {includedCoveragePlan.features
              .sort((a, b) => b.length - a.length)
              .map((feature) => (
                <li key={feature}>{feature}</li>
              ))}
          </ul>
          <span className="kind">Similares: Aveo</span>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2 mb-3">
          <Button
            disabled={hasMinusDays}
            onClick={() => handleSelectPlan(currentPlan.uuid)}
            block
          >
            Seleccionar
          </Button>
          {hasMinusDays && (
            <div className="should-increase-days">
              <div className="should-increase-days__bg" />
              <div className="should-increase-days__text">
                El plan es por una renta mínima de {requiredDays} días.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PlanDetails.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.object),
  fullVehicle: PropTypes.object.isRequired,
};

export default PlanDetails;
