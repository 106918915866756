import LoansModuleIcon from '@casanova/casanova-common/lib/Icons/LoansModule';
import i18n from '@i18n';
import { LOANS_MODULE } from 'utils/roles/permissions/loansPermissions';
import { withReconcileNav } from 'containers/Breadcrumb';
import { withRoleRestrictions } from 'utils/auth';
import ListOfLoans from '@loans/ListOfLoans';
import LoanCreator from '@loans/LoanCreator';
import LoanEditor from '@loans/LoanEditor';
import LoanDetails from '@loans/LoanDetails';

const loans = {
  moduleName: LOANS_MODULE,
  route: '/loans',
  label: i18n('LOANS__MENU_LABEL'),
  icon: LoansModuleIcon,
  main: 'all',
  children: {
    all: {
      route: '',
      label: '',
      component: withReconcileNav(
        'loans',
        'all'
      )(withRoleRestrictions(ListOfLoans)),
    },
    add: {
      route: `/add`,
      label: 'ALTA',
      onlyRoute: true,
      component: withReconcileNav(
        'loans',
        'add'
      )(withRoleRestrictions(LoanCreator)),
    },
    edit: {
      route: `/:id/edit`,
      label: 'EDITAR',
      format: (id) => `/loans/${id}/edit`,
      onlyRoute: true,
      component: withReconcileNav(
        'loans',
        'edit'
      )(withRoleRestrictions(LoanEditor)),
    },
    detail: {
      route: `/:id/detail`,
      label: i18n('COMMONS_DETAIL').toUpperCase(),
      format: (id) => `/loans/${id}/detail`,
      onlyRoute: true,
      component: withReconcileNav(
        'loans',
        'detail'
      )(withRoleRestrictions(LoanDetails)),
    },
  },
};

export default loans;
