import { setListParams } from 'store/invoices/actions';
import { YYYY_MM_DD, timeZone } from 'utils/constants';
import moment from 'moment-timezone';

const initialTouched = {
  folio: true,
  customerFullName: true,
  serie: true,
  totalAmount: true,
  dateTime: true,
};

const initialValues = {
  folio: '',
  customerFullName: '',
  serie: '',
  totalAmount: '',
  dateTime: '',
};

export const config = {
  schema: {},
  initialTouched,
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
  withButtons: false,
  formatter: (values) => {
    const { dateTime } = values;
    return {
      ...values,
      dateTime: dateTime
        ? moment(dateTime, YYYY_MM_DD).tz(timeZone).format(YYYY_MM_DD)
        : '',
    };
  },
  submitForm: setListParams,
  dialogs: {
    confirmation: {},
    response: {},
  },
};
