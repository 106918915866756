import {
  LOANS_MODULE,
  VIEW_LOANS,
  CREATE_LOAN_REQUEST,
  EDIT_ANY_LOAN_REQUEST,
  EDIT_OWN_LOAN_REQUEST,
} from 'utils/roles/permissions/loansPermissions';
import {
  LOANS_PATH,
  LOANS_DETAIL_PATH,
  LOANS_ADD_PATH,
  LOANS_EDIT_PATH,
} from 'utils/roles/paths';

export function loansAuth(permissions) {
  const allowedModules = [];

  if (permissions.includes(VIEW_LOANS)) {
    allowedModules.push(LOANS_MODULE);
  }

  return allowedModules;
}

export function loansPathsAuth(permissions) {
  const allowedPaths = [];

  if (permissions.includes(VIEW_LOANS)) {
    allowedPaths.push(LOANS_PATH);
    allowedPaths.push(LOANS_DETAIL_PATH);
  }

  if (permissions.includes(CREATE_LOAN_REQUEST)) {
    allowedPaths.push(LOANS_ADD_PATH);
  }

  if (
    permissions.includes(EDIT_ANY_LOAN_REQUEST) ||
    permissions.includes(EDIT_OWN_LOAN_REQUEST)
  ) {
    allowedPaths.push(LOANS_EDIT_PATH);
  }

  return allowedPaths;
}
