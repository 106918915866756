import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { withNavigation } from 'utils/navigation';
import { reconcileNav } from 'store/app/actions';
import Breadcrumb from './Breadcrumb';

export default compose(
  withNavigation,
  branch((props) => !props.activeRoute, renderNothing)
)(Breadcrumb);

export function withReconcileNav(item, subItem) {
  return compose(
    connect(
      (state) => ({
        activeRoute: state.app.activeRoute,
      }),
      { reconcile: reconcileNav }
    ),
    (Component) => (props) => {
      const { match, reconcile } = props;
      useEffect(() => {
        reconcile(item, subItem, match.params);
      }, [match, reconcile]);
      /* eslint-disable react/jsx-props-no-spreading */
      return <Component {...props} />;
      /* eslint-enable react/jsx-props-no-spreading */
    }
  );
}
