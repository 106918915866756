import {
  sendFormLinkFirm,
  verifySignaturesCompleted,
} from 'store/reservations/actions';
import {
  schemaStepTwo,
  initialTouchedStepTwo,
  initialValuesStepTwo,
} from '@reservations/commons/deliveryAndRefundConfig';

export const config = ({ number = '' }) => ({
  schema: schemaStepTwo,
  textBold: number,
  messageBold: true,
  modalType: 'action',
  initialTouched: initialTouchedStepTwo,
  initialValues: initialValuesStepTwo,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  redirectInSuccess: false,
  submitForm: sendFormLinkFirm,
  onSuccessAction: (values) => verifySignaturesCompleted(values.formUuid || ''),
  btnSubmitLabel: 'Entregar',
  dialogs: {
    confirmation: {
      title: 'FIRMAR',
      message: `Se enviará el link al número ${number} para la firma de aceptación del cliente. ¿Estás seguro que quieres enviarlo?`,
      actionLabel: 'Si, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: 'MENSAJE ENVIADO',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage: `¿El cliente ha recibido el mensaje?`,
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Si, continuar',
      errorLabel: 'Reintentar',
      cancelLabel: 'No, reenviar',
      successPath: './',
    },
  },
  withButtons: false,
});

export const SUCCESS_STEP = {
  FIRST: 'FIRST',
  SECOND: 'SECOND',
  DONE: 'DONE',
};
