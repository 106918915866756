import React, { useCallback } from 'react';
import withForm from 'hooks/withForm';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icons } from '@casanova/casanova-common';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { setQuoteVehicleCategory } from 'store/reservations/actions';
import { useDispatch } from 'react-redux';
import { config } from '../ReservationDates/config';
import ReservationDates from '../ReservationDates';
import './Schedule.scss';

const Left = () => <></>;

const HoursDisclaimer = () => (
  <div className="disclaimer">
    <span className="disclaimer-title">Importante: </span>
    <span className="disclaimer-body">
      <span>
        Importante: La hora de salida y devolución son con horario de la Zona
        Centro de México.
      </span>
    </span>
  </div>
);

const FormReservationDates = withForm({
  config,
})(ReservationDates);

const Schedule = ({
  schedStart,
  schedEnd,
  editScheduleAction,
  openEditScheduleSection,
  closeEditScheduleSection,
  quoteValues,
  selectedCategory,
  onErrorsChange,
  onDirtyAndSubmittedChange,
  onValuesChange,
  renderTop = null,
  selectedCategoryInForm = null,
  alwaysDirty,
}) => {
  const dispatch = useDispatch();

  const dateStart = moment(schedStart.date, 'yyyy-MM-DD hh:mm')
    .locale('es')
    .format('LLL');
  const dateEnd = moment(schedEnd.date, 'yyyy-MM-DD hh:mm')
    .locale('es')
    .format('LLL');

  const handleOnEdit = useCallback(() => {
    if (selectedCategoryInForm) {
      dispatch(setQuoteVehicleCategory(selectedCategoryInForm));
    }
    openEditScheduleSection();
  }, [
    dispatch,
    openEditScheduleSection,
    setQuoteVehicleCategory,
    selectedCategoryInForm,
  ]);

  const handleCancel = useCallback(() => {
    closeEditScheduleSection();
  }, [closeEditScheduleSection]);

  const customerName =
    quoteValues.customer && quoteValues.customer_search
      ? quoteValues.customer_search
      : '';

  return (
    <>
      <>{renderTop}</>
      <div className="ReservationSchedule">
        <div className="customer-name">{customerName}</div>
        <div className="scheduled-hour">
          <div className="start">
            <Icons.SchedStartVehicle width="2rem" height="2rem" /> {dateStart}{' '}
            Sucursal {schedStart.branch}
          </div>
          <div className="end">
            <Icons.SchedEndVehicle width="2rem" height="2rem" /> {dateEnd}{' '}
            Sucursal {schedEnd.branch}
          </div>
        </div>

        <div
          tabIndex={0}
          role="button"
          className="edit-section"
          onClick={handleOnEdit}
          onKeyDown={handleOnEdit}
        >
          {editScheduleAction === 'view' && (
            <>
              <div>Editar</div> <Icons.Edit width="1.5rem" height="1.5rem" />
            </>
          )}
        </div>
      </div>
      <>
        {editScheduleAction === FORM_ACTIONS.EDIT && (
          <FormReservationDates
            className="mb-5"
            onCancel={handleCancel}
            leftCol={2}
            middleCol={6}
            middle={<HoursDisclaimer />}
            left={<Left />}
            action={FORM_ACTIONS.EDIT}
            selectedCategory={selectedCategory}
            onErrorsChange={onErrorsChange}
            onDirtyAndSubmittedChange={onDirtyAndSubmittedChange}
            onValuesChange={onValuesChange}
            alwaysDirty={alwaysDirty}
            alwaysBlur
          />
        )}
      </>
    </>
  );
};

Schedule.propTypes = {
  schedStart: PropTypes.object,
};

export default Schedule;
