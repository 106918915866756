export const createObjectStatus = (keyStatus) => {
  const status = {};
  if (keyStatus) {
    keyStatus.list.map((key) => {
      status[key.name] = key.uuid;
    });
  }

  return status;
};

export const createObjectBranch = (branchs) => {
  const lendBranch = {};
  if (branchs && branchs.list) {
    branchs.list.map((branch) => {
      lendBranch[branch.name] = branch.uuid;
    });
  }

  return lendBranch;
};
