import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import {
  ProgressIndicator,
  Button,
  Icons,
  withForm,
  ActionModal,
} from '@casanova/casanova-common';
import { PreventChange } from 'components';
import { withReservationAuth } from '@hocs';
import i18n from '@i18n';
import {
  isChangeOfVehicleFlow,
  isExtendOfContractFlow,
} from '@reservations/commons/utils';
import config, { MAX_STEPS } from './config';

const Leasing = ({
  maxSteps,
  step,
  title,
  subtitle,
  history,
  reservationContract,
  formUuid,
  rejectSignatures,
  tempAcceptSignatures,
  acceptSignatures,
  getReservationForm,
  reservation,
  getSummaryInfo,
  quoteValues,
  isCompleted,
  isSending,
  accepted,
  hasCreated,
  reservationInfo,
  loader,
  finishExtendContract,
}) => {
  const [shouldPrevent, setShouldPrevent] = useStateWithCallbackLazy(true);
  const [hasContractFetched, setHasContractFetched] = useState(false);
  const [showFinishExtend, setShowFinishExtend] = useState(false);

  const {
    contract: leasingContract = '',
    contractVehicleChange = '',
  } = reservationContract;

  const contract = contractVehicleChange || leasingContract;

  const hasChangeOfVehicleFlow =
    isChangeOfVehicleFlow() || Boolean(contractVehicleChange);

  const reservationId = reservation.id;

  const isExtendContract = useMemo(() => isExtendOfContractFlow(), [
    isExtendOfContractFlow(),
  ]);

  const handleContinue = useCallback(() => {
    if (isExtendContract) {
      setShowFinishExtend(true);
    } else {
      setShouldPrevent(false, () => {
        history.push(
          `./promissory-note${
            hasChangeOfVehicleFlow ? '?change_vehicle=true' : ''
          }`
        );
      });
    }
  }, [history, hasChangeOfVehicleFlow, isExtendContract]);

  const handleFinishExtend = () => {
    setShowFinishExtend(true);
    setShouldPrevent(false, () => {
      finishExtendContract();
    });
  };

  const handleOnAccept = useCallback(() => {
    setShouldPrevent(false, () => {
      if (hasChangeOfVehicleFlow) {
        const activeChecklist = reservationInfo.verificationChecklists.filter(
          (vc) => vc.isEnabled
        );
        const returnPreviousVehicle =
          activeChecklist[0]?.completed ^ activeChecklist[1]?.completed;

        const path = returnPreviousVehicle
          ? `/returns/${reservationId}/refund`
          : `/daily-rent-contracts/${reservationId}/delivery`;
        acceptSignatures({
          form: formUuid,
          redirectPath: path,
        });
      } else {
        tempAcceptSignatures();

        history.push(
          `/daily-rent-contracts/promissory-note/signatures${
            isExtendContract ? '?extend=true' : ''
          }`
        );
      }
    });
  }, [hasChangeOfVehicleFlow]);

  const handleOnReject = useCallback(() => {
    rejectSignatures({ form: formUuid });
  }, [rejectSignatures, formUuid]);

  useEffect(() => {
    if (!isCompleted && !hasCreated && !hasContractFetched) {
      getReservationForm({ reservation: reservationId });
      setHasContractFetched(true);
    }
  }, [isCompleted, hasCreated, hasContractFetched]);

  useEffect(() => {
    if (step === 2 && _isEmpty(reservationInfo)) {
      getSummaryInfo(quoteValues.customer);
    }
  }, [reservationInfo, step]);

  useEffect(() => window.scrollTo(0, 0), []);

  const handleSignaturesContinue = useCallback(() => {
    history.push(
      `/daily-rent-contracts/leasing/signatures${
        isExtendContract ? '?extend=true' : ''
      }`
    );
  }, []);

  return (
    <>
      <PreventChange preventChange={shouldPrevent && !isCompleted} />
      <ActionModal
        open={showFinishExtend}
        actionLabel={i18n('COMMONS__CONTINUE__TEXT')}
        title="EXTENSIÓN FINALIZADA"
        message="El contrato ha sido extendido con éxito"
        icon={<Icons.IcoCarOk />}
        modalType="confirmation"
        onAction={handleFinishExtend}
      />
      <ActionModal
        open={accepted === null && isCompleted && !isSending && !loader}
        actionLabel={i18n('COMMONS__CONTINUE__TEXT')}
        title={i18n('RESERVATIONS__CONTRACT__SIGNATURES_COMPLETE_MODAL__TITLE')}
        message={i18n(
          'RESERVATIONS__CONTRACT__SIGNATURES_COMPLETE_MODAL__BODY'
        )}
        icon={<Icons.IcoCarOk />}
        modalType="confirmation"
        onAction={handleSignaturesContinue}
      />
      <div className="leasing">
        <div className="row">
          <div className="col-12 p-0 d-flex align-items-center">
            <ProgressIndicator
              size={120}
              stroke={['#e2e2e2', '#007aff']}
              strokeWidth={hasChangeOfVehicleFlow ? 4 : 6}
              progress={(step / maxSteps) * 100}
              label={`${step} de ${maxSteps}`}
            />
            <div className="ml-4">
              <h3 className="text-primary">{title}</h3>
              <p>{subtitle}</p>
            </div>
          </div>
        </div>

        {contract && (
          <div className="pdf-viewer">
            <iframe
              title={i18n('RESERVATIONS__LEASING__STEPPER__TITLE')}
              src={contract}
              frameBorder="0"
            />
          </div>
        )}

        {step === 4 ? (
          <div className="row mt-4">
            <div className="col-6 col-lg-4 col-xl-2">
              <Button block outline onClick={handleOnReject}>
                {i18n('COMMONS__REJECT__TEXT')}
              </Button>
            </div>
            <div className="offset-3 offset-lg-6 offset-xl-8 col-6 col-lg-4 col-xl-2">
              <Button block onClick={handleOnAccept}>
                {i18n('COMMONS__ACCEPT__TEXT')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="row mt-4">
            <div className="col-2 offset-10">
              <Button block onClick={handleContinue}>
                {i18n('COMMONS__CONTINUE__TEXT')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

Leasing.propTypes = {
  maxSteps: PropTypes.number,
  step: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Leasing.defaultProps = {
  maxSteps: MAX_STEPS,
  step: 2,
  title: i18n('RESERVATIONS__LEASING__STEPPER__TITLE'),
  subtitle: i18n('RESERVATIONS__LEASING__STEPPER__SUBTITLE'),
};

export default withReservationAuth(withForm({ config })(Leasing));
