import types from './action-types';

export function reset(payload) {
  return { type: types.RESET, payload };
}

export function getContractBySearch(payload) {
  return { type: types.GET_CONTRACT_BY_SEARCH, payload };
}

export function getContractBySearchSucceed(payload) {
  return { type: types.GET_CONTRACT_BY_SEARCH_SUCCEED, payload };
}

export function getContractBySearchFailed(payload) {
  return { type: types.GET_CONTRACT_BY_SEARCH_FAILED, payload };
}

export function closeResponseDialog() {
  return { type: types.CLOSE_RESPONSE_DIALOG };
}

export function closeResponseDialogValidCode() {
  return { type: types.CLOSE_RESPONSE_DIALOG_VALID_CODE };
}

export function getContractDetails(payload) {
  return { type: types.GET_CONTRACT_DETAILS, payload };
}

export function getContractDetailsSucceed(payload) {
  return { type: types.GET_CONTRACT_DETAILS_SUCCEED, payload };
}

export function getContractDetailsFailed(payload) {
  return { type: types.GET_CONTRACT_DETAILS_FAILED, payload };
}

export function cleanContractDetails(payload) {
  return { type: types.CLEAN_CONTRACT_DETAILS, payload };
}

export function getMovementList(payload) {
  return { type: types.GET_CONTRACT_MOVEMENT_LIST, payload };
}

export function getMovementListSucceed(payload) {
  return { type: types.GET_CONTRACT_MOVEMENT_LIST_SUCCEED, payload };
}

export function getMovementListFailed(payload) {
  return { type: types.GET_CONTRACT_MOVEMENT_LIST_FAILED, payload };
}

export function setMovementListParams(payload) {
  return { type: types.SET_MOVEMENT_LIST_PARAMS, payload };
}

export function setMovementListPage(payload) {
  return { type: types.SET_MOVEMENT_LIST_PAGE, payload };
}

export const setMovementTypes = (payload) => ({
  type: types.SET_MOVEMENT_TYPES,
  payload,
});

export function savePayment(payload) {
  return { type: types.SAVE_PAYMENT, payload };
}

export function savePaymentSucceed(payload) {
  return { type: types.SAVE_PAYMENT_SUCCEED, payload };
}

export function savePaymentFailed(payload) {
  return { type: types.SAVE_PAYMENT_FAILED, payload };
}

export function validCodeFailed(payload) {
  return { type: types.VALID_CODE_FAILED, payload };
}

export function setCancelMovement(payload) {
  return { type: types.SET_CANCEL_MOVEMENT, payload };
}

export function setCancelMovementSuccess(payload) {
  return { type: types.SET_CANCEL_MOVEMENT_SUCCESS, payload };
}

export function setCancelMovementFailed(payload) {
  return { type: types.SET_CANCEL_MOVEMENT_FAILED, payload };
}
