import compose from 'recompose/compose';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import CompanyDataDetail from './CompanyData';

export default compose(
  connect((state) => {
    if (
      !_isEmpty(
        _get(state, 'customers.customerDetail.data.juridicalPerson', {})
      )
    )
      return {
        data: _get(state, 'customers.customerDetail.data.juridicalPerson', {}),
      };

    return {
      data: _get(state, 'customers.customerDetail.data.tender', {}),
    };
  }, {})
)(CompanyDataDetail);
