export const defaultDriverData = [
  {
    driverUuid: '',
    driverCurp: '',
    driverName: '',
    driverFirstName: '',
    driverLastName: '',
    driverBirthDate: '',
    driverPlaceBirth: '',
    driverBirthState: '',
    driverBirthCountry: '',
    driverGender: '',
    driverFrontLicense: '',
    driverBackLicense: '',
  },
];

export const initialTouched = {
  driverAuthorizedData: [
    {
      driverUuid: true,
      driverCurp: true,
      driverName: true,
      driverFirstName: true,
      driverLastName: true,
      driverBirthDate: true,
      driverPlaceBirth: true,
      driverBirthState: true,
      driverGender: true,
      driverFrontLicense: true,
      driverBackLicense: true,
    },
  ],
};

export const initialValues = {
  driverAuthorizedData: defaultDriverData,
};

export default {
  initialValues,
  initialTouched,
  defaultDriverData,
};

export const DRIVERS_INFORMATION_FIELDS = Object.keys(
  initialValues.driverAuthorizedData
);
