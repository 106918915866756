import { takeLatest } from 'redux-saga/effects';
import types from './action-types';
import {
  getLoansListSaga,
  saveLoanSaga,
  getLoanDetailsSaga,
  editLoanSaga,
  closeLoanSaga,
  cancelLoanSaga,
  authorizeLoanSaga,
} from './sagas';

export default function* watchers() {
  yield takeLatest(types.GET_LIST, getLoansListSaga);
  yield takeLatest(types.SAVE_LOAN, saveLoanSaga);
  yield takeLatest(types.GET_LOAN_DETAILS, getLoanDetailsSaga);
  yield takeLatest(types.EDIT_LOAN, editLoanSaga);
  yield takeLatest(types.CLOSE_LOAN, closeLoanSaga);
  yield takeLatest(types.CANCEL_LOAN, cancelLoanSaga);
  yield takeLatest(types.AUTHORIZE_LOAN, authorizeLoanSaga);
}
