import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from '@casanova/casanova-common';
import { getInvoiceDetails } from 'store/invoices/actions';
import SearchInvoice from './SearchInvoice';

export default withLayout(
  compose(
    connect(
      (state) => ({
        invoiceDetails: state.invoices.invoiceDetails.data,
        hasNotFound: state.invoices.invoiceDetails.hasNotFound,
      }),
      { getInvoiceDetails }
    )
  )(SearchInvoice)
);
