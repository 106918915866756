import React, { useEffect, useState, useCallback } from 'react';
import { Loader, ProgressIndicator } from '@casanova/casanova-common';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ExtendDetail from './steps/ExtendDetail';
import { progressColors, maxSteps, getProgressInfo } from './config';

const ExtendContract = ({
  step,
  loader = false,
  getReservationInfo,
  reservationDetails,
  extendContract,
  history,
}) => {
  const { uuid } = useParams();
  const { success: successReservationDetails = false } = reservationDetails;
  const { success: successExtendedContracts = false } = extendContract;
  const { title, subtitle } = getProgressInfo(step);

  useEffect(() => {
    if (uuid && !successReservationDetails && !successReservationDetails) {
      history.push('/daily-rent-contracts/');
    }
  }, [getReservationInfo, uuid]);

  return (
    <>
      <Loader
        show={
          !loader && (!successReservationDetails || !successExtendedContracts)
        }
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0 d-flex align-items-center">
            <ProgressIndicator
              size={120}
              stroke={progressColors}
              strokeWidth={6}
              progress={(step / maxSteps) * 100}
              label={`${step} de ${maxSteps}`}
            />
            <div className="ml-4">
              <h3 className="text-primary">{title}</h3>
              <p>{subtitle}</p>
            </div>
          </div>
        </div>
        <ExtendDetail
          onCancel={() => {
            history.push('/daily-rent-contracts/');
          }}
        />
      </div>
    </>
  );
};

export default ExtendContract;
