import * as yup from 'yup';
import i18n from '@i18n';
import moment from 'moment';
import { createExtendContract } from 'store/reservations/actions';

const schema = yup.object().shape({
  newDateEnd: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .test(
      'invalid-date',
      i18n('ERROR__COMMONS__REQUIRED_FIELD'),
      (newDateEnd) => {
        if (moment(newDateEnd).isValid()) return true;
      }
    ),
});

const config = {
  schema,
  initialTouched: {
    newDateEnd: true,
    reservationUuid: true,
    daysAdded: true,
    extendNumber: true,
  },
  initialValues: {
    newDateEnd: '',
    reservationUuid: '',
    daysAdded: '',
    extendNumber: '',
  },
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  submitForm: (values) => createExtendContract({ ...values }),
  onCancelPath: '/daily-rent-contracts/',
  btnSubmitLabel: 'Continuar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE EXTENSIÓN',
      message:
        'Una vez guardada el contrato será extendido\n¿Estás seguro que quieres continuar?',
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: `Se guardó la extensión con éxito.`,
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: 'Ir a contratos de renta diaria',
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: './',
    },
  },
};

export default config;
