import React, { useCallback } from 'react';
import { ProgressIndicator } from '@casanova/casanova-common';
import { getQuotePath } from 'utils/navigation';
import One from './steps/One';
import Two from './steps/Two';
import Three from './steps/Three';
import { progressColors } from './config';

const Quote = ({ quote, step, history }) => {
  const { title, subtitle } = quote;
  const maxSteps = 3;

  const handleOnCancel = useCallback(() => {
    if (step === 1) {
      const withValues = history.location.search.includes('withValues=true');
      if (!withValues) {
        window.location.reload();
        return;
      }
    }

    const cancelPath = `${getQuotePath('list')}?isCanceling=true`;
    history.push(cancelPath);
  }, [step]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0 d-flex align-items-center">
            <ProgressIndicator
              size={120}
              stroke={progressColors}
              strokeWidth={6}
              progress={(step / maxSteps) * 100}
              label={`${step} de ${maxSteps}`}
            />
            <div className="ml-4">
              <h3 className="text-primary">{title}</h3>
              <p>{subtitle}</p>
            </div>
          </div>
        </div>
        {step === 1 && <One onCancel={handleOnCancel} />}
        {step === 2 && <Two />}
        {step === 3 && <Three />}
      </div>
    </>
  );
};

export default Quote;
