import { call, put, select } from 'redux-saga/effects';
import _get from 'lodash/get';
import { showLoader, hideLoader } from 'store/commons/actions';
import {
  getTransfers,
  getTransfersFailed,
  getTransfersSucceed,
  saveTransferFailed,
  saveTransferSucceed,
  getTransferDetailFailed,
  getTransferDetailSucceed,
  authorizeTransferFailed,
  authorizeTransferSucceed,
  closeTransferFailed,
  closeTransferSucceed,
  cancelTransferFailed,
  cancelTransferSucceed,
  rejectTransferFailed,
  rejectTransferSucceed,
  editTransferFailed,
  editTransferSucceed,
} from 'store/transfers/actions';
import {
  getTransfersList,
  saveTransfer,
  getTransferDetail,
  authorizeTransfer,
  cancelTransfer,
  closeTransfer,
  rejectTransfer,
  editTransfer,
} from 'services/transfers';

function* getCurrentTransferUuid() {
  return yield select((state) => _get(state, 'transfers.detail.uuid', ''));
}

function* updateTransfersList() {
  const transfers = yield select((state) => _get(state, 'transfers.list', {}));
  const { params, filters, searchPattern } = transfers;
  return yield put(getTransfers({ params, filters, searchPattern }));
}

export function* getTransfersListSaga(action) {
  try {
    yield put(showLoader());
    const params = action.payload.params;
    for (let key in params) {
      if (params[key] === '') delete params[key];
    }
    const data = yield call(getTransfersList, action.payload);
    yield put(getTransfersSucceed(data));
  } catch (error) {
    yield put(getTransfersFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* saveTransferSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(saveTransfer, payload);
    yield put(saveTransferSucceed(data));
  } catch (error) {
    yield put(saveTransferFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* getTransferDetailSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(getTransferDetail, payload);
    yield put(getTransferDetailSucceed(data));
  } catch (error) {
    yield put(getTransferDetailFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* authorizeTransferSaga({ payload }) {
  try {
    yield put(showLoader());
    const uuid = yield getCurrentTransferUuid();
    const data = yield call(authorizeTransfer, payload || uuid);
    yield updateTransfersList();
    yield put(authorizeTransferSucceed(data));
  } catch (error) {
    yield put(authorizeTransferFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* cancelTransferSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(cancelTransfer, payload);
    yield updateTransfersList();
    yield put(cancelTransferSucceed(data));
  } catch (error) {
    yield put(cancelTransferFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* closeTransferSaga({ payload }) {
  try {
    yield put(showLoader());
    const data = yield call(closeTransfer, payload);
    yield updateTransfersList();
    yield put(closeTransferSucceed(data));
  } catch (error) {
    yield put(closeTransferFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* rejectTransferSaga({ payload }) {
  try {
    yield put(showLoader());
    const uuid = yield getCurrentTransferUuid();
    const data = yield call(rejectTransfer, payload || uuid);
    yield updateTransfersList();
    yield put(rejectTransferSucceed(data));
  } catch (error) {
    yield put(rejectTransferFailed(error));
  } finally {
    yield put(hideLoader());
  }
}

export function* editTransferSaga({ payload }) {
  try {
    yield put(showLoader());
    const uuid = yield getCurrentTransferUuid();
    const data = yield call(editTransfer, payload, uuid);
    yield put(editTransferSucceed(data));
  } catch (error) {
    yield put(editTransferFailed(error));
  } finally {
    yield put(hideLoader());
  }
}
