import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getReservationInfo } from 'store/reservations/actions';
import {
  closeResponseDialog,
  getTotalPay,
} from 'store/dailyRentContracts/actions';
import ViewPayDetail from './ViewPayDetail';

export default compose(
  connect(
    (state) => ({
      responseDialog: state.dailyRentContracts.responseDialog,
      loader: state.commons.loader,
      reservationDetails: state.reservations.reservationInfo,
      refundDetails: state.dailyRentContracts.refund,
    }),
    { closeResponseDialog, getReservationInfo, getTotalPay }
  )
)(ViewPayDetail);
