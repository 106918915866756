import React, { useMemo } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import {
  CardSection,
  TextField,
  FileViewer,
  UserInfo,
} from '@casanova/casanova-common';
import { getTextColor } from 'utils/customers';
import { ACCOMMODATION_TYPES } from '@customers/common/constants';

const { RENT } = ACCOMMODATION_TYPES;

export default function Rent() {
  const data = useSelector((state) =>
    _get(state, 'customers.customerDetail.data.accommodation', {})
  );

  const {
    documentStatus,
    accommodationType,
    statusColor,
    files,
  } = useMemo(() => {
    const currentType = _get(data, 'type');
    const status = _get(data, 'documentStatus.name', '');
    const getFiles = () => {
      if (!_isEmpty(data) && currentType) {
        return currentType.identifier === RENT
          ? [
              _get(data, 'employmentLetter.presignedUrl', undefined),
              _get(data, 'leaseContract.presignedUrl', undefined),
              _get(data, 'leaseIdentityDocument.presignedUrl', undefined),
            ]
          : [];
      }
      return undefined;
    };

    return {
      documentStatus: status,
      accommodationType: currentType,
      statusColor: getTextColor(status),
      files: getFiles(),
    };
  }, [data]);

  return (
    <CardSection status={statusColor} title="Hospedaje" upperCase>
      <div className="row">
        <div className="col-7">
          <FileViewer files={files} />
        </div>
        <div className="col-5">
          <TextField
            title="Estatus"
            status={statusColor}
            value={documentStatus.toUpperCase()}
          />
          <TextField title="Tipo de hospedaje" value={accommodationType.name} />

          <UserInfo {...data} />
        </div>
      </div>
    </CardSection>
  );
}
