import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ExtraChargesDetails } from '@reservations/sections';
import { getTotalPriceExtraCharges } from '@reservations/commons/utils';
import { getExtraChargesTransformer } from '@reservations/commons/transformers';
import './ExtraChargesInfo.scss';

export default function ExtraChargesInfo({
  verificationCheckList = {},
  setExtraCharges,
  extraCharges,
  reservationInfo = {},
}) {
  const { listOfExtraCharges = [], totalPrice = 0 } = extraCharges;

  useEffect(() => {
    if (listOfExtraCharges.length <= 0) {
      // eslint-disable-next-line no-shadow
      const { verificationGroups = [] } = verificationCheckList;
      const newListOfExtraCharges = getExtraChargesTransformer(
        verificationGroups
      );
      setExtraCharges({ listOfExtraCharges: newListOfExtraCharges });
    }
  }, [verificationCheckList]);

  useEffect(() => {
    if (totalPrice === 0) {
      const total = getTotalPriceExtraCharges({ listOfExtraCharges });
      setExtraCharges({ totalPrice: total });
    }
  }, [totalPrice, listOfExtraCharges]);

  return (
    <ExtraChargesDetails
      title="CARGOS EXTRAS"
      information
      listOfExtraCharges={listOfExtraCharges}
      reservationInfo={reservationInfo}
      withSection={false}
    />
  );
}

ExtraChargesInfo.propTypes = {
  extraCharges: PropTypes.shape({
    listOfExtraCharges: PropTypes.array,
    totalPrice: PropTypes.number,
  }).isRequired,
  setExtraCharges: PropTypes.func,
  reservationInfo: PropTypes.shape({
    priceQuote: PropTypes.shape({
      selectedVehicleLinePrice: PropTypes.shape({
        amount: PropTypes.number,
      }),
    }),
    naturalPerson: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }).isRequired,
  verificationCheckList: PropTypes.shape({
    verificationGroups: PropTypes.array,
  }).isRequired,
};
