import {
  VIEW_VEHICLE_SINISTER,
  CREATE_VEHICLE_SINISTER,
} from 'utils/roles/permissions';
import Siniester from './Siniester';
import { withConnectedAdditional } from '../AdditionalCollapse';

export default withConnectedAdditional({
  dependsOnElement: 'policyOptions',
  identifierForView: VIEW_VEHICLE_SINISTER,
  identifierForAdd: CREATE_VEHICLE_SINISTER,
})(Siniester);
