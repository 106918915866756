export const contractTransformer = ({ vehicles, ...contractData }) => ({
  ...contractData,
  vehicles: vehicles.map((vehicle) => ({
    ...vehicle,
    uuid: vehicle.vehicleUuid,
  })),
});

export const transformContractFilters = (data) => {
  const { businessAreas } = data;
  return {
    ...data,
    businessAreas: businessAreas.map((ba) => ({
      ...ba,
      value: ba.uuid,
      label: ba.name,
    })),
  };
};
