import React, { useEffect, useCallback, useRef } from 'react';
import { Formik, yupToFormErrors, validateYupSchema } from 'formik';
import _get from 'lodash/get';
import {
  MaskedInput as Input,
  FileInput,
  PlainInput,
  DatePickerV2 as DatePicker,
  Select,
  Switch,
  NumberInput,
  TextAreaInput,
} from '@casanova/casanova-common';
import * as validations from 'utils/validations/additionals';
import { circulationCard as circulationCardMeta } from 'services/vehicles/additionals';
import { alphaMask, onlyLettersAndNumbers } from 'utils/masks';
import PreventChange from 'components/PreventChange';
import UserInfo from 'components/UserInfo';

import { CREATE_VEHICLE_CIRCULATION_CARD } from 'utils/roles/permissions';
import { MINIMUM_DATE, MAXIMUM_DATE } from '@vehicles/common/constants';
import { TEXT_AREA_NOT_ALLOWED_CHARACTERS } from 'utils/validations';

import AdditionalWrapper from '@additionals/components/AdditionalModals';
import { withAdditionalCollapse } from '../AdditionalCollapse';
import { SelectWithOptions } from '../components';

function CirculationCard(props) {
  const {
    addNew,
    data,
    editable,
    fetchCatalog,
    getAdditional,
    name,
    isNew,
    vehicleId,
    plateOptions,
    saveAdditional,
    vehicle,
    show,
    selectCardClass,
  } = props;
  const formRef = useRef(null);
  useEffect(() => {
    if (!show && formRef) {
      formRef.current.resetForm(circulationCardMeta.empty);
    }
  }, [show]);

  useEffect(() => {
    if (vehicleId) {
      getAdditional({ name, data: vehicleId, withFiles: ['receipt', 'card'] });
      fetchCatalog('circulation-card-class');
      fetchCatalog('circulation-card-validity');
      fetchCatalog('circulation-card-type');
    }
  }, [name, fetchCatalog, vehicleId, getAdditional]);

  const handleFormik = useCallback(
    (values) => {
      const cardNumber = _get(data, 'folio');
      saveAdditional({
        name,
        data: {
          ...values,
          vehicleId,
          receipt: values.circulationCardReceipt,
          serialNumber: vehicle.serialNumber,
        },
        forceCreate: cardNumber && cardNumber !== values.cardNumber,
        addNew,
      });
    },
    [addNew, saveAdditional, name, vehicleId, data, vehicle]
  );

  const handleValidation = (values) => {
    try {
      validateYupSchema(values, validations.circulationCard, true, {
        vehicle,
      });
    } catch (error) {
      return yupToFormErrors(error);
    }
    return {};
  };

  useEffect(() => {
    if (data && data.cardClass) {
      selectCardClass({ target: { value: data.cardClass } });
    }
  }, [data, selectCardClass]);

  return (
    <div className="container-fluid">
      <Formik
        initialValues={
          addNew ? circulationCardMeta.empty : data || circulationCardMeta.empty
        }
        validate={handleValidation}
        validateOnChange={false}
        enableReinitialize
        onSubmit={handleFormik}
        innerRef={formRef}
      >
        {(formikProps) => {
          const {
            values,
            initialValues,
            handleSubmit,
            dirty,
            setFieldValue,
          } = formikProps;

          const handleClassCardChange = (e) => {
            setFieldValue('type', undefined);
            selectCardClass(e);
          };

          return (
            <PreventChange preventChange={dirty && editable}>
              <AdditionalWrapper
                roleVerifierIdentifier={CREATE_VEHICLE_CIRCULATION_CARD}
                handleSubmit={handleSubmit}
                formikProps={formikProps}
                emptyValues={circulationCardMeta.empty}
                extraProps={props}
              >
                <div className="row">
                  {!isNew && !addNew && (
                    <div className="col-12 col-md-4">
                      <PlainInput
                        name="cardStatus"
                        disabled
                        label="Estatus"
                        limit={10}
                      />
                    </div>
                  )}
                  <div className="col-12 col-md-4">
                    <Select
                      name="plate"
                      label="Número de Placa*"
                      options={plateOptions}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="cardClass"
                      label="Clase*"
                      onChange={handleClassCardChange}
                      options="catalogs.circulationCardClass"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="type"
                      label="Tipo*"
                      options="catalogs.circulationCardClassSelected"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Input
                      mask={onlyLettersAndNumbers(30)}
                      maskPlaceholder=""
                      name="folio"
                      label="Folio*"
                      placeholder="Folio*"
                      whitespace={false}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <DatePicker
                      name="issueDate"
                      label="Fecha de expedición*"
                      placeholder="Fecha de expedición*"
                      initialValue={initialValues.issueDate}
                      disabled={!editable && !isNew}
                      minimumDate={MINIMUM_DATE}
                      maximumDate={MAXIMUM_DATE}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="validity"
                      label="Vigencia*"
                      options="catalogs.circulationCardValidity"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <NumberInput
                      maskSuffix=" mxn"
                      decimals={2}
                      max={99_999.99}
                      name="price"
                      label="Importe Pagado*"
                      placeholder="Importe Pagado*"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FileInput
                      url={
                        !isNew ? values.circulationCardReceiptUrl : undefined
                      }
                      displayText={values.circulationCardReceipt}
                      type="file"
                      label="Comprobante de pago*"
                      placeholder="Comprobante de pago*"
                      name="circulationCardReceipt"
                      accept="application/pdf"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <FileInput
                      url={!isNew ? values.cardUrl : undefined}
                      displayText={values.card}
                      type="file"
                      label="Tarjeta de circulación*"
                      placeholder="Tarjeta de circulación*"
                      name="card"
                      accept="application/pdf"
                      disabled={!editable && !isNew}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4 switch-inverted">
                    <label htmlFor="certifiedCopy">
                      ¿Cuenta con copia certificada?
                    </label>
                    <Switch
                      name="certifiedCopy"
                      disabled={!editable && !isNew}
                    />
                  </div>
                </div>
                {values.certifiedCopy && (
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <Input
                        name="certifiedCopy1Folio"
                        label="Folio de copia 1*"
                        placeholder="Folio de copia 1*"
                        mask={alphaMask(30)}
                        maskPlaceholder=""
                        disabled={!editable && !isNew}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <Input
                        name="certifiedCopy2Folio"
                        label="Folio de copia 2*"
                        placeholder="Folio de copia 2*"
                        mask={alphaMask(30)}
                        maskPlaceholder=""
                        disabled={!editable && !isNew}
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <DatePicker
                        name="certifiedCopyDate"
                        label="Fecha de certificación*"
                        placeholder="Fecha de certificación*"
                        initialValue={values.certifiedCopyDate}
                        disabled={!editable && !isNew}
                        minimumDate={MINIMUM_DATE}
                        maximumDate={MAXIMUM_DATE}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12 col-md-4 switch-inverted">
                    <label htmlFor="lost">
                      ¿La tarjeta se encuentra extraviada?
                    </label>
                    <Switch name="lost" disabled={!editable && !isNew} />
                  </div>
                </div>
                {values.lost && (
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <DatePicker
                        name="dateLost"
                        label="Fecha de extravío*"
                        placeholder="Fecha de extravío*"
                        initialValue={initialValues.dateLost}
                        disabled={!editable && !isNew}
                        minimumDate={MINIMUM_DATE}
                        maximumDate={MAXIMUM_DATE}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    <TextAreaInput
                      label="Observaciones"
                      placeholder="Observaciones"
                      name="ccRemarks"
                      disabled={!editable && !isNew}
                      regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS}
                      maxLength={200}
                    />
                  </div>
                </div>
                <UserInfo {...data} />
              </AdditionalWrapper>
            </PreventChange>
          );
        }}
      </Formik>
    </div>
  );
}
export default withAdditionalCollapse({ title: 'Tarjeta de Circulación' })(
  CirculationCard
);
