import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import { useFormikContext } from 'formik';
import * as yup from 'yup';

import { MaskedInput as Input } from '@casanova/casanova-common';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants';

import { AddedElements } from '@reservations/components';
import { CUSTOM_EXTRA_CHARGE } from '@reservations/commons/constants';
import {
  extraChargeFieldName,
  extraChargeLabel,
  extraChargeName,
} from '@reservations/sections/DeliveryRefluxSteps/StepTwo/config';

import i18n from '@i18n';
import { transformForSelect } from 'utils/transformHelpers';

import CustomExtraChargeForm from '../CustomExtraChargeForm';
import { reduceWithoutExtraCharges } from './transformers';
import { InputSwitch } from 'primereact/inputswitch';

export default function ExtraCharges(props) {
  const {
    extraCharges = [],
    checkPointIsSet,
    selectedVerifyPoint = {},
    activeGroup = {},
    handleAddExtraCharge = _noop,
    handleAddCustomExtraCharge = _noop,
    handleDeleteCustomExtraCharge = _noop,
    setInitialValues,
    setInitialTouched,
    setSchema,
    completedCheckList,
    checked,
    setChecked,
    positionActiveGroup = false,
  } = props;

  const { applicableExtraCharges = [] } = activeGroup;
  const {
    customExtraCharges = [],
    selectedExtraCharges = [],
    uuid: verifyPointUuid,
  } = selectedVerifyPoint;

  const {
    initialValues = {},
    values,
    setValues,
    setFieldError,
  } = useFormikContext();

  useEffect(()=>{
    console.log(positionActiveGroup, 'position');
  },[])

  const extraChargesName = useMemo(
    () => extraChargeFieldName({ uuid: verifyPointUuid }),
    []
  );

  useEffect(() => {
    setInitialValues(
      () => {
        const newInitialValues = extraCharges.reduce(
          (acc, { uuid = '' }) => ({
            ...acc,
            [extraChargeFieldName({ uuid })]: '',
          }),
          {}
        );
        return {
          ...initialValues,
          ...newInitialValues,
          [extraChargesName]: '',
        };
      },
      () => {
        // TODO: Move this logic to with form hoc
        setValues(values);
      }
    );
  }, [extraCharges, values]);

  useEffect(() => {
    if (!extraChargesName) return;

    const hasExtraCharge =
      selectedExtraCharges.length === 0 && !completedCheckList;

    setSchema(
      (prevSchema) => ({
        ...prevSchema,
        [extraChargesName]: hasExtraCharge
          ? yup
              .string()

              .test(
                'select-extra-charges',
                'Es necesario seleccionar un cargo extra',
                () => selectedExtraCharges.length >= 0
              )
          : yup.string(),
      }),
      () => setInitialTouched((prev) => ({ ...prev, [extraChargesName]: true }))
    );
  }, [selectedExtraCharges, completedCheckList]);

  useEffect(
    () => () => {
      setSchema(
        (prev) => Object.entries(prev).reduce(reduceWithoutExtraCharges, {}),
        () => {
          let prevTouched = {};
          setInitialTouched(
            (prev) => {
              prevTouched = { ...prev };
              return Object.entries(prev).reduce(reduceWithoutExtraCharges, {});
            },
            () =>
              Object.entries(prevTouched)
                .filter(([key]) => key.includes('extra-charge_'))
                .forEach(([key]) => setFieldError(key, undefined))
          );
        }
      );
    },
    []
  );

  return (
    <>
      {extraCharges.length > 0 ? (
        extraCharges.map(
          ({ name = '', uuid = '', isCustom = false, price = 0 }) => {
            const fieldName = extraChargeFieldName({ uuid });

            if (initialValues[fieldName] === undefined) return null;

            return (
              <div key={uuid} className="col-12 m-0 p-0">
                <Input
                  label={extraChargeLabel({ isCustom })}
                  placeholder={extraChargeName({ name, price })}
                  name={fieldName}
                  disabled
                  showLabel
                />
              </div>
            );
          }
        )
      ) : (
        <>
          {!checkPointIsSet && initialValues[extraChargesName] !== undefined && (
            <>
              {/* Desactiva el switch en la primera parte de la devolucion */}
              {positionActiveGroup !== 1 && (
                <div style={{ display: 'flex', marginBottom: '5px', marginLeft: '5px' }}>
                  <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                  <label style={{ marginLeft: '20px' }}>Con Cargos Extra</label>
                </div>
              )}
              <AddedElements
                name={extraChargesName}
                placeholder={i18n('COMMONS__EXTRA_CHARGE')}
                options={transformForSelect(
                  applicableExtraCharges,
                  'uuid',
                  'name'
                )}
                viewOptions={{
                  list: applicableExtraCharges.concat(customExtraCharges),
                  fieldToView: 'name',
                  fieldToSearch: 'uuid',
                  label: '',
                }}
                onSelectedElemensChange={handleAddExtraCharge}
                onAddCustomElement={handleAddCustomExtraCharge}
                onDeleteCustomElement={handleDeleteCustomExtraCharge}
                elementToRenderChildren={CUSTOM_EXTRA_CHARGE.value}
                customElements={selectedExtraCharges}
                renderCustomElements
                validCustomAdd={customExtraCharges.length < 5}
                secondListOfCustomElements={customExtraCharges}
                transformerName={extraChargeName}
                transformerLabel={extraChargeLabel}
              >
                {(helpers) => <CustomExtraChargeForm {...helpers} {...props} />}
              </AddedElements>
            </>
          )}
        </>
      )}
    </>
  );
}

ExtraCharges.propTypes = {
  extraCharges: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      uuid: PropTypes.string,
      isCustom: PropTypes.bool,
      price: PropTypes.number,
      // checked: PropTypes.bool,
    })
  ),
  checkPointIsSet: PropTypes.bool,
  selectedVerifyPoint: PropTypes.shape({
    uuid: PropTypes.string,
    selectedExtraCharges: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          name: PropTypes.string,
          uuid: PropTypes.string,
        }),
      ])
    ),
    customExtraCharges: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        uuid: PropTypes.string,
      })
    ),
  }),
  activeGroup: PropTypes.shape({
    uuid: PropTypes.string,
    applicableExtraCharges: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        uuid: PropTypes.string,
      })
    ),
  }),
  handleAddExtraCharge: PropTypes.func,
  handleAddCustomExtraCharge: PropTypes.func,
  handleDeleteCustomExtraCharge: PropTypes.func,
  setInitialValues: PropTypes.func,
  setInitialTouched: PropTypes.func,
  setSchema: PropTypes.func,
  completedCheckList: PropTypes.bool,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
};
