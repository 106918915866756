import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import moment from 'moment';
import {
  timeZone,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/constants/index.js';

const formatterCustomerInfoCommon = (values) => ({
  birthDate: moment(values.birthDate).tz(timeZone).format(YYYY_MM_DD),
  cellphone: values.phoneNumber,
  email: values.email,
  landline: values.homeNumber,
  lastName: values.lastName,
  names: values.names,
  secondLastName: values.secondLastName,
  sexUuid: values.gender,
});

export const customerInfoFormatter = (values = {}) => ({
  ...formatterCustomerInfoCommon(values),
  birthStateUuid: values.birthPlace,
  curp: _get(values, 'curp', null),
});

export const foreignCustomerInfoFormatter = (values) => ({
  ...formatterCustomerInfoCommon(values),
  originCountry: values.originCountry,
});
