import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icons } from '@casanova/casanova-common';
import {
  SchedDate,
  SpecItem,
  SendReservationModal,
} from '@reservations/components';
import { SendQuote } from '@reservations/sections';
import withForm from 'hooks/withForm';
import { useFormikContext } from 'formik';
import { PreventChange } from 'components';
import Section from 'components/Section';
// eslint-disable-next-line import/no-named-default
import { default as Card } from 'components/CardSection';
import { preventChangeConfig } from '@reservations/commons/config';
import { number2mxn } from 'utils/currency';
import { capitalizeFirstChar } from 'utils/masks';
import { getQuotePath } from 'utils/navigation';
import config from './config';
import './Three.scss';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { AREA_OPTIONS } from '@reservations/commons/constants';
import icono_aire from './AIRE.png';

const StepThree = ({
  branches,
  setTitles,
  pickupDateTime,
  pickupBranch,
  returnDateTime,
  returnBranch,
  fetchCatalog,
  vehicleLinePrice,
  selectedCategoryName,
  getDiscounts,
  sendQuoteDialog,
  sendReservationDialog,
  successPath,
  summaryInfo,
  closeSendReservationDialog,
  reservationSendReservation,
  sendReservation,
  closeSendQuoteDialog,
  quoteId,
  getCustomerDetail,
  fullVehicle = {},
  customer,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const history = useHistory();

  useEffect(() => {
    if (!quoteId) {
      window.location.href = getQuotePath('one');
    }
    setTitles({
      title: 'Resumen de cotización',
      subtitle:
        'Muestra los detalles elegidos por el cliente para la cotización del vehículo en renta.',
    });
    fetchCatalog('branches');
    getDiscounts();
  }, [setTitles, fetchCatalog, getDiscounts, quoteId]);

  useEffect(() => {
    if (customer) {
      getCustomerDetail(customer);
    }
  }, [customer, getCustomerDetail]);

  const [selectedStartBranch, setSelectedStartBranch] = useState({});
  const [selectedEndBranch, setSelectedEndBranch] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showModifyData, setShowModifyData] = useState(false);
  const [area, setArea] = useState(AREA_OPTIONS.USER);

  const { email, cellphone } = values;
  const { success = false } = reservationSendReservation;

  if (!quoteId) {
    window.location.href = getQuotePath('one');
  }
  const currentPlan = fullVehicle.vehicleLinePrices.filter(
    (plan) => plan.uuid === vehicleLinePrice
  );
  const { name, price, period, includedCoveragePlan } = currentPlan[0];
  const amount = price / period;
  const {
    specs,
    vehicleLineName,
    vehicleBrandName,
    vehicleTypeName,
  } = fullVehicle;
  const {
    cylinder,
    loadCapacity,
    passengers,
    fuelType,
    seatType,
    transmissionType,
    doors,
    cargoBed
  } = specs;
  console.log(vehicleTypeName)
  useEffect(() => {
    if (branches && branches.length > 0) {
      setSelectedStartBranch(
        branches
          .filter((item) => item.uuid === pickupBranch)
          .reduce((_, current) => current)
      );

      setSelectedEndBranch(
        branches
          .filter((item) => item.uuid === returnBranch)
          .reduce((_, current) => current)
      );
    }
  }, [branches, pickupBranch, returnBranch]);

  useEffect(() => {
    setFieldValue('email', summaryInfo.email || '');
    setFieldValue('cellphone', summaryInfo.cellphone || '');
    setFieldValue('comment', summaryInfo.comment || '');
  }, [summaryInfo]);

  useEffect(() => {
    setShowSuccess(success);
  }, [success]);

  const avoidCapitalization = ["HB20", "MG", "MG5", "KIA", "HR-V", "CR-V", "GMC"];
  let vehicleTitle = ''

  if(vehicleLineName == 'HAVAL JOLION'){
    vehicleTitle = 'GWM Haval Jolion'
  } else if(vehicleLineName == 'SILVERADO 3500 HD'){
    vehicleTitle = 'Chevrolet Silverado 3500 HD'
  } else {
    vehicleTitle = `${avoidCapitalization.includes(vehicleBrandName) ? vehicleBrandName : capitalizeFirstChar(vehicleBrandName.toLowerCase())} ${
      avoidCapitalization.includes(vehicleLineName) ? vehicleLineName : capitalizeFirstChar(vehicleLineName.toLowerCase())
    }`;
  }

  const onCloseSendReservationModal = () => {
    closeSendReservationDialog();
    history.push(successPath);
  };

  const handleClickSubDescription = useCallback(() => {
    closeSendReservationDialog();
    setShowModifyData(true);
    setFieldValue('area', AREA_OPTIONS.EMAIL);
    setArea(AREA_OPTIONS.EMAIL);
  }, []);

  const handleAreaChange = useCallback(({ value }) => setArea(value), []);

  const handleCancelModifyData = () => {
    setShowModifyData(false);
    onCloseSendReservationModal();
  };

  const handleActionSuccess = () => {
    setShowSuccess(false);
    setShowModifyData(false);
    onCloseSendReservationModal();
  };

  const submit = useCallback(() => {
    setShowModifyData(false);
    closeSendReservationDialog();
    // Se quitan los espacios en blanco
    if (values.comment.trim().length === 0) {
      values.comment = 'n/a';
    }

    if (!values.cellphone && !values.email) {
      setFieldTouched('cellphone', true);
      setArea(AREA_OPTIONS.CELLPHONE);
      setFieldValue('area', AREA_OPTIONS.CELLPHONE);
      setShowModifyData(true);
      return;
    }
    handleSubmit(values);
  }, [values, setFieldTouched, sendReservationDialog]);

  const handleSubmit = (data) => {
    sendReservation(data);
  };

  if(transmissionType.join(" ") == "Automático Manual" || transmissionType.join(" ") == "Automático")
    transmissionType[0] = 'Automática'

  return (
    <PreventChange {...preventChangeConfig}>
      <SendReservationModal
        show={sendReservationDialog}
        showSuccess={showSuccess}
        showModifyData={showModifyData}
        email={email}
        cellphone={cellphone}
        area={area}
        onSubmit={submit}
        onActionSuccess={handleActionSuccess}
        onCancel={onCloseSendReservationModal}
        onCancelModifyData={handleCancelModifyData}
        onClickSubDescription={handleClickSubDescription}
        onAreaChange={handleAreaChange}
      />
      <SendQuote open={sendQuoteDialog} onClose={closeSendQuoteDialog} />
      <div className="StepThree">
        <div className="col-md-12">
          <hr className="mb-0" />
        </div>
        <div className="row">
          <div className="col-12 vehicle-features">
            <p>
              <span className="kind">
                {selectedCategoryName} / {vehicleTypeName}
              </span>
              <br />
              {(transmissionType.join(" ") == "Automática Manual" && vehicleTypeName == "Mediano") ? (
                <span className="title">{vehicleTitle} automático / manual o similar</span>
              ) : (transmissionType.join(" ") == "Automática Manual" && vehicleTypeName != "Mediano") ? (
                <span className="title">{vehicleTitle} automática / manual o similar</span>
              ) : transmissionType.join(" ") == "Manual" ? (
                <span className="title">{vehicleTitle} manual o similar</span>
              ) : (transmissionType.join(" ") == "Automática" && vehicleTypeName == "Mediano") ? (
                <span className="title">{vehicleTitle} automático o similar</span>
              ) : (transmissionType.join(" ") == "Automática" && vehicleTypeName != "Mediano") ? (
                <span className="title">{vehicleTitle} automática o similar</span>
              ) : (
                <span className="title">{vehicleTitle} o similar</span>
              )}
            </p>
            <div className="features">
            <div className="title">Características</div>
            <div className="feature-list">
              {/* <SpecItem feat="cilindros" specList={cylinder} /> */}
                  {loadCapacity == 0 ? (
                    <SpecItem feat="capacidad" specList={['N/A']} />
                  ) : (
                    <>
                      {loadCapacity > 100 ? (
                        <SpecItem feat="kg." specList={loadCapacity.map(element => Number(element).toFixed(0))} />
                      ) : (
                        <SpecItem feat="de capacidad" specList={loadCapacity.map(element => Number(element).toFixed(0))} />
                      )}
                    </>
                  )}
              
              <SpecItem feat="pasajeros" specList={passengers} />
              {selectedCategoryName == "Carga" && (
                <>
                  <SpecItem feat="" specList={fuelType} />
                  <SpecItem feat="medidas de la caja" specList={cargoBed} />
                </>
              )}
              {/* <SpecItem feat="" specList={seatType} /> */}
              {/* <SpecItem feat="puertas" specList={doors} /> */}
              <SpecItem feat="" specList={transmissionType} />
              <div className={`text ml-3`} style={{ display: 'flex', alignItems: 'center' }}>
                <img src={icono_aire} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                <span className={`text d-block`}>
                  A/C
                </span>
              </div>
            </div>
          </div>
          </div>
          <div className="grid grid-nogutter w-full">
            <div className="col">
              <div className="plan-features">
                <span className="coverage">
                  <Icons.GoldenShield width="1.25rem" height="1.5rem" />
                  Cuenta con un paquete de cobertura integrado BÁSICO
                </span>
                <ul className="plan-features-list">
                  {includedCoveragePlan.features.map((feature) => (
                    <li key={feature}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-1 py-5">
              <Divider layout="vertical" />
            </div>
            <div className="col-4 pr-4 py-5 flex flex-column justify-content-start">
              <label htmlFor="description">Observaciones</label>
              <InputTextarea
                value={values.comment}
                onChange={(e) => setFieldValue('comment', e.target.value)}
                className="w-full"
                rows="5"
                maxLength="500"
              />
            </div>
          </div>
        </div>
        <Section title="FECHAS Y PLAN">
          <Card noStatus noTitle>
            <div className="row">
              <div className="col-4 d-flex common-info-text flex-column align-content-center justify-content-center">
                <span className=" font-weight-bold">PLAN {name} MXN</span>
                <span className="common-muted-text text-lg">
                  {number2mxn(amount)} x día
                </span>
              </div>
              <div className="col-4">
                <SchedDate
                  className="mb-0"
                  date={pickupDateTime}
                  branch={selectedStartBranch.name}
                  title="Fecha de salida"
                />
              </div>
              <div className="col-4">
                <SchedDate
                  className="mb-0"
                  date={returnDateTime}
                  branch={selectedEndBranch.name}
                  title="Fecha de devolución"
                  end
                />
              </div>
            </div>
          </Card>
        </Section>
      </div>
    </PreventChange>
  );
};

StepThree.propTypes = {
  kind: PropTypes.string,
  size: PropTypes.string,
  brandName: PropTypes.string,
  lineName: PropTypes.string,
  image: PropTypes.string,
  pickupDateTime: PropTypes.string,
  pickupBranch: PropTypes.string,
  returnDateTimew: PropTypes.string,
  returnBranch: PropTypes.string,
  planName: PropTypes.string,
  amount: PropTypes.number,
  pricePerDay: PropTypes.number,
};

StepThree.defaultProps = {
  kind: 'Pasajeros',
  size: 'Compacto',
  brandName: 'Chevrolet',
  lineName: 'Aveo',
  pickupDateTime: '2021-01-03 10:29',
  pickupBranch: 'Tlalpan',
  returnDateTimew: '2021-01-05 10:29',
  returnBranch: 'Tlalpan',
  pricePerDay: 609,
  planName: 'Kilometraje libre',
};

export default withForm({ config })(StepThree);
