import React from 'react';
import { MiniIconCard } from '@casanova/casanova-common';
import _noop from 'lodash/noop';
import { isGeneralGroup } from '@reservations/commons/utils';

export default function ListOfVehicleParts({
  listOfGroups = [],
  activeGroup = {},
  vehicleInfo = {},
  stockInCurrentPoint,
  handleClickGroup = _noop,
  verifyValidGroup = _noop,
  getCustomListOfSelectedImages = _noop,
}) {
  return (
    <div className="row mt-4">
      <div className="col-12 VehicleParts__VehicleSections">
        <div className="VehicleParts__VehicleSections--container">
          {listOfGroups.map((verificationGroup) => {
            const { name = '', uuid = '', thumbnail = '' } = verificationGroup;
            const active = activeGroup.uuid === uuid;
            const inGeneralGroup = isGeneralGroup(verificationGroup);
            const validGroup =
              verifyValidGroup(verificationGroup) || inGeneralGroup;
            const validSelection = active || validGroup;
            const status =
              // eslint-disable-next-line no-nested-ternary
              active && !stockInCurrentPoint
                ? 'active'
                : validGroup
                ? 'inactive'
                : 'disabled';

            const renderIcon = () => {
              const firstImageOfGroup = getCustomListOfSelectedImages(
                verificationGroup
              );
              const image = inGeneralGroup ? vehicleInfo.image : thumbnail;
              return (
                <>
                  {(firstImageOfGroup || image) && (
                    <div className="mini-card__image-container">
                      <img
                        src={firstImageOfGroup || image}
                        alt={`${name}`}
                        className="mini-card__image"
                      />
                    </div>
                  )}
                </>
              );
            };
            return (
              <MiniIconCard
                key={uuid}
                containerStyle={`d-flex justify-content-center align-items-center bg-white mini-card mini-card__active-border--${status} ${
                  !validSelection || stockInCurrentPoint
                    ? 'disable-pointer'
                    : ''
                }`}
                classNameCustomSize={`mini-card__custom-container mini-card${
                  active ? '__active' : ''
                } d-flex justify-content-center`}
                classNameTitleSize="mini-card__title"
                title={name}
                Icon={renderIcon()}
                iconDomProps={{
                  onClick: () => {
                    if (validSelection && !stockInCurrentPoint) {
                      handleClickGroup(verificationGroup);
                    }
                  },
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
