import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import {
  MaskedInput as Input,
  FileInput,
  ConnectedSelect,
  Section,
  DatePickerV2,
} from '@casanova/casanova-common';
import { alphaMask } from '@casanova/casanova-common/lib/utils/masks';
import { acceptFiles } from 'utils/files';
import { EMISSION_AND_VALIDITY_VALIDATIONS } from '@customers/common/validations';
import { isSafari } from 'utils/browser';
import { FORM_ACTIONS } from 'utils/constants/formActions';

const { EMISSION, VALIDITY } = EMISSION_AND_VALIDITY_VALIDATIONS;

const PassportInformation = ({ action }) => {
  const { values, initialValues } = useFormikContext();

  const disabledInput = action === FORM_ACTIONS.VIEW;
  const showInputUrl = action !== FORM_ACTIONS.ADD;

  return (
    <Section title="PASAPORTE">
      <div className="row">
        <div className="col-4">
          <Input
            label="Número de pasaporte*"
            placeholder="Número de pasaporte*"
            name="passportKey"
            disabled={disabledInput}
            mask={alphaMask(20)}
            maskPlaceholder=""
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={
              initialValues.passportYearIssue || values.passportYearIssue
            }
            label="Año de Emisión*"
            placeholder="Año de Emisión*"
            name="passportYearIssue"
            disabled={disabledInput}
            minimumDate={EMISSION.MIN_DATE}
            maximumDate={EMISSION.MAX_DATE}
            formatDateValue
            placement={isSafari() ? 'bottom' : undefined}
          />
        </div>
        <div className="col-md-4">
          <DatePickerV2
            initialValue={
              initialValues.passportValidity || values.passportValidity
            }
            placeholder="Vigencia*"
            label="Vigencia*"
            name="passportValidity"
            disabled={disabledInput}
            minimumDate={VALIDITY.MIN_DATE}
            maximumDate={VALIDITY.MAX_DATE}
            formatDateValue
            placement={isSafari() ? 'bottom' : undefined}
          />
        </div>
        <div className="col-4">
          <ConnectedSelect
            label="PAÍS*"
            name="passportCountry"
            options="commons.countries"
            disabled={disabledInput}
          />
        </div>
        <div className="col-4">
          <FileInput
            url={showInputUrl ? values.passportCoverFrontUrl : undefined}
            displayText={values.passportCoverFront}
            type="file"
            label="CARATULA PASAPORTE*"
            placeholder="CARATULA PASAPORTE*"
            name="passportCoverFront"
            accept={acceptFiles}
            disabled={disabledInput}
          />
        </div>
        <div className="col-4">
          <FileInput
            url={showInputUrl ? values.passportCoverBackUrl : undefined}
            displayText={values.passportCoverBack}
            type="file"
            label="TRASERA PASAPORTE*"
            placeholder="TRASERA PASAPORTE*"
            name="passportCoverBack"
            accept={acceptFiles}
            disabled={disabledInput}
          />
        </div>
      </div>
    </Section>
  );
};

PassportInformation.propTypes = {
  action: PropTypes.string,
};

PassportInformation.defaultProps = {
  action: 'add',
};

export default PassportInformation;
