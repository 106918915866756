"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.subtract = exports.add = void 0;
var add = exports.add = function add(a, b) {
  return a + b;
};
var subtract = exports.subtract = function subtract(a, b) {
  return a - b;
};