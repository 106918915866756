import BoardIcon from '@casanova/casanova-common/lib/Icons/Board';
import { withReconcileNav } from 'containers/Breadcrumb';
import CustomerList from '@customers/CustomerList';
import { withRoleRestrictions } from 'utils/auth';
import { validateRolePermissions } from 'utils/roles';

export const catalogs = {
  moduleName: 'Catalogs',
  route: '/catalogs',
  label: 'Catálogos',
  icon: BoardIcon,
  show: true,
  main: 'all',
  children: {
    all: {
      route: '',
      label: '',
      component: withReconcileNav('catalogs', 'all')(CustomerList),
    },
  },
};
