/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Suspense,
  lazy,
} from 'react';
import _sortBy from 'lodash/sortBy';
import _isNumber from 'lodash/isNumber';
import _reverse from 'lodash/reverse';
import _camelCase from 'lodash/camelCase';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import i18n from '@i18n';
import _get from 'lodash/get';
import {
  TextAreaInput,
  Button,
  RadioGroupInput,
  Camera,
  Loader,
  Input,
  Icons,
} from '@casanova/casanova-common';
import { getExtraChargesTransformer } from '@reservations/commons/transformers';
import {
  SpecItem,
  SendFirmLinkModal,
  WaitingModal,
} from '@reservations/components';
import {
  getVerificationCheckListDeliveryReturn,
  isRejected,
  isGeneralGroup,
  getPrincipalFieldsGeneral,
  getCirculationFieldsGeneral,
  makeRemarkName,
} from '@reservations/commons/utils';
import {
  REMARK_TYPES,
  AREA_OPTIONS,
  SPECT_ITEM_TYPES,
  ADDITIONAL_FIELDS,
  CUSTOM_EXTRA_CHARGE,
  CUSTOM_EXTRA_CHARGE_TYPES,
  VEHICLE_DETAILS_LABEL,
  DELIVERY_RETURN_TYPES,
} from '@reservations/commons/constants';
import useReservation from 'hooks/useReservation';
import { replaceElement, transformForOptionBar } from 'utils/transformHelpers';
import { textAreaRegex } from 'utils/masks';
import { OPTIONS_BAR_TYPES } from 'utils/constants';
import {
  ExtraCharges,
  VehicleStatement,
  ListOfVehicleParts,
} from '../sections';
import {
  specItemProps,
  generalAdditionalFieldTransformer,
  generalOtherFieldsTransformer,
  addOverflowHiddenInContainer,
  getFistImageOfGroupTransformer,
  isSetedGroup,
  dataToSubmitVerifyPointTransformer,
  isCheckPointSet,
  isImageSet,
  remarkFieldName,
  additionalFieldName,
  verifyCompletedInfoPoint,
} from './config';
import { saveCheckPointFormatter } from './formatters';
import './DeliveryReturnStepTwo.scss';
import AddedElements from '../../../components/AddedElements';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { RESERVATION_STEP } from '../../../../../utils/constants/reservations';
import { debounce } from 'lodash'; // Importa la función debounce de lodash o utiliza tu propia implementación

const OptionsBar = lazy(() => import('components/OptionsBar'));
const ActionModal = lazy(() =>
  import('@casanova/casanova-common/lib/Modal/ActionModal')
);
const IcoCarOkIcon = lazy(() =>
  import('@casanova/casanova-common/lib/Icons/IcoCarOk')
);
const StatusWarningIcon = lazy(() =>
  import('@casanova/casanova-common/lib/Icons/StatusWarning')
);
const { RETURN, DELIVERY } = DELIVERY_RETURN_TYPES;

export default function DeliveryReturnStepTwo({
  history,
  reservationInfo,
  verificationCheckList,
  type,
  verifyCheckPointState,
  verifyCheckPoint,
  isCheckPointSubmit,
  setIsCheckPointSubmit,
  setSubmitData,
  setActualPosition,
  isCompleted,
  isSending,
  accepted,
  verifyCompletedCheckListState,
  verifySignaturesCompleted,
  cleanSendFormLinkFirm,
  sendFirmForm,
  match,
  setInitialValues,
  setInitialTouched,
  setSchema,
}) {
  const [activeReservationInfo, setActiveReservationInfo] = useState({});
  const [
    activeVerificationCheckList,
    setActiveVerificationCheckList,
  ] = useState({});
  const [listOfGroups, setListOfGroups] = useState([]);
  const [isExtraChargeHoursApplied, setisExtraChargeHoursApplied] = useState(
    false
  );
  const [
    extraHoursExtraChargeAmmount,
    setExtraHoursExtraChargeAmmount,
  ] = useState(0);
  const [activeGroup, setActiveGroup] = useState({});
  const [cameraShow, setCameraShow] = useState(true);
  const [showSelectedImage, setShowSelectedImage] = useState(true);
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showFirm, setShowFirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showModifyData, setShowModifyData] = useState(false);
  const [area, setArea] = useState(AREA_OPTIONS.USER);
  const [stockInCurrentPoint, setStockInCurrentPoint] = useState(false);
  const [hasSubmittedFirm, setHasSubmittedFirm] = useState(false);

  const [extraChargesModal, setExtraChargesModal] = useState(false);
  // const [withExtraCharges, setWithExtraCharges] = useState(false);
  const [toExtraCharges, setToExtraCharges] = useState(false);
  const [checked, setChecked] = useState(true);

  const { success = false } = sendFirmForm;
  const { actualPosition = 1 } = verifyCheckPointState;
  const { folio = '', naturalPerson = {} } = activeReservationInfo;
  const { email = '', cellphone = '' } = naturalPerson;
  const { verificationPoints = [] } = activeGroup;
  const { selectedVerifyPoint = {}, applicableExtraCharges = [] } = activeGroup;
  const { additionalFields = {}, extraCharges = [] } = selectedVerifyPoint;
  const [otherField, setOtherField] = useState('');
  const generalGroup = useMemo(() => isGeneralGroup(activeGroup), [
    activeGroup,
  ]);
  const { type: currentType = '' } = activeVerificationCheckList;
  const returnDateTime = _get(reservationInfo, 'priceQuote.returnDateTime', '');
  const period = _get(
    reservationInfo,
    'priceQuote.selectedVehicleLinePrice.vehicleLinePrice.period',
    0
  );
  const price = _get(
    reservationInfo,
    'priceQuote.selectedVehicleLinePrice.vehicleLinePrice.price',
    0
  );
  const priceEachDay = parseFloat(((price * 100) / period / 100).toFixed(2));

  const calculateExtraChargeByExtraHours = (fechaDevolucion, costoPorDia) => {
    const fechaDevolucionObj = new Date(fechaDevolucion);
    const fechaDevolucionRealObj = new Date();
    const minutosDiferencia =
      (fechaDevolucionRealObj - fechaDevolucionObj) / (1000 * 60);

    const diasCompletos =
      Math.floor(minutosDiferencia / (24 * 60)) > 0
        ? Math.floor(minutosDiferencia / (24 * 60))
        : 0;
    const minutosRestantes = minutosDiferencia % (24 * 60);

    const tarifas = [
      { tiempoTolerancia: 30, porcentaje: 10 },
      { tiempoTolerancia: 90, porcentaje: 20 },
      { tiempoTolerancia: 150, porcentaje: 30 },
      { tiempoTolerancia: 210, porcentaje: 0 },
    ];

    let montoExtra = 0;

    tarifas.forEach((tarifa) => {
      if (minutosRestantes > tarifa.tiempoTolerancia) {
        montoExtra = (costoPorDia * tarifa.porcentaje) / 100;
      }
    });

    return montoExtra;
  };

  useEffect(() => {
    setExtraHoursExtraChargeAmmount(
      calculateExtraChargeByExtraHours(returnDateTime, priceEachDay)
    );
  }, [returnDateTime, priceEachDay]);

  const isDelivery = useMemo(
    () => currentType === DELIVERY_RETURN_TYPES.DELIVERY,
    [currentType]
  );
  const checkPointIsSet = useMemo(
    () =>
      isCheckPointSet({
        ...selectedVerifyPoint,
        position: activeGroup.position,
      }),
    [selectedVerifyPoint, activeGroup]
  );
  const firmRejected = useMemo(() => isRejected(history), [history]);
  const inRefund = useMemo(() => type === RETURN, [type]);
  const remarkName = useMemo(() => makeRemarkName(selectedVerifyPoint), [
    selectedVerifyPoint,
  ]);

  const { vehicleInfo = {} } = useReservation();
  const {
    setFieldValue,
    handleSubmit,
    setFieldTouched,
    values,
    errors,
  } = useFormikContext();

  const handleFirmSubmit = useCallback(() => {
    setShowFirm(false);
    setShowModifyData(false);

    if (!values.cellphone && !values.email) {
      setFieldTouched('cellphone', true);
      setArea(AREA_OPTIONS.CELLPHONE);
      setShowModifyData(true);
      return;
    }

    setIsCheckPointSubmit(false);
    setHasSubmittedFirm(true);
  }, [setFieldTouched, values.cellphone, values.email]);

  useEffect(() => {
    if (hasSubmittedFirm && !isCheckPointSubmit) {
      handleSubmit();
      setHasSubmittedFirm(false);
    }
  }, [hasSubmittedFirm, isCheckPointSubmit, handleSubmit]);

  const handleCheckPointSubmit = () => {
    const dataOfVerifyPoints = verificationPoints
      .filter((verifyPoint) => !isCheckPointSet(verifyPoint))
      .map(dataToSubmitVerifyPointTransformer(generalGroup));

    const formatted = saveCheckPointFormatter(
      listOfGroups,
      actualPosition,
      dataOfVerifyPoints,
      activeGroup
    );

    if (
      isExtraChargeHoursApplied &&
      formatted.groupUuid === 'a9eb7e8a-7145-4fe7-acd8-2a954b271181'
    ) {
      if (
        formatted &&
        formatted.dataOfVerifyPoints &&
        formatted.dataOfVerifyPoints[0] &&
        formatted.dataOfVerifyPoints[0].checkPointBody
      ) {
        formatted.dataOfVerifyPoints[0].checkPointBody.customExtraCharges = [
          ...formatted.dataOfVerifyPoints[0].checkPointBody.customExtraCharges,
          {
            name: 'Cargo horas extras',
            price: `${extraHoursExtraChargeAmmount}`,
          },
        ];
      }
    }

    setSubmitData(formatted);
    verifyCheckPoint(formatted);
    setIsCheckPointSubmit(true);
  };

  const setNewVerificationCheckList = useCallback(
    (newVerificationList) => {
      const verificationListsWithNewList = replaceElement(
        activeReservationInfo.verificationChecklists,
        newVerificationList,
        'type',
        newVerificationList.type
      );

      const newActiveReservationInfo = {
        ...activeReservationInfo,
        verificationChecklists: verificationListsWithNewList,
      };

      setActiveVerificationCheckList(newVerificationList);
      setActiveReservationInfo(newActiveReservationInfo);
    },
    [activeReservationInfo]
  );

  const setNewListOfGroups = useCallback(
    (newListOfGroups) => {
      const verificationCheckListWithNewVerificationGroups = {
        ...activeVerificationCheckList,
        verificationGroups: newListOfGroups,
      };
      setListOfGroups(newListOfGroups);
      setNewVerificationCheckList(
        verificationCheckListWithNewVerificationGroups
      );
    },
    [activeVerificationCheckList, setNewVerificationCheckList]
  );

  const setNewActiveGroup = useCallback(
    (newActiveGroup) => {
      const listOfGroupsWithNewGroup = replaceElement(
        listOfGroups,
        newActiveGroup,
        'uuid',
        newActiveGroup.uuid
      );
      setActiveGroup(newActiveGroup);
      setNewListOfGroups(listOfGroupsWithNewGroup);
    },
    [listOfGroups, setNewListOfGroups]
  );

  const setCheckPoint = useCallback(
    (newVerifyPoint) => {
      const verificationPointsWithNewVerifyPoint = replaceElement(
        activeGroup.verificationPoints,
        newVerifyPoint,
        'uuid',
        newVerifyPoint.uuid
      );

      const formattedActiveGroup = {
        ...activeGroup,
        verificationPoints: verificationPointsWithNewVerifyPoint,
        selectedVerifyPoint: newVerifyPoint,
      };

      setNewActiveGroup(formattedActiveGroup);
    },
    [activeGroup, listOfGroups, setNewActiveGroup]
  );

  const changeCameraShow = useCallback(() => {
    setCameraShow(!cameraShow);
  }, [cameraShow]);

  const changeShowSelectedImg = useCallback(() => {
    setShowSelectedImage((showSelectedImage) => !showSelectedImage);
  }, []);

  const handleSendImage = useCallback(
    ({ actualImage, listOfSelectedImages = [] }) => {
      const verifyPointWithImages = {
        ...selectedVerifyPoint,
        listOfImages: listOfSelectedImages,
        actualImage,
      };
      setCheckPoint(verifyPointWithImages);
      changeShowSelectedImg();
    },
    [changeShowSelectedImg, setCheckPoint, selectedVerifyPoint]
  );

  const initialSetGroups = useCallback(
    (verifyGroups, customPosition = null) => {
      if (verifyGroups.length <= 0) return;
      const formatedListOfGroups = verifyGroups.map((verificationGroup) => {
        const {
          verificationPoints = [],
          selectedVerifyPoint = {},
        } = verificationGroup;

        const formatedVerificationPoints = verificationPoints.map(
          (verifyPoint) => {
            const {
              remarks = '',
              passed = false,
              customRemark = {
                remark: '',
                type: null,
              },
              additionalFields = {},
              actualImage = null,
              listOfImages = [],
              selectedExtraCharges = [],
              customExtraCharges = [],
              prevCustomExtraCharges = [],
              actualExtraCharge = '',
              infoSet = false,
            } = verifyPoint;

            if (remarks && !customRemark.remark) {
              setFieldValue(remarkFieldName(verifyPoint), remarks);
              customRemark.remark = remarks;
            }
            if (infoSet && customRemark.type === null) {
              customRemark.type = passed
                ? REMARK_TYPES.GOOD_STATE
                : REMARK_TYPES.DETAIL;
            }

            Object.entries(additionalFields).forEach(
              ([fieldKey, fieldValue]) => {
                const { value = '' } = fieldValue;
                const formattedValue = _isNumber(value) ? 1 : value;
                const name = additionalFieldName({
                  fieldUuid: verifyPoint.uuid,
                  fieldKey,
                });
                setFieldValue(name, `${formattedValue}`);
              }
            );

            return {
              ...verifyPoint,
              customRemark,
              listOfImages,
              actualImage,
              selectedExtraCharges,
              customExtraCharges,
              prevCustomExtraCharges,
              actualExtraCharge,
              additionalFields,
            };
          }
        );

        const applicableExtraCharges = verificationGroup.applicableExtraCharges
          .filter(
            (extraCharge) => extraCharge.uuid !== CUSTOM_EXTRA_CHARGE.uuid
          )
          .concat(CUSTOM_EXTRA_CHARGE);

        return {
          ...verificationGroup,
          applicableExtraCharges,
          verificationPoints: formatedVerificationPoints,
          selectedVerifyPoint: selectedVerifyPoint.uuid
            ? selectedVerifyPoint
            : formatedVerificationPoints[0] || {},
        };
      });

      let verifyGroupByCustomPosition = null;

      if (customPosition) {
        verifyGroupByCustomPosition = customPosition
          ? formatedListOfGroups.find(
              (group) => group.position === customPosition
            )
          : null;
      }

      const verifyGroupByPosition = actualPosition
        ? formatedListOfGroups.find(
            (group) => group.position === actualPosition
          )
        : null;

      const newActiveGroup = activeGroup.uuid
        ? activeGroup
        : formatedListOfGroups[0] || {};

      setListOfGroups(formatedListOfGroups);
      setActiveGroup(
        verifyGroupByCustomPosition || verifyGroupByPosition || newActiveGroup
      );
    },
    [setFieldValue, activeGroup, actualPosition, inRefund, reservationInfo]
  );

  const findCheckPoint = useCallback(
    (uuid) => {
      const checkPoint = activeGroup.verificationPoints.find(
        (verifyPoint) => verifyPoint.uuid === uuid
      );
      return checkPoint;
    },
    [activeGroup]
  );

  const debouncedHandleChangeRemark = useCallback(
    debounce((remark) => {
      const verifyPointWithRemark = {
        ...selectedVerifyPoint,
        customRemark: {
          ...selectedVerifyPoint.customRemark,
          remark,
        },
      };
      setCheckPoint(verifyPointWithRemark);
    }, 700),
    [activeGroup, selectedVerifyPoint, remarkName, setCheckPoint]
  );

  const handleChangeRemark = (remark) => {
    debouncedHandleChangeRemark(remark);
  };

  // const debouncedHandleChangeRemark = debounce((remark) => {
  //   console.log("remark-debounce: ", remark)
  //   const verifyPointWithRemark = {
  //     ...selectedVerifyPoint,
  //     customRemark: {
  //       ...selectedVerifyPoint.customRemark,
  //       remark,
  //     },
  //   };
  //   setCheckPoint(verifyPointWithRemark);
  // }, 500);

  // const handleChangeRemark = useCallback((remark) => {
  //   console.log("remark-changemark: ", remark)
  //   debouncedHandleChangeRemark(remark);
  // },[debouncedHandleChangeRemark, activeGroup, selectedVerifyPoint, remarkName, setCheckPoint]);

  // const handleChangeRemark = useCallback(
  //   (remark) => {
  //     console.log("remark: ", remark)
  //     const verifyPointWithRemark = {
  //       ...selectedVerifyPoint,
  //       customRemark: {
  //         ...selectedVerifyPoint.customRemark,
  //         remark,
  //       },
  //     };
  //     setCheckPoint(verifyPointWithRemark);
  //   },
  //   [activeGroup, selectedVerifyPoint, remarkName, setCheckPoint]
  // );

  const handleClickStatement = useCallback(
    (statementType) => {
      if (selectedVerifyPoint.infoSet) return;

      const checkPointWithNewType = {
        ...selectedVerifyPoint,
        customRemark: {
          ...selectedVerifyPoint.customRemark,
          type: statementType,
        },
        selectedExtraCharges:
          statementType === REMARK_TYPES.GOOD_STATE
            ? []
            : selectedVerifyPoint.selectedExtraCharges,
      };

      setCheckPoint(checkPointWithNewType);
    },
    [activeGroup, selectedVerifyPoint, setCheckPoint]
  );

  const handleChangeSelection = useCallback(
    ({ uuid }) => {
      const checkPoint = findCheckPoint(uuid);
      if (!checkPoint) return;

      const activeGroupWithSelectedPoint = {
        ...activeGroup,
        selectedVerifyPoint: checkPoint,
      };

      setNewActiveGroup(activeGroupWithSelectedPoint);
    },
    [selectedVerifyPoint, findCheckPoint, activeGroup, setNewActiveGroup]
  );

  const handleContinue = () => setShowFirm(true);

  const handleChangeAditionalField = useCallback(
    (additionalField) => {
      const { value, name, fieldKey = '' } = additionalField;
      setFieldValue(name, value);

      const field = selectedVerifyPoint.additionalFields[fieldKey];
      const additionalFieldWithNewValue = {
        ...field,
        value,
      };
      const formatedSelectedVerifyPoint = {
        ...selectedVerifyPoint,
      };
      formatedSelectedVerifyPoint.additionalFields[
        fieldKey
      ] = additionalFieldWithNewValue;

      setCheckPoint(formatedSelectedVerifyPoint);
    },
    [selectedVerifyPoint, setCheckPoint, setFieldValue]
  );

  const getLastItemActive = useCallback(
    (group, list) => {
      const reversedListOfGroups = list
        ? [...list].reverse()
        : [...listOfGroups].reverse();

      const beforeGroup = reversedListOfGroups.find(
        (reversedGroup) => reversedGroup.position === group.position - 1
      );

      if (!beforeGroup) return false;

      let activeBeforeGroup = true;

      beforeGroup.verificationPoints.forEach((verifyPoint) => {
        if (!isCheckPointSet(verifyPoint)) activeBeforeGroup = false;
      });
      return activeBeforeGroup;
    },
    [listOfGroups]
  );

  const verifyValidGroup = useCallback(
    (group = {}) => {
      let active = true;

      group.verificationPoints.map((checkPoint) => {
        if (!isCheckPointSet(checkPoint)) active = false;
        return isCheckPointSet(checkPoint);
      });

      if (!active) {
        return getLastItemActive(group);
      }

      return active;
    },
    [getLastItemActive]
  );

  const disabledSubmitPoint = useMemo(() => {
    const completedVerifiedPoints = verificationPoints.filter(
      verifyCompletedInfoPoint(activeVerificationCheckList, checked)
    );

    const allPointsCompleted =
      completedVerifiedPoints.length === verificationPoints.length;

    return stockInCurrentPoint || hasError || !allPointsCompleted;
  }, [
    hasError,
    verificationPoints,
    generalGroup,
    stockInCurrentPoint,
    checked,
  ]);

  const changeVerifyList = useCallback(
    (checkListType) => () => {
      if (stockInCurrentPoint) return;

      const newActiveVerificationCheckList = getVerificationCheckListDeliveryReturn(
        {
          reservationInfo: activeReservationInfo,
          checkListType,
          completeReservation: true,
        }
      );
      setNewVerificationCheckList(newActiveVerificationCheckList);
      initialSetGroups(newActiveVerificationCheckList.verificationGroups);
    },
    [activeReservationInfo, stockInCurrentPoint]
  );

  const verifyActiveList = useCallback(
    (verifyCheckListType) =>
      verifyCheckListType === activeVerificationCheckList.type,
    [activeVerificationCheckList]
  );

  const setNewValueAditionalField = useCallback(
    (fields) => {
      if (!selectedVerifyPoint.additionalFields || selectedVerifyPoint.infoSet)
        return;

      const checkPointWithNewField = {
        ...selectedVerifyPoint,
      };

      if (Array.isArray(fields)) {
        Object.values(fields).forEach(({ fieldKey = '', fieldValue = {} }) => {
          checkPointWithNewField.additionalFields = {
            ...checkPointWithNewField.additionalFields,
            [fieldKey]: fieldValue,
          };
        });
      } else {
        const { fieldKey = '', fieldValue = {} } = fields;
        checkPointWithNewField.additionalFields = {
          ...checkPointWithNewField.additionalFields,
          [fieldKey]: fieldValue,
        };
      }
      setCheckPoint(checkPointWithNewField);
      setHasError(false);
    },
    [selectedVerifyPoint, setCheckPoint]
  );

  const handleAddExtraCharge = useCallback(
    (newSelectedExtraCharges) => {
      const checkPointWithActualExtraCharge = {
        ...selectedVerifyPoint,
        selectedExtraCharges: newSelectedExtraCharges,
      };
      setCheckPoint(checkPointWithActualExtraCharge);
    },
    [selectedVerifyPoint, setCheckPoint]
  );

  const handleChangeCirculation = useCallback(
    (field) => {
      if (selectedVerifyPoint.infoSet) return;
      const {
        circulationCardCopy = {},
        circulationCardOriginal = {},
      } = selectedVerifyPoint.additionalFields;

      const isOriginal = field.fieldKey === 'circulationCardOriginal';
      const newAdditionalFields = [
        {
          fieldKey: 'circulationCardCopy',
          fieldValue: {
            ...circulationCardCopy,
            value: !isOriginal,
          },
        },
        {
          fieldKey: 'circulationCardOriginal',
          fieldValue: {
            ...circulationCardOriginal,
            value: isOriginal ? field.value : false,
          },
        },
      ];

      setNewValueAditionalField(newAdditionalFields);
    },
    [selectedVerifyPoint, setNewValueAditionalField]
  );

  const handleSignaturesContinue = () => {
    const path = `./${inRefund ? 'refund' : 'delivery'}-information-and-firm`;
    history.push(path);
  };

  const handleConfirmationContinue = () => {
    setConfirmationMessage(false);
    handleContinue();
  };

  const handleConfirmationCancel = useCallback(() => {
    setConfirmationMessage(false);
  }, []);

  const handleClickGroup = useCallback(
    (group) => {
      setActualPosition(group.position);
      setNewActiveGroup(group);
    },
    [setNewActiveGroup, setActualPosition]
  );

  const handleExtraChargeAccept = useCallback(() => {
    setToExtraCharges(true);
    handleSubmit();
  }, [handleSubmit]);

  const openConfirmationMessage = useCallback(() => {
    const withExtraCharges = checkWithExtraCharges(
      verificationCheckList.verificationGroups,
      activeReservationInfo.step
    );
    if (withExtraCharges) setExtraChargesModal(true);
    else setConfirmationMessage(true);
  }, [verificationCheckList, activeReservationInfo]);

  const checkWithExtraCharges = (verificationGroups = [], reservationStep) => {
    if (type !== RETURN) return false;
    const listExtraCharges = getExtraChargesTransformer(verificationGroups);
    const isExtraChargesStep = reservationStep !== RESERVATION_STEP.SIGN_RETURN;
    return listExtraCharges.length > 0 && isExtraChargesStep;
  };

  useEffect(() => {
    if (type === RETURN && toExtraCharges) {
      history.push('./extra-charges');
    }
  }, [accepted, toExtraCharges]);

  useEffect(() => {
    setActiveReservationInfo(reservationInfo);
  }, [reservationInfo]);

  useEffect(() => {
    setActiveVerificationCheckList(verificationCheckList);
  }, [verificationCheckList]);

  useEffect(() => {
    const { verificationGroups = [] } = verificationCheckList;

    let lastGroupActive = null;
    let initialPosition = null;

    const lastGroupOfList = verificationGroups[verificationGroups.length - 1];

    verificationGroups.forEach((group) => {
      const active = getLastItemActive(group, verificationGroups);
      if (active) lastGroupActive = group;
    });

    const validGroups = lastGroupActive && lastGroupOfList;
    if (validGroups) {
      const inLastGroup = lastGroupActive.position === lastGroupOfList.position;

      if (inLastGroup) {
        const notCompletedLastGroup = !isSetedGroup(lastGroupOfList);

        initialPosition = notCompletedLastGroup
          ? lastGroupOfList.position
          : null;
      } else {
        initialPosition = lastGroupActive.position;
      }
    }

    initialSetGroups(verificationGroups, initialPosition);
  }, [verificationCheckList]);

  useEffect(() => {
    setFieldValue('email', email);
    setFieldValue('cellphone', cellphone);
    setFieldValue('area', area);
    setFieldValue('match', match);
  }, [area, match, email, cellphone]);

  useEffect(() => {
    setShowSuccess(success);
  }, [success]);

  useEffect(() => {
    addOverflowHiddenInContainer();
    return () => {
      cleanSendFormLinkFirm();
      addOverflowHiddenInContainer('remove');
    };
  }, []);

  useEffect(() => {
    if (verifyCompletedCheckListState.data && type === DELIVERY) {
      handleSubmit();
    }
  }, [verifyCompletedCheckListState, handleSubmit]);

  const getCustomListOfSelectedImages = useCallback(
    (customGroup = null) => {
      if (customGroup) {
        const firsImageOfGroup = getFistImageOfGroupTransformer(customGroup);
        if (firsImageOfGroup) {
          return firsImageOfGroup;
        }
        return '';
      }
      const { images = [], listOfImages = [] } = selectedVerifyPoint;
      let validImages = true;

      if (images && images.length > 0 && validImages) {
        return images.map((image) => {
          if (image && image.presignedUrl) {
            return image.presignedUrl;
          }
          validImages = false;
          return '';
        });
      }

      return listOfImages;
    },
    [selectedVerifyPoint]
  );

  const handleCancelFirm = useCallback(() => {
    setShowFirm(false);
  }, []);

  const handleCancelModifyData = useCallback(() => {
    setShowModifyData(false);
    if (!showSuccess) {
      setShowFirm(true);
    } else {
      setShowFirm(false);
    }
  }, [showSuccess]);

  const handleClickSubDescription = useCallback(() => {
    setShowFirm(false);
    setShowModifyData(true);
  }, []);

  const handleAreaChange = useCallback(({ value }) => setArea(value), []);

  const handleActionSuccess = useCallback(() => {
    setSchema({}, () => {
      setShowSuccess(false);
      setShowModifyData(false);
      const { form = {} } = verificationCheckList;
      verifySignaturesCompleted(form.uuid);
    });
  }, [verificationCheckList]);

  useEffect(() => {
    const { form = {} } = verificationCheckList;
    if (form.uuid) {
      setFieldValue('formUuid', form.uuid);
    }
  }, [verificationCheckList]);

  const handleAddCustomExtraCharge = useCallback(
    (values) => {
      const { CUSTOM_EXTRA_CHARGE_REMARK } = CUSTOM_EXTRA_CHARGE_TYPES;

      const names = Object.entries(values).map((valueArray) => valueArray[0]);

      const orderNames = [...names].sort((ele) => {
        const remarkBase = `${CUSTOM_EXTRA_CHARGE_TYPES.BASE}${CUSTOM_EXTRA_CHARGE_REMARK}`;
        const isRemark = ele.includes(remarkBase);
        return isRemark ? -1 : 1;
      });

      const [extraChargeRemarkName, extraChargeAmountName] = orderNames;

      const name = values[extraChargeRemarkName];
      const price = values[extraChargeAmountName];

      const newCustomExtraCharge = {
        ...values,
        uuid: `${name}_${price}`,
        name,
        price,
        isCustom: true,
      };

      setCheckPoint({
        ...selectedVerifyPoint,
        customExtraCharges: selectedVerifyPoint.customExtraCharges.concat(
          newCustomExtraCharge
        ),
        selectedExtraCharges: selectedVerifyPoint.selectedExtraCharges.concat(
          newCustomExtraCharge.uuid
        ),
      });
    },
    [selectedVerifyPoint, setCheckPoint]
  );

  const handleDeleteCustomExtraCharge = useCallback(
    (customExtraCharges) => {
      setCheckPoint({ ...selectedVerifyPoint, customExtraCharges });
    },
    [selectedVerifyPoint, setCheckPoint]
  );

  const handleAddAdditionalField = () => {
    if (!otherField) return;
    const label = otherField;
    const body = { fieldKey: _camelCase(otherField) };

    body.fieldValue = {
      label,
      type: 'boolean',
      value: false,
      isAdditional: true,
    };

    setOtherField('');
    setNewValueAditionalField(body);
  };

  const handleChangeAdditionalField = (evt) => {
    const { value } = evt.target;
    setOtherField(value);
  };

  useEffect(() => {
    if (type === RETURN) {
      setConfirmationMessage(firmRejected);
    } else if (firmRejected) handleContinue();
  }, [firmRejected, type]);

  useEffect(() => {
    const hasValidErrors = Object.values(errors).some(Boolean);
    setHasError(hasValidErrors);
  }, [errors, setHasError]);

  const textAreaRender = () => (
    <TextAreaInput
      name={remarkName}
      placeholder={i18n('RESERVATIONS__CONTRACT__OBSERVATIONS_MODAL__TITLE')}
      className="textarea"
      regex={textAreaRegex}
      maxLength={200}
      onChangeWithRegex={handleChangeRemark}
      disabled={selectedVerifyPoint.infoSet}
    />
  );

  const renderExtraCharges =
    verifyActiveList(RETURN) &&
    applicableExtraCharges.length > 0 &&
    (selectedVerifyPoint.customRemark.type === REMARK_TYPES.DETAIL ||
      generalGroup);

  const helpers = {
    isReturn: inRefund,
    selectedVerifyPoint,
    listOfGroups,
    activeGroup,
    additionalFields,
    reservationInfo,
    stockInCurrentPoint,
    setHasError,
    setCheckPoint,
    setStockInCurrentPoint,
  };

  const listOfVehiclePartsProps = {
    ...helpers,
    vehicleInfo,
    handleClickGroup,
    verifyValidGroup,
    getCustomListOfSelectedImages,
  };

  const vehicleStatementProps = {
    ...helpers,
    type,
    inRefund,
    checkPointIsSet,
    remarkName,
    handleClickStatement,
    setNewValueAditionalField,
    textAreaRender,
    handleChangeAditionalField,
  };

  const extraChargesProps = {
    ...helpers,
    extraCharges,
    checkPointIsSet,
    selectedVerifyPoint,
    activeGroup,
    handleAddExtraCharge,
    handleAddCustomExtraCharge,
    handleDeleteCustomExtraCharge,
    setInitialValues,
    setInitialTouched,
    setSchema,
    completedCheckList: activeVerificationCheckList.completed,
    checked,
    setChecked,
    positionActiveGroup: activeGroup.position,
  };

  const circulationTypeOptions = useMemo(() => {
    const options = getCirculationFieldsGeneral(additionalFields)
      .map((circulationValues) =>
        generalAdditionalFieldTransformer(circulationValues, vehicleInfo)
      )
      .map(({ fieldKey, fieldValue }) => ({
        ...fieldValue,
        value:
          fieldValue.label === VEHICLE_DETAILS_LABEL.circulationCardOriginal,
        fieldKey,
      }));

    _reverse(options);

    return options;
  }, [additionalFields, vehicleInfo]);

  const circulationName = useMemo(
    () => (isDelivery ? 'deliveryCirculationType' : 'returnCirculationType'),
    [isDelivery]
  );

  useEffect(() => {
    let circulationTypeValue = null;

    const { additionalFields = {}, infoSet = false } = selectedVerifyPoint;
    const {
      circulationCardCopy = {},
      circulationCardOriginal = {},
    } = additionalFields;
    const { value: copyValue = false } = circulationCardCopy;
    const { value: originalValue = false } = circulationCardOriginal;

    if (infoSet) {
      circulationTypeValue = copyValue ? false : originalValue;
    }

    if (typeof circulationTypeValue === 'boolean') {
      setFieldValue(circulationName, circulationTypeValue);
    }
  }, [selectedVerifyPoint, circulationName]);

  const inDeliveryMode = verifyActiveList(DELIVERY);
  const selectModeClassName = classNames(
    'VehicleParts__Select-mode--rectangle',
    inDeliveryMode && 'VehicleParts__Select-mode--rectangle__primary-bg'
  );
  const selectModeShapeClassName = classNames(
    'shape',
    `shape--${inDeliveryMode ? 'inactive' : 'active'}`
  );
  const deliveryModeClassName = classNames(
    'btn delivery-button',
    `text-${inDeliveryMode ? 'white' : 'primary'}`,
    stockInCurrentPoint && 'disable-pointer'
  );
  const refundModeClassName = classNames(
    'btn refund-button',
    `text-${!inDeliveryMode ? 'white' : 'primary'}`,
    stockInCurrentPoint && 'disable-pointer'
  );
  const openWaiting = accepted === null && !isCompleted && isSending;
  const openFinally = accepted === null && isCompleted && !isSending;
  const openConfirmation = Boolean(inRefund && confirmationMessage);

  return (
    <Suspense fallback={<Loader show />}>
      {openWaiting && <WaitingModal open={openWaiting} />}
      {openFinally && (
        <ActionModal
          open={openFinally}
          actionLabel={i18n(
            inRefund
              ? 'RESERVATIONS__DELIVER_REFUND__FINALLY'
              : 'RESERVATIONS__DELIVERY__SIGNATURES_COMPLETE_MODAL__ACTION_LABEL'
          )}
          title={i18n(
            inRefund
              ? 'RESERVATIONS__REFUND__SIGNATURES_COMPLETE_MODAL__TITLE'
              : 'RESERVATIONS__DELIVERY__SIGNATURES_COMPLETE_MODAL__TITLE'
          )}
          message={i18n(
            inRefund
              ? 'RESERVATIONS__REFUND__SIGNATURES_COMPLETE_MODAL__BODY'
              : 'RESERVATIONS__DELIVERY__SIGNATURES_COMPLETE_MODAL__BODY'
          )}
          icon={<IcoCarOkIcon />}
          modalType="confirmation"
          onAction={handleSignaturesContinue}
        />
      )}
      <ActionModal
        open={extraChargesModal}
        title="CARGOS EXTRAS"
        message="La devolución generó cargos extras, procederemos con el cobro."
        actionLabel="Pagar"
        icon={<Icons.IcoCarOk />}
        modalType="confirmation"
        onAction={handleExtraChargeAccept}
      />
      {openConfirmation && (
        <ActionModal
          open={openConfirmation}
          title={i18n(
            'RESERVATIONS__REFUND__SIGNATURES_CONFIRMATION_MODAL__TITLE'
          )}
          actionLabel={i18n('COMMONS__YES__TEXT')}
          closeLabel={i18n('COMMONS__NO_BACK__TEXT')}
          message={i18n(
            'RESERVATIONS__REFUND__SIGNATURES_CONFIRMATION_MODAL__MESSAGE'
          )}
          icon={<StatusWarningIcon />}
          onAction={handleConfirmationContinue}
          onClose={handleConfirmationCancel}
        />
      )}
      <SendFirmLinkModal
        showFirm={showFirm}
        showSuccess={showSuccess}
        showModifyData={showModifyData}
        email={email}
        cellphone={cellphone}
        area={area}
        onSubmit={handleFirmSubmit}
        onActionSuccess={handleActionSuccess}
        onCancelFirm={handleCancelFirm}
        onCancelModifyData={handleCancelModifyData}
        onClickSubDescription={handleClickSubDescription}
        onAreaChange={handleAreaChange}
      />
      <div className="container-fluid VehicleParts">
        <div className="row">
          <div className="col-7">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 text-uppercase">
                  <h5>{`${vehicleInfo.category || ''} / ${
                    vehicleInfo.type || ''
                  }`}</h5>
                </div>
                <div className="col-12">
                  <h3>
                    {i18n('RESERVATIONS__DELIVERY__DETAIL_FOLIO', [folio])}
                  </h3>
                </div>
              </div>

              {inRefund && (
                <div className="row mt-2 mb-4 VehicleParts__Select-mode--container">
                  <div className="col-12">
                    <div className={selectModeClassName}>
                      <div className={selectModeShapeClassName} />
                      <button
                        className={deliveryModeClassName}
                        onClick={changeVerifyList(DELIVERY)}
                        type="button"
                        tabIndex="0"
                      >
                        <h6 className="m-0">
                          {i18n('RESERVATIONS__DELIVERY__DETAIL_TITLE')}
                        </h6>
                      </button>
                      <button
                        className={refundModeClassName}
                        onClick={changeVerifyList(RETURN)}
                        type="button"
                        tabIndex="0"
                      >
                        <h6 className="m-0">
                          {i18n('RESERVATIONS__REFUND__DETAIL_TITLE')}
                        </h6>
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {generalGroup && selectedVerifyPoint.uuid ? (
                <>
                  <div className="VehicleParts__VehicleView--container">
                    {vehicleInfo.image && (
                      <img
                        src={vehicleInfo.image}
                        alt={`${vehicleInfo.brand} - ${vehicleInfo.line}`}
                        className="VehicleParts__VehicleView--vehicle_image"
                      />
                    )}
                  </div>
                  <div className="row m-0">
                    <div className="col-12">{textAreaRender()}</div>
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-12 justify-content-center VehicleParts__VehicleView--container">
                    <Camera
                      show={cameraShow}
                      setShow={changeCameraShow}
                      onChangeShowSelected={changeShowSelectedImg}
                      onPermissionsError={changeShowSelectedImg}
                      showSelectedImage={showSelectedImage}
                      showCameraButton={!isImageSet(selectedVerifyPoint)}
                      allScreen={!showSelectedImage}
                      onSend={handleSendImage}
                      customListOfImages={selectedVerifyPoint.listOfImages}
                      customListOfSelectedImages={getCustomListOfSelectedImages()}
                      customImage={selectedVerifyPoint.actualImage}
                      onlyCustomImage
                    >
                      <div className="row justify-content-center VehicleParts__VehicleView--container">
                        {activeGroup.image && (
                          <img
                            src={activeGroup.image}
                            alt={`${vehicleInfo.brand} - ${vehicleInfo.line}`}
                            className="VehicleParts__VehicleView--vehicle_image"
                          />
                        )}
                      </div>
                    </Camera>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-5">
            <div className="row p-0 m-0">
              <div className="col-12 m- p-0 Rectangle__Container">
                <div className="Rectangle__Body text-white py-2 justify-content-start d-flex align-items-center text-overflow-ellipsis">
                  <h6 className="d-inline mx-1 my-0">
                    {i18n('RESERVATIONS__DELIVER_REFUND__CUSTOMER')}
                  </h6>
                  <h6 className="font-weight-normal d-inline ml-2 my-0 text-overflow-ellipsis">
                    {naturalPerson.fullName}
                  </h6>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <h4 className="text-primary">
                  {activeGroup?.title || activeGroup?.name}
                </h4>
                <p className="text-muted">{activeGroup.description}</p>
              </div>
            </div>
            <div className="Forms__Container">
              {generalGroup ? (
                <div className="row">
                  {getPrincipalFieldsGeneral(additionalFields || {}, true)
                    .map((principalValues) =>
                      generalAdditionalFieldTransformer(
                        principalValues,
                        vehicleInfo
                      )
                    )
                    .map(({ fieldKey, fieldValue }, index, list) => {
                      const {
                        value = false,
                        label = '',
                        labelValue = '',
                      } = fieldValue;
                      const inVehicle = fieldKey === ADDITIONAL_FIELDS.VEHICLE;

                      if (!inVehicle && !label) return null;

                      const specLabel = { label, value: labelValue };
                      const specItem = inVehicle
                        ? vehicleInfo.vehicleTitle
                        : specLabel;

                      return (
                        <>
                          {!fieldValue.isTool && !fieldValue.isAdditional && (
                            <div key={fieldKey} className="col-12 my-2">
                              <SpecItem
                                {...specItemProps}
                                className={`VehicleParts__General--item ${
                                  selectedVerifyPoint.infoSet ? '' : 'pointer'
                                }`}
                                textClassName="text-dark"
                                reverse
                                specList={[specItem]}
                                spectListType={
                                  inVehicle
                                    ? SPECT_ITEM_TYPES.DIVISION
                                    : SPECT_ITEM_TYPES.WITH_VALUE
                                }
                                check={value}
                                onClick={() =>
                                  setNewValueAditionalField({
                                    fieldKey,
                                    fieldValue: {
                                      ...fieldValue,
                                      value: !value,
                                    },
                                  })
                                }
                              />
                            </div>
                          )}
                          {index + 1 === list.length &&
                            list.some(
                              ({ fieldValue }) => fieldValue.isTool
                            ) && (
                              <>
                                <div key="tools" className="col-12 my-2">
                                  <label
                                    style={{ fontWeight: 700 }}
                                    className="mb-3"
                                  >
                                    Herramientas:
                                  </label>
                                  {list.map(({ fieldKey, fieldValue }) => {
                                    if (!fieldValue.isTool) {
                                      return;
                                    }

                                    const {
                                      value = false,
                                      label = '',
                                      labelValue = '',
                                    } = fieldValue;
                                    const inVehicle =
                                      fieldKey === ADDITIONAL_FIELDS.VEHICLE;

                                    if (!inVehicle && !label) return null;

                                    const specLabel = {
                                      label,
                                      value: labelValue,
                                    };
                                    const specItem = inVehicle
                                      ? vehicleInfo.vehicleTitle
                                      : specLabel;
                                    return (
                                      <div
                                        key={fieldKey}
                                        className="col-12 my-2 pr-0"
                                      >
                                        <SpecItem
                                          {...specItemProps}
                                          className={`VehicleParts__General--item ${
                                            selectedVerifyPoint.infoSet
                                              ? ''
                                              : 'pointer'
                                          }`}
                                          textClassName="text-dark"
                                          reverse
                                          specList={[specItem]}
                                          spectListType={
                                            inVehicle
                                              ? SPECT_ITEM_TYPES.DIVISION
                                              : SPECT_ITEM_TYPES.WITH_VALUE
                                          }
                                          check={value}
                                          onClick={() =>
                                            setNewValueAditionalField({
                                              fieldKey,
                                              fieldValue: {
                                                ...fieldValue,
                                                value: !value,
                                              },
                                            })
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                {/* Campos adicionales se colocan al final de herramientas */}
                                {list.map(({ fieldKey, fieldValue }) => {
                                  if (!fieldValue.isAdditional) {
                                    return;
                                  }

                                  const {
                                    value = false,
                                    label = '',
                                    labelValue = '',
                                  } = fieldValue;
                                  const inVehicle =
                                    fieldKey === ADDITIONAL_FIELDS.VEHICLE;

                                  if (!inVehicle && !label) return null;

                                  const specLabel = {
                                    label,
                                    value: labelValue,
                                  };
                                  const specItem = inVehicle
                                    ? vehicleInfo.vehicleTitle
                                    : specLabel;
                                  return (
                                    <div key={fieldKey} className="col-12 my-2">
                                      <SpecItem
                                        {...specItemProps}
                                        className={`VehicleParts__General--item ${
                                          selectedVerifyPoint.infoSet
                                            ? ''
                                            : 'pointer'
                                        }`}
                                        textClassName="text-dark"
                                        reverse
                                        specList={[specItem]}
                                        spectListType={
                                          inVehicle
                                            ? SPECT_ITEM_TYPES.DIVISION
                                            : SPECT_ITEM_TYPES.WITH_VALUE
                                        }
                                        check={value}
                                        onClick={() =>
                                          setNewValueAditionalField({
                                            fieldKey,
                                            fieldValue: {
                                              ...fieldValue,
                                              value: !value,
                                            },
                                          })
                                        }
                                      />
                                    </div>
                                  );
                                })}
                                {/* Fin de los campos adicionales */}
                              </>
                            )}
                        </>
                      );
                    })}
                  {!inRefund && (
                    <div className="col-12">
                      <div className="row m-0 p-0 align-items-end">
                        <div className="col p-0 pr-2">
                          <Input
                            label="Otros"
                            name="otherField"
                            showLabel
                            onChange={handleChangeAdditionalField}
                            disabled={selectedVerifyPoint.infoSet}
                            value={otherField}
                          />
                        </div>
                        {!selectedVerifyPoint.infoSet && (
                          <div className="col-2">
                            <button
                              className="w-100 btn btn-outline btn-plus-minus mb-3"
                              style={{ height: '3.4rem' }}
                              type="submit"
                              onClick={handleAddAdditionalField}
                            >
                              <span>+</span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                    <RadioGroupInput
                      groupClass="col-12 d-flex VehicleParts__General--RadioGroup"
                      labelClass="d-flex align-items-center font-weight-bold"
                      parentClass="vehicle-radio-input"
                      inputContainerClass="VehicleParts__General--RadioGroup__input m-auto "
                      label="Tarjeta de circulación"
                      name={circulationName}
                      options={circulationTypeOptions}
                      onChange={handleChangeCirculation}
                      standardError
                      disabled={selectedVerifyPoint.infoSet}
                    />
                  </div>
                </div>
              ) : (
                <div className="container-fluid py-1 px-0">
                  {verificationPoints.length > 1 && (
                    <div className="row">
                      <div className="col-12">
                        <OptionsBar
                          onChangeSelection={handleChangeSelection}
                          name="verificationPointsOptionsBar"
                          options={transformForOptionBar({
                            elements: _sortBy(
                              verificationPoints,
                              (o) => o.position
                            ),
                            value: 'uuid',
                            label: 'name',
                            sub: 'title',
                            verifyCheck: verifyCompletedInfoPoint(
                              activeVerificationCheckList
                            ),
                          })}
                          valueToCompare={selectedVerifyPoint.uuid}
                          fullWidth
                          type={OPTIONS_BAR_TYPES.CHECK}
                          listItemClassName="px-1"
                          disabledSelections={stockInCurrentPoint}
                        />
                      </div>
                    </div>
                  )}
                  {selectedVerifyPoint.uuid &&
                    selectedVerifyPoint.customRemark && (
                      <VehicleStatement {...vehicleStatementProps} />
                    )}
                </div>
              )}
              <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0">
                  {renderExtraCharges && (
                    <>
                      {!isDelivery &&
                        selectedVerifyPoint.name === 'Datos generales' &&
                        !selectedVerifyPoint.infoSet &&
                        extraHoursExtraChargeAmmount > 0 && (
                          <div className="col-12 px-0">
                            <SpecItem
                              {...specItemProps}
                              className={`VehicleParts__General--item ${
                                selectedVerifyPoint.infoSet ? '' : 'pointer'
                              }`}
                              textClassName="text-dark"
                              reverse
                              specList={[
                                `Cargos por horas extra: ${parseFloat(
                                  extraHoursExtraChargeAmmount
                                ).toLocaleString('es-MX', {
                                  style: 'currency',
                                  currency: 'MXN',
                                })}`,
                              ]}
                              check={isExtraChargeHoursApplied}
                              onClick={() => {
                                if (
                                  !selectedVerifyPoint.additionalFields ||
                                  selectedVerifyPoint.infoSet
                                )
                                  return;
                                setisExtraChargeHoursApplied((prev) => !prev);
                              }}
                            />
                          </div>
                        )}
                      <ExtraCharges {...extraChargesProps} />
                      {/* <ExtraCharges
                        {...{
                          ...extraChargesProps,
                          switchProp: undefined,
                        }}
                      /> */}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col" />
              <div className="col-5">
                {activeVerificationCheckList.completed ? (
                  <>
                    {inRefund && activeVerificationCheckList.type === RETURN && (
                      <Button block onClick={openConfirmationMessage}>
                        {i18n('RESERVATIONS__REFUND__VEHICLE_PARTS__SUBMIT')}
                      </Button>
                    )}
                    {type === DELIVERY && (
                      <Button block onClick={handleContinue}>
                        {i18n('RESERVATIONS__DELIVERY__VEHICLE_PARTS__SUBMIT')}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {!checkPointIsSet && (
                      <Button
                        block
                        onClick={handleCheckPointSubmit}
                        disabled={disabledSubmitPoint}
                        type="submit"
                      >
                        {i18n('COMMONS__ACCEPT__TEXT')}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <ListOfVehicleParts {...listOfVehiclePartsProps} />
      </div>
    </Suspense>
  );
}
