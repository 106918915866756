export const RESERVATION_STEP = {
  CUSTOMER: 'CUSTOMER',
  CREATE_PAYMENT: 'CREATE_PAYMENT',
  UPLOAD_PAYMENT_FILES: 'UPLOAD_PAYMENT_FILES',
  VEHICLE_ASSIGNMENT: 'VEHICLE_ASSIGNMENT',
  CREATE_CONTRACT: 'CREATE_CONTRACT',
  SIGN_CONTRACT: 'SIGN_CONTRACT',
  DELIVERY: 'DELIVERY',
  SIGN_DELIVERY: 'SIGN_DELIVERY',
  RETURN: 'RETURN',
  SIGN_RETURN: 'SIGN_RETURN',
  CREATE_EXTRA_CHARGES_PAYMENT: 'CREATE_EXTRA_CHARGES_PAYMENT',
  UPLOAD_EXTRA_CHARGES_PAYMENT_FILES: 'UPLOAD_EXTRA_CHARGES_PAYMENT_FILES',
  END: 'END',
};
