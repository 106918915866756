import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  statusInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: -12,
    marginBottom: 10,
    paddingBottom: 5,
    paddingHorizontal: 6,
  },
  statusInfoText: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  totalAmount: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    padding: 15,
    marginTop: 5,
  },
  totalAmountText: {
    fontSize: 17,
    color: '#000000',
  },
});

export default styles;
