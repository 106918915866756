import _get from 'lodash/get';

export const transformer = (temporalChangeData) => {
  const transformedData = {
    ...temporalChangeData,
    reason: _get(temporalChangeData, 'reason.uuid', ''),
    incident: _get(temporalChangeData, 'incident.uuid', ''),
    vehicleA: _get(temporalChangeData, 'vehicleA', {}),
  };
  return transformedData;
};
