import Settings from '@casanova/casanova-common/lib/Icons/Settings';
import {
  WORKSHOP_MODULE,
  VIEW_WORKSHOP,
} from '../roles/permissions/workshopPermissions';
import { withRoleRestrictions } from 'utils/auth';
import { withReconcileNav } from 'containers/Breadcrumb';
import { validateRolePermissions } from 'utils/roles';
import WorkshopMain from '../../containers/Workshop/WorkshopMain';

export const workshop = {
  moduleName: WORKSHOP_MODULE,
  route: '/workshop',
  label: 'Talleres',
  icon: Settings,
  main: 'all',
  show: validateRolePermissions(VIEW_WORKSHOP),
  children: {
    all: {
      route: '',
      label: '',
      component: withReconcileNav(
        'workshop',
        'all'
      )(withRoleRestrictions(WorkshopMain)),
    },
  },
};
