import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { getQuotePath } from 'utils/navigation';
import { withLayout } from 'hooks';
import { DeliveryReturnStepTwo } from '@reservations/sections/DeliveryRefluxSteps';
import { config } from './config';

export default withLayout(
  compose(
    connect((state) => {
      const { naturalPerson = {} } = state.reservations.reservationInfo.data;
      return {
        loader: _get(state, 'commons.loader', false),
        onCancelPath: `${getQuotePath('one')}?isCanceling=true`,
        responseDialog: {
          ..._get(state, 'reservations.responseDialog', {}),
          modalType: 'action',
        },
        delivery: _get(state, 'reservations.delivery', false),
        leftCol: 4,
        middleCol: 4,
        config: config({
          cellphone: naturalPerson.cellphone,
        }),
      };
    }, {})
  )(DeliveryReturnStepTwo)
);
