import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  fetchCatalog,
  selectCardClass,
  fetchInstaller,
} from 'store/catalogs/actions';
import {
  getAdditional,
  saveAdditional,
  downloadAdditionalFile,
  addNewAdditional,
  cancelNewAdditional,
  closeAdditionalResult,
} from 'store/vehicles/actions';
import { Collapse } from '@casanova/casanova-common';
import { validateElement } from 'utils/roles';
import { useVehicle } from 'hooks';
import { AdditionalTitle } from './components';
import styles from './styles.module.css';

export function withAdditionalCollapse(moreTitleProps) {
  return (Component) => (props) => {
    const {
      name,
      addNew,
      data,
      onRequestClose,
      onClick,
      show,
      dependsOnElement,
      identifierForView,
      identifierForAdd,
      forceClose,
      customRef,
    } = props;
    const isNew = !(data && data.id);
    const [editable, setEditable] = useState(false);
    const [isForcedNew, setAddNew] = useState(false);
    const [isCancelModalVisible, showCancelModal] = useState(false);
    const [isCloseModalVisible, showCloseModal] = useState(false);

    const { isSold } = useVehicle();

    useEffect(() => {
      if (isNew || addNew) {
        setEditable(true);
      }
      return () => {
        if (editable) {
          setEditable(false);
        }
      };
    }, [isNew, addNew, editable]);

    const handleEdit = (next) => {
      setEditable(next);
    };

    const elementDepends = _get(props, dependsOnElement, []);
    const whenDependsOnElement =
      dependsOnElement && dependsOnElement !== '' && elementDepends.length <= 0;

    const canViewAdditional = validateElement(identifierForView);
    const canAddAdditional = validateElement(identifierForAdd);
    const renderAdditional = canAddAdditional || canViewAdditional;

    const isNewAndNotAdd = isNew && !canAddAdditional;
    const isNewAndDependsOnElement = isNew && whenDependsOnElement;
    const isNotNewAndNotView = !isNew && !canViewAdditional;

    const disabled =
      isSold && isNew
        ? true
        : isNewAndNotAdd || isNewAndDependsOnElement || isNotNewAndNotView;

    const titleProps = Object.assign(moreTitleProps, {
      isNew,
      show,
      editable,
      disabled,
      name,
    });

    return !renderAdditional ? null : (
      <div ref={customRef}>
        <Collapse
          name={name}
          title={AdditionalTitle}
          titleProps={titleProps}
          show={show}
          onClick={onClick}
          disabled={disabled}
          isNew={isNew}
          className={styles.vehicleAdditional}
        >
          <Component
            {...props}
            isNew={isNew}
            show={show}
            forceClose={forceClose}
            isForcedNew={isForcedNew}
            onForceNew={setAddNew}
            editable={editable}
            disabled={disabled}
            onEdit={handleEdit}
            isCancelModalVisible={isCancelModalVisible}
            onCancelModal={showCancelModal}
            isCloseModalVisible={isCloseModalVisible}
            onCloseModal={showCloseModal}
            onRequestClose={onRequestClose}
          />
        </Collapse>
      </div>
    );
  };
}

export function withConnectedAdditional(props) {
  return compose(
    connect(
      (state, { name }) => {
        const plate = _get(state, 'vehicles.additionals.plate.data');
        const policy = _get(state, 'vehicles.additionals.policy.data');
        const vehicle = _get(state, 'vehicles.vehicleData');
        const data = _get(state, `vehicles.additionals.${name}`);
        return {
          plateOptions: plate
            ? [{ label: plate.plateNumber, value: plate.id }]
            : [],
          policyOptions: policy
            ? [{ label: policy.policyNumber, value: policy.id }]
            : [],
          vehicle,
          ...data,
          ...props,
        };
      },
      {
        fetchCatalog,
        fetchInstaller,
        getAdditional,
        saveAdditional,
        addNewAdditional,
        cancelNewAdditional,
        downloadAdditionalFile,
        closeAdditionalResult,
        selectCardClass,
      }
    )
  );
}
