import { connect } from 'react-redux';
import compose from 'recompose/compose';
import _get from 'lodash/get';
import {
  searchVehicles,
  cleanSearchVehicles,
  closeResponseDialogVc,
} from 'store/vehicles/actions';
import Vehicles from './Vehicles';

export default compose(
  connect(
    (state) => ({
      onCancelPath: '/contracts',
      contractVehicles: _get(
        state,
        'contracts.contractDetails.data.vehicles',
        []
      ),
      vehiclesSearchList: state.vehicles.vehiclesByPlate.list,
      responseDialogVc: state.vehicles.vehiclesByPlate.responseDialog,
    }),
    { searchVehicles, cleanSearchVehicles, closeResponseDialogVc }
  )
)(Vehicles);
