import _get from 'lodash/get';

export const officialIdentificationTransformer = (
  officialIdentificationData = {}
) => ({
  identificationType: _get(
    officialIdentificationData,
    'identityDocumentType.uuid'
  ),
  identificationCode: officialIdentificationData.identityKey,
  emissionYear: `${officialIdentificationData.issuedYear}`,
  validity: `${officialIdentificationData.validityYear}`,
  identificationFrontSideUrl: _get(
    officialIdentificationData,
    'frontScanFile.presignedUrl'
  ),
  identificationFrontSide: _get(
    officialIdentificationData,
    'frontScanFile.originalName'
  ),
  identificationBackSideUrl: _get(
    officialIdentificationData,
    'backScanFile.presignedUrl'
  ),
  identificationBackSide: _get(
    officialIdentificationData,
    'backScanFile.originalName'
  ),
});
