import { connect } from 'react-redux';
import { closeResponseDialogUpdateCard } from 'store/reservations/actions';
import _get from 'lodash/get';
import ModifyCardModal from './ModifyCardModal';

export default connect(
  (state) => ({
    responseDialogUpdateCard: _get(
      state,
      'reservations.responseDialogUpdateCard',
      {}
    ),
  }),
  {
    closeResponseDialogUpdateCard,
  }
)(ModifyCardModal);
