/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { useFormikContext } from 'formik';
import {
  RadioGroupInput,
  MaskedInput as Input,
  FileInput,
  Switch,
  Icons,
  DatePickerV2,
  ConnectedSelect,
  NumberInput,
  Button,
  Loader,
  Modal,
} from '@casanova/casanova-common';
import { PaymentType } from '@reservations/components';
import {
  alphaMask,
  numericMask,
  hourMask,
  alphaMaskWithSpacesAndAccent,
  cardNumberValidations,
} from 'utils/masks';
import i18n from '@i18n';
import { BANK_CARD_TYPES } from 'utils/constants/bankCardTypes';
import { options } from '@casanova/casanova-common/lib/Loader/code';
import ClientCards from '../ClientCards';
import { Toast } from 'primereact/toast';

const CardMethod = ({
  name,
  setHasUpdatedHoldBack,
  onSelect,
  hasUpdateDifMethods,
  uuidCustomer,
  fetchCatalog,
}) => {
  const buttonGuardarBancoStyle = {
    backgroundColor: '#450E99',
    color: 'white',
    padding: '15px 32px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    marginTop: '10px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '4px',
  };

  const { values, setFieldValue } = useFormikContext();
  const { cardNumber, bank } = values;
  const [showModal, setShowModal] = useState();

  const [otroBanco, setOtroBanco] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');

  const cardTypes = useSelector((state) =>
    _get(state, 'commons.catalogsBankCardTypes.list', [])
  );

  const isCreditCard = useMemo(() => {
    const cardType = cardTypes.find((card) => card.uuid === values.cardType);
    return cardType && cardType.identifier === BANK_CARD_TYPES.CREDIT;
  }, [cardTypes, values.cardType]);

  const handleCardNumberChange = useCallback(
    (evt) => {
      if (isCreditCard) {
        setFieldValue('holdbackCard', evt.target.value);
        setHasUpdatedHoldBack(true);
      }
    },
    [isCreditCard]
  );

  useEffect(() => {
    if (isCreditCard) {
      setFieldValue('holdbackCard', cardNumber);
      setFieldValue('holdbackBank', bank);
      setHasUpdatedHoldBack(true);
    }
  }, [isCreditCard, cardNumber, bank]);

  const handleClickCharge = () => {
    setShowModal(true);
  };

  const handleCardsClose = () => {
    setShowModal(false);
  };

  const handleChangeBanco = (e) => {
    if (isCreditCard) {
      setFieldValue('holdbackBank', e.target.value);
      setHasUpdatedHoldBack(true);
    }
    setSelectedBank(e.target.value);
    if (e.target.value === 'c1f56866-0904-499b-a3fe-1b70a3e207b3') {
      setOtroBanco(true);
      return;
    }
    setOtroBanco(false);
  };

  const toast = useRef(null);
  const showToast = (detail, summary = 'Info', severity = 'info') => {
    toast.current.show({ severity, summary, detail });
  };

  const handleAddBank = async (newBank, options = {}) => {
    try {
      const headers = options.headers || new Headers();

      const { authToken } = options;
      if (authToken) {
        headers.append('Authorization', `Bearer ${authToken}`);
        headers.append('Content-Type', 'application/json');
      }
      headers.append('Content-Type', 'application/json');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/pricequotes/v1/catalogs/banks`,
        {
          method: 'POST',
          body: JSON.stringify(newBank),
          headers: headers,
          credentials: 'include',
        }
      );

      if (!response.ok) {
        throw new { code: '303', error: 'Network response was not ok' }();
      }

      const data = await response.json();
      fetchCatalog({
        catalogId: 'catalogs/banks',
        catalogPath: 'pricequotes',
      });

      setSelectedBank(data.uuid);
      setShowLoader(false);
      setFieldValue('holdbackOtherBankName', '');
      showToast(
        'Selecciona el banco que has agregado.',
        'Banco agregado',
        'info'
      );
      setFieldValue('holdbackBank', 'caadf013-a8ee-4904-b85c-a8b139562187');
      setOtroBanco(false);
    } catch (error) {
      console.log('error', error);
      setShowLoader(false);
      showToast(
        'El banco que intentas agregar ya existe o vuelve a intentarlo más tarde.',
        'Error',
        'error'
      );
    }
  };

  const handleSaveNewBank = () => {
    setShowLoader(true);
    const newBank = {
      name: values.holdbackOtherBankName,
      legalName: values.holdbackOtherBankName,
    };
    handleAddBank(newBank, options);
  };

  return (
    <>
      <Toast ref={toast} position="center" />
      {/* {showModal && ( */}
      <ClientCards
        show={showModal}
        onClose={handleCardsClose}
        uuidCustomer={uuidCustomer}
        setValues={setFieldValue}
      />
      {/* )} */}
      <PaymentType
        name={name}
        onSelect={onSelect}
        hasUpdateDifMetods={hasUpdateDifMethods}
        title="PAGO CON TARJETA DE CRÉDITO O DÉBITO"
        subtitle="Visa, MasterCard, American Express."
        noContainer
        right={
          <div className="d-flex flex-wrap mt-0">
            <div className="px-2 mr-3">
              <Icons.American width="3.125rem" height="3.125rem" />
            </div>
            <div className="px-2 mr-3">
              <Icons.Mastercard width="3.125rem" height="3.125rem" />
            </div>
            <div className="px-2">
              <Icons.Visa width="3.125rem" height="3.125rem" />
            </div>
          </div>
        }
      >
        {showLoader && <Loader show />}
        <div className="row">
          <div className="col-6 switch-inverted">
            <RadioGroupInput
              labelClass="d-flex align-items-center"
              groupClass="col-12 d-flex justify-content-between"
              parentClass="mb-0"
              label=""
              name="cardType"
              options={cardTypes}
            />
          </div>
          <div className="col-6 switch-inverted">
            <Button
              block
              className="PaymentButton mt-0 mr-1"
              onClick={handleClickCharge}
            >
              Seleccionar tarjeta
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ConnectedSelect
              label="Banco*"
              name="bank"
              value={selectedBank}
              options="commons.catalogsBanks"
              onChange={handleChangeBanco}
            />
          </div>
          {otroBanco && (
            <>
              <div className="col-md-6 col-sm-12">
                <Input
                  mask={alphaMaskWithSpacesAndAccent(40)}
                  maskPlaceholder=""
                  label="Otro Banco*"
                  placeholder="Ingresa nuevo banco*"
                  name="holdbackOtherBankName"
                  disabled={!otroBanco}
                />
              </div>
              <div className="col-md-6 col-sm-12 d-flex align-items-center">
                <Button
                  style={buttonGuardarBancoStyle}
                  onClick={() => handleSaveNewBank()}
                >
                  Guardar Banco
                </Button>
              </div>
            </>
          )}
          <div className="col-6">
            <Input
              mask={cardNumberValidations}
              maskPlaceholder="#### #### #### ####"
              label="No. de tarjeta*"
              placeholder="No. de tarjeta*"
              name="cardNumber"
              onChange={handleCardNumberChange}
            />
          </div>
          <div className="col-6">
            <Input
              mask={numericMask(30)}
              maskPlaceholder=""
              label="NO. DE REFERENCIA*"
              placeholder="NO. DE REFERENCIA*"
              name="reference"
            />
          </div>
          <div className="col-6">
            <Input
              mask={alphaMask(30)}
              maskPlaceholder=""
              label="AUTORIZACIÓN*"
              placeholder="AUTORIZACIÓN*"
              name="authorization"
            />
          </div>
          <div className="col-6">
            <DatePickerV2
              placeholder="Fecha*"
              name="paymentDate"
              label="Fecha*"
              disabled
            />
          </div>
          <div className="col-6">
            <Input
              mask={hourMask}
              maskPlaceholder=""
              label="Hora*"
              placeholder="Hora*"
              name="paymentTime"
              disabled
            />
          </div>
          <div className="col-6">
            <FileInput
              url={values.voucher}
              displayText={values.voucher}
              type="file"
              label="Comprobante*"
              placeholder="Comprobante*"
              name="voucher"
              showIcon={false}
            />
          </div>
          <div className="col-6">
            {hasUpdateDifMethods && (
              <NumberInput
                decimals={2}
                max={9_999_999}
                label="Monto en tarjeta"
                placeholder="Monto en tarjeta"
                name="amountCard"
                disabled={!hasUpdateDifMethods}
              />
            )}
          </div>
          <div className="col-md-8 switch-inverted">
            <label htmlFor="hasHolderName">
              {i18n('RESERVATIONS__PAYMENT__CARD__HOLDER')}
            </label>
            <Switch name="hasHolderName" />
          </div>
          {!values.hasHolderName && (
            <div className="col-md-6">
              <FileInput
                url={values.accountStatus}
                displayText={values.accountStatus}
                type="file"
                label="Comprobante de Titularidad*"
                placeholder="Comprobante de Titularidad*"
                name="accountStatus"
                showIcon={false}
              />
            </div>
          )}
        </div>
      </PaymentType>
    </>
  );
};

CardMethod.propTypes = {
  name: PropTypes.string,
  setHasUpdatedHoldBack: PropTypes.func,
  hasUpdateDifMethods: PropTypes.bool,
  onSelect: PropTypes.func,
  uuidCustomer: PropTypes.string,
};

CardMethod.defaultProps = {
  name: 'card',
};

export default CardMethod;
