import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Section } from '@casanova/casanova-common';
import { Invoice } from '@reservations/sections';
import ChargeCard from '@reservations/components/ChargeCard';

import { number2mxn } from 'utils/currency';
import { useReservation } from 'hooks';

import './ExtraChargesDetails.scss';

export default function ExtraChargesDetails({
  listOfExtraCharges = [],
  title = 'Cargos extras',
  showInformation = false,
  withSection = true,
  showSubtotal = true,
  fetchReservationInfo = false,
}) {
  const { reservationInfo = {} } = useReservation({ fetchReservationInfo });
  const { priceQuote = {} } = reservationInfo;
  const pricesExtraCharge = listOfExtraCharges.map((ec) => {
    return ec.price;
  });
  const totalExtraCharge =
    pricesExtraCharge.length > 0
      ? pricesExtraCharge?.reduce((total, p) => {
          return total + p;
        })
      : 0;
  const iva = (totalExtraCharge * 0.16) / 1.16;
  const subTotalExtraCharge = totalExtraCharge - iva;

  return (
    <Section title={title} uppercase>
      {listOfExtraCharges.map((extraCharge) => (
        <Fragment key={extraCharge.id}>
          {showInformation ? (
            <div className="row mt-4">
              <div className="col-2">
                {extraCharge.thumbnail && (
                  <img
                    src={`${extraCharge.thumbnail}`}
                    alt={`${extraCharge.groupName}`}
                    className="ExtraChargesDetails__Card--image"
                  />
                )}
              </div>
              <div className="col-6 d-flex flex-column justify-content-center">
                <h6 className="text-uppercase">{extraCharge.groupName}</h6>
                <p>{extraCharge.remarks || 'Sin comentarios'}</p>
              </div>
              <div className="col-4 d-flex flex-column justify-content-center text-right">
                <h6 className="text-muted">
                  MXN {number2mxn(extraCharge.price)}
                </h6>
                <p className="text-muted text-uppercase">{extraCharge.name}</p>
              </div>
            </div>
          ) : (
            <ChargeCard
              thumbnail={extraCharge.thumbnail}
              groupName={extraCharge.groupName}
              name={extraCharge.name}
              remarks={extraCharge.remarks}
              price={extraCharge.price}
            />
          )}
        </Fragment>
      ))}

      <Invoice
        title=""
        containerInvoiceClassName="ExtraChargesDetails__ContainerInvoice"
        detailInvoiceClassName="ExtraChargesDetails__detailInvoice"
        priceTitleClassName="ExtraChargesDetails__priceTitle"
        subtotal={subTotalExtraCharge}
        showDaysDifference={showInformation}
        showInvoiceSummaryData={showInformation}
        showExtraChargesInfo
        withSection={withSection}
        withDiscount={false}
        showRental={false}
        showSubtotal={showSubtotal}
      />
    </Section>
  );
}

ExtraChargesDetails.propTypes = {
  listOfExtraCharges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      price: PropTypes.number,
      groupName: PropTypes.string,
      remarks: PropTypes.string,
      thumbnail: PropTypes.string,
    })
  ),
  title: PropTypes.string,
  showInformation: PropTypes.bool,
  withSection: PropTypes.bool,
  showSubtotal: PropTypes.bool,
  fetchReservationInfo: PropTypes.bool,
};
