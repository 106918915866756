import { useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import { useSelector } from 'react-redux';
import { ActionModal, Icons } from '@casanova/casanova-common';

import i18n from '@i18n';

const withResponseDialog = (
  Component,
  { closeButton = true, hideWhenLoaderIsOpen = true } = {}
) => {
  function Wrapper(props) {
    const {
      responseDialog = {},
      resDialog = null,
      SuccessIcon = Icons.IcoCarOk,
      ErrorIcon = Icons.IcoCarError,
      messageBold = false,
      textBold = [],
      onSuccess = _noop,
      onError = _noop,
      onTryAgain,
      onClose = _noop,
      modalType = 'confirmation',
    } = props;

    const {
      open = false,
      success,
      errorCode,
      successMessage,
      errorMessage = i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successTitle,
      errorTitle = i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successLabel,
      errorLabel = i18n('COMMONS__TRY_AGAIN'),
    } = resDialog || responseDialog;

    const loader = useSelector((state) => state.commons.loader);

    const handleSuccess = useCallback(() => {
      onSuccess(props);
    }, [onSuccess, props]);

    const handleError = useCallback(() => {
      if (onTryAgain) onTryAgain(props);
      else onError(props);
    }, [onError, onTryAgain, props]);

    return (
      <>
        <ActionModal
          icon={success ? <SuccessIcon /> : <ErrorIcon />}
          open={hideWhenLoaderIsOpen ? open && !loader : open}
          success={success}
          title={success ? successTitle : errorTitle}
          actionLabel={success ? successLabel : errorLabel}
          message={success ? successMessage : errorMessage}
          errorCode={errorCode}
          closeButton={closeButton}
          textBold={textBold}
          messageBold={messageBold}
          onAction={success ? handleSuccess : handleError}
          onClose={onClose}
          modalType={modalType}
        />
        <Component {...props} />
      </>
    );
  }

  Wrapper.propTypes = {
    responseDialog: PropTypes.shape({
      open: PropTypes.bool,
      success: PropTypes.bool,
      errorCode: PropTypes.string,
      successMessage: PropTypes.string,
      errorMessage: PropTypes.string,
      successTitle: PropTypes.string,
      errorTitle: PropTypes.string,
      successLabel: PropTypes.string,
      errorLabel: PropTypes.string,
    }).isRequired,
    SuccessIcon: PropTypes.any,
    ErrorIcon: PropTypes.any,
    title: PropTypes.string,
    messageBold: PropTypes.bool,
    textBold: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onTryAgain: PropTypes.func,
    onClose: PropTypes.func,
    modalType: PropTypes.string,
  };

  return Wrapper;
};

export default withResponseDialog;
