import { connect } from 'react-redux';
import { login, resetLogin } from 'store/auth/actions';
import { Login } from '@casanova/casanova-common';

export default connect(
  (state) => ({
    loading: state.auth.loading,
    error: state.auth.error,
  }),
  { login, resetLogin }
)(Login);
