import { connect } from 'react-redux';
import { fetchCatalog, getTaxZipCode } from 'store/commons/actions';
import _get from 'lodash/get';
import compose from 'recompose/compose';
import InvoiceData from './InvoiceData';

export default compose(
  connect(
    (state) => ({
      taxZipCode: _get(state, 'commons.taxZipCodeSelected.state', []),
      dataLow: _get(state, 'commons.states.listwo', []),
      commons: state.commons,
    }),
    {
      getTaxZipCode,
      fetchCatalog,
    }
  )
)(InvoiceData);
