import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import {
  Icons,
  CardSection,
  TextField,
  FileViewer,
  UserInfo,
} from '@casanova/casanova-common';

import './InvoiceData.scss';

const InvoiceDataDetail = ({ data, fetchCatalog, cfdiUsesCatalog }) => {

  const files = !_isEmpty(data)
  ? [
      _get(data[0], 'taxSituationFile.presignedUrl', undefined),
      _get(data[1], 'taxSituationFile.presignedUrl', undefined),
      _get(data[2], 'taxSituationFile.presignedUrl', undefined),
    ]
  : undefined;

  useEffect(() => {
    console.log("DATA:",data)
    fetchCatalog({
      catalogId: 'catalogs/cfdi-uses',
      catalogPath: 'pricequotes',
    });
  }, [fetchCatalog]);

  return (
    <CardSection noStatus title="Datos de facturaci&oacute;n" upperCase>
      {data &&
        data.length > 0 &&
        data.map((billingElement, index) => {
          const cfdiUse = () => {
            const currentCfdiUse = cfdiUsesCatalog.find(
              (cfdi) => cfdi.uuid === billingElement.cfdiUse
            );

            return currentCfdiUse?.name || '';
          };

          return (
            <Fragment key={billingElement.uuid}>
              <div className="row invoice-row">
                
                {files[index] && (
                    <><div className="col-6">
                    <FileViewer files={[files[index]]} />
                  </div><div className="col-6">
                      <div className="row mb-6">
                        <TextField noMb title="Nombre o razón social" value={billingElement.name} />
                      </div>
                      <div className="row mb-6">
                        <TextField noMb title="RFC" value={billingElement.rfc} />
                      </div>
                      <div className="row mb-6">
                        <TextField noMb title="Uso de CFDI" value={cfdiUse()} />
                      </div>
                      <div className="row">
                        {billingElement.isPrimary && (
                          <div className="d-flex align-items-center extra-row">
                            <span className={`svg-icon ${true ? 'svg-icon--active' : ''}`}>
                              <Icons.CheckGroup width="100%" height="100%" />
                            </span>
                            <span className="text text--success">PREDETERMINADA</span>
                          </div>
                        )}
                      </div>
                    </div></>
                )}
                {!files[index] && (
                    <>
                    <div className="col-3">
                      <TextField
                        noMb
                        title="Nombre o razón social"
                        value={billingElement.name}
                      />
                    </div>
                    <div className="col-3">
                      <TextField noMb title="RFC" value={billingElement.rfc} />
                    </div>
                    <div className="col-3">
                      <TextField noMb title="Uso de CFDI" value={cfdiUse()} />
                    </div>
                    {billingElement.isPrimary && (
                        <div className="col-3 d-flex align-items-center extra-row">
                          <span
                            className={`svg-icon ${true ? 'svg-icon--active' : ''}`}
                          >
                            <Icons.CheckGroup width="100%" height="100%" />
                          </span>
                          <span className="text text--success">PREDETERMINADA</span>
                        </div>
                      )}</>
                )}
              </div>

              <UserInfo left {...billingElement} />
            </Fragment>

          );
        })}
      {data && data.length === 0 && (
        <span className="text-muted">Sin informaci&oacute;n</span>
      )}
    </CardSection>
  );
};

InvoiceDataDetail.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

InvoiceDataDetail.defaultProps = {
  data: [],
};

export default InvoiceDataDetail;
