import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  Fragment,
} from 'react';
import { useFormikContext } from 'formik';
import { Select, Input, Icons } from '@casanova/casanova-common';
import './AddedElements.scss';

export default function AddedElements({
  name = '',
  placeholder = '',
  disableLabel = true,
  options = [],
  disabledSelect = false,
  disableAdd = false,
  viewOptions = {},
  customElements = [],
  renderCustomElements = false,
  elementToRenderChildren,
  validCustomAdd = false,
  secondListOfCustomElements = [],
  onSelectedElemensChange = () => {},
  onAddCustomElement = () => {},
  onDeleteCustomElement = () => {},
  children = () => {},
  transformerName,
  transformerLabel,
}) {
  const [temporalSelected, setTemporalSelected] = useState('');
  const [elements, setElements] = useState([]);
  const [customRenderElement, setCustomRenderElement] = useState(null);
  const [showCustomRenderElement, setShowCustomRenderElement] = useState(false);
  const [formik, setFormik] = useState({});

  const { list = [], fieldToView = '', fieldToSearch = '' } = viewOptions;

  const { setFieldValue, setFieldTouched } = useFormikContext();

  const elementsToRender = useMemo(() => {
    const finalElements = renderCustomElements ? customElements : [...elements];
    return finalElements;
  }, [renderCustomElements, customElements, elements]);

  const validOptions = useMemo(
    () =>
      options.filter((option) => {
        const validOption = !elementsToRender.some(
          (element) => element === option.uuid
        );
        const isCustomRenderElement = option.value === elementToRenderChildren;
        return validOption || (isCustomRenderElement && validCustomAdd);
      }),
    [options, elementsToRender, elementToRenderChildren, validCustomAdd]
  );

  const handleDelete = useCallback(
    (elementToDelete) => {
      const newElements = elements.filter(
        (element) => element !== elementToDelete
      );
      setElements(newElements);
      const elementInSecondList = secondListOfCustomElements.find(
        (element) => element.uuid === elementToDelete
      );

      if (elementInSecondList) {
        const newSecondList = secondListOfCustomElements.filter(
          (element) => element.uuid !== elementToDelete
        );
        onDeleteCustomElement(newSecondList);
      } else {
        onSelectedElemensChange(newElements);
      }
    },
    [
      elements,
      elementToRenderChildren,
      onSelectedElemensChange,
      secondListOfCustomElements,
    ]
  );

  const handleSelectChange = useCallback(
    (evt) => {
      const { value } = evt.target;
      setTemporalSelected(value);
      setFieldValue(name, value);
    },
    [name, setFieldValue]
  );

  const handleClickAdd = useCallback(
    (values = {}) => {
      if (customRenderElement && showCustomRenderElement) {
        onAddCustomElement(values);
        setShowCustomRenderElement(false);
      } else if (temporalSelected) {
        const isCustomElementRender =
          temporalSelected === elementToRenderChildren;
        let newElements = [];

        const oneElement = elements.length + 1 === validOptions.length;

        if (oneElement) {
          if (isCustomElementRender) {
            setShowCustomRenderElement(true);
            newElements = elements
              .filter((element) => element !== elementToRenderChildren)
              .concat(elementToRenderChildren);
          } else {
            const validElement = validOptions.find(({ uuid }) => {
              const isValidElement = !elements.find(
                (element) => element === uuid
              );
              return isValidElement;
            });
            if (validElement) {
              newElements = elements.concat(validElement.uuid);
            }
          }
        } else {
          newElements = elements
            .filter((element) => element !== temporalSelected)
            .concat(temporalSelected);

          if (isCustomElementRender && validCustomAdd) {
            setShowCustomRenderElement(true);
          }
        }

        onSelectedElemensChange(newElements);
        setElements(newElements);
      }
    },
    [
      validOptions,
      elementToRenderChildren,
      validCustomAdd,
      temporalSelected,
      elements,
      customRenderElement,
      showCustomRenderElement,
      onSelectedElemensChange,
      onAddCustomElement,
    ]
  );

  useEffect(() => {
    if (renderCustomElements && customElements) {
      setElements(customElements);
    }
  }, [customElements, renderCustomElements]);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const customRenderElement = elementsToRender.find(
      (element) => element === elementToRenderChildren
    );
    setCustomRenderElement(customRenderElement);
  }, [elementsToRender]);

  useEffect(
    () => () => {
      setFieldValue(name, '');
    },
    [name]
  );

  const renderChildren = (moreProps = {}) => (
    <div className="row m-0 p-0">
      <div className="col-12 m-0 p-0">
        {children({
          handleClickAdd,
          handleDelete,
          onChangeFormik: setFormik,
          ...moreProps,
        })}
      </div>
    </div>
  );

  const handleSubmit = () => {
    if (
      formik?.handleSubmit &&
      showCustomRenderElement &&
      customRenderElement
    ) {
      formik.handleSubmit();
    } else {
      handleClickAdd();
    }

    setTimeout(() => setFieldTouched(name, true), 1);
  };

  return (
    <div className="AddedElements container-fluid p-0 m-0">
      {elementsToRender && elementsToRender.length > 0 && (
        <>
          {elementsToRender.map((element) => {
            if (!element) return null;

            const completeElement = list.find(
              (listItem) => listItem[fieldToSearch] === element
            );

            const sameElementToRender = element === elementToRenderChildren;

            if (!completeElement) return null;

            const renderInput = (customElement = false) => {
              let inputPlaceholder = transformerName
                ? transformerName(completeElement)
                : `${completeElement[fieldToView]}`;

              if (customElement) {
                const { label = '' } =
                  options.find(
                    (option) => option.value === elementToRenderChildren
                  ) || {};
                inputPlaceholder = label;
              }

              return (
                <div key={element} className="row p-0 m-0">
                  <div className="col-10 p-0 m-0">
                    <Input
                      label={
                        transformerLabel
                          ? transformerLabel(completeElement)
                          : `${completeElement[viewOptions.label]}`
                      }
                      placeholder={inputPlaceholder}
                      name={`${element}`}
                      disabled
                      showLabel
                    />
                  </div>
                  <div className="col-2 col-md-2 p-0 justify-content-center align-items-center d-flex">
                    <button
                      className="btn justify-content-center align-items-center d-flex"
                      type="button"
                      onClick={() => handleDelete(element)}
                    >
                      <Icons.IcoDeleteGray width={20} />
                    </button>
                  </div>
                </div>
              );
            };

            if (completeElement.isCustom)
              return (
                <Fragment key={element}>
                  {renderInput(true)}
                  {renderChildren({ disabledForm: true, completeElement })}
                </Fragment>
              );

            return (
              <Fragment key={element}>
                {sameElementToRender ? (
                  <>
                    {customRenderElement && showCustomRenderElement && (
                      <>
                        <>{renderInput()}</>
                        <>{renderChildren()}</>
                      </>
                    )}
                  </>
                ) : (
                  renderInput()
                )}
              </Fragment>
            );
          })}
        </>
      )}

      {validOptions && validOptions.length > 0 && (
        <div className="row m-0 p-0">
          <div className="col-10 p-0 pr-2">
            <Select
              className="m-0"
              availableFirstValue
              name={name}
              label={placeholder}
              disableLabel={disableLabel}
              options={validOptions}
              onChange={handleSelectChange}
              disabled={disabledSelect}
              defaultValue=""
            />
          </div>
          <button
            className="col-2 col-md-2 btn btn-outline btn-plus-minus AddButton"
            disabled={disableAdd}
            type="submit"
            onClick={handleSubmit}
          >
            <span>+</span>
          </button>
        </div>
      )}
    </div>
  );
}
