/* eslint-disable func-names */
import * as yup from 'yup';
import moment from 'moment';
import i18n from '@i18n';
import { hourValidation } from 'utils/validations/common';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import { isToday } from 'utils/date';
import {
  getFieldSearch,
  getLastSearchField,
} from '@casanova/casanova-common/lib/utils/transformHelpers';

const requiredSearch = (path) =>
  function (value) {
    return value && this.parent[path];
  };

const requesterSchema = yup
  .string()
  .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
  .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5]));

const vehiclePlateSchema = yup
  .string()
  .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
  .min(2, i18n('ERROR__COMMONS__MIN_LENGTH', [2]));

export const schema = yup.object().shape({
  requester_search: yup.mixed().when('action', {
    is: (action) => action !== FORM_ACTIONS.EDIT,
    then: requesterSchema.test(
      'required-requester-search',
      i18n('ERROR__COMMONS__REQUIRED__ITEM_OF_LIST', ['un solicitante']),
      requiredSearch('requester')
    ),
    otherwise: yup.string(),
  }),
  requester: yup.mixed().when('action', {
    is: (action) => action !== FORM_ACTIONS.EDIT,
    then: requesterSchema,
    otherwise: yup.string(),
  }),
  [getFieldSearch('plateNumber')]: vehiclePlateSchema
    .test(
      'required-requester-search',
      i18n('ERROR__COMMONS__REQUIRED__ITEM_OF_LIST', ['una placa']),
      requiredSearch('plateNumber')
    )
    .test(
      'same-plate-of-last-search',
      i18n('ERROR__COMMONS__REQUIRED__ITEM_OF_LIST', ['una placa']),
      function (value) {
        return value === this.parent[getLastSearchField('plateNumber')];
      }
    ),
  plateNumber: vehiclePlateSchema,
  departureDate: yup.string().required(i18n('ERROR__COMMONS__REQUIRED_FIELD')),
  departureTime: hourValidation
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .test(
      'min-current-time',
      i18n('ERROR__LOANS__ENTRY_TIME_CANNOT_BE_LESS_THAN_CURRENT_TIME'),
      function (departureTime) {
        const { departureDate } = this.parent;

        if (!departureDate || !departureTime) return true;

        if (isToday(departureDate)) {
          return moment(`${departureDate} ${departureTime}`) > moment();
        }

        return true;
      }
    ),
  returnDate: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .test(
      'higher-return-date',
      'La fecha de regreso no puede ser menor a la de salida',
      function (returnDate) {
        const { departureDate } = this.parent;

        if (!returnDate || !departureDate) return true;

        return moment(returnDate) >= moment(departureDate);
      }
    ),
  returnTime: hourValidation
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .test(
      'min-current-time',
      i18n('ERROR__COMMONS__HOUR__GREATER_THAN_MINUTES', [
        'regreso',
        'salida',
        '30',
      ]),
      function (returnTime) {
        const { departureDate, departureTime, returnDate } = this.parent;

        if (!returnDate || !returnTime || !departureDate || !departureTime)
          return true;

        const fullReturnDate = moment(`${returnDate} ${returnTime}`);
        const fullDepartureDate = moment(`${departureDate} ${departureTime}`);

        fullReturnDate.subtract('minutes', 30);

        return moment(fullReturnDate).isAfter(fullDepartureDate);
      }
    ),
  driverName: yup
    .string()
    .required(i18n('ERROR__COMMONS__REQUIRED_FIELD'))
    .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5])),
  observations: yup
    .string()
    .nullable()
    .min(5, i18n('ERROR__COMMONS__MIN_LENGTH', [5]))
    .max(500, i18n('ERROR__COMMONS__MIN_LENGTH', [500])),
});
