import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  mapStateToPropsOfDeliveryAndReturn,
  dispatchToPropsOfDeliveryAndReturn,
} from '@reservations/commons/deliveryAndRefundConfig';
import { DELIVERY_RETURN_TYPES } from '@reservations/commons/constants';
import Return from './Return';

const { RETURN } = DELIVERY_RETURN_TYPES;

export const ReturnStepOne = compose(
  connect(
    (state) => ({
      ...mapStateToPropsOfDeliveryAndReturn(state, RETURN),
      step: 1,
    }),
    dispatchToPropsOfDeliveryAndReturn
  )
)(Return);

export const ReturnStepTwo = compose(
  connect(
    (state) => ({
      ...mapStateToPropsOfDeliveryAndReturn(state, RETURN),
      step: 2,
      confirmationMessage: false,
    }),
    dispatchToPropsOfDeliveryAndReturn
  )
)(Return);

export const ReturnStepThree = compose(
  connect(
    (state) => ({
      ...mapStateToPropsOfDeliveryAndReturn(state, RETURN),
      step: 3,
    }),
    dispatchToPropsOfDeliveryAndReturn
  )
)(Return);
