import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  setListPage,
  cleanReportsList,
  downloadReports,
} from 'store/reports/actions';
import ListOfReports from './ListOfReports';

export default compose(
  connect(
    (state) => ({
      reportsList: state.reports.response,
      reportsColumns: state.reports.reportsColumns,
      typesOfReports: state.reports.typesOfReports.list,
    }),
    { setListPage, cleanReportsList, downloadReports }
  )
)(ListOfReports);
