import React, { useEffect, useCallback, useRef } from 'react';
import { Formik, yupToFormErrors, validateYupSchema } from 'formik';
import _get from 'lodash/get';
import {
  MaskedInput as Input,
  DatePickerV2,
  Select,
  TextAreaInput,
} from '@casanova/casanova-common';
import { sinisterValidation } from 'utils/validations/additionals';
import { sinister } from 'services/vehicles/additionals';
import { CREATE_VEHICLE_SINISTER } from 'utils/roles/permissions';
import UserInfo from 'components/UserInfo';

import AdditionalWrapper from '@additionals/components/AdditionalModals';
import { MINIMUM_DATE, MAXIMUM_DATE } from '@vehicles/common/constants';
import { TEXT_AREA_NOT_ALLOWED_CHARACTERS } from 'utils/validations';
import PreventChange from 'components/PreventChange';
import { numericMask } from 'utils/masks';
import { withAdditionalCollapse } from '../AdditionalCollapse';
import { SelectWithOptions } from '../components';

const Siniester = (props) => {
  const {
    name,
    vehicleId,
    fetchCatalog,
    getAdditional,
    editable,
    policyOptions,
    data,
    isNew,
    saveAdditional,
    vehicle,
    addNew,
    show,
  } = props;
  const formRef = useRef(null);

  useEffect(() => {
    if (!show && formRef) {
      formRef.current.resetForm(sinister.empty);
    }
  }, [show]);

  useEffect(() => {
    if (vehicleId) {
      getAdditional({ name, data: vehicleId });
    }
    fetchCatalog('sinister-type');
    fetchCatalog('sinister-status');
  }, [name, fetchCatalog, vehicleId, getAdditional]);

  const handleFormik = useCallback(
    (values) => {
      const folio = _get(data, 'folio');
      saveAdditional({
        name,
        data: {
          ...values,
          receipt: values.verificationReceipt,
          vehicleId,
        },
        forceCreate: folio && folio !== values.folio,
        addNew,
      });
    },
    [addNew, saveAdditional, name, vehicleId, data]
  );

  const handleValidation = (values) => {
    try {
      validateYupSchema(values, sinisterValidation, true, {
        vehicle,
        policyOptions,
      });
    } catch (error) {
      return yupToFormErrors(error);
    }
    return {};
  };

  return (
    <div className="container-fluid">
      <Formik
        initialValues={addNew ? sinister.empty : data || sinister.empty}
        validate={handleValidation}
        validationSchema={sinisterValidation}
        validateOnChange={false}
        onSubmit={handleFormik}
        enableReinitialize
        innerRef={formRef}
      >
        {(formikProps) => {
          const { handleSubmit, initialValues, dirty } = formikProps;
          return (
            <PreventChange preventChange={dirty && editable}>
              <AdditionalWrapper
                roleVerifierIdentifier={CREATE_VEHICLE_SINISTER}
                handleSubmit={handleSubmit}
                formikProps={formikProps}
                emptyValues={sinister.empty}
                extraProps={props}
              >
                <div className="row">
                  <div className="col-12 col-md-4">
                    <Input
                      mask={numericMask(10)}
                      maskPlaceholder=""
                      name="sinisterNumber"
                      label="Número de siniestro*"
                      placeholder="Número de siniestro*"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <Select
                      name="policyNumber"
                      label="Póliza*"
                      options={policyOptions}
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <DatePickerV2
                      name="sinisterDate"
                      label="Fecha*"
                      placeholder="Fecha*"
                      initialValue={initialValues.sinisterDate}
                      disabled={!editable && !isNew}
                      minimumDate={MINIMUM_DATE}
                      maximumDate={MAXIMUM_DATE}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="sinisterType"
                      label="Tipo*"
                      options="catalogs.sinisterType"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-4">
                    <SelectWithOptions
                      name="sinisterStatus"
                      label="Estatus*"
                      options="catalogs.sinisterStatus"
                      disabled={!editable && !isNew}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <TextAreaInput
                      name="description"
                      label="Descripción*"
                      placeholder="Descripción*"
                      disabled={!editable && !isNew}
                      regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS}
                      maxLength={200}
                    />
                  </div>
                </div>
                <UserInfo {...data} />
              </AdditionalWrapper>
            </PreventChange>
          );
        }}
      </Formik>
    </div>
  );
};

export default withAdditionalCollapse({ title: 'Siniestro' })(Siniester);
