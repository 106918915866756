export const transformer = (values) => {
  const { cardHolderName, bankUuid, number, validity = '' } = values;

  const elements = validity.split('-');
  const month = parseInt(elements[1], 10);
  const year = parseInt(elements[0], 10) - 2000;
  const realMonth = month >= 10 ? month : `0${month}`;
  const holdbackValidityModify = `${realMonth}/${year}`;

  return {
    holdbackCardHolderModify: cardHolderName,
    holdbackBankModify: bankUuid,
    holdbackCardModify: number,
    holdbackValidityModify,
    ...values,
  };
};
