import * as Yup from 'yup';
import {
  fileTypeValidation,
  shouldUpdateDocument,
} from 'utils/validations/common';
import {
  ADDRESS_FIELDS,
  REQUIRED_MESSAGE,
  ALLOWED_FORMATS,
  FORMAT_TYPES_ALLOWED_MESSAGE,
  UPDATE_DOCUMENT_MESSAGE,
} from 'utils/constants';
import { getCustomerType } from '@customers/common/utils';

const addressDocumentTest = Yup.mixed()
  .required(REQUIRED_MESSAGE)
  .test('should-update-document', UPDATE_DOCUMENT_MESSAGE, function (value) {
    const { initialValues } = this.options.context;
    if (!initialValues) return true;
    return shouldUpdateDocument(
      value,
      ADDRESS_FIELDS,
      this.parent,
      initialValues
    );
  })
  .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
    fileTypeValidation(file, ALLOWED_FORMATS)
  );

export const addressProps = {
  street: Yup.string().required(REQUIRED_MESSAGE),
  indoorNumber: Yup.string(),
  outdoorNumber: Yup.string().required(REQUIRED_MESSAGE),
  zipCode: Yup.string().required(REQUIRED_MESSAGE),
  state: Yup.string().required(REQUIRED_MESSAGE),
  municipality: Yup.string().required(REQUIRED_MESSAGE),
  colony: Yup.string().required(REQUIRED_MESSAGE),
  addressDocumentType: Yup.string().required(REQUIRED_MESSAGE),
  period: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test('not-zero-value', REQUIRED_MESSAGE, (value) => {
      if (!value) return true;
      return value !== 0 && value !== '0';
    }),
  addressDocument: Yup.mixed().when('personType', {
    is: (personType) =>
      getCustomerType({ customerType: personType, validationType: 'fisic' }),
    then: addressDocumentTest,
    otherwise: Yup.mixed(),
  }),
};

export const addressPropsTender = {
  ...addressProps,
  addressDocument: Yup.mixed().when('personType', {
    is: (personType) =>
      getCustomerType({ customerType: personType, validationType: 'tender' }),
    then: addressDocumentTest,
    otherwise: Yup.mixed(),
  }),
};

export const AddressSchema = Yup.object().shape({
  ...addressProps,
});

export default AddressSchema;
