const initialValues = {
  socialReason: '',
  rfc: '',
  paymentForm: '',
  paymentMethod: '',
  amount: '',
  date: '',
  time: '',
};

const initialTouched = {
  socialReason: true,
  rfc: true,
  paymentForm: true,
  paymentMethod: true,
  amount: true,
  date: true,
  time: true,
};

export default {
  initialTouched,
  initialValues,
};
