import { useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  Section,
  MaskedInput as Input,
  NumberInput,
  DatePickerV2 as DatePicker,
  ConnectedSelect,
  Icons,
} from '@casanova/casanova-common';

import {
  alphaMaskWithSpaces,
  hourMask,
} from '@casanova/casanova-common/lib/utils/masks';
import {
  formattedDateToPickerV2,
  getPrevYear,
  YYYY_MM_DD,
} from '@casanova/casanova-common/lib/utils/date';

import i18n from '@i18n';

const MIN_DATE = getPrevYear({ years: 2 });
const MAX_DATE = formattedDateToPickerV2(
  moment().add(1, 'day').format(YYYY_MM_DD)
);

export default function CRPInformation({
  invoiceDetails,
  fetchCatalog,
  onUpdateForm,
}) {
  useEffect(() => {
    fetchCatalog({
      catalogId: 'catalogs/payment-forms',
      catalogPath: 'pricequotes',
    });
    fetchCatalog({
      catalogId: 'catalogs/payment-methods',
      catalogPath: 'pricequotes',
    });
  }, [fetchCatalog]);

  useEffect(() => {
    if (!_isEmpty(invoiceDetails)) {
      onUpdateForm(invoiceDetails);
    }
  }, [onUpdateForm, invoiceDetails]);

  const disabledInput = true;

  return (
    <Section title="Emitir CRP">
      <div className="row">
        <div className="col-md-12">
          <Input
            mask={alphaMaskWithSpaces(50)}
            maskPlaceholder=""
            label="Nombre o Razón Social*"
            placeholder="Nombre o Razón Social*"
            name="socialReason"
            disabled={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <Input
            mask={alphaMaskWithSpaces(50)}
            maskPlaceholder=""
            label={`${i18n('COMMONS__RFC')}*`}
            placeholder={`${i18n('COMMONS__RFC')}*`}
            name="rfc"
            disabled={disabledInput}
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            label="Forma de pago*"
            name="paymentForm"
            options="commons.catalogsPaymentForms"
          />
        </div>
        <div className="col-md-4">
          <ConnectedSelect
            label="Método de pago*"
            name="paymentMethod"
            options="commons.catalogsPaymentMethods"
          />
        </div>
        <div className="col-md-4">
          <NumberInput
            label={`${i18n('COMMONS__AMOUNT')}*`}
            placeholder={`${i18n('COMMONS__AMOUNT')}*`}
            name="amount"
            max={999_999}
            decimals={2}
          />
        </div>
        <div className="col-md-4">
          <DatePicker
            label={`${i18n('COMMONS__DATE')}*`}
            placeholder={`${i18n('COMMONS__DATE')}*`}
            name="date"
            minimumDate={MIN_DATE}
            maximumDate={MAX_DATE}
          />
        </div>
        <div className="col-md-4">
          <Input
            mask={hourMask}
            maskPlaceholder=""
            label={`${i18n('COMMONS__HOUR')}*`}
            placeholder={`${i18n('COMMONS__HOUR')}*`}
            name="time"
            right={<Icons.Time width="85%" height="85%" />}
          />
        </div>
      </div>
    </Section>
  );
}
