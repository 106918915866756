import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withLayout } from 'hooks';
import { searchEmployees } from 'store/customers/actions';
import { searchVehicles } from 'store/vehicles/actions';
import { getLoanDetails, closeResponseDialog } from 'store/loans/actions';
import { successPath as onCancelPath } from '@loans/common/config';
import LoanEditor from './LoanEditor';

export default withLayout(
  compose(
    connect(
      (state) => ({
        data: state.loans.loanDetails.data,
        responseDialog: state.loans.responseDialog,
        onCancelPath,
      }),
      {
        searchEmployees,
        searchVehicles,
        getLoanDetails,
        closeResponseDialog,
      }
    )
  )(LoanEditor)
);
