import {
  VIEW_VEHICLE_POLICY,
  CREATE_VEHICLE_POLICY,
} from 'utils/roles/permissions';
import Policy from './Policy';
import { withConnectedAdditional } from '../AdditionalCollapse';

export default withConnectedAdditional({
  identifierForView: VIEW_VEHICLE_POLICY,
  identifierForAdd: CREATE_VEHICLE_POLICY,
})(Policy);
