import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import { MaskedInput, TextAreaInput, Checkbox} from '@casanova/casanova-common';
import { alphaMaskWithPuntuation, alphaMask } from 'utils/masks';
import { useFormikContext } from 'formik';
import { selectImeiGPS, fetchInstaller } from 'store/catalogs/actions';
import SearcheableSelect from 'components/SercheableSelect';
import SearchInput from '../../../../../../components/SearchInput';
import { TEXT_AREA_NOT_ALLOWED_CHARACTERS_PUNCTUATION_MARKS } from 'utils/validations';

const Section = ({ name }) => {
  const { setFieldValue, values } = useFormikContext();
  const modeType = useSelector((state) => state.catalogs.modeType);
  // console.log("🚀 ~ Section ~ modeType:", modeType)
  const gps = useSelector((state) => _get(state, 'catalogs.gps.list', []));
  const dispatch = useDispatch();
  // const [isChecked, setIsChecked] = useState(false);
  // console.log("🚀 ~ Section ~ isChecked:", isChecked)

  const returnValueTag = useCallback(
    (value) => {
      const tag = gps.filter((gp) => gp.value === value);
      return _get(tag, '0.imei', '');
    },
    [gps]
  );

  const handleSelectImei = useCallback(
    (value) => {
      setFieldValue(`gps.${name}.imeiGPS`, returnValueTag(value));
      const e = { target: { value, name } };
      dispatch(selectImeiGPS({ e, values, name }));
    },
    [setFieldValue, dispatch, values, name, returnValueTag]
  );

  const handleSearching = useCallback(
    (query = '') => {
      dispatch(fetchInstaller(`tag=${query}`));
      setFieldValue(`gps.${name}.imeiGPS`, '');
    },
    [dispatch, setFieldValue, name]
  );

  const handleWriteImei = ({ target: { value } }) => {
    setFieldValue(`gps.${name}.imeiGPS`, value);
  };

  const handleIsCustom = () => {
    // setFieldValue(`gps.${name}.isCustom`, !isChecked);
    setIsChecked(!isChecked);
  };

  // useEffect(() => {
  //   // if(modeType === '' ){
  //   //   setFieldValue(`gps.${name}.gpsTag`, '');
  //   //   setFieldValue(`gps.${name}.imeiGPS`, '');
  //   //   setFieldValue(`gps.${name}.tagGPS`, );
  //   // }
  //   // setFieldValue(`gps.${name}.imeiGPS`, '');
  //   // if(modeType === 'Reinstall' ){
  //   //   setIsChecked(true)
  //   // }

  // }, [isChecked]);

  return (
    <>
    {modeType==='Retiro'?
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <TextAreaInput
              label="Observaciones"
              placeholder="Observaciones"
              name={`gps.${name}.remarks`}
              regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS_PUNCTUATION_MARKS}
              maxLength={200}
              rows="3"
            />
          </div>
        </div>
      </div>
    :
    <div className="container-fluid">
      <div className="row">
      {
        modeType === 'Reinstall'?
          <div className="col-12 col-md-4">
          <SearcheableSelect
            onChange={handleSelectImei}
            name={`gps.${name}.tagGPS`}
            label="Tag GPS*"
            placeholder="Tag GPS*"
            options="catalogs.gps"
            mask={alphaMask(30)}
            disabled={modeType === 'Reinstall'}
            initialValue={
              modeType === 'Reinstall'
                ? values.gps[name].SelectedtagGPS
                : undefined
            }
            onSearching={handleSearching}
          />
        {
        // modeType==='Remove' || modeType==='' &&(
        //   <Checkbox
        //     name={`gps.${name}.isCustom`}
        //     label="Editar GPS"
        //     onChange={handleIsCustom}
        //   />)
        }
          </div>   
        :
          <>       
            <div className="col-12 col-md-4">
              <MaskedInput
                  maskPlaceholder=""
                  onChange={handleWriteImei}
                  label="Tag GPS*"
                  placeholder="Tag GPS*" 
                  name={`gps.${name}.gpsTag`}
                  mask={alphaMask(30)}
                  initialValue={
                  modeType === 'Reinstall'
                    ? values.gps[name].SelectedtagGPS
                    : undefined
                }
                  disabled={modeType === 'Reinstall'}
              />
              {/* {( modeType==='Remove' || modeType===''&&
                <Checkbox
                name={`gps.${name}.isCustom`}
                label="Lista de GPS agregados anteriormente"
                onChange={handleIsCustom}
              />)
              } */}
              
            </div>
          </>
        }
        <div className="col-12 col-md-4">
          <MaskedInput
            maskPlaceholder=""
            label="Imei GPS*"
            placeholder="Imei GPS*"
            name={`gps.${name}.imeiGPS`}
            // disabled
          />
        </div>
        <div className="col-12 col-md-4">
          <MaskedInput
            maskPlaceholder=""
            label="Lugar de instalación*"
            placeholder="Lugar de instalación*"
            name={`gps.${name}.installationPlace`}
            mask={alphaMaskWithPuntuation(100)}
          />
        </div>
        <div className="col-12">
          <TextAreaInput
            label="Observaciones"
            placeholder="Observaciones"
            name={`gps.${name}.remarks`}
            regex={TEXT_AREA_NOT_ALLOWED_CHARACTERS_PUNCTUATION_MARKS}
            maxLength={200}
            rows="3"
          />
        </div>
      </div>
    </div>    
  
     }
    </>
  );
};

export default Section;
