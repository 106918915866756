import { CellText } from 'components/ColumnsCells';

export const columns = [
  {
    label: 'Folio',
    field: 'folio',
    align: 'center',
    renderer: CellText,
  },
  {
    label: 'No. Contrato',
    field: 'contractNumber',
    align: 'center',
    renderer: CellText,
  },
  {
    label: 'Cliente',
    field: 'customer',
    align: 'center',
    renderer: CellText,
  },
  {
    label: 'Fecha Inicio',
    field: 'startDate',
    align: 'center',
    renderer: CellText,
  },
  {
    label: 'Fecha Fin',
    field: 'endDate',
    align: 'center',
    renderer: CellText,
  },
  {
    label: 'Usuario alta',
    field: 'createdBy',
    align: 'center',
    renderer: CellText,
  },
  {
    label: 'Tipo',
    field: 'type',
    align: 'center',
    renderer: CellText,
  },
  {
    label: 'Estatus',
    field: 'vehicleStatus',
    align: 'center',
    renderer: CellText,
  },
];
