import {
  CLOSE_RESPONSE_DIALOG,
  POST_AVAILABLE_USER,
  POST_AVAILABLE_USER_SUCCESSED,
  POST_AVAILABLE_USER_FAILED,
} from './action-types';

const initialState = {
  users: {
    list: {
      params: {
        page: 0,
        size: 10,
        property: 'id',
        direction: 'asc',
      },
      searchPattern: '',
      filters: [],
      totalPages: 1,
      totalElements: 10,
      results: [
        {
          page: 0,
          list: [],
          loading: false,
          loaded: false,
        },
      ],
    },
    singleUser: null,
    curpData: {
      error: false,
      errorMessage: '',
      data: null,
    },
    avaibleUser: {
      error: false,
      errorMessage: '',
      listOfFields: {},
      params: {},
    },
    responseDialog: {
      open: false,
      success: false,
      errorCode: 'MD-100',
      username: '',
      message: '',
    },
    preventChange: null,
  },
};

export function adminPanelReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_RESPONSE_DIALOG:
      return {
        ...state,
        users: {
          ...state.users,
          responseDialog: {
            ...state.users.responseDialog,
            open: false,
            success: false,
            errorCode: '',
            message: '',
          },
        },
      };
    case POST_AVAILABLE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          avaibleUser: {
            ...state.users.avaibleUser,
            error: false,
            errorMessage: '',
            params: {},
          },
        },
      };
    case POST_AVAILABLE_USER_SUCCESSED:
      const { data, field } = action.payload;

      const listOfFields = { ...state.users.avaibleUser.listOfFields };

      listOfFields[field] = data;

      return {
        ...state,
        users: {
          ...state.users,
          avaibleUser: {
            ...state.users.avaibleUser,
            error: false,
            errorMessage: '',
            listOfFields,
          },
        },
      };
    case POST_AVAILABLE_USER_FAILED:
      return {
        ...state,
        users: {
          ...state.users,
          avaibleUser: {
            ...state.users.avaibleUser,
            error: true,
            errorMessage: action.payload,
            params: {},
          },
        },
      };

    default:
      return state;
  }
}
