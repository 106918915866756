import * as yup from 'yup';
import moment from 'moment-timezone';
import 'moment/locale/es';
import {
  REQUIRED_MESSAGE,
  FORMAT_TYPES_ALLOWED_MESSAGE,
  ALLOWED_FORMATS,
} from 'utils/constants';
import { dateValidation, fileTypeValidation } from 'utils/validations/common';
import i18n from '@i18n';

const cardFormSchema = {
  cardType: yup.string().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'card') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup.string().required(REQUIRED_MESSAGE),
    otherwise: yup.string(),
  }),
  bank: yup.string().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'card') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup.string().required(REQUIRED_MESSAGE),
    otherwise: yup.string(),
  }),
  cardNumber: yup.string().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'card') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(15, i18n('ERROR__COMMONS__MIN_LENGTH', [15])),
    otherwise: yup.string(),
  }),
  reference: yup.string().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'card') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(6, i18n('ERROR__COMMONS__MIN_LENGTH', [6])),
    otherwise: yup.string(),
  }),
  authorization: yup.string().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'card') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup
      .string()
      .required(REQUIRED_MESSAGE)
      .min(6, i18n('ERROR__COMMONS__MIN_LENGTH', [6])),
    otherwise: yup.string(),
  }),
  voucher: yup.mixed().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'card') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup
      .mixed()
      .required(REQUIRED_MESSAGE)
      .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
        fileTypeValidation(file, ALLOWED_FORMATS)
      ),
    otherwise: yup.mixed(),
  }),
  accountStatus: yup.mixed().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'card') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup.mixed().when('hasHolderName', {
      is: false,
      then: yup
        .mixed()
        .required(REQUIRED_MESSAGE)
        .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
          fileTypeValidation(file, ALLOWED_FORMATS)
        ),
      otherwise: yup.mixed(),
    }),
    otherwise: yup.mixed(),
  }),
  accountStatus: yup
    .mixed()
    .when(['selectedMethods', 'movementType', 'hasHolderName'], {
      is: (selectedMethods, movementType, hasHolderName) =>
        selectedMethods.some((method) => method === 'card') &&
        movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2' &&
        !hasHolderName,
      then: yup
        .mixed()
        .required(REQUIRED_MESSAGE)
        .test('fileFormat', FORMAT_TYPES_ALLOWED_MESSAGE, (file) =>
          fileTypeValidation(file, ALLOWED_FORMATS)
        ),
      otherwise: yup.mixed(),
    }),
  amountCard: yup.mixed().when(['selectedMethods', 'movementType'], {
    is: (selectedMethods, movementType) =>
      selectedMethods.some((method) => method === 'card') &&
      movementType === 'a50a4fef-7a34-436e-ab58-5e7e576d12e2',
    then: yup.mixed().when('diffMethods', {
      is: (diffMethods) => diffMethods === true,
      then: yup
        .mixed()
        .required(REQUIRED_MESSAGE)
        .test(
          'invalidPrice',
          'La suma de los montos no es igual al total del abono',
          function (value) {
            if (!value) {
              return true;
            }
            const validPrice = this.parent.priceIsInvalid;
            return validPrice === true;
          }
        ),
      otherwise: yup.mixed(),
    }),
    otherwise: yup.mixed(),
  }),
};

export default cardFormSchema;
