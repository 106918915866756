import { createLoan } from 'store/contracts/actions';
import i18n from '@i18n';
import { schema as vehiclesSchema } from '@contracts/sections/Vehicles/schema';
import { schema } from './schema';
import { formatter } from './formatter';
import { transformer } from './transformer';

export const config = {
  schema: schema.concat(vehiclesSchema),
  initialTouched: {
    reason: true,
    startDate: true,
    endDate: true,
    remarks: true,
    vehicleA: true,
    vehicleB: true,
    uuid: true,
    vehicles: true,
    vehicleSearch: true,
  },
  initialValues: {
    reason: '',
    startDate: '',
    endDate: '',
    remarks: '',
    vehicleA: '',
    vehicleB: '',
    uuid: '',
    vehicles: [],
    vehicleSearch: '',
  },
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter,
  transformer,
  submitForm: createLoan,
  dialogs: {
    confirmation: {
      title: i18n('CONTRACTS__CONTRACT_CREATOR__MODAL__TITLE'),
      message: i18n('CONTRACTS__CONTRACT_CREATOR__MODAL__DESCRIPTION'),
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: i18n(
        'CONTRACTS__CONTRACT_LOAN_VEHICLE__SUCCESS_MODAL__MESSAGE'
      ),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: i18n(
        'CONTRACTS__CONTRACT_CREATOR__SUCCESS_MODAL__SUCCESS_LABEL'
      ),
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: '/contracts',
    },
  },
};
