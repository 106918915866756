import * as Yup from 'yup';
import moment from 'moment-timezone';
import 'moment/locale/es';

import { editStepOne } from 'store/reservations/actions';

import i18n from '@i18n';
import {
  REQUIRED_MESSAGE,
  NOT_SUNDAYS,
  YYYY_MM_DD,
  RETURN_DATE_MAJOR_THAN_START,
  ISO_DATE_FORMAT,
  FULL_DATE_FORMAT,
} from 'utils/constants';
import { dateValidationGreat, notSunday } from 'utils/validations/common';
import { isToday, realDate } from 'utils/date';

export const dateEndValidation = dateValidationGreat
  .required(REQUIRED_MESSAGE)
  .test('major-than-start', RETURN_DATE_MAJOR_THAN_START, function (value) {
    const { dateStart } = this.parent;
    if (!value || !dateStart) return true;

    const start = moment(dateStart, YYYY_MM_DD);
    const end = moment(value, YYYY_MM_DD);

    return end.isAfter(start, 'day');
  })
  .test(
    'in-returnable-list',
    'Este día no está disponible para la devolución de tu vehículo',
    function (value) {
      const { availableTime, vehicleCategory } = this.parent;

      if (!value || !availableTime) return true;

      const isValid = moment(value, YYYY_MM_DD).isValid();

      if (!isValid) return false;

      const selectedEndDate = availableTime.filter(
        (element) =>
          element.dayValue - 1 === moment(value, YYYY_MM_DD).weekday()
      );
      const validCategoryByDate =
        selectedEndDate[0].returnableCategories.filter(
          (cat) => cat === vehicleCategory
        ).length > 0;

      return validCategoryByDate;
    }
  );

export const reservationStartDatesProps = {
  dateStart: dateValidationGreat
    .required(REQUIRED_MESSAGE)
    .test('not-sunday', NOT_SUNDAYS, (value) => {
      if (!value) return true;
      return notSunday(value);
    })
    .test(
      'max-hour-in-same-day',
      'Este día no está disponible para la salida de tu vehículo',
      function (value) {
        const { startHour } = this.parent;
        if (!value || !startHour) return true;

        const inSaturday = moment(new Date()).weekday() === 5;

        const timeNow = moment().hour();
        const startTimeLimit = inSaturday ? 14 : 20;
        const exceededLimit = timeNow >= startTimeLimit;

        return !(isToday(value) && exceededLimit);
      }
    )
    .test(
      'limit-3pm',
      i18n('ERROR__RESERVATIONS__LIMIT_3PM_SAME_DAY', ['(08:00pm CDMX)']),
      (value) => {
        if (!value) return true;

        const sameDay = isToday(value);
        if (!sameDay) return true;

        const todayLimit = new Date();
        todayLimit.setHours(20, 0, 0);

        return new Date() < todayLimit;
      }
    ),
  startHour: Yup.string()
    .required(REQUIRED_MESSAGE)
    .test(
      'limit-3pm',
      i18n('ERROR__RESERVATIONS__LIMIT_3PM_SAME_DAY', ['(01:30pm CDMX)']),
      function (value) {
        if (!value) return true;
        const { startDate } = this.parent;
        const sameDay = isToday(startDate);
        if (!sameDay) return true;
        const parsedDate = moment(value, FULL_DATE_FORMAT);
        const todayLimit = parsedDate.toDate();
        todayLimit.setHours(13, 30, 0);
        return parsedDate < todayLimit;
      }
    ),
  branchStart: Yup.string().required(REQUIRED_MESSAGE),
};

export const reservationDatesProps = {
  ...reservationStartDatesProps,
  dateEnd: dateEndValidation,
  endHour: Yup.string().required(REQUIRED_MESSAGE),
  branchEnd: Yup.string().required(REQUIRED_MESSAGE),
};

export const reservationDatesContractQuoteProps = {
  dateStart: reservationStartDatesProps.dateStart,
  startHour: reservationStartDatesProps.startHour,
  dateEnd: reservationDatesProps.dateEnd,
  daysNumber: Yup.number()
    .required(REQUIRED_MESSAGE)
    .min(1, i18n('ERROR__COMMONS__NUMBER_AMOUNT__INVALID_MIN', [1]))
    .test('valid-return-date-time', '', function validate(daysNumber) {
      if (!daysNumber) return true;

      const { returnDateError = '' } = this.parent;

      if (returnDateError) {
        return this.createError({ message: returnDateError });
      }

      return true;
    }),
  planType: Yup.string().required(REQUIRED_MESSAGE),
  returnDateTime: Yup.string().required(REQUIRED_MESSAGE),
};

export const schema = Yup.object().shape({
  ...reservationDatesProps,
});

const initialValues = {
  dateStart: '',
  startHour: '',
  branchStart: '',
  dateEnd: '',
  endHour: '',
  branchEnd: '',
  planType: '',
  // TODO: TEMPORAL FLOW
  automaticReturnDate: true,
};

export const reservationDatesContractQuoteInitialValues = {
  dateStart: moment(new Date()).format(`${YYYY_MM_DD}`),
  startHour: '',
  daysNumber: '',
  planType: '',
  returnDateError: '',
  returnDateTime: '',
  vehicleCategory: '',
};

export const initialTouched = {
  dateStart: true,
  startHour: true,
  branchStart: true,
  dateEnd: true,
  endHour: true,
  branchEnd: true,
};

export const reservationDatesContractQuoteInitialTouched = {
  dateStart: true,
  startHour: true,
  daysNumber: true,
  planType: true,
  returnDateError: true,
  returnDateTime: true,
  vehicleCategory: true,
};

export const transformer = (values, contractQuote = false) => {
  const { pickupBranch, pickupDateTime, returnBranch, returnDateTime } = values;

  const startHour = moment(pickupDateTime, ISO_DATE_FORMAT).format(
    FULL_DATE_FORMAT
  );
  const dateStart = moment(pickupDateTime, ISO_DATE_FORMAT).format(YYYY_MM_DD);

  // This validation is for typeof bool
  const hasContractQuote = contractQuote === true;

  if (hasContractQuote) {
    return {
      startHour,
      dateStart,
      ...values,
    };
  }

  const endHour = moment(returnDateTime, ISO_DATE_FORMAT).format(
    FULL_DATE_FORMAT
  );
  const dateEnd = moment(returnDateTime, ISO_DATE_FORMAT).format(YYYY_MM_DD);

  return {
    dateStart,
    startHour,
    branchStart: pickupBranch,
    dateEnd,
    endHour,
    branchEnd: returnBranch,
    availableTime: values.availableTime,
    ...values,
  };
};

export const getReturnDateTime = ({ dateStart, startHour, daysNumber }) => {
  const formatedHour = `${dateStart} ${startHour}`;
  return moment(dateStart)
    .set({
      hour: moment(formatedHour).get('hour'),
      minutes: moment(formatedHour).get('minutes'),
      seconds: moment(formatedHour).get('seconds'),
    })
    .add(Number(daysNumber), 'days')
    .format(ISO_DATE_FORMAT);
};

export const formatter = (values, contractQuote = false) => {
  const {
    customer,
    branchStart,
    branchEnd,
    startHour,
    endHour,
    vehicleCategory,
    // eslint-disable-next-line camelcase
    customer_search,
    dateEnd,
    dateStart,
    lineSelected,
    planType,
    vehicleQuantity,
  } = values;

  const startDate = {
    pickupBranch: branchStart,
    pickupDateTime: realDate(dateStart, startHour).format(ISO_DATE_FORMAT),
  };

  // This validation is for typeof bool
  const hasContractQuote = contractQuote === true;

  if (hasContractQuote) {
    const formattedContractQuote = {
      ...startDate,
      returnDateTime: values.returnDateTime,
      rentalPlanUuid: planType,
    };

    delete formattedContractQuote.pickupBranch;

    return formattedContractQuote;
  }

  return {
    customer,
    customer_search,
    vehicleCategory,
    returnBranch: branchEnd,
    returnDateTime: realDate(dateEnd, endHour).format(ISO_DATE_FORMAT),
    lineSelected: lineSelected || '',
    vehicleQuantity,
    ...startDate,
  };
};

export const config = {
  schema,
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter,
  transformer,
  submitForm: editStepOne,
  btnSubmitLabel: 'Actualizar',
  disableOnDirty: true,
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE EDICIÓN',
      message: '¿Estás seguro que quieres continuar?',
      actionLabel: 'Sí, continuar',
      cancelLabel: 'Cancelar',
    },
    response: {
      successTitle: '¡BIEN HECHO!',
      errorTitle: '¡UPS! ALGO PASÓ!',
      successMessage:
        'El cliente se agregó de forma exitosa al catálogo, si deseas realizar algún cambio tendrás que buscarlo y editarlo manualmente.',
      errorMessage:
        'Hubo un error mientras procesabamos la información, por favor intenta de nuevo.',
      successLabel: 'Ir a Clientes',
      errorLabel: 'Aceptar',
      cancelLabel: 'Cancelar',
      successPath: '/customers/',
    },
  },
};

export default config;
