import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { END } from 'redux-saga';
import sagaMonitor from '@redux-saga/simple-saga-monitor';
import { createBrowserHistory } from 'history';
import createRootReducer from './rootReducers';
import rootSaga from './rootSaga';

export const history = createBrowserHistory();

const { NODE_ENV } = process.env;
function configureStore(preloadedState) {
  const enhancers = [];
  if (NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history)
        // ... other middlewares ...
      ),
      ...enhancers
    )
  );

  store.runSaga = sagaMiddleware.run(rootSaga);
  store.close = () => store.dispatch(END);

  window.$$LogSagas();
  return store;
}

const store = configureStore();

export default store;
