import compose from 'recompose/compose';
import { connect, useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import withLayout from 'hooks/withLayout';
import {
  closeResponseDialog,
  setCustomerType,
  hydrateCustomerEdition,
  resetDetail,
  getDetail,
} from 'store/customers/actions';
import { setContractQuote } from 'store/reservations/actions';
import { iconsResponseDialog } from '@customers/common/config';
import { QUOTE_CONTRACT_QUERY, isContractQuote } from 'utils/navigation';
import { fetchCatalog } from 'store/commons/actions';
// eslint-disable-next-line import/no-named-as-default
import CustomerEditor from './Parent';
import CustomerCreator from '../CustomerCreator/CustomerCreator';
import { useEffect } from 'react';
import { SkeletonLoader } from 'components';

const Component = (props) => {
  // const commonPayload = { withLoader: false, controlled: false };
  const dispatch = useDispatch();
  const hasBirthDate = Boolean(
    useSelector((state) =>
      _get(state, 'customers.customerDetail.data.userInfo.birthDate', '')
    )
  );
  const hasTenderRegister = Boolean(
    useSelector((state) =>
      _get(state, 'customers.customerDetail.data.tender.uuid', '')
    )
  );
  const success = useSelector((state) =>
    _get(state, 'customers.customerDetail.success')
  );

  useEffect(() => {
    dispatch(getDetail({ id: props.match.params.id }));
  }, []);

  return !success ? (
    <SkeletonLoader />
  ) : hasBirthDate || hasTenderRegister ? (
    <CustomerEditor {...props} />
  ) : (
    <CustomerCreator {...props} />
  );
};

export default withLayout(
  compose(
    connect(
      (state) => {
        const onCancelPath = isContractQuote()
          ? `./detail?${QUOTE_CONTRACT_QUERY}`
          : './detail';

        return {
          ...iconsResponseDialog,
          loader: _get(state, 'commons.loader', false),
          responseDialog: _get(state, 'customers.responseDialog', {}),
          customer: _get(state, 'customers.customerDetail', {}),
          quoteId: _get(state, 'reservations.quote.values.quoteId', undefined),
          onCancelPath,
          listOfInvalidsDocuments: _get(
            state,
            'customers.customerDetail.data.listOfInvalidsDocuments',
            []
          ),
          customerType: _get(state, 'customers.customerType', ''),
          customerPersonType: _get(state, 'customers.customerPersonType', ''),
          hydrateEdition: state.customers.hydrateCustomerEdition,
          tenderType: _get(state, 'customers.tenderType', ''),
        };
      },
      {
        closeResponseDialog,
        setCustomerType,
        setContractQuote,
        hydrateCustomerEdition,
        resetDetail,
        fetchCatalog,
      }
    )
  )(Component)
);
