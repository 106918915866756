import React, { useState, useRef } from 'react';

import 'primeflex/primeflex.css';

//theme

import 'primereact/resources/themes/lara-light-indigo/theme.css';

//core

import 'primereact/resources/primereact.min.css';

import { InputText } from 'primereact/inputtext';

import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';

import { InputTextarea } from 'primereact/inputtextarea';

import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';

import { InputMask } from 'primereact/inputmask';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';

import { Button } from 'primereact/button';

export const WorkshopMain = () => {
  const cities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' },
  ];
  const tarjeta = [
    { name: 'Original', key: 'og' },
    { name: 'Copia', key: 'co' },
  ];
  const [selectedTarjeta, setSelectedTarjeta] = useState([tarjeta[1]]);

  const onTarjetChange = (e) => {
    let _selectedTarjeta = [...selectedTarjeta];

    if (e.checked) _selectedTarjeta.push(e.value);
    else
      _selectedTarjeta = _selectedTarjeta.filter(
        (category) => category.key !== e.value.key
      );

    setSelectedTarjeta(_selectedTarjeta);
  };

  const vigencia1 = [
    { name: 'Manual', key: 'man' },
    { name: 'Llanta de refacción', key: 'llr' },
    { name: 'Gato', key: 'G' },
    { name: 'Segruo de Ruedas', key: 'R' },
    { name: 'Señales Reflectantes', key: 'S' },
  ];
  const [selectedVigencia1, setSelectedVigencia1] = useState([vigencia1[1]]);
  const onVigencia1Change = (e) => {
    let _selectedVigecia1 = [...selectedVigencia1];

    if (e.checked) _selectedVigecia1.push(e.value);
    else
      _selectedVigecia1 = _selectedVigecia1.filter(
        (category) => category.key !== e.value.key
      );

    setSelectedVigencia1(_selectedVigecia1);
  };

  const vigencia2 = [
    { name: 'Control de llave', key: 'cll' },
    { name: 'Radio', key: 'Rad' },
    { name: 'Tapetes', key: 'Tap' },
    { name: 'Vestiduras', key: 'Ves' },
    { name: 'Placa de Circulación', key: 'pc' },
    { name: 'Verificación', key: 'ver' },
  ];
  const [selectedVigencia2, setSelectedVigencia2] = useState([vigencia2[1]]);
  const onVigencia2Change = (e) => {
    let _selectedVigecia2 = [...selectedVigencia2];

    if (e.checked) _selectedVigecia2.push(e.value);
    else
      _selectedVigecia2 = _selectedVigecia2.filter(
        (category) => category.key !== e.value.key
      );

    setSelectedVigencia2(_selectedVigecia2);
  };

  const llantas = [
    { name: 'Original', key: 'og' },
    { name: 'Copia', key: 'co' },
  ];
  const [selectedLlantas, setSelectedLlantas] = useState([tarjeta[1]]);

  const onLlantasChange = (e) => {
    let _selectedLlantas = [...selectedLlantas];

    if (e.checked) _selectedLlantas.push(e.value);
    else
      _selectedLlantas = _selectedLlantas.filter(
        (category) => category.key !== e.value.key
      );

    setSelectedLlantas(_selectedLlantas);
  };

  const [selectedCity, setSelectedCity] = useState(null);
  const [datetime24h, setDateTime24h] = useState(null);
  const [value1, setValue1] = useState(20);
  const toast = useRef(null);

  const onUpload = () => {
    toast.current.show({
      severity: 'info',
      summary: 'Success',
      detail: 'File Uploaded',
    });
  };
  return (
    <div>
      <div className="bg-primaro text-white-bold flex justify-content-around flex-wrap  mb-3">
        <p>CASANOVA RENT</p>
        <p>ORDEN DE INGRESO AL TALLER</p>
        <p>FOLIO : CAS-345</p>
        <Button label="Cuadre De Piso" />
      </div>

      <div className="contenedor">
        <div className="grid">
          <div className="col-3 bg-secondary">
            <label className="block mb-2" htmlFor="placa">
              Placa
            </label>
            <InputText
              keyfilter="placa"
              placeholder="Placa"
              className="w-full"
            />
          </div>

          <div className="col-3 bg-secondary ">
            <label htmlFor="marca" className="block mb-2">
              Marca
            </label>
            <InputText disabled placeholder="Marca" className="w-full" />
          </div>
          <div className="col-3 bg-secondary">
            <label htmlFor="modelo" className="block mb-2">
              Modelo
            </label>
            <InputText disabled placeholder="Modelo" />
          </div>
          <div className="col-3 bg-secondary">
            <label htmlFor="año" className="block mb-2">
              Año
            </label>

            <MultiSelect
              disabled
              placeholder="Ano"
              className="w-full md:w-20rem"
            />
          </div>
        </div>

        <div className="grid mt-2">
          <div className="col-6 bg-secondary">
            <label htmlFor="nserie" className="block mb-2">
              N° de serie
            </label>
            <InputText
              keyfilter="nserie"
              placeholder="N° de serie"
              className="w-full"
            />
          </div>
          <div className="col-3 bg-secondary">
            <label htmlFor="color" className="block mb-2">
              Color
            </label>

            <MultiSelect
              disabled
              placeholder="Color"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="col-3 bg-secondary">
            <label htmlFor="Dependencia" className="block mb-2">
              Dependencia
            </label>

            <MultiSelect
              disabled
              placeholder="Dependencia"
              className="w-full md:w-20rem"
            />
          </div>
        </div>

        <div className="grid mt-2">
          <div className="col-3 bg-secondary">
            <label htmlFor="km" className="block mb-2">
              Kilometraje
            </label>

            <InputNumber
              value={value1}
              onValueChange={(e) => setValue1(e.value)}
              suffix="km"
            />
          </div>
          <div className="col-3 bg-secondary">
            <label htmlFor="er" className="block mb-2">
              Ejecutivo responsable
            </label>

            <MultiSelect
              disabled
              placeholder="Ejecutivo responsable"
              className="w-full md:w-20rem"
            />
          </div>
          <div className="col-6 bg-secondary">
            <label htmlFor="areas" className="block mb-2">
              Selecciona el/las Área(s) del Taller
            </label>

            <MultiSelect
              disabled
              placeholder="Elegir"
              style={{ width: '100%' }}
            />
          </div>
        </div>

        <div className="grid mt-2">
          <div className="col-3 bg-secondary">
            <label className="block mb-2" htmlFor="usr">
              Usuario
            </label>
            <InputText keyfilter="usr" placeholder="Usuario" />
          </div>

          <div className="col-3 bg-secondary ">
            <label htmlFor="Telefono" className="block mb-2">
              Telefono
            </label>
            <InputMask
              id="phone"
              mask="(99) 9999-9999"
              placeholder="(99) 9999-9999"
            ></InputMask>
          </div>
          <div className="col-3 bg-secondary">
            <label htmlFor="ingreso" className="block mb-2">
              Fecha y Hora Ingreso
            </label>
            <Calendar
              value={datetime24h}
              onChange={(e) => setDateTime24h(e.value)}
              showTime
              hourFormat="24"
            />
          </div>
          <div className="col-3 bg-secondary">
            <label htmlFor="entrega" className="block mb-2">
              Fecha y Hora Entrega
            </label>

            <Calendar
              id="calendar-24h"
              value={datetime24h}
              onChange={(e) => setDateTime24h(e.value)}
              showTime
              hourFormat="24"
            />
          </div>
        </div>

        <div className="grid mt-2">
          <div className="col-6">
            <div className="grid">
              <div className="col-6">
                <label htmlFor="tarjeta" className="block mb-2">
                  Tarjeta de circulación
                </label>
                {tarjeta.map((category) => {
                  return (
                    <div
                      key={category.key}
                      className="flex align-items-center "
                    >
                      <Checkbox
                        inputId={category.key}
                        name="tarjc"
                        value={category}
                        onChange={onTarjetChange}
                        checked={selectedTarjeta.some(
                          (item) => item.key === category.key
                        )}
                      />
                      <label htmlFor={category.key} className="ml-2">
                        {category.name}
                      </label>
                    </div>
                  );
                })}
                <label htmlFor="vigencia2" className="block mt-4 mb-2">
                  Vigencia
                </label>
                {vigencia2.map((category) => {
                  return (
                    <div
                      key={category.key}
                      className="flex align-items-center "
                    >
                      <Checkbox
                        inputId={category.key}
                        name="vigencia 2"
                        value={category}
                        onChange={onVigencia2Change}
                        checked={selectedVigencia2.some(
                          (item) => item.key === category.key
                        )}
                      />
                      <label htmlFor={category.key} className="ml-2">
                        {category.name}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="col-6">
                <label htmlFor="vigencia" className="block mb-2">
                  Vigencia
                </label>
                {vigencia1.map((category) => {
                  return (
                    <div
                      key={category.key}
                      className="flex align-items-center "
                    >
                      <Checkbox
                        inputId={category.key}
                        name="vigencia"
                        value={category}
                        onChange={onVigencia1Change}
                        checked={selectedVigencia1.some(
                          (item) => item.key === category.key
                        )}
                      />
                      <label htmlFor={category.key} className="ml-2">
                        {category.name}
                      </label>
                    </div>
                  );
                })}

                <label htmlFor="llantas" className="block mb-2 mt-3"></label>
                {llantas.map((category) => {
                  return (
                    <div
                      key={category.key}
                      className="flex align-items-center "
                    >
                      <Checkbox
                        inputId={category.key}
                        name="llantas"
                        value={category}
                        onChange={onLlantasChange}
                        checked={selectedLlantas.some(
                          (item) => item.key === category.key
                        )}
                      />
                      <label htmlFor={category.key} className="ml-2">
                        {category.name}
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="col-12">
                <label htmlFor="obs" className="block mb-2">
                  Observaciones
                </label>

                <div>
                  <InputTextarea
                    keyfilter="int"
                    placeholder="Observaciones"
                    rows={5}
                    cols={30}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 bg-secondary">
            <label htmlFor="llantas" className="block mb-2 mt-3">
              Fotos del vehiculo:
            </label>
            <div className="card">
              <FileUpload
                name="demo[]"
                url={'/api/upload'}
                multiple
                accept="image/*"
                maxFileSize={1000000}
                emptyTemplate={
                  <p className="m-0">Sube las fotos del vehiculo.</p>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
