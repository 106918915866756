import React, { useEffect, useState, useCallback, useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { useField } from 'formik';
import { Icons } from '@casanova/casanova-common';
import './CheckInput.scss';

const CheckInput = React.forwardRef((props, ref) => {
  const {
    className,
    id,
    name,
    label,
    right,
    left,
    placeholder,
    displayText,
    onChange = () => {},
    active = false,
    mask,
    maskPlaceholder,
    url,
    download,
    iconStyle = {
      width: '1.5rem',
      height: '1.5rem',
    },
    disabled,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(name);
  const { error, touched, value } = meta;
  const { setValue } = helpers;

  const [hasContent, setHasContent] = useState(false);

  const handleChange = useCallback(
    (evt) => {
      if (!disabled) {
        onChange(evt);
      }
    },
    [onChange, disabled]
  );

  const handleClick = useCallback(() => {
    if (!disabled) {
      setValue(!value);
      setHasContent(true);
    }
  }, [setValue, value, disabled]);

  useEffect(() => {
    setHasContent(field && !_isEmpty(field.value));
    handleChange(field);
  }, [field, handleChange]);

  const hasError = touched && error;
  const visibility = hasError || hasContent || url;
  const CheckIcon =
    value || active ? Icons.CheckAvailable : Icons.CheckUnavailable;

  const inputPaddingSize = useMemo(() => {
    const originalSize = iconStyle.width.replaceAll('rem', '');
    const size = Number(originalSize) * 1.9;
    const padding = `${size}rem`;
    return padding;
  }, [iconStyle]);

  const inputClassName = classNames(
    `FileInputContainer form-control`,
    hasError && 'is-invalid',
    `CheckInput__Form-control--${active ? 'active' : 'inactive'}`,
    disabled && 'CheckInput__Form-control--disabled'
  );

  return (
    <div className={`form-group ${className || ''} `}>
      {label && (
        <label
          style={{ visibility: `${visibility ? 'visible' : 'hidden'}` }}
          className={classNames('col-form-label', hasError && 'text-danger')}
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <div className="custom-input-group pointer">
        <span
          onClick={handleClick}
          onKeyPress={handleClick}
          tabIndex="0"
          role="button"
        >
          <span className="left-content d-flex justify-content-center align-items-center pr-4">
            <div>
              <CheckIcon width={iconStyle.width} height={iconStyle.height} />
            </div>
          </span>

          <input
            ref={ref}
            className={inputClassName}
            style={{ paddingLeft: inputPaddingSize }}
            id={name}
            placeholder={placeholder}
            disabled
            {...rest}
          />
        </span>

        {right && (
          <div className="right-content d-flex justify-content-center align-items-center pr-4">
            {right}
          </div>
        )}
      </div>
      {hasError && (
        <label
          className={classNames(
            'col-form-error',
            hasError && error && 'text-danger'
          )}
          htmlFor={name}
        >
          {hasError}
        </label>
      )}
    </div>
  );
});

export default CheckInput;
