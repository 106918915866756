import i18n from '@i18n';
import { editLoan } from 'store/loans/actions';
import { getInitialValues, getInitialTouched } from 'utils/validations/fields';
import { successPath } from '@loans/common/config';
import { LOAN_CREATOR_FIELDS as FIELDS } from '@loans/common/constants';
import { schema } from '@loans/sections/LoanInformation/schema';
import { formatter } from './formatter';
import { transformer } from './transformer';

const fields = { ...FIELDS };

fields[`${FIELDS.REQUESTER}_search`] = `${FIELDS.REQUESTER}_search`;
fields[`${FIELDS.PLATE}_search`] = `${FIELDS.PLATE}_search`;

export const config = {
  schema,
  initialTouched: getInitialTouched({ fields }),
  initialValues: getInitialValues({ fields }),
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  disableOnDirty: true,
  formatter,
  transformer,
  submitForm: editLoan,
  dialogs: {
    confirmation: {
      title: i18n('LOANS__LOAN_EDITOR__MODAL__TITLE'),
      message: i18n('LOANS__LOAN_EDITOR__MODAL__DESCRIPTION'),
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: i18n('LOANS__LOAN_EDITOR__MODAL_SUCCESS__MESSAGE'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: i18n('LOANS__LOAN_CREATOR__MODAL_SUCCESS__LABEL'),
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath,
    },
  },
};
