import compose from 'recompose/compose';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { setRefundStep, closeResponseDialog } from 'store/reservations/actions';
import { withLayout } from 'hooks';
import { DeliveryReturnStepTwo } from '@reservations/sections/DeliveryRefluxSteps';
import { config } from './config';

const mapStateToProps = (state) => ({
  loader: _get(state, 'commons.loader', false),

  onCancelPath: `/reservations`,
  responseDialog: {
    ..._get(state, 'reservations.responseDialog', {}),
    modalType: 'action',
  },
  leftCol: 4,
  middleCol: 4,

  refund: state.reservations.refund,
  config: config({
    number: _get(
      state,
      'reservations.reservationInfo.data.naturalPerson.cellphone',
      ''
    ),
  }),
});

export default withLayout(
  compose(connect(mapStateToProps, { closeResponseDialog, setRefundStep }))(
    DeliveryReturnStepTwo
  )
);
