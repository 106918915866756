import * as yup from 'yup';
import i18n from '@i18n';
import { setParamsListOfCustomers } from 'store/customers/actions';

const schema = yup.object().shape({
  customerNumber: yup.string(),
  name: yup.string(),
  rfc: yup.string(),
  type: yup.string(),
  customerStatus: yup.string(),
  documentStatus: yup.string(),
});

const initialValues = {
  customerNumber: '',
  name: '',
  rfc: '',
  type: '',
  customerStatus: '',
  documentStatus: '',
};

export const initialTouched = {
  customerNumber: true,
  name: true,
  rfc: true,
  type: true,
  customerStatus: true,
  documentStatus: true,
};

const formatter = (values) => {
  const { type } = values;
  return {
    ...values,
    isNaturalPerson: type == 'isNatural' ? true : '',
    isJuridicalPerson: type == 'isJuridical' ? true : '',
    isForeignPerson: type == 'isForeign' ? true : '',
    isTender: type == 'isTender' ? true : '',
  };
};

export const config = {
  schema,
  modalType: 'empty',
  initialTouched,
  initialValues,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  formatter,
  submitForm: setParamsListOfCustomers,
  withButtons: false,
  dialogs: {
    confirmation: {},
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      successMessage: '',
      successLabel: '',
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      errorLabel: i18n('COMMONS__CLOSE__TEXT'),
    },
  },
};
