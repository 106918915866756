import {
  VIEW_VEHICLE_ROAD_TAX,
  CREATE_VEHICLE_ROAD_TAX,
} from 'utils/roles/permissions';
import RoadTax from './RoadTax';
import { withConnectedAdditional } from '../AdditionalCollapse';

export default withConnectedAdditional({
  dependsOnElement: 'plateOptions',
  identifierForView: VIEW_VEHICLE_ROAD_TAX,
  identifierForAdd: CREATE_VEHICLE_ROAD_TAX,
})(RoadTax);
