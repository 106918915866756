import React, { useCallback, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { withForm, Modal } from '@casanova/casanova-common';
import { usePreventChange } from 'hooks';
import { PreventChange } from 'components';
import { Vehicles } from '@contracts/sections';
import { FORM_ACTIONS } from 'utils/constants/formActions';

function ContractBigModalForm({
  action = FORM_ACTIONS.ADD,
  openConfirmationMessage,
  setHide,
  setShow,
  setResponseDialog,
  responseDialog,
  setDirty,
  vehicle,
  children,
  withPreventChange,
  ...restProps
}) {
  const {
    values,
    initialValues,
    dirty,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const params = useParams();

  const handleConfirmation = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const handleVehicleChange = useCallback((vehicles) => {
    const [vehicleB] = vehicles;
    setFieldValue('vehicleB', vehicleB || null);
  }, []);

  useEffect(() => {
    setHide(openConfirmationMessage);
  }, [openConfirmationMessage]);

  useEffect(() => {
    setHide(responseDialog.open);
  }, [responseDialog.open]);

  useEffect(() => {
    setDirty(dirty);
  }, [setDirty, dirty]);

  useEffect(() => {
    setFieldValue('uuid', params.id);
  }, [params, setFieldValue]);

  const disabledInput = action === FORM_ACTIONS.VIEW;

  const helpers = {
    action,
    initialValues,
    values,
    disabledInput,
    vehicle,
    ...restProps,
  };

  return (
    <PreventChange
      preventChange={withPreventChange && dirty && !isSubmitting}
      stockInCurrentPath
      onConfirmation={handleConfirmation}
    >
      {children(helpers)}
      <Vehicles
        action={FORM_ACTIONS.ADD}
        onlyAdd
        onlyOne
        setResponseDialog={setResponseDialog}
        onVehiclesChange={handleVehicleChange}
      />
    </PreventChange>
  );
}

export default function ContractBigModal(props) {
  const {
    show = true,
    setShow,
    loader = false,
    history,
    config,
    responseDialog,
  } = props;

  const [hide, setHide] = useState(false);
  const [preventChange, setPreventChange] = usePreventChange();
  const [dirty, setDirty] = useState(false);
  const [withPreventChange, setWithPreventChange] = useState(true);

  const handleCloseModal = useCallback(() => {
    setWithPreventChange(true);

    if (!dirty) {
      setShow(false);
      return;
    }

    history.push('/contracts');
  }, [history, setShow, dirty]);

  const ContractBigModalWithForm = withForm({
    config: {
      ...config,
      submitForm: (...args) => {
        setPreventChange(false);
        setWithPreventChange(false);
        return config.submitForm(...args);
      },
      onSuccess: handleCloseModal,
    },
  })(ContractBigModalForm);

  useEffect(() => {
    setHide(preventChange.active);
  }, [preventChange.active]);

  const hideModal = responseDialog.open || loader || hide;

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  useEffect(() => {
    setOpenPaymentModal(show);
  }, [show]);

  return (
    <Modal
      open={show}
      header={null}
      footer={null}
      closeButton
      bigModal
      onClose={handleCloseModal}
      className={`${hideModal ? 'd-none' : ''}`}
      topContainerClassName={`${hideModal ? 'd-none' : ''}`}
    >
      {ContractBigModalWithForm({
        ...props,
        setHide,
        setDirty,
        onSuccess: () => setOpenPaymentModal(false),
        withPreventChange,
        // onCancelPath: '/contracts/',
      })}
    </Modal>
  );
}
