import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  fetchVehicleTransfers,
  setPageVehicleTransfers,
} from 'store/vehicles/actions';
import VehicleTransfers from './VehicleTransfers';

export default compose(
  connect(
    (state) => ({
      responseDialog: state.vehicles.responseDialog,
      showTitle: false,
      vehicleTransfers: state.vehicles.vehicleTransfers,
      vehicleDetails: state.vehicles.vehicleDetails.data,
    }),
    { fetchVehicleTransfers, setPageVehicleTransfers }
  )
)(VehicleTransfers);
