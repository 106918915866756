import _get from 'lodash/get';
import { connect } from 'react-redux';
import PayDetail from './PayDetail';
import compose from 'recompose/compose';

export default compose(
  connect(
    (state) => ({
      reservationDetails: _get(state, 'reservations.reservationInfo.data', {}),
      totalPay: _get(state, 'dailyRentContracts.totalPay.data', {}),
    }),
    {}
  )
)(PayDetail);
