import { connect } from 'react-redux';
import { search, reset, getDetail } from 'store/customers/actions';
import {
  getTimeLimits,
  closeResponseDialog,
  setTitles,
  setEndAvailableHours,
  setVehicleCategory,
  resetReservations,
  getReservationInfo,
  clearReservationInfo,
  resetResponseDialogUpdateReservation,
  getDetailOfCustomerInUpdateModal,
  setParams,
  setCategorySelected,
  getVehicles,
} from 'store/reservations/actions';
import { setRentalPlansToUse } from 'store/dailyRentContracts/actions';
import { fetchCatalog } from 'store/commons/actions';
import _get from 'lodash/get';
import ModifyReservationModal from './ModifyReservationModal';

export default connect(
  (state) => ({
    onCancelPath: '/reservations',
    loader: _get(state, 'commons.loader', false),
    quote: _get(state, 'reservations.quote', {}),
    responseDialog: _get(state, 'reservations.responseDialog', {}),
    customersSearch: _get(state, 'customers.basicInfo.list', []),
    customersSelected: _get(state, 'customers.customerDetail.data', {}),
    categories: _get(state, 'reservations.filters.categories', []),
    quoteValues: _get(state, 'reservations.quote.values', {}),
    availableTime: _get(state, 'reservations.quote.availableTime.list', []),
    reservationDetails: _get(state, 'reservations.reservationInfo', {}),
    responseDialogUpdateReservation: _get(
      state,
      'reservations.responseDialogUpdateReservation',
      {}
    ),
    vehicleTypes: _get(
      state,
      'reservations.filters.selectedCategory.types',
      []
    ),
    vehicles: _get(state, 'reservations.catalog.results', []),
    rentalPlansToUse: _get(
      state,
      'dailyRentContracts.rentalPlans.toUse.list',
      []
    ),
  }),
  {
    fetchCatalog,
    closeResponseDialog,
    search,
    getTimeLimits,
    setTitles,
    setEndAvailableHours,
    resetCustomers: reset,
    setVehicleCategory,
    resetReservations,
    getDetailOfCustomerInUpdateModal,
    getReservationInfo,
    clearReservationInfo,
    resetResponseDialogUpdateReservation,
    setParams,
    setCategorySelected,
    getVehicles,
    setRentalPlansToUse,
  }
)(ModifyReservationModal);
