import { takeLatest } from 'redux-saga/effects';
import {
  VERIFY_TOKEN_REQUESTED,
  USER_ACTIVATION_REQUESTED,
} from './action-types';
import { verifyValidTokenSaga, userActivationSaga } from './sagas';

export default function* watchers() {
  yield takeLatest(VERIFY_TOKEN_REQUESTED, verifyValidTokenSaga);
  yield takeLatest(USER_ACTIVATION_REQUESTED, userActivationSaga);
}
