import { call, put, select } from 'redux-saga/effects';
import {
  fetchCatalog,
  fetchInstaller,
  getVersionSpecs,
  getAreas,
  getRoles,
  getModules,
  getRentalStatues,
  getRentalFilterV2Service,
} from 'services/catalogs/services';
import { getOptionsAuth } from 'services/auth';
import { showLoader, hideLoader } from 'store/commons/actions';
import {
  fetchCatalogSucceeded,
  fetchCatalogFailed,
  fetchFiltersFailed,
  fetchFiltersSucceeded,
  fetchInstallerSucceeded,
  fetchInstallerFailed,
  getSpecsFailed,
  getSpecsSucceedeed,
  fetchAreasFailed,
  fetchAreasSucceeded,
  fetchRolesSucceeded,
  fetchRolesFailed,
  fetchModulesSucceeded,
  fetchModulesFailed,
  fetchRentalStatusesSucceeded,
  fetchRentalStatusesFailed,
  fetchRentalFilterSucceeded,
  fetchRentalFilterFailed,
} from './actions';

export function* fetchCatalogSaga(action) {
  try {
    const data = yield call(fetchCatalog, action.payload, getOptionsAuth());
    yield put(fetchCatalogSucceeded({ data, identifier: action.payload }));
  } catch (error) {
    yield put(fetchCatalogFailed({ error, identifier: action.payload }));
  }
}

export function* getSpecsSaga(action) {
  try {
    yield put(showLoader());
    const { version, model } = action.payload;
    const data = yield call(getVersionSpecs, version, model);
    yield put(getSpecsSucceedeed({ data }));
  } catch (error) {
    yield put(getSpecsFailed({ error }));
  } finally {
    yield put(hideLoader());
  }
}

export function* fetchInstallerSaga(action) {
  try {
    const data = yield call(fetchInstaller, action.payload, getOptionsAuth());
    yield put(fetchInstallerSucceeded({ data, identifier: action.payload }));
  } catch (error) {
    yield put(fetchInstallerFailed({ error, identifier: action.payload }));
  }
}

export function* fetchFiltersSaga(action) {
  try {
    const { brand, modal, owner, provider, status } = yield select(
      (state) => state.catalogs
    );
    const brandNotFetched = brand.list <= 0;
    const modalNotFetched = modal.list <= 0;
    const ownerNotFetched = owner.list <= 0;
    const providerNotFetched = provider.list <= 0;
    const statusNotFetched = status.list <= 0;

    const hasNotFetched =
      brandNotFetched ||
      modalNotFetched ||
      ownerNotFetched ||
      providerNotFetched ||
      statusNotFetched;

    if (!hasNotFetched) return;

    const data = yield call(fetchCatalog, action.payload, getOptionsAuth());
    yield put(fetchFiltersSucceeded({ data, identifier: action.payload }));
  } catch (error) {
    yield put(fetchFiltersFailed({ error, identifier: action.payload }));
  }
}

export function* fetchAreasSaga() {
  try {
    const data = yield call(getAreas, getOptionsAuth());
    yield put(fetchAreasSucceeded(data));
  } catch (error) {
    yield put(fetchAreasFailed({ error }));
  }
}

export function* fetchRolesSaga({ payload = {} }) {
  try {
    const { data } = yield call(getRoles, getOptionsAuth());
    yield put(
      fetchRolesSucceeded({ data, filterCustomer: payload.filterCustomer })
    );
  } catch (error) {
    console.error(error);
    yield put(fetchRolesFailed(error));
  }
}

export function* fetchModulesSaga() {
  try {
    const { data } = yield call(getModules, getOptionsAuth());
    yield put(fetchModulesSucceeded(data));
  } catch (error) {
    console.error(error);
    yield put(fetchModulesFailed(error));
  }
}

export function* fetchRentalStatusesSaga() {
  try {
    const data = yield call(getRentalStatues, getOptionsAuth());
    yield put(fetchRentalStatusesSucceeded(data));
  } catch (error) {
    console.error(error);
    yield put(fetchRentalStatusesFailed(error));
  }
}

export function* fetchRentalFilterSaga() {
  try {
    const data = yield call(getRentalFilterV2Service, getOptionsAuth());
    yield put(fetchRentalFilterSucceeded(data));
  } catch (error) {
    console.error(error);
    yield put(fetchRentalFilterFailed(error));
  }
}
