import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Icons, MaskedInput as Input, Button } from '@casanova/casanova-common';
import { alphaMaskWithSpaces } from 'utils/masks';
import { COLORS } from 'utils/colors';
import i18n from '@i18n';
import { generateSchema } from './validations';
import './SearchInput.scss';

export default function SearchInput({
  onSubmit,
  mask = alphaMaskWithSpaces(50),
  subtitle,
  schema,
  ...props
}) {
  const onFormikSubmit = useCallback((...args) => {
    onSubmit(...args);
  }, []);

  return (
    <Formik
      initialValues={{ [props.name]: '' }}
      validationSchema={
        schema
          ? generateSchema(props.name).concat(schema)
          : generateSchema(props.name)
      }
      enableReinitialize
      validateOnChange
      onSubmit={onFormikSubmit}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} className="SearchInput">
          <div className="row mt-2 mb-3">
            <div className="col-8 pl-0">
              <Input
                mask={mask}
                maskPlaceholder=""
                tabIndex="0"
                right={<Icons.Search width="60%" fill={COLORS.SECONDARY} />}
                className="mb-1"
                deleteValue={values[props.name]}
                {...props}
              />
              {subtitle && <p className="text-muted mt-0 mb-1">{subtitle}</p>}
            </div>

            <div className="col-4 pl-2">
              <Button block type="submit">
                {i18n('COMMONS__SEARCH')}
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

SearchInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  schema: PropTypes.object,
};
