import {
  VIEW_VEHICLE_VERIFICATION,
  CREATE_VEHICLE_VERIFICATION,
} from 'utils/roles/permissions';
import Verification from './Verification';
import { withConnectedAdditional } from '../AdditionalCollapse';

export default withConnectedAdditional({
  dependsOnElement: 'plateOptions',
  identifierForView: VIEW_VEHICLE_VERIFICATION,
  identifierForAdd: CREATE_VEHICLE_VERIFICATION,
})(Verification);
