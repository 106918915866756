export const number2mxn = (amount) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);

export const roudHalfUp = (number) => {
  const roudedNumber = number.toFixed(0);
  if (roudedNumber > number) return roudedNumber;
  return number;
};
