import { INVOICES_MODULE } from 'utils/roles/permissions';
import { INVOICES_LIST_PATH, INVOICES_DETAIL_PATH } from 'utils/roles/paths';

export function invoicesAuth(permissions) {
  
  const allowedModules = [];

  const invoiceModule = permissions.filter((permission) =>
    permission.includes('INVOICE')
  );

  if (invoiceModule.length > 0) {
    allowedModules.push(INVOICES_MODULE);
  }

  return allowedModules;
}

export function invoicesPathsAuth() {
  const allowedPaths = [INVOICES_LIST_PATH, INVOICES_DETAIL_PATH];

  return allowedPaths;
}
