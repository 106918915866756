import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  withLayout,
  Section,
  TableBottom,
  useFirstRender,
} from '@casanova/casanova-common';
import i18n from '@i18n';
import { columns } from './columns';

// import { getVehicleHistoricContracts } from "services/vehicles/index.js";

function VehicleContracts({
  match,
  vehicleContracts,
  fetchVehicleContracts,
  setPageVehicleContracts,
  onlyFetchParams = false,
}) {
  let { params = {}, results = {} } = vehicleContracts;

  const isFirstRender = useFirstRender();

  const vehicleUuid = useMemo(() => match.params.id, [match.params.id]);

  useEffect(() => {
    if (onlyFetchParams ? !isFirstRender : true) {
      fetchVehicleContracts({ params, uuid: vehicleUuid });
    }
  }, [vehicleUuid, params, onlyFetchParams]);
  return (
    <Section title={i18n('VEHICLES__SECTIONS__VEHICLE_CONTRACTS__TITLE')}>
      <Table
        emptyMessage={i18n('ERROR__COMMONS__NOT_FOUND')}
        dataList={results.list}
        columns={columns}
      />
      <TableBottom
        search={vehicleContracts}
        onChange={setPageVehicleContracts}
        showButton={false}
      />
    </Section>
  );
}

VehicleContracts.propTypes = {
  onlyFetchParams: PropTypes.bool,
};

export default withLayout(VehicleContracts, { withLoader: false });
