import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { FileInput, Section } from '@casanova/casanova-common';
import ConnectedSelect from 'components/ConnectedSelect';
import { acceptFiles } from 'utils/files';
import { ENTRANCE_TYPES } from '@customers/common/constants';
import { FORM_ACTIONS } from 'utils/constants/formActions';
import config from './config';

const { ADD } = FORM_ACTIONS;

const { AERIAL, MARITIME } = ENTRANCE_TYPES;

const CountryEntranceType = ({ action = ADD, countryEntranceTypeEntities }) => {
  const { values, setValues } = useFormikContext();
  const { selectedEntrance } = values;

  const handleChangeEntranceType = useCallback(
    (event) => {
      const entranceType = event.target.value;
      const newSelectedEntrance = countryEntranceTypeEntities.find(
        ({ uuid }) => uuid === entranceType
      );
      setValues({
        ...values,
        ...config.initialValues,
        entranceType,
        selectedEntrance: newSelectedEntrance,
      });
    },
    [values, countryEntranceTypeEntities]
  );

  const RENDER_INPUTS = {
    [AERIAL]: (
      <>
        <div className="col-4">
          <FileInput
            url={action !== ADD ? values.entranceItineraryUrl : undefined}
            displayText={values.entranceItinerary}
            type="file"
            label="ITINERARIO*"
            placeholder="ITINERARIO*"
            name="entranceItinerary"
            accept={acceptFiles}
          />
        </div>
        <div className="col-4">
          <FileInput
            url={action !== ADD ? values.entranceFFMEUrl : undefined}
            displayText={values.entranceFFME}
            type="file"
            label="FMME*"
            placeholder="FMME*"
            name="entranceFFME"
            accept={acceptFiles}
          />
        </div>
      </>
    ),
    [MARITIME]: (
      <div className="col-4">
        <FileInput
          url={action !== ADD ? values.entranceFMMUrl : undefined}
          displayText={values.entranceFMM}
          type="file"
          label="FMM*"
          placeholder="FMM*"
          name="entranceFMM"
          accept={acceptFiles}
        />
      </div>
    ),
  };

  return (
    <Section title="INGRESO">
      <div className="row">
        <div className="col-4">
          <ConnectedSelect
            label="TIPO DE INGRESO*"
            name="entranceType"
            options="commons.countryEntranceTypeEntities"
            onChange={handleChangeEntranceType}
          />
        </div>
        {(selectedEntrance || values.entranceType) &&
          RENDER_INPUTS[selectedEntrance.identifier]}
      </div>
    </Section>
  );
};

export default CountryEntranceType;
