import * as yup from 'yup';
import i18n from '@i18n';
import { REQUIRED_MESSAGE } from '@casanova/casanova-common/lib/utils/constants/index';
import { saveContractPay } from 'store/dailyRentContracts/actions';
import { initialTouched, initialValues } from '../sections/PayDetail/config';
import { savePayFormatter } from './formatter';

const schema = yup.object().shape({
  amount: yup.string().required(REQUIRED_MESSAGE),
});

export const config = {
  schema,
  initialValues,
  initialTouched,
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  redirectInSuccess: false,
  formatter: savePayFormatter,
  submitForm: saveContractPay,
  btnSubmitLabel: 'Guardar',
  dialogs: {
    confirmation: {
      title: 'CONFIRMACIÓN DE ABONO',
      message:
        'Se añadirá el abono al estado de cuenta del cliente\n¿Estás seguro que quieres continuar?',
      actionLabel: i18n('COMMONS__YES_CONTINUE__TEXT'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
    },
    response: {
      successTitle: i18n('SUCCESS__COMMONS__SUCCESS_REQUEST_TITLE'),
      errorTitle: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__TITLE'),
      successMessage: `Se guardó la devolución con éxito.`,
      errorMessage: i18n('ERROR__COMMONS__ERROR_IN_REQUEST__MESSAGE'),
      successLabel: 'Ir a contratos de renta diaria',
      errorLabel: i18n('COMMONS__TRY_AGAIN'),
      cancelLabel: i18n('COMMONS__CANCEL__TEXT'),
      successPath: './',
    },
  },
};
