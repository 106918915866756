export const initialTouched = {
  street: true,
  indoorNumber: true,
  outdoorNumber: true,
  zipCode: true,
  state: true,
  municipality: true,
  colony: true,
  addressDocumentType: true,
  period: true,
  addressDocument: true,
};

const initialValues = {
  street: '',
  indoorNumber: '',
  outdoorNumber: '',
  zipCode: '',
  state: '',
  municipality: '',
  colony: '',
  addressDocumentType: '',
  period: '',
  addressDocument: '',
};

export default {
  initialValues,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true,
  initialTouched,
};
