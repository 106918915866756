import compose from 'recompose/compose';
import { connect } from 'react-redux';
import {
  getList,
  setPage,
  getCustomersListFilters,
  reset,
} from 'store/customers/actions';
import { withLayout } from 'hooks';
import CustomerList from './CustomerList';

export default withLayout(
  compose(
    connect(
      (state) => ({
        customersList: state.customers.list,
        confirmationMessage: false,
        responseDialog: {
          ...state.customers.responseDialog,
          closeInConfirmation: true,
        },
      }),
      {
        getList,
        setPage,
        getCustomersListFilters,
        reset,
      }
    )
  )(CustomerList)
);
